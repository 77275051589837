export const gtmVariables = {
  CAR_ID: 'Car Id',
  CAR_BRAND: 'Car Brand',
  CAR_MODEL: 'Car Model',
  CAR_YEAR: 'Car Year',
  CAR_VERSION: 'Car Version',
  AUCTION_REMAINING_TIME: 'Auction Remaining Time',
  CAR_APPROVAL_PERCENT: 'Car Approval Percent',
  USER_EMAIL: 'User Email',
  USER_DEALERSHIP: 'User Dealership',
  IS_NINJA: 'isNinja',
  BUYER_ID: 'Buyer Id',
};

export const gtmEvents = {
  CTA_MAKE_OFFER_LISTING: 'CTA_MAKE_OFFER_LISTING',
  CTA_MAKE_OFFER_DETAILS: 'CTA_MAKE_OFFER_DETAILS',
  CTA_VIEW_AUCTION_DETAILS: 'CTA_VIEW_AUCTION_DETAILS',
  AUCTION_DETAILS_ACCESS: 'AUCTION_DETAILS_ACCESS',
  PLACE_BID: 'PLACE_BID',
};

export const gtmTracker = (variables = {}) => {
  if (window.dataLayer) {
    window.dataLayer.push(variables);
  }
};

const getCommonAuctionVariables = (auction, carApprovalPercent) => {
  const { summary, _id } = auction;
  const { make, model, year, version } = summary;

  return {
    [gtmVariables.CAR_ID]: _id,
    [gtmVariables.CAR_BRAND]: make,
    [gtmVariables.CAR_MODEL]: model,
    [gtmVariables.CAR_YEAR]: year,
    [gtmVariables.CAR_VERSION]: version,
    [gtmVariables.CAR_APPROVAL_PERCENT]: carApprovalPercent,
  };
};

export const trackAuctionEvent = (event, auction, elapsedTime, carApprovalPercent, extras = {}) => {
  const { auction: auctionData, elapsedSinceLastRequest = 0 } = auction;

  const remainingTime =
    (auctionData.remainingTime - (elapsedTime - elapsedSinceLastRequest)) / 1000;

  gtmTracker({
    event,
    ...extras,
    [gtmVariables.AUCTION_REMAINING_TIME]: remainingTime,
    ...getCommonAuctionVariables(auction, carApprovalPercent),
  });
};
