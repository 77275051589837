import get from 'lodash/get';

import { trackAuctionEvent as gtmTrackAuction } from '../../utils/gtmTracker';
import { getAuctionById } from '../selectors';
import { fractionToPercentage } from '../../utils/number';

class Mixpanel {
  constructor() {
    this.queue = [];
  }
  track(event, payload) {
    this.queue.push({ event, payload });
  }
  clear(mx) {
    this.queue.forEach(i => mx.track(i.event, i.payload));
    this.queue = [];
  }
}

let mixpanel = new Mixpanel();
// https://blog.theodo.fr/2017/07/load-scripts-react-bundle-asynchronously-win-seo/
const AsyncMixpanelTimer = setInterval(() => {
  if (window.mixpanel) {
    mixpanel.clear(window.mixpanel);
    mixpanel = window.mixpanel;
    clearInterval(AsyncMixpanelTimer);
  }
}, 100);

export const trackListing = user => {
  return () => {
    const { dealership, email } = user;
    mixpanel.track('PageView_Listings', {
      userDealership: dealership,
      userEmail: email,
      userOffline: !user.isLoggedIn(),
      lojasVersion: 3,
    });
  };
};

export const trackDetails = (user, car) => {
  return () => {
    const { dealership, email } = user;
    const { make, model, version, year } = car.summary;

    mixpanel.track('PageView_Details', {
      userDealership: dealership,
      userEmail: email,
      userOffline: !user.isLoggedIn(),
      carMake: make,
      carModel: model,
      carVersion: version,
      carYear: year,
      lojasVersion: 3,
    });
  };
};

export const trackBid = (id, user, amount, buyNow, dealership, car) => {
  return () => {
    const { make, model, version, year } = car.summary;
    const { email, dealershipName } = user;

    const payload = {
      userDealership: dealership || dealershipName,
      userEmail: email,
      userOffline: !user.isLoggedIn(),
      bidAmount: amount,
      carId: id,
      carMake: make,
      carModel: model,
      carVersion: version,
      carYear: year,
      lojasVersion: 3,
    };

    const event = buyNow ? 'Click_CompreJa' : 'Click_Bid';
    mixpanel.track(event, payload);
  };
};

export const trackBidSucceed = (id, user, amount, buyNow, dealership, car) => {
  return () => {
    const { make, model, version, year } = car.summary;
    const { email, dealershipName } = user;

    const payload = {
      userDealership: dealership || dealershipName,
      userEmail: email,
      userOffline: !user.isLoggedIn(),
      bidAmount: amount,
      carId: id,
      carMake: make,
      carModel: model,
      carVersion: version,
      carYear: year,
      lojasVersion: 3,
    };

    const event = buyNow ? 'Click_CompreJa_Succeeded' : 'Click_Bid_Succeeded';
    mixpanel.track(event, payload);
  };
};

export const trackBidError = (id, user, amount, buyNow, dealership, car, error) => {
  return () => {
    const { make, model, version, year } = car.summary;
    const { email, dealershipName } = user;

    const payload = {
      userDealership: dealership || dealershipName,
      userEmail: email,
      userOffline: !user.isLoggedIn(),
      bidAmount: amount,
      carId: id,
      carMake: make,
      carModel: model,
      carVersion: version,
      carYear: year,
      lojasVersion: 3,
      error: error,
    };

    const event = buyNow ? 'Click_CompreJa_Error' : 'Click_Bid_Error';
    mixpanel.track(event, payload);
  };
};

export const trackWishlist = (id, wishlist, user, isAdd) => {
  return () => {
    const { email, dealershipName } = user;
    const { make, model, yearRange } = wishlist;

    const payload = {
      userDealership: dealershipName,
      userEmail: email,
      userOffline: !user.isLoggedIn(),
      carId: id,
      carMake: make,
      carModel: model,
      favoriteYearMin: yearRange.min,
      favoriteYearMax: yearRange.max,
      lojasVersion: 3,
    };

    const event = isAdd ? 'Click_Favorite_Add' : 'Click_Favorite_Remove';
    mixpanel.track(event, payload);
  };
};

export const trackWishlistSucceed = (id, wishlist, user, isAdd) => {
  return () => {
    const { email, dealershipName } = user;
    const { make, model, yearRange } = wishlist;

    const payload = {
      userDealership: dealershipName,
      userEmail: email,
      userOffline: !user.isLoggedIn(),
      carId: id,
      carMake: make,
      carModel: model,
      favoriteYearMin: yearRange.min,
      favoriteYearMax: yearRange.max,
      lojasVersion: 3,
    };

    const event = isAdd ? 'Click_Favorite_Add_Succeeded' : 'Click_Favorite_Succeeded';
    mixpanel.track(event, payload);
  };
};

export const trackWishlistError = (id, wishlist, isAdd, user, error) => {
  return () => {
    const { email, dealershipName } = user;
    const { make, model, yearRange } = wishlist;

    const payload = {
      userDealership: dealershipName,
      userEmail: email,
      userOffline: !user.isLoggedIn(),
      carId: id,
      carMake: make,
      carModel: model,
      favoriteYearMin: yearRange.min,
      favoriteYearMax: yearRange.max,
      lojasVersion: 3,
      error: error,
    };

    const event = isAdd ? 'Click_Favorite_Add_Error' : 'Click_Favorite_Remove_Error';
    mixpanel.track(event, payload);
  };
};

export const trackBannerClick = () => {
  mixpanel.track('Click_Banner', { lojasVersion: 3 });
};

export const trackBannerDismiss = () => {
  mixpanel.track('Click_Banner_Dismiss', { lojasVersion: 3 });
};

export const trackLoginSucceeded = user => {
  const { email, dealershipName } = user;
  mixpanel.track('Login_Succeeded', {
    userEmail: email,
    userDealership: dealershipName,
    lojasVersion: 3,
  });
};

export function trackAuctionEvent(event, auctionId) {
  return (dispatch, getState) => {
    const state = getState();
    const auction = getAuctionById(state, auctionId);

    if (!auction) return;

    const approvalPct = fractionToPercentage(
      Number(get(auction, 'summary.approvedAnswersCount')),
      Number(get(auction, 'summary.answersCount'))
    );

    gtmTrackAuction(event, auction, state.timer.elapsedTime, approvalPct);
  };
}
