import styled from 'styled-components';

export const PreferencesContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #003a76;
  color: #ffffff;
  padding: 24px 1.125rem;
  border: 0.063rem solid #d2d4d7;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.25rem #2274a519;
`;

export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: #003a76;
  font-size: 18px;
  cursor: pointer;
`;
