import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

Container.SubmitBtn = styled.button`
  border: 1px solid #2274a5;
  background: transparent;
  box-sizing: border-box;
  font-style: normal;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  color: #2274a5;
  height: 40px;
  width: 100%;
  cursor: pointer;
`;

Container.OutlineBtn = styled.button`
  text-decoration: underline;
  background: transparent;
  font-weight: normal;
  font-style: normal;
  margin-top: 12px;
  font-size: 16px;
  color: #737171;
  height: 40px;
  border: none;
  width: 100%;
  cursor: pointer;
`;

Container.Loading = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`;

Container.Message = styled.div`
  text-align: center;
  font-size: 14px;
  color: #aaa;
`;
