import Types from './types';

export const createUserPreferencesRequest = () => ({
  type: Types.CREATE_USER_PREFERENCES_REQUEST,
});

export const createUserPreferencesSuccess = payload => ({
  type: Types.CREATE_USER_PREFERENCES_SUCCESS,
  payload,
});

export const createUserPreferencesFailure = error => ({
  type: Types.CREATE_USER_PREFERENCES_FAILURE,
  error,
});

export const updateUserPreferencesRequest = () => ({
  type: Types.UPDATE_USER_PREFERENCES_REQUEST,
});

export const updateUserPreferencesSuccess = payload => ({
  type: Types.UPDATE_USER_PREFERENCES_SUCCESS,
  payload,
});

export const updateUserPreferencesFailure = error => ({
  type: Types.UPDATE_USER_PREFERENCES_FAILURE,
  error,
});

export const deleteUserPreferencesRequest = () => ({
  type: Types.DELETE_USER_PREFERENCES_REQUEST,
});

export const deleteUserPreferencesSuccess = payload => ({
  type: Types.DELETE_USER_PREFERENCES_SUCCESS,
  payload,
});

export const deleteUserPreferencesFailure = error => ({
  type: Types.DELETE_USER_PREFERENCES_FAILURE,
  error,
});

export const listUserPreferencesRequest = () => ({
  type: Types.LIST_USER_PREFERENCES_REQUEST,
});

export const listUserPreferencesSuccess = payload => ({
  type: Types.LIST_USER_PREFERENCES_SUCCESS,
  payload,
});

export const listUserPreferencesFailure = error => ({
  type: Types.LIST_USER_PREFERENCES_FAILURE,
  error,
});
