import styled from 'styled-components';

const Header = styled.div`
  max-width: 100%;
  width: 100%;
  margin-bottom: 1.5rem;

  & > p {
    margin: 0;
  }

  & > h3 {
    margin-bottom: 0.5rem;
  }
`;

const Title = styled.h3`
  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: ${({ color }) => color ?? '#2274a5'};
`;

const Description = styled.p`
  margin: 0;
  font: 400 normal 0.875rem/1.25em 'Roboto', sans-serif;
  color: ${({ color }) => color ?? '#575756'};
`;

const SubTitle = styled(Description)`
  margin: 0 0 0.5rem 0;
  font-weight: 500;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child):after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #c4c4c4;
    margin: 1.125rem 0;
  }
`;

const Informations = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 0.875rem;
`;

const Icon = styled.img`
  width: 36px;
  height: 36px;
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;

  & > h3 {
    margin-bottom: 0.375rem;
  }
`;

export const StyleModal = {
  Header,
  Title,
  Description,
  SubTitle,
  Content,
  Informations,
  Icon,
  Texts,
};
