import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../../abstract';

const getTag = level => {
  if (level >= 1 && level <= 6) {
    return `h${parseInt(level, 10)}`;
  }

  return 'h2';
};

export const Heading = ({ children, level, ...props }) => (
  <Text
    fontWeight="headings.medium"
    lineHeight="headings"
    fontFamily="headings"
    {...props}
    as={getTag(level)}
  >
    {children}
  </Text>
);

Heading.propTypes = {
  level: PropTypes.number.isRequired,
  children: PropTypes.node,
};

Heading.defaultProps = {
  level: 2,
};
