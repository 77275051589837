import React, { useState, useLayoutEffect, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, keyBy, mapValues } from 'lodash';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { Layout, Loading } from '../components';
import { ListsTitle } from './AuctionsPage';
import { LabeledCheckBoxWithIcon } from '../components/filters/components/LabeledCheckBoxWithIcon';

import {
  car_check,
  category_car,
  category_like,
  category_sale,
  category_location,
  category_timer,
  category_fipe70k,
  category_fipe100k,
} from '../images';

import { SetRecommendedCarsNotificationsCount } from '../store/actions/auction.actions';

import { preferencesLabel } from '../constants/preferencesLabel';
import {
  createUserPreferences,
  deleteUserPreferences,
  listUserPreferences,
  updateUserPreferences,
} from '../store/orderCars/effects';
import { enableTooltip } from '../store/tooltip/actions';
import { FaEdit } from 'react-icons/fa';
import { DealershipSelect, NewSelect as Select } from '../components/NewSelect';
import { listOfYears } from '../utils/listOfYears';
import ICSlider from '../components/filters/components/ic-slider';
import { useAsync } from 'react-use';
import { fetchMarks } from '../services/marks';
import { transformMarks } from '../utils/marks';
import SelectWithSearch from '../components/filters/components/select-with-search';
import { invertKeysToValues } from '../utils/array';
import { fetchRelatedDealers } from '../store/actions/user.actions';
import { RecommendedIcon } from '../components/Icons';

const preferenceObj = invertKeysToValues(preferencesLabel);

const formatRequest = obj =>
  mapValues(
    keyBy(obj, o => preferenceObj[o.name]),
    'isActive'
  );

const sortName = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comp = 0;
  if (nameA > nameB) {
    comp = 1;
  } else if (nameA < nameB) {
    comp = -1;
  }
  return comp;
};

const Button = styled.button`
  padding: 6px 32px;
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  &:disabled {
    background-color: #c4c4c4;
  }

  ${({ variant }) =>
    variant === 'outlined'
      ? css`
          font-weight: 400;
          background: transparent;
          border: 1px solid #2274a5;
          color: #2274a5;
          width: 42%;
        `
      : css`
          font-weight: 700;
          background: #22aa52;
          border: none;
          color: #ffffff;
          width: 54%;
        `}
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  align-self: center;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: 20px;
  cursor: pointer;
`;

const NewTypography = styled.span`
  font-family: ${({ fontFamily }) => fontFamily ?? 'Roboto'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  font-size: ${({ fontSize }) => fontSize ?? 18}px;
  color: ${({ color }) => color ?? '#2274a5'};
`;

const MyDivider = () => <Box height={'1px'} sx={{ backgroundColor: '#D9D9D9' }} />;

const MyTypography = ({
  fontFamily = 'Roboto',
  color = '#575756',
  fontSize = 20,
  fontWeight = 700,
  children,
}) => (
  <Typography
    fontFamily={fontFamily}
    color={color}
    fontSize={fontSize}
    fontWeight={fontWeight}
    marginTop={3}
    marginBottom={2}
  >
    {children}
  </Typography>
);

const PREFERENCES = [
  { name: 'cautelar', label: 'Cautelar Aprovada', imgSrc: car_check },
  /*   { name: 'score80', label: 'Score acima de 80%', imgSrc: category_like }, */
  { name: 'before100k', label: 'Até 100.000 Km', imgSrc: category_car },
  { name: 'fipe70', label: '70% da FIPE', imgSrc: category_sale },
  { name: 'fipe70k', label: 'FIPE acima de R$ 70.000', imgSrc: category_fipe70k },
  { name: 'fipe100k', label: 'FIPE acima de R$ 100.000', imgSrc: category_fipe100k },
  { name: 'closeToEnd', label: 'Próximos a encerrar', imgSrc: category_timer },
  { name: 'uf', label: 'Na sua região (UF)', imgSrc: category_location },
];

const RECEIVER = [
  { name: 'whatsapp', label: 'WhatsApp' },
  { name: 'email', label: 'E-mail' },
  { name: 'push_notification', label: 'Notificações no App' },
];

const PREFERENCES_ORIGINAL_STATE = {
  cautelar: false,
  before100k: false,
  fipe70: false,
  score80: false,
  closeToEnd: false,
  uf: false,
  fipe70k: false,
  fipe100k: false,
  brands: false,
  year: false,
  km: false,
};

const RECEIVER_ORIGINAL_STATE = {
  whatsapp: false,
  email: false,
  push_notification: false,
};

export function PreferencesPage() {
  const [preferences, setPreferences] = useState(PREFERENCES_ORIGINAL_STATE);
  const [receivers, setReceivers] = useState(RECEIVER_ORIGINAL_STATE);
  const [loading, setLoading] = useState(false);
  const [showAllPreferences, setShowAllPreferences] = useState(false);
  const [selectedKm, setSelectedKm] = useState(0);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedDealer, setSelectedDealer] = useState(null);

  const {
    loaded: userPreferencesLoaded,
    userPreferences,
    loading: userPreferencesLoading,
  } = useSelector(state => state.orderCars);
  const { user } = useSelector(state => state.authentication);
  const { relatedDealers, relatedDealersLoading } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const history = useHistory();
  const isMobileSize = useMediaQuery('(max-width: 832px)');

  const hero = user.isNinja()
    ? {
        title: 'Recomendados para você - Ativar/alterar preferências',
        preferences: 'Selecione as características de interesse da loja',
        km: 'KM máxima de veículos',
        brand: 'Selecione as marcas',
        notifications: 'Como a loja deseja ser avisada?',
      }
    : {
        title: 'Alterar preferências de ofertas',
        preferences: 'Características selecionadas:',
        km: 'KM máxima de veículos do seu interesse',
        brand: 'Selecione as marcas do seu interesse',
        notifications: 'Como você deseja ser avisado?',
      };

  const brands = useAsync(async () => {
    const b = await fetchMarks();
    return transformMarks(b);
  });

  const onReceiverClick = ({ target: { name } }) =>
    setReceivers(prev => ({ ...prev, [name]: !prev[name] }));

  const formatObj = obj =>
    Object.entries(obj).map(([name, isActive]) => {
      if (name === 'brands') {
        return {
          name: preferencesLabel[name],
          value: selectedBrands,
          isActive,
        };
      }

      if (name === 'km') {
        return {
          name: preferencesLabel[name],
          value: selectedKm,
          isActive,
        };
      }

      if (name === 'year') {
        return {
          name: preferencesLabel[name],
          value: Number(selectedYear),
          isActive,
        };
      }

      return { name: preferencesLabel[name], isActive };
    });

  const onClickSave = async () => {
    setLoading(true);

    const formattedPreferences = formatObj({ ...preferences }).sort(sortName);
    const sorttedPreferences = [...(userPreferences?.preferences || [])].sort(sortName);

    const preferencesAreEqual = isEqual(formattedPreferences, sorttedPreferences);
    const receiversAreEqual = isEqual(receivers, {
      ...RECEIVER_ORIGINAL_STATE,
      ...userPreferences?.channelOfContact,
    });

    const preferencesAreSelected = Object.values(preferences).some(v => v);

    const objectFormat = obj =>
      Object.entries(obj).map(([name, isActive]) => ({
        name: preferencesLabel[name],
        isActive,
      }));

    const channelOfContactFormatted = objectFormat({ ...receivers });
    const id = !!selectedDealer ? selectedDealer : user.id;
    // eslint-disable-next-line prettier/prettier
    const relatedNinja = user.isNinja() ? (id === user.id ? '' : user?.email) : '';

    try {
      if (user.isNinja() && !userPreferences?.preferences) {
        await dispatch(
          createUserPreferences(id, channelOfContactFormatted, formattedPreferences, relatedNinja)
        );
        setLoading(false);
        history.push('/');
      } else if (preferencesAreEqual && receiversAreEqual) {
        history.goBack();
      } else if (preferencesAreSelected) {
        await dispatch(
          updateUserPreferences(
            userPreferences.id,
            id,
            channelOfContactFormatted,
            formattedPreferences,
            relatedNinja
          )
        );
        setLoading(false);
        history.push('/');
      } else {
        await dispatch(deleteUserPreferences(userPreferences.id));
        await dispatch(SetRecommendedCarsNotificationsCount());
        dispatch(enableTooltip({ messageId: 9 }));
        setLoading(false);
        history.push('/');
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const usePreferences = useCallback(() => {
    if (showAllPreferences) {
      return PREFERENCES.map(res => (
        <>
          <Box height={64} display="flex" alignItems="center">
            <LabeledCheckBoxWithIcon
              name={res.name}
              label={res.label}
              imgSrc={res.imgSrc}
              checked={preferences[res.name]}
              showNumber={false}
              onClick={() => setPreferences(prev => ({ ...prev, [res.name]: !prev[res.name] }))}
            />
          </Box>
          <MyDivider />
        </>
      ));
    }

    return PREFERENCES.map(
      res =>
        !!preferences[res.name] && (
          <>
            <Box height={64} display="flex" alignItems="center" gap="0 10px">
              <img src={res.imgSrc} />
              <NewTypography fontFamily="Nunito" color="#2f3741">
                {res.label}
              </NewTypography>
            </Box>
            <MyDivider />
          </>
        )
    );
  }, [preferences, showAllPreferences]);

  useLayoutEffect(() => {
    const brandsPreference =
      Array.isArray(userPreferences) &&
      userPreferences?.preferences?.find(({ name }) => name === 'BRANDS');
    const kmPreference =
      Array.isArray(userPreferences) &&
      userPreferences?.preferences?.find(({ name }) => name === 'KM');
    const yearPreference =
      Array.isArray(userPreferences) &&
      userPreferences?.preferences?.find(({ name }) => name === 'YEAR');

    if (!userPreferences?.preferences) {
      setPreferences(PREFERENCES_ORIGINAL_STATE);
      setReceivers(RECEIVER_ORIGINAL_STATE);
      setSelectedBrands([]);
      setSelectedKm(0);
      setSelectedYear(0);
    } else {
      if (!brandsPreference && !kmPreference && !yearPreference) {
        setSelectedBrands([]);
        setSelectedKm(0);
        setSelectedYear(0);
      } else if (!brandsPreference) setSelectedBrands([]);
      else if (!kmPreference) setSelectedKm(0);
      else if (!yearPreference) setSelectedYear(0);

      const newObj = formatRequest(userPreferences?.preferences);
      setPreferences(prev => ({ ...prev, ...newObj }));
      setReceivers(prev => ({ ...prev, ...userPreferences?.channelOfContact }));
    }
  }, [userPreferences]);

  useEffect(() => {
    if (!userPreferencesLoaded) dispatch(listUserPreferences(user.id));
  }, []);

  useEffect(() => {
    if (!!selectedDealer) dispatch(listUserPreferences(selectedDealer));
  }, [selectedDealer]);

  useEffect(() => {
    const { preferences } = userPreferences || {};

    if (!!preferences?.length)
      for (let preference of preferences) {
        const { name, value } = preference;

        if (name === 'BRANDS') {
          setSelectedBrands(value);
        }

        if (name === 'KM') {
          setSelectedKm(value);
        }

        if (name === 'YEAR') {
          setSelectedYear(value);
        }
      }
  }, [userPreferences]);

  useEffect(() => {
    const isArray = !!selectedBrands?.length;

    if (isArray) {
      setPreferences(oldState => ({ ...oldState, brands: true }));
    } else {
      setPreferences(oldState => ({ ...oldState, brands: false }));
    }
  }, [selectedBrands]);

  useEffect(() => {
    if (selectedKm !== 0) {
      setPreferences(oldState => ({ ...oldState, km: true }));
    } else {
      setPreferences(oldState => ({ ...oldState, km: false }));
    }
  }, [selectedKm]);

  useEffect(() => {
    const year = Number(selectedYear);

    if (year !== 0) {
      setPreferences(oldState => ({ ...oldState, year: true }));
    } else {
      setPreferences(oldState => ({ ...oldState, year: false }));
    }
  }, [selectedYear]);

  useEffect(() => {
    if (preferences.before100k) setSelectedKm(0);
  }, [preferences.before100k]);

  useEffect(() => {
    if (user.isNinja()) {
      dispatch(fetchRelatedDealers());
      setShowAllPreferences(true);
    }
  }, []);

  const load = userPreferencesLoading || relatedDealersLoading;

  return (
    <Layout>
      {!user.isNinja() && !userPreferencesLoaded ? (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
          <Loading />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={1300}
          width="100%"
          marginY={8}
          marginX="auto"
          paddingX={3}
        >
          <Box display="flex" flexDirection="column" gap="32px 0">
            <ListsTitle>
              <RecommendedIcon color="#22aa52" />
              <NewTypography fontSize={20} color="#575756">
                {hero.title}
              </NewTypography>
            </ListsTitle>
            {user.isNinja() && (
              <Box maxWidth={510} width="100%">
                <DealershipSelect
                  placeholder="Selecionar loja"
                  list={relatedDealers}
                  value={selectedDealer}
                  onChange={evt => setSelectedDealer(evt.target.value)}
                />
              </Box>
            )}
            <MyDivider />
          </Box>

          {user.isNinja() && load ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
            >
              <Loading />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={isMobileSize ? 'column' : 'row'}
            >
              <Box width={isMobileSize ? '100%' : '45%'}>
                <MyTypography>{hero.preferences}</MyTypography>

                <Box display="flex" flexDirection="column" gap={isMobileSize ? '40px 0' : '52px 0'}>
                  <Box display="flex" flexDirection="column">
                    {usePreferences()}{' '}
                    {!user.isNinja() && (
                      <EditButton onClick={() => setShowAllPreferences(!showAllPreferences)}>
                        <FaEdit size={18} fill="#2274a5" />
                        <NewTypography>Editar características selecionadas</NewTypography>
                      </EditButton>
                    )}
                  </Box>

                  <Box>
                    <MyDivider />
                    <MyTypography>{hero.km}</MyTypography>
                    <ICSlider
                      min={0}
                      max={400_000}
                      name="km"
                      onChange={evt => {
                        setSelectedKm(evt.target.value);
                        setPreferences(oldState => ({ ...oldState, before100k: false }));
                      }}
                      step={25_000}
                      value={selectedKm}
                      previewSuffix="Km"
                      fontFamily="Roboto"
                    />
                  </Box>

                  <Box marginBottom={isMobileSize ? 3 : 0}>
                    <MyDivider />
                    <MyTypography>Selecione veículos com até</MyTypography>
                    <Select
                      placeholder="Selecione o ano"
                      list={listOfYears}
                      value={selectedYear}
                      onChange={evt => setSelectedYear(evt.target.value)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                width={isMobileSize ? '100%' : '45%'}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {isMobileSize ? <MyDivider /> : null}
                <Box marginBottom={6.5}>
                  <MyTypography>{hero.brand}</MyTypography>
                  {!brands.loading && (
                    <SelectWithSearch
                      name="brands"
                      type="image"
                      data={brands.value}
                      presetValue={selectedBrands}
                      onChange={evt => setSelectedBrands(evt.target.value)}
                      fontFamily="Roboto"
                    />
                  )}
                </Box>

                <Box marginBottom={4}>
                  <MyTypography>{hero.notifications}</MyTypography>
                  <Box display="flex" flexDirection="column">
                    <Box>
                      <Box height={64} display="flex" alignItems="center">
                        <LabeledCheckBoxWithIcon
                          name="none"
                          label="Não quero receber notificações"
                          checked={!Object.values(receivers).some(v => v)}
                          showNumber={false}
                          onClick={() => setReceivers(RECEIVER_ORIGINAL_STATE)}
                          fontFamily="Roboto"
                          fontWeight={400}
                        />
                      </Box>
                      <MyDivider />
                    </Box>

                    {RECEIVER.map(res => (
                      <Box key={res.name}>
                        <Box height={64} display="flex" alignItems="center">
                          <LabeledCheckBoxWithIcon
                            name={res.name}
                            label={res.label}
                            checked={receivers[res.name]}
                            showNumber={false}
                            onClick={onReceiverClick}
                            fontFamily="Roboto"
                            fontWeight={400}
                          />
                        </Box>
                        <MyDivider />
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={isMobileSize ? 'column' : 'row'}
                  rowGap={2}
                >
                  <Button
                    style={{ width: isMobileSize ? '100%' : '42%' }}
                    isMobileSize={isMobileSize}
                    variant="outlined"
                    onClick={() => history.goBack()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    style={{ width: isMobileSize ? '100%' : '54%' }}
                    isMobileSize={isMobileSize}
                    onClick={onClickSave}
                    disabled={loading}
                  >
                    {loading ? 'Salvando...' : 'Salvar'}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Layout>
  );
}
