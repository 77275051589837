import { rem } from 'polished';

export const fonts = {
  headings: "'Rubik', sans-serif", // NOTE: This is used for headlines only.
  paragraphs: "'Nunito', sans-serif", // NOTE: This is used for all text inside body only.
};

export const fontSizes = [12, 14, 16, 18, 20, 24, 28, 36, 42, 48, 54, 60].map(n => rem(n));

export const fontWeights = {
  headings: {
    regular: 400,
    medium: 500,
  },
  paragraphs: {
    regular: 400,
    medium: 600,
    bold: 700,
  },
};

export const lineHeights = {
  headings: 1.25,
  paragraphs: 1.5,
};
