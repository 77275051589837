import React from 'react';
import { MdNotificationsNone } from 'react-icons/md';
import { connect } from 'react-redux';

import { Container, Badge } from './styles';

function NotificationTab({ notificationsCount }) {
  return (
    <Container>
      {notificationsCount > 0 && <Badge>{notificationsCount}</Badge>}
      <MdNotificationsNone />
    </Container>
  );
}

function mapStateToProps({ ui }) {
  return {
    notificationsCount: ui.notificationsCount,
  };
}

export default connect(mapStateToProps)(NotificationTab);
