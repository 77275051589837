import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default {
  default: css`
    box-shadow: ${({ theme }) => theme.shadows.cardDefault};
    border-radius: ${({ theme }) => theme.radii.default};
  `,
  flat: css`
    border-radius: ${themeGet('radii.default')};
  `,
};
