import styled from 'styled-components';

export const Content = styled.div`
  @media screen and (min-width: 768px) {
    order: 0;
  }
  box-sizing: border-box;
  background-color: ${({ bg }) => bg ?? 'rgba(0, 0, 0, 0.5)'};
  padding: 6px;
  border-radius: 22px;
  display: flex;
  gap: 0 8px;
  align-items: center;
  z-index: 2;
`;

export const Typography = styled.span`
  font: 400 normal 14px/1.5em 'Roboto', sans-serif;
  color: ${({ color }) => color ?? '#fff'};
`;
