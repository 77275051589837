import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Layout } from '../../components';
import { Card, Link, Text, Flex, Box } from '../../components/abstract';
import { Heading, Button } from '../../components/enhanced';
import { Input } from '../../components/enhanced/ui';
import styled from 'styled-components';

const TermsLink = styled.a`
  color: #2274a5;
  text-decoration: underline;
`;

const Label = styled.label`
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

const Base = ({
  title,
  subtitle,
  CTA,
  errorMessage,
  links,
  inputs,
  error,
  loading,
  onSubmit,
  disabled,
  icon,
  onChangeCheckBox,
  valueCheckBox,
  authErrorMessage,
}) => {
  return (
    <Layout>
      <Flex
        alignItems="center"
        justifyContent="center"
        className="page"
        style={{ padding: '50px 0' }}
      >
        <Card p={3} maxWidth={400} style={{ margin: 'auto' }}>
          {icon && (
            <Text color="secondaries.Uno" fontSize={11} lineHeight={0.5}>
              {icon}
            </Text>
          )}
          <Heading color="neutrals.EclipseE700" fontSize={6}>
            {title}
          </Heading>
          <Text color="neutrals.EclipseE400" fontSize={2}>
            {subtitle}
          </Text>
          {inputs && (
            <form onSubmit={onSubmit}>
              {inputs.map((el, i) => (
                <React.Fragment key={i}>
                  <Text
                    color={
                      error && authErrorMessage === 'Invalid username' && el.label === 'E-mail'
                        ? 'alerts.Ferrari'
                        : error && authErrorMessage === 'Invalid password' && el.label === 'Senha'
                        ? 'alerts.Ferrari'
                        : authErrorMessage === 'User does not exist' ||
                          authErrorMessage === 'User is not active'
                        ? 'alerts.Ferrari'
                        : 'neutrals.EclipseE500'
                    }
                    fontWeight="paragraphs.bold"
                    fontSize={1}
                    style={{ padding: '15px 0 5px 0' }}
                  >
                    {el.label}
                  </Text>
                  <Input
                    value={el.value}
                    type={el.type}
                    label={el.label}
                    name={el.name}
                    placeholder={el.placeholder}
                    onChange={el.onChange}
                    spellCheck="false"
                    required
                    error={error}
                    errorMessage={authErrorMessage}
                  />
                  {error &&
                    (authErrorMessage === 'Invalid username' ? (
                      <Text
                        color={'alerts.Ferrari'}
                        fontSize={1}
                        style={{ padding: '5px 0 5px 0' }}
                      >
                        {el.label === 'E-mail'
                          ? 'Ops! Verifique se o e-mail inserido está correto.'
                          : null}
                      </Text>
                    ) : (
                      <Text
                        color={'alerts.Ferrari'}
                        fontSize={1}
                        style={{ padding: '5px 0 5px 0' }}
                      >
                        {authErrorMessage === 'Invalid password' && el.label === 'Senha'
                          ? 'Ops! Verifique se sua senha inserida está correta.'
                          : null}
                      </Text>
                    ))}
                </React.Fragment>
              ))}
              {error && (
                <Text color="alerts.Ferrari" fontSize={1}>
                  {authErrorMessage === 'User does not exist'
                    ? 'Usuário não existe.'
                    : authErrorMessage === 'User is not active'
                    ? 'Usuário desativado.'
                    : null}
                </Text>
              )}
              <div style={{ marginTop: rem(20) }}>
                <input
                  name="terms"
                  id="terms"
                  type="checkbox"
                  onChange={onChangeCheckBox}
                  checked={valueCheckBox}
                />
                <Label for="terms">
                  Li e aceito os{' '}
                  <TermsLink
                    href="https://sandbox-api.instacarro.com/au_queries/v1/files/get-by-name/termo-compra.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos e condições
                  </TermsLink>{' '}
                  e{' '}
                  <TermsLink
                    href="https://sandbox-api.instacarro.com/au_queries/v1/files/get-by-name/polices.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Política de devolução
                  </TermsLink>
                  .
                </Label>
              </div>
              {error && (
                <Text color="alerts.Ferrari" fontSize={2} style={{ padding: '15px 0 5px 0' }}>
                  {errorMessage}
                </Text>
              )}
              <Button
                isLoading={loading}
                disabled={disabled}
                width={1}
                type="submit"
                style={{
                  marginTop: rem(15),
                  fontWeight: 'bold',
                }}
              >
                {CTA}
              </Button>
            </form>
          )}
          <Flex justifyContent={links.length > 1 && 'space-around'} style={{ marginTop: '15px' }}>
            {links.map((el, i) => (
              <div key={i}>
                <Text color="neutrals.EclipseE700" fontSize={1}>
                  {el.text}
                </Text>
                <Box as={Link} color="secondaries.Uno" {...el}>
                  {el.label}
                </Box>
              </div>
            ))}
          </Flex>
        </Card>
      </Flex>
    </Layout>
  );
};

Base.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  errorMessage: PropTypes.string,
  CTA: PropTypes.string,
  icon: PropTypes.any,
  links: PropTypes.array,
  inputs: PropTypes.array,
  error: PropTypes.any,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export { Base };
