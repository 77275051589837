import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { resetPassword } from '../../utils/fecth-wrappers';
import { MdDone } from 'react-icons/md';
import { Base } from './';

export const ERROR_MESSAGES = {
  passwordsDoNotMatch: 'as senhas são diferentes',
  errorToken: 'Erro ao alterar a senha, verifique se o link está correto',
};
const ResetPasswordPage = ({ resetPassword }) => {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [state, setState] = useState('default');
  const [error, setError] = useState();
  const location = useLocation();

  const handleSubmit = e => {
    e.preventDefault();
    const error = passwordRepeat !== password;
    const { token } = queryString.parse(location.search);
    setState('loading');
    if (!error) {
      resetPassword(password, token).then(
        () => {
          setState('success');
        },
        () => {
          setState('error');
          setError(ERROR_MESSAGES.errorToken);
        }
      );
    } else {
      setState('error');
      setError(ERROR_MESSAGES.passwordsDoNotMatch);
    }
  };

  const handlePassword = e => setPassword(e.target.value);
  const handlePasswordRepeat = e => setPasswordRepeat(e.target.value);

  return (
    <Base
      title={state === 'success' ? 'Senha alterada com successo!' : 'Crie uma nova senha'}
      subtitle={
        state === 2
          ? 'Não se esqueça de anotar a nova senha. Agora é só voltar e participar dos leilões normalmente. ;)'
          : 'Utilize números e caracteres especiais para garantir mais segurança para sua conta.'
      }
      CTA="Alterar senha"
      errorMessage={error}
      links={
        state === 2
          ? []
          : [
              {
                text: '',
                href: '/login',
                label: 'Voltar para o login',
              },
            ]
      }
      inputs={
        state === 'success'
          ? null
          : [
              {
                name: 'password',
                value: password,
                type: 'password',
                label: ' Nova senha',
                placeholder: 'Mínimo de 8 caracteres',
                minLength: 8,
                onChange: handlePassword,
              },
              {
                name: 'passwordRepeat',
                value: passwordRepeat,
                type: 'password',
                label: 'Confirme a nova senha',
                placeholder: 'Repita a senha digitada acima',
                minLength: 8,
                onChange: handlePasswordRepeat,
              },
            ]
      }
      error={state === 'error'}
      loading={state === 'loading'}
      onSubmit={handleSubmit}
      disabled={password.length < 8 || passwordRepeat.length < 8}
      icon={state === 'success' && <MdDone />}
    />
  );
};

ResetPasswordPage.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

ResetPasswordPage.defaultProps = {
  resetPassword,
};

export { ResetPasswordPage };
