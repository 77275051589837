import AppConstants from '../constants/appConstants';
import { getLocalUser } from '../utils/auth';
import { authGet, authPut } from '../utils/fecth-wrappers';

class UrlService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get myProfile() {
    const user = getLocalUser();
    return `${this.baseUrl}/v1/dealers/${user.getId()}`;
  }

  get updateProfile() {
    return `${this.baseUrl}/v1/dealers/my/profile`;
  }
}

export class UserProfileService {
  constructor(baseApiUrl) {
    this.urls = new UrlService(baseApiUrl);
  }

  async updateProfile({ phoneNumber, companyPhone }) {
    const request = await authPut(this.urls.updateProfile, {
      companyPhone,
      personalPhone: phoneNumber,
    });
    return request.data.data;
  }

  async getCurrentUserProfile() {
    const request = await authGet(this.urls.myProfile);
    return request.data.data;
  }
}

export const userProfileService = new UserProfileService(
  AppConstants.baseURLs.baseIcApiDealers,
  AppConstants.USER_PROFILE_TTL_IN_DAYS
);
