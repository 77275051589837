import get from 'lodash/get';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Cell as RSuiteCell } from 'rsuite-table';
import { useTimer } from '../../../hooks/useTimer';

const TimerCell = memo(({ rowData, formatter, startedTimeStamp, dataKey, ...rest }) => {
  useSelector(state => state?.timer?.elapsedTime);
  return <RSuiteCell {...rest}>{formatter(get(rowData, 'currentAuctionEndTime'))}</RSuiteCell>;
});

const TimerCellDuration = memo(({ rowData, formatter, startedTimeStamp, dataKey, ...rest }) => {
  const auctionDuration = useMemo(() => get(rowData, 'remainingTime', 0), [rowData?.remainingTime]);
  const { formattedRemainingTime } = useTimer(auctionDuration);
  return <RSuiteCell {...rest}>{formattedRemainingTime}</RSuiteCell>;
});

const FormattedCell = ({ rowData, formatter, dataKey, ...rest }) => (
  <RSuiteCell {...rest}>{formatter(get(rowData, dataKey), rowData)}</RSuiteCell>
);

const LinkCell = ({ rowData, formatter, getUrl, target = '_blank', dataKey, ...rest }) => (
  <RSuiteCell {...rest}>
    <a target={target} href={getUrl(rowData)}>
      {formatter ? formatter(get(rowData, dataKey), rowData) : get(rowData, dataKey)}
    </a>
  </RSuiteCell>
);

const Cell = ({ type, ...props }) => {
  switch (type) {
    case 'link':
      return <LinkCell {...props} />;
    case 'formatter':
      return <FormattedCell {...props} />;
    case 'timer':
      return <TimerCell {...props} />;
    case 'duration':
      return <TimerCellDuration {...props} />;

    default:
      return <RSuiteCell {...props} />;
  }
};

export default Cell;
