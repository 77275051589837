import { useCallback, useEffect, useMemo, useState } from 'react';
import { find } from 'lodash';
import { formatPrice } from '../utils/formatPrice';

export const useTemperature = (auction, currentValue) => {
  const hotPrice = formatPrice(auction?.summary?.vehiclePricing?.hotTarget);

  const temperatures = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Baixa chance',
        icon: {
          participate: require('../images/temperature/card-participate/baixa-16px.svg'),
          details: require('../images/temperature/details-participating/baixa-16px.svg'),
          modal: require('../images/temperature/modal-feedback/baixa-49px.svg'),
          slider: {
            details: require('../images/temperature/slider-details-participating/baixa-slider.svg'),
            participate: require('../images/temperature/slider-participate/baixa-slider.svg'),
          },
          toast: require('../images/temperature/toast/baixa-22px.svg'),
        },
        description: `Invista em lances acima de R$ ${hotPrice} para comprar mais rápido!`,
        color: '#6b0874',
        bgColor: '#fef5ff',
      },
      {
        id: 2,
        title: 'Média chance',
        icon: {
          participate: require('../images/temperature/card-participate/media-16px.svg'),
          details: require('../images/temperature/details-participating/media-16px.svg'),
          modal: require('../images/temperature/modal-feedback/media-49px.svg'),
          slider: {
            details: require('../images/temperature/slider-details-participating/media-slider.svg'),
            participate: require('../images/temperature/slider-participate/media-slider.svg'),
          },
          toast: require('../images/temperature/toast/media-22px.svg'),
        },
        description: `Invista em lances acima de R$ ${hotPrice} para comprar mais rápido!`,
        color: '#864600',
      },
      {
        id: 3,
        title: 'Alta chance',
        icon: {
          participate: require('../images/temperature/card-participate/alta-16px.svg'),
          details: require('../images/temperature/details-participating/alta-16px.svg'),
          modal: require('../images/temperature/modal-feedback/alta-49px.svg'),
          slider: {
            details: require('../images/temperature/slider-details-participating/alta-slider.svg'),
            participate: require('../images/temperature/slider-participate/alta-slider.svg'),
          },
          toast: require('../images/temperature/toast/alta-22px.svg'),
        },
        description: 'Continue participando, sua oferta possui potencial para fechar negócio!',
        color: '#137435',
        bgColor: '#f5fff9',
      },
    ];
  }, [hotPrice]);

  const [currentTemperature, setCurrentTemperature] = useState();

  const temperatureMeasurement = useCallback(
    temperatureId => {
      const selectedTemperature = find(temperatures, { id: temperatureId });

      if (!selectedTemperature) {
        throw new Error('Invalid provided temperature');
      }

      const { ...rest } = selectedTemperature;
      setCurrentTemperature(rest);
    },
    [setCurrentTemperature, temperatures]
  );

  const highChances = useMemo(() => currentValue >= auction?.summary?.vehiclePricing?.hotTarget, [
    currentValue,
    auction?.summary?.vehiclePricing?.hotTarget,
  ]);

  const lowChances = useMemo(() => currentValue < auction?.summary?.vehiclePricing?.warmTarget, [
    currentValue,
    auction?.summary?.vehiclePricing?.warmTarget,
  ]);

  useEffect(() => {
    if (highChances) {
      return temperatureMeasurement(3);
    }

    return temperatureMeasurement(lowChances ? 1 : 2);
  }, [highChances, lowChances, temperatureMeasurement]);

  return { currentTemperature, highChances, lowChances };
};
