import React from 'react';
import { Button } from './styles';
import loading from '../../../../images/icons/loading-tail.svg';
import { Image } from '../../../abstract';

const PrimaryButton = ({
  singleOffer,
  negotiateExclusivity,
  finNegotiateExclusivity,
  isLoading,
  children,
  ...props
}) => (
  <Button
    singleOffer={singleOffer}
    negotiateExclusivity={negotiateExclusivity}
    finNegotiateExclusivity={finNegotiateExclusivity}
    isLoading={isLoading}
    disabled={props.disabled}
    {...props}
  >
    {props.disabled}
    {children}
    {isLoading && <Image ml={1} src={loading} width="20px" />}
  </Button>
);

export default PrimaryButton;
