import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 2.625rem;
  width: 100%;

  font: 400 normal 16px/1em 'Roboto', sans-serif;
  background-color: #303337;
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: 160px 1fr 1fr;
  gap: 0 6rem;

  align-items: center;

  max-width: 81.25rem;
  width: 100%;

  margin-bottom: 2.625rem !important;

  @media screen and (max-width: 1281px) {
    padding: 0 0.5rem !important;
  }

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding: 0 1.5rem !important;
  }
`;

export const Logo = styled.li`
  align-self: flex-start;

  max-width: 100%;
  width: 10rem;

  @media screen and (max-width: 1025px) {
    margin-bottom: 1.25rem !important;
  }
`;

export const SectionLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 10.625rem);
  gap: 0 6rem;

  color: #fff;

  @media screen and (max-width: 1281px) {
    gap: 0 4rem;
  }

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ListLink = styled.li`
  display: flex;
  flex-direction: column;

  & > a:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  & > strong:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > * {
      margin-bottom: 1.25rem;
    }

    &:nth-child(2) {
      margin-bottom: 2rem !important;
    }

    &:nth-child(3) {
      margin-bottom: 3rem !important;
    }
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 2.25rem;
`;

export const ListIcon = styled.li`
  &:not(:last-child) {
    margin-right: 2.125rem !important;
  }

  @media screen and (max-width: 1025px) {
    margin-right: 0 !important;
  }
`;

export const ListSeals = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  & > img:not(:last-child) {
    margin-right: 2.125rem !important;
  }

  @media screen and (max-width: 1025px) {
    margin-right: 0 !important;
  }
`;

export const DevelopedBy = styled.span`
  width: 100%;
  padding: 0.75rem 0;

  color: #fff;
  text-align: center;

  background-color: #24262b;
`;
