import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  width: 100%;
  padding: ${rem(15)};
  border: 1px solid #c9cbce;
  border-radius: 4px;
  color: #2e3743;
  &:focus {
    border-color: #82878e;
    outline: none;
  }
  ${({ error, errorMessage, label }) =>
    error && errorMessage === 'Invalid username' && label === 'E-mail'
      ? `
      border-color: #F8333C;
      border-width: 2px;
    `
      : error && errorMessage === 'Invalid password' && label === 'Senha'
      ? `
      border-color: #F8333C;
      border-width: 2px;
    `
      : errorMessage === 'User does not exist' || errorMessage === 'User is not active'
      ? `
      border-color: #F8333C;
      border-width: 2px;
    `
      : `
      border-color: #c9cbce;
    `}
`;

const Input = props => <StyledInput {...props} />;
export { Input };
