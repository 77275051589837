import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '../abstract';
import { rem } from 'polished';
const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(5)};
  text-align: center;
`;
const MiniDocumentResearch = ({ label, items, ...props }) => {
  if (!items) return null;
  return (
    <Wrapper {...props}>
      <Text color="neutrals.EclipseE400" fontSize={2}>
        {label}
      </Text>
      <Text fontSize={2}>{items.join(', ')}</Text>
    </Wrapper>
  );
};
MiniDocumentResearch.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string.isRequired,
};
MiniDocumentResearch.defaultProps = {
  label: 'Restrições:',
};

export default MiniDocumentResearch;
