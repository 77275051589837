import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../enhanced/Modal';
import withMakeOffer from '../../hoc/WithMakeOffer';
import { toggleModal } from '../../../store/actions/ui.actions';
import { AUCTION_BUY_KEY, AUCTION_BUY_KEY_CONFIRMATION } from '../';
import { AuctionItemTimer } from '../../enhanced/AuctionItemTimer';
import { TimingAuctionContextProvider } from '../../../context/TimingAuctionContext';
import {
  Content,
  VehicleNameAndInfos,
  NegotiationExclusivityLabel,
  NegotiationExclusivityValue,
  ContentFin,
  TitleFinDiv,
  TitleFinText,
  FinText,
  FinTextDiv,
  FinancingInfoDiv,
  EntryValueText,
  EntryValue,
  TaxesTotalDiv,
  style,
} from './styles';
import { PrimaryButton } from '../../Buttons/old';
import { getSelectedAuctionRemainingTime } from '../../../store/selectors/auctionSelectors';
import { useBid } from '../../../hooks/useBid';
import FincreditCoin from '../../../images/icons/fincredit-coin.svg';
import { formatPrice } from '../../../utils/formatPrice';

const Component = ({ selectedAuction, show, toggle, selectedAuctionRemainingTime }) => {
  const { extras } = useSelector(state => state.ui.modals.auctionBuy);
  const { negotiationExclusivityLabel } = useBid(selectedAuction);
  const { section, isRecommended = false, showFin, fincreditInfo, unleashFinFlag } = extras || {};

  const _auction = useMemo(() => {
    return {
      ...selectedAuction.auction,
      remainingTime: selectedAuctionRemainingTime,
    };
  }, [selectedAuction, selectedAuctionRemainingTime]);

  const getWindowScreen = useMemo(() => {
    if (window.screen.width >= 1025) return 'buyNowLg';

    return window.screen.width < 1025 && window.screen.width > 414 ? 'buyNowMd' : null;
  }, [window.screen.width]);

  return (
    <TimingAuctionContextProvider auction={_auction}>
      <Modal
        toggle={() => {
          toggle({ key: AUCTION_BUY_KEY, show: false });
        }}
        show={show}
        buyNowSpecificStyle={show ? getWindowScreen : undefined}
        style={style}
      >
        <Content>
          <VehicleNameAndInfos>
            {selectedAuction?.summary?.make} {selectedAuction?.summary?.model}{' '}
            {selectedAuction?.summary?.yearLabel} - {selectedAuction?.summary?.version}{' '}
            {selectedAuction?.summary?.kmLabel}
          </VehicleNameAndInfos>

          <AuctionItemTimer showBuyNowButton={false} auction={selectedAuction} />

          <NegotiationExclusivityLabel>
            Negocie com exclusividade com a oferta:
          </NegotiationExclusivityLabel>

          <NegotiationExclusivityValue>{negotiationExclusivityLabel}</NegotiationExclusivityValue>

          <PrimaryButton
            onClick={() => {
              toggle({
                key: AUCTION_BUY_KEY_CONFIRMATION,
                show: true,
                extras: {
                  auction: selectedAuction,
                  section,
                  isRecommended,
                },
              });
              toggle({ key: AUCTION_BUY_KEY, show: false });
            }}
            negotiateExclusivity
            modal
          >
            Negociar com exclusividade
          </PrimaryButton>
        </Content>
        {showFin && fincreditInfo?.entryAmount && (
          <ContentFin>
            <TitleFinDiv>
              <img src={FincreditCoin} alt={'fincredit icon'} />
              <TitleFinText>Pagamento facilitado disponível</TitleFinText>
            </TitleFinDiv>
            <FinTextDiv>
              <FinText>
                Aproveite o pagamento facilitado para finalizar essa compra e pague agora apenas:
              </FinText>
              <FinancingInfoDiv>
                <EntryValueText>Valor de entrada</EntryValueText>
                <EntryValue>R$ {formatPrice(fincreditInfo?.entryAmount)}</EntryValue>
                <TaxesTotalDiv>
                  + {fincreditInfo?.quantityInstallments} parcelas de R${' '}
                  {formatPrice(fincreditInfo?.monthlyInstallment)}
                </TaxesTotalDiv>
              </FinancingInfoDiv>
            </FinTextDiv>
            <PrimaryButton
              onClick={() => {
                toggle({
                  key: AUCTION_BUY_KEY_CONFIRMATION,
                  show: true,
                  extras: {
                    auction: selectedAuction,
                    section,
                    isRecommended,
                    fincreditInfo,
                    showFin,
                  },
                });
                toggle({ key: AUCTION_BUY_KEY, show: false });
              }}
              finNegotiateExclusivity
              modal
            >
              Negociar e pagar com facilidade
            </PrimaryButton>
          </ContentFin>
        )}
      </Modal>
    </TimingAuctionContextProvider>
  );
};

Component.propTypes = {
  toggle: PropTypes.func.isRequired,
  selectedAuction: PropTypes.object,
  show: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  toggle: bindActionCreators(toggleModal, dispatch),
});

const mapStateToProps = state => ({
  show: state.ui.modals.auctionBuy.show,
  selectedAuctionRemainingTime: getSelectedAuctionRemainingTime(state),
});

const BuyNowModal = connect(mapStateToProps, mapDispatchToProps)(withMakeOffer(Component));
export { BuyNowModal, Component as default };
