import React from 'react';
import styled from 'styled-components';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Box } from './abstract';

const StyledButton = styled(Box)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 0px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 300ms;
  ${({ right }) => (right ? 'right: 10px;' : 'left: 10px;')};
  &:focus {
    outline: 0;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

export const CarouselArrow = ({ right, big, ...etc }) => {
  const buttonSize = big ? { sm: '2rem', md: '3rem' } : { sm: '2rem', md: '2rem' };
  const iconSize = big ? 42 : 24;
  return (
    <StyledButton as="button" right={right} width={buttonSize} height={buttonSize} {...etc}>
      {right ? <MdChevronRight size={iconSize} /> : <MdChevronLeft size={iconSize} />}
    </StyledButton>
  );
};
