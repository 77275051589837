import { ToastContainer as Container } from 'react-toastify';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import 'react-toastify/dist/ReactToastify.min.css';

const setDefaultAttributes = props => ({
  autoClose: props.autoClose >= 0 ? props.autoClose : 5000,
  position: props.position || 'top-center',
});

const ToastContainer = styled(Container).attrs(setDefaultAttributes)`
  .Toastify__toast-body {
    font-family: ${themeGet('fonts.paragraphs')};
  }

  .Toastify__toast {
    background-color: #ffffff;
  }

  .Toastify__toast--error {
    color: ${themeGet('colors.alerts.Ferrari')};
  }

  .Toastify__progress-bar--error {
    background-color: ${themeGet('colors.alerts.Ferrari')};
  }

  .Toastify__close-button--error {
    color: ${themeGet('colors.alerts.Ferrari')};
  }

  .Toastify__close-button--success {
    color: ${themeGet('colors.secondaries.Uno')};
  }

  .Toastify__toast--success {
    color: ${themeGet('colors.secondaries.Uno')};
  }

  .Toastify__progress-bar--success {
    background-color: ${themeGet('colors.secondaries.Uno')};
  }
`;

export { ToastContainer };
