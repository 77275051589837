import { getTaxIdList } from '../services/taxids';
import { useQuery } from 'react-query';

export function useDefaultProfile() {
  const query = useQuery('taxids', getTaxIdList);
  const taxids = query.data?.data?.data || [];

  const defaultProfile = taxids.find(obj => obj.default === true);

  if (taxids) {
    return defaultProfile;
  }

  return null;
}
