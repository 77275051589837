import React from 'react';
import { connect } from 'react-redux';

import ParticipatingFilters from '../..';
import { Container } from './styles';

function ParticipatingDesktopTemplate({ show }) {
  return (
    <Container className={show ? 'show' : ''}>
      <ParticipatingFilters />
    </Container>
  );
}

function mapStateToProps({ ui }) {
  return {
    show: ui.filters.showParticipatingFilters,
  };
}

export default connect(mapStateToProps)(ParticipatingDesktopTemplate);
