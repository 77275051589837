import React from 'react';
import styled, { css } from 'styled-components';
import { rem, hideVisually } from 'polished';
import PropTypes from 'prop-types';
import { MdCheck } from 'react-icons/md';
import { themeGet } from 'styled-system';

import { Text, Box } from '../../../abstract';

const Label = styled(Text).attrs({ as: 'label' })`
  display: block;
  margin: ${rem(10)} 0;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually()};
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  margin-right: ${rem(10)};
`;

const CheckIcon = styled(MdCheck)``;

const getCheckedStyles = (primaryColorKey, secondaryColorKey) => {
  return css`
    ${props =>
      props.checked &&
      css`
        background-color: ${themeGet(primaryColorKey)};
        border-color: ${themeGet(secondaryColorKey)};
      `};

    ${HiddenCheckbox}:focus + & {
      box-shadow: 0 0 3px 1px ${themeGet(secondaryColorKey)};
      border-color: ${themeGet(secondaryColorKey)};
    }
  `;
};

const StyledCheckbox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(20)};
  height: ${rem(20)};
  border: 1px solid ${props => (props.isDark ? '#ffffff' : themeGet('colors.neutrals.EclipseE200'))};
  border-radius: ${themeGet('radii.default')};
  transition: all 150ms;
  color: ${themeGet('colors.neutrals.EclipseE50')};
  background-color: transparent;
  ${({ isSecondary }) => {
    if (isSecondary) {
      return getCheckedStyles('colors.secondaries.Uno', 'colors.secondaries.UnoU300');
    }

    return getCheckedStyles('colors.primaries.Soul', 'colors.primaries.SoulS300');
  }};

  ${CheckIcon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const Checkbox = React.memo(
  ({ label, checked, isSecondary, isDark, labelProps, ...props }) => {
    return (
      <Label fontWeight={checked ? 'paragraphs.bold' : 'paragraphs.regular'} {...labelProps}>
        <CheckboxContainer>
          <HiddenCheckbox checked={checked} {...props} />
          <StyledCheckbox checked={checked} isDark={isDark} isSecondary={isSecondary}>
            <CheckIcon size={12} />
          </StyledCheckbox>
        </CheckboxContainer>
        {label}
      </Label>
    );
  }
);

Checkbox.propTypes = {
  label: PropTypes.node,
  checked: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isDark: PropTypes.bool,
  labelProps: PropTypes.object,
};
