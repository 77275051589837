import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  cursor: pointer;

  .group-title {
    font: 700 normal 20px/16px 'Nunito', sans-serif;
    color: #2f3741;
  }

  .dropdown-list-title {
    font: 700 normal 16px/16px 'Nunito', sans-serif;
    color: #2f3741;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 97%;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;

  .card-image {
    max-height: 100%;
    height: 164px;
    cursor: pointer;
  }

  .card-description {
    padding: 8px;
    font: 700 normal 12px/16px 'Roboto', sans-serif;
    color: #2f3741;
  }

  .resize-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 20px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
    border-top-right-radius: 6px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 97%;
    width: 100%;
  }
`;

const WithoutImage = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px 80px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

  .onlyText {
    padding: 12px 8px;
    font: 400 normal 14px/20px 'Roboto', sans-serif;
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 95%;
  }
`;

export const S = {
  Container,
  Title,
  CarouselContainer,
  Card,
  WithoutImage,
};
