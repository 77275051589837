import React, { useState, useCallback } from 'react';
import {
  Container,
  List,
  Logo,
  SectionLinks,
  ListLink,
  FlexColumn,
  FlexRow,
  ListIcon,
  ListSeals,
  DevelopedBy,
} from './styles';

import links from '../../../data/links-footer.json';
import { medias } from '../../../data/medias';

import logo from '../../../images/logo-white.svg';
import godaddy from '../../../images/godaddy.svg';
import mcAfee from '../../../images/mcAfee.svg';

const Footer = () => {
  const [size, setSize] = useState(window.screen.width);

  window.addEventListener('resize', () => setSize(window.screen.width));

  const useLayout = useCallback(
    (name, link) => {
      if (size <= 1024) {
        if (name.includes('Termos') || name.includes('Política')) {
          return null;
        }
      }

      return (
        <a key={name} href={link} target="_blank" referrerPolicy="no-referrer">
          {name}
        </a>
      );
    },
    [size]
  );

  return (
    <Container>
      <List>
        <Logo>
          <a href="/" title="InstaCarro">
            <img src={logo} alt="Logo InstaCarro" />
          </a>
        </Logo>

        <SectionLinks>
          {links.map(({ title, items }, index) => (
            <ListLink key={index}>
              {title ? <strong>{title}</strong> : null}

              {items.map(({ name, link }) => useLayout(name, link))}
            </ListLink>
          ))}
        </SectionLinks>

        <FlexColumn>
          <FlexRow>
            {medias.map(({ title, link, icon: Icon }) => (
              <ListIcon key={title}>
                <a title={title} href={link} target="_blank" referrerPolicy="no-referrer">
                  <Icon />
                </a>
              </ListIcon>
            ))}
          </FlexRow>

          <ListSeals>
            <img title="GoDaddy" src={godaddy} alt="Selo de segurança GoDaddy" />
            <img title="McAfee Secure" src={mcAfee} alt="Selo de segurança McAfee" />
          </ListSeals>
        </FlexColumn>
      </List>

      <DevelopedBy>Desenvolvido pela © InstaCarro</DevelopedBy>
    </Container>
  );
};

export default Footer;
