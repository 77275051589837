import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../../abstract';

const getMarginProp = isRight => {
  if (isRight) {
    return {
      ml: 1,
    };
  }

  return {
    mr: 1,
  };
};

export const Icon = ({ isRight, children }) => (
  <Text as="span" lineHeight="0" className="button-icon" {...getMarginProp(isRight)}>
    {children}
  </Text>
);

Icon.propTypes = {
  isRight: PropTypes.bool,
  children: PropTypes.element,
};
