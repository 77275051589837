import { useMemo } from 'react';

export function useBid(auction) {
  const currentOffer = useMemo(() => auction?.auction?.winningBid?.amount || 0, [
    auction?.auction?.winningBid?.amount,
  ]);

  const currentOfferLabel = useMemo(
    () =>
      new Intl.NumberFormat('pt-br', {
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Math.ceil(auction?.auction?.winningBid?.amount)),
    [auction?.auction?.winningBid?.amount]
  );

  const negotiationExclusivityLabel = useMemo(
    () =>
      new Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Math.ceil(auction?.summary?.buynowPrice?.amount)),
    [auction?.summary?.buynowPrice?.amount]
  );

  return {
    currentOffer,
    currentOfferLabel,
    negotiationExclusivityLabel,
  };
}
