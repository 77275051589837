import React from 'react';
import PropTypes from 'prop-types';

import { Text, Box } from '../../abstract';
import { Heading } from '../';

export const Price = ({ value = 0, label, ...etc }) => (
  <Box fontSize={5} {...etc}>
    {label && (
      <Box as="span" fontSize="0.6em" css={{ display: 'block', opacity: 0.7 }}>
        {label}
      </Box>
    )}

    <Heading as="strong" fontWeight={500} fontSize="1em">
      <Box as="sup" fontSize="0.5em">
        R$
      </Box>{' '}
      {value}
    </Heading>
  </Box>
);

Price.propTypes = {
  value: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string,
  symbolFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
