import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

Container.InputSearch = styled.div`
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: ${props => (props.orderCars ? '10px' : '26px')};
  width: 100%;

  label {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #757778;
    width: 100%;
  }

  .input-wrapper {
    grid-template-columns: auto 1fr;
    margin-top: 20px;
    display: grid;
    grid-gap: 5px;

    input {
      border: none;

      &::placeholder {
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        color: #c4c4c4;
      }
    }

    .search-icon {
      font-weight: normal;
      font-size: 14px;
      color: #c4c4c4;
      bottom: 0;
      left: 0;
    }
  }
`;

Container.List = styled.ul`
  padding-right: 9px !important;
  max-height: 256px;
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #e9e9e9;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }
`;

Container.List.Item = styled.li`
  border-top: 1px solid #d9d9d9;
  padding: 1px 25px !important;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 51px;
  width: 100%;

  ${({ orderCars }) =>
    orderCars &&
    css`
      &:first-child {
        border-top: none;
      }
    `}

  &:last-child {
    border-bottom: 1px solid #d9d9d9;
  }

  .label {
    font-family: ${props => `${props.fontFamily}, sans-serif`};
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #68777c;
  }

  .image {
    margin-right: 25px;
    text-align: center;
    width: 51px;

    img {
      max-width: 51px !important;
    }

    .text {
      font-style: normal;
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      color: #000000;
    }
  }
`;
