import styled from 'styled-components';
import Select from '@mui/material/Select';

export const ICMultiselect = styled(Select)`
  border-radius: 0 !important;
  border: 1px solid #d2d4d7;
  box-sizing: border-box;
  height: 41px;
  width: 100%;
`;

export const Label = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #757778;

  .icon {
    margin-right: 7px;
  }
`;
