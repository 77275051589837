import { Basic } from './AfterBid';
import { WithSlider, ParticipateWithSlider } from './WithSlider';
import { KnowMore } from './KnowMoreModal';
import { NinjaLayout } from './NinjaLayout';

export const Temperature = {
  Basic,
  Ninja: NinjaLayout,
  WithSlider: {
    Default: WithSlider,
    Participate: ParticipateWithSlider,
  },
  KnowMore,
};
