import React from 'react';
import styled from 'styled-components';

import { Layout } from '../components';
import { Text, Box } from '../components/abstract';
import { Heading } from '../components/enhanced';

const titleProps = {
  textAlign: 'center',
  level: '1',
  fontSize: 6,
};

const StyledHeading = styled(Heading)`
  text-transform: uppercase;
`;

const Wrapper = styled(Box)`
  text-align: justify;
  padding: 2rem !important;
  box-shadow: 0 1px 5px 0 rgba(157, 157, 157, 0.5);
  margin: 2rem auto 2rem;

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  p {
    margin-bottom: 1.5rem;
  }
`;

const SectionSubHeader = styled(Heading).attrs({
  level: 4,
  color: 'secondaries.UnoU300',
  m: 0,
})`
  padding: 0.5rem 0 1rem;
`;

const SectionHeader = styled(SectionSubHeader).attrs({
  level: 3,
})`
  padding: 1rem 0 1.5rem;
  text-transform: uppercase;
`;

const Highlight = styled(Box)`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.neutrals.EclipseE70};
  font-weight: ${({ theme }) => theme.fontWeights.paragraphs.bold};
  margin: 1rem 0;
`;

const Terms = () => {
  return (
    <Layout>
      <Wrapper className="container">
        <StyledHeading {...titleProps}>
          Termos de uso plataforma de compra de veículos usados
        </StyledHeading>

        <StyledHeading {...titleProps} level="2" mb={3}>
          Lojista
        </StyledHeading>

        <Text as="p">
          Este site e todo o seu conteúdo (<strong>"Site"</strong>) são de propriedade exclusiva da{' '}
          <strong>INSTACARRO SERVIÇOS DE INTERMEDIAÇÃO E COMÉRCIO DE VEÍCULOS EIRELI</strong>,
          inscrita no CNPJ/ME sob nº 23.513.643/0001-34, com sede na Rua Doutor Alceu de Campos
          Rodrigues, nº 486, Vila Nova Conceição, CEP 04544-001, São Paulo/SP (
          <strong>"InstaCarro"</strong>).
        </Text>

        <Text as="p">
          Estes Termos de Uso (<strong>"Termos de Uso"</strong>) regulam as condições para que os
          Lojistas, conforme abaixo definidos, possam utilizar os serviços de intermediação de venda
          de veículos usados e da plataforma Comprar Carros (<strong>“Plataforma”</strong>),
          disponibilizado pela InstaCarro no Site.
        </Text>

        <Highlight>
          A aceitação destes Termos de Uso é indispensável à utilização da Plataforma, sendo que ao
          acessá-la o Lojista declara ter lido, entendido e concordado com estes Termos de Uso,
          independentemente da assinatura de qualquer contrato físico.
        </Highlight>

        <SectionHeader>A. Conta de Lojista</SectionHeader>

        <SectionSubHeader>A.1. Cadastro</SectionSubHeader>

        <Highlight>
          O cadastro no Plataforma se dá por meio da criação de uma Conta de Lojista, o que é
          permitido apenas a revendedores de carros usados autorizados pela InstaCarro ("Lojista").
        </Highlight>

        <Text as="p">
          O Lojista desde já declara, sob as penas da lei, que todas as informações por ele
          prestadas para cadastro de sua Conta de Lojista são verdadeiras, exatas e autênticas.
        </Text>

        <Text as="p">
          A InstaCarro reserva-se o direito de utilizar todos os meios válidos e possíveis para
          identificar e avalizar o Lojista, inclusive solicitar dados e documentos adicionais a fim
          de conferir a veracidade dos dados informados.
        </Text>

        <SectionSubHeader>A.2. Tratamento das Informações</SectionSubHeader>

        <Highlight>
          Todas as informações fornecidas voluntariamente pelo Lojista estão sujeitas a medidas de
          segurança que impedem o acesso, o uso e a divulgação não autorizados pela InstaCarro. Para
          mais informações sobre a coleta, uso, armazenamento, tratamento e segurança das
          informações fornecidas, o Lojista deve acessar a Política de Privacidade da InstaCarro
          ("Política de Privacidade"), que faz parte integrante destes Termos de Uso.
        </Highlight>

        <Text as="p">
          Desde já o Lojista autoriza a InstaCarro a compartilhar seus dados com parceiros com o fim
          de viabilizar a utilização da Plataforma.
        </Text>

        <SectionSubHeader>A.3. Do Uso da Conta de Lojista</SectionSubHeader>

        <Text as="p">
          Ao criar uma Conta de Lojista na Plataforma, o Lojista concorda que não irá revelar sua
          senha a terceiros e que assumirá total responsabilidade por quaisquer ações realizadas
          através de sua Conta de Lojista.
        </Text>

        <Text as="p">
          O Lojista deverá agir com cautela e cuidado na utilização das funcionalidades da
          Plataforma “lembrar senha” ou “mantenha-me conectado”, disponibilizadas por navegadores de
          internet, em computadores ou dispositivos móveis próprios ou de terceiros.
        </Text>

        <Highlight>
          O Lojista será inteiramente responsável por transações comerciais realizadas por terceiros
          não autorizados pelo Lojista caso tais transações tenham sido realizadas com a senha e
          login corretos do Lojista.
        </Highlight>

        <Text as="p">
          O Lojista deverá informar imediatamente a InstaCarro pelos canais de atendimento da
          InstaCarro caso haja suspeita ou constatação de acesso não autorizado à sua Conta de
          Lojista.
        </Text>

        <Text as="p">
          A InstaCarro reserva para si o direito de recusar qualquer solicitação de cadastro de
          Conta de Lojista e de cancelar qualquer Conta de Lojista ativa, a seu exclusivo critério e
          sem aviso prévio, não cabendo nenhuma indenização ou reparação ao Lojista nestes casos.
        </Text>

        <Text as="p">
          Não é permitida a criação de nova Conta de Lojista por Lojista que tenha sido inabilitado
          pela InstaCarro, temporária ou definitivamente e independente do motivo.
        </Text>

        <SectionHeader>B. Compra e venda do veículo</SectionHeader>

        <Text as="p">
          A Plataforma não envolve a prestação de quaisquer serviços de consultoria acerca de compra
          e venda de veículos, de forma que InstaCarro não se responsabiliza pelas decisões de
          compra de veículos tomadas pelo Lojista.
        </Text>

        <Text as="p">
          A InstaCarro disponibilizará imagens e informações de veículos de vendedores na sua
          Plataforma por um determinado período de tempo, podendo o Lojista dar lances para comprar
          qualquer veículo durante o referido período (<strong>“Leilão”</strong>).
        </Text>

        <Text as="p">
          Após o encerramento do Leilão a InstaCarro poderá, a seu exclusivo critério, fazer uma
          proposta de compra do veículo ao vendedor (<strong>“Proposta”</strong>). O valor da
          Proposta é composto pela soma <strong>(i)</strong> do valor do lance do Lojista dado no
          Leilão (<strong>“Lance”</strong>) e <strong>(ii)</strong> do valor da comissão de venda da
          InstaCarro (<strong>“Comissão”</strong>).
        </Text>

        <SectionSubHeader>B.1. Negociação e Venda do Veículo</SectionSubHeader>

        <Text as="p">
          O aceite da Proposta pelo vendedor é vinculante para o Lojista, que ficará obrigado a
          reembolsar a InstaCarro do valor do Lance e pagar à InstaCarro o valor da Comissão, exceto
          se a InstaCarro, após análise de documentação do veículo e/ou do vendedor, a seu exclusivo
          critério, decidir por não concretizar a compra e venda do veículo.
        </Text>

        <Text as="p">
          A InstaCarro, a seu exclusivo critério e a qualquer tempo, poderá bloquear qualquer
          Lojista que utilize a Plataforma indevidamente. São exemplos de uso indevido da
          Plataforma: <strong>(i)</strong> frequentemente dar lances muito abaixo ou muito acima do
          preço de mercado do veículo disponibilizado na Plataforma, <strong>(ii)</strong>{' '}
          recusar-se a comprar veículo pelo qual deu Lance vencedor do Leilão,{' '}
          <strong>(iii)</strong> não realizar os pagamentos de valores devidos à InstaCarro nos
          prazos previstos nestes Termos de Uso.
        </Text>

        <Text as="p">
          Ao Lojista é vedado ter qualquer tipo de contato direto com vendedores que
          disponibilizaram veículos na Plataforma, durante ou depois do Leilão, sem limitação de
          prazo.
        </Text>

        <SectionSubHeader>B.2. Pagamento ao Vendedor</SectionSubHeader>

        <Text as="p">
          A InstaCarro pagará o valor da Proposta ao vendedor no momento em que receber o veículo e
          a sua respectiva documentação em termos para transferência de titularidade, sendo o
          pagamento feito mediante transferência eletrônica de fundos para conta corrente indicada
          pelo vendedor. Tal pagamento será feito por conta e ordem do Lojista que deu o Lance
          vencedor do Leilão.
        </Text>

        <Text as="p">
          Após ser informando do pagamento feito ao vendedor pela InstaCarro, o Lojista deverá{' '}
          <strong>(i)</strong> a emitir a nota fiscal de entrada do veículo pelo preço constante do
          Documento Único de Transferência (<strong>“DUT”</strong>) do veículo comprado,{' '}
          <strong>(ii)</strong> pagar boleto emitido pela InstaCarro com valor do Lance a ser
          reembolsado e da Comissão a ser paga à InstaCarro em até 48 (quarenta e oito) horas da
          data de seu recebimento (o <strong>“Boleto”</strong>).
        </Text>

        <Text as="p">
          Caso o Lojista não realize o pagamento do Boleto no prazo de vencimento, além de bloquear
          o acesso do Lojista à Plataforma, a InstaCarro poderá, a seu exclusivo critério,
          alternativa ou cumulativamente: <strong>(i)</strong> reter o veículo em seu pátio e
          protestar o Boleto vencido, <strong>(ii)</strong> vender o veículo a qualquer terceiro
          pelo preço que considerar conveniente.
        </Text>

        <Text as="p">
          Em qualquer das hipóteses do parágrafo anterior, sem prejuízo do pagamento da multa de que
          trata o item B.6, o Lojista ficará obrigado a ressarcir a InstaCarro de todos os
          prejuízos, custos e despesas por ela havidos com o inadimplemento do Boleto, inclusive,
          mas não limitado a, despesas de manutenção do veículo em pátio, de emissão de novo DUT do
          veículo e de despachantes.
        </Text>

        <Text as="p">
          Os impostos incidentes sobre a diferença entre o valor do Lance e o valor da Proposta
          serão de responsabilidade da InstaCarro e serão reembolsados ao Lojista mediante
          apresentação de comprovantes de recolhimento.
        </Text>

        <SectionSubHeader>B.5. Entrega e Transferência do Veículo</SectionSubHeader>

        <Text as="p">
          Após a confirmação do pagamento do Boleto a InstaCarro deverá disponibilizar o veículo
          vendido ao Lojista livre e desimpedido de ônus e encargos e com o DUT devidamente
          preenchido. A InstaCarro se reserva o direito de não permitir que imagens, fotocópias ou
          até mesmo dados do DUT do veículo sejam disponibilizados ao Lojista até a data da sua
          entrega ao Lojista.
        </Text>

        <Text as="p">
          Os veículos são adquiridos pelo Lojista sem garantia e no estado em que se encontram, e a
          InstaCarro não será responsabilizada por quaisquer danos, materiais ou morais, suportados
          pelo Lojista em razão de vícios ocultos nos veículos vendidos por meio da Plataforma.
        </Text>

        <Text as="p">
          A responsabilidade da InstaCarro será limitada unicamente as informações apresentadas no
          relatório de inspeção do veículo, apresentado ao Lojista na data de retirada do veículo.
          As informações apresentadas no relatório de inspeção referem-se às condições verificadas
          na data de retirada do veículo, de forma que a InstaCarro não se responsabiliza por
          qualquer alteração dessas condições após a entrega do veículo ao Lojista.
        </Text>

        <Text as="p">
          A InstaCarro tomará todas as medidas necessárias para manter o Lojista indene de qualquer
          ônus ou gravames que recaiam sobre veículo e que tenham origem anterior à sua entrega ao
          Lojista. A partir da data de entrega do veículo ao Lojista toda e qualquer multa,
          penalidade, tributo, taxa e ônus incidentes sobre o veículo originadas após esta data
          serão de única e exclusiva responsabilidade do Lojista.
        </Text>

        <Text as="p">
          Caso a InstaCarro efetue pagamento de qualquer valor relativo a veículo adquirido por
          Lojista que seja de responsabilidade do Lojista, este deverá ressarcir a InstaCarro no
          prazo de 2 (dois) dias úteis contados da data de recebimento de boleto de cobrança, sob
          pena de ter seu acesso à Plataforma bloqueado.
        </Text>

        <Text as="p">
          Caso haja atraso na transferência da propriedade do veículo por culpa do Lojista, o
          Lojista ficará obrigado a ressarcir a InstaCarro de todos os prejuízos, custos e despesas
          por ela havidos com o inadimplemento do Boleto, inclusive, mas não limitado a, despesas de
          manutenção do veículo em pátio, emissão de novos documentos de transferência do DUT, sem
          prejuízo da cobrança da multa de que trata a cláusula B.6.
        </Text>

        <SectionSubHeader>B.6 Penalidades</SectionSubHeader>

        <Text as="p">
          Caso o Lojista descumpra qualquer disposição destes Termos de Uso ficará sujeito ao
          pagamento à InstaCarro de multa de 5% (cinco por cento) sobre o valor do veículo disposto
          na tabela FIPE vigente na data da infração.
        </Text>

        <Text as="p">
          Em caso de mora de qualquer pagamento de que dispõem estes Termos de Uso, incidirão sobre
          o valor em atraso, além da multa de que trata parágrafo anterior, correção monetária pelo
          IPC-A IBGE e juros de 1% (um por cento) ao mês, cobrados <em>pro rata</em> die.
        </Text>

        <Text as="p">
          Caso a InstaCarro precise promover qualquer demanda judicial ou extrajudicial contra o
          Lojista, este ficará obrigado a pagar à InstaCarro, além da multa e dos encargos tratados
          nos parágrafos anteriores, honorários advocatícios aos advogados da InstaCarro fixados em
          10% (dez por cento) do valor de débito.
        </Text>

        <SectionHeader>C — Propriedade intelectual</SectionHeader>

        <Text as="p">
          Todos os direitos relativos ao Site, bem como às suas funcionalidades são de titularidade
          exclusiva da InstaCarro, inclusive no que aqueles relativos à Plataforma, seus textos,
          imagens, layouts, códigos, bases de dados, gráficos, relatórios demais conteúdos
          produzidos direta ou indiretamente pela InstaCarro.
        </Text>

        <SectionHeader>D — Limitação de responsabilidade</SectionHeader>

        <Text as="p">
          O Site pode conter links ou ligações a sites ou serviços de terceiros que não pertencem e
          nem são controlados pela InstaCarro, de forma que a InstaCarro não tem qualquer
          responsabilidade pelo conteúdo, precisão, políticas, práticas, opiniões expressas em
          qualquer desses sites ou por qualquer terceiro com quem o Lojista interaja através do
          Site.
        </Text>

        <Text as="p">
          A InstaCarro utiliza serviços de terceiros para manter o funcionamento do Site (por
          exemplo, servidores de hospedagem), podendo, portanto, eventualmente ocorrerem falhas em
          tais serviços. A InstaCarro não será poderá ser responsabilizada por quaisquer perdas e
          danos sofridas pelo Lojista decorrentes de falha dos serviços destes terceiros, mas, na
          medida do possível, manterá o Lojista informado sobre prazo e providências que estão sendo
          tomadas para sanear referida falha.
        </Text>

        <Highlight>
          A InstaCarro não se responsabiliza pelas falhas de acesso ou navegação no Site decorrentes
          de circunstâncias alheias à sua vontade e controle, inclusive, sem limitação, falhas na
          internet em geral, quedas de energia, mau funcionamento eletrônico e/ou físico de qualquer
          rede de telecomunicações, interrupções ou suspensões de conexão e falhas de software e/ou
          hardware do Lojista, bem como paralisações programadas para manutenção, atualização e
          ajustes de configuração do Site.
        </Highlight>

        <SectionHeader>E – Fraudes</SectionHeader>

        <Text as="p">
          Qualquer tentativa, direta ou indireta, bem-sucedida ou não, de copiar, armazenar, ou de
          qualquer forma acessar qualquer informação contida no Site para fins que não sejam
          expressamente autorizados pela InstaCarro tornará o autor, bem como seus mandantes e
          auxiliares, passíveis de serem alvos de ações legais pertinentes e de serem obrigados a
          indenizar a InstaCarro por eventuais danos causados.
        </Text>

        <SectionHeader>F — Alterações nos Termos de Uso</SectionHeader>

        <Text as="p">
          A InstaCarro poderá alterar estes Termos de Uso sempre que considerar necessário, razão
          pela qual recomenda-se a leitura periódica destes Termos de Uso pelo Lojista.
        </Text>

        <SectionHeader>H — Contato</SectionHeader>

        <Text as="p">
          Quaisquer comunicações, requisições, notificações ou informações relacionadas aos Termos
          de Uso, a Conta do Usuário ou a Plataforma deverão ser enviadas pelo Lojista à InstaCarro
          através do e-mail <a href="mailto:suporte@instacarro.com">suporte@instacarro.com</a>.
        </Text>

        <SectionHeader>I — Política de Privacidade</SectionHeader>

        <Highlight>
          O Lojista expressamente aceita os termos e condições elencados na Política de Privacidade
          da InstaCarro, os quais ficam aqui incorporados por referência.
        </Highlight>

        <SectionHeader>J — Lei Aplicável e Resolução de Conflitos</SectionHeader>

        <Text as="p">
          Toda e qualquer controvérsia oriunda dos termos expostos nos presentes Termos de Uso, ou
          relativos à Conta do Lojista ou à Plataforma será solucionada de acordo com a lei
          brasileira, sendo competente o Foro da Comarca de São Paulo/SP, com exclusão de qualquer
          outro por mais privilegiado que seja.
        </Text>

        <Text as="p">Estes Termos de Uso foram atualizados em [---].</Text>
      </Wrapper>
    </Layout>
  );
};

export default Terms;
