import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { themeGet } from 'styled-system';
import { MdVerifiedUser, MdNavigateNext } from 'react-icons/md';

import { Button, Text, Flex } from '../../abstract';

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${themeGet('colors.neutrals.EclipseE60', '#F2F3F3')};
  color: ${themeGet('colors.neutrals.EclipseE800', '#000000')};

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
`;

StyledButton.displayName = 'Button';

export const AttentionPointsLink = ({ percentage = 0, ...props }) => (
  <StyledButton
    px={1}
    py={rem(4)}
    fontSize={1}
    fontWeight="paragraphs.bold"
    width={1}
    height={rem(32)}
    justifyContent="space-between"
    {...props}
  >
    <Flex as="span" alignItems="center">
      <Text as={MdVerifiedUser} color="secondaries.Uno" size={rem(16)} lineHeight={null} mr={1} />
      Aprovado em {percentage}% dos pontos
    </Flex>
    <Text as={MdNavigateNext} color="neutrals.EclipseE80" lineHeight={null} size={rem(16)} />
  </StyledButton>
);

AttentionPointsLink.propTypes = {
  percentage: PropTypes.number.isRequired,
};
