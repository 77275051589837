import React from 'react';

import { Text } from './abstract';

export const TestLabel = ({ show = false }) =>
  show ? (
    <Text bg="#e64d4d" textAlign="center" color="white" p={1}>
      Leilão experimental. Isso não é real
    </Text>
  ) : null;
