export const primaries = {
  Soul: '#2274A5',
  SoulS300: '#3680AD',
  SoulS600: '#1C5F88',
  Taxi: '#FFFFFF',
};

export const secondaries = {
  Uno: '#22AA52',
  UnoU300: '#4AB971',
  UnoU600: '#1C8C44',
};

export const neutrals = {
  // dark
  EclipseE800: '#1A2331',
  EclipseE700: '#2E3743',
  EclipseE600: '#585F69',
  EclipseE500: '#82878E',
  // mid
  EclipseE400: '#AFB2B7',
  EclipseE300: '#BDC0C4',
  EclipseE200: '#C9CBCE',
  EclipseE100: '#D2D4D6',
  // light
  EclipseE80: '#DADBDD',
  EclipseE70: '#EAEBEC',
  EclipseE60: '#F2F3F3',
  EclipseE50: '#F4F5F5',
};

export const alerts = {
  Ferrari: '#F8333C',
  FerrariF600: '#CB2A32',
  FerrariF300: '#F9585F',
  FerrariF40: '#FDD9DB',
  Lamborghini: '#FCAB10',
  LamborghiniL600: '#CF8C0E',
  LamborghiniL300: '#FCBA3B',
  LamborghiniL40: '#FEEFD3',
  SoulS40: '#D6E5EE',
  UnoU40: '#DEF2E5',
};
