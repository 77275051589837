import React, { useMemo } from 'react';
import styled from 'styled-components';

import NotificationTab from './topbar/notification-tab';
import Footer from './shared/Footer';
import { useAppContext } from '../context';
import { Flex } from './abstract';
import TopBar from './topbar';
import Header from './header';

const Container = styled.div`
  height: 100vh !important;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'main';
`;

const Main = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  grid-area: main;

  min-height: calc(100% - 155px);
  display: grid;
  grid-template-rows: 1fr auto;
`;

const Navigation = styled.div`
  grid-area: header;
`;

export const Layout = ({
  children,
  header = true,
  tabs = false,
  tabsData = {},
  user = false,
  ...etc
}) => {
  const { isApp } = useAppContext();

  const tabsItems = useMemo(
    function() {
      let items = [
        {
          label: 'novas ofertas',
          counter: tabsData?.newOffersCount,
          active: tabsData?.activeTab === 0,
          onClick: tabsData?.onClickTab0,
        },
        {
          label: 'participando',
          showNotifyIcon: tabsData?.showNotifyIcon,
          counter: tabsData?.participatingCount,
          active: tabsData?.activeTab === 1,
          onClick: tabsData?.onClickTab1,
        },
      ];
      if (user && !user.isNinja()) {
        items.push({
          label: <NotificationTab />,
          active: tabsData?.activeTab === 2,
          onClick: tabsData?.onClickTab2,
        });
      }
      return items;
    },
    [tabsData, user]
  );

  return (
    <Container>
      <Navigation>
        {header && <Header />}
        {tabs && <TopBar tabs={tabsItems} />}
      </Navigation>
      <Main id="base-layout">
        <Flex css={{ flexGrow: 1, position: 'relative', maxWidth: '100vw' }}>{children}</Flex>
        {!isApp && <Footer />}
      </Main>
    </Container>
  );
};
