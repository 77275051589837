import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

import { ICMultiselect, Label } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Placeholder({ icon = '', label = '' }) {
  return (
    <Label>
      {icon && <span className="icon">{icon}</span>}
      {label}
    </Label>
  );
}

function Multiselect({ icon = '', label = '', data, name, onChange, value }) {
  function handleRenderValue(selected) {
    return selected.length === 0 ? <Placeholder icon={icon} label={label} /> : selected.join(', ');
  }

  return (
    <ICMultiselect
      multiple
      displayEmpty
      value={value}
      onChange={onChange}
      name={name}
      input={<OutlinedInput />}
      renderValue={handleRenderValue}
      MenuProps={MenuProps}
    >
      {data.map((item, key) => (
        <MenuItem key={key} value={item.id}>
          <Checkbox checked={value.indexOf(item.id) > -1} /> {item.label}
        </MenuItem>
      ))}
    </ICMultiselect>
  );
}

export default Multiselect;
