import React from 'react';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { startTimer, stopTimer } from './store/actions/timer.actions';

import theme from './design-system';
import DetailsPage from './pages/DetailsPage';
import AuctionsPage from './pages/AuctionsPage';
import { Purchases } from './pages/Purchases';
import { TaxProfileForm } from './pages/TaxProfileForm';
import { LoginPage, ForgotPasswordPage, ResetPasswordPage } from './pages/AuthPages';
import Terms from './pages/Terms';
import { getLocalUser } from './utils/auth';
import { fetchAuction, fetchAuctions } from './store/actions/auction.actions';
import { fetchRelatedDealers } from './store/actions/user.actions';
import NotificationWrapper from './components/shared/notification/NotificationWrapper';
import AuctionPusher from './components/shared/pusher/AuctionPusher';
import Loading from './components/shared/loading/Loading';
import { AppProvider } from './context';
import { gtmTracker, gtmVariables } from './utils/gtmTracker';

import {
  SingleOfferModal,
  WonModal,
  LostModal,
  BuyNowModal,
  SuccessModal,
  ConfirmMakeOfferModal,
  BuyFor,
  ConfirmBuyFor,
} from './components/Modals';

import { Temperature } from './components/Temperature';
import { PurchasesProvider } from './pages/Purchases/PurchasesContext';
import { NewPurchasesProvider } from './pages/Purchases/NewPurchasesContext';
import { AcceptedModal, RejectedModal } from './components/AuctionList/modals';
import { ToastContainer } from './components/toast-notification-container';
import { saveFirstLoginOfTheDay } from './utils/freeShippingDialog';
import { checkToken } from './utils';
import TooltipFeedback from './components/Tooltips/TooltipFeedback';
import TooltipFeedbackOld from './components/Tooltips/TooltipFeedback/old';
import Favorites from './pages/Favorites';
import { FiltersContextProvider } from './context/AuctionFilters';
import { userFilterService } from './services/UserFilterService';
import NinjaDashboardPage from './pages/NinjaDashboard';
import { UpdateContactInfoPage } from './pages/UpdateContactInfoPage';
import { UserFavoriteInspectionsContextProvider } from './context/FavoriteContext';
import { favoriteService } from './services/favorite';
import { UserContextProvider } from './context/UserProfileContext';
import { userProfileService } from './services/user-profile';
import AppConstants from './constants/appConstants';
import { TaxProfiles } from './pages/TaxProfiles';
import Pusher from 'pusher-js';

import { AutoBidOfferModal } from './components/Modals/AutoBid/AutomaticOffer';

import MoreOptions from './components/Modals/AutoBid/MoreOptions';
import RemoveAutomaticOffer from './components/Modals/AutoBid/RemoveAutomaticOffer';
import TiebreakerRules from './components/Modals/AutoBid/TiebreakerRules';
import ConfirmAutomaticOffer from './components/Modals/AutoBid/ConfirmAutomaticOffer';
import ConfirmBuyNowModal from './components/Modals/ConfirmBuyNowModal';

import { deauthenticate } from './store/actions/authentication.actions';
import { FlagProvider } from '@unleash/proxy-client-react';
import { FilterProvider } from './hooks/useFilter';

import { PreferencesPage } from './pages/PreferencesPage';
import { PreferencesModal } from './components/Modals/PreferencesModal';
import { ProductNF } from './components/Modals/ProductNF';

import balboaFont from './styles/balboa-w01-bold.ttf';
import balboaFontCondensed from './styles/balboa-condensed.otf';

export const pusher = new Pusher(AppConstants.PusherKey, {
  encrypted: true,
});

const PrivateRoute = ({ children, onlyNinja, ...rest }) => {
  const user = getLocalUser();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!user.isLoggedIn()) {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
        }

        if (onlyNinja) {
          return user.isNinja() ? children : <Redirect to="/" />;
        }

        return children;
      }}
    />
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const GlobalStyle = createGlobalStyle`
  ${reset};
  strong {
    font-weight: 700;
  }
  p {
    margin: 0.5rem 0 1rem 0;
  }
  @font-face {
    font-family: 'Balboa W01 Cond';
    src: url(${balboaFont}) format('truetype');
  }

  @font-face {
    font-family: 'Balboa Condensed';
    src: url(${balboaFontCondensed}) format('truetype');
  }
`;

const App = () => {
  //usePageViews();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.authentication);
  const user = useSelector(state => state.user);
  const auctions = useSelector(state => state.auctions);
  const isLoading = useSelector(
    ({ relatedDealersLoading, allRequestOnGoing, singleRequestOnGoing }) =>
      relatedDealersLoading || allRequestOnGoing || singleRequestOnGoing
  );
  const notifier = React.useRef();
  const history = useHistory();

  const config = {
    url:
      process.env.IC_ENV === 'production'
        ? 'https://unleash-proxy.instacarro.com/proxy'
        : 'https://proxy-unleash.instacarro.com/proxy',
    clientKey: 'secret-one',
    refreshInterval: 60,
    appName: 'lojas-web',
    environment: process.env.IC_ENV === 'production' ? 'prd' : 'dev',
  };

  React.useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'optimize.activate',
      });
    }
  }, []);

  React.useEffect(() => {
    const { user: _user } = auth;

    gtmTracker({
      [gtmVariables.USER_EMAIL]: _user.email,
      [gtmVariables.IS_NINJA]: _user.isNinja(),
      [gtmVariables.USER_DEALERSHIP]: _user.dealershipName,
      [gtmVariables.BUYER_ID]: user.buyerId,
    });
  }, [user, auth]);

  // Start timer actions on the store.
  React.useEffect(() => {
    dispatch(startTimer());

    return () => {
      dispatch(stopTimer());
    };
  }, []);

  // async function dispatchCars() {
  //   await dispatch(fetchAuctions());
  // }

  // Fetch user related dealers
  React.useEffect(() => {
    const { user: _user } = auth;
    if (!_user.isLoggedIn()) return;

    if (_user.email && _user.isNinja()) {
      checkToken()
        .then(async () => {
          dispatch(fetchRelatedDealers());
        })
        .catch(() => dispatch(deauthenticate(() => history.push('/login'))));
    }

    //dispatchCars();
    if (auctions.detailedAuction._id) {
      dispatch(fetchAuction(auctions.detailedAuction._id));
    }
  }, [auth]);

  React.useEffect(() => {
    saveFirstLoginOfTheDay();
  }, []);

  return (
    <FlagProvider config={config}>
      <FiltersContextProvider service={userFilterService} notify={console}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <AppProvider>
              <FilterProvider>
                <GlobalStyle />
                {isLoading ? (
                  <Loading centered />
                ) : (
                  <Switch>
                    <PrivateRoute exact path="/detalhes/:id">
                      <DetailsPage />
                    </PrivateRoute>
                    {/*   <PrivateRoute path="/compras">
                      <PurchasesProvider>
                        <Purchases />
                      </PurchasesProvider>
                    </PrivateRoute> */}
                    <PrivateRoute path="/compras">
                      <NewPurchasesProvider>
                        <Purchases />
                      </NewPurchasesProvider>
                    </PrivateRoute>
                    <PrivateRoute path="/favoritos">
                      <UserFavoriteInspectionsContextProvider
                        service={favoriteService}
                        notify={console}
                      >
                        <Favorites />
                      </UserFavoriteInspectionsContextProvider>
                    </PrivateRoute>
                    <PrivateRoute path="/perfis" exact>
                      <TaxProfiles />
                    </PrivateRoute>
                    <PrivateRoute path="/perfis/novo">
                      <TaxProfileForm />
                    </PrivateRoute>
                    <Route path="/login">
                      <LoginPage />
                    </Route>
                    <Route path="/esqueceu-senha">
                      <ForgotPasswordPage />
                    </Route>
                    <Route path="/resetear-senha">
                      <ResetPasswordPage />
                    </Route>
                    <Route path="/terms">
                      <Terms />
                    </Route>
                    <PrivateRoute path="/ferramenta-comercial" onlyNinja>
                      <NinjaDashboardPage />
                    </PrivateRoute>
                    <PrivateRoute path="/atualizar-perfil">
                      <UpdateContactInfoPage />
                    </PrivateRoute>
                    <PrivateRoute path="/preferencias">
                      <PreferencesPage />
                    </PrivateRoute>
                    <PrivateRoute path="/">
                      <UserContextProvider service={userProfileService} notifier={console}>
                        <AuctionsPage />
                      </UserContextProvider>
                    </PrivateRoute>
                  </Switch>
                )}

                <WonModal />
                <LostModal />
                <AcceptedModal />
                <RejectedModal />
                <SingleOfferModal />
                <BuyNowModal />
                <SuccessModal />
                <ConfirmMakeOfferModal />
                <AutoBidOfferModal />
                <MoreOptions />
                <RemoveAutomaticOffer />
                <TiebreakerRules />
                <ConfirmAutomaticOffer />
                <ConfirmBuyNowModal />
                <PreferencesModal />
                <Temperature.KnowMore />
                <ProductNF />
                <BuyFor />
                <ConfirmBuyFor />

                {process.env.NODE_ENV !== 'test' && (
                  <React.Fragment>
                    <NotificationWrapper ref={notifier} />
                    <AuctionPusher
                      pusher={pusher}
                      notifier={notifier ? notifier.current : undefined}
                      auctions={auctions.allAuctions}
                    />
                  </React.Fragment>
                )}
              </FilterProvider>
            </AppProvider>
            <ToastContainer />
            <TooltipFeedback />
            <TooltipFeedbackOld />
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </FiltersContextProvider>
    </FlagProvider>
  );
};

export default App;
