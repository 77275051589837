import React from 'react';
import PropTypes from 'prop-types';

import { TextFieldStyles } from '../styled/forms/textfield-styles';
import { ErrorMessage, LabelStyles, FormTextStyles } from '../styled/forms/common-styles';

export const TextField = React.forwardRef(
  ({ labelText, formText, hasError, errorMessage, ...props }, ref) => {
    const describedById = `${props.id}-help-block`;
    const a11yProps = {
      'aria-describedby': formText ? describedById : undefined,
    };

    return (
      <>
        <LabelStyles htmlFor={props.id}>{labelText}</LabelStyles>
        {formText && <FormTextStyles id={describedById}>{formText}</FormTextStyles>}
        <TextFieldStyles {...a11yProps} {...props} ref={ref} hasError={hasError} />
        {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    );
  }
);

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  formText: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};
