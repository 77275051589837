import React from 'react';

export const FlagIcon = ({ color = '#f88820', ...props }) => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 2a2 2 0 012-2h11.19a5 5 0 013.621 1.552l7.345 7.712a3 3 0 01.133 3.99L16.833 22.2A5 5 0 0112.992 24H2a2 2 0 01-2-2V2z"
        fill={color}
      />
    </svg>
  );
};
