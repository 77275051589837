import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { ListsTitle } from '../../pages/AuctionsPage';
import { useSegment } from '../../hooks/useSegment';

export const ContactFormHeader = () => {
  const { page } = useSegment();

  useEffect(() => {
    page('Atualizar Perfil');
  }, []);

  return (
    <>
      <Typography variant="h6" component="h6" fontWeight={600} mb={4}>
        Atualize seus dados para continuar recebendo notificações de leilões
      </Typography>
      <ListsTitle>
        <PersonIcon />
        <Typography variant="h7" component="h7" fontWeight={600}>
          Dados cadastrais
        </Typography>
      </ListsTitle>
    </>
  );
};
