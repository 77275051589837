import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 90%;
  flex-direction: row;
  display: flex;
  @media screen and (max-width: 1025px) {
    width: 97%;
    max-width: 97%;
  }
`;

export const SubContainer = styled.div`
  padding-left: 40px;
  @media screen and (max-width: 1025px) {
    padding-left: 26px;
  }
`;

export const MainCardContainer = styled.a`
  display: flex;
  box-shadow: 0px 4px 16px 0px rgba(24, 94, 136, 0.15);
  background-color: white;
  height: 100%;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 100%;
  background-color: ${props => props.backgroundColor};
  padding: 13px;
  @media screen and (max-width: 1025px) {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const DropContainer = styled.div`
  display: flex;
  box-shadow: 0px 4px 16px 0px rgba(24, 94, 136, 0.15);
  background-color: white;
  height: 100%;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  @media screen and (max-width: 1025px) {
    height: 45px;
  }
`;

export const ArrowContainer = styled.div`
  padding-right: 6px;
  padding-top: 6px;
`;

export const Text = styled.div`
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: ${props => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 1025px) {
    width: 200px;
    font-weight: 500;
    align-items: center;
  }
`;

export const InfoText = styled.div`
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: ${props => props.color};
  @media screen and (max-width: 1025px) {
    line-height: 15.6px;
    padding-top: 10px;
  }
`;

export const InfoLinkText = styled.a`
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #00a81f;
  line-height: 30px;
  text-decoration: underline;
  width: fit-content;
  @media screen and (max-width: 1025px) {
    padding-top: 5px;
  }
`;

export const FinanceDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -36px;
  align-items: center;
  @media screen and (max-width: 1025px) {
    margin-top: 10px;
  }
`;

export const FinancingButton = styled.a`
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.24);
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #171c24;
  background-color: #ff6c1c;
  width: fit-content;
  padding: 15px;
  border-radius: 5px;
  width: 100%;

  display: flex;
  justify-content: center;
  @media screen and (max-width: 1025px) {
  }
`;

export const CashLink = styled.a`
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 14px;
  color: #171c24;
  text-decoration: underline;
  width: fit-content;
  padding-top: ${props => props.onlyCash}px;
  padding-bottom: 5px;
  @media screen and (max-width: 1025px) {
  }
`;

export const CardsContainer = styled.div`
  width: 93%;
  max-width: 93%;
  margin-bottom: 33px;
  margin-top: -16px;
  padding-left: 23px;
  flex-direction: column;
  display: flex;

  @media screen and (max-width: 1025px) {
    margin-top: -13px;
    padding-left: 11px;
    margin-bottom: 20px;
    width: 80%;
    width: 91%;
    max-width: 91%;
  }
`;

export const StatusImgContainer = styled.div`
  align-items: center;
  display: flex;
  padding-right: 20px;
  padding-left: 23px;
  flex-direction: column;
  @media screen and (max-width: 1025px) {
    padding-left: 30px;
  }
`;

export const LinhaVertical = styled.div`
  border-left: 2px solid ${props => props.color};
  height: 100%;
  margin: 0 10px;
`;

export const CardSlugContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CardOpenInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const UpdateDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UpdateText = styled.div`
  font-family: 'Rubik';
  font-weight: 300;
  font-size: 12px;
  color: #5d6066;
  padding-left: 5px;
`;

export const LinkMessageDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
  }
`;

export const DateDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconsDiv = styled.div``;
