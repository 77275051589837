import React from 'react';
import PropTypes from 'prop-types';
import { MdCheck } from 'react-icons/md';

import { Badge } from '../Badge';

export const Check = ({ isChecked, isDisabled, color, bg = 'secondaries.Uno', ...props }) => (
  <Badge
    {...props}
    bg={isChecked ? bg : 'primaries.Taxi'}
    borderColor={Boolean(isDisabled && !isChecked) ? 'neutrals.EclipseE100' : bg}
    color={!isChecked ? bg : color}
  >
    {isChecked && <MdCheck />}
  </Badge>
);

// TODO: Add proptypes structure for design system colors.
Check.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  bg: PropTypes.string,
};
