export const noop = () => {};

/**
 * Excludes specifics properties from an object and returns a new object.
 * @param {object} obj object to exclude properties from.
 * @param {Array} propNames an array of the properties names to exclude.
 * @returns {object}
 */
export const exclude = (obj, ...propNames) => {
  const target = { ...obj };
  propNames.forEach(name => {
    if (name in target) delete target[name];
  });
  return target;
};

/**
 * Scrolls to top only if the current scrolling value is greater
 * than zero.
 */
export const scrollTop = () => {
  const element = document.querySelector('#app');
  if (element.scrollY > 0) element.scroll({ top: 0 });
};
