import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.button`
  background-color: transparent;
  justify-content: end;
  align-items: center;
  font-weight: normal;
  font-style: normal;
  text-align: right;
  line-height: 18px;
  font-size: 16px;
  cursor: pointer;
  color: #757778;
  display: flex;
  border: none;

  .label {
    margin-right: 13px;
  }
`;

export const FilterContent = styled.div`
  display: none;

  &.show {
    display: block;
  }
`;

export const MobileFilter = styled.div`
  visibility: hidden;

  @media (max-width: 882px) {
    visibility: visible !important;
  }
`;

export const DesktopFilter = styled.div`
  visibility: visible;

  @media (max-width: 882px) {
    visibility: hidden;
  }
`;
