import React from 'react';

export function ArrowLeft({ color = '#2F3741', ...props }) {
  return (
    <svg
      width={14}
      height={22}
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.09 11L14 19.556 11.455 22 0 11 11.455 0 14 2.446l-8.91 8.556V11z" fill={color} />
    </svg>
  );
}
