import { padDigits } from './number';

export const secondsToHHMMSS = value => {
  if (!value || typeof value !== 'number' || Math.floor(value) <= 0) {
    return '--:--:--';
  }

  let hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  let seconds = value - hours * 3600 - minutes * 60;

  seconds = Math.floor(Math.round(seconds * 100) / 100);
  hours = hours === 0 ? '' : `${padDigits(hours, 2)}:`;
  return `${hours}${padDigits(minutes, 2)}:${padDigits(seconds, 2)}`;
};
