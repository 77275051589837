import React from 'react';
import { FaArrowRight, FaRegSadTear } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { BsTrophyFill } from 'react-icons/bs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatToThousands } from '../../../../utils/number';
import { hasUserWon } from '../../../../utils/auction';
import { CarouselPhoto } from '../../..';
import { Container } from './styles';
import Extras from '../Extras';
import baixaChance from './img/baixa.svg';
import mediaChance from './img/media.svg';
import greenArrow from './img/greenArrow.svg';
import { useTemperature } from '../../../../hooks/useTemperature';

function SimpleCard({ user, auction, temperature }) {
  const history = useHistory();
  const { lowChances } = useTemperature(
    auction,
    auction.auction.bids[auction.auction.bids.length - 1].amount
  );

  function goToPurchasesPage() {
    history.push('/compras');
  }

  function won() {
    const bidLength = auction.auction.bids.length;
    if (bidLength < 1) return false;
    const _won = hasUserWon(auction.auction.bids[bidLength - 1], user, user.relatedDealers);
    return _won;
  }

  function title() {
    return `${auction.summary.make} ${auction.summary.model}`;
  }

  function temperaturedescription() {
    const { summary } = auction;
    const year = summary?.productionYear
      ? `${summary?.productionYear} / ${summary?.year}`
      : summary?.year;
    return `${auction.summary.make} ${auction.summary.model} ${year}`;
  }

  function description() {
    const { summary } = auction;
    const km = summary?.km ? `${formatToThousands(summary?.km)} Km` : '';
    const year = summary?.productionYear
      ? `${summary?.productionYear} / ${summary?.year}`
      : summary?.year;
    return `${year} ${summary?.version} ${km}`;
  }

  function getRestrictions() {
    const { summary } = auction;
    if (summary.documentResearch.historics?.length > 0) {
      return summary.documentResearch.historics.join(', ');
    }
    return false;
  }

  return (
    <Container
      className={!temperature ? (!won() ? 'lose' : '') : lowChances ? 'lowChance' : 'warmChance'}
    >
      {!temperature ? (
        won() ? (
          <Container.Status className="desktop-version mobile-version winning">
            <span>Você ganhou!</span> <BsTrophyFill />
          </Container.Status>
        ) : (
          <Container.Status className="desktop-version mobile-version losing">
            <span>Você não ganhou</span> <FaRegSadTear />
          </Container.Status>
        )
      ) : lowChances ? (
        <Container.Status className="desktop-version mobile-version lowChance">
          <span>Baixas chances</span> <img src={baixaChance} />
        </Container.Status>
      ) : (
        <Container.Status className="desktop-version mobile-version warmChance">
          <span>Média chance</span> <img src={mediaChance} />
        </Container.Status>
      )}
      <Container.Header
        img={auction.summary.photos[0]}
        className={!temperature && (!won() ? 'lose' : '')}
      >
        <div className="carousel-photos">
          <CarouselPhoto
            photos={auction.summary.photos}
            arrows
            dots
            counter
            height={170}
            css={{ borderRadius: '6px 6px 0 0' }}
            onClickSlide={() => {}}
            notifications
          ></CarouselPhoto>
        </div>
      </Container.Header>
      <Container.Body className={!temperature && (!won() ? 'lose' : '')}>
        <Container.Body.Content>
          {!temperature ? (
            <>
              <h5 className="title">{title()}</h5>
              <p className="description">
                <span className="car-name">{`${title()} `}</span>
                {description()}
              </p>
              <div className="extras">
                <Extras verticalStyle={true} auction={auction} />
              </div>
            </>
          ) : (
            <>
              <p className="description">
                <span className="temperatureDescription">{`Atenção, o leilão ${temperaturedescription()} encerra em breve! `}</span>
                Você está ganhando mas o seu lance está em{' '}
                <span className={lowChances ? 'temperatureLow' : 'temperatureWarm'}>
                  {lowChances ? 'baixas chances.' : 'média chance.'}
                </span>
              </p>
              {!temperature && (
                <div className="extras">
                  <Extras verticalStyle={true} auction={auction} />
                </div>
              )}
            </>
          )}
        </Container.Body.Content>
        {!temperature ? (
          won() && (
            <Container.Body.Btn onClick={goToPurchasesPage}>
              Ver meus leilões
              <span className="icon">
                <FaArrowRight />
              </span>
            </Container.Body.Btn>
          )
        ) : (
          <Container.Body.tempLink>
            <Link to={'detalhes/' + auction._id} className="temperatureDetail">
              <span>Clique aqui e aumente suas chances</span>
              <img src={greenArrow} className="icon" />
            </Link>
          </Container.Body.tempLink>
        )}
      </Container.Body>
      {getRestrictions() && (
        <Container.Footer className={!temperature && (!won() ? 'lose' : '')}>
          <Container.Footer.Container>
            <span className="title">Restrições: </span>
            {getRestrictions()}
          </Container.Footer.Container>
        </Container.Footer>
      )}
    </Container>
  );
}

function mapStateToProps({ authentication }) {
  return {
    user: authentication.user,
  };
}

export default connect(mapStateToProps)(SimpleCard);
