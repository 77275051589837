import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: ${({ shouldDisplay }) => (shouldDisplay ? 'flex' : 'none')};
  border: 1px solid #d2d4d7;
  align-items: center;
  margin-bottom: 10px;
  align-items: center;
`;

export const IconGridItem = styled.div`
  height: 100%;
  min-height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  max-width: 108px;
  min-width: 63px;
  background-color: #fdfcce;
`;

export const ContentGridItem = styled.div`
  padding: 4px 12px;

  h4 {
    font-weight: 700;
    font-size: 16px;
  }

  p {
    color: #757778;
    margin: 8px 0;

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;
