import React from 'react';
import { MdAdd } from 'react-icons/md';
import { Button } from './styles';

const PlusButton = ({ ...rest }) => (
  <Button {...rest}>
    <MdAdd size={20} fill="#fff" />
  </Button>
);

export default PlusButton;
