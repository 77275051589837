import styled from 'styled-components';
import Slider from '@mui/material/Slider';

export const Container = styled.div`
  width: 100%;
`;

Container.Label = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  height: 16px;
  width: 100%;

  .icon {
    margin-right: 8px;
    color: #2274a5;
  }

  .text {
    vertical-align: top;
    color: #757778;
  }
`;

Container.SliderWrapper = styled.div`
  margin-top: ${({ mt }) => (mt ? mt : 0)};
  .preview {
    font-family: ${props => `${props.fontFamily}, sans-serif`};
    font-style: normal;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    width: 100%;

    &.j-between {
      text-align: left;
      display: flex;
      justify-content: space-between;
    }
  }
`;

Container.Slider = styled(Slider)`
  .MuiSlider-thumb {
    box-shadow: 0px 2px 4px #2274a5;
    background-color: #fff;
    height: 22px;
    width: 22px;

    &:hover {
      box-shadow: 0px 2px 4px #2274a5;
    }
  }
  .muislider-track {
    height: 8px;
  }

  .MuiSlider-rail {
    color: #c4c4c4;
    height: 8px;
  }

  .MuiSlider-valueLabel {
    background: #e9e9e9;
    border-radius: 6px;
    font-style: normal;
    font-weight: bold;
    height: 29.11px;
    font-size: 14px;
    color: #2f3741;
    width: 51px;
  }
`;
