import styled from 'styled-components';

export const Container = styled.a``;

export const DropContainer = styled.a`
  display: flex;
  box-shadow: 0px 4px 16px 0px rgba(24, 94, 136, 0.15);
  background-color: ${props => props.backgroundColor};
  height: auto;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (max-width: 1025px) {
    height: auto;
  }
`;

export const Text = styled.div`
  font-family: 'Rubik';
  font-weight: ${props => props.weight};
  font-size: 14px;
  line-height: 30px;
  color: ${props => props.color};
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 1025px) {
    width: 90%;
    font-weight: 500;
    padding-left: 10px;
    align-items: center;
  }
`;

export const IconsDiv = styled.div`
  @media screen and (max-width: 1025px) {
    display: flex;
    align-items: center;
  }
`;

export const SubTextDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const UpdateDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UpdateText = styled.div`
  font-family: 'Rubik';
  font-weight: 300;
  font-size: 12px;
  color: #5d6066;
  padding-left: 5px;
`;

export const DateDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardOpenInfoStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardOpenInfo = styled.div`
  width: 100%;
`;

export const InfoText = styled.div`
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.color};
  padding-top: 10px;
  @media screen and (max-width: 1025px) {
    line-height: 15.6px;
    padding-top: 10px;
  }
`;

export const SubInfoDiv = styled.div`
  display: flex;
  align-items: center;
  line-height: 18.6px;
  width: 97%;
  flex-direction: row;
  width: 50%;
  @media screen and (max-width: 1025px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const AuthorizationLink = styled.a`
  font-family: 'Rubik';
  font-size: 14px;
  color: #171c24;
  text-decoration: underline;
  width: fit-content;
  color: #ff6c1c;
  padding-top: 5px;
`;

export const SubIndoDivLink = styled.div`
  width: 95%;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1025px) {
    flex-direction: column;
    align-items: normal;
  }
`;

export const CardSlugContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1025px) {
    width: 94%;
  }
`;

export const ArrowContainer = styled.div`
  padding-top: 6px;
`;
