import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 30%;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const CardContainer = styled.a`
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
  }
`;

export const InputBox = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  border-radius: 8px;
  padding-right: 5rem;
  width: 100%;
  height: 48px;
  border: 1px solid #b9bbbd;
  padding-left: 10px;

  &:focus::placeholder {
    padding-left: 0;
  }
`;

export const Button = styled.a`
  border: none;
  background: transparent;
  height: 100%;
  padding: 0 0.5rem;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding-right: 0.4rem;
`;
