import styled from 'styled-components';
import {
  alignSelf,
  bottom,
  color,
  display,
  flex,
  fontSize,
  height,
  justifySelf,
  left,
  order,
  overflow,
  position,
  right,
  size,
  space,
  top,
  width,
  zIndex,
  flexBasis,
} from 'styled-system';

export const Box = styled('div')(
  {
    boxSizing: 'border-box',
  },
  alignSelf,
  bottom,
  color,
  display,
  flex,
  fontSize,
  height,
  justifySelf,
  left,
  order,
  overflow,
  position,
  right,
  size,
  space,
  top,
  width,
  zIndex,
  flexBasis
);

Box.displayName = 'Box';

Box.propTypes = {
  ...alignSelf.propTypes,
  ...bottom.propTypes,
  ...color.propTypes,
  ...display.propTypes,
  ...flex.propTypes,
  ...fontSize.propTypes,
  ...height.propTypes,
  ...justifySelf.propTypes,
  ...left.propTypes,
  ...order.propTypes,
  ...overflow.propTypes,
  ...position.propTypes,
  ...right.propTypes,
  ...size.propTypes,
  ...space.propTypes,
  ...top.propTypes,
  ...width.propTypes,
  ...zIndex.propTypes,
  ...flexBasis.propTypes,
};
