import React from 'react';
import { bindActionCreators } from 'redux';
import { toggleModal } from '../../../../store/actions/ui.actions';
import { connect } from 'react-redux';
import Modal from '../../../enhanced/Modal';
import { TIEBREAKER_RULES_KEY } from '../../../../constants/modalConstants';

import { Content, Title, Description } from './styles';

const TiebreakerRules = ({ toggleModal, show }) => {
  return (
    <Modal show={show} toggle={() => toggleModal({ key: TIEBREAKER_RULES_KEY, show: false })}>
      <Content>
        <Title>Regra de desempate</Title>

        <Description>
          Caso outro lojista tenha estabelecido o <br /> mesmo valor máximo que você, o valor <br />{' '}
          do lance máximo será efetuado para o <br /> lojista que inseriu o limite primeiro.
          <br />
          <br />
          Enquanto o leilão estiver em andamento <br /> cada lojista pode aumentar{' '}
          <strong>até 02 vezes</strong> <br />o seu limite de orçamento para ofertas
          <br /> automáticas.
          <br />
          <br />
          <strong>
            Após 02 alterações a oferta automática
            <br /> é encerrada, sendo possível apenas
            <br />
            ofertas avulsas ou negociar com exclusividade.
          </strong>
        </Description>
      </Content>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

const mapStateToProps = state => ({
  show: state.ui.modals.tiebreakerRules.show,
});

export default connect(mapStateToProps, mapDispatchToProps)(TiebreakerRules);
