import { sortFilterParams } from '../constants/appConstants';
const sortByProp = (prop, order = 1) => (a, b) => order * (a.auction[prop] - b.auction[prop]);

export const sortLatestFirst = sortByProp('expectedEndTime', -1);
export const sortOldestFirst = sortByProp('expectedEndTime');
export const sortShortestExpectedTimeFirst = (car1, car2) => {
  const car1InitialExpectedEnd = car1.auction.startTime + car1.auction.initialDuration;
  const car2InitialExpectedEnd = car2.auction.startTime + car2.auction.initialDuration;
  return car1InitialExpectedEnd - car2InitialExpectedEnd;
};

export const sortAuctionsByFilter = (auctions, filterParam) => {
  const sortingMapperProp = {
    [sortFilterParams.LONGER_TIME]: sortLatestFirst,
    [sortFilterParams.SHORTER_TOTAL_TIME]: sortOldestFirst,
    [sortFilterParams.SHORTEST_EXPECTED_TIME]: sortShortestExpectedTimeFirst,
  };
  const sorterFn = sortingMapperProp[filterParam];
  return [...auctions].sort(sorterFn);
};
