import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from '@brainhubeu/react-carousel';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import '@brainhubeu/react-carousel/lib/style.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { Box } from '../../../abstract';
import { ArrowButton } from './ArrowButton';

export const getActiveImageIndex = (currentValue, totalImages) => {
  const total = Number(totalImages);
  const index = Number(currentValue) % total;

  if (index < 0) {
    return total + index;
  }

  return Math.abs(index);
};

const LazyImage = styled(LazyLoadImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

Image.displayName = 'Image';
LazyImage.displayName = 'LazyImage';

const ImageWrapper = styled(Box)`
  overflow: hidden;
`;

ImageWrapper.displayName = 'ImageWrapper';

const CarouselWrapper = styled(Box)`
  .lazy-load-image-loaded {
    width: 100% !important;
    height: 100% !important;
  }

  .slides-counter {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: #ffffff;
    font-size: 0.5rem;
    font-weight: 600;
  }

  .BrainhubCarousel__dots {
    position: absolute;
    bottom: 0.5rem;
    width: 100%;

    .BrainhubCarousel__dot {
      padding: 0.2rem;
      opacity: 0.3;

      &:before {
        width: 0.5rem;
        height: 0.5rem;
        background: #ffffff;
      }
    }
  }
`;

CarouselWrapper.displayName = 'CarouselWrapper';

const SlidesCounter = ({ current, total }) => (
  <div className="slides-counter">
    {current}/{total}
  </div>
);

SlidesCounter.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export const PhotoCarousel = trackWindowScroll(
  ({
    photos = [],
    hasBigArrows,
    sliderHeight,
    onClickSlide,
    showArrows,
    highlightActive,
    isLazy,
    scrollPosition,
    showCounter,
    auctionId,
    ...carouselProps
  }) => {
    return !photos ? null : (
      <CarouselWrapper position="relative">
        <Carousel
          arrowLeft={showArrows && <ArrowButton isBig={hasBigArrows} />}
          arrowRight={showArrows && <ArrowButton isBig={hasBigArrows} isRight />}
          addArrowClickHandler
          infinite
          {...carouselProps}
        >
          {photos.map((photo, index) => (
            <LazyImage
              key={photo.src || photo}
              s
              src={photo.src || photo}
              alt={photo.alt || ''}
              effect="opacity"
              width={371}
              height={sliderHeight}
            />
          ))}
        </Carousel>
      </CarouselWrapper>
    );
  }
);

PhotoCarousel.propTypes = {
  photos: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      })
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  hasBigArrows: PropTypes.bool,
  showArrows: PropTypes.bool,
  highlightActive: PropTypes.bool,
  sliderHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onClickSlide: PropTypes.func,
  isLazy: PropTypes.bool,
  showCounter: PropTypes.bool,
};
