import styled from 'styled-components';

export const CarouselContainer = styled.div`
  position: relative;
  min-width: 300px;
  padding-bottom: 30px;
`;

export const SlideContainer = styled.div`
  display: inline-block;
  flex-shrink: 0;

  min-width: 280px;
  max-width: 100%;
  width: 100%;
  height: 100%;

  padding-right: 10px;

  & > div {
    height: 99%;
  }
`;

export const Dot = styled.div`
  width: 20px;
  height: 20px;

  margin: 10px;

  background-color: ${({ active, isDetails }) =>
    isDetails ? (active ? '#438A4C' : '#d2d4d7') : active ? '#ffed1c' : '#d2d4d7'};
  border-radius: 50%;

  @media screen and (max-width: 880px) {
    display: none;
  }
`;

export const Button = styled.button`
  display: none;

  @media screen and (min-width: 1024px) {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 70px;
    height: 35px;

    background-color: transparent;
    border: 1px solid ${({ color }) => color};

    cursor: pointer;
  }
`;
