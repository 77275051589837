import styled from 'styled-components';
import _Alert from '@mui/material/Alert';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-width: 15vw;

  @media (max-width: 882px) {
    width: 100%;
    max-width: none;
  }

  label {
    font-weight: 600;
    margin-top: 20px;
  }

  button {
    margin-top: 20px;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 8px 20px;
`;

export const ImageContainer = styled.div`
  flex-shrink: 3;
  img {
    max-width: 600px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
  @media (max-width: 882px) {
    display: none;
    opacity: 0;
  }
`;

export const Alert = styled(_Alert)`
  margin: 4px 2px;
`;
