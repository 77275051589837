import get from 'lodash/get';

import Transmission from './transmission';
import { formatToThousands } from './number';
import { auctionStatus } from '../constants/appConstants';
import { getCurrentElapsed } from './dateUtils';
import objectAssign from 'object-assign';
import { getAuctions } from '../services/auctions';

export function getAuctionFromStart(data) {
  return {
    _id: data.id,
    summary: {
      goodPointsCount: data?.goodPointsCount || 0,
      km: data.km,
      mainPhoto: data.photos.main,
      photos: [...(data.photos.all || [data.photos.main])],
      make: data.make,
      model: data.model,
      version: data.version,
      year: data.year,
      mechanicPicture: data.mechanicPicture,
      productionYear: data.productionYear,
      summary: data.summary,
      highlights: data.highlights,
      plateNumber: data.plateNumber,
      marketPrice: data.prices.market,
      maxPrice: data.prices.max,
      minPrice: data.prices.min,
      startingPrice: data.prices.startPrice,
      isArmored: data.isArmored,
      inspectionCondition: data.conditions,
      ecvReport: data.ecvReport,
      inspectionReport: data.inspectionReport,
      buynowPrice: data.prices.buyNow || { amount: 0, enabled: false },
      buyForPrice: data.prices.buyFor || { amount: 0, enabled: false },
      documentResearch: data.documentResearch || {},
      plateLocation: data.plateLocation,
      vehicleDetails: data.vehicleDetails,
      kilometrageAcceptable: data?.summary?.kilometrageAcceptable,
      precautionaryReport: data?.summary?.precautionaryReport,
      isBelowThreshold: data?.summary?.isBelowThreshold,
      allMechanicsCorrect: data?.summary?.allMechanicsCorrect,
      fastSpin: data?.summary?.fastSpin,
    },
    auction: {
      winningBid: { amount: data.prices.startPrice },
      idAuction: data.idAuction,
      remainingTime: data.remainingTime,
      initialDuration: data.remainingTime,
      startTime: data.timestamp,
      expectedEndTime: data.expectedEndTime,
      bids: [],
      onGoing: true,
      lost: false,
      won: false,
      outBid: false,
      showMakeOffer: false,
      winning: false,
      participating: false,
    },
  };
}

export function isUserParticipating(auction, user, relatedDealers = [], ninjaDealership = '') {
  if (!auction || !auction.auction.bids || !Array.isArray(auction.auction.bids)) {
    return false;
  }

  if (
    auction.auction.bids.some(bid => {
      return user.email === bid.dealer.email;
    })
  ) {
    return true;
  }

  if (user.isNinja() && !ninjaDealership) {
    return auction.auction.bids.some(bid => {
      return (
        relatedDealers.some(dealer => {
          return String(dealer.dealershipName).trim() === String(bid.dealership).trim();
        }) || isUserWinningBid(bid, user, relatedDealers)
      );
    });
  } else if (user.isNinja() && ninjaDealership) {
    return auction.auction.bids.some(bid => {
      return (
        String(ninjaDealership).trim() === String(bid.dealership).trim() ||
        isUserWinningBid(bid, user, relatedDealers)
      );
    });
  }
  return false;
}

export function bidsByUser(auction, user, relatedDealers, selectedDealership) {
  if (!Array.isArray(auction.auction.bids) || !auction.auction.bids.length) {
    return [];
  }

  if (user.isNinja() && selectedDealership) {
    return auction.auction.bids.filter(b => b.dealership === selectedDealership);
  }
  return auction.auction.bids.filter(b => user.email === b.bidder.email);
}

export function isUserWinning(auction, user, relatedDealers = []) {
  if (!auction.auction.winningBid) {
    return false;
  }
  return isUserWinningBid(auction.auction.winningBid, user, relatedDealers);
}

export const hasUserWon = (bid, user, relatedDealers = []) => {
  if (bid.dealer && bid.dealer.id === user.id) {
    return true;
  }

  return isUserWinningBid(bid, user, relatedDealers);
};

export function isUserWinningBid(bid, user, relatedDealers = []) {
  if (bid.bidder && bid.bidder.email === user.email) {
    return true;
  }
  if (user.isNinja()) {
    return relatedDealers.some(dealer => {
      return bid.dealership && bid.dealership === dealer.dealershipName;
    });
  }
  if (user.dealershipName) {
    return bid.dealership && bid.dealership === user.dealershipName;
  }
  return false;
}

export function getAuctionById(auctions, id) {
  const auction = auctions.filter(auc => auc._id === id);
  return auction.length === 0 ? null : auction[0];
}

export function getIconStatusClass(auction) {
  if (!auction.auction.participating) {
    return '';
  }

  return auction.auction.winning ? 'icon-ICO_thumb' : 'icon-ICO_sad';
}

export function getStatusTooltip(auction) {
  if (!auction.auction.participating) return '';

  return auction.auction.participating && auction.auction.winning
    ? 'Você está ganhando este leilão!'
    : 'Você está perdendo esse leilão, faça uma oferta!';
}

export function getAuctionFromDetails(data) {
  return {
    _id: data.id,
    auctionId: data.idAuction,
    summary: {
      answersCount: data.evaluation.answersCount,
      approvedAnswersCount: data.evaluation.approvedAnswersCount,
      km: data.vehicle.mileage,
      mainPhoto: data.vehicle.photos.main,
      make: data.vehicle.make,
      model: data.vehicle.model,
      version: data.vehicle.version,
      year: data.vehicle.year.actual,
      mechanicPicture: data.evaluation.evaluator.profilePicture || '/images/logo--smal.svg',
      mechanicName: data.evaluation.evaluator.name || 'instacarro',
      productionYear: data.vehicle.year.production,
      summary: data.evaluation.evaluator.remarks,
      plateNumber: data.vehicle.plate,
      marketPrice: data.vehicle.price.market,
      maxPrice: data.vehicle.price.max,
      minPrice: data.vehicle.price.min,
      startingPrice: data.vehicle.price.starting,
      isArmored: data.isArmored,
      inspectionCondition: data.conditions,
      ecvReport: data.ecvReport,
      inspectionReport: data.inspectionReport,
      photos: data.vehicle.photos.all,
      evaluation: data.evaluation,
      buynowPrice: data.vehicle.price.buyNow || { amount: 0, enabled: false },
      buyForPrice: data.vehicle.price.buyFor || { amount: 0, enabled: false },
      advertisementPrice: data.vehicle.price.advertisement,
      status: data.vehicle.status,
      documentResearch: data.documentResearch || {},
      vehicleYardRegion: data.vehicle.vehicleYardRegion,
      plateLocation: data.vehicle.plateLocation,
      vehicleDetails: data.vehicle.vehicleDetails,
      nf: data.nf,
      webPrice: data.webmotors_avg,
    },
    auction: {
      winningBid: {
        amount:
          data.vehicle.price.final > 0 ? data.vehicle.price.final : data.vehicle.price.starting,
      },
      expectedEndTime: data.auction.endTime ? data.auction.endTime.expected : 0,
      remainingTime: data.auction.remainingTime ? data.auction.remainingTime : 0,
      initialDuration: data.auction.duration ? data.auction.duration.initial : 0,
      startTime: data.auction.startTime ? data.auction.startTime : 0,
      bids: [],
      autoBids: [],
      onGoing: data.auction.remainingTime ? data.auction.remainingTime > 0 : false,
      lost: false,
      won: false,
      outBid: false,
      showMakeOffer: false,
      winning: data.auction.winning,
      taxId: data.auction.taxId,
      taxIdentificationNumbers: data.auction.taxIdentificationNumbers,
      participating: data.auction.participating,
      numberOfDealersParticipating: data.auction.numberOfDealersParticipating || 0,
      viewCount: data.auction.viewCount,
      vehicle: data.vehicle,
      favoriteCount: data.auction.favoriteCount,
    },
  };
}

export function hasManual(auction) {
  return hasHighLight('Manual original presente', auction);
}

export function hasBackupKey(auction) {
  return hasHighLight('Chave-reserva presente', auction);
}

export function hasAirConditioner(auction) {
  return hasFeature('Ar condicionado', auction);
}

export function hasHydraulicSteering(auction) {
  return hasFeature('Direção Hidraulica', auction);
}

export function hasValityPaper(auction) {
  return hasHighLightCautelar('Laudo Cautelar: Aprovado', auction);
}

export function hasInvalityPaper(auction) {
  return hasHighLight('Pericia Cautelar sujeito a ressalva', auction);
}

export function getTransmissionType(auction) {
  return Transmission.getTranny(auction.summary.version);
}

export function getEvaluationPointsOverview(auction) {
  const res = {
    totalPoints: 0,
    totalSuccess: 0,
    totalFailure: 0,
    percentage: 0,
  };
  auction.summary.evaluation.benchmarks.items.forEach(benchmark => {
    const filteredGood = benchmark.items.filter(item => {
      return item.passed;
    });
    const filteredBad = benchmark.items.filter(item => {
      return !item.passed;
    });
    res.totalFailure += filteredBad.length;
    res.totalSuccess += filteredGood.length;
  });

  res.totalPoints = res.totalFailure + res.totalSuccess;

  if (res.totalSuccess > 0 && res.totalPoints > 0) {
    res.percentage = Math.round((res.totalSuccess / res.totalPoints) * 100);
  }

  return res;
}

/**
 * Given an array of auctions it will return a sorted not repeated array of texts with
 * '${Make} ${Model}' from the auctions
 * @param auctions
 * @returns {Array.<string>}
 */
export function getFlatResults(auctions) {
  const results = [];
  auctions.forEach(auction => {
    const flatName = `${auction.summary.make} ${auction.summary.model}`;
    if (results.indexOf(flatName) === -1) {
      results.push(flatName);
    }
  });
  return results.sort();
}

function hasHighLight(name, auction) {
  return auction.summary.evaluation.highlights.some(highlight => {
    return highlight.description.toLowerCase() === name.toLowerCase();
  });
}

function hasHighLightCautelar(name, auction) {
  return auction?.summary?.highlights?.some(highlight => {
    return (
      removeAcentos(highlight.description.toLowerCase()) === removeAcentos(name.toLowerCase()) ||
      removeAcentos(highlight.description.toLowerCase()) ===
        removeAcentos('Perícia Cautelar aprovada'.toLowerCase())
    );
  });
}

function hasFeature(name, auction) {
  return auction.summary.evaluation.features.some(feature => {
    return feature.items.some(highlight => {
      return highlight.description.toLowerCase() === name.toLowerCase();
    });
  });
}

export function getEndingSoonestAuction(auctions, user, relatedDealers = []) {
  let auction = {};
  if (auctions.length === 0) {
    return auction;
  }

  const _auctions = auctions.slice(0).filter(auct => {
    return isUserParticipating(auct, user, relatedDealers);
  });

  const sort = (a, b) => {
    return b.auction.remainingTime - a.auction.remainingTime;
  };

  if (_auctions.length >= 1) {
    _auctions.sort(sort);
    auction = _auctions[0];
  } else {
    const _auctionsCpy = auctions.slice(0).sort(sort);
    auction = _auctionsCpy[0];
  }
  return auction;
}

export function getDetailIconsAmount(car) {
  let amount = 1;
  amount += hasValityPaper(car) ? 1 : 0;
  amount += hasAirConditioner(car) ? 1 : 0;
  amount += hasHydraulicSteering(car) ? 1 : 0;
  return amount;
}

export function getYearLabel(auction) {
  const { productionYear, year } = auction.summary;
  return productionYear ? `${productionYear} / ${year}` : year;
}

export function getYearLabelStockCar(car) {
  return getYearLabel({
    summary: car,
  });
}

export function getKmLabel(auction, kmSpacing = false) {
  const { km } = auction.summary;
  return km ? `${formatToThousands(km)}${kmSpacing ? 'km' : ' km'}` : 'N/A';
}

export const getDescription = ({ summary }) => {
  return `${summary.make} ${summary.model} ${summary.version} ${summary.year}`;
};

export const applyFilters = (
  auctions,
  { price, kilometers, attentionPoints, metadata, search, locations }
) => {
  const doFilter = ({
    auction: { winningBid },
    summary: { make, model, year, version, km, approvedAnswersCount, answersCount, state },
  }) => {
    if (metadata.count > 0) {
      let meetsFilters =
        winningBid.amount <= price &&
        answersCount - approvedAnswersCount <= attentionPoints &&
        km <= kilometers;

      if (locations.length) {
        meetsFilters = meetsFilters && locations.includes(state);
      }

      return search.length
        ? meetsFilters &&
            search
              .map(keyword => keyword.toUpperCase())
              .every(
                keyword =>
                  `${make} ${model} ${year} ${version}`.includes(keyword) ||
                  `${make}`.includes(keyword) ||
                  `${make} ${year}`.includes(keyword)
              )
        : meetsFilters;
    }
    return true;
  };
  return auctions.filter(doFilter);
};

export const isAuctionOfferAccepted = status => {
  return [
    auctionStatus.offerAccepted,
    auctionStatus.paymentPending,
    auctionStatus.paymentReady,
    auctionStatus.waitingWithdrawal,
    auctionStatus.delivered,
    auctionStatus.documentationPending,
    auctionStatus.documentationReady,
    auctionStatus.finished,
  ].includes(status);
};

export const addCommonPropsToAuction = auction => ({
  ...auction,
  summary: {
    ...auction.summary,
    yearLabel: getYearLabel(auction),
    description: getDescription(auction),
    kmLabel: getKmLabel(auction),
    startingPrice: auction.summary.startingPrice || 0,
  },
});

export function defineSupperOfferAuctions(
  listing,
  {
    now = Date.now(),
    threshold = 3600000,
    minimunExpectedParticipants = 4,
    supperOfferMaximumSize = 10,
  } = {}
) {
  const evaluateSupperOffer = auction => {
    const expectedEndTime = auction.initialDuration + auction.startTime;
    const remainingTime = expectedEndTime - now;
    const isAuctionFinishing = remainingTime < threshold;
    const auctionBids = auction.bids || [];
    const auctionParticipants = auctionBids.map(bid => bid.dealer.email);
    const participantSet = new Set(auctionParticipants);
    const participantsCount = participantSet.size;

    const isSupperOffer = participantsCount < minimunExpectedParticipants && isAuctionFinishing;
    return isSupperOffer;
  };

  let superOffersCount = 0;

  return listing.map(car => {
    const currentAuction = { ...car.auction };
    const isSupperOffer = evaluateSupperOffer(currentAuction);
    const hasReachedMaximum = superOffersCount >= supperOfferMaximumSize;
    currentAuction.isSupperOffer = isSupperOffer && !hasReachedMaximum;

    if (currentAuction.isSupperOffer) superOffersCount += 1;

    return { ...car, auction: currentAuction };
  });
}

export function formatAuctionObjectKeys(auction, requestStartTime, user, relatedDealers = []) {
  const elapsedTime = getCurrentElapsed();
  const requestEndTime = elapsedTime - requestStartTime;
  auction.elapsedSinceLastRequest = elapsedTime;
  const { remainingTime, winningBid } = auction.auction;
  auction.auction.remainingTime = remainingTime - requestEndTime;
  const winning = isUserWinning(auction, user, relatedDealers);
  const participating = isUserParticipating(auction, user, relatedDealers);
  auction.auction.onGoing = true;
  winningBid.amount = winningBid.amount > 0 ? winningBid.amount : auction.summary.startPrice;
  auction.auction.won = false;
  auction.auction.lost = false;
  auction.auction.outBid = participating && !winning;
  auction.auction.showMakeOffer = false;
  auction.auction.winning = winning;
  auction.auction.participating = participating;
  auction.auction.status = auction.auction.status !== 'STARTED' ? 'FINISHED' : 'AUCT_ONGOING';
  auction.auction.outBid = false;
  auction.summary.documentResearch = auction.summary.documentResearch || {};
  return addCommonPropsToAuction(auction);
}

export function getParticipatingAuctions(auctions) {
  return auctions.filter(auction => auction.auction.participating);
}

export function getNewOffersAuctions(auctions) {
  return auctions.filter(auction => !auction.auction.participating);
}

export function updateDetailedAuction(actualDetailedAuction, newDetailedAuction) {
  return {
    ...actualDetailedAuction,
    auction: {
      ...actualDetailedAuction.auction,
      remainingTime: newDetailedAuction.auction.remainingTime,
    },
    elapsedSinceLastRequest: newDetailedAuction.elapsedSinceLastRequest,
  };
}

function wasAlreadyParticipating(participatingAuctions, wantedAuctionId) {
  return participatingAuctions?.find(
    participatingAuction => participatingAuction._id === wantedAuctionId
  );
}

export async function updateParticipatingAuctionsOutBid(user, relatedDealers) {
  try {
    let auctions = await getAuctions();
    auctions = auctions.map(auction =>
      formatAuctionObjectKeys(auction, getCurrentElapsed(), user, relatedDealers)
    );
    return getParticipatingAuctions(auctions);
  } catch {
    return [];
  }
}

function updateAuctionBids(auction, action) {
  return objectAssign({}, auction, {
    auction: objectAssign({}, auction.auction, {
      bids: auction.auction.bids.slice().concat(action.bid),
      winningBid: action.winningBid,
      outBid: action.outBid,
      winning: action.winning,
      participating: action.participating,
    }),
  });
}

export function updateAuctionsOutBid(state, action) {
  let participatingAuctions = [...state.participatingAuctions];
  let newOffers = [...state.newOffers];
  let modified = {};

  if (action.participating) {
    if (wasAlreadyParticipating(participatingAuctions, action.id)) {
      participatingAuctions = participatingAuctions.map(auction => {
        if (auction._id !== action.id) return auction;
        modified = updateAuctionBids(auction, action);
        return modified;
      });
    } else {
      const index = newOffers.findIndex(auction => auction._id === action.id);
      if (index !== -1) {
        const removedAuction = newOffers.splice(index, 1).shift();
        modified = updateAuctionBids(removedAuction, action);
        participatingAuctions.push(modified);
      }
    }
  } else {
    newOffers = newOffers.map(auction => {
      if (auction._id !== action.id) return auction;
      modified = updateAuctionBids(auction, action);
      return modified;
    });
  }

  return [participatingAuctions, newOffers, modified];
}

export function updateAuctionTimer(auction, action) {
  let _modifiedAuction = objectAssign({}, auction, {
    auction: objectAssign({}, auction.auction, {
      remainingTime: action.remainingTime,
    }),
    elapsedSinceLastRequest: action.elapsedSinceLastRequest,
  });

  if (action.endTime) {
    _modifiedAuction.auction.expectedEndTime = action.endTime;
  }

  return _modifiedAuction;
}
