import React, { useState, useEffect } from 'react';

import { setShowed } from '../../utils/freeShippingDialog';
import FlexDialog from '../FlexDialog';
import Modal from '../enhanced/Modal';
import { Dialog } from '../dialog';
import styled from 'styled-components';

const Message = styled.p`
  font: 400 normal 1rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;

function FreeShippingDialog() {
  const [showFreeShippingDialog, setShowFreeShippingDialog] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFreeShippingDialog(false);
      setShowed();
    }, 30000);
    return () => clearTimeout(timer);
  }, []);

  function closeMobileDialog() {
    setShowFreeShippingDialog(false);
    setShowed();
  }

  return (
    <>
      {window.screen.width < 769 ? (
        <Modal show={showFreeShippingDialog}>
          <Dialog header="ATENÇÃO!" onCTAClick={closeMobileDialog} payment>
            <Message>
              Amigo lojista, <br />
              <br />
              Fique atento ao pagamento em <strong>até 24h</strong> do aceite de sua proposta, para
              não correr o risco de ficar impossibilitado de dar novos lances!
            </Message>
          </Dialog>
        </Modal>
      ) : (
        <FlexDialog show={showFreeShippingDialog} title="ATENÇÃO!">
          <Message>
            Amigo lojista, <br />
            <br />
            Fique atento ao pagamento em <strong>até 24h</strong> do aceite de sua proposta, para
            não correr o risco de ficar impossibilitado de dar novos lances!
          </Message>
        </FlexDialog>
      )}
    </>
  );
}

export default FreeShippingDialog;
