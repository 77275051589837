import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  margin-bottom: ${props => props.mb ?? 12}px;

  border: 0.063rem solid #d2d4d7;
  border-radius: 0.25rem;
`;

export const Select = styled.select`
  max-width: 100%;
  width: 100%;

  padding: 0.8rem 0.875rem;

  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  background-color: transparent;
  appearance: none;

  border: none;
  outline: none;

  cursor: pointer;
  z-index: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArrowDown = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  cursor: pointer;
  z-index: 0;
`;
