import styled from 'styled-components';

export const Container = styled.div`
  flex-wrap: wrap;
  display: flex;
  width: 100%;
`;

Container.Item = styled.span`
  background: #5d5d5e;
  border-radius: 16px;
  align-items: center;
  padding: 0 10px;
  display: flex;
  height: 23px;

  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: #ffffff;

  margin-bottom: 12px;
  margin-right: 12px;

  .close {
    background: #ffffff;
    border-radius: 50%;
    margin-left: 10px;
    font-weight: bold;
    font-size: 11px;
    cursor: pointer;
    color: #5d5d5e;
    height: 11px;
    width: 11px;
  }
`;
