import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

const minDistance = 10;

const SliderFilter = memo(({ label = '', value, onChange, max = 100, min = 0 }) => {
  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      onChange([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      onChange([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="caption">{label}</Typography>
      <Box sx={{ padding: 1 }}>
        <Slider
          getAriaLabel={() => 'Minimum distance'}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          size="small"
          min={min}
          max={max}
          disableSwap
        />
      </Box>
    </Box>
  );
});

export default SliderFilter;
