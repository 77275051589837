import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { MdAddCircle } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getAuthenticatedUser } from '../../store/selectors/userSelectors';
import { getTaxIdList, updateDefaultTaxId } from '../../services/taxids';
import { ChangeTaxIdDialog } from '../../components/change-taxid-dialog';
import { EmptyState } from '../../components/enhanced/EmptyState';
import { Layout, Box, Flex, Loading } from '../../components';
import { Button } from '../../components/enhanced/Button';
import { Accordion } from '../../components/accordion';
import Modal from '../../components/enhanced/Modal';
import Content from './components/content';
import Header from './components/header';
import { TopBar } from './styles';
import { useSegment } from '../../hooks/useSegment';

const VIEW_STATUS = {
  CONFIRM_DEFAULT_CHANGE: 0,
  IDLE: 1,
};

const toastProps = {
  autoClose: 10000,
  closeButton: false,
};

export const TaxProfiles = () => {
  const [taxIdSelected, setTaxIdSelected] = useState(null);
  const [status, setStatus] = useState(VIEW_STATUS.IDLE);

  const { user } = useSelector(getAuthenticatedUser);
  const { page } = useSegment();

  const query = useQuery('taxids', getTaxIdList);
  const queryClient = useQueryClient();

  let taxids = [];
  let items = [];

  const defaultTaxIdMutation = useMutation(updateDefaultTaxId, {
    onSuccess: () => {
      queryClient.invalidateQueries('taxids');
      setTaxIdSelected(null);
      toast.success('O seu perfil padrão foi alterado com sucesso!!!', toastProps);
    },
    onError: error => {
      toast.error('Sua alteração não foi realizada, tente novamente', toastProps);
    },
  });

  const handleDefaultProfileChange = value => {
    setTaxIdSelected(value);
    setStatus(VIEW_STATUS.CONFIRM_DEFAULT_CHANGE);
  };

  const handleDefaultProfileChangeCancel = () => {
    setStatus(VIEW_STATUS.IDLE);
    setTaxIdSelected(null);
  };

  const handleDefaultProfileChangeConfirm = async () => {
    setStatus(VIEW_STATUS.IDLE);
    await defaultTaxIdMutation.mutateAsync({ userId: user.id, taxId: taxIdSelected });
  };

  const getTaxProfileById = id => {
    if (!taxids.length) return {};

    const [taxIdFound] = taxids.filter(item => item._id === id);
    return taxIdFound || {};
  };

  const getDefaultTaxId = () => {
    const [taxIdFound] = taxids.filter(taxId => taxId.default);

    if (taxIdFound) {
      return taxIdFound._id;
    }

    return null;
  };

  if (query.isSuccess) {
    // TODO: Investigate how to clean this uggly nested object.
    taxids = query.data?.data?.data || [];
    items = taxids.map(taxId => ({
      id: taxId._id,
      header: () => (
        <Header
          taxId={taxId}
          status={status}
          defaultTaxIdMutation={defaultTaxIdMutation}
          handleDefaultProfileChange={handleDefaultProfileChange}
        />
      ),
      content: () => <Content taxId={taxId} />,
    }));
  }

  useEffect(() => {
    page('Perfis');
  }, []);

  return (
    <Layout>
      <Flex flexDirection="column" width={1}>
        <TopBar height={{ sm: '2.5rem', md: '3.7rem' }} fontSize={{ sm: 2, md: 5 }}>
          Cadastro de perfis para emissão dos documentos
        </TopBar>
        {query.isLoading ? (
          <Loading css={{ margin: 'auto', position: 'relative' }} />
        ) : (
          <Box className="container" mx="auto" p={1} width={1} css={{ maxWidth: '960px' }}>
            {items.length > 0 ? (
              <>
                <Flex justifyContent="flex-end" mb={1}>
                  <Button as={Link} variant="secondary" to="/perfis/novo" isRounded>
                    <Button.Icon>
                      <MdAddCircle />
                    </Button.Icon>
                    Solicitar novo cadastro
                  </Button>
                </Flex>
                <Accordion items={items} />
              </>
            ) : (
              <EmptyState
                title="OPS.... Você não tem nenhum perfil cadastrado :("
                subtitle="Você ainda não tem nenhum perfil cadastrado."
                description="Para criar um novo perfil entre em contato com o responsável pelo seu atendimento ou via suporte@instacarro.com."
                css={{ position: 'relative' }}
              />
            )}
          </Box>
        )}
      </Flex>
      <Modal
        show={status === VIEW_STATUS.CONFIRM_DEFAULT_CHANGE && taxIdSelected}
        toggle={handleDefaultProfileChangeCancel}
        hideCloseBtn
      >
        <ChangeTaxIdDialog
          header="Você gostaria de alterar o seu perfil padrão?"
          onCTAClick={handleDefaultProfileChangeConfirm}
          onSecondaryBtnClick={handleDefaultProfileChangeCancel}
          oldTaxId={getTaxProfileById(getDefaultTaxId())}
          newTaxId={getTaxProfileById(taxIdSelected)}
        />
      </Modal>
    </Layout>
  );
};
