import React, { useMemo } from 'react';

import { Container, Description, Title, Icon, Underline } from './styles';
import { useTemperature } from '../../../hooks/useTemperature';
import { toggleModal } from '../../../store/actions/ui.actions';
import { useDispatch } from 'react-redux';
import { AUCTION_OFFER_KEY, TEMPERATURE_MODAL_KNOW_MORE } from '../../Modals';

export const Basic = ({
  auction,
  currentValue,
  colored = false,
  tooltip = false,
  isDetails = false,
}) => {
  const dispatch = useDispatch();
  const { currentTemperature } = useTemperature(auction, currentValue);
  const { icon: tempIcon, title, description, color: tempColor, bgColor } =
    currentTemperature || {};

  const color = useMemo(() => {
    if (!tooltip && isDetails) return '#fff';
    return tooltip ? '#2f3741' : tempColor;
  }, [tooltip, isDetails, tempColor]);

  const icon = useMemo(() => {
    if (!tooltip && isDetails) return tempIcon?.details;
    return tooltip ? tempIcon?.toast : tempIcon?.participate;
  }, [tooltip, isDetails, tempIcon]);

  const openKnowMore = () => {
    dispatch(toggleModal({ key: AUCTION_OFFER_KEY, show: false }));
    dispatch(
      toggleModal({
        key: TEMPERATURE_MODAL_KNOW_MORE,
        show: true,
        extras: { selectedAuction: auction },
      })
    );
  };

  return (
    <Container colored={colored} bg={bgColor} borderColor={tempColor}>
      <Icon src={icon} />
      <Title color={!tooltip && isDetails ? '#fff' : tempColor}>
        {title}:{' '}
        <Description color={color}>
          {description} <Underline onClick={openKnowMore}>Saiba mais.</Underline>
        </Description>
      </Title>
    </Container>
  );
};
