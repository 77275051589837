import React from 'react';
import { MdClose } from 'react-icons/md';
import { themeGet } from 'styled-system';

import { Button } from '../../abstract';
import { padding } from 'polished';

export const CloseButton = props => (
  <Button
    variant="link.inverse"
    style={{
      marginLeft: 'auto',
      top: '18px',
      padding: 0,
    }}
    {...props}
  >
    <MdClose size={40} color={themeGet('colors.primaries.Taxi')(props)} />
  </Button>
);
