import styled from 'styled-components';

import { Box } from '../../../abstract';

export const Container = styled.div`
  border: 1px solid ${({ isHighlight }) => (isHighlight ? '#FCAB10' : '#c4c4c4')};
  box-shadow: 0px 4px 4px 0px #00000040;
  box-sizing: border-box;
  background: #ffffff;
  margin-bottom: 16px;
  border-radius: 8px;
  position: relative;
  min-height: 170px;
  padding: 16px 0;
  width: 100%;

  flex-direction: row;
  display: flex;

  &.disabled {
    opacity: 0.5;
  }

  /* VERTICAL STYLE */
  &.vertical-style {
    flex-direction: column;
    padding: 16px 0;
  }

  @media (max-width: 882px) {
    flex-direction: column;
    padding: 16px 0;
  }

  .left {
    grid-template-columns: 39% 61%;
    display: grid;
    width: 100%;

    /* VERTICAL STYLE */
    &.vertical-style {
      grid-template-columns: 34% 66%;
    }

    @media (max-width: 882px) {
      grid-template-columns: 34% 66%;
    }

    .image {
      justify-content: center;
      align-items: top;
      display: flex;

      .image-container {
        border-radius: 8px;
        max-height: 134px;
        overflow: hidden;
        max-width: 200px;
        display: block;
        width: 100%;
      }

      /* VERTICAL STYLE */
      &.vertical-style {
        grid-column: span 2;
        margin-bottom: 16px;
        padding: 0 18px;

        .image-container {
          max-height: 194px;
          max-width: 100%;
        }
      }

      @media (max-width: 882px) {
        grid-column: span 2;
        margin-bottom: 16px;
        padding: 0 18px;

        .image-container {
          max-height: 194px;
          max-width: 100%;
        }
      }
    }
    .description {
      min-height: 99px;

      display: flex;
      flex-direction: column;
      justify-content: top;

      /* VERTICAL STYLE */
      &.vertical-style {
        grid-column: span 2;
        min-height: 58px;
        padding: 0 18px;
      }

      @media (max-width: 882px) {
        grid-column: span 2;
        min-height: 58px;
        padding: 0 18px;
      }
    }
    .extras {
      justify-content: space-between;
      align-items: center;
      max-width: 266px;
      display: flex;
      height: 24px;
      width: 100%;

      /* VERTICAL STYLE */
      &.vertical-style {
        max-width: 100%;
      }

      @media (max-width: 882px) {
        max-width: 100%;
      }
    }
  }

  .right {
    grid-template-columns: 56% 44%;
    display: grid;
    width: 100%;

    /* VERTICAL STYLE */
    &.vertical-style {
      margin-top: 15px;
    }

    @media (max-width: 882px) {
      margin-top: 15px;
    }

    .bid-value {
      background-color: #fff;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      max-width: 298px;
      margin-left: 10%;
      display: flex;

      /* VERTICAL STYLE */
      &.vertical-style {
        grid-column: span 2;
        margin-bottom: 16px;
        margin-top: 10px;
        padding: 0 18px;
        max-width: 100%;
        margin-left: 0;
      }

      @media (max-width: 882px) {
        margin-top: 0 !important;
        grid-column: span 2;
        margin-bottom: 16px;
        padding: 0 18px;
        max-width: 100%;
        margin-left: 0;
      }
    }
    .countdown {
      justify-content: end;
      align-items: center;
      padding-right: 36px;
      display: flex;

      /* VERTICAL STYLE */
      &.vertical-style {
        grid-column: span 2;
        grid-row-start: 1;
        text-align: right;
        padding: 0 18px;
        grid-row-end: 2;
        width: 100%;
        order: 1;
      }

      @media (max-width: 882px) {
        grid-column: span 2;
        grid-row-start: 1;
        text-align: right;
        padding: 0 18px;
        grid-row-end: 2;
        width: 100%;
        order: 1;
      }
    }
  }

  .mobile-footer {
    flex-direction: column;
    padding: 0 20px;
    display: none;
    width: 100%;

    /* VERTICAL STYLE */
    &.vertical-style {
      display: flex;
    }

    @media (max-width: 882px) {
      display: flex;
    }

    .divider {
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin-bottom: 8px;
      align-self: center;
      margin-top: 16px;
      width: 95%;
      height: 0;
    }
  }
`;

export const CountdownBox = styled(Box)`
  width: 113px;

  /* VERTICAL STYLE */
  &.vertical-style {
    width: 100%;
  }

  @media (max-width: 882px) {
    width: 100%;
  }
`;

export const Presentation = styled.div``;

Presentation.Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
`;

Presentation.FavoriteBtn = styled.button`
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  color: #db4647;
  border: none;
  height: 24px;
  width: 24px;
`;

Presentation.Title = styled.a`
  margin-bottom: 10px;
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  font-size: 16px;
  color: #212121;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

Presentation.Description = styled.p`
  font-weight: normal;
  margin-bottom: 10px;
  white-space: normal;
  font-style: normal;
  line-height: 16px;
  font-size: 14px;
  color: #757778;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }

  .bold {
    font-weight: 700;
  }

  .small {
    font-size: 12px;
  }

  /* VERTICAL STYLE */
  &.vertical-style.hide-when-mobile {
    display: none;
  }

  @media (max-width: 882px) {
    &.hide-when-mobile {
      display: none;
    }
  }
`;

Presentation.Percentage = styled.span`
  white-space: normal;
  font-size: 14px;
  cursor: pointer;
  color: #22aa52;
  display: flex;

  span {
    margin-left: 4.8px;
    font-weight: normal;
    font-style: normal;
    line-height: 16px;
    color: #737171;
  }
`;

Presentation.StateTag = styled.div`
  align-items: center;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  font-size: 14px;
  cursor: pointer;
  color: #22aa52;
  display: flex;
  height: 24px;
  width: 42px;

  position: relative;

  span {
    margin-left: 6px;
  }

  .tooltip {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px 0px;
    border-top: 2px solid rgb(61 184 112);
    position: absolute;
    border-radius: 6px;
    line-height: 1.3em;
    background: white;
    font-size: 0.8rem;
    min-width: 15rem;
    padding: 0.5rem;
    color: #212529;
    z-index: 99;
    top: 30px;
    left: 0;
    white-space: normal !important;
    margin-left: 0 !important;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 100%;
      left: 1.5em;
      border: 0.5rem solid transparent;
      border-top: none;
      border-bottom-color: rgb(61 184 112);
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }
  }

  /* VERTICAL STYLE */
  &.vertical-style {
    .tooltip {
      right: 0 !important;
      left: unset;

      &:before {
        left: 16.5em;
      }
    }
  }

  @media (max-width: 882px) {
    .tooltip {
      right: 0 !important;
      left: unset;

      &:before {
        left: 16.5em;
      }
    }
  }
`;

export const StatusTag = styled.div`
  justify-content: space-evenly;
  align-items: center;
  font-style: normal;
  position: absolute;
  font-weight: bold;
  line-height: 15px;
  font-size: 15px;
  display: flex;
  height: 18px;
  width: 88px;
  z-index: 1;

  span {
    font-size: 13px;
  }

  &.losing {
    background: #fcab10;
    color: #fff;
  }

  &.winning {
    background: #1a843f;
    color: #fff;

    &::before {
      border-right-color: #1a843f !important;
      border-top-color: #1a843f !important;
    }

    &::after {
      border-left-color: #1a843f !important;
      border-top-color: #1a843f !important;
    }
  }

  &.desktop-version {
    border-radius: 0px 4px 4px 0px;
    right: -3px;
    top: 32px;

    &::before {
      content: '';
      height: 0px;
      width: 0;
      position: absolute;
      left: -6px;
      top: 0px;
      border-radius: 4px 0px 0px 4px;
      border-top: 4px solid #fcab10;
      border-right: 6px solid #fcab10;
      border-bottom: 16px solid transparent;
      border-left: 20px transparent;
    }
  }

  @media (max-width: 882px) {
    right: -3px;
    top: 32px;
    &.desktop-version {
      &::before {
        display: none;
      }
    }

    &.mobile-version {
      border-radius: 4px 0px 0px 4px;
      left: -4px;
      top: 14px;
      &::after {
        content: '';
        height: 0px;
        width: 0;
        position: absolute;
        right: -6px;
        top: 0px;
        border-radius: 0px 4px 4px 0px;
        border-top: 4px solid #fcab10;
        border-left: 6px solid #fcab10;
        border-bottom: 16px solid transparent;
        border-right: 20px transparent;
      }
    }
  }
`;

export const FipeTag = styled.div`
  min-width: 78px;
  min-height: 28px;
  background: #e2f1e6;
  border-radius: 6px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #438a4c;
  z-index: 1;
  left: 8.5px;
  top: 7px;
`;
