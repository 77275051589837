import React from 'react';
import Radio from '@mui/material/Radio';

import ModalTemplate from '../../../modal-template';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Option } from './styles';
import { bindActionCreators } from 'redux';
import { handleFilters } from '../../../../../../store/actions/auction.actions';
import { connect } from 'react-redux';

function SortFilterMobile({ options, handleOptionClicked, value, closeFilters }) {
  function handleClose() {
    closeFilters({ showSortFilters: false });
  }

  return (
    <ModalTemplate handleCloseModal={handleClose}>
      <RadioGroup
        aria-label="sort-filter"
        name="radio-buttons-group"
        onChange={event => handleOptionClicked(event.target.value)}
      >
        {options?.map((option, key) => (
          <Option key={key}>
            <FormControlLabel
              value={option.value}
              control={<Radio checked={option.value === value} />}
              label={option.label}
              sx={{
                fontWeight: 'normal',
                fontStyle: 'normal',
                color: '#757778',
                fontSize: 16,
              }}
            />
          </Option>
        ))}
      </RadioGroup>
    </ModalTemplate>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    closeFilters: bindActionCreators(handleFilters, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SortFilterMobile);
