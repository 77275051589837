import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authenticate } from '../../store/actions/authentication.actions';
import { Base } from './';
import { useAppContext } from '../../context';
import { useSegment } from '../../hooks/useSegment';
import { useHistory } from 'react-router-dom';

export const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [errorCheckBox, setErrorCheckBox] = useState('');
  const [loading, setLoading] = useState(false);

  const { page } = useSegment();
  const { isApp } = useAppContext();
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, authOnGoing, authError, authErrorMessage } = useSelector(
    state => state.authentication
  );

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);

      try {
        if (acceptTerms) {
          await dispatch(authenticate({ username, password }));
          history.push('/');
        } else {
          setErrorCheckBox(
            'Para entrar na plataforma é preciso aceitar os termos de uso e política de devolução.'
          );
        }
      } catch (error) {
        setErrorCheckBox(
          'Erro ao acessar a sua conta. Favor entrar em contato com o seu consultor.'
        );
      } finally {
        setLoading(false);
      }
    },
    [username, password, acceptTerms]
  );

  const handleChange = e => {
    const { name, value, checked } = e.target;

    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'terms') {
      setAcceptTerms(checked);
    }
  };

  useEffect(() => {
    page('Login');
  }, []);

  return (
    <Base
      title="Bem vindo de volta!"
      subtitle="Insira seu email e senha para entrar na plataforma de leilão da InstaCarro."
      CTA="Entrar na plataforma"
      errorMessage={
        errorCheckBox ??
        'Houve um problema com o seu email e senha. Por favor, tente novamente ou entre em contato com o suporte.'
      }
      links={[
        {
          text: 'Esqueceu sua senha?',
          href: '/esqueceu-senha',
          label: 'Recuperar senha',
        },
        {
          text: 'Ainda não possui uma conta?',
          href: `https://www.instacarro.com/${isApp ? 'form' : 'cadastro'}-lojista/`,
          label: 'Criar conta',
        },
      ]}
      inputs={[
        {
          name: 'username',
          type: 'email',
          label: 'E-mail',
          placeholder: 'Informe o seu email de acesso à plataforma',
          value: username,
          onChange: handleChange,
        },
        {
          name: 'password',
          type: 'password',
          label: 'Senha',
          placeholder: 'Insira sua senha de acesso',
          value: password,
          onChange: handleChange,
        },
      ]}
      error={authError || errorCheckBox}
      loading={loading}
      onSubmit={handleSubmit}
      disabled={!username || !password || !acceptTerms}
      onChangeCheckBox={handleChange}
      valueCheckBox={acceptTerms}
      authErrorMessage={authErrorMessage}
    />
  );
};
