import React from 'react';
import { FaCheck } from 'react-icons/fa';

import { Container } from './styles';

function SortFilterDesktop({ options, handleOptionClicked, value }) {
  return (
    <Container>
      {options?.map((option, key) => (
        <Container.Item key={key} onClick={() => handleOptionClicked(option.value)}>
          {value === option.value ? <FaCheck /> : ''} <span>{option.label}</span>
        </Container.Item>
      ))}
    </Container>
  );
}

export default SortFilterDesktop;
