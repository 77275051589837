import styled from 'styled-components';

export const StyledButton = styled.button`
  max-width: ${props => props.maxWidth ?? '100%'};
  width: 100%;
  padding: 12px 0;

  font-family: ${props => props.fontFamily ?? 'Roboto'}, sans-serif;
  font-size: ${props => props.fontSize ?? 16}px;
  font-weight: ${props => props.fontWeight ?? 700};
  color: ${props => props.color ?? '#fff'};
  line-height: ${props => props.lineHeight ?? 1}em;
  text-align: center;

  background-color: ${props => props.bg ?? '#438a4c'};

  border: ${props => props.border ?? 'none'};
  border-radius: ${props => props.borderRadius ?? 4}px;
  box-sizing: border-box;

  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: ${props => props.hover};
  }

  &:disabled {
    background-color: #f4f5f5;
    cursor: not-allowed;
  }
`;
