export const preferencesLabel = {
  email: 'EMAIL',
  whatsapp: 'WHATSAPP',
  push_notification: 'PUSH_NOTIFICATION',
  cautelar: 'CAUTELAR_APROVADA',
  uf: 'UF',
  closeToEnd: 'DISPUTA_TURBO',
  score80: 'SCORE',
  fipe70: 'ABAIXO_DA_FIPE',
  before100k: 'CEM_MIL_KM',
  fipe70k: 'FIPE_70K',
  fipe100k: 'FIPE_100K',
  brands: 'BRANDS',
  year: 'YEAR',
  km: 'KM',
};
