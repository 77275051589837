import React, { useMemo } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import { Container, Select, ArrowDown } from './styles';

function TaxProfilesDropdown({ selectedTaxProfile, onTaxProfileChange, taxProfiles, mb }) {
  const alphabeticallyTaxProfiles = useMemo(() => {
    if (!taxProfiles) return;

    const formattedTaxProfiles = [...taxProfiles].sort((taxProfileA, taxProfileB) => {
      if (taxProfileA.nickName?.trim() < taxProfileB.nickName?.trim()) return -1;

      return 1;
    });

    return formattedTaxProfiles;
  }, [taxProfiles]);

  return (
    <Container mb={mb}>
      <Select
        onChange={({ target }) => onTaxProfileChange(target.value)}
        value={selectedTaxProfile}
        required
      >
        <option value="" disabled>
          Selecione um Perfil
        </option>
        {alphabeticallyTaxProfiles?.map(profile => (
          <option key={profile._id} value={profile.taxIdentificationNumber}>
            {profile.nickName.toUpperCase()}
          </option>
        ))}
      </Select>
      <ArrowDown>
        <IoIosArrowDown color="#2f3741" size={24} />
      </ArrowDown>
    </Container>
  );
}

export default TaxProfilesDropdown;
