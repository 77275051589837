import React from 'react';
import PropTypes from 'prop-types';
import { secondsToHHMMSS } from '../../../utils/time';
import { connect } from 'react-redux';
import classnames from 'classnames';

export const CountDownTimer = ({
  isOnGoing,
  isBlink,
  remainingTime,
  elapsedTime,
  elapsedSinceLastRequest = 0,
}) => {
  const time = (remainingTime - (elapsedTime - elapsedSinceLastRequest)) / 1000;
  const timeBlink = time < 10 && time > 0;
  const timerClass = classnames('timer', { blinking: isBlink || timeBlink });

  return (
    <span className={timerClass} data-name="countdown-timer">
      {(isOnGoing && secondsToHHMMSS(time)) || '---'}
    </span>
  );
};

const mapStateToProps = state => ({
  blink: state.timer.blink,
  elapsedTime: state.timer.elapsedTime,
});

CountDownTimer.propTypes = {
  isOnGoing: PropTypes.bool.isRequired,
  remainingTime: PropTypes.number.isRequired,
  isBlink: PropTypes.bool,
  elapsedTime: PropTypes.number.isRequired,
  elapsedSinceLastRequest: PropTypes.number,
};

export default connect(
  mapStateToProps,
  {}
)(CountDownTimer);
