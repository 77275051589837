import Types from './types';

export const createViewsRequest = () => ({
  type: Types.CREATE_VIEWS_REQUEST,
});

export const createViewsSuccess = payload => ({
  type: Types.CREATE_VIEWS_SUCCESS,
  payload,
});

export const createViewsFailure = error => ({
  type: Types.CREATE_VIEWS_FAILURE,
  error,
});
