import React, { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';

import { removeSuccessBidMessage } from '../../../../store/actions/auction.actions';
// import { Temperature } from '../../../Temperature';
import { Container } from './styles';
import { useBid } from '../../../../hooks/useBid';

import { find } from 'lodash';

function TooltipMessage({ removeSuccessBidMessage, showSuccessMessage, auctionId, auctions }) {
  const currentAuction = useMemo(() => find(auctions, { _id: auctionId }), [auctions, auctionId]);

  const { currentOffer } = useBid(currentAuction);

  useEffect(
    function() {
      if (showSuccessMessage) {
        const timer = setTimeout(() => {
          removeSuccessBidMessage();
        }, 5000);
        return () => clearTimeout(timer);
      }
    },
    [showSuccessMessage]
  );

  return (
    <Container show={showSuccessMessage}>
      <div className="content">
        <span className="success-text">Oferta efetuada com sucesso!</span>
        {/* <Temperature.AfterBid auction={currentAuction} currentValue={currentOffer} /> */}
      </div>

      <button className="close-btn" onClick={() => removeSuccessBidMessage()}>
        <MdClose />
      </button>
    </Container>
  );
}

const mapDispatchToProps = dispatch => ({
  removeSuccessBidMessage: bindActionCreators(removeSuccessBidMessage, dispatch),
});

const mapStateToProps = ({ auctions }) => ({
  showSuccessMessage: auctions.showSuccessMessage || false,
  auctionId: auctions.biddedAuctionId || false,
  auctions: auctions.allAuctions,
});

export default connect(mapStateToProps, mapDispatchToProps)(TooltipMessage);
