import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../shared/Primitives/Button';
import { withModal } from '../../base/modal/hoc';

class CloseButton extends React.Component {
  dismiss = () => {
    this.props.modal.dismiss();
  };

  render() {
    return <Button onClick={this.dismiss} {...this.props} />;
  }
}
CloseButton.propTypes = {
  modal: PropTypes.any.isRequired,
};

CloseButton = withModal(CloseButton);

export { CloseButton };
