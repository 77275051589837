import React, { useState, useRef } from 'react';
import { MdClose } from 'react-icons/md';
import SearchIcon from '../../../../images/icons/fi-bs-search.svg';
import { usePurchasesContext } from '../../NewPurchasesContext';
import { InputBox, Container, Button, ButtonContainer } from './styles';

export const NewSearchInput = () => {
  const [value, SetValue] = useState('');
  const { updateFilter } = usePurchasesContext();
  const searchInput = useRef(null);
  const onChange = e => {
    SetValue(e.target.value);
  };
  const handleClear = e => {
    SetValue('');
    searchInput.current.focus();
    updateFilter({ search: '' });
  };
  const submit = e => {
    e.preventDefault();
    updateFilter({ search: value });
  };
  return (
    <Container as="form" onSubmit={submit}>
      <InputBox
        p={1}
        as="input"
        type="text"
        placeholder="Buscar por marca ou modelo"
        onChange={onChange}
        value={value}
        bg="white"
        color="neutrals.EclipseE600"
        ref={searchInput}
      />
      <ButtonContainer>
        {value.length > 0 && (
          <Button
            as="button"
            color="neutrals.EclipseE400"
            type="button"
            right="2rem"
            onClick={handleClear}
          >
            <MdClose size={18} />
          </Button>
        )}

        <Button as="button" color="primaries.Soul">
          <img className="icon" src={SearchIcon} alt="ícone" />
        </Button>
      </ButtonContainer>
    </Container>
  );
};
