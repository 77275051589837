import React from 'react';
import { Svg } from './styles';

export function TwitterIcon({ color = '#E9EBEC' }) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.53902 22.515C16.5954 22.515 21.551 15.0048 21.551 8.503C21.551 8.29172 21.551 8.08043 21.5414 7.86915C22.5018 7.17767 23.3373 6.30372 24 5.31453C23.1164 5.70828 22.1657 5.96759 21.1669 6.09244C22.1849 5.4874 22.9628 4.51741 23.3373 3.36495C22.3866 3.93157 21.3301 4.33493 20.2065 4.55582C19.3037 3.59544 18.0264 3 16.6146 3C13.8968 3 11.6879 5.20888 11.6879 7.92677C11.6879 8.31092 11.7359 8.68547 11.8127 9.05042C7.72149 8.84874 4.09124 6.87995 1.66146 3.90276C1.2389 4.63265 0.9988 5.47779 0.9988 6.38055C0.9988 8.09004 1.87275 9.59784 3.18848 10.4814C2.38175 10.4526 1.62305 10.2317 0.960384 9.86675C0.960384 9.88595 0.960384 9.90516 0.960384 9.93397C0.960384 12.3157 2.66026 14.3133 4.90756 14.7647C4.4946 14.88 4.06242 14.9376 3.61104 14.9376C3.29412 14.9376 2.98679 14.9088 2.68908 14.8511C3.31333 16.8103 5.13806 18.2317 7.28932 18.2701C5.59904 19.5954 3.47659 20.383 1.17167 20.383C0.777912 20.383 0.384154 20.3637 0 20.3157C2.17047 21.6987 4.76351 22.515 7.53902 22.515Z"
        fill={color}
      />
    </Svg>
  );
}
