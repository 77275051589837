import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  min-height: 21px;
  margin-bottom: 0.2rem;

  img {
    width: 20px;
    margin-right: 0.37rem;
  }

  span {
    margin-top: 1px;

    font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
    color: #22aa52;

    &.heigh {
      color: #22aa52;
    }

    &.low {
      color: #6b0874;
    }

    &.possible {
      color: #a34f00;
    }
  }
`;
