import { CAR_MARKS, CAR_MODELS } from '../filter-enums';

export const userFiltersMock = [
  {
    _id: 's6d8fth47s698rfth4fs6d4hf',
    filterName: 'Filtro 1',
    description: 'Está é uma descrição de filtro',
    price: 10000,
    km: 50000,
    marks: [CAR_MARKS.CITROEN, CAR_MARKS.FIAT, CAR_MARKS.FIAT],
    model: null,
    yearInterval: [1996, 2020],
  },
  {
    _id: 'shs5f6g4h6df4gh6df4gh6df5',
    filterName: 'Filtro 2',
    description: 'Está é uma descrição de filtro',
    price: 20000,
    km: 70000,
    marks: [CAR_MARKS.VOLKSWAGEN, CAR_MARKS.TOYOTA, CAR_MARKS.PEUGEOT],
    model: [CAR_MODELS.CROSSOVER, CAR_MODELS.SUV],
    yearInterval: [1996, 2020],
  },
];
