import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import set from 'lodash/set';

import rootReducer from './reducers/index';
import Actions from '../constants/actionTypes';
import initialState from './reducers/initialState';
import commercialService from '../services/commercial';

/* eslint consistent-return: 0 */

// TODO: yes, I know this isn't extensible, but needed a quick way to store
// the settings. The whole localStorage thing must be refactored (token mgt).
const icNamespace = 'ic_redux_state';
const saveSettingsMiddleware = store => next => action => {
  next(action);

  switch (action.type) {
    case Actions.Settings.UpdateAuctionView:
      localStorage.setItem(`${icNamespace}::settings`, JSON.stringify(store.getState().settings));
      break;
    case Actions.UI.SetMyPurchasesFilters:
    case Actions.UI.ClearMyPurchasesFilters:
      localStorage.setItem(
        `${icNamespace}::ui.purchaseFilters`,
        JSON.stringify({ ...store.getState().ui.purchaseFilters, keyword: '' })
      );
      break;
    default: // nothing
  }
};

/**
 * Check the keys in the localStorage that match `namespace::stateSlice`
 * where namespace is a constant to denote redux state and stateSlice is
 * the part of the state that was saved into localstorage.
 *
 * @returns an object with parsed data from the matched keys
 */
const stateFromStorage = (state, namespace) => {
  const newState = { ...state };

  for (let i = 0; i < localStorage.length; i++) {
    const currentKey = localStorage.key(i);
    if (currentKey.startsWith(namespace)) {
      const stateSlicePath = currentKey.replace(namespace, '').replace('::', '');

      const stateSlice = JSON.parse(localStorage.getItem(currentKey));
      set(newState, stateSlicePath, stateSlice);
    }
  }
  return newState;
};

const mergedInitialStateWithLocal = {
  ...stateFromStorage(initialState, icNamespace),
};

function configureStoreProd() {
  const middlewares = [thunk, saveSettingsMiddleware];

  return createStore(
    rootReducer,
    mergedInitialStateWithLocal,
    compose(applyMiddleware(...middlewares))
  );
}

function configureStoreDev() {
  const middlewares = [
    commercialService.middleware,
    reduxImmutableStateInvariant(),
    thunk,
    saveSettingsMiddleware,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: true })
    : compose; // add support for Redux dev tools

  const store = createStore(
    rootReducer,
    mergedInitialStateWithLocal,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../store/reducers', () => {
      const nextReducer = require('../store/reducers').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
