import React from 'react';
import PropTypes from 'prop-types';
import { rem, rgba } from 'polished';
import styled from 'styled-components';

import { Box } from '../../../abstract';
import { Container } from '../../../layout/index';

const Wrapper = styled(Box)`
  box-shadow: 0 2px 3px 0 ${rgba('#000000', 0.1)};
`;

export const FiltersBar = ({ children, ...props }) => (
  <Wrapper bg="primaries.Soul" height={{ md: rem(64) }} mb={{ sm: 4, md: rem(80) }} {...props}>
    <Box className="container" position="relative" px={{ sm: 0, md: rem(28), lg: 3, xlg: 0 }}>
      {children}
    </Box>
  </Wrapper>
);

FiltersBar.propTypes = {
  children: PropTypes.node,
};
