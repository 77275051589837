import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding: ${({ hasError }) => (hasError ? '1.3rem 1.625rem' : '0 1.625rem')};

  @media screen and (max-width: 1025px) {
    padding: 0;
  }

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      justify-content: flex-start;
      min-height: 100%;
      padding: 0;
    `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.3rem;
`;

export const Title = styled.span`
  margin-right: 0.313rem;

  font: 700 normal 1.25rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
    `}
`;

export const LastOfferLabel = styled.span`
  margin-bottom: 0.7rem;

  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
    `}
`;

export const LastOfferAndEditionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ hasError }) => (hasError ? '0.75rem' : '0')};
`;

export const AutoBidLastOfferValue = styled.span`
  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
    `}
`;

export const AlertIconImg = styled.img`
  margin-right: 0.125rem;
`;

export const RemainingEditionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RemainingEditionsLabel = styled.span`
  font: 400 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
    `}

  @media screen and (max-width: 415px) {
    font-size: 0.688rem;
  }
`;

export const RemoveAndMoreOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1.5rem;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
    `}

  @media screen and (max-width: 1025px) {
    flex-direction: ${props => (props.detailsVehicle ? 'row' : 'column')};
    justify-content: ${props => (props.detailsVehicle ? 'space-between' : 'center')};
  }
`;

export const ChangeIcon = styled.img`
  margin-right: 0.4rem;
`;

export const RemoveLimitContainer = styled.button`
  display: flex;
  align-items: center;

  padding: 0;

  font: 400 normal 1rem/1em 'Roboto', sans-serif;
  color: #125d8a;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
    `}

  @media screen and (min-width: 1025px) and (max-width: 1285px) {
    font-size: 0.75rem;
  }

  @media screen and (max-width: 1025px) {
    justify-content: center;

    width: ${props => (props.detailsVehicle ? '50%' : '100%')};

    margin-bottom: ${props => (props.detailsVehicle ? '0' : '1.5rem')};
    padding: 0.8rem 0;

    border: 0.063rem solid;
    border-color: ${props => (props.detailsVehicle ? '#fff' : '#125d8a')};
    border-radius: 0.25rem;
  }
`;

export const MoreOptions = styled.button`
  display: flex;
  align-items: center;

  padding: 0;

  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;
  text-transform: uppercase;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
    `}

  @media screen and (min-width: 1025px) and (max-width: 1285px) {
    font-size: 0.7rem;
  }
`;

export const MoreOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px 0;
  width: 100%;
`;

export const MoreOptionsContent = styled(MoreOptionsWrapper)`
  gap: 12px 0;
`;
