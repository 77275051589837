import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex } from '../../../../components/abstract';
import { usePurchasesContext } from '../../NewPurchasesContext';
import { FormatDate } from '../../format';
import { NewSearchInput } from '../NewSearchInput';
import { FilterButton } from './FilterButton';
import {
  FiltersContainer,
  FilterButtonContainer,
  Line,
  OrdenateContainer,
  ResultText,
  ResultNumber,
  FilterAndOrdenateContainer,
  FilterAndOrdenateText,
  ResultContainer,
  DropText,
  DivFilter,
  DropContainer,
  LineDiv,
} from './styles';
import FilterIcon from '../../../../images/icons/fi-bs-filter.svg';

export const NewFilterBar = ({ filteredAuctions }) => {
  const { updateFilter, filterByDate } = usePurchasesContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);
  const [selectedButton, setSelectedButton] = useState(0);

  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const dropdownRef = useRef(null);

  const statusOptions = [
    { label: 'Todas', value: null },
    { label: 'Em andamento', value: n => n === 0 },
    { label: 'Concluídas', value: n => n === 1 },
  ];
  const timeOptions = [
    { label: 'Atualizados nos últimos 7 dias', value: 7 },
    { label: 'Atualizados nos últimos 30 dias', value: 30 },
    { label: 'Atualizados nos últimos 90 dias', value: 90 },
    { label: 'Atualizados nos últimos 180 dias', value: 180 },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth <= 760) {
        return;
      }

      if (!isMobile && window.innerWidth > 760) {
        return;
      }

      if (isMobile && window.innerWidth > 760) {
        setIsMobile(false);
      }

      if (!isMobile && window.innerWidth <= 760) {
        setIsMobile(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSelectVisible(false);
      }
    };

    if (isSelectVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSelectVisible]);

  const handleFilterTextClick = () => {
    setIsSelectVisible(!isSelectVisible);
  };

  for (let i = 2019; i <= new Date().getFullYear(); i++) {
    timeOptions.push({
      label: `Atualizados no ${i}`,
      value: {
        from: FormatDate({ date: `1/1/${i}`, short: true }),
        to: FormatDate({ date: `12/31/${i}`, short: true }),
      },
    });
  }

  return (
    <>
      <FiltersContainer>
        {isMobile && <NewSearchInput />}
        <FilterButtonContainer>
          {statusOptions.map((option, index) => (
            <FilterButton
              key={index}
              label={option.label}
              noBorder={index === 1}
              leftRadius={index === 0}
              rightRadius={index === 2}
              isSelected={selectedButton === index}
              onClick={() => {
                setSelectedButton(index);
                updateFilter({ state: option.value });
              }}
            />
          ))}
        </FilterButtonContainer>
        {!isMobile && <NewSearchInput />}
      </FiltersContainer>
      <OrdenateContainer>
        <ResultContainer>
          {filteredAuctions?.length > 0 && <ResultNumber>{filteredAuctions?.length}</ResultNumber>}
          <ResultText>
            {filteredAuctions?.length > 0 &&
              (filteredAuctions?.length !== 0 ? 'resultados' : 'resultado')}
          </ResultText>
        </ResultContainer>
        <DivFilter ref={dropdownRef}>
          <FilterAndOrdenateContainer onClick={handleFilterTextClick}>
            <FilterAndOrdenateText>Filtrar e ordenar</FilterAndOrdenateText>
            <img className="icon" src={FilterIcon} alt="ícone" />
          </FilterAndOrdenateContainer>
          {isSelectVisible && (
            <DropContainer>
              {timeOptions.map((option, index) => (
                <DropText
                  key={`${option.value}-${index}`}
                  onClick={() => {
                    filterByDate(option.value);
                    handleFilterTextClick();
                  }}
                >
                  {option.label}
                </DropText>
              ))}
            </DropContainer>
          )}
        </DivFilter>
      </OrdenateContainer>
      <LineDiv>
        <Line />
      </LineDiv>
    </>
  );
};
