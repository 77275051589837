import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdKeyboardBackspace } from 'react-icons/md';
import { rem } from 'polished';

import { Button, Box, Flex, Tag, PhotoCarousel, AuctionBidders } from '../../../../components';
import { photosPropTypes } from '../../../../components/enhanced/ui';

const BackButton = styled(Button).attrs({
  variant: 'default',
  isRounded: true,
  fontSize: 1,
  height: { sm: rem(24), md: rem(34) },
  px: 2,
})`
  color: #ffffff !important;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  background-color: rgba(3, 3, 3, 0.4);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
`;

const StyledTag = styled(Tag)`
  justify-content: center;
  min-width: ${rem(136)};
`;

export const DetailsPhotoCarousel = ({
  auctionId,
  photos,
  onBackButtonClick,
  isOngoing,
  isWinning,
  isUserParticipating,
  onClickSlide,
}) => {
  return (
    <Box position="relative">
      <BackButton onClick={onBackButtonClick}>
        <Button.Icon>
          <MdKeyboardBackspace size={14} />
        </Button.Icon>{' '}
        Voltar
      </BackButton>

      <PhotoCarousel
        photos={photos}
        sliderHeight={{ sm: 240, md: 362 }}
        slidesPerPage={3}
        slidesPerScroll={1}
        onClickSlide={onClickSlide}
        breakpoints={{
          1024: {
            slidesPerPage: 2,
            slidesPerScroll: 2,
          },
          600: {
            slidesPerPage: 1,
            slidesPerScroll: 1,
          },
        }}
        hasBigArrows
        showArrows
      />

      <Flex
        position="absolute"
        bottom={{ md: '20px' }}
        left={{ sm: '120px', md: '10px' }}
        top={{ sm: '10px', md: 'auto' }}
        flexWrap="wrap"
        justifyContent={{ sm: 'flex-end', md: 'flex-start' }}
      >
        <AuctionBidders
          auctionId={auctionId}
          mr={{ sm: '10px', md: 2 }}
          fontSize={{ sm: 1, md: 2 }}
          height={{ sm: rem(24), md: rem(44) }}
          order={{ sm: 1, md: 0 }}
        />

        {isOngoing && isUserParticipating && (
          <StyledTag
            backgroundColor={isWinning ? 'secondaries.Uno' : 'alerts.Ferrari'}
            alpha={0.7}
            fontSize={{ sm: 1, md: 2 }}
            height={{ sm: rem(24), md: rem(44) }}
            mr={{ sm: '10px', md: 0 }}
            mb={{ sm: 1, md: 0 }}
            order={{ sm: 0, md: 1 }}
          >
            {isWinning ? 'Ganhando' : 'Perdendo'}
          </StyledTag>
        )}
      </Flex>
    </Box>
  );
};

DetailsPhotoCarousel.propTypes = {
  ...photosPropTypes,
  auctionId: PropTypes.string.isRequired,
  onBackButtonClick: PropTypes.func,
  isWinning: PropTypes.bool,
  isOngoing: PropTypes.bool,
  isUserParticipating: PropTypes.bool,
  onClickSlide: PropTypes.func,
};

DetailsPhotoCarousel.defaultProps = {
  onBackButtonClick() {},
};
