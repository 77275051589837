import React from 'react';

import { formatToThousands } from '../../../../utils/number';
import { Container } from './styles';

function ICSlider({
  formatValue = true,
  previewPrefix = '',
  previewSuffix = '',
  defaultValue = 0,
  label = '',
  max = 100,
  icon = '',
  step = 1,
  min = 0,
  value,
  fontFamily,
  ...props
}) {
  return (
    <Container>
      {!!label && (
        <Container.Label>
          {icon && <span className="icon">{icon}</span>}
          {label && <span className="text">{label}</span>}
        </Container.Label>
      )}
      <Container.SliderWrapper mt={Array.isArray(value) ? '20px' : ''}>
        {!Array.isArray(value) ? (
          <div className="preview" fontFamily={fontFamily}>
            {previewPrefix} {formatValue ? formatToThousands(value || 0) : value} {previewSuffix}
          </div>
        ) : (
          <div className="preview j-between" fontFamily={fontFamily}>
            {value?.map((v, key) => (
              <span key={key}>
                {previewPrefix} {formatValue ? formatToThousands(v || 0) : v}
                {previewSuffix}
              </span>
            ))}
          </div>
        )}
        <Container.Slider {...props} value={value} min={min} max={max} step={step} />
      </Container.SliderWrapper>
    </Container>
  );
}

export default ICSlider;
