import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import get from 'lodash/get';

import { Flex } from '../../../abstract';

const Wrapper = styled(Flex)`
  ${({ size }) => `
    width: ${rem(size)};
    height: ${rem(size)};
  `};
  border-radius: 50%;
  border: 1px solid
    ${({ borderColor, theme, bg }) =>
      !borderColor ? get(theme, `colors.${bg}`) : get(theme, `colors.${borderColor}`)};
`;
Wrapper.defaultProps = {
  bg: 'primaries.Soul',
};

export const Badge = ({ children, size = 20, color = 'primaries.Taxi', borderColor, ...props }) => {
  return (
    <Wrapper
      fontSize={0}
      {...props}
      borderColor={borderColor}
      color={color}
      size={size}
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Wrapper>
  );
};

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  size: PropTypes.number,
  color: PropTypes.string,
  borderColor: PropTypes.string,
};
