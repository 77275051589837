import { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { media } from '../utils';

const defaultStyles = css`
  border: 0;
  transition: all 100ms;
  &:focus {
    outline: none !important;
  }
`;

const linkStyles = (color, hoverColor, activeColor) => css`
  ${defaultStyles};
  background-color: transparent;
  color: ${themeGet(`colors.${color}`)};

  &:hover {
    color: ${themeGet(`colors.${hoverColor || color}`)};
  }

  &:focus,
  &:active {
    color: ${themeGet(`colors.${activeColor || color}`)};
  }
`;

export const buttons = {
  primary: css`
    ${defaultStyles};
    color: ${themeGet('colors.primaries.Taxi')};
    background-color: ${themeGet('colors.secondaries.Uno')};

    &:hover {
      background-color: ${themeGet('colors.secondaries.UnoU300')};
    }

    &:focus,
    &:active {
      background-color: ${themeGet('colors.secondaries.UnoU600')};
    }
  `,
  secondary: css`
    ${defaultStyles};
    color: ${themeGet('colors.primaries.Taxi')};
    background-color: ${themeGet('colors.primaries.Soul')};

    &:hover {
      background-color: ${themeGet('colors.primaries.SoulS300')};
    }

    &:focus,
    &:active {
      background-color: ${themeGet('colors.primaries.SoulS600')};
    }
  `,
  yellow: css`
    ${defaultStyles};
    color: white;
    background-color: #ffc90e;

    &:hover {
      background-color: #ffc00e;
    }
  `,
  darkGreen: css`
    background-color: #1a843f;
    color: #fff;
    border: none;
    outline: none;

    &:hover {
      background-color: ${themeGet('colors.primaries.SoulS300')};
      color: ${themeGet('colors.primaries.Taxi')};
    }

    &:focus,
    &:active {
      color: #fff;
      border: none;
      outline: none;
      background-color: #1a843f;
    }
  `,
  darkYellow: css`
    color: #fff;
    border: none;
    outline: none;
    background: #fcab10;

    &:hover {
      background-color: #ffc00e;
    }
  `,
  default: css`
    ${defaultStyles};
    color: ${themeGet('colors.neutrals.EclipseE600')};
    background-color: ${themeGet('colors.neutrals.EclipseE200')};

    &:hover {
      color: ${themeGet('colors.primaries.Taxi')};
      background-color: ${themeGet('colors.neutrals.EclipseE600')};
    }

    &:focus,
    &:active {
      color: ${themeGet('colors.primaries.Taxi')};
      background-color: ${themeGet('colors.neutrals.EclipseE700')};
    }
  `,
  disabled: css`
    ${defaultStyles};
    color: ${themeGet('colors.neutrals.EclipseE300')};
    background-color: ${themeGet('colors.neutrals.EclipseE70')};
    cursor: not-allowed;
  `,
  warning: css`
    ${defaultStyles};
    color: ${themeGet('colors.primaries.Taxi')};
    background-color: ${themeGet('colors.alerts.Lamborghini')};

    &:hover {
      background-color: ${themeGet('colors.alerts.LamborghiniL300')};
    }

    &:focus,
    &:active {
      background-color: ${themeGet('colors.alerts.LamborghiniL600')};
    }
  `,
  error: css`
    ${defaultStyles};
    color: ${themeGet('colors.primaries.Taxi')};
    background-color: ${themeGet('colors.alerts.Ferrari')};

    &:hover {
      background-color: ${themeGet('colors.alerts.FerrariF300')};
    }

    &:focus,
    &:active {
      background-color: ${themeGet('colors.alerts.FerrariF600')};
    }
  `,
  ghost: {
    default: css`
      background-color: transparent;
      border: ${themeGet('borders.ghostButton.default')};
      color: ${themeGet('colors.neutrals.EclipseE500')};

      &:hover {
        background-color: ${themeGet('colors.neutrals.EclipseE500')};
        color: ${themeGet('colors.primaries.Taxi')};
      }

      &:focus,
      &:active {
        background-color: ${themeGet('colors.neutrals.EclipseE600')};
        border-color: ${themeGet('colors.neutrals.EclipseE600')};
        color: ${themeGet('colors.primaries.Taxi')};
      }
    `,
    primary: css`
      background-color: transparent;
      border: ${themeGet('borders.ghostButton.primary')};
      color: ${themeGet('colors.primaries.Soul')};

      &:hover {
        background-color: ${themeGet('colors.primaries.SoulS300')};
        color: ${themeGet('colors.primaries.Taxi')};
      }

      &:focus,
      &:active {
        background-color: ${themeGet('colors.primaries.SoulS600')};
        border-color: ${themeGet('colors.primaries.SoulS600')};
        color: ${themeGet('colors.primaries.Taxi')};
      }
    `,
    warning: css`
      background-color: transparent;
      border: ${themeGet('borders.ghostButton.warning')};
      color: ${themeGet('colors.alerts.Lamborghini')};

      &:hover {
        background-color: ${themeGet('colors.alerts.LamborghiniL300')};
        color: ${themeGet('colors.primaries.Taxi')};
      }

      &:focus,
      &:active {
        background-color: ${themeGet('colors.alerts.LamborghiniL600')};
        border-color: ${themeGet('colors.alerts.LamborghiniL600')};
        color: ${themeGet('colors.primaries.Taxi')};
      }
    `,
    inversed: css`
      ${defaultStyles};
      background-color: transparent;
      border: ${themeGet('borders.ghostButton.inversed')};
      color: ${themeGet('colors.primaries.Taxi')};
    `,
  },
  link: {
    menu: css`
      ${defaultStyles};
      background-color: transparent;
      color: ${themeGet('colors.neutrals.EclipseE600')};
      font-family: ${themeGet('headings')};
      font-size: ${themeGet('fontSizes.2')};
      text-decoration: none;

      ${media.md`
        font-size: ${themeGet('fontSizes.1')};
      `}
    `,
    subtle: css`
      ${defaultStyles}
      background-color: transparent;
      color: #757575;

      &:hover {
        text-decoration: underline;
      }
    `,
    primary: css`
      ${linkStyles('primaries.Soul', 'primaries.SoulS300', 'primaries.SoulS600')}
    `,
    secondary: css`
      ${linkStyles('secondaries.Uno', 'secondaries.UnoU300', 'secondaries.UnoU600')}
    `,
    inverse: css`
      ${linkStyles('primaries.Taxi')}
    `,
  },
};
