import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCurrentElapsed } from '../utils/dateUtils';
import { secondsToHHMMSS } from '../utils/time';

export function useTimer(duration) {
  if (parseInt(duration) === NaN) throw new Error('You must provide a duration');

  const [elapsedTime, setElapsedTime] = useState(0);
  const timeoutId = useRef(null);
  const lastTickMemo = useRef(getCurrentElapsed());

  const increaseElapsedTime = useCallback(() => {
    const now = getCurrentElapsed();
    const increment = now - lastTickMemo.current;
    setElapsedTime(old => old + increment);
    lastTickMemo.current = now;
  }, []);

  useEffect(() => {
    timeoutId.current = setInterval(increaseElapsedTime, 1000);

    return () => {
      if (timeoutId.current) {
        clearInterval(timeoutId.current);
      }
      setElapsedTime(0);
    };
  }, [duration, increaseElapsedTime]);

  const secondsLeft = useMemo(() => (duration - elapsedTime) / 1000, [duration, elapsedTime]);

  return {
    secondsLeft,
    isBlinking: secondsLeft < 60,
    milisecondsLeft: secondsLeft * 1000,
    formattedRemainingTime: secondsToHHMMSS(secondsLeft),
  };
}
