import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { MdZoomOutMap } from 'react-icons/md';
import { Item } from './Item';

const ZoomIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  width: ${rem(25)};
  height: ${rem(25)};
  color: white;
  border-radius: 0 ${rem(10)} 0 0;
  font-size: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -4px 5px 5px rgba(0, 0, 0, 0.2);
  svg {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.7));
  }
`;
const Img = styled.div`
  width: 100%;
  height: ${rem(134.33)};
  object-fit: cover;
  border-radius: ${rem(10)};
  cursor: pointer;
  background-size: cover;
  position: relative;
  background-image: url('${({ src }) => src}');
  margin-bottom: ${rem(15)};
`;
export const Photo = ({ description, img, onClick, passed, ...etc }) => (
  <div className="slide" {...etc}>
    <Img src={img.src} onClick={onClick}>
      <ZoomIcon>
        <MdZoomOutMap />
      </ZoomIcon>
    </Img>
    <Item description={description} passed={passed} color="neutrals.EclipseE600" />
  </div>
);
Photo.propTypes = {
  description: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  passed: PropTypes.bool,
};
