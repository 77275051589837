import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../enhanced/Modal';
import { toggleModal } from '../../../store/actions/ui.actions';
import { StyleModal } from './styles';
import { TEMPERATURE_MODAL_KNOW_MORE } from '../../Modals';
import { formatPrice } from '../../../utils/formatPrice';

export const KnowMore = () => {
  const dispatch = useDispatch();

  const { show: isOpen, extras } = useSelector(state => state.ui.modals.temperatureModalKnowMore);
  const warmTarget = extras?.selectedAuction?.summary?.vehiclePricing?.warmTarget;
  const hotTarget = extras?.selectedAuction?.summary?.vehiclePricing?.hotTarget;

  const temperatures = [
    {
      title: 'Baixa chance de compra',
      range: `R$ 250,00 até R$ ${formatPrice(warmTarget)}`,
      description:
        'Em nosso histórico, ofertas como essa não venceram disputas. Não perca tempo, aumente sua oferta e agilize a compra!',
      icon: require('../../../images/temperature/modal-know-more/baixa-26px.svg'),
      color: '#6b0874',
    },
    {
      title: 'Média chance de compra',
      range: `R$ ${formatPrice(warmTarget + 1)} até R$ ${formatPrice(hotTarget - 1)}`,
      description:
        'Ainda não há chance de fechar negócio, recomendamos aumentar o valor da oferta para acelerar o processo de compra.',
      icon: require('../../../images/temperature/modal-know-more/media-26px.svg'),
      color: '#864600',
    },
    {
      title: 'Alta chance de compra',
      range: `Acima de R$ ${formatPrice(hotTarget)}`,
      description:
        'Sua oferta possui um forte potencial para ser aceita rapidamente. Continue participando, você está no caminho certo!',
      icon: require('../../../images/temperature/modal-know-more/alta-26px.svg'),
      color: '#137435',
    },
  ];

  return (
    <Modal
      show={isOpen}
      toggle={() => dispatch(toggleModal({ key: TEMPERATURE_MODAL_KNOW_MORE, show: false }))}
      style={{
        content: {
          position: 'relative',
          maxHeight: '667px',
          maxWidth: '375px',
          overflow: 'unset',
          top: 'auto',
          left: 'auto',
          bottom: 'auto',
          right: 'auto',
          padding: '64px 32px',
          border: 'none',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '999999',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <StyleModal.Header>
        <StyleModal.Title>Temperatura - Chance de compra</StyleModal.Title>
        <StyleModal.Description color="#2f3741">
          A sugestão de temperatura é calculada com base no histórico de veículos similares vendidos
          em nossa plataforma. Procure investir em ofertas com altas chances para fechar negócio e
          comprar mais rápido.
        </StyleModal.Description>
      </StyleModal.Header>
      {temperatures.map(({ title, range, description, icon, color }) => (
        <StyleModal.Content key={title}>
          <StyleModal.Informations>
            <StyleModal.Icon src={icon} />

            <StyleModal.Texts>
              <StyleModal.Title color={color}>{title}</StyleModal.Title>
              <StyleModal.SubTitle color={color}>{range}</StyleModal.SubTitle>
              <StyleModal.Description>{description}</StyleModal.Description>
            </StyleModal.Texts>
          </StyleModal.Informations>
        </StyleModal.Content>
      ))}
    </Modal>
  );
};
