import { authenticatedDelete, authGet, authPost } from '../utils/fecth-wrappers';
import constants from '../constants/appConstants';
import { localStorageHttpCache } from '../utils/http-cache';
import { formatAuctionObjectKeys } from '../utils/auction';
import { getCurrentElapsed } from '../utils/dateUtils';

export class FavoriteService {
  constructor(dealersApi, listingApi) {
    this.dealersApi = dealersApi;
    this.listingApi = listingApi;
  }

  async addFavorite({ inspectionId }) {
    const response = await authPost(this.dealersApi, { inspectionId });
    const userFavorites = response.data.data;

    await localStorageHttpCache.clearCache();
    return userFavorites;
  }

  async removeFavorite({ inspectionId }) {
    const response = await authenticatedDelete(this.dealersApi, { inspectionId });
    const userFavorites = response.data.data;

    await localStorageHttpCache.clearCache();
    return userFavorites;
  }

  async getFavorites() {
    const url = `${this.dealersApi}/list`;

    const { cachedRequest, isValid } = await localStorageHttpCache.getCache(url);
    if (isValid) return cachedRequest;

    const request = await authGet(url);
    const userFavorites = request.data.data;
    await localStorageHttpCache.setCache(this.dealersApi, userFavorites);

    return userFavorites;
  }

  async getFavoriteVehicles(vehicleIds, user) {
    const requestStartTime = getCurrentElapsed();
    const url = `${this.listingApi}/vehicle-list?v=${vehicleIds.join(',')}`;
    const { cachedRequest, isValid } = await localStorageHttpCache.getCache(url);
    if (isValid) return cachedRequest;

    const request = await authGet(url);
    const vehicles = request.data.result;
    const formatedVehicles = vehicles?.map(v => formatAuctionObjectKeys(v, requestStartTime, user));
    await localStorageHttpCache.setCache(formatedVehicles);

    return formatedVehicles;
  }
}

export const favoriteService = new FavoriteService(
  constants.API.userFavorite,
  `${constants.baseURLs.dealers}/listings-aggregation`
);
