import { authGet, authPatch, authPost, authPut } from '../utils/fecth-wrappers';
import constants from '../constants/appConstants';
import { replace } from 'lodash';

const getTaxIdList = () => {
  return authGet(constants.API.taxIds);
};

const updateDefaultTaxId = ({ userId, taxId }) => {
  return authPatch(`${constants.baseURLs.icApiDealers}/${userId}/taxids/${taxId}`, {
    default: true,
  });
};

const getFileUploadURL = (userId, fileExtension) => {
  return authPost(`${constants.baseURLs.icApiDealers}/${userId}/taxids/supportFiles`, {
    fileExtension,
  });
};

/**
 * Register a TaxId (CPF) using the API.
 *
 * @param {object} data TaxId information.
 * @param {string} data.type The type of TaxId (CPF).
 * @param {string} data.fullName The company or person name who is owner of the TaxId.
 * @param {string} data.taxIdentificationNumber The id number.
 * @param {string} data.personalIdRG The RG number.
 * @param {string} data.nickName A nickname for this TaxId.
 * @param {string[]} data.supportImages An array of file names.
 */
const registerTaxId = data => {
  return authPost(constants.API.taxIds, data);
};

const changeTaxIdForAuction = ({ auctionId, taxIdNumber }) => {
  return authPut(`${constants.API.auctionsAuthenticated}/${auctionId}/taxid`, {
    taxId: taxIdNumber,
  });
};

const getTaxIdListByStore = storeId => {
  const url = replace(constants.API.taxIds, 'current-user', storeId);
  return authGet(url);
};

export {
  registerTaxId,
  getFileUploadURL,
  getTaxIdList,
  updateDefaultTaxId,
  changeTaxIdForAuction,
  getTaxIdListByStore,
};
