import React, { useMemo } from 'react';
import { MdDescription } from 'react-icons/md';
import { IconContext } from 'react-icons';

import { RadioButton } from '../../../../components/abstract/forms/radio-button';
import { TaxProfileIcon } from '../../../../components/tax-profile-icon';
import appConstants from '../../../../constants/appConstants';
import { primaries } from '../../../../design-system/colors';
import { Box, Flex, Tag } from '../../../../components';
import { Grid } from './styles';

const {
  TaxIdStatus: { APPROVED, REJECTED, VALIDATING },
} = appConstants;

const TAXID_STATUS = {
  [APPROVED]: {
    statusText: 'Aprovado',
    statusColor: 'secondaries.Uno',
  },
  [VALIDATING]: {
    statusText: 'Em validação',
    statusColor: 'alerts.Lamborghini',
  },
  [REJECTED]: {
    statusText: 'Reprovado',
    statusColor: 'alerts.Ferrari',
  },
};

const VIEW_STATUS = {
  CONFIRM_DEFAULT_CHANGE: 0,
  IDLE: 1,
};

function Header({ taxId, handleDefaultProfileChange, defaultTaxIdMutation, status }) {
  const { statusText, statusColor } = useMemo(
    function() {
      const defaultStatusProps = { statusText: '', statusColor: '' };
      return TAXID_STATUS[taxId.status] || defaultStatusProps;
    },
    [taxId.status]
  );

  return (
    <IconContext.Provider value={{ color: primaries.Soul, size: '1.8em' }}>
      <Grid>
        <Box className="tax-id-col tax-id-nickname">
          <TaxProfileIcon profileType={taxId.type} /> {taxId.nickName}
        </Box>
        <Box className="tax-id-col tax-id-status">
          {statusText && (
            <Tag
              backgroundColor={statusColor}
              minWidth={{ sm: '80px', md: '150px' }}
              px={0}
              fontSize={{ sm: 0, md: 1 }}
              css={{ justifyContent: 'center' }}
            >
              {statusText}
            </Tag>
          )}
        </Box>
        <Flex pr={{ md: 4 }} justifyContent="flex-end" className="tax-id-col tax-id-default">
          <span css={{ marginRight: '0.3rem' }}>{taxId.default && 'Perfil padrão'}</span>
          <RadioButton
            name="default-profile"
            value={taxId._id}
            onChange={handleDefaultProfileChange}
            checked={taxId.default}
            disabled={
              taxId.status !== APPROVED ||
              taxId.default ||
              defaultTaxIdMutation.isLoading ||
              status === VIEW_STATUS.CONFIRM_DEFAULT_CHANGE
            }
          />
        </Flex>
        <Box className="tax-id-col tax-id-number">
          <MdDescription /> {taxId.taxIdentificationNumber}
        </Box>
      </Grid>
    </IconContext.Provider>
  );
}

export default Header;
