export const handleAddCategory = category => {
  const filter = JSON.parse(sessionStorage.getItem('categories'));

  if (!filter || filter?.length === 0) {
    sessionStorage.setItem('categories', JSON.stringify([category]));
  } else if (!filter?.find(el => el.filterName === category.filterName)) {
    sessionStorage.setItem('categories', JSON.stringify([category, ...filter]));
  }
};

export const handleRemoveCategory = category => {
  const filter = JSON.parse(sessionStorage.getItem('categories'));
  filter?.splice(filter?.indexOf(filter?.find(el => el?.filterName === category?.filterName)), 1);

  sessionStorage.setItem('categories', JSON.stringify(filter));
};
