import styled from 'styled-components';
import { maxWidth, maxHeight, minWidth, minHeight } from 'styled-system';

import { Box } from './Box';

export const Container = styled(Box)(maxHeight, maxWidth, minHeight, minWidth);

Container.displayName = 'Container';

Container.propTypes = {
  ...maxWidth.propTypes,
  ...minWidth.propTypes,
  ...maxHeight.propTypes,
  ...minHeight.propTypes,
};
