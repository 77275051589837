import { IoIosArrowDown } from 'react-icons/io';
import styled from 'styled-components';

export const Select = styled.select`
  position: relative;

  width: 100%;
  height: 100%;

  padding: 12px;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #2274a5;

  background-color: transparent;
  border: none;

  appearance: none;
  outline: none;

  cursor: pointer;
  z-index: 1;
`;

Select.Container = styled.div`
  position: relative;

  max-width: 100%;
  width: 100%;

  background-color: transparent;
  border: 1px solid #d2d4d7;
`;

Select.Icon = styled(IoIosArrowDown)`
  position: absolute;
  top: 11px;
  right: 12px;

  font-size: 18px;
  z-index: 0;
`;
