import { getRelatedDealers } from '../../services/related-dealers';
import { getIdByDealershipName } from '../../services/buyers';
import { fetchWishlist, insertWishlist } from '../../services/wishlist';
import { saveBuyerId } from '../../utils/auth';
import ActionTypes from '../../constants/actionTypes';
import { gtmTracker, gtmVariables } from '../../utils/gtmTracker';
import { getTaxIdList } from '../../services/taxids';

export const getWishlist = () => {
  return async dispatch => {
    dispatch({
      type: ActionTypes.Wishlist.Fetch,
    });

    try {
      const wishlist = await fetchWishlist();
      dispatch({
        type: ActionTypes.Wishlist.FetchSuccess,
        wishlist,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.Wishlist.FetchError,
        error: err.message || err,
      });
    }
  };
};

export function addWishlist(vehicleId) {
  return async dispatch => {
    dispatch({
      type: ActionTypes.Wishlist.Add,
    });
    try {
      const wishlist = await insertWishlist(vehicleId);
      dispatch({
        type: ActionTypes.Wishlist.AddSuccess,
        wishlist,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.Wishlist.AddError,
        error: err.message || err,
      });
    }
  };
}

export const fetchRelatedDealers = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.RelatedDealers.Fetch,
    });

    return getRelatedDealers()
      .then(response => {
        dispatch({
          type: ActionTypes.RelatedDealers.FetchSuccess,
          relatedDealers: response.data.result.sort((a, b) =>
            (a.dealershipName || '').localeCompare(b.dealershipName || '')
          ),
        });
      })
      .catch(e => {
        dispatch({
          type: ActionTypes.RelatedDealers.FetchError,
          error: e.message || e,
        });
      });
  };
};

export const getBuyerId = dealershipName => {
  return async dispatch => {
    dispatch({
      type: ActionTypes.RelatedDealers.GetBuyerId,
    });

    try {
      const buyerId = await getIdByDealershipName(dealershipName);

      dispatch(getBuyerIdSuccess(buyerId));
    } catch (err) {
      gtmTracker({
        [gtmVariables.BUYER_ID]: null,
      });

      dispatch({
        type: ActionTypes.RelatedDealers.GetBuyerIdError,
        error: err.message || err,
      });
    }
  };
};

const getBuyerIdSuccess = buyerId => {
  saveBuyerId(buyerId);
  gtmTracker({
    [gtmVariables.BUYER_ID]: buyerId,
  });

  return {
    type: ActionTypes.RelatedDealers.GetBuyerIdSuccess,
    buyerId,
  };
};

export const updateAuctionView = view => ({
  type: ActionTypes.Settings.UpdateAuctionView,
  view,
});

export const setUserProfileState = isRegistrationOutdate => ({
  isRegistrationOutdate,
  type: ActionTypes.UI.SetUserProfileState,
});

export const defaultUserProfile = payload => ({
  type: ActionTypes.UI.DefaultUserProfile,
  payload,
});

export const userProfile = payload => ({
  type: ActionTypes.UI.UserProfile,
  payload,
});

export const getDefaultUserProfile = () => async dispatch => {
  try {
    const result = await getTaxIdList();
    const profile = result?.data.data.filter(profile => profile.default === true);

    dispatch(defaultUserProfile({ profile, userProfileLoading: false }));
  } catch (error) {
    dispatch(
      defaultUserProfile({
        profile: null,
        userProfileError: true,
        userProfileLoading: false,
      })
    );
  }
};
