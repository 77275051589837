import React, { useMemo, useEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { connect } from 'react-redux';

import { getUser } from '../../../store/selectors/userSelectors';
import { Container, Select, ArrowDown } from './styles';

function DealersDropdown({ relatedDealers, selectedDealer, onDealerChange, mb }) {
  const alphabeticallyDealers = useMemo(() => {
    if (!relatedDealers) return;

    const formattedDealers = relatedDealers.sort((a, b) => {
      const dealershipNameA = a.dealershipName.trim();
      const dealershipNameB = b.dealershipName.trim();
      return dealershipNameA.localeCompare(dealershipNameB);
    });

    return formattedDealers;
  }, [relatedDealers]);

  useEffect(() => {
    if (selectedDealer) {
      onDealerChange(selectedDealer);
    }
  }, [selectedDealer]);

  return (
    <Container mb={mb}>
      <Select onChange={e => onDealerChange(e.target.value)} value={selectedDealer} required>
        <option value="" disabled>
          Selecione uma Loja
        </option>
        {alphabeticallyDealers?.map(({ _id, dealershipName }) => (
          <option key={_id} value={_id}>
            {dealershipName?.toUpperCase()}
          </option>
        ))}
      </Select>
      <ArrowDown>
        <IoIosArrowDown color="#2f3741" size={24} />
      </ArrowDown>
    </Container>
  );
}

const mapStateToProps = state => ({
  relatedDealers: getUser(state).relatedDealers,
});

export default connect(mapStateToProps)(DealersDropdown);
