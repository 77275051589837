import React from 'react';
import { Container, ContentGridItem, IconGridItem } from './styles/snack-styles';
import AttentionIcon from '../../images/icons/attention-icon.svg';
import { Link } from 'react-router-dom';

export const WarningNotificationSnack = ({ link = '/atualizar-perfil', shouldShow = false }) => {
  return (
    <Container shouldDisplay={shouldShow}>
      <IconGridItem>
        <img src={AttentionIcon} alt="Atenção" />
      </IconGridItem>
      <ContentGridItem>
        <h4>Atualização necessária</h4>
        <p>
          Atualize seus dados cadastrais, é rápido e fácil. <Link to={link}>Saiba mais.</Link>
        </p>
      </ContentGridItem>
    </Container>
  );
};
