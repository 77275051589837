import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import { Text } from '../../abstract';
import { Button, Price } from '..';
import { toPrice } from '../../../utils/number';

const formatPrice = price => {
  return toPrice(price, false);
};

export const BuyNowButton = ({ price = 0, priceProps = {}, onlyText = false, ...props }) => {
  const formattedPrice = formatPrice(price);

  return (
    <Button
      type="button"
      variant="yellow"
      textAlign="center"
      flexWrap="wrap"
      justifyContent="space-evenly"
      width={1}
      px={1}
      py={1}
      height="auto"
      {...props}
    >
      <Text fontSize={1} fontWeight={800}>
        Negociar com exclusividade
      </Text>

      {!onlyText && (
        <div>
          <Text fontSize={0}>Lance mínimo:</Text>
          <Price
            value={formattedPrice}
            fontSize={formattedPrice.length > 6 ? 2 : 3}
            display="block"
            symbolFontSize={rem(10)}
            {...priceProps}
          />
        </div>
      )}
    </Button>
  );
};

BuyNowButton.propTypes = {
  price: PropTypes.number,
  priceProps: PropTypes.object,
};
