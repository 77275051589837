import React from 'react';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import styled from 'styled-components';

import loading from '../../../images/icons/loading-tail.svg';
import { Button as AbstractButton, Image } from '../../abstract';
import { Icon } from './Icon';

const StyledButton = styled(AbstractButton)`
  ${props =>
    props.isLoading &&
    `
    .button-icon {
      display: none;
    }
  `};
`;

StyledButton.displayName = 'StyledButton';

// TODO: Use a styled button to hide span inside the button when loading
export const Button = React.forwardRef(
  ({ children, isRounded, isLoading, iconSize = 24, ...props }, ref) => (
    <StyledButton
      ref={ref}
      borderRadius={isRounded ? 'rounded' : 'default'}
      isLoading={isLoading}
      {...props}
    >
      <IconContext.Provider value={{ size: iconSize }}>
        {children}
        {isLoading && <Image ml={1} src={loading} width="20" />}
      </IconContext.Provider>
    </StyledButton>
  )
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  isRounded: PropTypes.bool,
  iconSize: PropTypes.number,
  isLoading: PropTypes.bool,
};

Button.Icon = Icon;
