import styled from 'styled-components';
import { rem } from 'polished';
import {
  buttonStyle,
  fontWeight,
  borders,
  borderColor,
  borderRadius,
  fontFamily,
} from 'styled-system';

import { Flex } from './Flex';

export const Button = styled(Flex).attrs(props => ({
  variant: props.disabled ? 'disabled' : props.variant || 'primary',
}))`
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  ${buttonStyle};
  ${fontWeight};
  ${borders};
  ${borderColor};
  ${borderRadius};
  ${fontFamily};
`;

Button.displayName = 'Button';

Button.propTypes = {
  ...buttonStyle.propTypes,
  ...fontWeight.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...fontFamily.propTypes,
};

Button.defaultProps = {
  as: 'button',
  fontSize: 2,
  px: 3,
  py: 0,
  variant: 'primary',
  height: rem(48),
  borderRadius: 'default',
  fontFamily: 'paragraphs',
  fontWeight: 'paragraphs.regular',
  lineHeight: 'headings',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
