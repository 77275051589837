import React, { useCallback, useState } from 'react';
import { ContactForm } from './contact-form';
import { Container, ImageContainer } from './styles/contact-form-styles';
import SupportImage from '../../images/profile-update-suppor-image.png';
import { useUserProfileContext } from '../../context/UserProfileContext';
import { useHistory } from 'react-router-dom';

export const UpdateContractInfo = () => {
  const { currentUserProfile, updateProfile } = useUserProfileContext();
  const [status, setSuccess] = useState({ success: false, error: false });
  const history = useHistory();
  const onSubmit = useCallback(
    async values => {
      try {
        await updateProfile(values);
        setSuccess({ success: true, error: false });
        setTimeout(() => history.push('/'), 2000);
      } catch (error) {
        setSuccess({ success: false, error: true });
        setTimeout(() => setSuccess({ success: false, error: false }), 1500);
      }
    },
    [updateProfile, history]
  );

  return (
    <Container>
      <ContactForm initialValues={currentUserProfile} onSubmit={onSubmit} status={status} />
      <ImageContainer>
        <img src={SupportImage} alt="User updating own register" />
      </ImageContainer>
    </Container>
  );
};
