import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';

import ParticipatingMobileTemplate from '../../filters/participating-filters/components/participating-mobile-template';
import ParticipatingDesktopTemplate from '../../filters/participating-filters/components/participating-desktop-template';
import { handleFilters, setSelectedAuctionId } from '../../../store/actions/auction.actions';
import FilterNavigation from '../../filters/components/filter-navigation';
import { sortAuctionsByFilter } from '../../../utils/sorting';
import { ListContainer } from './styles';
import Card from '../Components/Card';
import AuctionParticipateCard from '../../AuctionCards/AuctionParticipateCard';

function ParticipatingAuctionsList({
  toggleParticipatingFilters,
  showParticipatingFilters,
  setSelectedAuctionId,
  selectedAuctionId,
  sortFilterParam,
  auctions,
  displayWidth,
}) {
  const [participatingAuctions, setParticipatingAuctions] = useState([]);

  const { user } = useSelector(state => state.authentication);

  useEffect(
    function() {
      const sortedAuctions = sortAuctionsByFilter([...auctions], sortFilterParam);
      setParticipatingAuctions(sortedAuctions);
    },
    [auctions, sortFilterParam]
  );

  /**
   * Handle page scroll
   *
   */
  function handleOnLoad(id) {
    if (id === selectedAuctionId) {
      setSelectedAuctionId(null);
      document.getElementById(id).scrollIntoView();
    }
  }

  function toggleFilters() {
    toggleParticipatingFilters({ showParticipatingFilters: !showParticipatingFilters });
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <div>
          <FilterNavigation handleFilterButton={toggleFilters} showResultNumber={false} tab={1} />
          <ParticipatingDesktopTemplate />
        </div>
        <ListContainer>
          {participatingAuctions?.map((auction, index) => (
            <AuctionParticipateCard
              auction={auction}
              displayWidth={displayWidth}
              previousCard={handleOnLoad}
              key={auction._id}
              isFirstChild={index === 0}
            />

            /* <Card
              key={key}
              currentTab={1}
              showStatus={true}
              auction={auction}
              handleOnLoad={handleOnLoad}
            /> */
          ))}
          <ParticipatingMobileTemplate />
        </ListContainer>
      </div>
    </>
  );
}

function mapDispatchToprops(dispatch) {
  return {
    setSelectedAuctionId: bindActionCreators(setSelectedAuctionId, dispatch),
    toggleParticipatingFilters: bindActionCreators(handleFilters, dispatch),
  };
}

function mapStateToProps({ ui }) {
  return {
    selectedAuctionId: ui.selectedAuctionId,
    sortFilterParam: ui.sortFilterParam,
    showParticipatingFilters: ui.filters.showParticipatingFilters,
    sortFilterParam: ui.sortFilterParam,
  };
}

export default connect(mapStateToProps, mapDispatchToprops)(ParticipatingAuctionsList);
