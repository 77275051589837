import 'rsuite-table/dist/css/rsuite-table.css';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AdapterMoment from '@mui/lab/AdapterMoment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { isDate } from 'lodash';
import get from 'lodash/get';
import React, { useCallback, useState } from 'react';
import { Column, HeaderCell, Table } from 'rsuite-table';

import { Layout } from '../../components';
import { Button } from '../../components/Buttons/old';
import { useGetAuctionsQuery, useGetNinjasQuery } from '../../services/commercial';
import Cell from './components/Cell';
import SliderFilter from './components/SliderFilter';
import { Container } from './styles';
import { columns } from './utils/columns';
import { auctionTemperatureFormatter, marketPriceFormatter } from './utils/formatters';
import { localeTable, localeTextField } from './utils/locale';
import { Box } from '../../components/abstract';
import { MdSearch } from 'react-icons/md';
import moment from 'moment';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function expensiveFiltering({
  allAuctions,
  chanceFilter,
  marketPriceFilter,
  timeFilter = [],
  orderBy,
  orderDirection,
  ninjaFilter,
  carModelFilter,
}) {
  const filterByTemperature = auction => {
    const currentChance = auctionTemperatureFormatter(auction.vehiclePricing, auction);
    return chanceFilter.includes(currentChance);
  };

  const filterByPrice = auction => {
    const currentMarketPrice = Number(
      marketPriceFormatter(auction.fipe, auction)?.replace('%', '') || 0
    );
    const finalPercentage = currentMarketPrice > 150 ? 150 : currentMarketPrice;
    return finalPercentage >= marketPriceFilter[0] && finalPercentage <= marketPriceFilter[1];
  };

  const filterByTime = auction => {
    const expectedEndTime = moment(auction.currentAuctionEndTime);
    const [start, end] = timeFilter;
    let isBetweenInterval = true;

    if (isDate(start)) isBetweenInterval = expectedEndTime.isSameOrAfter(start);
    if (isDate(end)) isBetweenInterval = isBetweenInterval && expectedEndTime.isSameOrBefore(end);

    return isBetweenInterval;
  };

  let filteredAuctions = allAuctions.filter(auction => {
    return filterByTemperature(auction) && filterByPrice(auction) && filterByTime(auction);
  });

  if (!ninjaFilter.includes('Todos')) {
    const ninjaFilterValues = ninjaFilter.map(value =>
      value === 'Aguardando assoc.'
        ? 'não encontrado'
        : value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase()
    );

    filteredAuctions = filteredAuctions.filter(auction => {
      return ninjaFilterValues.includes(
        get(auction, 'ninjaHost.owner.name')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase()
      );
    });
  }

  if (carModelFilter) {
    const lowerCaseModel = carModelFilter.toLocaleLowerCase();
    filteredAuctions = filteredAuctions.filter(auction => {
      const model = get(auction, 'model', 'não definido').toLocaleLowerCase();
      return model.includes(lowerCaseModel);
    });
  }

  const orded = filteredAuctions.sort((a, b) => {
    const valueA = get(a, orderBy);
    const valueB = get(b, orderBy);
    if (orderDirection === 'desc') {
      if (valueA > valueB) return 1;
      if (valueA < valueB) return -1;
      return 0;
    } else {
      if (valueA < valueB) return 1;
      if (valueA > valueB) return -1;
      return 0;
    }
  });

  return orded;
}

function NinjaDashboardPage() {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 2);
  const [orderBy, setOrderBy] = useState('currentAuctionEndTime');
  const [orderDirection, setOrderDirection] = useState('desc');
  const [chanceFilter, setChaceFilter] = useState(['Quente', 'Morno', 'Frio']);
  const [ninjaFilter, setNinjaFilter] = useState(['Todos']);
  const [carModelFilter, setCarModelFilter] = useState('');
  const [marketPriceFilter, setMarketPriceFilter] = useState([0, 150]);
  const [timeFilter, setTimeFilter] = useState([today, tomorrow]);
  const [params, setParams] = useState({
    page: 1,
    orderBy,
    orderDirection,
    chanceFilter,
    marketPriceFilter,
    timeFilter,
    ninjaFilter,
    carModelFilter,
  });

  const { data: ninjaData, isLoading: ninjaLoading } = useGetNinjasQuery();
  const { data, isLoading, startedTimeStamp, isFetching } = useGetAuctionsQuery({
    ...params,
    transformResponse: (response, meta, arg) =>
      expensiveFiltering({ ...arg, allAuctions: response.result }),
  });

  const handleChangeChaceFilter = useCallback((event, newValue) => {
    setChaceFilter(newValue);
  }, []);

  const handleChangeOrderBy = useCallback((dataKey, sortType) => {
    setOrderBy(dataKey);
    setOrderDirection(sortType);
    setParams(p => ({
      ...p,
      orderBy: dataKey,
      orderDirection: sortType,
    }));
  }, []);

  const handleChangeNinja = useCallback((event, newValue) => {
    setNinjaFilter(newValue);
  }, []);

  const handleCarModelChange = useCallback(event => {
    setCarModelFilter(event.target.value);
  }, []);

  const handleChangeParams = useCallback(() => {
    setParams({
      page: 1,
      orderBy,
      orderDirection,
      chanceFilter,
      marketPriceFilter,
      timeFilter,
      ninjaFilter,
      carModelFilter,
    });
  }, [
    orderBy,
    orderDirection,
    chanceFilter,
    marketPriceFilter,
    timeFilter,
    ninjaFilter,
    carModelFilter,
  ]);

  const ninjaOptions = [
    'Todos',
    ...(ninjaData?.result
      ?.filter(({ profile: p }) => p.firstName && p.lastName)
      .map(({ profile: p }) => `${p.firstName} ${p.lastName}`) || []),
    'Aguardando assoc.',
  ];

  return (
    <React.StrictMode>
      <Layout>
        <Container width="100%" mx="auto">
          <Container.Title>Relátorio de Leilões em Andamento</Container.Title>
          <Container.Filter>
            <Grid container spacing={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <DateTimePicker
                    renderInput={props => <TextField {...props} fullWidth />}
                    label="Termino de"
                    value={timeFilter[0]}
                    onChange={newValue => {
                      setTimeFilter(i => [newValue.toDate(), i[1]]);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <DateTimePicker
                    renderInput={props => <TextField {...props} fullWidth />}
                    label="Termino até"
                    value={timeFilter[1]}
                    onChange={newValue => {
                      setTimeFilter(i => [i[0], newValue.toDate()]);
                    }}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <Autocomplete
                  {...localeTextField}
                  multiple
                  options={['Quente', 'Morno', 'Frio']}
                  getOptionLabel={option => option}
                  onChange={handleChangeChaceFilter}
                  value={chanceFilter}
                  filterSelectedOptions
                  loadingText="Carregando..."
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={params => <TextField {...params} label="Chance Atual" />}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <SliderFilter
                  label="Atingimento % de Fipe:"
                  value={marketPriceFilter}
                  onChange={setMarketPriceFilter}
                  max={150}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label="Modelo"
                  value={carModelFilter}
                  onChange={handleCarModelChange}
                  loading={ninjaLoading}
                  multiple
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Autocomplete
                  {...localeTextField}
                  multiple
                  options={ninjaOptions}
                  getOptionLabel={option => option}
                  onChange={handleChangeNinja}
                  value={ninjaFilter}
                  loading={ninjaLoading}
                  filterSelectedOptions
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={params => <TextField {...params} label="Ninja" />}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Button
                  onClick={handleChangeParams}
                  variant="primary"
                  justifyContent="center"
                  flex={1}
                  fontSize={2}
                  height={56}
                  leftIcon={
                    <Box size={{ sm: 18, lg: 22 }}>
                      <MdSearch size="100%" />
                    </Box>
                  }
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Container.Filter>
          <Table
            data={data}
            height={500}
            locale={localeTable}
            sortColumn={orderBy}
            sortType={orderDirection}
            loading={isLoading || isFetching}
            onSortColumn={handleChangeOrderBy}
          >
            {columns.map(({ label, dataKey, type, formatter, ...column }) => (
              <Column {...column} key={`column-${dataKey}`}>
                <HeaderCell>{label}</HeaderCell>
                <Cell
                  {...column}
                  dataKey={dataKey}
                  formatter={formatter}
                  startedTimeStamp={startedTimeStamp}
                  type={type || (formatter && 'formatter')}
                />
              </Column>
            ))}
          </Table>
        </Container>
      </Layout>
    </React.StrictMode>
  );
}

export default NinjaDashboardPage;
