import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../enhanced/Modal';
import { Content, Title, List, ListItem, Button } from './styles';
import ImageNF from './productNF.svg';

import { toggleModal } from '../../../store/actions/ui.actions';
import { PRODUCT_NF } from '../../../constants/modalConstants';

const descriptions = [
  'Os veículos com esse selo possuem vendedores que emitem Nota Fiscal de Produto.',
  'Se o regime tributário do comprador Pessoa Jurídica (PJ) for compatível com o regime tributário do vendedor, pode haver possibilidade de obter crédito fiscal integral de ICMS, PIS e Cofins.',
  'Avalie sempre com o seu contador as possibilidades de créditos fiscais nas operações de compra e venda de veículos.',
];

export const ProductNF = () => {
  const dispatch = useDispatch();
  const { show } = useSelector(state => state.ui.modals.productNF);

  const [size, setSize] = useState(window.screen.width);

  const handleToggle = () => dispatch(toggleModal({ key: PRODUCT_NF, show: false }));

  window.addEventListener('resize', () => setSize(window.screen.width));

  return (
    <Modal
      toggle={handleToggle}
      show={show}
      style={{
        content: {
          position: 'relative',
          maxHeight: size <= 1024 ? '100%' : '667px',
          height: size <= 1024 ? '100%' : 'auto',
          maxWidth: '555px',
          overflow: 'unset',
          top: 'auto',
          left: 'auto',
          bottom: 'auto',
          right: 'auto',
          padding: '28px 36px',
          border: 'none',
          borderRadius: size <= 1024 ? 0 : 4,
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '999999',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <Content>
        <img src={ImageNF} />
        <Title>Nota Fiscal de Produto</Title>
        <List>
          {descriptions.map((d, index) => (
            <ListItem key={index}>{d}</ListItem>
          ))}
        </List>
        {size <= 1024 ? <Button onClick={handleToggle}>Entendi</Button> : null}
      </Content>
    </Modal>
  );
};
