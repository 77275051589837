import axios from 'axios';
import AppConstants from '../../constants/appConstants';
import { enableTooltip } from '../tooltip/actions';
import {
  autoBidFailure,
  autoBidRequest,
  autoBidSuccess,
  listAutoBidVehiclesRequest,
  listAutoBidVehiclesSuccess,
  listAutoBidVehiclesFailure,
  listAllAutoBidVehiclesRequest,
  listAllAutoBidVehiclesSuccess,
  listAllAutoBidVehiclesFailure,
} from './actions';
import { getToken } from '../../utils/auth';

export function createAutoBid(inspection, user, balance, amount) {
  return async function(dispatch) {
    dispatch(autoBidRequest());

    try {
      const { data } = await axios.post(
        `${AppConstants.API.autobid}/create`,
        {
          inspection,
          user,
          balance,
          amount,
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken().access_token,
            'x-custom-user-agent': 'Leiloes Web',
          },
        }
      );

      dispatch(autoBidSuccess(data));
      dispatch(enableTooltip({ inspection, messageId: 1, balance }));
      return data;
    } catch (error) {
      dispatch(autoBidFailure(error?.response?.data?.message));
    } finally {
      dispatch(listAutoBidVehicles(user));
    }
  };
}

export function editionAutoBidVehicle(autobidId, balance, user) {
  return async function(dispatch) {
    dispatch(autoBidRequest());

    try {
      const { data } = await axios.put(
        `${AppConstants.API.autobid}/update-one-by-id/${autobidId}`,
        {
          status: 'ON',
          balance,
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken().access_token,
            'x-custom-user-agent': 'Leiloes Web',
          },
        }
      );
      dispatch(autoBidSuccess(data));
      dispatch(enableTooltip({ inspection: data.inspection, messageId: 2, balance }));
      return data;
    } catch (error) {
      dispatch(autoBidFailure(error?.response?.data?.message));
    } finally {
      dispatch(listAutoBidVehicles(user));
    }
  };
}

export function removeAutoBidVehicle(autobidId, user) {
  return async function(dispatch) {
    dispatch(autoBidRequest());

    try {
      const { data } = await axios.patch(
        `${AppConstants.API.autobid}/change-status-by-id/${autobidId}`,
        {
          status: 'OFF',
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken().access_token,
            'x-custom-user-agent': 'Leiloes Web',
          },
        }
      );
      dispatch(autoBidSuccess(data));
      dispatch(enableTooltip({ inspection: data.inspection, messageId: 3 }));
    } catch (error) {
      dispatch(autoBidFailure(error?.response?.data?.message));
    } finally {
      dispatch(listAutoBidVehicles(user));
    }
  };
}

export function listAutoBidVehicles(userId) {
  return async function(dispatch) {
    dispatch(listAutoBidVehiclesRequest());

    try {
      const { data } = await axios.get(
        `${AppConstants.API.autobid}/vehicle-list/by-user/${userId}`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken().access_token,
          },
        }
      );
      dispatch(listAutoBidVehiclesSuccess(data));
    } catch (error) {
      dispatch(listAutoBidVehiclesFailure(error?.response?.data?.message));
    }
  };
}

export function listAllAutoBidVehicles() {
  return async function(dispatch) {
    dispatch(listAllAutoBidVehiclesRequest());

    try {
      const { data } = await axios.get(
        `${AppConstants.API.autobid}/vehicle-list-all?limit=${300}`,
        {
          headers: {
            Authorization: 'Bearer ' + getToken().access_token,
          },
        }
      );

      dispatch(listAllAutoBidVehiclesSuccess(data));
    } catch (error) {
      dispatch(listAllAutoBidVehiclesFailure(error?.response?.data?.message));
    }
  };
}
