import { buttons } from './buttons';
import * as colors from './colors';
import space from './space';
import * as typography from './typography';
import breakpoints from './breakpoints';
import shadows from './shadows';
import * as borders from './borders';
import cards from './cards';
import tabs from './tabs';

export default {
  buttons,
  colors,
  breakpoints,
  space,
  ...typography,
  ...borders,
  shadows,
  tabs,
  cards,
};
