import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const Footer = ({ children, className, ...props }) => {
  const classNames = classnames('modal-footer', className);
  return (
    <footer className={classNames} {...props}>
      <div className="modal-footer__content">{children}</div>
    </footer>
  );
};
Footer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export { Footer };
