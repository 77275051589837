const { useMemo } = require('react');

export const enBounds = {
  LOWER: 0,
  MEDIUM: 1,
  HIGH: 2,
};

export function definePointBounds(lineBounds, point) {
  let lowerBoundaryIndex = -1,
    upperBoundaryIndex = -1;

  for (let i = 0; i < lineBounds.length; i++) {
    const currentBoundPoint = lineBounds[i];
    const shouldAddUpperBound = point < currentBoundPoint;

    if (shouldAddUpperBound) {
      upperBoundaryIndex = i;
      break;
    } else {
      lowerBoundaryIndex = i;
    }
  }
  const bounds = { lowerBoundaryIndex, upperBoundaryIndex };
  return bounds;
}

export function mapBoundsToLabel(bounds) {
  const mapper = {
    '-1': enBounds.LOWER,
    '0': enBounds.MEDIUM,
    '1': enBounds.HIGH,
  };

  const label = mapper[`${bounds.lowerBoundaryIndex}`];
  if (!label === undefined) throw new Error('Unexpected lower bound for temperature mapper');

  return label;
}

export function useAuctionTemperature(auction, latestBidValue) {
  const { summary } = auction || { summary: null };

  const boundsLine = useMemo(() => {
    if (!summary) {
      return [0, 0];
    }

    const lowerTarget = (summary.vehiclePricing && summary.vehiclePricing.warmTarget) || 0;
    const upperTarget = (summary.vehiclePricing && summary.vehiclePricing.hotTarget) || 0;

    return [lowerTarget, upperTarget];
  }, [summary]);

  const hasValidTemperature = useMemo(() => boundsLine.every(point => point > 0), [boundsLine]);

  const statusLabel = useMemo(() => {
    if (!summary || !hasValidTemperature) {
      return enBounds.HIGH;
    }

    const bounds = definePointBounds(boundsLine, latestBidValue);
    const label = mapBoundsToLabel(bounds);

    return label;
  }, [boundsLine, latestBidValue, hasValidTemperature]);

  return { hasValidTemperature, statusLabel };
}
