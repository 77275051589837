import React from 'react';
import { MdRemove } from 'react-icons/md';

import { Button } from './styles';

const MinusButton = ({ ...rest }) => (
  <Button {...rest}>
    <MdRemove size={20} fill="#757778" />
  </Button>
);

export default MinusButton;
