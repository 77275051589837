import React from 'react';
import styled from 'styled-components';
import image from '../../../images/loading.gif';

const Image = styled.img`
  ${props =>
    props.centered &&
    `
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
`};
`;
export const Loading = props => {
  return <Image src={image} alt="loading" css={{ width: '4.5rem', height: '4.5rem' }} {...props} />;
};

export default Loading;
