import React from 'react';
import { Svg } from './styles';

export function FacebookIcon({ color = '#E9EBEC' }) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7862 1H2.21379C1.89188 1 1.58314 1.12788 1.35551 1.35551C1.12788 1.58314 1 1.89188 1 2.21379L1 21.7862C1 22.1081 1.12788 22.4169 1.35551 22.6445C1.58314 22.8721 1.89188 23 2.21379 23H12.7586V14.4921H9.89483V11.1617H12.7586V8.71138C12.7586 5.87034 14.4959 4.32276 17.0297 4.32276C17.8835 4.3209 18.7369 4.36394 19.5862 4.45172V7.42172H17.8414C16.4607 7.42172 16.1914 8.07414 16.1914 9.03759V11.1579H19.4914L19.0628 14.4883H16.1724V23H21.7862C22.1081 23 22.4169 22.8721 22.6445 22.6445C22.8721 22.4169 23 22.1081 23 21.7862V2.21379C23 1.89188 22.8721 1.58314 22.6445 1.35551C22.4169 1.12788 22.1081 1 21.7862 1V1Z"
        fill={color}
      />
    </Svg>
  );
}
