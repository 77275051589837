import React from 'react';
import PropTypes from 'prop-types';

const TabsContext = React.createContext({
  activeTabId: {},
});

class TabProvider extends React.Component {
  static propTypes = {
    activeTabId: PropTypes.object,
    onTabSelected: PropTypes.func,
    children: PropTypes.node,
  };

  state = {
    tabs: [],
    activeTabId: this.props.activeTabId,
  };

  componentDidUpdate(_, prevState) {
    const { activeTabId, tabs } = this.state;

    if (!activeTabId && !prevState.tabs.length && tabs.length) {
      this.setState({ activeTabId: tabs[0].id });
    }
  }

  addTab = newTab => {
    this.setState(prevState => {
      const isTabFound = prevState.tabs.some(tab => tab.id === newTab.id);

      if (!isTabFound) {
        return {
          tabs: [...prevState.tabs, newTab],
        };
      }

      return prevState;
    });
  };

  updateTab = (tabId, attrs) => {
    this.setState(prevState => {
      const newTabs = prevState.tabs.reduce((acc, current) => {
        if (current.id === tabId) {
          acc.push({ id: tabId, ...attrs });
        } else {
          acc.push(current);
        }

        return acc;
      }, []);

      return { tabs: newTabs };
    });
  };

  onClick = tabId => () => {
    const { onTabSelected } = this.props;
    onTabSelected && onTabSelected(tabId);
    this.setState({ activeTabId: tabId });
  };

  render() {
    return (
      <TabsContext.Provider
        value={{
          ...this.state,
          onClick: this.onClick,
          addTab: this.addTab,
          updateTab: this.updateTab,
        }}
      >
        {this.props.children}
      </TabsContext.Provider>
    );
  }
}

const TabConsumer = TabsContext.Consumer;

export { TabProvider, TabConsumer };

export default TabsContext;
