import React from 'react';

import { StyledButton } from './styles';

export const Button = ({ color, bg, border, borderRadius, hover, children, ...props }) => (
  <StyledButton
    color={color}
    bg={bg}
    border={border}
    hover={hover}
    borderRadius={borderRadius}
    {...props}
  >
    {children}
  </StyledButton>
);
