import styled, { css } from 'styled-components';

export const VehicleInfoBanner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  width: 100%;
  padding: 44px 32px;

  background-color: #1a2331;

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;

    padding: 2.688rem 1.25rem 2rem;
  }
`;

export const VehicleInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media screen and (max-width: 1025px) {
    margin-bottom: 1rem;
  }
`;

export const VehicleNameAndInfos = styled.span`
  width: 70%;
  margin-bottom: 1rem;

  font: 700 normal 1.9rem/1.2em 'Roboto', sans-serif;
  color: #fff;

  @media screen and (max-width: 1025px) {
    margin-bottom: 0.5rem;
    width: 100%;

    font: 700 normal 1.375em 'Roboto', sans-serif;
  }
`;

export const VehicleYearAndKmLabel = styled.span`
  margin-bottom: 1.3rem;

  font: 400 normal 1.5rem/1em 'Roboto', sans-serif;
  color: #fff;

  @media screen and (max-width: 1025px) {
    margin-bottom: 0;
  }
`;

export const VehicleChronometerAndLastOffer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1%;
  width: 80%;

  @media screen and (max-width: 1025px) {
    flex-direction: row;
    align-items: center;

    width: 100%;
    margin: 0 0 1.5rem 0;
  }
`;

export const VehicleLastOfferLabel = styled.span`
  margin-top: 1.125rem;
  margin-bottom: 0.25rem;

  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #fff;
`;

export const VehicleLastOfferAndIsWinningStatus = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 6px;
  margin-bottom: ${({ isParticipating }) => (isParticipating ? 4 : 16)}px;
`;

export const VehicleLastOfferValue = styled.span`
  font: 700 normal 1.125rem/1em 'Roboto', sans-serif;
  color: #fff;
`;

export const VehicleIsWinningLabel = styled.span`
  max-width: 70px;
  width: 100%;

  padding: 0.3rem 0.5rem;
  margin-bottom: 16px;

  font: 700 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #fff;

  background-color: ${props => props.bg};
  border-radius: 1.25rem;
`;

export const VehicleActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-right: 1.25rem;
  width: 100%;

  @media screen and (max-width: 1025px) {
    padding-right: 0;
  }

  ${({ specificStyle }) =>
    specificStyle &&
    css`
      min-height: 100%;
    `}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0 24px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
`;

export const VehicleLastOfferResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  ${({ participating }) =>
    participating &&
    css`
      align-items: flex-start;
    `}

  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

export const AlertIconImg = styled.img`
  align-self: flex-start;

  margin-top: 0.3rem;
  margin-right: 0.3rem;

  fill: #fff;
`;

export const AutoBidViewNinja = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8rem 2rem;
`;

export const AutoBidViewNinjaLabel = styled.span`
  font: 400 normal 1rem/1.4em 'Roboto', sans-serif;
  color: #fff;
`;

export const FeedbackAutoBidContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const FeedbackAutoBidLabel = styled.span`
  font: 400 normal 0.875rem/1.5em 'Roboto', sans-serif;
  color: #fff;
`;

export const TextEnding = styled.span`
  color: #fff6a7;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
`;

export const TextEndingdiv = styled.div`
  padding-bottom: 5px;
`;

export const AutoBidRules = styled.button`
  padding: 0;

  font: 400 normal 0.875rem/1.4em 'Roboto', sans-serif;
  color: #fff;
  text-decoration: underline;

  background-color: transparent;
  border: none;

  cursor: pointer;
  outline: none;
`;

export const Content = styled.section`
  @media screen and (min-width: 2160px) {
    max-width: 1300px;
    width: 100%;

    padding: 0;
    margin: 0 auto;
  }

  .evaluation-section {
    padding-inline: 32px 52px;

    @media screen and (max-width: 1025px) {
      padding-inline-start: 20px;
      padding-right: 0;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  margin: 1.25rem auto;

  border-bottom: 0.0625rem solid #eaebec;
`;

export const NewButton = styled.button`
  width: 100%;
  padding: 12px 0;

  font: normal 16px/1em 'Roboto', sans-serif;
  font-weight: ${props => props.fontWeight ?? 700};
  color: ${props => props.color ?? '#fff'};
  text-align: center;

  background-color: ${props => props.bg ?? '#438a4c'};

  border: none;
  border-radius: 4px;

  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${props => props.hover};
  }
`;

export const SectionContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px 0;
`;

export const AvaliatorContent = styled.section`
  padding-right: 10px;
`;

const SimilarsContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 28px 0;
  margin-top: 32px;
`;

const SimilarTypography = styled.span`
  font: 700 normal 24px/1em 'Nunito', sans-serif;
  color: #2f3741;
`;

export const Recommended = {
  Content: SimilarsContent,
  Title: SimilarTypography,
};

export const FavoriteDiv = styled.div`
  padding-left: 7px;
`;

export const VideoTitle = styled.div`
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  color: #2274a5;
  align-items: center;
  display: flex;
`;
export const VideoContainer = styled.div`
  width: 540px;
  padding-right: 15px;
  @media screen and (max-width: 1025px) {
    width: 100%;
    height: 100%;
  }
`;

export const PhotoCategoryButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const PhotoCategoryButton = styled.a`
  width: auto;
  height: 40px;
  border-radius: 6px;
  background: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-right: 10px;
  background: ${({ isSelected }) => (isSelected ? '#ffffff' : '#00000000')};
  border: 1px solid #ffffff;

  &:active {
    opacity: 0.7;
  }
`;
export const MainPhotoText = styled.div`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ isSelected }) => (isSelected ? '#141414' : '#ffffff')};
  width: max-content;
  @media screen and (max-width: 1025px) {
    font-size: 14px;
  }
`;
