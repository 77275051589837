import React from 'react';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import { borderRadius, themeGet } from 'styled-system';

import { Container } from '../../../abstract';

const TagWrapper = styled(Container)`
  ${borderRadius};
  display: inline-flex;
  align-items: center;
  line-height: 1;
  background-color: ${props =>
    rgba(themeGet(`colors.${props.backgroundColor}`, '#2274A5')(props), props.alpha)};
`;

TagWrapper.displayName = 'TagWrapper';

export const Tag = ({
  children,
  Icon,
  textColor = 'primaries.Taxi',
  backgroundColor = 'secondaries.Uno',
  alpha = 1,
  ...props
}) => (
  <TagWrapper
    borderRadius="rounded"
    px={2}
    py={rem(12)}
    minWidth={rem(190)}
    {...props}
    backgroundColor={backgroundColor}
    alpha={alpha}
    color={textColor}
  >
    <IconContext.Provider value={{ size: 20, style: { marginRight: rem(8) } }}>
      {Icon && <Icon />}
      {children}
    </IconContext.Provider>
  </TagWrapper>
);

Tag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  alpha: PropTypes.number,
};
