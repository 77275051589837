class User {
  isNinja() {
    const _role = this.role ? this.role.toLowerCase() : '';
    return (
      [
        'ninja',
        'sales supervisor',
        'ninja supervisor',
        'super instacarro root admin 2',
        'mechanic supervisor',
      ].indexOf(_role) > -1 &&
      this.permissions &&
      this.permissions.some(permission => {
        return permission === 'can_place_bid_to_all_lojas';
      })
    );
  }

  getId() {
    return this._id || this.userId || this.id;
  }

  isLoja() {
    return this.role && !this.isNinja();
  }

  isLoggedIn() {
    return this.isNinja() || this.isLoja() || false;
  }

  getLoginName() {
    return this.isNinja() ? this.getFullName() : this.dealershipName;
  }

  getFullName() {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }
}

export default User;
