import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../enhanced/Modal';
import { About, ActionsContainer, Button, Content, Image, Text, Title } from './styles';

import withMakeOffer from '../../hoc/WithMakeOffer';
import { formatPrice } from '../../../utils/formatPrice';

import { getSelectedAuction } from '../../../store/selectors';
import { toggleModal } from '../../../store/actions/ui.actions';

import { CONFIRM_BUY_FOR } from '../../../constants/modalConstants';

import FinishIcon from './images/compra-garantida.svg';
import { toAmount } from '../../../utils/number';
import { createAutoBid } from '../../../store/autobid/effects';
import { useSegment } from '../../../hooks/useSegment';
import { makeBid } from '../../../store/actions/auction.actions';
import { useDefaultProfile } from '../../../hooks/useDefaultProfile';

const ConfirmBuyFor = ({ onSubmitBuyFor }) => {
  const [size, setSize] = useState(window.screen.width);
  const dispatch = useDispatch();

  const state = useSelector(state => state);
  const defaultProfile = useDefaultProfile();
  const { show, extras } = useSelector(state => state.ui.modals.confirmBuyFor);
  const { user } = useSelector(state => state.authentication);

  const selectedAuction = getSelectedAuction(state);
  const { trackConfirmBid } = useSegment();

  const toggle = () => dispatch(toggleModal({ key: CONFIRM_BUY_FOR, show: false }));

  const handleNotCastOffAuction = useCallback(async () => {
    await dispatch(createAutoBid(selectedAuction?._id, user.id, toAmount(extras?.bid), 250));

    /*
     * auctionId -> selectedAuction?._id
     * budget -> toAmount(extras?.bid)
     * dealershipName -> user?.dealershipName,
     * dealerId -> null
     * taxProfile -> '',
     * isSuperOffer -> selectedAuction?.auction?.isSupperOffer
     */

    trackConfirmBid({
      inspection_id: selectedAuction?._id,
      auction_id: selectedAuction.auction.idAuction,
      bidAmount: toAmount(extras?.bid),
      type: 'autoBid',
      section: extras?.section,
    });

    dispatch(toggleModal({ key: CONFIRM_BUY_FOR, show: false }));
  }, [extras, user.id, trackConfirmBid]);

  const handleCastOffAuction = useCallback(async () => {
    await dispatch(
      makeBid(
        selectedAuction?._id,
        extras?.bid,
        user?.dealershipName,
        null,
        defaultProfile?.taxIdentificationNumber,
        selectedAuction?.auction?.isSupperOffer
      )
    );
    trackConfirmBid({
      inspection_id: selectedAuction._id,
      auction_id: selectedAuction.auction.idAuction,
      currentBid: selectedAuction.auction.winningBid.amount,
      buyForBid: toAmount(extras?.bid),
      type: 'buyForBid',
      section: extras?.section,
    });
    dispatch(toggleModal({ key: CONFIRM_BUY_FOR, show: false }));
  }, [selectedAuction, extras, user?.dealershipName, trackConfirmBid]);

  const handleSubmit = useCallback(async () => {
    await extras?.onSubmit();
    trackConfirmBid({
      inspection_id: selectedAuction._id,
      auction_id: selectedAuction.auction.idAuction,
      currentBid: selectedAuction.auction.winningBid.amount,
      buyForBid: toAmount(extras?.bid),
      type: 'buyForBid',
      section: extras?.section,
    });
  }, [selectedAuction, extras, trackConfirmBid]);

  const useLayout = useCallback(() => {
    if (extras?.type === 'automaticOffer') {
      return (
        <React.Fragment>
          <About>
            <Title>Importante</Title>
            <Text>
              Com este valor você pode encerrar o leilão e ter a compra garantida. Caso não aceite,
              seu lance máximo será registrado e outros lojistas poderão fazer a compra garantida.{' '}
              <strong>Deseja comprar por R$ {formatPrice(extras?.bid)}?</strong>
            </Text>
          </About>

          <ActionsContainer>
            <Button
              w="110px"
              fontWeight={400}
              color="#2274A5"
              border="1px solid #2274A5"
              onClick={handleNotCastOffAuction}
            >
              Não
            </Button>
            <Button w="200px" bg="#f2c94c" onClick={handleCastOffAuction}>
              Sim, confirmar compra
            </Button>
          </ActionsContainer>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <About>
          <Title>Importante</Title>
          <Text>
            O seu lance é maior que o valor estabelecido para <strong>compra garantida.</strong> Por
            isso, ao continuar o leilão será encerrado e você comprará o veículo no valor de R${' '}
            {formatPrice(extras?.bid)}.<br />
            <strong>Deseja continuar?</strong>
          </Text>
        </About>

        <ActionsContainer>
          <Button
            w="110px"
            fontWeight={400}
            color="#2274A5"
            border="1px solid #2274A5"
            onClick={toggle}
          >
            Cancelar
          </Button>
          <Button w="200px" bg="#f2c94c" onClick={handleSubmit}>
            Sim, confirmar compra
          </Button>
        </ActionsContainer>
      </React.Fragment>
    );
  }, [extras, handleNotCastOffAuction, handleCastOffAuction, handleSubmit]);

  window.addEventListener('resize', () => setSize(window.screen.width));

  return (
    <Modal
      toggle={toggle}
      show={show}
      style={{
        content: {
          position: 'relative',
          maxWidth: '500px',
          overflow: 'unset',
          top: 'auto',
          left: 'auto',
          bottom: 'auto',
          right: 'auto',
          padding: size < 375 ? '36px 28px' : '36px 32px',
          border: 'none',
          margin: size <= 1024 ? '0 16px' : 0,
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '999999',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <Content>
        <Image src={FinishIcon} />
        {useLayout()}
      </Content>
    </Modal>
  );
};

export default withMakeOffer(ConfirmBuyFor);
