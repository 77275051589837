import initialState from './initialState';
import objectAssign from 'object-assign';
import ActionTypes from '../../constants/actionTypes';

function auctionsReducer(state = initialState.authentication, action) {
  switch (action.type) {
    case ActionTypes.Modal.Login.Toggle:
      return objectAssign({}, state, { showModal: action.showModal });
    case ActionTypes.Authenticate.Success:
      return objectAssign({}, state, {
        showModal: false,
        authError: false,
        user: action.user,
        token: action.token,
        authOnGoing: false,
      });
    case ActionTypes.Authenticate.Error:
      return objectAssign({}, state, {
        authError: true,
        authOnGoing: false,
        token: null,
        authErrorMessage: action.error,
      });
    case ActionTypes.Authenticate.Perform:
      return objectAssign({}, state, { authOnGoing: true, authError: false });
    case ActionTypes.Authenticate.DeAuthenticate:
      return objectAssign({}, state, { user: action.user, token: action.token });
    default:
      return state;
  }
}

export default auctionsReducer;
