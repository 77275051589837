export default {
  AUTOBID_REQUEST: '@autobid/AUTOBID_REQUEST',
  AUTOBID_SUCCESS: '@autobid/AUTOBID_SUCCESS',
  AUTOBID_FAILURE: '@autobid/AUTOBID_FAILURE',

  LIST_AUTOBID_VEHICLES_REQUEST: '@autobid/LIST_AUTOBID_VEHICLES_REQUEST',
  LIST_AUTOBID_VEHICLES_SUCCESS: '@autobid/LIST_AUTOBID_VEHICLES_SUCCESS',
  LIST_AUTOBID_VEHICLES_FAILURE: '@autobid/LIST_AUTOBID_VEHICLES_FAILURE',

  LIST_ALL_AUTOBID_VEHICLES_REQUEST: '@autobid/LIST_ALL_AUTOBID_VEHICLES_REQUEST',
  LIST_ALL_AUTOBID_VEHICLES_SUCCESS: '@autobid/LIST_ALL_AUTOBID_VEHICLES_SUCCESS',
  LIST_ALL_AUTOBID_VEHICLES_FAILURE: '@autobid/LIST_ALL_AUTOBID_VEHICLES_FAILURE',
};
