import AppConstants from '../constants/appConstants';

export const padDigits = (number, digits) => {
  return new Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number;
};

export const toPrice = (value, symbol = true) => {
  if ((!value && value < 0) || typeof value !== 'number') {
    return '';
  }

  return (symbol ? 'R$ ' : '') + value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const formatToThousands = (number, decimalPoints, point, comma) => {
  const _point = typeof point !== 'undefined' ? point : ',';
  const _comma = typeof comma !== 'undefined' ? comma : '.';
  const _decimalPoints = typeof decimalPoints !== 'undefined' ? decimalPoints : 0;
  const numberFloat = number.toFixed(_decimalPoints).split('.');
  numberFloat[0] = numberFloat[0].replace(/\B(?=(\d{3})+(?!\d))/g, _comma);
  return numberFloat.join(_point);
};

export const toAmount = amount => {
  if (!amount) {
    return '';
  }
  const strippedAmount = `${amount}`.replace(/\D/g, '');
  if (strippedAmount === '') {
    return '';
  }
  const intAmount = parseInt(strippedAmount, 10);
  return intAmount > AppConstants.MAX_INPUT_AMOUNT ? '' : intAmount;
};

export const fractionToPercentage = (numerator, denominator) => {
  const percentage = (numerator / denominator) * 100;

  if (percentage > 100) return 100;

  return Math.round(percentage);
};

export const getIntegerValue = (value, fallback) => {
  const n = Number.parseInt(value, 10);
  return Number.isNaN(n) ? fallback : n;
};
