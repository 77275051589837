import { sortFilterParams } from '../constants/appConstants';
import { sortAuctionsByFilter } from './sorting';

export const sortFavoriteList = favorites => {
  let aucFinished = [];
  let aucOnGoing = [];
  for (let favorite of favorites) {
    if (favorite.auction.status === 'FINISHED') {
      aucFinished.push(favorite);
    } else {
      aucOnGoing.push(favorite);
    }
  }

  const aucOnGoingSorted = sortAuctionsByFilter(
    aucOnGoing,
    sortFilterParams.SHORTEST_EXPECTED_TIME
  );
  return [...aucOnGoingSorted, ...aucFinished];
};
