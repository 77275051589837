import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, IconButton, Typography } from '@mui/material';
import { Badge } from './styles';
import { primaries } from '../../../../design-system/colors';

import {
  KeyboardArrowUpRounded,
  KeyboardArrowDownRounded,
  SearchRounded,
} from '@mui/icons-material';
import car_check from '../../../../images/categories/category_car_check.svg';
import category_car from '../../../../images/categories/category_car.svg';
import category_eye from '../../../../images/categories/category_eye.svg';
import category_like from '../../../../images/categories/category_like.svg';
import category_sale from '../../../../images/categories/category_sale.svg';
import category_timer from '../../../../images/categories/category_timer.svg';
import category_eraser from '../../../../images/categories/category_eraser.svg';
import category_fipe70k from '../../../../images/categories/category_fipe70k.svg';
import category_fipe100k from '../../../../images/categories/category_fipe100k.svg';
import category_buy_for from '../../../../images/categories/category_buy_for.svg';

import { clearCategoryFilter, setCategoryFilter } from '../../../../store/actions/ui.actions';
import { useFilter } from '../../../../hooks/useFilter';
import { LabeledCheckBoxWithIcon } from '../LabeledCheckBoxWithIcon';
import { CannotSelectWithActiveCategoriesModal } from '../../../Modals/CannotSelectWithActiveCategoriesModal';
import { handleAddCategory, handleRemoveCategory } from '../../../../helpers/filterSession';
import { sendEvent } from '../../../../helpers/googleAnalytics';

const makeFilters = [
  { name: 'buyFor', label: 'Compra Garantida', imgSrc: category_buy_for },
  { name: 'cautelar', label: 'Cautelar Aprovada', imgSrc: car_check },
  { name: 'fipe70', label: '70% da FIPE', imgSrc: category_sale },
  { name: 'closeToEnd', label: 'Próximos a encerrar', imgSrc: category_timer },
  /*   { name: 'score80', label: 'Score acima de 80%', imgSrc: category_like }, */
  { name: 'fipe70k', label: 'FIPE acima de R$ 70.000', imgSrc: category_fipe70k },
  { name: 'mostViewed', label: 'Mais visualizados', imgSrc: category_eye },
  { name: 'before100k', label: 'Até 100.000 Km', imgSrc: category_car },
  { name: 'fipe100k', label: 'FIPE acima de R$ 100.000', imgSrc: category_fipe100k },
];

export function FilterCategory() {
  const categoryFilter = useSelector(state => state.ui.categoryFilter);
  const user = useSelector(state => state.authentication.user);
  const applyedFilter = useSelector(state => state.ui.filters.newOffersFiltersApplyed);
  const dispatch = useDispatch();

  const {
    toogleFilter,
    filterList,
    filtredAuctions: { lengths, data },
    clearFilter,
  } = useFilter();

  const [open, setOpen] = useState(true);
  const [selectedState, setSelectedState] = useState(null);
  const [openedCannotModal, setOpenedCannotModal] = useState(false);

  const onClickToggle = () => {
    if (applyedFilter) {
      setOpenedCannotModal(true);
    }
    setOpen(prev => !prev);
  };

  const onClearClick = selectedCategory => {
    setSelectedState(null);
    sendEvent('categoriesTracker', {
      dealershipId: user.id,
      dealershipEmail: user.email,
      dealershipName: user.dealershipName,
      selectedCategory: selectedCategory,
      eventCategory: 'Auctions',
      eventAction: 'ClearCategory',
      eventLabel: 'Categorias',
    });
    dispatch(clearCategoryFilter());
    clearFilter();
  };

  const onClickCheck = ({ target: { name, checked } }) => {
    dispatch(setCategoryFilter(name));
    toogleFilter(name);

    if (!checked) {
      setSelectedState({ action: 'Unclick', filterName: name });
      handleRemoveCategory({ action: 'Unclick', filterName: name });
    } else {
      setSelectedState({ action: 'Click', filterName: name });
      handleAddCategory({ action: 'Click', filterName: name });
    }
  };

  useEffect(() => {
    if (!!selectedState) {
      sendEvent('categoriesTracker', {
        dealershipId: user.id,
        dealershipEmail: user.email,
        dealershipName: user.dealershipName,
        selectedCategory: selectedState.filterName,
        carResults: data.length,
        eventCategory: 'Auctions',
        eventAction: selectedState.action,
        eventLabel: 'Categorias',
      });
    }
  }, [selectedState]);

  useEffect(() => {
    const filter = JSON.parse(sessionStorage.getItem('categories'));

    if (filter?.length > 0) {
      filter?.forEach(el => {
        dispatch(setCategoryFilter(el.filterName));
        toogleFilter(el.filterName);
      });
    }
  }, []);

  useEffect(() => {
    if (applyedFilter) setOpen(false);
  }, [applyedFilter]);

  const howManyChecked = useMemo(() => Object.values(categoryFilter).filter(e => e).length, [
    categoryFilter,
  ]);

  return (
    <>
      <CannotSelectWithActiveCategoriesModal
        isOpen={openedCannotModal}
        onToggle={() => setOpenedCannotModal(!openedCannotModal)}
        type="filter"
      />

      <Box
        bgcolor={'#E7F3FF80'}
        px={4.5}
        py={2}
        paddingBottom={1}
        border={'1px solid'}
        borderRadius={1}
        borderColor={primaries.Soul}
        width={'100%'}
        position={'relative'}
        marginTop={4}
      >
        <IconButton
          sx={{ color: primaries.Soul, position: 'absolute', right: 0, top: '0.7rem' }}
          onClick={onClickToggle}
          disableRipple
        >
          {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        </IconButton>

        <Typography
          fontFamily={'Nunito'}
          fontWeight={800}
          fontSize={18}
          color={'#2F3741'}
          marginBottom={1}
          display={'flex'}
          alignItems={'center'}
        >
          <SearchRounded
            fontSize={'small'}
            sx={{ position: 'absolute', color: primaries.Soul, left: '0.8rem', top: '1.25rem' }}
          />
          Buscar por categorias
          {!open && !!howManyChecked && <Badge>{howManyChecked}</Badge>}
        </Typography>

        {open && (
          <Box
            marginTop={1}
            display={'grid'}
            gridTemplateColumns={'repeat(auto-fill, minmax(400px, 1fr))'}
          >
            {makeFilters.map(res => (
              <LabeledCheckBoxWithIcon
                key={res.label}
                name={res.name}
                label={res.label}
                imgSrc={res.imgSrc}
                checked={categoryFilter[res.name]}
                onClick={onClickCheck}
                disabled={
                  (lengths.hasOwnProperty(res.name) &&
                    !lengths[res.name] &&
                    !categoryFilter[res.name]) ||
                  (!lengths.hasOwnProperty(res.name) &&
                    !lengths[res.name] &&
                    !categoryFilter[res.name])
                }
                number={lengths[res.name]}
              />
            ))}
          </Box>
        )}
      </Box>

      {!!howManyChecked && (
        <Box
          onClick={() => onClearClick(filterList.join(', '))}
          marginTop={1}
          display={'flex'}
          alignItems={'center'}
          sx={{ cursor: 'pointer' }}
        >
          <img src={category_eraser} />
          <Typography
            marginLeft={0.5}
            fontFamily={'Nunito'}
            fontWeight={700}
            color={primaries.Soul}
            sx={{ textDecoration: 'underline' }}
          >
            Limpar categorias selecionadas
          </Typography>
        </Box>
      )}
      <Box marginBottom={3} />
    </>
  );
}
