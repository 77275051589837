import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Flex, RoundPhoto, Text } from '../../../../components';

export const Appraiser = ({ appraiser }) => {
  return (
    <Box width={{ sm: 1, md: 4 / 8 }} style={{ flexShrink: 0 }}>
      <Flex alignItems="center" mt={4} mb={18}>
        <RoundPhoto src={appraiser.mechanicPicture} alt="mechanic-photo" mr={2} />
        <div>
          <Text color="#505050" fontSize={3} fontWeight="paragraphs.bold" lineHeight="1">
            Avaliado por
          </Text>
          <Text color="#505050" fontSize={5} fontWeight="paragraphs.bold">
            {appraiser.mechanicName}
          </Text>
        </div>
      </Flex>
    </Box>
  );
};

Appraiser.propTypes = {
  appraiser: PropTypes.object.isRequired,
};
