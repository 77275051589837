import styled from 'styled-components';

export const Container = styled.div`
  grid-template-columns: auto auto 1fr;
  align-items: center;
  margin-bottom: 13px;
  grid-gap: 20px;
  display: grid;
  width: 100%;

  .result-number {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #757778;
  }

  & > :first-child {
    position: relative;
    width: 325px;

    @media (max-width: 882px) {
      width: auto;
    }
  }

  & > :last-child {
    margin-left: auto;
  }

  @media (max-width: 882px) {
    grid-template-columns: 1fr auto;
    grid-gap: 1px;

    .result-number {
      display: none;
    }
  }
`;
