import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { rem } from 'polished';
import { MdCheckCircle } from 'react-icons/md';

import Modal from '../enhanced/Modal';
import { Heading } from '../enhanced/Heading';
import { Box, Text } from '../abstract';
import { toggleModal } from '../../store/actions/ui.actions';
import { AUCTION_SUCCESS_KEY } from './';
import { getSelectedAuction } from '../../store/selectors';

const CheckIcon = styled(MdCheckCircle)`
  color: #22aa52;
  font-size: 5rem;
  margin: 0.5rem auto;
  display: block;
`;
const Component = ({ selectedAuction, show, toggle }) => {
  const { summary } = selectedAuction;

  return !summary ? null : (
    <Modal
      toggle={() => {
        toggle({ key: AUCTION_SUCCESS_KEY, show: false });
      }}
      show={show}
    >
      <Box style={{ maxWidth: rem(400), padding: `${rem(30)} ${rem(25)} ${rem(40)} ${rem(25)}` }}>
        <Heading color="neutrals.EclipseE800" fontSize={3} style={{ textTransform: 'capitalize' }}>
          {summary.description.toLowerCase()}
        </Heading>
        <Text color="neutrals.EclipseE500" fontSize={1}>
          {[summary.productionYear, summary.year].filter(n => Boolean(n)).join(' / ')} |{' '}
          {summary.km ? `${summary.km} km` : 'N/A km'}
        </Text>
        <CheckIcon />
        <Text ccolor="neutrals.EclipseE800" fontSize={3} fontFamily="headings" textAlign="center">
          Oferta enviada com sucesso!
        </Text>
        <Text color="neutrals.EclipseE500" fontSize={1} textAlign="center">
          Você pode consultar a negociação na página de Leilões, aba “Participando"
        </Text>
      </Box>
    </Modal>
  );
};

Component.propTypes = {
  toggle: PropTypes.func.isRequired,
  selectedAuction: PropTypes.object,
  show: PropTypes.bool,
};

Component.defaultProps = {
  selectedAuction: {},
};

const mapDispatchToProps = dispatch => ({
  toggle: bindActionCreators(toggleModal, dispatch),
});

const mapStateToProps = state => {
  const { ui } = state;

  return {
    show: ui.modals.auctionSuccess.show,
    selectedAuction: getSelectedAuction(state) || {},
  };
};

const SuccessModal = connect(mapStateToProps, mapDispatchToProps)(Component);
export { SuccessModal, Component as default };
