import styled from 'styled-components';

export const Container = styled.div`
  grid-template-rows: 50px 40px;
  grid-gap: 16px;
  display: grid;
  width: 100%;
`;

export const InputWrapper = styled.div`
  justify-content: space-between;
  border: 1px solid #d2d4d7;
  box-sizing: border-box;
  align-items: center;
  border-radius: 4px;
  position: relative;
  padding: 0 10px;
  display: flex;
  height: 50px;
  width: 100%;
`;

export const InputLabel = styled.label`
  background-color: #fff;
  font-weight: normal;
  font-style: normal;
  position: absolute;
  text-align: center;
  line-height: 14px;
  font-size: 12px;
  color: #2f3741;
  height: 15px;
  width: 80px;
  top: -8px;
`;

export const InputContainer = styled.div`
  align-items: center;
  height: 19.82px;
  padding: 0 10px;
  display: flex;
  width: 100%;
`;

InputContainer.MoneySign = styled.span`
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  font-size: 16px;
  color: #2f3741;
`;

InputContainer.Input = styled.input`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2f3741;
  border: none;
  width: 100%;
`;

export const DecrementBtn = styled.button`
  border: 1px solid #c4c4c4;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: 22.13px;
  color: #757778;
  outline: none;
  display: flex;
  height: 20px;
`;

export const IncrementBtn = styled.button`
  box-shadow: 0px 10px 20px rgba(26, 132, 63, 0.3);
  justify-content: center;
  background: #22aa52;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  display: flex;
  height: 30px;
  border: none;
  width: 40px;
  color: #fff;
`;

export const ButtonsWrapper = styled.div`
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  display: grid;
  height: 40px;
  width: 100%;
`;

ButtonsWrapper.Button = styled.button`
  background: #2274a5;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  opacity: 0.8;
  border: none;
  height: 40px;
  width: 100%;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
`;

export const SubmitBtn = styled.button`
  justify-content: center;
  background: #438a4c;
  align-items: center;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  height: 40px;
  border: none;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  font-size: 15px;
  color: red;
  margin: 0;
`;
