import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Text } from '../../abstract';
import { getKmLabel } from '../../../utils/auction';

const Wrapper = styled(Box)`
  background: #f8f8f8;
  padding: 6px 20px;
  font-size: 0.875rem;
  color: #323232;
  line-height: 1.0625rem;
  text-align: left;
`;

const VehicleDetails = ({ auction, className, ...props }) => {
  const { summary } = auction;
  return (
    <Wrapper className={className} {...props}>
      <Text color="primaries.Taxi" fontWeight="paragraphs.bold" fontSize={1}>
        {`${summary.make} ${summary.model} ${summary.yearLabel}`}
      </Text>
      <div>{summary.version}</div>
      <div>{`${getKmLabel(auction)} | Número da placa: ${summary.plateNumber}`}</div>
    </Wrapper>
  );
};
VehicleDetails.propTypes = {
  auction: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export { VehicleDetails };
