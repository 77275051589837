import { keyBy, mapValues } from 'lodash';
import AppConstants from '../../constants/appConstants';
import { invertKeysToValues } from '../../utils/array';
import { authGet, authPost, authPut, authenticatedDelete } from '../../utils/fecth-wrappers';
import {
  createUserPreferencesFailure,
  createUserPreferencesRequest,
  createUserPreferencesSuccess,
  deleteUserPreferencesFailure,
  deleteUserPreferencesRequest,
  deleteUserPreferencesSuccess,
  listUserPreferencesFailure,
  listUserPreferencesRequest,
  listUserPreferencesSuccess,
  updateUserPreferencesFailure,
  updateUserPreferencesRequest,
  updateUserPreferencesSuccess,
} from './actions';
import { enableTooltip } from '../tooltip/actions';
import { preferencesLabel } from '../../constants/preferencesLabel';
import { sendEvent } from '../../helpers/googleAnalytics';

const preferenceObj = invertKeysToValues(preferencesLabel);

const formatRequest = obj =>
  mapValues(
    keyBy(obj, o => preferenceObj[o.name]),
    'isActive'
  );

export const createUserPreferences = (
  userId,
  channelOfContact,
  userPreferences,
  relatedNinja
) => async dispatch => {
  dispatch(createUserPreferencesRequest());
  try {
    const { data } = await authPost(`${AppConstants.API.user_preferences}`, {
      userId,
      channelOfContact,
      userPreferences,
      ninja: {
        email: relatedNinja,
      },
    });

    const cars = await authGet(
      `${AppConstants.API.user_preferences}/list-vehicle-by-user/${userId}`
    );

    console.log('created preferences cars: ', cars);

    if (!!relatedNinja) await dispatch(enableTooltip({ messageId: 12 }));
    else if (!!cars?.data?.length) await dispatch(enableTooltip({ messageId: 4 }));
    else await dispatch(enableTooltip({ messageId: 8 }));

    const { id, channelOfContact: receivers, userPreferences: preferences, ninja } = data || {};
    dispatch(createUserPreferencesSuccess({ id, channelOfContact: receivers, preferences, ninja }));
    dispatch(listUserPreferences(userId));
  } catch (error) {
    dispatch(createUserPreferencesFailure(error));
  }
};

export const updateUserPreferences = (
  preferenceId,
  userId,
  channelOfContact,
  userPreferences,
  relatedNinja
) => async (dispatch, getState) => {
  dispatch(updateUserPreferencesRequest());
  try {
    const prefs = await authPut(
      `${AppConstants.API.user_preferences}/update-by-id/${preferenceId}`,
      {
        userId,
        channelOfContact,
        userPreferences,
        ninja: {
          email: relatedNinja,
        },
      }
    );

    const cars = await authGet(
      `${AppConstants.API.user_preferences}/list-vehicle-by-user/${userId}`
    );

    console.log('altered preferences cars: ', cars);

    if (!!relatedNinja) await dispatch(enableTooltip({ messageId: 12 }));
    else if (!!cars?.data?.length) await dispatch(enableTooltip({ messageId: 5 }));
    else await dispatch(enableTooltip({ messageId: 6 }));

    const selected = Object.keys(userPreferences)
      .filter(key => userPreferences[key])
      .join(', ');
    const user = getState().authentication.user;

    sendEvent('preferencesTracker', {
      dealershipId: user?.id,
      dealershipEmail: user?.email,
      dealershipName: user.dealershipName,
      selectedCategory: selected,
      carResults: cars?.data?.length,
      eventCategory: 'Auctions',
      eventAction: 'SavePreferences',
      eventLabel: 'Pedir Carros',
    });

    const { id, channelOfContact: receivers, userPreferences: preferences, ninja } = prefs?.data;
    const formattedChannelOfContact = formatRequest(receivers);
    const formattedPreferences = formatRequest(preferences);
    dispatch(
      updateUserPreferencesSuccess({
        id,
        channelOfContact: formattedChannelOfContact,
        preferences: formattedPreferences,
        ninja,
      })
    );
  } catch (error) {
    dispatch(updateUserPreferencesFailure(error));
    dispatch(enableTooltip({ messageId: 7 }));
    console.log('error: ', error);
  }
};

export const deleteUserPreferences = preferenceId => async dispatch => {
  dispatch(deleteUserPreferencesRequest());
  try {
    await authenticatedDelete(`${AppConstants.API.user_preferences}/${preferenceId}`);
    dispatch(deleteUserPreferencesSuccess());
  } catch (error) {
    dispatch(deleteUserPreferencesFailure(error));
  }
};

export const listUserPreferences = userId => async dispatch => {
  dispatch(listUserPreferencesRequest());
  try {
    const { data } = await authGet(
      `${AppConstants.API.user_preferences}/find-all/by-user-id/${userId}`
    );
    const { id, channelOfContact: receivers, userPreferences: preferences, ninja } = data[0] || {};
    const channelOfContact = formatRequest(receivers);
    dispatch(listUserPreferencesSuccess({ id, channelOfContact, preferences, ninja }));
  } catch (error) {
    dispatch(listUserPreferencesFailure(error));
  }
};
