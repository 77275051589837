import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;

  ${({ colored, p }) =>
    colored
      ? css`
          gap: 0 14px;
          align-items: center;
          padding: ${p ?? 8}px;
          background-color: ${({ bg }) => bg ?? '#fffef3'};
          border: 1px solid ${({ borderColor }) => borderColor ?? '#864600'};
          border-radius: 4px;
        `
      : css`
          gap: 0 8px;
          align-items: flex-start;
        `}
`;

export const Title = styled.span`
  margin-bottom: ${({ mb }) => `${mb}px` ?? 0};
  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: ${({ color }) => color ?? '#a34f00'};
`;

export const Description = styled.span`
  font: 400 normal 0.875rem/1.5em 'Roboto', sans-serif;
  color: ${({ color }) => color ?? '#2f3741'};
`;

export const Icon = styled.img`
  width: ${({ w }) => w ?? 20}px;
  height: ${({ h }) => h ?? 20}px;
`;

export const Underline = styled.u`
  cursor: pointer;
`;
