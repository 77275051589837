import React from 'react';
import { MdGroup, MdFavorite } from 'react-icons/md';

import { Content, Typography } from './styles';
import { FaEye } from 'react-icons/fa';

const Icon = ({ viewed = false, size = 20, color = '#fff', favorite = false }) =>
  !viewed && !favorite ? (
    <MdGroup size={size} color={'#9BEFB9'} />
  ) : !favorite ? (
    <FaEye size={size} color={color} />
  ) : (
    <MdFavorite size={size} color={color} />
  );

const Text = ({ color, children }) => <Typography color={color}>{children}</Typography>;

export const Container = ({ bg, children }) => <Content bg={bg}>{children}</Content>;

export const Tag = {
  Container,
  Icon,
  Text,
};
