export class IntervalJob {
  constructor(onTick, tickDuration) {
    this.tickDuration = tickDuration;
    this.onTick = onTick;
    this.intervalId = null;
  }

  start() {
    this.intervalId = setInterval(this.onTick, this.tickDuration);
  }

  end() {
    if (this.intervalId) clearInterval(this.intervalId);
  }
}
