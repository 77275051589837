import { LocalFilterStore } from './stores/local-filter-storage';

export class UserFilterService {
  constructor(store) {
    this.store = store;
  }

  async getUserFilters() {
    return this.store.getUserFilters();
  }

  async addFilter(filter) {
    return this.store.addFilter(filter);
  }

  async removeFilter(filterId) {
    return this.store.removeFilter(filterId);
  }
}

const localFilterStore = new LocalFilterStore();
export const userFilterService = new UserFilterService(localFilterStore);
