import * as auctionUtils from '../../utils/auction';
import { getDealershipByName } from '../../utils/user';
import ActionTypes from '../../constants/actionTypes';
import { toPrice } from '../../utils/number';
import * as fetchUtils from '../../utils/fecth-wrappers';
import { getCurrentElapsed } from '../../utils/dateUtils';
import AppConstants from '../../constants/appConstants';
import * as eventTracking from './event-tracking.actions';
import {
  endNegotiation,
  startNegotiation,
  showCarouselPlaceHolder,
  hideCarouselPlaceHolder,
  toggleModal,
} from './ui.actions';
import {
  AUCTION_ACCEPTED_KEY,
  AUCTION_REJECTED_KEY,
  AUCTION_SUCCESS_KEY,
  AUCTION_OFFER_KEY,
  AUCTION_BUY_KEY,
  AUCTION_BUY_KEY_CONFIRMATION,
  AUCTION_BUY_FOR,
  CONFIRM_BUY_FOR,
} from '../../components/Modals';
import { trackAuctionEvent, gtmEvents, gtmVariables } from '../../utils/gtmTracker';
import { getSelectedAuction } from '../selectors';
import { getAuctions } from '../../services/auctions';
import commercialService from '../../services/commercial';
import { timingService } from '../../services/TimingService';
import { deauthenticate } from './authentication.actions';
import { enableTooltip } from '../tooltip/actions';

function carListingFetched(auctions) {
  return {
    type: ActionTypes.Auction.FetchedALl,
    auctions,
  };
}

export function fetchedNewOffers(newOffers) {
  return {
    type: ActionTypes.Auction.FetchedNewOffers,
    newOffers,
  };
}

export function fetchedParticipatingAuctions(participatingAuctions) {
  return {
    type: ActionTypes.Auction.FetchedParticipatingAuctions,
    participatingAuctions,
  };
}

export function negotiationStart(data, auctions) {
  return (dispatch, getState) => {
    const auction = auctionUtils.getAuctionById(auctions, data.id);
    const { user } = getState().authentication;
    const { relatedDealers } = getState().user;

    if (!auction) {
      return;
    }

    if (auctionUtils.isUserParticipating(auction, user, relatedDealers)) {
      dispatch(startNegotiation(auction));
    }
  };
}

export function temperatureNotification(data) {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.authentication.user;
    if (data[0].userId === user.id) {
      dispatch(SetNotificationsCount(state.ui.notificationsCount + 1));
    }
  };
}

export function negotiationEnd(data, auctions) {
  return (dispatch, getState) => {
    let auction = auctionUtils.getAuctionById(auctions, data.id);

    if (!auction && data.id === getState().auctions.detailedAuction._id) {
      auction = getState().auctions.detailedAuction;
    }

    const { user } = getState().authentication;
    const { relatedDealers } = getState().user;

    if (auction && user) {
      const isWinning = auctionUtils.isUserWinning(auction, user, relatedDealers);
      if (!isWinning) {
        return;
      }
      dispatch(
        toggleModal({
          key: data.accept ? AUCTION_ACCEPTED_KEY : AUCTION_REJECTED_KEY,
          show: true,
          extras: { auction: auction },
        })
      );
      dispatch(endNegotiation(data.accept, auction));
    }
  };
}

export function start(data, notifier) {
  return (dispatch, getState) => {
    const user = getState().authentication.user;
    const auction = auctionUtils.getAuctionFromStart(data);
    const requestEndTime = getCurrentElapsed();

    if (data.test && !user.permissions.includes('can_see_test_inspections')) return false;

    auction.auction.initialDuration = auction.auction.remainingTime;
    auction.elapsedSinceLastRequest = requestEndTime;
    auction.auction.autoBids = [];
    auction.summary.yearLabel = auctionUtils.getYearLabel(auction);
    auction.summary.description = auctionUtils.getDescription(auction);
    auction.summary.kmLabel = auctionUtils.getKmLabel(auction);
    auction.auction.status = 'AUCT_ONGOING';
    auction.summary.test = data.test;
    auction.summary.state = data.state;
    auction.auction._id = auction._id;
    auction.summary.vehiclePricing = data.summary.vehiclePricing;
    auction.summary.marketPrice = data.prices.marketPrice;

    if (user.isLoggedIn()) {
      if (auction.summary.buynowPrice.enabled) {
        notifier._trigger(
          'Compre Ja !!',
          `${data.make} ${data.model} ${data.year} ${toPrice(auction.summary.buynowPrice.amount)}`,
          'NewBuyNowdAuction',
          data.photos.main ? data.photos.main : AppConstants.DefaultICImage
        );
      } else if (user.isNinja()) {
        notifier._trigger(
          'Novo leilão está começando !!',
          `Um leilão para um ${data.make} ${data.model} ${data.year} está prestes a começar.`,
          'NewAuction',
          data.photos.main ? data.photos.main : AppConstants.DefaultICImage
        );
      } else {
        // TODO: add elseIf carIsInWishlist
      }
    }

    dispatch(commercialService.util.invalidateTags([{ type: 'Auction', id: 'LIST' }]));

    dispatch({
      type: ActionTypes.Auction.Start,
      auction,
      newOffersFiltersApplyed: getState().ui.filters.newOffersFiltersApplyed,
    });
  };
}

export function outBid(data, notifier, auctions) {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.authentication.user;
    const selectedAuction = getSelectedAuction(state);
    const relatedDealers = state.user.relatedDealers;
    const { bid, id } = data;
    const detailedAuction = state.auctions.detailedAuction;
    const auction = auctionUtils.getAuctionById(auctions, id) || detailedAuction;
    const showSuccessMessage = data?.bid?.bidder?.email === user.email;

    if (auction === null || !auction._id) {
      return;
    }

    const iMadeTheBid =
      bid.dealership === user.dealershipName ||
      relatedDealers.some(dealer => bid.dealership === dealer.dealershipName) ||
      (bid.bidder && bid.bidder.email === user.email);

    const participating =
      iMadeTheBid || auctionUtils.isUserParticipating(auction, user, relatedDealers);

    const auctionWasOutBidded = bid.amount > auction.auction.winningBid.amount;
    const wasOutbiddedByOtherDealer = participating && auctionWasOutBidded && !iMadeTheBid;

    const winningBid = auctionWasOutBidded ? bid : auction.auction.winningBid;
    const winning = auctionUtils.isUserWinningBid(winningBid, user, relatedDealers);

    if (wasOutbiddedByOtherDealer) {
      // If the bid modal is opened the no notify newer bid.
      if (selectedAuction && selectedAuction._id === id) {
        dispatch({ type: ActionTypes.Auction.OutBidOvercome });
      }

      notifier._trigger(
        'Seu lance foi superado !!',
        `Você foi superado por um lance de \n${toPrice(
          bid.amount
        )}. Clique aqui para ir e fazer uma oferta.`,
        'Outbid',
        auction.summary.mainPhoto || AppConstants.DefaultICImage
      );
    }

    const participatingAuctions = state.auctions.participatingAuctions;
    const exists = participatingAuctions.find(auction => auction._id === id);
    if (participating && state.ui.filters.participating.length > 0 && !exists) {
      auctionUtils
        .updateParticipatingAuctionsOutBid(user, relatedDealers)
        .then(data => {
          dispatch(fetchedParticipatingAuctions(data));
          dispatch(handleFilters({ participating: Array() }));
        })
        .catch(() => fetchedParticipatingAuctions([]));
    }

    // dispatch(commercialService.util.invalidateTags([{ type: 'Auction', id }]));

    dispatch({
      type: ActionTypes.Auction.OutBid,
      participating,
      bid,
      id,
      outBid: wasOutbiddedByOtherDealer,
      winning,
      winningBid,
      // showSuccessMessage,
    });

    // TODO: Unify
    if (detailedAuction._id === id) {
      dispatch(outbidDetails(id, bid, detailedAuction, iMadeTheBid, participating));
    }
  };
}

export function removeSuccessBidMessage() {
  return {
    type: ActionTypes.Auction.RemoveSuccessBidMessage,
  };
}

export function setDuration(data, extended = false) {
  return dispatch => {
    const { id, blinkTimeout = 5000, remainingTime, endTime } = data;

    if (extended) {
      // dispatch(commercialService.util.invalidateTags([{ type: 'Auction', id }]));
      dispatch({
        type: ActionTypes.Auction.SetBlink,
        id,
      });

      setTimeout(() => {
        dispatch({
          type: ActionTypes.Auction.RemoveBlink,
          id,
        });
      }, blinkTimeout);
    }

    // remainingTime = Number(data.remainingTime) + getCurrentElapsed();

    timingService.setAuction(id, Number(remainingTime), true);

    dispatch({
      type: ActionTypes.Auction.SetDuration,
      remainingTime: Number(remainingTime),
      elapsedSinceLastRequest: getCurrentElapsed(),
      endTime: Number(endTime),
      id,
    });
  };
}

export function hideSelectedAuctionBiddingModals(id) {
  return (dispatch, getState) => {
    const selectedAuction = getSelectedAuction(getState());

    if (selectedAuction && selectedAuction._id === id) {
      dispatch(toggleModal({ key: AUCTION_OFFER_KEY, show: false }));
      dispatch(toggleModal({ key: AUCTION_BUY_KEY, show: false }));
    }
  };
}

export function won(data, notifier, auctions) {
  return (dispatch, getState) => {
    const { id, bid } = data;
    const state = getState();
    const auction = auctionUtils.getAuctionById(auctions, id);

    if (auction === null) {
      console.error('Received event from missing auction');
      return;
    }

    const user = state.authentication.user;
    const relatedDealers = state.user.relatedDealers;
    const _won = auctionUtils.hasUserWon(bid, user, relatedDealers);
    const lost = auctionUtils.isUserParticipating(auction, user, relatedDealers) && !_won;

    if (_won) {
      notifier._trigger(
        'Você ganhou !!',
        `Você ganhou o leilão de um ${data.make} ${data.model} ${data.year.actual} !!`,
        'Outbid',
        data.photos.main ? data.photos.main : AppConstants.DefaultICImage
      );

      dispatch(SetNotificationsCount(state.ui.notificationsCount + 1));
    }

    if (lost) {
      dispatch(SetNotificationsCount(state.ui.notificationsCount + 1));
    }

    dispatch(hideSelectedAuctionBiddingModals(id));

    // dispatch(commercialService.util.invalidateTags([{ type: 'Auction', id }]));

    dispatch({
      type: ActionTypes.Auction.Won,
      id,
      won: _won,
      lost,
    });
  };
}

export function end(data) {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.authentication.user;
    const relatedDealers = state.user.relatedDealers;
    const auction = auctionUtils.getAuctionById(state.auctions.allAuctions, data.id);

    const isUserParticipating = auctionUtils.isUserParticipating(auction, user, relatedDealers);

    dispatch(commercialService.util.invalidateTags([{ type: 'Auction', id: data.id }]));
    dispatch(hideSelectedAuctionBiddingModals(data.id));

    dispatch({
      type: ActionTypes.Auction.End,
      id: data.id,
      isUserParticipating,
      isBuyNow: auction?.auction?.isBuyNow || false,
    });
  };
}

export function cancel(data) {
  return dispatch => {
    // dispatch(commercialService.util.invalidateTags([{ type: 'Auction', id: data.id }]));

    dispatch({
      type: ActionTypes.Auction.Cancel,
      id: data.id,
    });
  };
}

export function fetchAllCars() {
  return (dispatch, getState) => {
    const requestStartTime = getCurrentElapsed();
    const user = getState().authentication.user;
    dispatch({
      type: ActionTypes.Auction.AllRequestStarted,
    });
    return fetchUtils
      .fetchCars(user)
      .then(({ data: response }) => {
        const relatedDealers = getState().user.relatedDealers;
        const evaluatedSupperOffers = auctionUtils.defineSupperOfferAuctions(response.result);
        const auctions = evaluatedSupperOffers.map(auction => {
          const elapsedTime = getCurrentElapsed();
          const requestEndTime = elapsedTime - requestStartTime;
          auction.elapsedSinceLastRequest = elapsedTime;
          const { remainingTime, winningBid } = auction.auction;
          auction.auction.remainingTime = remainingTime - requestEndTime;
          const winning = auctionUtils.isUserWinning(auction, user, relatedDealers);
          const participating = auctionUtils.isUserParticipating(auction, user, relatedDealers);
          auction.auction.onGoing = true;
          winningBid.amount =
            winningBid.amount > 0 ? winningBid.amount : auction.summary.startPrice;
          auction.auction.won = false;
          auction.auction.lost = false;
          auction.auction.outBid = participating && !winning;
          auction.auction.showMakeOffer = false;
          auction.auction.winning = winning;
          auction.auction.participating = participating;
          auction.auction.status = 'AUCT_ONGOING';
          auction.auction.outBid = false;
          auction.summary.documentResearch = auction.summary.documentResearch || {};

          return auctionUtils.addCommonPropsToAuction(auction);
        });

        dispatch(carListingFetched(auctions));
      })
      .catch(err => {
        console.error(err);
        dispatch(carListingFetched([]));
      });
  };
}

export function fetchAuctions() {
  return async (dispatch, getState) => {
    const requestStartTime = getCurrentElapsed();

    const user = getState().authentication.user;
    dispatch({
      type: ActionTypes.Auction.AllRequestStarted,
    });
    try {
      let auctions = await getAuctions();
      const relatedDealers = getState().user.relatedDealers;
      auctions = auctions.map(auction =>
        auctionUtils.formatAuctionObjectKeys(auction, requestStartTime, user, relatedDealers)
      );

      auctions.forEach(auction =>
        timingService.setAuction(auction._id, auction.auction.remainingTime)
      );

      const participating = auctionUtils.getParticipatingAuctions(auctions);
      const newOffers = auctionUtils.getNewOffersAuctions(auctions);
      dispatch(carListingFetched(auctions));
      dispatch(fetchedNewOffers(newOffers));
      dispatch(fetchedParticipatingAuctions(participating));
    } catch (err) {
      console.error(err);
      dispatch(fetchedNewOffers([]));
      dispatch(fetchedParticipatingAuctions([]));
    } finally {
      dispatch({
        type: ActionTypes.Auction.AllRequestEnded,
      });
    }
  };
}

export function fetchAllAuctions() {
  return async (dispatch, getState) => {
    const requestStartTime = getCurrentElapsed();
    const user = getState().authentication.user;
    dispatch({
      type: ActionTypes.Auction.AllRequestStarted,
    });
    try {
      let auctions = await getAuctions();
      const relatedDealers = getState().user.relatedDealers;
      auctions = auctions.map(auction =>
        auctionUtils.formatAuctionObjectKeys(auction, requestStartTime, user, relatedDealers)
      );
      dispatch(carListingFetched(auctions));
    } catch (err) {
      console.error(err);
      dispatch(carListingFetched([]));
    } finally {
      dispatch({
        type: ActionTypes.Auction.AllRequestEnded,
      });
    }
  };
}

export function fetchAuctionsHistory() {
  return async (dispatch, getState) => {
    const user = getState().authentication.user;
    const relatedDealers = getState().user.relatedDealers;

    dispatch({ type: ActionTypes.Auction.FetchAuctionsHistory });

    try {
      const response = await fetchUtils.fetchNegotiatedAuctions();
      const { body: result } = response;

      const auctions = result.map(auction => {
        const hasWon = auctionUtils.isUserWinning(auction, user, relatedDealers);

        const normalizedAuction = {
          ...auction,
          auction: {
            ...auction.auction,
            status: auction.summary.status,
            participating: true,
            winning: hasWon,
            won: hasWon,
            lost: !hasWon,
            onGoing: false,
            outBid: false,
            showMakeOffer: false,
            bids: [],
          },
          summary: {
            ...auction.summary,
            buynowPrice: {
              enabled: false,
            },
          },
        };

        return auctionUtils.addCommonPropsToAuction(normalizedAuction);
      });

      dispatch(fetchAuctionsNegotiatedSuccess(auctions));
    } catch (err) {
      dispatch(fetchAuctionsNegotiatedSuccess([]));
    }

    // TODO: Fetching of sold vehicles.

    dispatch({
      type: ActionTypes.Auction.FetchAuctionsHistorySuccess,
    });
  };
}

export function setFavoritesAuctions(favorites) {
  return {
    type: ActionTypes.Auction.SetFavorites,
    favorites,
  };
}

function fetchAuctionsNegotiatedSuccess(auctions) {
  return {
    type: ActionTypes.Auction.FetchAuctionsNegotiatedSuccess,
    auctions,
  };
}

export function setOutBid(_outBid, id) {
  return {
    type: ActionTypes.Auction.SetOutBid,
    outBid: _outBid,
    id,
  };
}

export function toggleConfirmationOfferModal(toggle, auction = {}) {
  return dispatch => {
    dispatch(setSelectedAuction(auction));
    dispatch(onBidError(null)); // TODO: enhancement: errors should be local, we shouldn't need to reset them.
    dispatch({
      type: ActionTypes.Modal.MakeOffer.Confirm,
      toggle,
    });
  };
}
export function notifyAuctionIsBidding(id, isBidding) {
  return {
    type: ActionTypes.Auction.IsBidding,
    id,
    isBidding,
  };
}

export function notifyAuctionIsBuyNow(id, isBuyNow) {
  return {
    type: ActionTypes.Auction.IsBuyNow,
    id,
    isBuyNow,
  };
}

export function makeBid(
  id,
  amount,
  dealershipName = '',
  dealerId,
  car = null,
  buyNow = false,
  taxProfile = '',
  isSupperOffer = false,
  isBuyForOffer = false
) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedAuction = getSelectedAuction(state);
    const { detailedAuction } = state.auctions;
    const { user } = state.authentication;
    const auction = selectedAuction._id ? selectedAuction : detailedAuction;
    const wasAlreadyParticipating = auction?.auction?.participating || false;
    const _userId = dealerId ?? user.id;

    dispatch(MakeOfferLoading());
    dispatch(notifyAuctionIsBidding(id, true));
    dispatch(eventTracking.trackBid(id, user, amount, buyNow, dealershipName, auction));
    dispatch(notifyAuctionIsBuyNow(id, buyNow));

    fetchUtils
      .checkToken()
      .then(() => {
        fetchUtils
          .makeBid(id, amount, dealershipName, _userId, taxProfile, isSupperOffer, isBuyForOffer)
          .then(() => {
            const { elapsedTime } = state.timer;
            const { buyerId: userId, relatedDealers } = state.user;
            let answersCount = Math.round(
              (auction.summary.answersCount * auction.summary.approvedAnswersCount) / 100
            );

            if (answersCount > 100) {
              answersCount = 100;
            }

            const valueBiggerThanBuyFor =
              auction?.summary?.buyForPrice?.enabled &&
              amount >= auction?.summary?.buyForPrice?.amount;

            if (isBuyForOffer || valueBiggerThanBuyFor) {
              dispatch(
                enableTooltip({ inspection: id, messageId: 11, balance: amount, selectedAuction })
              );
            } else {
              dispatch(enableTooltip({ inspection: id, messageId: 10, balance: amount }));
            }

            if (userId) {
              trackAuctionEvent(gtmEvents.PLACE_BID, auction, elapsedTime, answersCount, {
                [gtmVariables.BUYER_ID]: userId,
              });
            } else {
              const dealership = getDealershipByName(dealershipName, relatedDealers);

              if (dealership && dealership._id) {
                trackAuctionEvent(gtmEvents.PLACE_BID, auction, elapsedTime, answersCount, {
                  [gtmVariables.BUYER_ID]: dealership._id,
                });
              } else {
                trackAuctionEvent(gtmEvents.PLACE_BID, auction, elapsedTime, answersCount, {
                  [gtmVariables.BUYER_ID]: null,
                });
              }
            }

            dispatch(
              eventTracking.trackBidSucceed(id, user, amount, buyNow, dealershipName, auction)
            );

            dispatch(notifyAuctionIsBidding(id, false));
            dispatch(MakeOfferLoading(false));

            dispatch(
              toggleModal({
                key: AUCTION_BUY_KEY_CONFIRMATION,
                show: false,
              })
            );

            dispatch({
              type: ActionTypes.Auction.BidSucesss,
              currentAuction: auction,
            });

            if (!wasAlreadyParticipating) {
              dispatch(IsParticipatingInNewAuctions());
            }
            dispatch({ type: ActionTypes.Auction.ClearBidSucess });

            if (buyNow) {
              dispatch(IsParticipatingInNewAuctions(false));
              dispatch(toggleModal({ key: AUCTION_SUCCESS_KEY, show: true }));
              setTimeout(() => {
                dispatch(toggleModal({ key: AUCTION_SUCCESS_KEY, show: false }));
              }, 4000);
            }

            dispatch({
              type: ActionTypes.Auction.SetConfirmationCar,
              confirmationCar: auctionUtils.getAuctionById(state.auctions.allAuctions, id) || car,
            });

            dispatch(toggleModal({ key: AUCTION_BUY_FOR, show: false }));
            dispatch(toggleModal({ key: CONFIRM_BUY_FOR, show: false }));
          })
          .catch(error => {
            let errorMessage = `Houve um problema ao processar o lance (${error.responseStatus}: ${error.message})`;

            // After 7 secs, clear the error
            const dismissError = (secs = 7) =>
              setTimeout(() => dispatch(onBidError(null)), secs * 1000);

            if (error.message.includes('bid amount is lower than highest bid')) {
              errorMessage = 'O valor do lance é menor do que o lance mais alto';
              dismissError();
            }

            if (error.message.includes('refresh token error')) {
              errorMessage =
                'Devido à inatividade, sua sessão expirou. Por favor, tente o lance novamente.';
              dismissError(5);
            }

            if (
              error.message.includes('dealer with an unbiddable status') ||
              error.message.includes('user not allowed to place bid') ||
              error.message.includes(
                'Erro ao acessar a sua conta. Favor entrar em contato com o seu consultor.'
              )
            ) {
              errorMessage =
                'Erro ao acessar a sua conta. Favor entrar em contato com o seu consultor.';
            }

            if (error.message.includes('cannot bid on expired auction')) {
              errorMessage = 'Esse leilão já terminou e não pode mais receber lances!';
            }

            dispatch(
              eventTracking.trackBidError(
                id,
                user,
                amount,
                buyNow,
                dealershipName,
                auction,
                error,
                errorMessage
              )
            );
            dispatch(onBidError(errorMessage));
            dispatch(notifyAuctionIsBidding(id, false));
            dispatch(MakeOfferLoading(false));
          });
      })
      .catch(() => {
        const errorMessage = 'Houve um erro ao realizar o lance';
        dispatch(onBidError(errorMessage));
        dispatch(notifyAuctionIsBidding(id, false));
        dispatch(MakeOfferLoading(false));
        dispatch(deauthenticate());
        dispatch(
          eventTracking.trackBidError(
            id,
            user,
            amount,
            buyNow,
            dealershipName,
            auction,
            error,
            errorMessage
          )
        );
      });
  };
}

export function onBidError(message) {
  return {
    type: ActionTypes.Auction.BidError,
    error: message || '',
  };
}

export function removeDetailedAuction(auction) {
  return dispatch =>
    dispatch({
      type: ActionTypes.Auction.RemoveSingle,
      auction,
    });
}

export function fetchAuction(id) {
  return (dispatch, getState) => {
    const requestStartTime = getCurrentElapsed();
    dispatch({
      type: ActionTypes.Auction.SingleRequestStarted,
    });
    dispatch(showCarouselPlaceHolder());
    return fetchUtils
      .fetchAuction(id)
      .then(({ data }) => {
        const user = getState().authentication.user;
        const elapsedTime = getCurrentElapsed();
        const requestEndTime = elapsedTime - requestStartTime;
        const auction = auctionUtils.getAuctionFromDetails(data);

        auction.elapsedSinceLastRequest = elapsedTime;
        auction.auction.remainingTime = auction.auction.remainingTime - requestEndTime;
        auction.auction.outBid = auction.auction.participating && !auction.auction.winning;
        auction.auction.showMakeOffer = false;
        auction.auction._id = auction._id;
        auction.summary.test = data.test;
        auction.summary.state = data.state;

        const normalizedAuction = auctionUtils.addCommonPropsToAuction(auction);

        dispatch({
          type: ActionTypes.Auction.FetchedSingle,
          auction: normalizedAuction,
        });

        dispatch(calculateAutoBid(normalizedAuction, user.dealershipName));
        // TODO: this can be local in details
        dispatch(hideCarouselPlaceHolder());
        return normalizedAuction;
      })
      .catch(err => {
        console.error('Error getting auction details', err);
        dispatch({
          type: ActionTypes.Auction.FetchedSingle,
          auction: {},
        });
        dispatch(hideCarouselPlaceHolder());
      });
  };
}

export function setAutoBid(id, autobidAmount, dealership, bidAmount) {
  return (dispatch, getState) => {
    if (!dealership) {
      dispatch(onBidError('Deve selecionar a loja para cobrir lances'));
      return;
    }

    if (bidAmount % AppConstants.MinimumBid !== 0) {
      dispatch(onBidError(`Total deve ser divisível por ${AppConstants.MinimumBid}`));
      return;
    }

    if (autobidAmount < bidAmount) {
      const message = `Lance deve ser maior que o lance atual (${toPrice(bidAmount)})`;
      dispatch(onBidError(message));
      return;
    }

    dispatch(MakeOfferLoading());
    fetchUtils
      .setAutoBid(id, autobidAmount, dealership)
      .then(response => {
        dispatch(toggleModal({ key: AUCTION_OFFER_KEY, show: false }));
        dispatch(MakeOfferLoading(false));
        dispatch(setAutoBidCreator(id, dealership, response));
        dispatch(
          showConfirmationModal(true, 'Seu lance automático foi configurado com sucesso!!!')
        );

        const auction = auctionUtils.getAuctionById(getState().auctions.allAuctions, id);
        dispatch(calculateAutoBid(auction, dealership));
      })
      .catch(error => {
        error.json().then(() => {
          dispatch(
            onBidError(
              'Houve um erro ao processar seu lance, tente novamente ou contate o suporte.'
            )
          );
          dispatch(MakeOfferLoading(false));
        });
      });
  };
}

export const setAutoBidCreator = (id, dealership, autoBid) => ({
  type: ActionTypes.Auction.SetAutoBid,
  id,
  autoBid,
  dealership,
});

export function deleteAutoBid(id, dealershipName) {
  return dispatch => {
    dispatch(MakeOfferLoading());
    fetchUtils
      .deleteAutoBid(id, dealershipName)
      .then(() => {
        dispatch({
          type: ActionTypes.Auction.DeleteAutoBid,
          id,
          dealershipName: dealershipName,
        });
        dispatch(MakeOfferLoading(false));
      })
      .catch(error => {
        error.json().then(() => {
          dispatch(
            onBidError(
              'Houve um erro ao cancelar seu lance automático, tente novamente ou contate o suporte.'
            )
          );
          dispatch(MakeOfferLoading(false));
        });
      });
  };
}

export const fetchRelatedDealers = () => {
  return dispatch => {
    getRelatedDealers().then(res => {
      dispatch({
        type: ActionTypes.RelatedDealers.Success,
        relatedDealers: res,
      });
    });
  };
};

export const setSelectedAuction = (auction, { remainingTime }) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.Auction.SetSelected,
      auction: { ...auction, remainingTime },
    });
    dispatch(calculateAutoBid(auction, getState().authentication.user.dealershipName));
  };
};

export const calculateAutoBid = (auction, dealership) => ({
  type: ActionTypes.Auction.CalculateAutoBid,
  auction,
  dealership,
});

export const MakeOfferLoading = (loading = true) => ({
  type: ActionTypes.UI.MakeOfferLoading,
  loading,
});

export const ShowSuccessBidMessage = (show = false) => ({
  type: ActionTypes.UI.ShowSuccessBidMessage,
  show,
});

export const handleFilters = value => {
  return {
    type: ActionTypes.UI.HandleFilters,
    value,
  };
};

export const IsParticipatingInNewAuctions = (isParticipating = true) => ({
  type: ActionTypes.UI.IsParticipatingInNewAuctions,
  isParticipating,
});

export const SetNotificationsCount = (count = 0) => ({
  type: ActionTypes.UI.NotificationsCount,
  notificationsCount: count,
});

export const SetRecommendedCarsNotificationsCount = (count = 0) => ({
  type: ActionTypes.UI.RecommendedCarsNotificationsCount,
  count,
});

export const setSelectedAuctionId = (auctionId = null) => ({
  type: ActionTypes.UI.SelectedAuctionId,
  auctionId,
});

export const showConfirmationModal = (toggle, message) => ({
  type: ActionTypes.Modal.AutoBidConfirmation.Toggle,
  toggle,
  message,
});

function outbidDetails(id, bid, detailedAuction, winning, participating) {
  return {
    type: ActionTypes.Auction.OutBid_Details,
    bid,
    id,
    winning,
    participating,
  };
}

export function toggleMakeBidConfirmationModal(toggle) {
  return {
    type: ActionTypes.Modal.MakeBidConfirmation.Toggle,
    toggle,
  };
}

export function removeAuction(id) {
  return {
    type: ActionTypes.Auction.ClearAuction,
    id,
  };
}

export function updateBuyNowPrice(data) {
  return {
    type: ActionTypes.Auction.BuyNow.Update,
    data: data,
  };
}

export const buyForEnabled = payload => ({
  type: ActionTypes.Auction.BuyFor.Enabled,
  payload,
});

export function buyNowWon(data, notifier, auctions) {
  return (dispatch, getState) => {
    const { user } = getState().authentication;
    const { id } = data;
    const { relatedDealers } = getState().user;

    const auction = auctionUtils.getAuctionById(auctions, id) || {
      auction: {},
    };

    auction.auction.status = 'ON_OFFER';

    const _won =
      data.bid.dealership === user.dealershipName || user.email === data.bid.bidder.email;

    const lost = !_won && auctionUtils.isUserParticipating(auction, user, relatedDealers);

    if (_won) {
      notifier._trigger(
        'Você ganhou !!',
        `Você ganhou o leilão de um ${data.make} ${data.model} ${data.year.actual} !!`,
        'Outbid',
        data.photos.main ? data.photos.main : AppConstants.DefaultICImage
      );
    }

    dispatch({
      type: ActionTypes.Auction.BuyNow.Won,
      id,
      won: _won,
      lost,
      bid: data.bid,
    });
  };
}

export const setRecommendedCarsIds = recommendedIds => ({
  type: ActionTypes.Auction.SetRecommendedCarsIds,
  payload: { recommendedCarsId: recommendedIds },
});

export const addRecommendedCarsId = id => ({
  type: ActionTypes.Auction.AddRecommendedCarsId,
  payload: { id },
});

export const preferenceStart = data => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.authentication.user.id;

    if (userId === data.userId) {
      dispatch(addRecommendedCarsId(data.inspectionId));
      const notificationsCount = state.ui.notificationsCount;
      const recommendedCarsNotificationsCount = state.ui.recommendedCarsNotificationsCount;
      dispatch(SetNotificationsCount(notificationsCount + 1));
      dispatch(SetRecommendedCarsNotificationsCount(recommendedCarsNotificationsCount + 1));
    }
  };
};
