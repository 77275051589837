export const AUCTION_ACCEPTED_KEY = 'auctionAccepted';
export const AUCTION_REJECTED_KEY = 'auctionRejected';
export const AUCTION_WON_KEY = 'auctionWon';
export const AUCTION_LOST_KEY = 'auctionLost';
export const AUCTION_BUY_KEY = 'auctionBuy';
export const AUCTION_OFFER_KEY = 'auctionOffer';
export const AUCTION_SUCCESS_KEY = 'auctionSuccess';
export const AUCTION_NEGOTIATION_KEY = 'auctionNegotiation';
export const AUCTION_CONFIRM_MAKE_OFFER_KEY = 'auctionConfirmMakeOffer';
export const AUCTION_AUTOMATIC_OFFER_KEY = 'auctionAutomaticOffer';
export const MORE_OPTIONS_KEY = 'moreOptions';
export const REMOVE_AUTOMATIC_OFFER_KEY = 'removeAutomaticOffer';
export const TIEBREAKER_RULES_KEY = 'tiebreakerRules';
export const AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY = 'auctionConfirmAutomaticOffer';
export const AUCTION_BUY_KEY_CONFIRMATION = 'auctionBuyConfirmation';
export const PREFERENCES_CONFIG_KEY = 'preferencesConfig';
export const TEMPERATURE_MODAL_KNOW_MORE = 'temperatureModalKnowMore';
export const PRODUCT_NF = 'productNF';
export const AUCTION_BUY_FOR = 'auctionBuyFor';
export const CONFIRM_BUY_FOR = 'confirmBuyFor';
