import styled, { keyframes, css } from 'styled-components';

export const ANIMATION_MILLISECONDS = 4000;

const slidein = keyframes`
  from {
    margin-left: 0;
  }
  to {
    margin-left: calc(100% - 142px);
  }
  `;

const fill = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%
  }
  `;

const NoStyledButton = styled.button`
  padding: 0;

  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    color: rgba(0, 0, 0, 0.3);
    cursor: default;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px 0;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 601px) {
    gap: 0;
    height: auto;
  }
`;

export const NotificationContent = styled(ModalContent)`
  @media screen and (max-width: 601px) {
    gap: 0;
    max-height: 100vh;
    height: 100%;
    justify-content: flex-start;
  }
`;

export const AccurateContent = styled(NotificationContent)`
  gap: 72px 0;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 600px) {
    margin-top: -8px;
    padding: 24px 20px;
    background-color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
`;

export const Typography = styled.span`
  margin-bottom: ${({ marginBottom }) => `${marginBottom || 0}rem`};
  font: ${({ fontFamily, fontWeight, fontSize }) =>
    `${fontWeight} normal ${fontSize || 16}px/1.5em '${fontFamily}', sans-serif`};
  color: ${({ color }) => color || '#2F3741'};
  text-align: ${({ textAlign }) => textAlign || 'center'};

  ${({ alignSelf }) =>
    alignSelf
      ? css`
          align-self: alignSelf;
        `
      : null}
`;

export const MergedContent = styled.div`
  @media screen and (min-width: 0) and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px 0;
    height: 100%;
  }

  @media screen and (min-width: 601px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    align-items: center;
    margin-bottom: 48px;
  }
`;

export const GridContent = styled.div`
  display: grid;
  gap: ${props => props.gap ?? '14px 16px'};
  align-items: center;

  @media screen and (min-width: 0) and (max-width: 685px) {
    grid-template-columns: 100%;
    margin: 16px 0;
    padding: 0 20px;
  }

  @media screen and (min-width: 688px) and (max-width: 1020px) {
    grid-template-columns: repeat(2, 310px);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 48px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 0) and (max-width: 685px) {
    padding: 20px;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
  }
`;

export const NextButton = styled(NoStyledButton)`
  display: flex;
  align-items: center;

  font-family: 'Roboto';
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  font-size: 16px;
  color: ${({ color }) => color || '#2F3741'};
`;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `${gap || 0}px`};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  max-width: ${({ maxWidth }) => `${maxWidth || '320'}px`};
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 10rem;

  @media screen and (max-width: 668px) {
    padding: 0 2.5rem;
  }
`;

export const ImgContainer = styled.img`
  animation: ${slidein} ${ANIMATION_MILLISECONDS}ms linear both;
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 32px;
  background-color: #ededed;
  border-radius: 4px;
`;

export const LoadingContent = styled.img`
  height: 100%;

  background-color: #22aa52;
  border-radius: 4px;

  animation: ${fill} ${ANIMATION_MILLISECONDS}ms linear both;
`;

export const ModalMobileHeader = styled.div`
  @media screen and (min-width: 0) and (max-width: 600px) {
    display: flex;
    align-items: center;

    width: 100%;
    height: 64px;
    padding: 0 16px;

    background-color: #2274a5;
  }

  @media screen and (min-width: 601px) {
    display: none;
  }
`;

export const NextButtonWrapper = styled.div`
  @media screen and (min-width: 0) and (max-width: 600px) {
    display: none;
  }

  @media screen and (min-width: 601px) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
  }
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  align-items: center;
`;

export const Section = styled.div`
  display: flex;
  gap: 0 40px;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 345px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 12px 0;
    min-height: 520px;
  }
`;

Section.Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;

  height: 100%;

  @media screen and (max-width: 600px) {
    gap: 12px 0;
    width: 100%;
  }
`;

Section.Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;

  ${({ height }) =>
    height &&
    css`
      height: ${height}%;
    `}

  @media screen and (max-width: 600px) {
    width: 100%;

    ${({ height }) =>
      height &&
      css`
        min-height: 345px;
      `}
  }
`;

Section.Divider = styled.div`
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

ModalContent.Mobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px 0;

  @media screen and (min-width: 0) and (max-width: 600px) {
    gap: 28px 0;

    margin-top: -8px;
    padding: 24px 20px 0;

    background-color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    overflow-y: scroll;
  }
`;
