import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { find } from 'lodash';

import { Container, Icon, Label } from './styles';

import TemperatureDetailsVehicle from '../../../images/icons/temperatureDetailsVehicle.svg';

export const NinjaLayout = ({
  auction,
  currentValue,
  modal = false,
  detailsVehicle = false,
  autobid = false,
  autobidParticipate = false,
}) => {
  const [temperatures] = useState([
    {
      id: 1,
      label: 'Baixas chances de compra',
      icon: require('../../../images/icons/lowChances.png'),
    },
    {
      id: 2,
      label: 'Possível negociação',
      icon: require('../../../images/icons/possibleNegotiation.png'),
    },
    {
      id: 3,
      label: 'Altas chances de compra',
      icon: require('../../../images/icons/highChances.png'),
    },
  ]);

  const [currentTemperature, setCurrentTemperature] = useState();

  const temperatureMeasurement = useCallback(
    temperatureId => {
      const selectedTemperature = find(temperatures, { id: temperatureId });

      if (!selectedTemperature) {
        throw new Error('Invalid provided temperature');
      }

      const { ...rest } = selectedTemperature;
      setCurrentTemperature(rest);
    },
    [setCurrentTemperature, temperatures]
  );

  const highChances = useMemo(() => currentValue > auction?.summary?.vehiclePricing?.hotTarget, [
    currentValue,
    auction?.summary?.vehiclePricing?.hotTarget,
  ]);

  const lowChances = useMemo(() => currentValue < auction?.summary?.vehiclePricing?.warmTarget, [
    currentValue,
    auction?.summary?.vehiclePricing?.warmTarget,
  ]);

  useEffect(() => {
    if (highChances) {
      return temperatureMeasurement(3);
    }

    return temperatureMeasurement(lowChances ? 1 : 2);
  }, [highChances, lowChances, temperatureMeasurement]);

  return (
    <Container modal={modal}>
      <Icon src={detailsVehicle ? TemperatureDetailsVehicle : currentTemperature?.icon} />
      <Label
        autobidParticipate={autobidParticipate}
        detailsVehicle={detailsVehicle}
        color={currentTemperature?.id}
        autobid={autobid}
      >
        {currentTemperature?.label}
      </Label>
    </Container>
  );
};
