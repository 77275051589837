import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  padding: 1.1rem 1.375rem;
`;

export const Text = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  color: ${props => props.color};
`;

export const Image = styled.img`
  align-self: center;
  max-width: 274px;
  width: 100%;
`;

export const Button = styled.button`
  width: 100%;
  margin-top: 12px;
  padding: 14px 0;

  font: 700 normal 16px/1em 'Roboto', sans-serif;
  color: #000;
  text-align: center;

  background-color: #f2c94c;
  border: none;
  border-radius: 4px;

  outline: none;
  cursor: pointer;
`;

export const ButtoFincred = styled.button`
  width: 100%;
  padding: 14px 0;

  font: 700 normal 16px/1em 'Roboto', sans-serif;
  color: #000;
  text-align: center;

  background-color: #f3f3f3;
  border: 2px solid #a37d0b;
  color: #a37d0b;
  border-radius: 4px;

  outline: none;
  cursor: pointer;
`;

export const ContentFin = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  height: 63%;
  padding: 1.1rem 1.375rem;
`;

export const TitleFinDiv = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: start;
`;

export const TitleFinText = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  padding-left: 6px;
`;

export const FinText = styled.div`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 21.31px;
  letter-spacing: 0.01em;
  text-align: left;
`;

export const FinTextDiv = styled.div`
  padding-top: 5px;
`;

export const FinancingInfoDiv = styled.div`
  padding-top: 7px;
  padding-bottom: 10px;
`;

export const EntryValueText = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

export const EntryValue = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;

export const TaxesTotalDiv = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

export const style = {
  content: {
    position: 'relative',
    maxWidth: '340px',
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
