import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchHistoryAuctions } from '../../utils/fecth-wrappers';
import { formatPurchased, strIncludes, FormatDate } from './format';
import { useSelector } from 'react-redux';

const defaultData = {
  auctions: [],
  filtered: [],
  getAuctions: () => {},
  filterBySearch: () => {},
  filterByState: () => {},
};

export const PurchasesContext = createContext(defaultData);
export const usePurchasesContext = () => useContext(PurchasesContext);
export const PurchasesConsumer = PurchasesContext.Consumer;

export const PurchasesProvider = ({ children }) => {
  const userId = useSelector(state => state.authentication.user.id);

  const [auctions, setAuctions] = useState(defaultData.auctions);
  const [filtered, setFiltered] = useState(defaultData.filtered);
  const [filter, setFilter] = useState({
    search: null,
    state: null,
    days: null,
  });

  const getAuctions = (params = null, newFilter = filter) => {
    new Promise((resolve, reject) => {
      setAuctions(null);
      setFiltered(null);
      fetchHistoryAuctions(params)
        .then(res => {
          const formatted = res && res.data && res.data.body ? formatPurchased(res.data.body) : [];
          setAuctions(formatted);
          updateFilter(newFilter, formatted);
          resolve();
        })
        .catch(x => {
          setAuctions([]);
          updateFilter(newFilter, []);
          reject(x);
        });
    });
  };

  const updateFilter = (data, arr = auctions) => {
    const newFilter = { ...filter, ...data };
    const matchSearch = obj => strIncludes(newFilter.search, obj.slug + obj.lastUpdated);
    const matchstate = obj => (newFilter.state ? newFilter.state(obj.events.active.id) : true);
    const result = arr
      .filter(obj => matchSearch(obj) && matchstate(obj))
      .sort((a, b) => {
        if (!a.events.timestamp === 'N/A') return 1;
        if (!b.events.timestamp === 'N/A') return -1;
        if (a.events.timestamp > b.events.timestamp) return -1;
        if (a.events.timestamp < b.events.timestamp) return 1;
        return 0;
      });
    setFiltered(result);
    setFilter(newFilter);
  };

  const filterByDate = n => {
    if (filter.days !== n) {
      if (n) {
        const endDate = n.to
          ? n.to
          : FormatDate({ date: new Date().setDate(new Date().getDate() + 2), short: true });
        const startDate = n.from
          ? n.from
          : FormatDate({
              date: new Date().setDate(new Date().getDate() - n),
              short: true,
            });
        getAuctions(`dateStart=${startDate}&dateEnd=${endDate}&dealerId=${userId}`, {
          ...filter,
          days: n,
        });
      } else {
        getAuctions(`dealerId=${userId}`, { ...filter, days: null });
      }
    }
  };

  return (
    <PurchasesContext.Provider
      value={{
        filtered,
        getAuctions,
        updateFilter,
        filterByDate,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
