import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdZoomOutMap } from 'react-icons/md';
import { rem } from 'polished';
import noop from 'lodash/noop';
import { Text, Box, Flex, HorizontalLine, Badge, Carousel } from '../../../../components';
import { MdError, MdCheckCircle } from 'react-icons/md';

const Icon = styled.span`
  svg {
    vertical-align: middle;
  }
`;
const HR = styled(HorizontalLine)`
  &:last-of-type {
    background: white;
  }
`;
const SliderWrapper = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;
  .BrainhubCarouselItem {
    flex-shrink: 0;
    flex-grow: 0;
    flex-direction: column;
    margin: 0.3em;
    justify-content: flex-start;
  }
  .imgWrapper {
    position: relative;
    cursor: pointer;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 8.3rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.4);
      color: white;
      padding: 3px;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .BrainhubCarousel__dots {
    display: none;
  }
  button {
    top: 4.5rem;
  }
`;

const SectionTexts = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  width: 100%;
  list-style: none;

  @media screen and (max-width: 1025px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 415px) {
    grid-template-columns: 1fr;
  }
`;

const FlexRow = styled.li`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.span`
  display: flex;
  align-items: center;

  width: 100%;
  margin: 0.5rem 0;

  font: 600 normal 0.875rem/1.3em 'Nunito', sans-serif;
  color: #1a2331;
`;

const BadgeBox = styled.div`
  align-self: flex-start;

  width: 40px;
  height: 40x;

  text-align: center;
`;

export class Section extends React.Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.toggle = this.toggle.bind(this);
  }
  state = {
    open: true,
  };
  settings = {
    slidesToShow: 6,
    slidesToScroll: 6,
    lazyLoad: true,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  sortbyImages(items) {
    const photos = [];
    const texts = [];
    items.map(el => (el.img && el.img.src ? photos.push(el) : texts.push(el)));
    return { photos: photos, texts: texts };
  }
  toggle() {
    this.setState(prevState => ({ open: !prevState.open }));
  }
  goToNext(next) {
    if (next) {
      this.sliderRef.slickNext();
    } else {
      this.sliderRef.slickPrev();
    }
  }
  render() {
    const { title, counter, items, passed, onOpenFullscreen } = this.props;
    const { open } = this.state;
    const sections = this.sortbyImages(items);
    return !items.length ? null : (
      <React.Fragment>
        <Flex flexWrap="wrap">
          <Flex
            alignItems="center"
            style={{ cursor: 'pointer' }}
            pb={open ? { sm: rem(6), md: rem(12) } : 0}
            width={1}
            my={1}
            onClick={this.toggle}
          >
            <Badge
              color={passed ? 'secondaries.Uno' : 'alerts.Ferrari'}
              borderColor={passed ? 'secondaries.Uno' : 'alerts.Ferrari'}
              bg="transparent"
              size={30}
              bold
              fontSize={1}
              style={{
                fontWeight: 'bolder',
                borderWidth: '2px',
              }}
            >
              {counter}
            </Badge>
            <Text color="neutrals.EclipseE600" fontSize={3} ml={1}>
              {title}
              <Icon>
                {open ? <MdKeyboardArrowDown size={27} /> : <MdKeyboardArrowUp size={27} />}
              </Icon>
            </Text>
          </Flex>

          {sections.photos.length > 0 && open && (
            <SliderWrapper pb={{ sm: 2, mb: 4 }}>
              <Carousel sm={2} md={3} lg={7} arrows fadeOut>
                {sections.photos.map(({ img, description }, i) => (
                  <Box key={img.src}>
                    <Box
                      className="imgWrapper"
                      m={1}
                      onClick={() => {
                        onOpenFullscreen(i, sections.photos);
                      }}
                    >
                      <img {...img} alt={description} />
                      <Box as={MdZoomOutMap} />
                    </Box>
                    <Item>
                      <BadgeBox>
                        {passed ? <MdCheckCircle color="#22aa52" /> : <MdError color="#f8333d" />}
                      </BadgeBox>
                      {description}
                    </Item>
                  </Box>
                ))}
              </Carousel>
            </SliderWrapper>
          )}

          <SectionTexts>
            {open &&
              sections.texts.map(({ description }) => (
                <FlexRow>
                  <Item key={description}>
                    {passed ? (
                      <MdCheckCircle
                        size="20px"
                        color="#22aa52"
                        style={{ marginRight: '0.5rem' }}
                      />
                    ) : (
                      <MdError size="20px" color="#f8333d" style={{ marginRight: '0.5rem' }} />
                    )}
                    {description}
                  </Item>
                </FlexRow>
              ))}
          </SectionTexts>
        </Flex>
        <HR />
      </React.Fragment>
    );
  }
}

Section.propTypes = {
  title: PropTypes.string,
  counter: PropTypes.number,
  items: PropTypes.array.isRequired,
  passed: PropTypes.bool,
  onOpenFullscreen: PropTypes.func,
};
Section.defaultProps = {
  title: 'null',
  counter: 0,
  onOpenFullscreen: noop,
};

export default Section;
