import React, { useMemo } from 'react';
import { IMaskInput } from 'react-imask';

export const MASK_FORMAT_1 = '(00) 0000-0000';
export const MASK_FORMAT_2 = '(00) 00000-0000';
export const PhoneNumber = React.forwardRef(function PhoneNumber(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={[{ mask: MASK_FORMAT_1 }, { mask: MASK_FORMAT_2 }]}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
    />
  );
});
