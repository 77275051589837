import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 0;

  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

export const VehicleSpecifications = styled.span`
  font: 700 normal 1rem/1.2em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`;

export const Button = styled.button`
  width: 100%;
  padding: 12px 0;

  font: normal 16px/1em 'Roboto', sans-serif;
  font-weight: ${props => props.fontWeight ?? 700};
  color: ${props => props.color ?? '#fff'};
  text-align: center;

  background-color: ${props => props.bg ?? '#438a4c'};

  border: none;
  border-radius: 4px;

  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${props => props.hover};
  }
`;

export const moreOptionsStyle = {
  content: {
    position: 'relative',
    maxWidth: '340px',
    maxHeight: '532px',
    height: '100%',
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '56px 16px',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
