import initialState from './initialState';
import ActionTypes from '../../constants/actionTypes';
import objectAssign from 'object-assign';

function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case ActionTypes.RelatedDealers.Fetch:
      return {
        ...state,
        relatedDealersLoading: true,
      };
    case ActionTypes.RelatedDealers.FetchSuccess:
      return {
        ...state,
        relatedDealers: action.relatedDealers,
        relatedDealersLoading: false,
      };
    case ActionTypes.RelatedDealers.FetchError:
      return {
        ...state,
        relatedDealersLoading: false,
      };
    case ActionTypes.RelatedDealers.GetBuyerIdSuccess:
      return {
        ...state,
        buyerId: action.buyerId,
      };
    case ActionTypes.RelatedDealers.ClearBuyerId:
      return {
        ...state,
        buyerId: null,
      };
    case ActionTypes.RelatedDealers.Clear:
      return objectAssign({}, state, { relatedDealers: [] });

    case ActionTypes.Wishlist.Fetch:
      return {
        ...state,
        wishlistLoading: true,
      };
    case ActionTypes.Wishlist.FetchSuccess:
      return {
        ...state,
        wishlistLoading: false,
        wishlist: action.wishlist,
      };
    case ActionTypes.Wishlist.FetchError:
      return {
        ...state,
        wishlistLoading: false,
        wishlistHaveError: true,
        wishlistError: action.error,
      };
    case ActionTypes.Wishlist.Add:
      return {
        ...state,
        wishlistLoading: true,
      };
    case ActionTypes.Wishlist.AddSuccess:
      return {
        ...state,
        wishlistLoading: false,
        wishlist: [...state.wishlist, action.wishlist],
      };
    case ActionTypes.Wishlist.AddError:
      return {
        ...state,
        wishlistLoading: false,
        wishlistHaveError: true,
        wishlistError: action.error,
      };
    case ActionTypes.UI.SetUserProfileState:
      return { ...state, isRegistrationOutdate: action.isRegistrationOutdate };
    case ActionTypes.UI.DefaultUserProfile:
      const { profile, userProfileError = false, userProfileLoading = true } = action.payload;
      return { ...state, defaultUserProfile: { profile, userProfileError, userProfileLoading } };
    case ActionTypes.UI.UserProfile:
      return { ...state, profile: action.payload };
    default:
      return state;
  }
}

export default userReducer;
