import React, { useMemo, useCallback } from 'react';
import {
  TimingAuctionContextProvider,
  useTimingAuctionContext,
} from '../../../../context/TimingAuctionContext';
import { toggleModal } from '../../../../store/actions/ui.actions';
import {
  getSelectedAuction,
  getSelectedAuctionRemainingTime,
} from '../../../../store/selectors/auctionSelectors';
import { AuctionItemTimer } from '../../../enhanced';
import Modal from '../../../enhanced/Modal';
import {
  MORE_OPTIONS_KEY,
  AUCTION_BUY_KEY,
  REMOVE_AUTOMATIC_OFFER_KEY,
  AUCTION_AUTOMATIC_OFFER_KEY,
  AUCTION_BUY_FOR,
} from '../../../../constants/modalConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionsContainer,
  Button,
  Content,
  Header,
  VehicleSpecifications,
  moreOptionsStyle,
} from './styles';
import { setSelectedAuction } from '../../../../store/actions/auction.actions';
import { useAutoBid } from '../../../../hooks/useAutoBid';
import { useSegment } from '../../../../hooks/useSegment';
import { formatPrice } from '../../../../utils/formatPrice';

const MoreOptions = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { show, extras } = useSelector(state => state.ui.modals.moreOptions);

  const selectedAuction = getSelectedAuction(state);
  const selectedAuctionRemainingTime = getSelectedAuctionRemainingTime(state);

  const { milisecondsLeft } = useTimingAuctionContext();
  const { currentAutoBidConfigured } = useAutoBid(selectedAuction?._id);
  const { trackButtonClicked } = useSegment();

  const setSection = typeof extras !== null ? extras?.section : undefined;

  const toggle = () => dispatch(toggleModal({ key: MORE_OPTIONS_KEY, show: false }));

  const auction = useMemo(() => {
    return {
      ...selectedAuction?.auction,
      remainingTime: selectedAuctionRemainingTime,
    };
  }, [selectedAuction, selectedAuctionRemainingTime]);

  const handleAutomaticOfferConfiguration = useCallback(() => {
    dispatch(setSelectedAuction(selectedAuction, { remainingTime: milisecondsLeft }));
    dispatch(toggleModal({ key: MORE_OPTIONS_KEY, show: false }));
    dispatch(
      toggleModal({
        key: AUCTION_AUTOMATIC_OFFER_KEY,
        show: true,
        extras: { selectedAuction, section: setSection },
      })
    );
    trackButtonClicked(
      selectedAuction?._id,
      'autoBid',
      selectedAuction?.auction.idAuction,
      'participate'
    );
  }, [selectedAuction, milisecondsLeft, setSection, trackButtonClicked]);

  const handleAutomaticOfferRemoval = useCallback(() => {
    dispatch(toggleModal({ key: MORE_OPTIONS_KEY, show: false }));
    dispatch(
      toggleModal({
        key: REMOVE_AUTOMATIC_OFFER_KEY,
        show: true,
        extras: {
          selectedAuctionId: selectedAuction?._id,
          selectedAuctionAuId: selectedAuction.auction.idAuction,
          selectedAutoBidId: currentAutoBidConfigured?.autoBidId,
          section: setSection,
          otherStyle: true,
        },
      })
    );
  }, [currentAutoBidConfigured?.autoBidId, selectedAuction?._id, setSection]);

  const handleNegotiateExclusivityModal = useCallback(() => {
    dispatch(setSelectedAuction(selectedAuction, { remainingTime: milisecondsLeft }));
    dispatch(toggleModal({ key: MORE_OPTIONS_KEY, show: false }));
    dispatch(toggleModal({ key: AUCTION_BUY_KEY, show: true, extras: { section: setSection } }));
    trackButtonClicked(
      selectedAuction?._id,
      'exclusivityBid',
      selectedAuction?.auction.idAuction,
      'participate'
    );
  }, [selectedAuction, milisecondsLeft, trackButtonClicked]);

  const handleBuyForModal = useCallback(() => {
    dispatch(setSelectedAuction(selectedAuction, { remainingTime: milisecondsLeft }));
    dispatch(toggleModal({ key: MORE_OPTIONS_KEY, show: false }));
    dispatch(toggleModal({ key: AUCTION_BUY_FOR, show: true, extras: { section: setSection } }));
    trackButtonClicked(
      selectedAuction?._id,
      'buyForBid',
      selectedAuction?.auction.idAuction,
      'participate'
    );
  }, [selectedAuction, milisecondsLeft, trackButtonClicked]);

  const useExclusivityLayout = useCallback(() => {
    const buyForEnabled = selectedAuction?.summary?.buyForPrice?.enabled;
    const buyNowEnabled = selectedAuction?.summary?.buynowPrice?.enabled;

    if (buyForEnabled || !buyNowEnabled) return null;

    return (
      <Button
        fontWeight={400}
        bg="#ff4c00"
        hover="#ff7941"
        onClick={handleNegotiateExclusivityModal}
      >
        Negocie a partir de{' '}
        <strong>R$ {formatPrice(selectedAuction?.summary?.buynowPrice?.amount)}</strong>
      </Button>
    );
  }, [
    selectedAuction?.summary?.buyForPrice?.enabled,
    selectedAuction?.summary?.buynowPrice?.enabled,
    selectedAuction?.summary?.buynowPrice?.amount,
    handleNegotiateExclusivityModal,
  ]);

  const useBuyForLayout = useCallback(() => {
    if (!selectedAuction?.summary?.buyForPrice?.enabled) return null;

    return (
      <Button fontWeight={400} color="#000" bg="#f2c94c" onClick={handleBuyForModal}>
        Compra garantida por{' '}
        <strong>R$ {formatPrice(selectedAuction?.summary?.buyForPrice?.amount)}</strong>
      </Button>
    );
  }, [
    selectedAuction?.summary?.buyForPrice?.enabled,
    selectedAuction?.summary?.buyForPrice?.amount,
    handleBuyForModal,
  ]);

  const useButtonsLayout = useCallback(() => {
    const status = currentAutoBidConfigured?.autoBidStatus;
    const limit = currentAutoBidConfigured?.updateLimit;
    const canAutoBid = status === 'OFF' && limit !== 2;
    const losing = !currentAutoBidConfigured?.isWinner || currentAutoBidConfigured?.tie;
    const losingWithouEdits = losing && limit === 2;
    const canBid = currentAutoBidConfigured && status === 'ON' && !losingWithouEdits;

    if (!currentAutoBidConfigured || canAutoBid) {
      return (
        <Button hover="#4ab971" borderRadius={8} onClick={handleAutomaticOfferConfiguration}>
          Oferta Automática
        </Button>
      );
    }

    if (canBid) {
      return (
        <Button hover="#4ab971" borderRadius={8} onClick={handleAutomaticOfferRemoval}>
          Oferta Avulsa
        </Button>
      );
    }
  }, [currentAutoBidConfigured, handleAutomaticOfferConfiguration, handleAutomaticOfferRemoval]);

  return (
    <TimingAuctionContextProvider auction={auction}>
      <Modal toggle={toggle} show={show} style={moreOptionsStyle}>
        <Content>
          <Header>
            <VehicleSpecifications>
              {selectedAuction?.summary?.make} {selectedAuction?.summary?.model}{' '}
              {selectedAuction?.summary?.yearLabel} - {selectedAuction?.summary?.version}{' '}
              {selectedAuction?.summary?.kmLabel}
            </VehicleSpecifications>
            <AuctionItemTimer showBuyNowButton={false} auction={selectedAuction} />
          </Header>

          <ActionsContainer>
            {useButtonsLayout()}
            {useExclusivityLayout()}
            {useBuyForLayout()}
          </ActionsContainer>
        </Content>
      </Modal>
    </TimingAuctionContextProvider>
  );
};

export default MoreOptions;
