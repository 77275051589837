import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #757778;
  box-sizing: border-box;
  background: #ffffff;
  position: absolute;
  padding: 15px 20px;
  min-height: 141px;
  width: 300px;
  right: 10px;
  z-index: 2;
  top: 37px;
`;

Container.Item = styled.div`
  min-height: 29px;
  width: 100%;
  margin-bottom: 12px;
  text-align: right;
  cursor: pointer;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #2274a5;

  span {
    font-size: 16px;
    color: #757778;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
