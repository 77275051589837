import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px 0;
  height: 100%;
`;

export const Title = styled.span`
  font: 700 normal 20px/1em 'Roboto', sans-serif;
  color: #6d1974;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px 0;
  text-align: center;

  @media screen and (max-width: 1025px) {
    margin-bottom: 24px !important;
  }
`;

export const ListItem = styled.span`
  font: 400 normal 14px/20px 'Roboto', sans-serif;
  color: #2f3741;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px 0;
  background-color: #1a2331;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font: 600 normal 16px/1em 'Rubik', sans-serif;
  color: #fff;
`;
