import styled from 'styled-components';

export const Option = styled.div`
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  min-height: 54px;
  display: flex;

  &:last-child {
    border-bottom: none;
  }
`;
