import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

import { Card as CardBase, Text, Flex, Box } from '../../../abstract';
import LastBid from './LastBid';

const Card = styled(CardBase)`
  border: 3px solid ${themeGet('colors.secondaries.Uno')};
`;

Card.displayName = 'Card';

export const OfferAcceptedCallout = ({ price }) => {
  return (
    <Card variant="flat" bg="neutrals.EclipseE60" p={rem(12)}>
      <Flex alignItems="center" color="neutrals.EclipseE500">
        <Text as="p" fontSize={1} width={7 / 12} mb={0} pr={1}>
          Você será contatado para darmos sequência ao processo de compra.
        </Text>
        <Box width={5 / 12}>
          <LastBid price={price} />
        </Box>
      </Flex>
    </Card>
  );
};

OfferAcceptedCallout.propTypes = {
  price: PropTypes.string.isRequired,
};
