import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdGroup } from 'react-icons/md';
import { rem } from 'polished';

import { Text, Box } from '../../../abstract';
import { getIntegerValue } from '../../../../utils/number';

const Wrapper = styled(Text)`
  border-radius: ${rem(22)};
  display: flex;
  align-items: center;
  z-index: 2;
`;

export const AuctionBidders = ({ count = 0, iconSize, ...props }) => {
  return (
    <Wrapper px={2} bg="rgba(0, 0, 0, 0.5)" fontSize={3} color="white" height={rem(44)} {...props}>
      <MdGroup size={getIntegerValue(iconSize, 20)} />
      <Box as="span" ml={1}>
        {count} participando
      </Box>
    </Wrapper>
  );
};

AuctionBidders.propTypes = {
  count: PropTypes.number,
  iconSize: PropTypes.number,
};

AuctionBidders.defaultProps = {
  count: 0,
};

export default AuctionBidders;
