import {
  moneyFormatter,
  dateFormatter,
  marketPriceFormatter,
  auctionTemperatureFormatter,
  elapsedTimeFormatter,
  ninjaNameFormatter,
} from './formatters';

export const columns = [
  {
    width: 100,
    sortable: true,
    fixed: true,
    resizable: true,
    label: 'Placa',
    dataKey: 'plate',
    type: 'link',
    getUrl: data => `/detalhes/${data['_id']}`,
    target: '_blank',
  },
  {
    width: 130,
    sortable: true,
    resizable: true,
    label: 'Marca',
    dataKey: 'make',
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Modelo',
    dataKey: 'model',
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Versão',
    dataKey: 'version',
  },
  {
    width: 65,
    sortable: true,
    resizable: true,
    label: 'Ano',
    dataKey: 'year',
  },
  {
    width: 90,
    sortable: true,
    resizable: true,
    label: 'Disputas',
    dataKey: 'auctionsCount',
  },
  {
    width: 175,
    sortable: true,
    resizable: true,
    label: 'Termino',
    dataKey: 'currentAuctionEndTime',
    formatter: dateFormatter,
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Restam',
    dataKey: 'currentAuctionEndTime',
    type: 'duration',
    formatter: elapsedTimeFormatter,
  },

  {
    width: 110,
    sortable: true,
    resizable: true,
    label: 'Start Price',
    dataKey: 'startPrice',
    formatter: moneyFormatter,
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Max Bid',
    dataKey: 'maxBidValue',
    formatter: moneyFormatter,
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: '% Fipe',
    dataKey: 'fipe',
    formatter: marketPriceFormatter,
  },
  {
    width: 65,
    sortable: true,
    resizable: true,
    label: 'Bids',
    dataKey: 'bidsCounts',
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Dealers',
    dataKey: 'dealersCount',
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Morno',
    dataKey: 'vehiclePricing.warmTarget',
    formatter: moneyFormatter,
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Quente',
    dataKey: 'vehiclePricing.hotTarget',
    formatter: moneyFormatter,
  },
  {
    width: 135,
    sortable: true,
    resizable: true,
    label: 'Chance atual',
    dataKey: 'vehiclePricing',
    formatter: auctionTemperatureFormatter,
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Fipe',
    dataKey: 'fipe',
    formatter: moneyFormatter,
  },
  {
    width: 110,
    sortable: true,
    resizable: true,
    label: 'Min Price',
    dataKey: 'minPrice',
    formatter: moneyFormatter,
  },
  {
    width: 110,
    sortable: true,
    resizable: true,
    label: 'Max Price',
    dataKey: 'maxPrice',
    formatter: moneyFormatter,
  },
  {
    width: 265,
    sortable: true,
    resizable: true,
    label: 'Cautelar',
    dataKey: 'cautelar',
  },
  {
    width: 80,
    sortable: true,
    resizable: true,
    label: 'Score',
    dataKey: 'score',
  },
  {
    width: 140,
    sortable: true,
    resizable: true,
    label: 'Loja - Max Bid',
    dataKey: 'currentWinnerDealership',
  },
  {
    width: 100,
    sortable: true,
    resizable: true,
    label: 'Ninja',
    dataKey: 'ninjaHost.owner.name',
    formatter: ninjaNameFormatter,
  },
];
