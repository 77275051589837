import initialState from './initialState';
import objectAssign from 'object-assign';
import ActionTypes from '../../constants/actionTypes';

function stockCarsReducer(state = initialState.stockCars, action) {
  switch (action.type) {
    case ActionTypes.StockCars.FetchedAll:
      return objectAssign({}, state, { cars: action.cars, allRequestOnGoing: false });
    case ActionTypes.StockCars.SetSelected:
      return objectAssign({}, state, { selected: action.car });
    case ActionTypes.Modal.MakeOfferStock.Toggle:
      return objectAssign({}, state, { showSingleOfferModal: action.toggle });
    case ActionTypes.StockCars.SuccesfullyBought:
      return objectAssign({}, state, { showSingleOfferModal: false, showConfirmationModal: true });
    case ActionTypes.Modal.StockConfirmation.Toggle:
      return objectAssign({}, state, { showConfirmationModal: action.toggle });
    case ActionTypes.StockCars.AllRequestOnGoing:
      return objectAssign({}, state, { allRequestOnGoing: true });
    default:
      return state;
  }
}

export default stockCarsReducer;
