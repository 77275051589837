import styled from 'styled-components';
import { fontFamily, fontWeight, textAlign, lineHeight, letterSpacing } from 'styled-system';

import { Box } from './Box';

export const Text = styled(Box)(fontFamily, fontWeight, textAlign, lineHeight, letterSpacing);

Text.displayName = 'Text';

Text.propTypes = {
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...letterSpacing.propTypes,
};

// TODO: Default font css properties should be in global style, consider this
// after full implementation of styled-components.
Text.defaultProps = {
  fontFamily: 'paragraphs',
  fontSize: 2,
  lineHeight: 'paragraphs',
};
