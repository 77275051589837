import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { MdInfoOutline } from 'react-icons/md';

import { Flex, Box, Button } from './abstract';
import { Heading } from './enhanced/Heading';
import appConstants from '../constants/appConstants';
import { Tooltip } from './tooltip';

const columnProps = {
  flex: {
    sm: '1 1 100%',
    md: '1 1 50%',
  },
  justifyContent: 'space-between',
  alignItems: 'center',
  px: 3,
  py: 1,
};

const Wrapper = styled(Box)`
  h4 {
    color: ${themeGet('colors.neutrals.EclipseE400')};
    margin-right: 1rem;
  }
`;

const Label = styled(Heading).attrs(props => ({
  level: 4,
  fontWeight: 'headings.medium',
}))`
  display: flex;
  align-items: center;
  ${Button} {
    margin-left: 5px;
    height: 1rem;
  }
`;

export const TaxProfileDetails = ({ profile }) => {
  if (profile.type === appConstants.TaxIdType.CNPJ) {
    return (
      <Wrapper>
        <Flex flexWrap="wrap">
          <Flex {...columnProps}>
            <Label>Razão Social</Label>
            <div>{profile.socialReason}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>Nome Fantasia</Label>
            <div>{profile.fantasyName}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>CNPJ</Label>
            <div>{profile.taxIdentificationNumber}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>Apelido</Label>
            <div>{profile.nickName}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>Logradouro</Label>
            <div>{profile.address?.street}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>Número</Label>
            <div>{profile.address?.number}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>Complemento</Label>
            <div>{profile.address?.complement}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>CEP</Label>
            <div>{profile.address?.zipcode}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>Bairro</Label>
            <div>{profile.address?.neighborhood}</div>
          </Flex>

          <Flex {...columnProps}>
            <Label>Município</Label>
            <div>{profile.address?.municipality}</div>
          </Flex>

          <Flex {...columnProps} maxWidth={{ md: '50%' }}>
            <Label>Estado</Label>
            <div>{profile.address?.state}</div>
          </Flex>
        </Flex>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Flex flexWrap="wrap">
        <Flex {...columnProps}>
          <Label>Nome completo</Label>
          <div>{profile.fullName}</div>
        </Flex>

        <Flex {...columnProps}>
          <Label>RG</Label>
          <div>{profile.personalIdRG}</div>
        </Flex>

        <Flex {...columnProps}>
          <Label>CPF</Label>
          <div>{profile.taxIdentificationNumber}</div>
        </Flex>

        <Flex {...columnProps}>
          <Label>Apelido</Label>
          <div>{profile.nickName}</div>
        </Flex>

        <Flex {...columnProps} justifyContent="flex-start">
          <Label>
            Comentários{' '}
            <Tooltip label="Caso o seu cadastro seja reprovado, você poderá encontrar aqui um comentário sobre a reprovação">
              <Button variant="link.primary" css={{ display: 'inline-flex', padding: 0 }}>
                <MdInfoOutline aria-hidden />
              </Button>
            </Tooltip>
          </Label>
          <div>
            {profile.comments ??
              'Só é possível cadastrar pessoas associadas ao seu contrato social.'}
          </div>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
