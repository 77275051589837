import React, { useState } from 'react';
import { Container } from './styles';

const Tooltip = props => {
  const [active, setActive] = useState(false);

  return (
    <Container onClick={() => setActive(!active)}>
      {props.children}

      {active && <div className={`Tooltip-Tip ${props.direction || 'top'}`}>{props.content}</div>}
    </Container>
  );
};

export default Tooltip;
