import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.313rem;

  background-color: transparent;

  border: 0.063rem solid #c4c4c4;
  border-radius: 0.48rem;

  cursor: pointer;
  outline: none;
`;
