import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ModalContainer as Modal, withModal } from '../base';
import { SuccessMessage } from '../shared';
import { VehicleDetails } from '../../../shared/vehicle-details';

import { CloseButton as Button } from '../shared';
import { AUCTION_ACCEPTED_KEY } from '../../../Modals';

const Content = withModal(({ modal }) => (
  <div>
    <SuccessMessage title="Parabéns!" subtitle="A oferta foi aceita pelo cliente" />
    <VehicleDetails auction={modal.extras().auction} />
  </div>
));

const AcceptedModal = ({ className, ...props }) => {
  const classNames = classnames('accepted-modal', className);

  return (
    <Modal modalKey={AUCTION_ACCEPTED_KEY} className={classNames} {...props}>
      <Modal.Header />
      <Modal.Body>
        <Content />
      </Modal.Body>
      <Modal.Footer>
        <Button>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};
AcceptedModal.propTypes = {
  className: PropTypes.string,
};

export { AcceptedModal };
