import { authGet, authPost } from '../utils/fecth-wrappers';
import constants from '../constants/appConstants';

export const fetchWishlist = () => {
  return authGet(constants.API.wishlist);
};

export const insertWishlist = vehicleId => {
  return authPost(constants.API.wishlist, { vehicleId });
};
