import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  font-size: 27px;
  height: 30px;
  width: 30px;
`;

export const Badge = styled.span`
  background: #df0808;
  border-radius: 10px;
  position: absolute;
  min-width: 14.82px;
  font-style: normal;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  height: 14.86px;
  color: #ffffff;
  padding: 2.5px;
  left: 15px;
  top: 0;
`;
