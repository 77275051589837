import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';

import { Container } from './styles';

function ActiveItemsImage({ data, activeItems, removeAction, orderCars }) {
  const [values, setValues] = useState([]);

  useEffect(
    function() {
      const activedItems = data.filter(item => activeItems.includes(item.value));
      setValues(activedItems);
    },
    [data, activeItems]
  );

  return (
    <Container orderCars={orderCars}>
      {values?.map((item, key) => (
        <Container.Item key={key}>
          <div className="close" onClick={removeAction(item.value)}>
            <MdClose />
          </div>
          {item.image ? <img src={item.image} /> : <span className="text">{item.value}</span>}
        </Container.Item>
      ))}
    </Container>
  );
}

export default ActiveItemsImage;
