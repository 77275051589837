import React from 'react';

import { Card } from '../../../abstract';

export const AuctionCard = props => (
  <Card
    width={{ sm: '100%', md: '49%', lg: '32%' }}
    mb={3}
    mx="0.5%"
    overflow="hidden"
    {...props}
  />
);
