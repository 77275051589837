import styled from 'styled-components';
import { rem } from 'polished';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  max-width: 100%;
  width: 20%;
  margin-bottom: 24px;
`;

export const ActionsButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;

  @media screen and (max-width: 415px) {
    flex-direction: column-reverse;
  }
`;

export const Title = styled.span`
  margin-bottom: 0.5rem;

  font: 700 normal 1.25rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const Description = styled.span`
  margin-bottom: 1rem;

  font: 400 normal 1.1rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
  text-align: center;

  @media screen and (max-width: 415px) {
    margin-bottom: 1.125rem;

    width: 100%;
  }
`;

export const ButtonCancel = styled.button`
  padding: 0.8rem 2rem;
  margin-bottom: 0.75rem;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #2274a5;
  text-align: center;

  background-color: transparent;

  border: 1px solid #2274a5;
  border-radius: 0.5rem;

  cursor: pointer;
  outline: none;

  @media screen and (max-width: 415px) {
    width: 100%;
    padding: 0.8rem 0;
  }
`;

export const ConfirmButton = styled.button`
  padding: 0.8rem 2rem;
  margin-bottom: 0.75rem;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #fff;
  text-align: center;

  background-color: #2274a5;

  border: none;
  border-radius: 0.5rem;

  cursor: pointer;
  outline: none;

  @media screen and (max-width: 415px) {
    width: 100%;
    padding: 0.8rem 0;
  }
`;

export const confirmMakeOfferStyle = {
  content: {
    position: 'relative',
    maxHeight: `calc(100% - ${rem(10)})`,
    maxWidth: `calc(100% - ${rem(10)})`,
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
