import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleModal, setAuctionTabSelected } from '../../store/actions/ui.actions';
import Modal from '../enhanced/Modal';
import { AUCTION_LOST_KEY } from './';

const Component = ({ show, toggle, summary, setAuctionTab }) => {
  const details = {
    title: summary ? `${summary.make} ${summary.model} ${summary.version}` : '',
    year: summary ? [summary.productionYear, summary.year].filter(n => Boolean(n)).join(' / ') : '',
    mileage: summary && summary.km ? `${summary.km} km` : 'N/A km',
    plate: summary ? `Placa: ${summary.plateNumber}` : '',
    img: summary ? summary.mainPhoto : '',
  };
  const buttons = [
    {
      link: '/',
      label: 'Ver seus leilões',
      variant: 'primary',
      onClick: () => {
        setAuctionTab(1);
        toggle({
          key: AUCTION_LOST_KEY,
          show: false,
        });
      },
      main: true,
    },
  ];
  return (
    <Modal
      title="Não foi dessa vez :("
      description="Você não ganhou. Acompanhe seus leilões através da aba participando e não perca nenhum lance!"
      buttons={buttons}
      details={details}
      show={show}
      toggle={() => {
        toggle({ key: AUCTION_LOST_KEY, show: false });
      }}
    />
  );
};

Component.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  summary: PropTypes.any,
  setAuctionTab: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  toggle: bindActionCreators(toggleModal, dispatch),
  setAuctionTab: bindActionCreators(setAuctionTabSelected, dispatch),
});

const mapStateToProps = ({
  ui: {
    modals: { auctionLost },
  },
}) => ({
  show: auctionLost.show,
  summary:
    auctionLost.extras && auctionLost.extras.auction ? auctionLost.extras.auction.summary : null,
});
const LostModal = connect(mapStateToProps, mapDispatchToProps)(Component);
export { LostModal, Component as default };
