import React from 'react';
import 'react-dates/initialize';
import { DayPickerRangeController, DateRangePickerInputController } from 'react-dates';
import omit from 'lodash/omit';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import 'react-dates/lib/css/_datepicker.css';

import { Text } from '../../../abstract';
import {
  dateRangePickerPropTypes,
  dayPickerRangeControllerPropTypes,
} from './dateRangePickerPropTypes';
import { DayPickerPhrases, DateRangePickerInputPhrases } from './phrases';
import { DatePickerRangeWrapper, CalendarDay } from './styledWrappers';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

export class DateRangePicker extends React.Component {
  static propTypes = {
    ...dateRangePickerPropTypes,
    ...dayPickerRangeControllerPropTypes,
  };

  static defaultProps = {
    noBorder: true,
    keepOpenOnDateSelect: true,
    hideKeyboardShortcutsPanel: true,
    renderMonthElement: ({ month }) => (
      <Text as="span" fontWeight="paragraphs.bold" color="primaries.Taxi">
        {month.format('MMMM YYYY')}
      </Text>
    ),
    daySize: 36,
    navNext: <MdKeyboardArrowRight size={24} />,
    navPrev: <MdKeyboardArrowLeft size={24} />,
    renderDayContents: (day, status) => (
      <CalendarDay isBlocked={status.has('blocked')}>{day.format('D')}</CalendarDay>
    ),
    startDatePlaceholderText: 'Data inicial',
    endDatePlaceholderText: 'Data final',
    onDatesChange() {},
    startDate: null,
    endDate: null,
  };

  state = {
    focusedInput: this.props.autoFocusEndDate ? END_DATE : START_DATE,
    isDayPickerFocused: false,
  };

  componentDidUpdate(prevProps) {
    // Set focus on startDate when it's cleared.
    if (
      !this.props.startDate &&
      !this.props.endDate &&
      prevProps.startDate &&
      this.state.focusedInput === END_DATE
    ) {
      this.setState({ focusedInput: START_DATE });
    }
  }

  handleFocusChange = focusedInput => {
    // Force the focusedInput to always be truthy so that dates are always selectable
    this.setState({ focusedInput: !focusedInput ? START_DATE : focusedInput });
  };

  handleInputKeyArrowDown = () => {
    this.setState({ isDayPickerFocused: true });
  };

  render() {
    const { focusedInput } = this.state;
    const props = omit(this.props, Object.keys(dateRangePickerPropTypes));
    const { startDatePlaceholderText, endDatePlaceholderText } = this.props;
    const {
      displayFormat,
      keepOpenOnDateSelect,
      isOutsideRange,
      onDatesChange,
      startDate,
      endDate,
    } = props;
    const commonProps = {
      startDate,
      endDate,
      onDatesChange,
      onFocusChange: this.handleFocusChange,
      minimumNights: 0,
    };

    return (
      <DatePickerRangeWrapper>
        <DateRangePickerInputController
          {...commonProps}
          startDatePlaceholderText={startDatePlaceholderText}
          endDatePlaceholderText={endDatePlaceholderText}
          startDateId={START_DATE}
          endDateId={END_DATE}
          displayFormat={displayFormat}
          keepOpenOnDateSelect={keepOpenOnDateSelect}
          isOutsideRange={isOutsideRange}
          isStartDateFocused={focusedInput === START_DATE}
          isEndDateFocused={focusedInput === END_DATE}
          onKeyDownArrowDown={this.handleInputKeyArrowDown}
          phrases={DateRangePickerInputPhrases}
          small
          block
        />

        <DayPickerRangeController
          {...props}
          {...commonProps}
          focusedInput={focusedInput}
          isFocused={this.state.isDayPickerFocused}
          phrases={DayPickerPhrases}
        />
      </DatePickerRangeWrapper>
    );
  }
}
