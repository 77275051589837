import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { AddFilterCommand } from './filter-commands/add-filter-command';
import { FilterCommandManager } from './filter-commands/filter-command-manager';
import { RemoveFilterCommand } from './filter-commands/remove-filter-command';
import { createFilterServiceProxy } from './utils/create-filter-service-proxy';

const doNothing = val => () => Promise.resolve(val);

export const FiltersContext = createContext({
  filters: [],
  undo: doNothing(null),
  addFilter: doNothing({}),
  removeFilter: doNothing({}),
});

const commandsController = new FilterCommandManager();

export const FiltersContextProvider = ({ service, notify, children }) => {
  const [filters, setFilters] = useState([]);
  const [cleanSelects, setCleanSelects] = useState(false);

  const _onFilterAdded = useCallback(newFilter => {
    setFilters(oldFilters => oldFilters.concat(newFilter));
  }, []);

  const _onFilterRemoved = useCallback(filterToRemove => {
    setFilters(oldFilters => {
      const indexOfFilterToRemove = oldFilters.findIndex(
        filter => filter._id === filterToRemove._id
      );
      const shouldRemoveElement = indexOfFilterToRemove !== -1;
      if (!shouldRemoveElement) return oldFilters;

      const clone = [...oldFilters];
      clone.splice(indexOfFilterToRemove, 1);
      return clone;
    });
  }, []);

  const serviceProxy = useMemo(() => {
    return createFilterServiceProxy(service, {
      onFilterAdded: _onFilterAdded,
      onFilterRemoved: _onFilterRemoved,
    });
  }, [service, _onFilterAdded, _onFilterRemoved]);

  const addFilter = useCallback(
    async filterToAdd => {
      try {
        const addCommand = new AddFilterCommand(serviceProxy, filterToAdd);
        await commandsController.executeCommand(addCommand);
      } catch (error) {
        notify.error(error);
      }
    },
    [serviceProxy, notify]
  );

  const removeFilter = useCallback(
    async filterIdToRemove => {
      try {
        const removeCommand = new RemoveFilterCommand(serviceProxy, filterIdToRemove);
        await commandsController.executeCommand(removeCommand);
      } catch (error) {
        notify.error(error);
      }
    },
    [serviceProxy, notify]
  );

  const undo = useCallback(() => commandsController.undoLastCommand(), []);

  const contextValue = useMemo(
    () => ({
      undo,
      filters,
      addFilter,
      removeFilter,
      cleanSelects,
      setCleanSelects,
    }),
    [undo, filters, addFilter, removeFilter, cleanSelects]
  );

  window.filterContext = contextValue;

  useEffect(() => {
    const populateContext = async () => {
      const initialFilters = await service.getUserFilters();
      setFilters(initialFilters);
    };
    populateContext();
  }, [service]);

  return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>;
};
