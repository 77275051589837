import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0 12px;
  max-width: 367px;
  width: 100%;
  padding: 12px 8px;
  margin-top: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px 0;

  .rated-by,
  .evaluator-name {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #000;
  }

  .rated-by {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  .evaluator-name {
    font-size: 16px;
  }
`;
