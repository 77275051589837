import React, { useState, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import ClickOutside from 'react-click-outside';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { useTimingAuctionContext } from '../../../../context/TimingAuctionContext';
import { makeBid, setSelectedAuction } from '../../../../store/actions/auction.actions';
import loading from '../../../../images/icons/loading-tail.svg';
import AppConstants from '../../../../constants/appConstants';
import { Image } from '../../../abstract';
import get from 'lodash/get';
import { toPrice, toAmount } from '../../../../utils/number';
import { toggleModal } from '../../../../store/actions/ui.actions';
import { AUCTION_CONFIRM_MAKE_OFFER_KEY } from '../../../Modals';
import { useDefaultProfile } from '../../../../hooks/useDefaultProfile';
import {
  InputContainer,
  ButtonsWrapper,
  InputWrapper,
  DecrementBtn,
  IncrementBtn,
  ErrorMessage,
  InputLabel,
  Container,
  SubmitBtn,
} from './styles';

const MinimumBid = AppConstants.MinimumBid;

function MakeOfferQuick({
  makeOfferLoading,
  bidErrorMessage,
  selectAuction,
  bidSuccess,
  lastOffer,
  authUser,
  auction,
  makeBid,
  toggle,
}) {
  const defaultProfile = useDefaultProfile();
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const [value, setValue] = useState(0);
  const [auxButtons, setAuxButtons] = useState([
    {
      id: 1,
      value: 250,
      active: false,
    },
    {
      id: 2,
      value: 500,
      active: false,
    },
    {
      id: 3,
      value: 1000,
      active: false,
    },
  ]);

  const { secondsLeft: currentAuctionDuration } = useTimingAuctionContext();

  /**
   * Initialize component
   *
   */
  useEffect(
    function() {
      setValue(lastOffer);
    },
    [lastOffer]
  );

  /**
   * Format value to money
   *
   */
  function formatToMoney(value) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter
      .format(value)
      .replace(',00', '')
      .replace('R$', '');
  }

  /**
   * Handle input value
   *
   */
  function handleValue(e) {
    setShowSubmitButton(true);
    const inputValue = Number.parseInt(e.target.value.replace(/\D/g, ''));
    setValue(inputValue || 0);
  }

  /**
   * Auxiliary buttons action
   *
   */
  function handleAuxButtons(id) {
    return function() {
      setShowSubmitButton(true);
      const buttons = [...auxButtons].map(btn => {
        if (btn.id === id) {
          btn.active = true;
          setValue(value + btn.value);
          return btn;
        } else {
          return btn;
        }
      });
      setAuxButtons([...buttons]);
    };
  }

  /**
   * Increment minimum bid to value
   *
   */
  function handleIncrementValue() {
    setShowSubmitButton(true);
    setValue(value + MinimumBid);
  }

  /**
   * Decrement minimum bid to value
   *
   */
  function handleDecrementValue() {
    if (value < lastOffer) {
      setValue(value);
    }

    setShowSubmitButton(true);
    const decrementedValue = value - MinimumBid;
    if (decrementedValue < lastOffer) {
      setValue(lastOffer);
    } else {
      setValue(decrementedValue);
    }
  }

  /**
   * Submit bid
   *
   */
  function handleSubmit() {
    try {
      const minValueAccepted = lastOffer + MinimumBid;
      const buyNowEnabled = get(auction, 'summary.buynowPrice.enabled', false);

      if (value < minValueAccepted) {
        throw Error(`Faça um lance de R$ ${formatToMoney(minValueAccepted)} ou mais`);
      }

      if (buyNowEnabled && toAmount(auction.summary.buynowPrice.amount) <= toAmount(value)) {
        selectAuction(auction, { remaningTime: currentAuctionDuration * 1000 });

        toggle({
          key: AUCTION_CONFIRM_MAKE_OFFER_KEY,
          show: true,
          extras: {
            amountValue: toPrice(value, false),
            submitOffer: () =>
              makeBid(
                auction._id,
                value,
                authUser.dealershipName,
                null,
                false,
                defaultProfile?.taxIdentificationNumber,
                auction.auction.isSupperOffer
              ),
            makeOfferLoading,
          },
        });

        return;
      }

      selectAuction(auction, { remaningTime: currentAuctionDuration * 1000 });
      makeBid(
        auction._id,
        value,
        authUser.dealershipName,
        null,
        false,
        defaultProfile?.taxIdentificationNumber,
        auction.auction.isSupperOffer
      );
    } catch (e) {
      setLocalErrorMessage(e.message);
    }
  }

  /**
   * Disable auxiliary buttons
   *
   */
  function disableAuxButtons() {
    const buttons = [...auxButtons].map(button => {
      return {
        ...button,
        active: false,
      };
    });
    setAuxButtons(buttons);
  }

  /**
   * Handle click outside the component
   *
   */
  function handleClickOutside() {
    setValue(lastOffer);
    setShowSubmitButton(false);
    disableAuxButtons();
    setLocalErrorMessage('');
  }

  /**
   * Initialize component after submission
   *
   */
  useEffect(
    function() {
      if (!makeOfferLoading && bidSuccess) {
        setShowSubmitButton(false);
        disableAuxButtons();
        setLocalErrorMessage('');
      }
    },
    [makeOfferLoading, bidSuccess]
  );

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <Container showLastComponent={showSubmitButton} hasError={bidErrorMessage}>
        <InputWrapper>
          <InputLabel>Lance atual</InputLabel>
          <DecrementBtn onClick={handleDecrementValue}>
            <FaMinus />
          </DecrementBtn>
          <InputContainer>
            <InputContainer.MoneySign>R$</InputContainer.MoneySign>
            <InputContainer.Input onChange={handleValue} value={formatToMoney(value)} />
          </InputContainer>
          <IncrementBtn onClick={handleIncrementValue}>
            <FaPlus />
          </IncrementBtn>
        </InputWrapper>
        <ButtonsWrapper>
          {auxButtons.map(button => (
            <ButtonsWrapper.Button
              key={button.id}
              className={button.active ? 'active' : ''}
              onClick={handleAuxButtons(button.id)}
            >
              + {button.value}
            </ButtonsWrapper.Button>
          ))}
        </ButtonsWrapper>

        {(bidErrorMessage || localErrorMessage) && (
          <ErrorMessage>{bidErrorMessage || localErrorMessage}</ErrorMessage>
        )}

        {showSubmitButton && (
          <SubmitBtn onClick={handleSubmit}>
            Fazer oferta {makeOfferLoading && <Image ml={1} src={loading} width="20" />}
          </SubmitBtn>
        )}
      </Container>
    </ClickOutside>
  );
}

function mapStateToProps({ authentication, ui, auctions }) {
  return {
    bidErrorMessage: auctions.bidErrorMessage,
    makeOfferLoading: ui.makeOfferLoading,
    bidSuccess: auctions.bidSuccess,
    authUser: authentication.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectAuction: bindActionCreators(setSelectedAuction, dispatch),
    makeBid: bindActionCreators(makeBid, dispatch),
    toggle: bindActionCreators(toggleModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MakeOfferQuick);
