import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

const textFieldRawStyles = css`
  appearance: none;
  border: 1px solid
    ${props =>
      props.hasError
        ? themeGet('colors.alerts.Ferrari')(props)
        : themeGet('colors.neutrals.EclipseE500')(props)};
  border-radius: ${themeGet('radii.fields')};
  font-size: 1rem;
  padding: 0.5rem 0.35rem;
  font-family: ${themeGet('fonts.paragraphs')};
  margin: 0;
  background: #ffffff;
  width: 100%;
  display: block;
`;

const TextFieldStyles = styled.input.attrs(props => ({
  type: props.type || 'text',
}))`
  ${textFieldRawStyles};
`;

export { textFieldRawStyles, TextFieldStyles };
