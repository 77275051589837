import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import { Card, Text, Flex, Box } from '../../../abstract';
import LastBid from './LastBid';

export const NegotiationCallout = ({ price }) => {
  return (
    <Card variant="flat" bg="neutrals.EclipseE60" p={rem(12)}>
      <Flex alignItems="center" color="neutrals.EclipseE500">
        <Text as="p" fontSize={1} width={7 / 12} mb={0} pr={1}>
          Estamos negociando, te avisaremos assim que tivermos uma resposta.
        </Text>
        <Box width={5 / 12}>
          <LastBid label="Seu último lance" price={price} />
        </Box>
      </Flex>
    </Card>
  );
};

NegotiationCallout.propTypes = {
  price: PropTypes.string.isRequired,
};
