import styled from 'styled-components';
import { themeGet } from 'styled-system';

import { Text } from '../../Text';

export const ErrorMessage = styled(Text).attrs(() => ({
  color: 'alerts.Ferrari',
  mt: '0.5rem',
  fontSize: 1,
}))``;

export const LabelStyles = styled.label`
  color: ${themeGet('colors.neutrals.EclipseE800')};
  display: inline-block;
  margin-bottom: 0.5rem;
`;

export const FormTextStyles = styled.div`
  color: ${themeGet('colors.neutrals.EclipseE500')};
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  line-height: 1.2;
`;
