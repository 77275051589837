import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { media } from '../../../../design-system/utils';
import { Box, Text, Flex, HorizontalLine, Badge, TabConsumer } from '../../../../components';

const StyledTab = styled(Box)`
  cursor: pointer;
  flex-shrink: 0;
  ${media.md`
    flex-shrink: 1;
  `};
`;
const StyledBadge = styled(Badge)`
  ${media.md`
    display: none;
  `};
`;

export const DetailsTab = props => (
  <TabConsumer>
    {({ tabs, onClick, activeTabId }) => (
      <Flex mb={1} {...props}>
        {tabs &&
          tabs.map(({ id, title, tabProps = {} }) => (
            <StyledTab
              onClick={onClick(id)}
              key={title}
              pb={{ sm: 0, md: 1 }}
              mb={1}
              mr={{ sm: 1, md: 100 }}
            >
              <Text
                color={activeTabId === id ? 'neutrals.EclipseE800' : 'neutrals.EclipseE400'}
                fontSize={3}
                fontWeight="paragraphs.bold"
                open={activeTabId === id}
                my={rem(11)}
                display="flex"
              >
                {title}
                <StyledBadge
                  color="white"
                  bg={activeTabId === id ? tabProps.color : 'neutrals.EclipseE400'}
                  size={30}
                  ml={1}
                >
                  {tabProps.counter}
                </StyledBadge>
              </Text>
              <HorizontalLine
                bg={activeTabId === id ? tabProps.color : 'white'}
                width="70%"
                my={0}
              />
            </StyledTab>
          ))}
      </Flex>
    )}
  </TabConsumer>
);
