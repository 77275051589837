import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OnClickOutside from 'react-click-outside';
import { MdAccountCircle } from 'react-icons/md';

import { Box } from '../abstract';
import { Menu } from './Menu';
import { Backdrop } from './Backdrop';
import { Badge } from '../StyledBadge';

export class SideMenu extends Component {
  static propTypes = {
    children: PropTypes.any,
    showNotificationDot: PropTypes.bool,
  };

  state = {
    opened: false,
    showNotificationDot: false,
  };

  toggle = () => {
    this.setState(state => ({
      opened: !state.opened,
    }));
  };

  close = () => {
    const { opened } = this.state;
    if (opened) {
      this.setState({ opened: false });
    }
  };

  render() {
    const { children, ...props } = this.props;
    const { opened } = this.state;

    return (
      <Box position={{ sm: 'initial', md: 'relative' }} alignSelf="center" {...props}>
        {opened && <Backdrop />}

        <OnClickOutside onClickOutside={this.close}>
          <Badge
            variant="dot"
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            invisible={!props.showNotificationDot}
          >
            <Box
              as={MdAccountCircle}
              size={32}
              style={{ alignSelf: 'center', cursor: 'pointer' }}
              onClick={this.toggle}
              ml={1}
            />
            <Menu close={this.close} {...this.state}>
              {children}
            </Menu>
          </Badge>
        </OnClickOutside>
      </Box>
    );
  }
}
