import User from '../models/user.model';

export class UserFactory {
  static CreateUser(userData) {
    const user = new User();
    user.id = userData.userId;
    user.firstName = userData.firstName;
    user.lastName = userData.lastName;
    user.dealershipName = userData.dealershipName;
    user.email = userData.email;
    user.exp = userData.exp;
    user.permissions = userData.permissions;
    user.pictureUrl = userData.pictureUrl;
    user.role = userData.role;
    user.username = userData.username;
    user.carsBoughtLastMonth = userData.carsBoughtLastMonth || 0;
    user.status = userData.status || '';
    user.phone = userData.profile ? userData.profile.phone : null;
    return user;
  }
}
