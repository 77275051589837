import Types from './types';

const INITIAL_STATE = {
  enabled: false,
  payload: {},
};

export function tooltip(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.TOOLTIP_ENABLED:
      return { ...state, enabled: true, payload: action.payload };
    case Types.TOOLTIP_DISABLED:
      return { ...state, enabled: false, payload: {} };
    default:
      return state;
  }
}
