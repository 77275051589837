import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const VERTICAL_ORIENTATION = 'vertical';
export const VERTICAL_SCROLLABLE = 'verticalScrollable';

export const dateRangePickerPropTypes = {
  autoFocusEndDate: PropTypes.bool,
  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
};

export const dayPickerRangeControllerPropTypes = {
  noBorder: PropTypes.bool,
  keepOpenOnDateSelect: PropTypes.bool,
  renderKeyboardShortcutsButton: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  onDatesChange: PropTypes.func.isRequired,
  startDateOffset: PropTypes.func,
  endDateOffset: PropTypes.func,
  minDate: momentPropTypes.momentObj.isRequired,
  maxDate: momentPropTypes.momentObj.isRequired,
  daySize: PropTypes.number,

  minimumNights: PropTypes.number,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: PropTypes.oneOf([HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION, VERTICAL_SCROLLABLE]),
  verticalHeight: PropTypes.number,
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,
  renderMonthElement: PropTypes.func,
  renderMonthText: PropTypes.func,
  hideKeyboardShortcutsPanel: PropTypes.bool,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool,
};
