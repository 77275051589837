import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { PrimaryButton } from '../../Buttons/old';

import { AiOutlineQuestionCircle, AiFillCloseCircle } from 'react-icons/ai';
import { RiArrowRightSLine, RiArrowUpSLine } from 'react-icons/ri';

import AlertIcon from '../../../images/icons/automaticOfferAlertIconParticipate.svg';
import ChangeBidIcon from '../../../images/icons/changeBid.svg';
import { AUTO_BID_ERRORS } from '../../../utils/autobid-enums';

import {
  Container,
  Header,
  Title,
  LastOfferLabel,
  LastOfferAndEditionsContainer,
  AutoBidLastOfferValue,
  RemainingEditionsContainer,
  AlertIconImg,
  RemainingEditionsLabel,
  ChangeIcon,
  RemoveAndMoreOptionsContainer,
  RemoveLimitContainer,
  MoreOptions,
  MoreOptionsWrapper,
  MoreOptionsContent,
} from './styles';
import BidInput from '../../Inputs/BidInput';
import { bindActionCreators } from 'redux';
import { setSelectedAuction } from '../../../store/actions/auction.actions';
import { toggleModal } from '../../../store/actions/ui.actions';
import { useTimingAuctionContext } from '../../../context/TimingAuctionContext';
import {
  AUCTION_BUY_FOR,
  AUCTION_BUY_KEY,
  AUCTION_OFFER_KEY,
  MORE_OPTIONS_KEY,
  REMOVE_AUTOMATIC_OFFER_KEY,
} from '../../Modals';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Temperature } from '../../Temperature';
import Tooltip from '../../Tooltips/TooltipMessage';
import { editionAutoBidVehicle } from '../../../store/autobid/effects';
import { useAutoBid } from '../../../hooks/useAutoBid';
import { toAmount, toPrice } from '../../../utils/number';

import Types from '../../../store/autobid/types';
import { ErrorMessage } from '../../Modals/AutoBid/AutomaticOffer/styles';
import { useSegment } from '../../../hooks/useSegment';
import { Button } from '../../Buttons';
import { formatPrice } from '../../../utils/formatPrice';

const AutoBidAction = ({
  auction,
  selectAuction,
  toggleModal,
  detailsVehicle = false,
  editionAutoBidVehicle,
  isFirstChild,
}) => {
  const dispatch = useDispatch();
  const isDetails = window.location.pathname.includes('detalhes');

  const { milisecondsLeft } = useTimingAuctionContext();
  const { user } = useSelector(state => state.authentication);
  const { error } = useSelector(state => state.autoBid);

  const [changeMaximumBidInput, setChangeMaxinumBidInput] = useState(false);
  const [automaticBidBudget, setAutomaticBidBudget] = useState();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState('');
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState(window.screen.width);

  const {
    currentAutoBidConfigured,
    maximumAutoBidBudget,
    currentUpdateLimit,
    tieWithEdits,
    losingWithEdits,
  } = useAutoBid(auction._id);

  const { trackButtonClicked } = useSegment();

  const { trackChangeMaximumAutoBid, trackReachedLimitAutoBid, trackTieAutoBid } = useSegment();

  const setSection = window.location.href.includes('/detalhes') ? 'details' : 'participate';

  const responsiveMoreOptionsLabel = useMemo(() => {
    if ((detailsVehicle && size > 1024) || (!detailsVehicle && size < 1025)) {
      return 'Mais Opções de Oferta';
    }

    return 'Mais Opções';
  }, [detailsVehicle, size]);

  const handleMoreOptionsModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggleModal({
      key: MORE_OPTIONS_KEY,
      show: true,
      extras: { auction, autobid: currentAutoBidConfigured, section: setSection },
    });
  }, [auction, toggleModal, milisecondsLeft, setSection]);

  const handleSingleOfferModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggleModal({
      key: AUCTION_OFFER_KEY,
      show: true,
    });
    trackButtonClicked(auction._id, 'singleBid', auction.auction.idAuction, setSection);
  }, [auction, toggleModal, milisecondsLeft, setSection, trackButtonClicked]);

  const handleNegotiateExclusivityModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggleModal({ key: AUCTION_BUY_KEY, show: true, extras: { section: setSection } });
    trackButtonClicked(auction._id, 'exclusivityBid', auction.auction.idAuction, setSection);
  }, [selectAuction, auction, milisecondsLeft, toggleModal]);

  const handleBuyForModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggleModal({ key: AUCTION_BUY_FOR, show: true, extras: { section: setSection } });
    trackButtonClicked(auction._id, 'buyForBid', auction.auction.idAuction, setSection);
  }, [selectAuction, auction, milisecondsLeft, toggleModal]);

  const handleRemoveAutomaticOfferModal = useCallback(() => {
    toggleModal({
      key: REMOVE_AUTOMATIC_OFFER_KEY,
      show: true,
      extras: {
        selectedAuctionId: auction._id,
        selectedAutoBidId: currentAutoBidConfigured?.autoBidId,
        selectedAuctionAuId: auction.auction.idAuction,
        section: setSection,
      },
    });
  }, [currentAutoBidConfigured, toggleModal, setSection, auction]);

  const handleOnSubmitEditionAutoBid = useCallback(async () => {
    if (!changeMaximumBidInput) {
      return setChangeMaxinumBidInput(true);
    }

    setLoading(true);

    const { update_limit, tie } = await editionAutoBidVehicle(
      currentAutoBidConfigured.autoBidId,
      toAmount(automaticBidBudget),
      user.id
    );

    trackChangeMaximumAutoBid(
      auction._id,
      currentAutoBidConfigured.autoBidId,
      toAmount(automaticBidBudget),
      currentAutoBidConfigured?.balance,
      setSection
    );

    if (update_limit === 2) {
      trackReachedLimitAutoBid(
        auction._id,
        currentAutoBidConfigured.autoBidId,
        auction.auction.idAuction,
        setSection
      );
    }

    if (tie) {
      trackTieAutoBid(
        auction._id,
        currentAutoBidConfigured.autoBidId,
        toAmount(automaticBidBudget),
        setSection
      );
    }

    dispatch({ type: Types.AUTOBID_FAILURE, error: false });
    setChangeMaxinumBidInput(false);
    setLoading(false);
  }, [
    auction,
    changeMaximumBidInput,
    editionAutoBidVehicle,
    currentAutoBidConfigured,
    automaticBidBudget,
    setSection,
    trackChangeMaximumAutoBid,
    trackReachedLimitAutoBid,
    trackTieAutoBid,
  ]);

  const hasExclusivity = auction?.summary?.buynowPrice?.enabled;
  const hasBuyFor = auction?.summary?.buyForPrice?.enabled;

  useEffect(() => {
    if (tieWithEdits || losingWithEdits) {
      return setChangeMaxinumBidInput(true);
    }
  }, [tieWithEdits, losingWithEdits]);

  useEffect(() => {
    if (!!error) {
      setHasError(error);
      setLoading(false);
    }
  }, [error]);

  window.addEventListener('resize', () => setSize(window.screen.width));

  return (
    <Container detailsVehicle={detailsVehicle} hasError={!!hasError || !!error}>
      <Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title detailsVehicle={detailsVehicle}>Oferta automática</Title>
          <Tooltip
            direction={
              !isFirstChild || window.screen.width <= 1024 || detailsVehicle ? 'top' : 'bottom'
            }
            content={
              <span>
                <strong>Oferta automática</strong>
                <br />
                <br />
                No automático, insira o seu valor
                <br /> máximo de orçamento e a plataforma
                <br /> dará lances automáticos por você, até
                <br /> chegar no limite estabelecido.
              </span>
            }
          >
            <AiOutlineQuestionCircle fill={detailsVehicle ? '#fff' : '#2f3741'} size={20} />
          </Tooltip>
        </div>
        <RemainingEditionsContainer>
          <RemainingEditionsLabel detailsVehicle={detailsVehicle}>
            {currentUpdateLimit}
          </RemainingEditionsLabel>
        </RemainingEditionsContainer>
      </Header>

      {changeMaximumBidInput ? (
        <>
          <BidInput
            label="Lance máximo"
            placeholder="Insira um limite maior"
            value={toPrice(automaticBidBudget)}
            handleMinusButton={() =>
              setAutomaticBidBudget(oldState =>
                toAmount(oldState) > auction?.auction?.winningBid?.amount
                  ? toAmount(oldState) - 250
                  : oldState
              )
            }
            handlePlusButton={() =>
              setAutomaticBidBudget(oldState => (oldState ? toAmount(oldState) + 250 : 250))
            }
            onChange={evt => setAutomaticBidBudget(toAmount(evt.target.value))}
            autobid
            detailsVehicle={detailsVehicle}
          />

          <LastOfferAndEditionsContainer hasError={!!hasError || !!error}>
            <Temperature.WithSlider.Participate
              auction={auction}
              currentValue={toAmount(automaticBidBudget)}
              style={{ width: '100%' }}
            />
          </LastOfferAndEditionsContainer>

          {!!hasError || !!error ? (
            <ErrorMessage style={{ margin: '0 0 1.25rem' }}>
              {AUTO_BID_ERRORS[error] || hasError}
            </ErrorMessage>
          ) : null}
        </>
      ) : (
        <>
          <LastOfferLabel detailsVehicle={detailsVehicle}>Seu lance máximo:</LastOfferLabel>
          <LastOfferAndEditionsContainer style={{ marginBottom: '20px' }}>
            <AutoBidLastOfferValue detailsVehicle={detailsVehicle}>
              {maximumAutoBidBudget}
            </AutoBidLastOfferValue>
          </LastOfferAndEditionsContainer>
          <div style={{ marginBottom: '10px' }}>
            <Temperature.Basic
              auction={auction}
              currentValue={currentAutoBidConfigured?.balance}
              isDetails={isDetails}
            />
          </div>
        </>
      )}

      {currentAutoBidConfigured?.updateLimit !== 2 && (
        <PrimaryButton
          onClick={handleOnSubmitEditionAutoBid}
          singleOffer
          modal
          isLoading={loading}
          disabled={
            changeMaximumBidInput
              ? toAmount(automaticBidBudget) <= auction?.auction?.winningBid?.amount ||
                toAmount(automaticBidBudget) === toAmount(currentAutoBidConfigured?.balance)
              : false
          }
          specificDisabled
        >
          {!changeMaximumBidInput && (
            <ChangeIcon src={ChangeBidIcon} alt="Quadrado transparente com um lápis dentro" />
          )}
          Alterar lance máximo
        </PrimaryButton>
      )}
      <RemoveAndMoreOptionsContainer detailsVehicle={detailsVehicle}>
        <RemoveLimitContainer
          detailsVehicle={detailsVehicle}
          onClick={handleRemoveAutomaticOfferModal}
        >
          <AiFillCloseCircle
            size={18}
            fill={detailsVehicle ? '#fff' : '#125d8a'}
            style={{ marginRight: '0.4rem' }}
          />
          Remover limite
        </RemoveLimitContainer>

        {size > 1024 || detailsVehicle ? (
          <MoreOptions detailsVehicle={detailsVehicle} onClick={() => handleMoreOptionsModal()}>
            {responsiveMoreOptionsLabel}
            <RiArrowRightSLine size={18} fill={detailsVehicle ? '#fff' : '#2f3741'} />
          </MoreOptions>
        ) : (
          <MoreOptionsWrapper>
            <MoreOptions onClick={() => setVisible(!visible)}>
              {responsiveMoreOptionsLabel}
              {visible ? (
                <RiArrowUpSLine size={18} fill="#2f3741" />
              ) : (
                <RiArrowRightSLine size={18} fill="#2f3741" />
              )}
            </MoreOptions>

            {visible && (
              <MoreOptionsContent>
                <Button hover="#4ab971" borderRadius={8} onClick={handleSingleOfferModal}>
                  Oferta avulsa
                </Button>

                {hasBuyFor && (
                  <Button fontWeight={400} color="#000" bg="#f2c94c" onClick={handleBuyForModal}>
                    Compra garantida por{' '}
                    <strong>R$ {formatPrice(auction?.summary?.buyForPrice?.amount)}</strong>
                  </Button>
                )}

                {!hasBuyFor && hasExclusivity && (
                  <Button
                    fontWeight={400}
                    bg="#ff4c00"
                    hover="#ff7941"
                    onClick={handleNegotiateExclusivityModal}
                  >
                    Negocie a partir de{' '}
                    <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
                  </Button>
                )}
              </MoreOptionsContent>
            )}
          </MoreOptionsWrapper>
        )}
      </RemoveAndMoreOptionsContainer>
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  selectAuction: bindActionCreators(setSelectedAuction, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  editionAutoBidVehicle: bindActionCreators(editionAutoBidVehicle, dispatch),
});

export default connect(null, mapDispatchToProps)(AutoBidAction);
