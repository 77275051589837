import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import configureStore from './store/configureStore';
import './styles/styles.scss';
import AppConstants from './constants/appConstants';

require('./favicon.ico');

Sentry.init({
  dsn: AppConstants.Sentry,
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
});

const store = configureStore();
const rootElement = document.getElementById('app');

render(
  <AppContainer>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </AppContainer>,
  rootElement
);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default;
    render(
      <AppContainer>
        <Provider store={store}>
          <Router>
            <NewApp store={store} history={history} />
          </Router>
        </Provider>
      </AppContainer>,
      rootElement
    );
  });
}
