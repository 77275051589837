import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ICCheckedIcon, ICCheckbox, BtnWrapper, OutlineBtn, SubmitBtn, Option } from './styles';
import { formatAuctionObjectKeys, getParticipatingAuctions } from '../../../utils/auction';
import { PARTICIPATING_OPTIONS } from '../../../context/AuctionFilters/filter-enums';
import { applyParticipatingFilters } from '../../../utils/filter';
import image from '../../../images/icons/check-icon-white.svg';
import { getCurrentElapsed } from '../../../utils/dateUtils';
import { getAuctions } from '../../../services/auctions';
import {
  fetchedParticipatingAuctions,
  handleFilters,
} from '../../../store/actions/auction.actions';

function ParticipatingFilters({
  participatingFilters,
  setFilters,
  user,
  setParticipating,
  relatedDealers,
}) {
  const [values, setValues] = useState([]);

  useEffect(
    function() {
      setValues([...participatingFilters]);
    },
    [participatingFilters]
  );

  function handleChange(event) {
    const optionStatus = event.target.checked;
    let options = [...values];
    if (optionStatus) {
      options.push(event.target.value);
    } else {
      options = options.filter(option => option !== event.target.value);
    }
    event.persist();
    setValues(options);
  }

  async function handleSubmit() {
    try {
      setFilters({
        participating: values,
        showParticipatingLoading: true,
        showParticipatingFilters: false,
      });

      // Get auctions
      let auctions = await getAuctions();
      if (auctions.length < 1)
        throw Error('Não foi encontrado leilões para os filtros selecionados');

      // Format values
      const requestStartTime = getCurrentElapsed();
      auctions = auctions.map(auction =>
        formatAuctionObjectKeys(auction, requestStartTime, user, relatedDealers)
      );

      // Get participating auctions only
      let participating = getParticipatingAuctions(auctions);

      // Apply filters
      participating = applyParticipatingFilters(values, participating);
      if (participating.length < 1)
        throw Error('Não foi encontrado leilões para os filtros selecionados');

      // Set redux
      setParticipating(participating);
    } catch (e) {
      setFilters({ participatingErrors: e.message });
    } finally {
      setFilters({ showParticipatingLoading: false });
    }
  }

  async function cleanFilters() {
    try {
      setFilters({
        participating: Array(),
        showParticipatingLoading: true,
        showParticipatingFilters: false,
      });

      // Get auctions
      let auctions = await getAuctions();

      // Format values
      const requestStartTime = getCurrentElapsed();
      auctions = auctions.map(auction =>
        formatAuctionObjectKeys(auction, requestStartTime, user, relatedDealers)
      );

      // Get participating auctions only
      let participating = getParticipatingAuctions(auctions);

      // Set redux
      setParticipating(participating);
    } catch (e) {
      setFilters({ participatingErrors: e.message });
    } finally {
      setFilters({ showParticipatingLoading: false });
    }
  }

  function isChecked(value) {
    return values.find(v => v === value) ? true : false;
  }

  return (
    <div>
      {PARTICIPATING_OPTIONS.map((option, key) => (
        <Option key={key}>
          <FormControlLabel
            sx={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: 16,
              color: '#757778',
              marginLeft: 0,
            }}
            control={
              <Checkbox
                checked={isChecked(option.value)}
                onChange={handleChange}
                value={option.value}
                checkedIcon={<ICCheckedIcon bg={image} />}
                icon={<ICCheckbox />}
              />
            }
            label={option.label}
          />
        </Option>
      ))}
      <BtnWrapper>
        <SubmitBtn onClick={handleSubmit}>Aplicar filtros</SubmitBtn>
        <OutlineBtn onClick={cleanFilters}>Limpar filtros</OutlineBtn>
      </BtnWrapper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    participatingFilters: state.ui.filters.participating,
    user: state.authentication.user,
    relatedDealers: state.user.relatedDealers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFilters: bindActionCreators(handleFilters, dispatch),
    setParticipating: bindActionCreators(fetchedParticipatingAuctions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipatingFilters);
