import React, { useState, useEffect } from 'react';
import { useMemo } from 'react';
import { connect } from 'react-redux';

import FilterIcon from '../../../../images/icons/filter-icon.svg';
import { cleanFilterValues } from '../../../../utils/filter';
import { Container, Badge } from './styles';

function FilterButton({
  handleAction,
  tab = 0,
  participatingFilters,
  newOffersFilters,
  hasNewOffersFiltered,
  showNewOffersFilters,
  showParticipatingFilters,
}) {
  const [numFilters, setNumFilters] = useState(8);

  useEffect(function() {
    const count = tab === 0 ? countNewOffersFilters() : countParticipatingFilters();
    setNumFilters(count);
  });

  const showNumFilters = useMemo(
    function() {
      if (tab === 0 && hasNewOffersFiltered) return true;
      if (tab === 1 && countParticipatingFilters() > 0) return true;
      return false;
    },
    [hasNewOffersFiltered, showNewOffersFilters, showParticipatingFilters, tab]
  );

  function countNewOffersFilters() {
    const cleanedFilters = cleanFilterValues(newOffersFilters);
    return Object.keys(cleanedFilters).length;
  }

  function countParticipatingFilters() {
    return participatingFilters.length;
  }

  return (
    <Container width={numFilters > 0 ? 90 : 51} onClick={handleAction}>
      <img className="icon" src={FilterIcon} alt="ícone" />
      <span className="label">Filtrar</span>
      {showNumFilters && <Badge>{numFilters}</Badge>}
    </Container>
  );
}

function mapStateToProps({ ui }) {
  return {
    participatingFilters: ui.filters.participating,
    newOffersFilters: ui.filters.newOffers,
    hasNewOffersFiltered: ui.filters.newOffersFiltersApplyed,
    showNewOffersFilters: ui.filters.showNewOffersFilters,
    showParticipatingFilters: ui.filters.showParticipatingFilters,
  };
}

export default connect(mapStateToProps)(FilterButton);
