import React from 'react';
import Downshift from 'downshift';
import { themeGet } from 'styled-system';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowDown, MdCheck } from 'react-icons/md';

import { Box, Flex } from './abstract';
import * as colors from '../design-system/colors';

const variantStyles = props => {
  switch (props.variant) {
    case 'primary':
      return css`
        font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
        color: #fff;

        background-color: ${colors.primaries.Soul};
        border-radius: 9px;
      `;
    default:
      return '';
  }
};

const Input = styled(Flex)`
  min-height: 2.3rem;
  padding: 0.5em 0.5em 0.5em 0.7em;

  color: inherit;
  background-color: transparent;
`;

const NativeWrapper = styled(Box)`
  position: relative;
  display: none;

  ${variantStyles};

  @media only screen and (max-width: 768px) {
    display: block;
  }

  select {
    width: 100%;
    min-height: 2.3rem;

    padding: 0.5em 1.5em 0.5em 0.5em;

    color: currentColor;

    background-color: transparent;
    border: none;
    border-radius: 4px;

    -webkit-appearance: none;
    -moz-appearance: none;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0.5rem;

    pointer-events: none;
    transform: translateY(-50%);
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Options = styled(Box)`
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  left: 0;

  display: none;
  min-width: 10rem;

  line-height: 1.5em;

  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

  overflow: hidden;
  z-index: 10;

  ${({ show }) => show && 'display: block'}
`;

const Wrapper = styled(Box)`
  position: relative;
  width: 55%;
  cursor: pointer;

  ${variantStyles};

  @media screen and (min-width: 1280px) and (max-width: 1441px) {
    width: calc(100% - 3.125rem);
  }

  @media screen and (max-width: 1280px) {
    width: 88%;
  }

  @media screen and (max-width: 1025px) {
    width: 50%;
    margin-right: 1.25rem;
  }
`;

const Option = styled(Flex)``;

const LabelContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const LabelIcon = styled.span`
  margin-right: 0.5rem;

  display: flex;
  align-items: center;
`;

const Label = styled.span`
  width: 100%;

  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #fff;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const getHightlightedItemStyles = (isHighlighted, isSelected, variant) => {
  const highlightStyles = {
    backgroundColor: colors.primaries.Soul,
    color: '#ffffff',
  };
  if (isSelected && variant === 'primary') return highlightStyles;

  if (!isHighlighted)
    return {
      backgroundColor: 'transparent',
    };

  if (variant === 'primary') {
    return highlightStyles;
  } else {
    return {
      backgroundColor: colors.neutrals.EclipseE60,
    };
  }
};

export const Select = ({
  options,
  placeholder = 'Choose an Option',
  onChange,
  variant,
  hideTick,
  listProps,
  ...etc
}) => (
  <>
    <Downshift itemToString={item => (item ? item.label : '')} onChange={onChange} {...etc}>
      {({
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        getRootProps,
        selectedItem,
        highlightedIndex,
      }) => {
        return (
          <Wrapper {...getRootProps()} variant={variant}>
            <Input {...getToggleButtonProps()} justifyContent="space-between" alignItems="center">
              {selectedItem ? (
                <LabelContainer>
                  <LabelIcon>{selectedItem.icon}</LabelIcon>
                  <Label>{selectedItem.label}</Label>
                </LabelContainer>
              ) : (
                <Label css={{ opacity: '0.3' }}>{placeholder}</Label>
              )}
              <Box as={MdKeyboardArrowDown} size={24} ml={1} />
            </Input>

            <Options {...getMenuProps()} show={isOpen} {...listProps}>
              {options &&
                isOpen &&
                options.map((item, index) => (
                  <Option
                    py={2}
                    px={1}
                    key={item.key ?? item.label}
                    fontSize={1}
                    alignItems="center"
                    color="primaries.Soul"
                    variant={variant}
                    style={{
                      ...getHightlightedItemStyles(
                        highlightedIndex === index,
                        selectedItem === item,
                        variant
                      ),
                    }}
                    {...getItemProps({ item })}
                  >
                    <LabelIcon>{item.icon}</LabelIcon>
                    {item.label}
                    {!hideTick && selectedItem === item && (
                      <Box as={MdCheck} color="secondaries.Uno" ml={1} />
                    )}
                  </Option>
                ))}
            </Options>
          </Wrapper>
        );
      }}
    </Downshift>
    <NativeWrapper
      onChange={e => {
        onChange(options[e.target.value]);
      }}
      variant={variant}
    >
      <Box as="select" defaultValue={etc.selectedItem ? options.indexOf(etc.selectedItem) : 0}>
        {options &&
          options.map((item, i) => (
            <option key={item.key ?? item.label} value={i}>
              {item.label}
            </option>
          ))}
      </Box>
      <Box as={MdKeyboardArrowDown} size={18} ml={1} />
    </NativeWrapper>
  </>
);
