import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 25px;
  left: 50%;

  display: ${({ show }) => (show ? 'grid' : 'none')};
  grid-template-columns: 1fr auto;

  width: 90%;
  min-height: 48px;

  padding: 11px 22px;
  margin-left: -45%;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.27);

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .success-text {
      margin-right: 29px;

      font: 700 normal 1rem/1em 'Roboto', sans-serif;
      color: #2f3741;
    }
  }

  .close-btn {
    display: flex;
    align-items: center;

    font-size: 1.875rem;
    color: #2f3741;

    background-color: transparent;
    border: none;

    cursor: pointer;
  }
`;
