import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { setShowed } from '../../utils/freeShippingDialog';
import { Container, Content } from './styles';

import PaymentIcon from '../../images/icons/paymentIcon.svg';

function FlexDialog({ variant = 'success', title = '', children, show }) {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  function closeDialog() {
    setShowDialog(false);
    setShowed();
  }

  return (
    <Container className={showDialog ? 'showDialog' : ''}>
      <Container.Header variant={variant}>
        <span className="closeBtn" onClick={closeDialog} style={{ cursor: 'pointer' }}>
          <MdClose color="#2F3741" />
        </span>
      </Container.Header>
      <Container.Body>
        <Content>
          <img src={PaymentIcon} />
          <Container.Body.Title variant={variant}>{title}</Container.Body.Title>
        </Content>
        {children}
      </Container.Body>
    </Container>
  );
}

export default FlexDialog;
