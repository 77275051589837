import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../..';
import { UndecoratedLink } from '../../ui';

export const Title = ({ title, linkTo }) => {
  return (
    <Heading level={3} color="neutrals.EclipseE800" fontSize={3} mb={1}>
      {linkTo ? (
        <UndecoratedLink
          fontWeight="headings.medium"
          lineHeight="headings"
          fontFamily="headings"
          to={linkTo}
        >
          {title}
        </UndecoratedLink>
      ) : (
        title
      )}
    </Heading>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
};
