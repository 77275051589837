import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Button.scss';

const Button = ({
  className,
  warning,
  outline,
  label,
  children,
  loading,
  packed,
  clean,
  name,
  rounded,
  ...props
}) => {
  const classNames = classnames('button', className, {
    warning,
    outline,
    label,
    loading,
    packed,
    clean,
    rounded,
  });

  return (
    <button className={classNames} data-name={name} {...props}>
      {children}
    </button>
  );
};

export const ButtonPropTypes = {
  className: PropTypes.string,
  warning: PropTypes.bool,
  outline: PropTypes.bool,
  label: PropTypes.bool,
  children: PropTypes.node,
  loading: PropTypes.bool,
  packed: PropTypes.bool,
  clean: PropTypes.bool,
  name: PropTypes.string,
  rounded: PropTypes.bool,
};

Button.propTypes = ButtonPropTypes;

Button.defaultProps = {
  className: '',
  loading: false,
};

export default Button;
