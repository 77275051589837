import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 1.25rem 1.063rem;
`;

export const Title = styled.h1`
  margin-bottom: 0.75rem;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const Description = styled.p`
  font: 400 normal 0.875rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;
