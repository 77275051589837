import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Flex } from '../../abstract';

const ProgressWrapper = styled(Flex)`
  display: flex;
  align-items: center;

  width: ${props => (props.detailVehicle ? '30' : '100')}%;
  height: 0.175rem;

  background-color: #e1e1e1;
  border-radius: 0.125rem;

  overflow: hidden;

  ${({ participate }) =>
    participate &&
    css`
      width: 75%;

      @media screen and (max-width: 1025px) {
        width: 100%;
      }
    `}

  @media screen and (max-width: 1025px) {
    ${({ detailVehicle }) =>
      detailVehicle &&
      css`
        width: 100%;
      `}
  }
`;

const Progress = styled(Flex)`
  height: 0.175rem;

  border-radius: 0.125rem;
  transition: width 0.6s ease;
`;

export const ProgressBar = ({
  progress = 0,
  progressColor = '#2274a5',
  detailVehicle,
  participate,
  ...props
}) => {
  return (
    <ProgressWrapper
      detailVehicle={detailVehicle}
      participate={participate}
      bg="neutrals.EclipseE60"
      height={rem(8)}
      {...props}
    >
      <Progress
        bg={progressColor}
        width={`${progress}%`}
        justifyContent="center"
        flexDirection="column"
      />
    </ProgressWrapper>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  progressColor: PropTypes.string,
};
