import React from 'react';
import { Tag, TagText, Container } from './styles';

import FincreditCoin from '../../images/icons/fincredit-coin.svg';

export function TagFincredit() {
  return (
    <Container>
      <Tag>
        <img src={FincreditCoin} alt={'fincredit icon'} />
        <TagText>Pagamento facilitado disponível</TagText>
      </Tag>
    </Container>
  );
}
