import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { createPortal } from '../../../../hoc';
import { closeModal } from '../../../../../store/actions/ui.actions';
import { noop, exclude } from '../../../../../utils/misc';
import { withModal } from './hoc';

import { Header } from '../header';
import { Body } from '../body';
import { Footer } from '../footer';

import './styles.scss';

// Create the portal where the modal will be appended to.
export const Portal = createPortal('modal-root');

class Modal extends React.Component {
  getChildContext() {
    return {
      modal: this,
    };
  }

  dismiss = () => {
    this.props.dismiss(this.props.modalKey);
  };

  extras = () => this.props.extras;

  render() {
    const { className, active, showBackdrop, children, ...others } = this.props;
    const classNames = classnames('c-modal', className);
    const props = exclude(others, 'dismiss', 'modalKey', 'dispatch');

    return active ? (
      <Portal>
        <div className={classNames} {...props}>
          {showBackdrop && <div className="c-modal__backdrop" />}
          <div className="c-modal__content">{children}</div>
        </div>
      </Portal>
    ) : null;
  }
}
Modal.propTypes = {
  modalKey: PropTypes.string.isRequired,
  showBackdrop: PropTypes.bool,
  active: PropTypes.bool,
  extras: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  dismiss: PropTypes.func,
};
Modal.defaultProps = {
  showBackdrop: true,
  dismiss: noop,
};
Modal.childContextTypes = {
  modal: PropTypes.any,
};

Modal.Header = withModal(Header);
Modal.Body = Body;
Modal.Footer = Footer;

const mapStateToProps = ({ ui }, props) => ({
  active: ui.modals[props.modalKey].show,
  extras: ui.modals[props.modalKey].extras,
});
const mapDispatchToProps = dispatch => ({
  dismiss: bindActionCreators(closeModal, dispatch),
});
const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(Modal);

export { Modal, ModalContainer };
