import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { rem } from 'polished';

import { Text, Flex, Box } from '../../abstract';
import { fractionToPercentage } from '../../../utils/number';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useTimingAuctionContext } from '../../../context/TimingAuctionContext/index';

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

const BlinkText = styled.span`
  ${props =>
    props.isBlinking &&
    css`
      animation: ${blink} 1s linear infinite;
    `};
`;

const ChronometerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1025px) {
    ${({ detailVehicle, participate }) =>
      (detailVehicle || participate) &&
      css`
        width: ${({ participationActive }) => (participationActive ? '80%' : '100%')};
        margin-bottom: 0;
      `}
  }
`;

const ChronometerTexts = styled.div`
  display: flex;
  flex-direction: ${props => (props.participate || props.detailVehicle ? 'column' : 'row')};
  align-items: ${props => (props.participate || props.detailVehicle ? 'flex-start' : 'center')};
  justify-content: ${props =>
    props.participate || props.detailVehicle ? 'flex-start' : 'space-between'};

  width: 100%;

  @media screen and (max-width: 1025px) {
    ${({ detailVehicle, participate }) =>
      (detailVehicle || participate) &&
      css`
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      `}
  }
`;

const ChronometerLabel = styled.span`
  margin-bottom: ${props => (props.participate || props.detailVehicle ? '0.313rem' : '0')};

  font: 400 normal 0.75rem/1em 'Roboto', sans-serif;
  color: ${props => (props.detailVehicle ? '#fff' : '#737171')};

  ${({ participate, detailVehicle }) =>
    (participate || detailVehicle) &&
    css`
      font-size: 0.875rem;
    `}
`;

const ChronometerCount = styled.span`
  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: ${props => (props.detailVehicle ? '#fff' : '#2274a5')};

  ${({ participate, detailVehicle }) =>
    (participate || detailVehicle) &&
    css`
      font-size: 1.125rem;
    `}
`;

export const ProgressTimer = ({
  labelProps = {},
  timeProps = {},
  progressBarProps = {},
  reverse,
  showProgressBar = true,
  participate,
  detailVehicle,
  participationActive,
}) => {
  const {
    formattedRemainingTime,
    secondsLeft,
    isBlinking,
    isStop,
    totalSeconds,
  } = useTimingAuctionContext();

  const progress = fractionToPercentage(secondsLeft, totalSeconds);

  return (
    <ChronometerContainer
      participate={participate}
      participationActive={participationActive}
      detailVehicle={detailVehicle}
    >
      <Flex order={reverse ? 1 : 0} mb={reverse ? 0 : 1} alignItems="center" flexWrap="wrap">
        <ChronometerTexts detailVehicle={detailVehicle} participate={participate}>
          <ChronometerLabel detailVehicle={detailVehicle} participate={participate}>
            Tempo restante
          </ChronometerLabel>

          <ChronometerCount detailVehicle={detailVehicle} participate={participate}>
            <BlinkText isBlinking={isBlinking}>{isStop ? '---' : formattedRemainingTime}</BlinkText>
          </ChronometerCount>
        </ChronometerTexts>
      </Flex>

      {showProgressBar && (
        <Box order={reverse ? 0 : 1} mb={reverse ? 1 : 0}>
          <ProgressBar
            progress={progress > 0 && progress < 2 ? 2 : progress}
            detailVehicle={detailVehicle}
            participate={participate}
            {...progressBarProps}
          />
        </Box>
      )}
    </ChronometerContainer>
  );
};
