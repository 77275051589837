import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1.75rem 1.375rem;
`;

export const VehicleNameAndInfos = styled.span`
  margin-bottom: 0.5rem;

  font: 700 normal 1rem/1.281em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const NegotiationExclusivityLabel = styled.span`
  margin-bottom: 1rem;

  font: 500 normal 1rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const NegotiationExclusivityValue = styled.span`
  padding: 0.813rem 5.313rem;
  margin-bottom: 0.75rem;

  font: 900 normal 1.188rem/1em 'Roboto', sans-serif;
  color: #2f3741;
  text-align: center;

  background-color: #f0f7fd;
  border: 1px solid #eaebec;
  border-radius: 0.3rem;
`;

export const ContentFin = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  height: 63%;
  padding: 1.1rem 1.375rem;
`;

export const TitleFinDiv = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: start;
`;

export const TitleFinText = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  padding-left: 6px;
`;

export const FinText = styled.div`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 21.31px;
  letter-spacing: 0.01em;
  text-align: left;
`;

export const FinTextDiv = styled.div`
  padding-top: 5px;
`;

export const FinancingInfoDiv = styled.div`
  padding-top: 7px;
  padding-bottom: 10px;
`;

export const EntryValueText = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

export const EntryValue = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;

export const TaxesTotalDiv = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

export const style = {
  content: {
    position: 'relative',
    maxWidth: '340px',
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
