import React from 'react';
// import Modal from 'react-modal';
import Modal from '../../enhanced/Modal';
// import Modal from 'react-modal';
import _ from 'lodash';
import icon from '../../../images/icons/warning.svg';

import { customStyles, WarningIcon, Title, Text, Button } from './styles';

function CategoryText() {
  return (
    <Text>
      Não é possível utilizar a <strong>busca por categorias</strong> juntamente com o{' '}
      <strong>
        filtro. Ao selecionar opções de filtros, as categorias que estão selecionadas serão
        desmarcadas.
      </strong>{' '}
      Mas fique tranquilo, você poderá voltar a buscar por categorias quando quiser.
    </Text>
  );
}

function FilterText() {
  return (
    <Text>
      Não é possível utilizar o <strong>filtro</strong> juntamente com a{' '}
      <strong>
        busca por categorias. Ao selecionar categorias, os filtros que estão selecionados serão
        desmarcados.
      </strong>{' '}
      Mas fique tranquilo, você poderá voltar utilizar o filtro quando quiser.
    </Text>
  );
}

export function CannotSelectWithActiveCategoriesModal({ isOpen, onToggle, type }) {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Example Modal"
      toggle={onToggle}
      show={isOpen}
      buyNowSpecificStyle={customStyles}
    >
      <WarningIcon src={icon} />
      <Title>Importante</Title>
      {type === 'category' && <CategoryText />}
      {type === 'filter' && <FilterText />}
      <Button onClick={onToggle} style={{ cursor: 'pointer' }}>
        Entendi
      </Button>
    </Modal>
  );
}
