import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useTempNotification() {
  const { authentication: user, auctions } = useSelector(state => state);
  const { email } = useSelector(state => state.authentication.user);

  const currentTime = new Date().getTime();

  const newOffers = useMemo(
    () =>
      auctions.allAuctions.filter(
        al =>
          (al.auction.expectedEndTime - currentTime) / 1000 / 60 < 15 &&
          (al.auction.expectedEndTime - currentTime) / 1000 / 60 > 0 &&
          al.auction.participating &&
          (al.auction.bids.some(
            bid => bid?.bidder?.email === email || bid?.dealer?.email === email
          ) ||
            al?.auction?.autoBids[al.auction.autoBids.length - 1]?.autoBidStatus === 'ON') &&
          (al?.auction?.winningBid?.bidder?.email === email ||
            al?.auction?.winningBid?.dealer?.email === email) &&
          al.auction.bids[al.auction.bids.length - 1].amount < al.summary.vehiclePricing.hotTarget
      ),
    [auctions.allAuctions, currentTime, email]
  );

  return newOffers;
}
