import moment from 'moment';
import AppConstants from '../../../constants/appConstants';

function getUserCnpj(user) {
  const taxIds = user?.companyInfo?.taxIdentificationNumbers || [];
  return taxIds.find(taxId => taxId.type === AppConstants.TaxIdType.CNPJ);
}

export class UserProfileDTO {
  constructor(profile, { ttl } = {}) {
    this.companyPhone = profile?.companyPhone || '';
    this.phoneNumber = profile?.phoneNumber || '';
    this.dealershipName = profile?.dealershipName || '';
    this.cnpj = profile?.cnpj || '';
    this.email = profile?.email || '';
    this.id = profile?._id || '';
    this.updatedAt = profile?.updatedAt;
    this.ttl = ttl || 120;
  }

  cloneAndUpdate(updates) {
    const updatedProfile = new UserProfileDTO(this);
    updatedProfile._update(updates);
    return updatedProfile;
  }

  get isOutdated() {
    if (!this.updatedAt) return true;

    const today = moment();
    const expirationDate = moment(this.updatedAt).add(this.ttl, 'days');
    const isProfileOutdated = expirationDate.isBefore(today);
    return isProfileOutdated;
  }

  get hasCompanyInfo() {
    return !!this.cnpj;
  }

  _update(updates) {
    Object.assign(this, updates);
  }

  static createFromUser(rawUser) {
    const profile = new UserProfileDTO();

    profile.companyPhone = rawUser.profile.companyPhone;
    profile.phoneNumber = rawUser.profile.phoneNumber;
    profile.dealershipName = rawUser.profile.dealershipName;

    const taxId = getUserCnpj(rawUser);
    profile.cnpj = taxId?.taxIdentificationNumber;
    profile.email = rawUser.email;
    profile.id = rawUser._id;
    profile.updatedAt = new Date(rawUser.profile.updatedAt);

    return profile;
  }

  static default() {
    return new UserProfileDTO();
  }
}
