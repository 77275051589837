import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const getUser = state => state.user;

export const isLoggedIn = state => getUser(state).isLoggedIn();

export const getAuthenticatedUser = state => state.authentication;

export const getDealersForDropdown = createSelector([getUser], ({ relatedDealers }) =>
  !isEmpty(relatedDealers)
    ? relatedDealers.reduce((acc, { dealershipName }) => {
        if (!isEmpty(dealershipName)) {
          acc.push({ label: dealershipName, value: dealershipName });
        }
        return acc;
      }, [])
    : []
);
