import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Modal from '../../enhanced/Modal';
import { toggleModal } from '../../../store/actions/ui.actions';
import { PREFERENCES_CONFIG_KEY } from '../../../constants/modalConstants';

import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';

import {
  ANIMATION_MILLISECONDS,
  ModalContainer,
  ModalContent,
  ModalHeader,
  Typography,
  GridContent,
  ModalFooter,
  NextButton,
  ColumnContent,
  LoadContainer,
  ImgContainer,
  LoadingBox,
  LoadingContent,
  ModalMobileHeader,
  NextButtonWrapper,
  HeaderColumn,
  NotificationContent,
  MergedContent,
  Section,
  AccurateContent,
} from './styles';

import {
  car_check,
  category_car,
  category_like,
  category_sale,
  category_location,
  category_loading_car,
  category_timer,
  trafficLight,
  category_fipe70k,
  category_fipe100k,
} from '../../../images';

import { preferencesLabel } from '../../../constants/preferencesLabel';
import { createUserPreferences } from '../../../store/orderCars/effects';
import { Button } from '../../Buttons';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { primaries } from '../../../design-system/colors';
import SelectWithSearch from '../../filters/components/select-with-search';
import { useAsync } from 'react-use';
import { fetchMarks } from '../../../services/marks';
import { transformMarks } from '../../../utils/marks';
import ICSlider from '../../filters/components/ic-slider';
import { NewSelect as Select } from '../../NewSelect';
import { listOfYears } from '../../../utils/listOfYears';

const PREFERENCES_CONTENT = [
  { name: 'cautelar', label: 'Cautelar Aprovada', imgSrc: car_check },
  /*   { name: 'score80', label: 'Score acima de 80%', imgSrc: category_like }, */
  { name: 'before100k', label: 'Até 100.000 Km', imgSrc: category_car },
  { name: 'fipe100k', label: 'FIPE acima de R$ 100.000', imgSrc: category_fipe100k },
  { name: 'fipe70', label: '70% da FIPE', imgSrc: category_sale },
  { name: 'fipe70k', label: 'FIPE acima de R$ 70.000', imgSrc: category_fipe70k },
  { name: 'uf', label: 'Na sua região (UF)', imgSrc: category_location },
  { name: 'closeToEnd', label: 'Próximos a encerrar', imgSrc: category_timer },
];

const RECEIVER_CONTENT = [
  { name: 'push_notification', label: 'Notificações no App' },
  { name: 'whatsapp', label: 'WhatsApp' },
  { name: 'email', label: 'E-mail' },
];

const PREFERENCES_ORIGINAL_STATE = {
  cautelar: false,
  before100k: false,
  fipe70: false,
  score80: false,
  closeToEnd: false,
  uf: false,
  fipe70k: false,
  fipe100k: false,
  brands: false,
  year: false,
  km: false,
};

const RECEIVER_ORIGINAL_STATE = {
  whatsapp: false,
  email: false,
  push_notification: false,
};

export function PreferencesModal() {
  const [preferences, setPreferences] = useState(PREFERENCES_ORIGINAL_STATE);
  const [receivers, setReceivers] = useState(RECEIVER_ORIGINAL_STATE);
  const [page, setPage] = useState(1);
  const [reqInfo, setReqInfo] = useState({
    isLoading: false,
    animationPassTime: false,
    error: false,
  });
  const { error } = useSelector(state => state.orderCars);

  const [size, setSize] = useState(window.screen.width);

  const [selectedKm, setSelectedKm] = useState(0);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedYear, setSelectedYear] = useState(0);

  const isOpen = useSelector(state => state.ui.modals.preferencesConfig.show);
  const userId = useSelector(state => state.authentication.user.id);

  const dispatch = useDispatch();
  const history = useHistory();

  const toggle = () => {
    setPage(1);
    setPreferences(PREFERENCES_ORIGINAL_STATE);
    setReceivers(RECEIVER_ORIGINAL_STATE);
    setSelectedKm(0);
    setSelectedBrands([]);
    setSelectedYear(0);
    dispatch(toggleModal({ key: PREFERENCES_CONFIG_KEY, show: false }));
  };

  const onReceiverClick = name => setReceivers(prev => ({ ...prev, [name]: !prev[name] }));

  const howManyPreferences = useMemo(() => Object.values(preferences).filter(v => v).length, [
    preferences,
  ]);

  const handleSubmitPreferences = async () => {
    setReqInfo({ isLoading: true, animationPassTime: false, error: false });

    const objectFormat = obj =>
      Object.entries(obj).map(([name, isActive]) => {
        if (name === 'brands') {
          return {
            name: preferencesLabel[name],
            value: selectedBrands,
            isActive: !!selectedBrands.length ? true : false,
          };
        }

        if (name === 'km') {
          return {
            name: preferencesLabel[name],
            value: selectedKm,
            isActive: !!selectedKm ? true : false,
          };
        }

        if (name === 'year') {
          return {
            name: preferencesLabel[name],
            value: selectedYear,
            isActive: !!selectedYear ? true : false,
          };
        }

        return { name: preferencesLabel[name], isActive };
      });

    const channelOfContact = objectFormat(receivers);
    const userPreferences = objectFormat(preferences);

    await dispatch(createUserPreferences(userId, channelOfContact, userPreferences));

    setReqInfo(prev => ({ ...prev, isLoading: false }));
  };

  const brands = useAsync(async () => {
    const b = await fetchMarks();
    return transformMarks(b);
  });

  useEffect(() => {
    if (reqInfo.animationPassTime && !reqInfo.isLoading && !reqInfo.error) {
      toggle();
      setReqInfo(prev => ({ ...prev, animationPassTime: false, error: false }));
    }
  }, [reqInfo]);

  useEffect(() => {
    let timeout;
    if (page === 4 && !error) {
      timeout = setTimeout(() => {
        setReqInfo(prev => ({ ...prev, animationPassTime: true }));
      }, ANIMATION_MILLISECONDS);
    } else if (page === 4 && !!error) {
      setReqInfo(prev => ({ ...prev, error: true }));
      setPage(5);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [page, error]);

  useEffect(() => {
    if (preferences.before100k) setSelectedKm(0);
  }, [preferences.before100k]);

  window.addEventListener('resize', () => setSize(window.screen.width));

  return (
    <Modal
      show={isOpen}
      toggle={toggle}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          maxWidth: '1024px',
          width: '100%',
          maxHeight: size <= 600 ? '100vh' : '602px',
          height: '100%',
          transform: 'translate(-50%, -50%)',
          padding: size <= 600 ? 0 : '56px 32px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99999,
          position: 'relative',
          backgroundColor: '#fff',
          boxShadow: '3px 4px 14px #438a4c7f',
          borderRadius: size <= 600 ? 0 : 4,
        },
      }}
      closeBtnPadding={20}
      closeBtnSize={64}
      hideCloseBtn={size <= 600 ? true : false}
    >
      <ModalContainer>
        {page === 1 && (
          <ModalContent>
            <ModalMobileHeader>
              <ArrowBackRounded fontSize="small" sx={{ color: '#fff' }} onClick={toggle} />
              <Typography
                fontFamily="Roboto"
                fontWeight={700}
                fontSize={18}
                color="#fff"
                style={{
                  maxWidth: '100%',
                  width: '100%',
                }}
              >
                Preferências
              </Typography>
            </ModalMobileHeader>
            <ModalHeader>
              <Typography fontFamily="Roboto" fontWeight={700} fontSize={20} marginBottom={1}>
                Selecione as características que mais lhe interessam:
              </Typography>
              <Typography fontFamily="Roboto" fontWeight={400}>
                Vamos separar as melhores ofertas de acordo com as suas escolhas.
              </Typography>
            </ModalHeader>
            <GridContent>
              {PREFERENCES_CONTENT.map(res => (
                <CustomFormControlLabel
                  key={res.name}
                  label={res.label}
                  icon={res.imgSrc}
                  checked={preferences[res.name]}
                  onClick={() => setPreferences(prev => ({ ...prev, [res.name]: !prev[res.name] }))}
                />
              ))}
            </GridContent>
            <ModalFooter>
              <Button
                onClick={() => setPage(2)}
                maxWidth={size <= 600 ? '100%' : '196px'}
                fontFamily="Rubik"
                fontWeight={600}
                bg="#2274A5"
                borderRadius={6}
                disabled={!howManyPreferences}
              >
                Continuar
              </Button>
            </ModalFooter>
          </ModalContent>
        )}

        {page === 2 && (
          <AccurateContent>
            <ModalMobileHeader style={{ justifyContent: 'space-between' }}>
              <ArrowBackRounded
                fontSize="small"
                sx={{ color: '#fff' }}
                onClick={() => setPage(1)}
              />
              <Typography
                fontFamily="Roboto"
                fontWeight={700}
                fontSize={18}
                color="#fff"
                style={{
                  maxWidth: '100%',
                  width: '100%',
                }}
              >
                Preferências
              </Typography>
            </ModalMobileHeader>

            <ModalContent.Mobile>
              <ModalHeader>
                <NextButtonWrapper>
                  <NextButton onClick={() => setPage(1)} fontWeight={400}>
                    <MdKeyboardArrowLeft size={40} style={{ marginRight: '-8px' }} />
                    Voltar
                  </NextButton>
                </NextButtonWrapper>
                <HeaderColumn>
                  <Typography fontFamily="Roboto" fontWeight={700} fontSize={20}>
                    Adicione mais informações para receber ofertas mais precisas
                  </Typography>
                </HeaderColumn>
              </ModalHeader>

              <Section>
                <Section.Content>
                  <Section.Item>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize={16}
                      color="#2f3741"
                      textAlign="start"
                    >
                      KM máxima de veículos do seu interesse
                    </Typography>
                    <ICSlider
                      min={0}
                      max={400_000}
                      name="km"
                      onChange={evt => {
                        setSelectedKm(evt.target.value);
                        setPreferences(oldState => ({ ...oldState, before100k: false }));
                      }}
                      step={25_000}
                      value={selectedKm}
                      previewSuffix="Km"
                      fontFamily="Roboto"
                    />
                  </Section.Item>

                  <Section.Item>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight={700}
                      fontSize={16}
                      color="#2f3741"
                      textAlign="start"
                    >
                      Selecione veículos com até
                    </Typography>
                    <Select
                      placeholder="Selecione o ano"
                      list={listOfYears}
                      value={selectedYear}
                      onChange={evt => setSelectedYear(Number(evt.target.value))}
                    />
                  </Section.Item>
                </Section.Content>

                <Section.Divider></Section.Divider>

                <Section.Item height={100}>
                  <Typography
                    fontFamily="Roboto"
                    fontWeight={700}
                    fontSize={16}
                    color="#2f3741"
                    textAlign="start"
                  >
                    Selecione as marcas do seu interesse
                  </Typography>
                  {!brands.loading && (
                    <SelectWithSearch
                      name="brands"
                      type="image"
                      data={brands.value}
                      value={selectedBrands}
                      onChange={evt => setSelectedBrands(evt.target.value)}
                      fontFamily="Roboto"
                      orderCars
                    />
                  )}
                </Section.Item>
              </Section>
            </ModalContent.Mobile>

            <ModalFooter>
              <Button
                onClick={() => setPage(3)}
                maxWidth={size <= 600 ? '100%' : '196px'}
                fontFamily="Rubik"
                fontWeight={600}
                bg="#2274A5"
                borderRadius={6}
              >
                Continuar
              </Button>
            </ModalFooter>
          </AccurateContent>
        )}

        {page === 3 && (
          <NotificationContent>
            <ModalMobileHeader>
              <ArrowBackRounded
                fontSize="small"
                sx={{ color: '#fff' }}
                onClick={() => setPage(2)}
              />
              <Typography
                fontFamily="Roboto"
                fontWeight={700}
                fontSize={18}
                color="#fff"
                style={{
                  maxWidth: '100%',
                  width: '100%',
                }}
              >
                Preferências
              </Typography>
            </ModalMobileHeader>

            <ModalHeader>
              <NextButtonWrapper>
                <NextButton onClick={() => setPage(2)} fontWeight={400}>
                  <MdKeyboardArrowLeft size={40} style={{ marginRight: '-8px' }} />
                  Voltar
                </NextButton>
              </NextButtonWrapper>
              <HeaderColumn>
                <Typography fontFamily="Roboto" fontWeight={700} fontSize={20}>
                  Melhore ainda mais a sua experiência:
                </Typography>
                <Typography fontFamily="Roboto" fontWeight={400} style={{ maxWidth: '515px' }}>
                  Você também pode escolher se deseja ser avisado assim que as ofertas recomendadas
                  entrarem na plataforma.
                </Typography>
              </HeaderColumn>
            </ModalHeader>

            <MergedContent>
              <CustomFormControlLabel
                label="Não quero receber notificações"
                checked={!Object.values(receivers).some(v => v)}
                onClick={() => setReceivers(RECEIVER_ORIGINAL_STATE)}
                fontFamily="Roboto"
                fontWeight={400}
              />
              {RECEIVER_CONTENT.map(res => (
                <CustomFormControlLabel
                  key={res.name}
                  checked={receivers[res.name]}
                  onClick={() => onReceiverClick(res.name)}
                  label={res.label}
                  fontFamily="Roboto"
                  fontWeight={400}
                />
              ))}
            </MergedContent>
            <ModalFooter>
              <Button
                onClick={() => {
                  handleSubmitPreferences();
                  setPage(4);
                  history.push('/', { notify: true });
                }}
                maxWidth={size <= 600 ? '100%' : '196px'}
                fontFamily="Rubik"
                fontWeight={600}
                bg="#2274A5"
                borderRadius={6}
              >
                {size <= 600 ? 'Finalizar' : 'Continuar'}
              </Button>
            </ModalFooter>
          </NotificationContent>
        )}

        {page === 4 && (
          <LoadContainer>
            <React.Fragment>
              <ImgContainer
                src={category_loading_car}
                width={142}
                alt="icone de carro carregando"
              />
              <LoadingBox>
                <LoadingContent />
              </LoadingBox>
              <Typography
                alignSelf="center"
                fontFamily="Roboto"
                fontWeight={700}
                fontSize={size <= 600 ? 20 : 24}
                color="#22AA52"
              >
                Salvando as suas preferências...
              </Typography>
            </React.Fragment>
          </LoadContainer>
        )}

        {page === 5 && (
          <ColumnContent maxWidth={500} alignItems="center" justifyContent="center">
            <img
              style={{ marginInline: 'auto', marginBottom: 40 }}
              width={103}
              src={trafficLight}
              alt="imagem de semáforo"
            />
            <Typography fontFamily="Roboto" fontWeight={700} fontSize={20} marginBottom={1}>
              Ops...Ocorreu um erro
            </Typography>
            <Typography fontFamily="Roboto" fontWeight={400}>
              Suas preferências não foram salvas, pedimos desculpas pelo imprevisto. Fique
              tranquilo, você pode tentar novamente mais tarde.
            </Typography>
          </ColumnContent>
        )}
      </ModalContainer>
    </Modal>
  );
}

const CustomFormControlLabel = ({
  label,
  icon,
  fontFamily = 'Nunito',
  fontWeight = 800,
  ...props
}) => (
  <FormControlLabel
    control={
      <Checkbox
        disableRipple
        sx={{
          padding: 0,
          color: '#737171',
          '&.Mui-checked': { color: primaries.Soul },
        }}
        {...props}
      />
    }
    label={
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        color="#2F3741"
      >
        {!!icon && <img src={icon} />}
        {label}
      </Box>
    }
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1.5,
      width: '100%',
      height: 64,
      margin: 0,
      padding: '0 12px',
      border: '1px solid #c4c4c4',
      borderRadius: 2.5,
    }}
  />
);
