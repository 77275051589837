import React from 'react';
import PropTypes from 'prop-types';

import { forgotPassword } from '../../utils/fecth-wrappers';
import { MdEmail } from 'react-icons/md';
import { Base } from './';

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    email: '',
    loading: false,
    success: false,
    error: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      this.props
        .forgotPassword(this.state.email)
        .then(() => {
          this.setState({ loading: false, success: true });
        })
        .catch(() => {
          this.setState({ loading: false, error: true });
        });
    });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { loading, success, email, error } = this.state;
    return (
      <Base
        title={success ? 'Email de recuperação enviado!' : 'Vamos recuperar sua senha'}
        subtitle={
          success
            ? 'Siga as instruções que enviamos para o seu email, recupere sua senha e não perca mais nenhum leilão!'
            : 'Te enviaremos um email com as instruções para recuperar sua senha.'
        }
        CTA="Recuperar senha"
        errorMessage="Houve um problema ao tentar encontrar este e-mail, é possível
        que essa conta não esteja registrada. Tente novamente ou entre em contato com o suporte."
        links={[
          {
            text: '',
            href: '/login',
            label: 'Voltar para o login',
          },
        ]}
        inputs={
          success
            ? null
            : [
                {
                  name: 'email',
                  value: email,
                  type: 'email',
                  label: 'E-mail',
                  placeholder: 'Informe o seu email de acesso à plataforma',
                  onChange: this.handleChange,
                },
              ]
        }
        error={error}
        loading={loading}
        onSubmit={this.handleSubmit}
        disabled={!email}
        icon={success && <MdEmail />}
      />
    );
  }
}

ForgotPasswordPage.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
};

ForgotPasswordPage.defaultProps = {
  forgotPassword,
};

export { ForgotPasswordPage };
