import constants from '../constants/appConstants';
import { getToken } from '../utils/auth';

import axios from 'axios';

export const checkFinancingEnabled = async dealerEmail => {
  const data = {
    dealerEmail,
    userSession: getToken(),
  };

  let config = {
    method: 'post',
    url: constants.API.checkFinancingEnabled,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  const response = await axios.request(config);
  return response.data.data.isFinancingEnabled;
};

export const loadCreditAvaible = async (dealerId, dealerEmail, amountCar) => {
  const data = {
    dealerId,
    dealerEmail,
    amountCar,
    userSession: getToken(),
  };

  let config = {
    method: 'post',
    url: constants.API.loadCreditAvaible,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  const response = await axios.request(config);
  return response.data.data;
};

export const saveCreditSimulation = async (dealerId, defaultProfile, dataSim, auction) => {
  const data = {
    dealerId,
    dealerCnpj: defaultProfile.taxIdentificationNumber,
    inspectionId: auction._id,
    auctionId: auction.auction.idAuction,
    vehicleValue: auction.summary?.buyForPrice?.amount,
    quantityInstallments: dataSim.quantityInstallments,
    monthlyInstallment: dataSim.monthlyInstallment,
    entryAmount: dataSim.entryAmount,
    financingTax: dataSim.financingTax,
    entryPercentage: dataSim.entryPercentage,
    installments: dataSim.installments,
    entryTotalValue: dataSim.entryTotalValue,
    entryVoucherValue: dataSim.entryVoucherValue,
    financingValue: dataSim.financingValue,
    installmentsTotalValue: dataSim.installmentsTotalValue,
    simulatedBy: dataSim.simulatedBy,
    taxesTotalValue: dataSim.taxesTotalValue,
    entryValue: dataSim.entryAmount,
    gravameValue: 500,
    billingOption: defaultProfile.type,
    userSession: getToken(),
  };

  let config = {
    method: 'post',
    url: constants.API.saveCreditSimulation,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  const response = await axios.request(config);
  return response.data.data;
};
