import React from 'react';
import PropTypes from 'prop-types';

/**
 * This HOC creates a new component that obtains the modal reference
 * from  the context and inject it to the specified component via props.
 * @param {*} Component
 * @returns a connected Component.
 */
export const withModal = Component => {
  class WithModal extends React.Component {
    render() {
      return <Component modal={this.context.modal} {...this.props} />;
    }
  }
  WithModal.contextTypes = {
    modal: PropTypes.any,
  };
  return WithModal;
};
