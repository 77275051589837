import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, withRouter } from 'react-router-dom';
import { useUnleashContext, useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as userActionsTypesTypes from '../store/actions/user.actions';
import * as auctionsActionsTypes from '../store/actions/auction.actions';
import * as uiActionsTypes from '../store/actions/ui.actions';
import { listAutoBidVehicles, listAllAutoBidVehicles } from '../store/autobid/effects';

import { defineSupperOfferAuctions } from '../utils/auction';
import { scrollTop } from '../utils/misc';
import { canShowFreeShippingDialog } from '../utils/freeShippingDialog';
import { checkToken } from '../utils';
import { sortAuctionsByFilter } from '../utils/sorting';

import { useFilter } from '../hooks/useFilter';
import { UserFavoriteInspectionsContextProvider } from '../context/FavoriteContext';
import { favoriteService } from '../services/favorite';
import { checkFinancingEnabled } from '../services/fincredit';

import ConfirmationModal from '../components/shared/confirmation-modal/ConfirmationModal.jsx';
import MakeBidConfirmationModal from '../components/shared/make-offer/MakeBidConfirmationModal.jsx';
import { Layout } from '../components';
import { Box, Flex } from '../components/abstract';
import Loading from '../components/shared/loading/Loading';
import ParticipatingAuctionsList from '../components/NewAuctionsList/ParticipatingAuctionsList';
import NewOffersList from '../components/NewAuctionsList/NewOffersList';
import HighlightOffersList from '../components/NewAuctionsList/HighlightOffersList';
import NotificationsList from '../components/NewAuctionsList/NotificationsList';
import { WarningNotificationSnack } from '../components/update-contact-info/warning-notification-snack';
import NewOffersFilters from '../components/filters/new-offers-filters';
import FilterNavigation from '../components/filters/components/filter-navigation';
import MobileNewOffersFilters from '../components/filters/mobile-new-offers-filters';
import ModalTemplate from '../components/filters/components/modal-template';
import {
  NewOffersContainer,
  LoadingAuctions,
  NewOffersBody,
  FilterSide,
  CarList,
} from '../components/auctions-page-styles';
import FreeShippingDialog from '../components/freeShippingDialog';
import { CannotSelectWithActiveCategoriesModal } from '../components/Modals/CannotSelectWithActiveCategoriesModal';
import NewCarousel from '../components/NewCarousel';
import { EmptyState } from '../components/enhanced/EmptyState';

import { MdClose } from 'react-icons/md';
import FireIcon from '../images/fire-icon-1x.png';
import LoadingGif from '../images/loading.gif';

import { AppConstants } from '../constants/appConstants';
import { hotjar } from '../utils/hotjar-script';
import { usePreferences } from '../hooks/usePreferences';
import { deauthenticate } from '../store/actions/authentication.actions';
import { sendEvent } from '../helpers/googleAnalytics';
import { ArrowBackRounded, RecommendedIcon } from '../components/Icons';
import { useSegment } from '../hooks/useSegment';
import { listUserPreferences } from '../store/orderCars/effects';
import { FincredCard } from '../components/NewAuctionsList/Components/FincredCard';

export const ListsTitle = styled.div`
  align-items: center;
  gap: 0 8px;
  min-height: 28px;
  display: flex;

  h5 {
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
    color: ${({ color }) => color ?? '#2f3741'};
    font-size: 24px;
  }

  img {
    margin-right: 10px;
  }
`;

const PreferenceButton = styled.button`
  background: none;
  border: none;
  outline: none;
  text-decoration: underline;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 0;
  text-align: start;

  @media screen and (max-width: 1024px) {
    margin-bottom: 16px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 325px;
  padding: 6px 0;
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  background-color: #fff;
  /* color: #ffffff; */
  font-family: 'Roboto';
  border: none;
  margin: 1rem 0;
  &:disabled {
    background-color: #c4c4c4;
  }
  @media screen and (min-width: 1024px) {
    margin: 0 0.75rem;
  }
`;

const ShowAllPreferences = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Recommended = styled(Content)`
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  background-color: #003a76;

  @media screen and (max-width: 1024px) {
    padding: 8px;
    margin-bottom: 24px;
  }
`;

Recommended.Content = styled(Content)`
  max-width: 1280px;
`;

const TAB_NAME = {
  0: 'Novas Ofertas',
  1: 'Participando',
  2: 'Notificações',
};

export const AuctionsLayout = ({
  user,
  auctions,
  ui,
  allRequestOnGoing,
  loadingHistoricsAuctions,
  historicsLoaded,
  allCarsFetched,
  isParticipatingInNewAuctions,
  isProfileOutdated,
  auctionsActions,
  uiActions,
  listAutoBidVehicles,
  listAllAutoBidVehicles,
}) => {
  const [state, setState] = useState({ tab: 0 });
  const [displayWidth, setDisplayWidth] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(null);
  const [staticAuctions, setStaticAuctions] = useState([]);
  const [showAllPreferences, setShowAllPreferences] = useState(false);
  const [displaySimulationBanner, setDisplaySimulationBanner] = useState(false);
  const [size, setSize] = useState(window.screen.width);

  const applyedFilter = useSelector(state => state.ui.filters.newOffersFiltersApplyed);

  const { userProfileLoading } = useSelector(state => state.user?.defaultUserProfile || []);
  const { profile } = useSelector(state => state.user.profile || {});

  const dispatch = useDispatch();
  const updateContext = useUnleashContext();
  const enabled = useFlag('auto-bid');
  const enabledOrderCars = useFlag('order-cars');
  const enabledSimulationBanner = useFlag('banner-simulacao');
  const history = useHistory();

  const { recommendedCars } = usePreferences();
  const { filtredAuctions, filterList, clearFilter } = useFilter();
  const { page } = useSegment();

  function onConversationsAPIReady() {
    var _hsq = window._hsq ?? [];
    console.log('ready');
    _hsq.push([
      'identify',
      {
        email: user?.email,
      },
    ]);
    _hsq.push(['trackPageView']);
    setTimeout(() => {
      console.log('refresh');
      window.HubSpotConversations.widget.refresh();
    }, 1000);
  }

  function sendIdentify() {
    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  }
  const fetchData = async () => {
    try {
      const response = await checkFinancingEnabled(user?.email);
      setDisplaySimulationBanner(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (enabledSimulationBanner) {
      fetchData();
    }
  }, [enabledSimulationBanner]);

  useEffect(() => {
    if (!user.email.includes('@instacarro.com')) {
      hotjar.initialize();
    }

    analytics.identify(user.id, {
      userId: user?.id,
      dealershipName: user?.dealershipName,
      email: user?.email,
      relatedNinja: profile?.relatedNinja,
    });
  }, [user, profile]);

  useEffect(() => {
    if (typeof window.hj !== 'undefined') {
      const userId = user.id || null;

      if (filterList.length > 0) {
        hotjar.identify(userId, user.email, user.dealershipName, user.isNinja(), true);
      } else {
        hotjar.identify(userId, user.email, user.dealershipName, user.isNinja(), false);
      }
    }
  }, [window.hj, filterList]);

  useEffect(() => {
    page(TAB_NAME[state.tab]);
  }, [state.tab]);

  useEffect(() => {
    listAutoBidVehicles(user.id);
  }, [user.id]);

  useEffect(() => {
    updateContext({ userId: user?.email });
  }, [user?.email]);

  useEffect(() => {
    if (user.isNinja() || !enabled) {
      listAllAutoBidVehicles();
    }
  }, [user.isNinja, enabled]);

  useEffect(() => {
    auctionsActions.fetchAuctions();
  }, [auctionsActions.fetchAuctions]);

  useEffect(() => {
    if (auctions.detailedAuction?.auction?.participating) setState({ tab: 1 });

    if (history.location?.state?.from?.includes('detalhes')) setState({ tab: 0 });

    if (state.tab === 2) auctionsActions.SetNotificationsCount(0);

    authenticateLogin();
    dispatch(listUserPreferences(user.id));
    dispatch(userActionsTypesTypes.getDefaultUserProfile());
  }, []);

  useEffect(() => {
    const userIdentified = JSON.parse(localStorage.getItem('user-identified'));

    if (!userIdentified) {
      localStorage.setItem('user-identified', JSON.stringify(true));

      _cio.identify({
        id: user?.email,
        email: user?.email,
        first_name: user?.dealershipName,
        created_at: new Date(),
      });
    }
  }, []);

  useEffect(() => {
    sendIdentify();
  }, []);

  const authenticateLogin = async () => {
    try {
      await checkToken();
      scrollTop();
      const isStore = String(user.role).toLowerCase() === 'loja';
      if (!loadingHistoricsAuctions && !historicsLoaded && allCarsFetched && isStore) {
        auctionsActions.fetchAuctionsHistory();
      }
    } catch (error) {
      dispatch(deauthenticate(() => history.push('/login')));
    }
  };

  const showNewOffersFilters = useCallback(async () => {
    if (filterList.length > 0) {
      setType('category');
      setIsOpen(true);
    } else {
      auctionsActions.handleFilters({ showNewOffersFilters: true });
      const { id, dealershipName, email } = user;
      sendEvent('filterTracker', {
        dealershipId: id,
        dealershipEmail: email,
        dealershipName: dealershipName,
        eventCategory: 'Filters',
        eventAction: 'Open filters',
        eventLabel: 'New offers filter',
      });
    }

    auctionsActions.handleFilters({ showNewOffersFilters: true });

    setStaticAuctions(auctions.newOffers);

    const { id, dealershipName, email } = authentication.user;
    sendEvent('filterTracker', {
      dealershipId: id,
      dealershipEmail: email,
      dealershipName: dealershipName,
      eventCategory: 'Filters',
      eventAction: 'Open filters',
      eventLabel: 'New offers filter',
    });
  }, [filterList, auctions]);

  function closeNewOffersFilters() {
    auctionsActions.handleFilters({ showNewOffersFilters: false });
  }

  const { highlightOffers, newOffers, recommendedOffers } = useMemo(() => {
    let recommendedOffers = auctions.newOffers.filter(car => recommendedCars.includes(car._id));

    let newOffers = [...auctions.newOffers.filter(car => !recommendedCars.includes(car._id))];

    if (filterList.length > 0) newOffers = [...filtredAuctions.data];

    if (ui.filters.newOffersFiltersApplyed) newOffers = [...auctions.newOffers];

    let highlightOffers = defineSupperOfferAuctions(newOffers, {
      supperOfferMaximumSize: 15,
    }).filter(car => car.auction.isSupperOffer);

    highlightOffers = sortAuctionsByFilter(highlightOffers, ui.sortFilterParam);

    newOffers = sortAuctionsByFilter(newOffers, ui.sortFilterParam);

    return { highlightOffers, newOffers, recommendedOffers };
  }, [recommendedCars, filtredAuctions, filterList, auctions, ui.sortFilterParam, ui.filters]);

  const tabsData = {
    showNotifyIcon: isParticipatingInNewAuctions,
    participatingCount: auctions.participatingAuctions.length,
    newOffersCount: auctions.newOffers.length,
    activeTab: state.tab,
    onClickTab0: () => setState({ tab: 0 }),
    onClickTab1: () => {
      setState({ tab: 1 });
      auctionsActions.IsParticipatingInNewAuctions(false);
    },
    onClickTab2: () => {
      setState({ tab: 2 });
    },
  };

  let screen = window.screen.width;

  const recommendedLength = screen < 880 ? recommendedOffers.length : 15;

  window.addEventListener('resize', () => {
    if (window.screen.width <= 1024 && window.screen.width >= 768) {
      return setDisplayWidth('md');
    }

    window.screen.width < 768 ? setDisplayWidth('sm') : setDisplayWidth('lg');
    screen = window.screen.width;
  });

  if (allRequestOnGoing || loadingHistoricsAuctions || userProfileLoading)
    return <Loading centered />;

  return (
    <>
      <CannotSelectWithActiveCategoriesModal
        isOpen={isOpen}
        onToggle={() => setIsOpen(!isOpen)}
        type={type}
      />
      <Layout tabs={true} tabsData={tabsData} user={user}>
        <Content>
          <UserFavoriteInspectionsContextProvider service={favoriteService} notify={console}>
            {state.tab === 0 &&
              !!recommendedOffers.length &&
              !filterList.length &&
              enabledOrderCars && (
                <Recommended>
                  <Recommended.Content>
                    <ListsTitle color="#fff" style={{ marginTop: 32, marginBottom: 12 }}>
                      <RecommendedIcon color="#ffed1c" />
                      <h5>Recomendados para você</h5>
                    </ListsTitle>

                    <PreferenceButton onClick={() => history.push('/preferencias')}>
                      Alterar preferências
                    </PreferenceButton>

                    {showAllPreferences ? (
                      <NewOffersList auctions={recommendedOffers} />
                    ) : (
                      <NewCarousel
                        auctions={recommendedOffers.slice(0, recommendedLength)}
                        isRecommended={true}
                      />
                    )}

                    {recommendedOffers.length > 16 && !showAllPreferences && screen > 1024 && (
                      <ShowAllPreferences>
                        <Button
                          onClick={() => {
                            setShowAllPreferences(true);
                            document
                              .getElementById('base-layout')
                              .scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                        >
                          Ver todos Recomendados{' '}
                          <ArrowBackRounded color="#003a76" style={{ marginLeft: 10 }} />
                        </Button>
                      </ShowAllPreferences>
                    )}
                  </Recommended.Content>
                </Recommended>
              )}

            <Box width="100%" pb={3}>
              <Flex
                className="container"
                mx="auto"
                p={1}
                flexWrap="wrap"
                justifyContent={state.tab === 1 ? 'center' : ''}
              >
                <WarningNotificationSnack shouldShow={isProfileOutdated} />

                {state.tab === 0 ? (
                  newOffers.length < 1 && recommendedOffers.length < 1 ? (
                    <EmptyState tab={0} showFinCred={true} />
                  ) : (
                    <NewOffersContainer>
                      <FilterNavigation handleFilterButton={showNewOffersFilters} tab={0} />
                      <NewOffersBody filtersOpen={ui.filters.showNewOffersFilters}>
                        {ui.filters.showNewOffersFilters && (
                          <FilterSide>
                            <FilterSide.Content>
                              <FilterSide.Content.Close onClick={closeNewOffersFilters}>
                                <MdClose />
                              </FilterSide.Content.Close>
                              <NewOffersFilters />
                            </FilterSide.Content>
                          </FilterSide>
                        )}

                        <NewOffersBody.Content>
                          {highlightOffers.length > 0 && !filterList.length && (
                            <>
                              <ListsTitle>
                                <img src={FireIcon} alt="fire" />
                                <h5>Ofertas em destaque</h5>
                              </ListsTitle>
                              {/* <NewCarousel auctions={highlightOffers} isHighlight /> */}
                              <HighlightOffersList auctions={highlightOffers} />
                            </>
                          )}

                          {(recommendedOffers.length > 0 || highlightOffers.length > 0) &&
                            newOffers.length > 0 &&
                            !filterList.length && (
                              <ListsTitle>
                                <h5>Mais ofertas</h5>
                              </ListsTitle>
                            )}

                          {!!filterList.length && (
                            <ListsTitle>
                              <h5>
                                {filtredAuctions?.data.length}{' '}
                                <span style={{ fontWeight: 400 }}>resultados encontrados</span>
                              </h5>
                            </ListsTitle>
                          )}

                          {newOffers.length === 0 && displaySimulationBanner ? (
                            <CarList>
                              <FincredCard />
                            </CarList>
                          ) : (
                            <NewOffersList auctions={newOffers} newOffers={true} />
                          )}

                          {/* {!!filterList.length && (
                          <Box style={{ marginBottom: '3rem', textAlign: 'center' }}>
                            <Button
                              style={{ margin: '1rem auto' }}
                              onClick={() => {
                                clearFilter();
                                dispatch(uiActionsTypes.clearCategoryFilter());
                                document
                                  .getElementById('base-layout')
                                  .scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                            >
                              Ver outras ofertas
                            </Button>
                          </Box>
                        )} */}

                          {ui.filters.showNewOffersLoading && (
                            <LoadingAuctions>
                              <img src={LoadingGif} alt="Carregando" />
                            </LoadingAuctions>
                          )}
                        </NewOffersBody.Content>
                      </NewOffersBody>
                    </NewOffersContainer>
                  )
                ) : null}

                {state.tab === 1 ? (
                  auctions.participatingAuctions.length < 1 ? (
                    <EmptyState
                      title="Você não está participando de nenhum leilão"
                      description="Participe de leilões para visualizar aqui os status de cada um dos seus carros."
                      buttons={[
                        {
                          label: 'Ver leilões ativos',
                          action: () => {
                            setState({ tab: 0 });
                          },
                          variant: 'ghost.default',
                        },
                      ]}
                    />
                  ) : (
                    <div style={{ width: '100%' }}>
                      <ParticipatingAuctionsList
                        auctions={auctions.participatingAuctions}
                        displayWidth={displayWidth}
                      />
                      {ui.filters.showParticipatingLoading && (
                        <LoadingAuctions>
                          <img src={LoadingGif} alt="Carregando" />
                        </LoadingAuctions>
                      )}
                      {ui.filters.participatingErrors && (
                        <ModalTemplate
                          handleCloseModal={() =>
                            auctionsActions.handleFilters({ participatingErrors: '' })
                          }
                        >
                          <p>{ui.filters.participatingErrors}</p>
                        </ModalTemplate>
                      )}
                    </div>
                  )
                ) : null}

                {state.tab === 2 && (
                  <NotificationsList goToPageOne={() => tabsData.onClickTab0()} />
                )}
              </Flex>

              <ConfirmationModal
                id="autobid-confirmation-modal"
                show={ui.showConfirmationModal}
                message={ui.confirmationModalMessage}
                toggle={() => {
                  uiActions.toggleAutoBidConfirmation(false);
                }}
                confirmationToggle={t => {
                  uiActions.toggleAutoBidConfirmation(t);
                }}
              />
              <MakeBidConfirmationModal
                id="make-bid-confirmation-modal"
                toggle={auctionsActions.toggleMakeBidConfirmationModal}
                show={auctions.showMakeBidConfirmationModal}
                selectedCar={auctions.confirmationCar}
              />

              {canShowFreeShippingDialog() && <FreeShippingDialog />}
            </Box>
          </UserFavoriteInspectionsContextProvider>
        </Content>

        {ui.filters.showNewOffersFilters && <MobileNewOffersFilters />}
      </Layout>
    </>
  );
};

AuctionsLayout.propTypes = {
  user: PropTypes.object.isRequired,
  auctions: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  allRequestOnGoing: PropTypes.bool,
  loadingHistoricsAuctions: PropTypes.bool,
  historicsLoaded: PropTypes.bool,
  allCarsFetched: PropTypes.bool,
  isParticipatingInNewAuctions: PropTypes.bool,
  isProfileOutdated: PropTypes.bool,
  auctionsActions: PropTypes.object.isRequired,
  uiActions: PropTypes.object.isRequired,
  listAutoBidVehicles: PropTypes.func,
  listAllAutoBidVehicles: PropTypes.func,
};

export function mapStateToProps(state) {
  return {
    ui: state.ui,
    auctions: state.auctions,
    user: state.authentication.user,
    allCarsFetched: state.auctions.allCarsFetched,
    historicsLoaded: state.auctions.historicsLoaded,
    allRequestOnGoing: state.auctions.allRequestOnGoing,
    isProfileOutdated: state.user.isRegistrationOutdate,
    loadingHistoricsAuctions: state.auctions.loadingHistoricsAuctions,
    isParticipatingInNewAuctions: state.ui.isParticipatingInNewAuctions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActionsTypes, dispatch),
    auctionsActions: bindActionCreators(auctionsActionsTypes, dispatch),
    listAutoBidVehicles: bindActionCreators(listAutoBidVehicles, dispatch),
    listAllAutoBidVehicles: bindActionCreators(listAllAutoBidVehicles, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuctionsLayout));
