import constants from '../constants/appConstants';
import axios from 'axios';

export const generateDoc = async (id, type) => {
  let config = {
    method: 'post',
    url: `${constants.API.generateDoc}/${id}/type/${type}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.request(config);
  return response.status;
};

export const generateDocExist = async (id, type) => {
  let config = {
    method: 'get',
    url: `${constants.API.generateDoc}/${id}/type/${type}/exists`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.request(config);

  return response.data;
};
