import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${({ enabled }) => (!enabled ? '24px' : 0)};

  @media screen and (max-width: 1025px) {
    align-items: flex-start;
  }
`;

export const VehicleArea = styled.div`
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const BadgeArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-end;
  column-gap: 3rem;

  padding: 0 32px;
  margin-bottom: 24px;

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

export const BadgeAreaTitle = styled.span`
  font-family: Rubik;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.64px;
  text-align: left;
  color: #171c24;
  display: flex;
  margin: 24px 0;
  padding: 0 32px;
  @media screen and (max-width: 1025px) {
    padding: 0 20px;
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    margin-top: 0.5rem;
  }
`;

export const BadgeIcon = styled.img`
  width: 40px;
  height: 40px;

  margin-right: 0.5rem;
`;

export const BadgeContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
`;

export const BadgeTitle = styled.span`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  padding-bottom: 3px;
`;

export const BadgeDescription = styled.span`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  color: #5d6066;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 1.25rem auto;
  border: 0.0625rem solid #eaebec;
`;

export const ScoreLocation = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  padding: 0 32px;

  @media screen and (max-width: 1025px) {
    width: 100%;
    padding: 0 20px;
    margin-top: 8px;
  }
`;

export const SpanRow = styled.span`
  display: flex;
  align-items: center;

  width: 100%;
  margin-bottom: 1rem;

  font: 800 normal 18px/1.5em 'Nunito', sans-serif;
  color: #1a2331;
`;

export const Row = styled.div`
  display: flex;
  gap: 0 160px;

  max-width: 856px;
  width: 100%;

  padding: 0 32px;

  @media screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 8px !important;

  font: 400 normal 16px/1.5em 'Roboto', sans-serif;
  color: #1a2331;
  text-align: end;
`;
