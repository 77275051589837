import styled from 'styled-components';
import { Box } from '../../components';

export const Container = styled(Box)`
  padding: 5rem 0.5rem 0.5rem 0.5rem;
  min-height: calc(90vh - 60px);
  justify-content: center;
  max-width: 1300px;
`;

Container.Title = styled.h5`
  margin-bottom: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #2f3741;
`;

Container.List = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  display: grid;

  @media (max-width: 882px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

Container.Loading = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`;
