import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-height: 100%;
  height: 100%;

  padding: 1.75rem 1.375rem;
`;

export const VehicleNameAndInfos = styled.span`
  margin-bottom: 0.5rem;

  font: 700 normal 1rem/1.281em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const NegotiationExclusivityInfo = styled.span`
  margin-bottom: 1.125rem;

  font: 400 normal 0.75rem/1.5em 'Roboto', sans-serif;
  color: #575756;
`;

export const ErrorMessage = styled.span`
  width: 100%;
  margin-bottom: 0.75rem;

  font: 500 normal 0.9rem/1em 'Roboto', sans-serif;
  color: #ff4c00;
`;

export const CancelButton = styled.button`
  padding: 0.75rem 1.313rem;
  margin-top: 0.75rem;

  background-color: transparent;

  font: 500 normal 1rem/1em 'Roboto', sans-serif;
  color: #2274a5;
  text-align: center;

  border: 2px solid #2274a5;
  border-radius: 0.25rem;

  cursor: pointer;
  outline: none;
`;
