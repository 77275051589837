import React from 'react';
import PropTypes from 'prop-types';

import TabsContext from './TabsContext';

export class TabPanel extends React.PureComponent {
  static propTypes = {
    tabId: PropTypes.string.isRequired,
    tabTitle: PropTypes.node.isRequired,
    children: PropTypes.node,
    shouldPersist: PropTypes.bool,
    tabProps: PropTypes.object,
  };

  static defaultProps = {
    tabProps: {},
  };

  state = {
    shouldRender: this.context.activeTabId === this.props.tabId || !this.props.shouldPersist,
  };

  componentDidMount() {
    const { tabId, tabTitle, tabProps } = this.props;

    this.context.addTab({
      id: tabId,
      title: tabTitle,
      tabProps,
    });
  }

  componentDidUpdate(prevProps) {
    const { tabId, tabTitle, tabProps } = this.props;

    const shouldRender = this.context.activeTabId === tabId;
    if (!this.state.shouldRender && shouldRender) {
      this.setState({ shouldRender: true });
    }

    // Update tab properties if its title change.
    if (prevProps.tabTitle !== tabTitle) {
      this.context.updateTab(tabId, {
        title: tabTitle,
        tabProps: tabProps,
      });
    }
  }

  static contextType = TabsContext;

  render() {
    const { shouldPersist, tabId, children } = this.props;
    const isActive = this.context.activeTabId === tabId;

    if ((!this.state.shouldRender && shouldPersist) || (!isActive && !shouldPersist)) {
      return null;
    }

    return <section style={isActive ? undefined : { display: 'none' }}>{children}</section>;
  }
}
