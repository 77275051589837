import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  flex-shrink: 0;

  max-width: ${({ maxWidth }) => maxWidth}px;
  min-width: ${({ minWidth }) => minWidth}px;
  width: 100%;

  padding-right: 10px;
  padding-bottom: 10px;

  & > div {
    height: 100%;
  }
`;
