import styled from 'styled-components';

export const Container = styled.a`
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ isSelected }) => (isSelected ? '#007E62' : '#b9bbbd')}; 
  height: 48px;
  width: 140px;
  justify-content: center;
  display: flex;
  cursor: pointer;

  ${({ noBorder }) =>
    noBorder &&
    `
    border-left: none;
    border-right: none;
  `}

  ${({ leftRadius }) =>
    leftRadius &&
    `
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  `}

  ${({ rightRadius }) =>
    rightRadius &&
    `
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  `}

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #007E62; 
    color: white;
  `}
`;

export const FiltersButton = styled.div`
  color: ${({ isSelected }) => (isSelected ? '#BCF738' : '#2F3741')};
  font-family: 'Rubik';
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
  font-size: 16px;
  @media screen and (max-width: 1025px) {
    font-size: 14px;
  }
`;
