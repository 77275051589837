import React, { useMemo } from 'react';
import { TextField as _TextField } from '@mui/material';
import styled from 'styled-components';
import _CheckIcon from '@mui/icons-material/Check';
import _WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const statusColor = new Map();
const ERROR_INPUT_STATUS = 'error';
const DEFAULT_INPUT_STATUS = 'default';
const SUCCESS_INPUT_STATUS = 'success';

statusColor.set(ERROR_INPUT_STATUS, '#DF0808');
statusColor.set(DEFAULT_INPUT_STATUS, '#757778');
statusColor.set(SUCCESS_INPUT_STATUS, '#22AA52');

const getColorByProp = props =>
  statusColor.get(props.inputStatus) || statusColor.get(DEFAULT_INPUT_STATUS);

const DecoratedInput = styled(_TextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #22aa52;
    }

    &.Mui-focused fieldset {
      border-color: #757778;
    }
  }

  & input:valid + fieldset {
    border-color: #22aa52;
  }

  & input:invalid + fieldset {
    border-color: #df0808;
  }

  & .MuiInputBase-inputSizeSmall {
    height: 10px;
  }
`;

const CheckIcon = styled(_CheckIcon)`
  color: ${getColorByProp};
`;

const WarningAmberOutlinedIcon = styled(_WarningAmberOutlinedIcon)`
  color: ${getColorByProp};
`;

const CurrentStatus = ({ status }) => {
  if (status === DEFAULT_INPUT_STATUS) return <></>;
  if (status === ERROR_INPUT_STATUS) return <WarningAmberOutlinedIcon inputStatus={status} />;
  if (status === SUCCESS_INPUT_STATUS) return <CheckIcon inputStatus={status} />;

  return <></>;
};

export const TextInput = ({ helperText = ' ', InputProps, inputStatus, ...props }) => {
  const hasError = useMemo(() => inputStatus === ERROR_INPUT_STATUS);
  const _InputProps = Object.assign(
    {
      endAdornment: <CurrentStatus status={inputStatus} />,
      'aria-invalid': inputStatus === ERROR_INPUT_STATUS,
    },
    InputProps
  );
  return (
    <DecoratedInput
      error={hasError}
      InputProps={_InputProps}
      success={inputStatus === SUCCESS_INPUT_STATUS}
      {...props}
    />
  );
};

export const INPUT_STATUS = {
  ERROR_INPUT_STATUS,
  DEFAULT_INPUT_STATUS,
  SUCCESS_INPUT_STATUS,
};
