import initialState from './initialState';
import objectAssign from 'object-assign';
import ActionTypes from '../../constants/actionTypes';
import { sortOldestFirst } from '../../utils/sorting';
import { auctionStatus } from '../../constants/appConstants';
import {
  updateAuctionsOutBid,
  updateAuctionTimer,
  updateDetailedAuction,
} from '../../utils/auction';
import { isEqual } from 'lodash';

function setBlink(state, id, isBlink) {
  let newState = {
    ...state,
    allAuctions: state.allAuctions.map(auction => {
      if (auction._id !== id) return auction;
      return { ...auction, isBlink };
    }),
    newOffers: state.newOffers?.map(auction => {
      if (auction._id !== id) return auction;
      return { ...auction, isBlink };
    }),
    participatingAuctions: state.participatingAuctions?.map(auction => {
      if (auction._id !== id) return auction;
      return { ...auction, isBlink };
    }),
  };

  const { detailedAuction } = newState;

  if (detailedAuction && detailedAuction._id === id) {
    newState = {
      ...newState,
      detailedAuction: {
        ...detailedAuction,
        isBlink,
      },
    };
  }

  return newState;
}

function auctionsReducer(state = initialState.auctions, action) {
  switch (action.type) {
    case ActionTypes.Auction.Start: {
      const allAuctions = state.allAuctions
        .slice()
        .filter(a => a._id !== action.auction._id)
        .concat(action.auction)
        .sort(sortOldestFirst);

      let newOffers = state.newOffers;
      if (!action.newOffersFiltersApplyed) {
        newOffers = state.newOffers
          .slice()
          .filter(a => a._id !== action.auction._id)
          .concat(action.auction)
          .sort(sortOldestFirst);
      }

      return objectAssign({}, state, {
        allAuctions,
        newOffers,
        detailedAuction:
          state.detailedAuction._id === action.auction._id
            ? { id: state.detailedAuction._id, reload: true }
            : state.detailedAuction,
      });
    }
    case ActionTypes.Auction.IsBidding: {
      let modifiedAuction = {};
      const newState = objectAssign({}, state, {
        allAuctions: state.allAuctions.map(auction => {
          if (auction._id === action.id) {
            modifiedAuction = objectAssign({}, auction, {
              auction: objectAssign({}, auction.auction, {
                isBidding: action.isBidding,
              }),
            });
            return modifiedAuction;
          }
          return auction;
        }),
      });

      if (state.detailedAuction._id === action.id) {
        return objectAssign(newState, {
          detailedAuction: objectAssign({}, state.detailedAuction, {
            auction: objectAssign({}, state.detailedAuction.auction, {
              isBidding: action.isBidding,
            }),
          }),
        });
      }
      return newState;
    }
    case ActionTypes.Auction.IsBuyNow: {
      return {
        ...state,
        allAuctions: state.allAuctions.map(auction => {
          if (auction._id === action.id) {
            return objectAssign({}, auction, {
              auction: objectAssign({}, auction.auction, {
                isBuyNow: action.isBuyNow,
              }),
            });
          }
          return auction;
        }),
      };
    }
    case ActionTypes.Auction.OutBid: {
      let modifiedAuction = {};
      let newState = objectAssign({}, state, {
        allAuctions: state.allAuctions.map(auction => {
          if (auction._id === action.id) {
            modifiedAuction = objectAssign({}, auction, {
              auction: objectAssign({}, auction.auction, {
                bids: auction.auction.bids.slice().concat(action.bid),
                winningBid: action.winningBid,
                outBid: action.outBid,
                winning: action.winning,
                participating: action.participating,
              }),
            });
            return modifiedAuction;
          }
          return auction;
        }),
        favorites: state.favorites?.map(auction => {
          if (auction._id === action.id) {
            const modifiedFavorite = objectAssign({}, auction, {
              auction: objectAssign({}, auction.auction, {
                bids: auction.auction.bids.slice().concat(action.bid),
                winningBid: action.winningBid,
                outBid: action.outBid,
                winning: action.winning,
                participating: action.participating,
              }),
            });
            return modifiedFavorite;
          }
          return auction;
        }),
      });
      const [participatingAuctions, newOffers, modified] = updateAuctionsOutBid(state, action);
      newState = objectAssign(newState, {
        newOffers: newOffers,
        participatingAuctions: participatingAuctions,
      });
      modifiedAuction = modified;
      if (modifiedAuction._id === state.confirmationCar._id) {
        newState = objectAssign(newState, { confirmationCar: modifiedAuction });
      }
      if (action.showSuccessMessage) {
        newState = objectAssign(newState, { showSuccessMessage: true, biddedAuctionId: action.id });
      }
      return newState;
    }
    case ActionTypes.Auction.RemoveSuccessBidMessage: {
      return {
        ...state,
        showSuccessMessage: false,
        biddedAuctionId: null,
      };
    }
    case ActionTypes.Auction.SetBlink: {
      return setBlink(state, action.id, true);
    }
    case ActionTypes.Auction.RemoveBlink: {
      return setBlink(state, action.id, false);
    }
    case ActionTypes.Auction.SetDuration: {
      let _newState = objectAssign({}, state, {
        allAuctions: state.allAuctions
          .map(auction => {
            if (auction._id !== action.id) return auction;
            return updateAuctionTimer(auction, action);
          })
          .sort(sortOldestFirst),
        favorites: state.favorites.map(auction => {
          if (auction._id !== action.id) return auction;
          return updateAuctionTimer(auction, action);
        }),
        newOffers: state.newOffers?.map(auction => {
          if (auction._id !== action.id) return auction;
          return updateAuctionTimer(auction, action);
        }),
        participatingAuctions: state.participatingAuctions?.map(auction => {
          if (auction._id !== action.id) return auction;
          return updateAuctionTimer(auction, action);
        }),
      });

      if (state.detailedAuction._id === action.id) {
        _newState = objectAssign(_newState, {
          detailedAuction: objectAssign({}, state.detailedAuction, {
            elapsedSinceLastRequest: action.elapsedSinceLastRequest,
            auction: objectAssign({}, state.detailedAuction.auction, {
              remainingTime: action.remainingTime,
              expectedEndTime:
                action.endTime || Number(state.detailedAuction.auction.expectedEndTime),
            }),
          }),
        });
      }
      return _newState;
    }
    case ActionTypes.Auction.RemoveSingle:
      return {
        ...state,
        detailedAuction: {},
      };
    case ActionTypes.Auction.BuyNow.Won:
    case ActionTypes.Auction.Won:
      const nState = objectAssign({}, state, {
        allAuctions: state.allAuctions.map(auction => {
          if (auction._id === action.id) {
            const bids = auction.auction.bids || [];
            const _auction = objectAssign({}, auction, {
              summary: {
                ...auction.summary,
                status: 'ON_OFFER',
              },
              auction: objectAssign({}, auction.auction, {
                onGoing: false,
                won: action.won,
                lost: action.lost,
                outBid: false,
                showMakeOffer: false,
                bids: action.bid ? bids.concat([action.bid]) : bids,
                winningBid: action.bid ? action.bid : auction.auction.winningBid,
                winning: action.won,
              }),
            });
            return _auction;
          }
          return auction;
        }),
      });

      if (state.selectedAuctionId === action.id) {
        return objectAssign(nState, {
          selectedAuctionId: null,
        });
      }

      if (state.detailedAuction._id === action.id) {
        return objectAssign(nState, {
          detailedAuction: objectAssign({}, state.detailedAuction, {
            summary: {
              ...state.detailedAuction.summary,
              status: 'ON_OFFER',
            },
            auction: objectAssign({}, state.detailedAuction.auction, {
              onGoing: false,
              status: 'ON_OFFER',
            }),
          }),
        });
      }
      return nState;
    case ActionTypes.Auction.End:
      let _nState = {};

      const updatedStatus = state.allAuctions.map(v => {
        if (isEqual(v, action.id))
          return Object.assign({}, v, {
            auction: Object.assign({}, auction.auction, { status: 'ON_OFFER' }),
          });
        else return v;
      });

      const filteredAuctions = updatedStatus.filter(v => v._id !== action.id);
      const filteredNewOffers = state.newOffers.filter(v => v._id !== action.id);
      const filteredParticipating = state.participatingAuctions.filter(v => v._id !== action.id);

      _nState = objectAssign({}, state, {
        allAuctions: filteredAuctions,
        newOffers: filteredNewOffers,
        participatingAuctions: filteredParticipating,
      });

      if (state.selectedAuctionId === action.id) {
        _nState = objectAssign(_nState, {
          selectedAuctionId: null,
        });
      }

      if (state.detailedAuction._id === action.id) {
        _nState = objectAssign(_nState, {
          detailedAuction: objectAssign({}, state.detailedAuction, {
            auction: objectAssign({}, state.detailedAuction.auction, {
              onGoing: false,
              status: 'ON_OFFER',
            }),
          }),
        });
      }

      _nState = objectAssign({}, _nState, {
        favorites: state.favorites.map(auction => {
          if (auction._id === action.id) {
            return objectAssign({}, auction, {
              auction: objectAssign({}, auction.auction, {
                status: 'FINISHED',
              }),
            });
          }
          return auction;
        }),
      });

      return _nState;
    case ActionTypes.Auction.FetchedALl: {
      const newState = { ...state };
      let detailedAuction = { ...state.detailedAuction };
      return {
        ...newState,
        allAuctions: action.auctions.sort(sortOldestFirst).map(auction => {
          if (state.detailedAuction && state.detailedAuction._id === auction._id) {
            detailedAuction = {
              ...detailedAuction,
              auction: {
                ...detailedAuction.auction,
                remainingTime: auction.auction.remainingTime,
              },
              elapsedSinceLastRequest: auction.elapsedSinceLastRequest,
            };
          }
          return auction;
        }),
        //allRequestOnGoing: false,
        //allCarsFetched: true,
        detailedAuction,
      };
    }
    case ActionTypes.Auction.FetchedNewOffers: {
      const newState = { ...state };
      let detailedAuction = { ...state.detailedAuction };
      return {
        ...newState,
        newOffers: action.newOffers.sort(sortOldestFirst).map(auction => {
          if (state.detailedAuction && state.detailedAuction._id === auction._id) {
            detailedAuction = updateDetailedAuction(detailedAuction, auction);
          }
          return auction;
        }),
        //allRequestOnGoing: false,
        //allCarsFetched: true,
        detailedAuction,
      };
    }
    case ActionTypes.Auction.FetchedParticipatingAuctions: {
      const newState = { ...state };
      let detailedAuction = { ...state.detailedAuction };
      return {
        ...newState,
        participatingAuctions: action.participatingAuctions.sort(sortOldestFirst).map(auction => {
          if (state.detailedAuction && state.detailedAuction._id === auction._id) {
            detailedAuction = updateDetailedAuction(detailedAuction, auction);
          }
          return auction;
        }),
        // allRequestOnGoing: false,
        //allCarsFetched: true,
        detailedAuction,
      };
    }
    case ActionTypes.Auction.SetOutBid:
      return objectAssign({}, state, {
        allAuctions: state.allAuctions.map(auction => {
          if (auction._id === action.id) {
            return objectAssign({}, auction, {
              auction: objectAssign({}, auction.auction, {
                outBid: action.outBid,
              }),
            });
          }
          return auction;
        }),
      });
    case ActionTypes.Auction.SetSelected: {
      const { auction, id } = action;
      return {
        ...state,
        selectedAuctionId: auction._id || id,
        selectedAuctionRemainingTime: auction.remainingTime,
      };
    }
    case ActionTypes.Auction.ClearBidSucess:
      return objectAssign({}, state, {
        bidSuccess: false,
      });
    case ActionTypes.Auction.BidSucesss:
      return objectAssign({}, state, {
        bidSuccess: true,
        bidError: false,
        bidErrorMessage: '',
        currentAuction: action.currentAuction || null,
      });

    case ActionTypes.Auction.BidError:
      return objectAssign({}, state, {
        bidError: !!action.error,
        bidErrorMessage: action.error,
      });
    case ActionTypes.Auction.FetchedSingle:
      let newState = objectAssign({}, state, {
        detailedAuction: action.auction,
        singleRequestOnGoing: false,
      });

      newState = {
        ...newState,
        allAuctions: newState.allAuctions.map(auction => {
          if (auction._id === action.auction._id) {
            return {
              ...auction,
              auction: {
                ...auction.auction,
                remainingTime: action.auction.auction.remainingTime,
              },
              elapsedSinceLastRequest: action.auction.elapsedSinceLastRequest,
            };
          }
          return auction;
        }),
      };

      return newState;
    case ActionTypes.Auction.OutBid_Details:
      const detailedAuction = objectAssign({}, state.detailedAuction, {
        auction: objectAssign({}, state.detailedAuction.auction, {
          bids: state.detailedAuction.auction.bids.slice(0).concat(action.bid),
          winningBid: action.bid,
          winning: action.winning,
          participating: action.participating,
        }),
      });
      return objectAssign({}, state, {
        detailedAuction: detailedAuction,
        confirmationCar: detailedAuction,
      });
    case ActionTypes.Auction.DeleteAutoBid:
      const auction = objectAssign(
        {},
        state.allAuctions.find(a => a._id === action.id)
      );
      return objectAssign({}, state, {
        allAuctions: state.allAuctions.map(a => {
          if (a._id === auction._id) {
            return objectAssign({}, a, {
              auction: objectAssign({}, auction.auction, {
                autoBids: auction.auction.autoBids.filter(
                  ab => ab.dealership !== action.dealershipName
                ),
              }),
            });
          }
          return a;
        }),
      });
    case ActionTypes.Auction.SetAutoBid:
      const auctionToSetAutoBid = objectAssign(
        {},
        state.allAuctions.find(a => a._id === action.id)
      );
      return objectAssign({}, state, {
        allAuctions: state.allAuctions.map(a => {
          if (a._id === auctionToSetAutoBid._id) {
            return objectAssign({}, a, {
              auction: objectAssign({}, auctionToSetAutoBid.auction, {
                autoBids: auctionToSetAutoBid.auction.autoBids
                  .filter(ab => ab.dealership !== action.dealership)
                  .concat([action.autoBid]),
              }),
            });
          }
          return a;
        }),
      });

    case ActionTypes.Auction.SingleRequestStarted:
      return objectAssign({}, state, { singleRequestOnGoing: true });
    case ActionTypes.Auction.AllRequestStarted:
      return objectAssign({}, state, { allRequestOnGoing: true });
    case ActionTypes.Auction.AllRequestEnded:
      return objectAssign({}, state, { allRequestOnGoing: false, allCarsFetched: true });
    case ActionTypes.Auction.FetchAuctionsHistory:
      return objectAssign({}, state, { loadingHistoricsAuctions: true });
    case ActionTypes.Auction.FetchAuctionsHistorySuccess:
      return objectAssign({}, state, {
        loadingHistoricsAuctions: false,
        historicsLoaded: true,
      });
    case ActionTypes.Auction.FetchAuctionsNegotiatedSuccess:
      return {
        ...state,
        allAuctions: [...state.allAuctions, ...action.auctions],
      };
    case ActionTypes.Auction.SetFavorites:
      return {
        ...state,
        favorites: [...action.favorites],
      };
    case ActionTypes.Modal.MakeBidConfirmation.Toggle:
      return objectAssign({}, state, {
        showMakeBidConfirmationModal: action.toggle,
      });
    case ActionTypes.Auction.SetConfirmationCar:
      return objectAssign({}, state, {
        confirmationCar: action.confirmationCar,
      });
    case ActionTypes.Auction.CalculateAutoBid:
      if (!action.auction.auction) {
        return objectAssign({}, state, {
          autoBid: { isSet: false, amount: null },
        });
      }

      const autoBid =
        action.auction.auction.autoBids.find(a => a.dealership === action.dealership) || {};
      const isSet = autoBid.amount > 0;
      const amount = isSet ? parseInt((autoBid.amount + '').replace(/\./g, ''), 10) : null;
      return objectAssign({}, state, { autoBid: { isSet, amount } });
    case ActionTypes.Negotiations.Ended: {
      const newStatus = action.accept ? auctionStatus.offerAccepted : auctionStatus.offerDeclined;
      const auctions = objectAssign({}, state, {
        allAuctions: state.allAuctions.map(a => {
          if (a._id === action.auction._id) {
            return objectAssign({}, a, {
              auction: objectAssign({}, a.auction, { status: newStatus }),
              summary: {
                ...a.summary,
                status: newStatus,
              },
            });
          }
          return a;
        }),
      });
      if (state.detailedAuction._id === action.auction._id) {
        return objectAssign(auctions, {
          detailedAuction: objectAssign({}, state.detailedAuction, {
            auction: objectAssign({}, state.detailedAuction.auction, {
              status: newStatus,
            }),
            summary: {
              ...state.detailedAuction.summary,
              status: newStatus,
            },
          }),
        });
      }
      return auctions;
    }
    case ActionTypes.Negotiations.Started: {
      const newStatus = 'OFFER_ONGOING';
      const auctions = objectAssign({}, state, {
        allAuctions: state.allAuctions.map(a => {
          if (a._id === action.auction._id) {
            return objectAssign({}, a, {
              auction: objectAssign({}, a.auction, { status: newStatus }),
            });
          }
          return a;
        }),
      });
      if (state.detailedAuction._id === action.auction._id) {
        return objectAssign(auctions, {
          detailedAuction: objectAssign({}, state.detailedAuction, {
            auction: objectAssign({}, state.detailedAuction.auction, {
              status: newStatus,
            }),
          }),
        });
      }
      return auctions;
    }
    case ActionTypes.Auction.ClearAuction:
      return objectAssign({}, state, {
        allAuctions: state.allAuctions.slice().filter(a => {
          return a._id !== action.id;
        }),
      });
    case ActionTypes.Auction.ClearAuctions:
      return objectAssign({}, state, {
        allAuctions: [],
        historicsLoaded: false,
        loadingHistoricsAuctions: false,
        allCarsFetched: false,
      });
    case ActionTypes.Auction.Cancel:
      return objectAssign({}, state, {
        allAuctions: state.allAuctions.slice(0).filter(a => a._id !== action.id),
      });
    case ActionTypes.Auction.BuyNow.Update:
      const buyNowAuctions = objectAssign({}, state, {
        allAuctions: state.allAuctions.map(a => {
          if (a._id === action.data.id) {
            return objectAssign({}, a, {
              summary: objectAssign({}, a.summary, {
                buynowPrice: action.data.prices.buyNow || {
                  amount: 0,
                  enabled: false,
                },
              }),
            });
          }
          return a;
        }),
        newOffers: state.newOffers?.map(auction => {
          if (auction._id !== action.data.id) return auction;
          return objectAssign({}, auction, {
            summary: objectAssign({}, auction.summary, {
              buynowPrice: action.data.prices.buyNow || {
                amount: 0,
                enabled: false,
              },
            }),
          });
        }),
        participatingAuctions: state.participatingAuctions?.map(auction => {
          if (auction._id !== action.data.id) return auction;
          return objectAssign({}, auction, {
            summary: objectAssign({}, auction.summary, {
              buynowPrice: action.data.prices.buyNow || {
                amount: 0,
                enabled: false,
              },
            }),
          });
        }),
      });

      if (state.detailedAuction._id === action.data.id) {
        return objectAssign(buyNowAuctions, {
          detailedAuction: objectAssign({}, state.detailedAuction, {
            summary: objectAssign({}, state.detailedAuction.summary, {
              buynowPrice: action.data.prices.buyNow || {
                amount: 0,
                enabled: false,
              },
            }),
          }),
        });
      }
      return buyNowAuctions;

    case ActionTypes.Auction.BuyFor.Enabled:
      const buyForAuctions = objectAssign({}, state, {
        allAuctions: state.allAuctions.map(a => {
          if (a._id === action.payload.id) {
            return objectAssign({}, a, {
              summary: objectAssign({}, a.summary, {
                buyForPrice: action.payload.prices.buyFor || {
                  amount: 0,
                  enabled: false,
                },
              }),
            });
          }
          return a;
        }),

        newOffers: state.newOffers?.map(auction => {
          if (auction._id !== action.payload.id) return auction;
          return objectAssign({}, auction, {
            summary: objectAssign({}, auction.summary, {
              buyForPrice: action.payload.prices.buyFor || {
                amount: 0,
                enabled: false,
              },
            }),
          });
        }),

        participatingAuctions: state.participatingAuctions?.map(auction => {
          if (auction._id !== action.payload.id) return auction;
          return objectAssign({}, auction, {
            summary: objectAssign({}, auction.summary, {
              buyForPrice: action.payload.prices.buyFor || {
                amount: 0,
                enabled: false,
              },
            }),
          });
        }),
      });

      if (state.detailedAuction._id === action.payload.id) {
        return objectAssign(buyForAuctions, {
          detailedAuction: objectAssign({}, state.detailedAuction, {
            summary: objectAssign({}, state.detailedAuction.summary, {
              buyForPrice: action.payload.prices.buyFor || {
                amount: 0,
                enabled: false,
              },
            }),
          }),
        });
      }
      return buyForAuctions;

    case ActionTypes.Auction.SetRecommendedCarsIds:
      return { ...state, recommendedCarsId: [...action.payload.recommendedCarsId] };

    case ActionTypes.Auction.AddRecommendedCarsId:
      return { ...state, recommendedCarsId: [...state.recommendedCarsId, action.payload.id] };

    default:
      return state;
  }
}

export default auctionsReducer;
