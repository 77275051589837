import * as yup from 'yup';

const phoneRegExp = /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/;
const MANDATORY_FIELD_MESSAGE = 'Este campo é obrigatório o informar';
const phoneSchema = yup.string().matches(phoneRegExp, { message: 'Formato de telefone inválido' });

export const profileUpdationSchema = yup.object().shape({
  companyPhone: phoneSchema.required(MANDATORY_FIELD_MESSAGE),
  phoneNumber: phoneSchema.required(MANDATORY_FIELD_MESSAGE),
});
