import styled, { css } from 'styled-components';

export const Tag = styled.div`
  position: absolute;
  top: 15px;
  left: 10px;

  padding: 6px;

  font: 700 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #438a4c;

  background-color: #e2f1e6;
  border-radius: 4px;

  z-index: 1;

  ${({ isDetails }) =>
    isDetails &&
    css`
      position: static;
      color: #fff;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 2px;
    `}
`;
