import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';
import ReactModal from 'react-modal';

import { Box, Text } from '../../abstract';
import { CloseButton } from './CloseButton';
import { CarouselPhoto } from '../../index';

const Tumbnail = styled(Carousel)`
  margin-top: 10px;
  .BrainhubCarouselItem {
    margin-right: 8px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }

  .BrainhubCarouselItem--active {
    opacity: 1;
    border: 2px solid #ffffff;
    border-radius: 4px;
  }

  img {
    height: 5rem;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 4px;
  }
`;

const Centered = styled(Box).attrs({ top: { sm: '8rem', md: '110px' } })`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const HeaderWrapper = styled(Box).attrs({
  color: 'primaries.Taxi',
  left: { sm: '10px', md: '48px' },
  right: { sm: '40px', md: '94px' },
})`
  top: 20px;
`;

const SlideFooter = styled(Box)`
  width: 100%;
  color: #ffffff;
  padding-top: 1px;
  margin-top: -1px;
`;

const ImgDescription = styled.div`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  color: white;
  padding-top: 15px;
`;

const TumbContainer = styled.div`
  margin-top: 30px;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const CategoryList = styled.div`
  margin-right: 15px;
  background: ${({ isSelected }) =>
    isSelected
      ? 'linear-gradient(to bottom, transparent 90%, #33aef8 10%)' // Feito para funcionar no navegador safari
      : 'transparent'};
  cursor: pointer;
`;

const CategoryText = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  width: max-content;
`;

export const PhotoGallery = ({
  slideIndex = 0,
  images,
  photos,
  Header,
  Footer,
  onDismiss,
  categoryPhotos,
  categorySelected,
  openCarousel,
  nextCategoryGalery,
}) => {
  const [slide, setSlide] = useState(slideIndex);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const total = images ? images.length : photos.length;

  const groups = categoryPhotos?.items;
  const groupNames = groups?.map(group => group.group);

  const handleCategoryClick = index => {
    setSelectedCategory(index);
    setSlide(0);
  };

  useLayoutEffect(() => {
    setSlide(slideIndex);
  }, [categorySelected]);

  const selectedGroupItems =
    selectedCategory !== null ? groups !== undefined && groups[selectedCategory]?.items : [];

  const filteredDescription = Array.isArray(selectedGroupItems)
    ? selectedGroupItems
        .filter(item => item?.description !== null && item?.img !== null)
        .map(item => item?.description)
    : [];

  const filteredImages = Array.isArray(selectedGroupItems)
    ? selectedGroupItems.filter(item => item?.img !== null).map(item => item?.img?.src)
    : [];

  const selectedCategoryRef = useRef(null);

  useEffect(() => {
    if (selectedCategoryRef.current) {
      selectedCategoryRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (
      slide > filteredImages?.length - 1 &&
      categorySelected !== 0 &&
      nextCategoryGalery === true
    ) {
      const nextCategory = (selectedCategory + 1) % groups?.length;
      setSelectedCategory(nextCategory);
      setSlide(0);
    } else if (slide === -1) {
      const prevCategory = (selectedCategory - 1 + groups?.length) % groups?.length;
      setSelectedCategory(prevCategory);

      const prevFilteredImages =
        groups[prevCategory]?.items
          ?.filter(item => item?.img !== null)
          .map(item => item?.img?.src) || [];

      setSlide(prevFilteredImages.length - 1);
    }
  }, [slide, filteredImages?.length, selectedCategory, groups?.length]);

  return (
    <ReactModal
      onRequestClose={onDismiss}
      style={{
        content: {
          position: 'absolute',
          background: 'transparent',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          padding: 0,
          zIndex: 99992,
          border: 0,
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.95)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 99991,
        },
      }}
      isOpen
    >
      <CloseButton onClick={onDismiss} />
      <HeaderWrapper>{Header}</HeaderWrapper>
      <Centered width={{ sm: 1, md: '35.5%' }}>
        <Box position="relative" width={1}>
          {filteredImages.length > 0 && (
            <Text
              as="h1"
              fontSize={3}
              color="white"
              p={{
                sm: 1,
                md: 0,
              }}
              mb={{
                sm: 0,
                md: 2,
              }}
            >
              {(filteredImages[slide] && filteredImages[slide].description) || ''}
            </Text>
          )}
          <CarouselPhoto
            counter
            active={slide}
            bigArrows
            height={{ sm: '35.3vh', md: '50.5vh', lg: '51.5vh' }}
            photos={images ? images : categorySelected !== 0 ? filteredImages : photos}
            onChange={setSlide}
            openCarousel={openCarousel}
            categorySelected={categorySelected}
          />
        </Box>
        {categorySelected !== 0 && (
          <ImgDescription>
            {filteredDescription[slide] || (images && images[slide].description) || ''}
          </ImgDescription>
        )}
        <TumbContainer>
          {categorySelected !== 0 && (
            <CategoryContainer>
              {groupNames?.map((name, index) => (
                <CategoryList
                  key={index}
                  isSelected={selectedCategory === index}
                  onClick={() => handleCategoryClick(index)}
                  ref={selectedCategory === index ? selectedCategoryRef : null}
                >
                  <CategoryText>{name}</CategoryText>
                </CategoryList>
              ))}
            </CategoryContainer>
          )}
          {total > 1 && (
            <Tumbnail
              value={slide}
              slidesPerPage={8}
              onChange={setSlide}
              breakpoints={{
                780: {
                  slidesPerPage: 5,
                },
                500: {
                  slidesPerPage: 3,
                },
              }}
            >
              {categorySelected !== 0
                ? filteredImages.map((src, i) => (
                    <img
                      key={i}
                      src={src}
                      alt={`Imagem ${i + 1}`}
                      onClick={() => {
                        setSlide(i);
                      }}
                    />
                  ))
                : images
                ? images.map((photo, i) => (
                    <img
                      key={photo.img.alt}
                      src={photo.img.src}
                      alt={photo.img.alt}
                      onClick={() => {
                        setSlide(i);
                      }}
                    />
                  ))
                : photos.map((photo, i) => (
                    <img
                      src={photo}
                      alt={photo}
                      key={photo}
                      onClick={() => {
                        setSlide(i);
                      }}
                    />
                  ))}
            </Tumbnail>
          )}
        </TumbContainer>
      </Centered>
    </ReactModal>
  );
};
