import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: inline-block;

  cursor: pointer;

  .Tooltip-Tip {
    position: absolute;
    left: 95px;

    padding: 1.125rem;

    font: 400 normal 1rem/1.3em 'Roboto', sans-serif;
    color: #2f3741;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: 3px 5px 19px rgba(0, 0, 0, 0.25);

    transform: translateX(-50%);
    white-space: nowrap;

    z-index: 100;

    @media screen and (max-width: 1441px) {
      left: 0;
    }
  }

  .Tooltip-Tip::before {
    content: ' ';

    position: absolute;
    left: 22%;

    width: 0;
    height: 0;

    margin-left: calc(6px * -1);

    border: 6px solid transparent;

    pointer-events: none;
  }

  .Tooltip-Tip.top {
    top: calc(170px * -1);
  }

  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: #fff;

    @media screen and (max-width: 1441px) {
      left: 53%;
    }
  }

  .Tooltip-Tip.right {
    left: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .Tooltip-Tip.right::before {
    left: calc(6px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: #fff;
  }

  .Tooltip-Tip.bottom {
    bottom: calc(165px * -1);
  }

  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: #fff;

    @media screen and (max-width: 1441px) {
      left: 53%;
    }
  }

  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(6px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: #fff;
  }
`;
