import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import { Text } from '../../../abstract';
import { Price } from '../../Price';

const LastBid = ({ price, label = 'Valor final' }) => (
  <React.Fragment>
    <Text fontSize={rem(10)}>{label}</Text>
    <Price value={price} color="neutrals.EclipseE800" fontSize={{ sm: 4, xlg: 5 }} />
  </React.Fragment>
);

LastBid.propTypes = {
  price: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default LastBid;
