import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { secondsToHHMMSS } from '../../../utils/time';

export class AuctionTimer extends Component {
  getProps = () => {
    const { auction, elapsedTime } = this.props;
    const { isBlink = false } = auction;

    const auctionDetails = auction.auction || {};
    const currentTimeStamp = new Date().getTime();

    const startTime = Number(auctionDetails.startTime);
    const expectedEndTime = Number(auctionDetails.expectedEndTime);
    const secondsLeft = (Number(auctionDetails.expectedEndTime) - currentTimeStamp) / 1000;
    const isBlinking = isBlink || (secondsLeft > 0 && secondsLeft < 10);
    const totalSeconds = (expectedEndTime - startTime) / 1000;
    const isStop = !auctionDetails.onGoing;

    return {
      formattedRemainingTime: secondsToHHMMSS(secondsLeft),
      isBlinking,
      secondsLeft,
      totalSeconds,
      isStop,
    };
  };

  render() {
    return this.props.children(this.getProps());
  }
}

AuctionTimer.propTypes = {
  children: PropTypes.func.isRequired,
  auction: PropTypes.object.isRequired,
  elapsedTime: PropTypes.number.isRequired,
};

// TODO: Create a selector to get these properties from store's timer key.
const mapStateToProps = state => ({
  blink: state.timer.blink,
  elapsedTime: state.timer.elapsedTime,
});

export default connect(mapStateToProps)(AuctionTimer);
