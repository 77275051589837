export default {
  PusherKey: '946bd696994b61fce258',
  ISC_API_KEY: 'CMKStF9fJpq8judYTn',
  GTM: 'GTM-TV892P7',
  Segment: 'zNwfm1Npxl7Q6p2ErOswkEzg6QJbOdyq',
  CustomerIOSiteID: 'abc0abe1b58571f91f6d',
  TrackingID: 'UA-66374966-12',
  Hotjar: 1149277,
  Sentry: 'https://faefac7a8b3341edb43cf886cee00ac3@sentry.instacarro.com/21',
  baseURLs: {
    authentication: 'https://api.instacarro.com/accounts',
    newAuthentication: 'https://apigateway.instacarro.com/api/evaluation_account/v1/user',
    buyers: 'https://api.instacarro.com/buyers/v2',
    dealers: 'https://api.instacarro.com/dealers/v3',
    sales: 'https://api.instacarro.com/buyers/v2',
    buyersV3: 'https://api.instacarro.com/buyers/v3',
    accounts: 'https://api.instacarro.com/accounts',
    metrics: 'http://api.instacarro.com/managers/v1/metrics',
    internals: 'https://api.instacarro.com/internals',
    ninja_dashboard: 'https://api.instacarro.com/ninja-dashboard-records/dashboard',
    mechanics: 'https://api.instacarro.com/mechanics',
    logistics: 'https://api.instacarro.com/logistics',
    icApiDealers: 'https://api.instacarro.com/ic-api-dealers/v1/dealers',
    baseIcApiDealers: 'https://api.instacarro.com/ic-api-dealers',
    auQueries: 'https://api.instacarro.com/au_queries/v1',
    autobid: 'https://api.instacarro.com/au_managements',
    preferences: 'https://apigateway.instacarro.com/notification_api/order-cars',
    fincredit: 'https://api.instacarro.com/fincredit_simulator_api/api',
    generateDoc: 'https://api.instacarro.com/v1/pipelines/report/dealer/docs',
  },
  MixpanelToke: '20bc00aeb623fdab4a2633bd1e85d09c',
  Debug: false,
  IndiqueLin: 'https://cupom.instacarro.com/lojistas',
  GA_Dimentions: {
    dealership_name: 'dimension3',
    auction_id: 'dimension5',
    dealership_id: 'dimension6',
    bid_amount: 'dimension7',
    bid_type: 'dimension8',
    dealership_email: 'dimension9',
    selectedCategory: 'dimension10',
    carResults: 'dimension11',
    interactionType: 'dimension12',
  },
  DOMAIN_APP: '.leilao.instacarro.com',
};
