import React, { useState, useEffect, useCallback } from 'react';
import {
  Text,
  DropContainer,
  Container,
  IconsDiv,
  SubTextDiv,
  InfoContainer,
  DateDiv,
  UpdateText,
  CardOpenInfo,
  InfoText,
  SubInfoDiv,
  AuthorizationLink,
  SubIndoDivLink,
  CardSlugContainer,
  ArrowContainer,
} from './styles';
import BlockClockIcon from '../../../../images/icons/blockClockIcon.svg';
import SubCheckIcon from '../../../../images/icons/subCheckIcon.svg';
import SubWaitIcon from '../../../../images/icons/subWaitIcon.svg';
import CalendarIcon from '../../../../images/icons/fi-bs-calendar.svg';
import OpenIcon from '../../../../images/icons/open-sub.svg';
import CloseIcon from '../../../../images/icons/close-sub.svg';
import { usePurchaseTime } from '../../../../hooks/usePurchaseTime';

export const SubCardItem = ({ sub, all }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <DropContainer
        backgroundColor={sub.active ? '#FFFFFF' : '#F3F3EF'}
        onClick={sub.active ? handleDropdownToggle : null}
      >
        <CardOpenInfo>
          <SubTextDiv>
            {sub.active ? (
              <IconsDiv>
                <img
                  className="icon"
                  src={sub.icon === 'wait' ? SubWaitIcon : SubCheckIcon}
                  alt="ícone"
                />
              </IconsDiv>
            ) : (
              <IconsDiv>
                <img className="icon" src={BlockClockIcon} alt="ícone" />
              </IconsDiv>
            )}
            <CardSlugContainer>
              <Text color={sub.active ? '#2f3741' : '#B9BBBD'} weight={sub.active ? 500 : 400}>
                {sub.msg.main}
              </Text>
              {sub.msg.sub && sub.active && (
                <ArrowContainer>
                  {isOpen ? (
                    <img className="icon" src={CloseIcon} alt="ícone" />
                  ) : (
                    <img className="icon" src={OpenIcon} alt="ícone" />
                  )}
                </ArrowContainer>
              )}
            </CardSlugContainer>
          </SubTextDiv>
          {sub.active && (
            <InfoContainer>
              <DateDiv>
                <IconsDiv>
                  <img className="icon" src={CalendarIcon} alt="ícone" />
                </IconsDiv>
                <UpdateText>{usePurchaseTime(sub.updateTime)}</UpdateText>
              </DateDiv>
              {sub.msg.sub && isOpen && (
                <SubIndoDivLink>
                  <SubInfoDiv>
                    <InfoText>{sub.msg.sub}</InfoText>
                  </SubInfoDiv>
                  <AuthorizationLink onClick={() => window.open(sub.msg.link, '_blank')}>
                    {sub.approvalLink}
                  </AuthorizationLink>
                </SubIndoDivLink>
              )}
            </InfoContainer>
          )}
        </CardOpenInfo>
      </DropContainer>
    </Container>
  );
};
