export function errorsTranslation(erro) {
  switch (erro) {
    case 'Upload file error':
      return 'Tivemos um problema com o arquivo anexado. Isso pode acontecer por conta do tipo de arquivo ou por conta de um problema de conectividade. Verifique esses pontos e tente novamente.';
    case 'taxIdentificatioNumber was already inserted.':
      return 'CPF já cadastrado';
    case 'personalIdRG was already inserted.':
      return 'RG já cadastrado';
    case 'An error ocurred fetching the uploadURL from S3.':
      return 'Ocorreu um erro ao buscar o URL de upload do S3.';
    default:
      return 'Erro ao realizar o cadastro do perfil';
  }
}
