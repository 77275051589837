import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ParticipatingFilters from '../..';
import { handleFilters } from '../../../../../store/actions/auction.actions';
import ModalTemplate from '../../../components/modal-template';

import { Container } from './styles';

function ParticipatingMobileTemplate({ show, closeFilters }) {
  function handleClose() {
    closeFilters({ showParticipatingFilters: false });
  }
  return (
    <Container className={show ? 'show' : ''}>
      <ModalTemplate handleCloseModal={handleClose}>
        <ParticipatingFilters />
      </ModalTemplate>
    </Container>
  );
}

function mapStateToProps({ ui }) {
  return {
    show: ui.filters.showParticipatingFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeFilters: bindActionCreators(handleFilters, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipatingMobileTemplate);
