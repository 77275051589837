import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from '../base-modal/BaseModal.jsx';
import CountDownTimer from '../count-down-timer/CountDownTimer';

// import { formatToThousands } from '../../../utils/number';

import './MakeBidConfirmationModal.scss';

class MakeBidConfirmationModal extends React.Component {
  render() {
    const { toggle, selectedCar } = this.props;

    const handleClick = ev => {
      ev.preventDefault();
      toggle(false);
    };

    if (!selectedCar || !selectedCar._id) {
      return null;
    }

    const { summary } = selectedCar;
    const actualAuction = selectedCar.auction;

    return (
      <div className="make-bid-confirmation">
        <div className="auction-details row">
          <div className="col-xs-12 car-title">
            <h2>{`${summary.make} ${summary.model}`}</h2>
          </div>
          <div className="col-xs-12 year-km-row">
            {`${summary.yearLabel} | ${summary.kmLabel}`}
          </div>
        </div>
        <div className="make-bid-confirmation row">
          <h2>
            <i className="icon-Check" /> Oferta realizada
          </h2>
          <p>Seu lance está sendo processado!</p>
          <p>
            Acompanhe o andamento deste leilão na área{' '}
            <strong>Meus Leilões</strong>, na página inicial.
          </p>
          <p>
            Tempo restante:
            <CountDownTimer
              isOnGoing={actualAuction.onGoing}
              remainingTime={actualAuction.remainingTime}
              showRemainingTime
              isBlink={selectedCar.isBlink}
              elapsedSinceLastRequest={selectedCar.elapsedSinceLastRequest}
            />!
          </p>
        </div>
        <div className="make-bid-confirmation-button row">
          <div className="col-xs-12">
            <button onClick={handleClick}>Fechar</button>
          </div>
        </div>
      </div>
    );
  }
}

MakeBidConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  selectedCar: PropTypes.object.isRequired,
};

export default BaseModal(MakeBidConfirmationModal);
