import VOLKSWAGEN from '../../../../images/brands/VOLKSWAGEN-logo.png';
import MERCEDESBENZ from '../../../../images/brands/MERCEDES-logo.png';
import MITSUBISHI from '../../../../images/brands/MITSUBISHI-logo.png';
import LANDROVER from '../../../../images/brands/LANDROVER-logo.png';
import CHEVROLET from '../../../../images/brands/CHEVROLET-logo.png';
import PLACEHOLDER from '../../../../images/brands/placeholder.png';
import CHRYSLER from '../../../../images/brands/CHRYSLER-logo.png';
import CITROËN from '../../../../images/brands/CITROEN-logo.png';
import HYUNDAI from '../../../../images/brands/HYUNDAI-logo.png';
import PEUGEOT from '../../../../images/brands/PEUGEOT-logo.png';
import RENAULT from '../../../../images/brands/RENAULT-logo.png';
import PORSCHE from '../../../../images/brands/PORSCHE-logo.png';
import TROLLER from '../../../../images/brands/TROLLER-logo.png';
import NISSAN from '../../../../images/brands/NISSAN-logo.png';
import TOYOTA from '../../../../images/brands/TOYOTA-logo.png';
import SUBARU from '../../../../images/brands/SUBARU-logo.png';
import SUZUKI from '../../../../images/brands/SUZUKI-logo.png';
import JAGUAR from '../../../../images/brands/JAGUAR-logo.png';
import HONDA from '../../../../images/brands/HONDA-logo.png';
import DODGE from '../../../../images/brands/DODGE-logo.png';
import HAFEI from '../../../../images/brands/HAFEI-logo.png';
import VOLVO from '../../../../images/brands/VOLVO-logo.png';
import SMART from '../../../../images/brands/SMART-logo.png';
import LIFAN from '../../../../images/brands/LIFAN-logo.png';
import CHERY from '../../../../images/brands/CHERY-logo.png';
import LEXUS from '../../../../images/brands/LEXUS-logo.png';
import ACURA from '../../../../images/brands/ACURA-logo.png';
import FIAT from '../../../../images/brands/FIAT-logo.png';
import FORD from '../../../../images/brands/FORD-logo.png';
import JEEP from '../../../../images/brands/JEEP-logo.png';
import MINI from '../../../../images/brands/MINI-logo.png';
import AUDI from '../../../../images/brands/AUDI-logo.png';
import KIA from '../../../../images/brands/KIA-logo.png';
import BMW from '../../../../images/brands/BMW-logo.png';
import JAC from '../../../../images/brands/JAC-logo.png';

export const CAR_IMAGES = {
  MERCEDESBENZ,
  PLACEHOLDER,
  VOLKSWAGEN,
  MITSUBISHI,
  CHEVROLET,
  LANDROVER,
  CHRYSLER,
  CITROËN,
  HYUNDAI,
  PEUGEOT,
  RENAULT,
  PORSCHE,
  TROLLER,
  NISSAN,
  TOYOTA,
  SUBARU,
  SUZUKI,
  JAGUAR,
  DODGE,
  HONDA,
  HAFEI,
  VOLVO,
  CHERY,
  LEXUS,
  SMART,
  LIFAN,
  ACURA,
  FIAT,
  FORD,
  JEEP,
  MINI,
  AUDI,
  KIA,
  BMW,
  JAC,
};
