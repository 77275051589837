import React from 'react';
import { IoMdPricetag } from 'react-icons/io';
import { Container, Highlights } from './styles';

const IPVA = () => {
  const isDecember = new Date().getMonth() === 11;
  const year = new Date().getFullYear();
  const IPVAYear = isDecember ? year + 1 : year;

  return (
    <Container>
      <Container.Body>
        <IoMdPricetag fill="#171c24" size={28} />
        <span className="text">
          Todos os carros com <Highlights>IPVA {IPVAYear} pago!</Highlights>
        </span>
      </Container.Body>
    </Container>
  );
};

export default IPVA;
