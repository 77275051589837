import styled, { css } from 'styled-components';

export const Button = styled.button`
  padding: 0.75rem 1.313rem;

  background-color: ${props => (props.disabled ? '#f4f5f5' : props.color)};

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #fff;
  text-align: center;

  border: none;
  border-radius: ${props =>
    props.participate || props.modal || props.vehicleDetail ? '0.25rem' : '0.5rem'};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: none;

  transition: background-color 0.2s ease-out;

  :hover { transition: background-color 0.2s ease-in; }

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${({ singleOffer }) =>
    singleOffer &&
    css`
      width: ${props => (props.modal ? '100%' : 'auto')};

      background-color: ${({ disabled }) => (disabled ? '#f4f5f5' : '#438a4c')};

      :hover {
        background-color: ${({ disabled }) => (disabled ? '#f4f5f5' : '#4ab971')};
      }

      @media screen and (min-width: 1226px) and (max-width: 1285px) {
        padding: 0.75rem 2.8rem;
      }

      @media screen and (max-width: 835px) {
        width: ${props => (props.modal ? '100%' : '48%')};
      }
    `}

  ${({ negotiateExclusivity }) =>
    negotiateExclusivity &&
    css`
      width: 100%;
      padding: 0.75rem 0;

      background-color: ${({ disabled }) => (disabled ? '#f4f5f5' : '#ff4c00')};

      :hover {
        background-color: ${({ disabled }) => (disabled ? '#f4f5f5' : '#ff7941')};
      }
    `}

      ${({ finNegotiateExclusivity }) =>
        finNegotiateExclusivity &&
        css`
          width: 100%;
          padding: 0.75rem 0;

          background-color: #f3f3f3;
          color: #ff4c00;

          border: 1px solid #ff4c00;

          :hover {
            background-color: ${({ disabled }) => (disabled ? '#f4f5f5' : '#ff7941')};
          }
        `}

    ${({ singleOffer, participate }) =>
      singleOffer &&
      participate &&
      css`
        @media screen and (max-width: 835px) {
          width: 85%;
        }
      `}

    ${({ participate }) =>
      participate &&
      css`
        width: 85%;
      `}
    
    ${({ singleOffer, vehicleDetail }) =>
      singleOffer &&
      vehicleDetail &&
      css`
        width: 50%;

        @media screen and (min-width: 1226px) and (max-width: 1285px) {
          padding: 0.75rem 1.313rem;
        }
      `}
    
    ${({ singleOffer, isNinja }) =>
      singleOffer &&
      isNinja &&
      css`
        width: 100%;
        margin-bottom: 0.688rem;
      `}

    ${({ specificDisabled, disabled }) =>
      disabled &&
      specificDisabled &&
      css`
        background-color: #438a4c;

        :hover {
          background-color: #438a4c;
        }

        cursor: default;
      `}
    
    ${({ isHighlight }) =>
      isHighlight &&
      css`
        @media screen and (max-width: 1365px) {
          padding: 0.75rem 1.2rem;
        }

        @media screen and (max-width: 1025px) {
          padding: 0.75rem 0.7rem;
        }

        @media screen and (max-width: 835px) {
          padding: 0.75rem 0.4rem;
        }
      `}

      ${({ automaticOffer }) =>
        automaticOffer &&
        css`
          /* margin-bottom: 0.625rem; */
        `}
`;
