import React from 'react';

export const AscendingIcon = ({ color = '#fff', ...props }) => {
  return (
    <svg
      width={13}
      height={12}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.46 3.31A.5.5 0 0011 3H8.5a.5.5 0 100 1h1.295L7 6.795l-1.645-1.65a.5.5 0 00-.71 0l-3 3a.5.5 0 00.163.82.5.5 0 00.547-.11L5 6.205l1.645 1.65a.5.5 0 00.71 0l3.145-3.15V6a.5.5 0 101 0V3.5a.5.5 0 00-.04-.19z"
        fill={color}
      />
    </svg>
  );
};
