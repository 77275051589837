import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toPrice, toAmount } from '../../utils/number';
import AppConstants from '../../constants/appConstants';
import { bidsByUser } from '../../utils/auction';
import { onBidError, makeBid } from '../../store/actions/auction.actions';
import { getSelectedAuction } from '../../store/selectors';
import { getTaxIdListByStore, getTaxIdList } from '../../services/taxids';

let taxIdList = [];

/* This hoc is used solely to contain make-offer logic */
const WithMakeOffer = Component => {
  const findDefaultObject = taxIdList => {
    return taxIdList.find(item => item.default === true);
  };

  class MakeOffer extends React.PureComponent {
    static propTypes = {
      selectedAuction: PropTypes.object.isRequired,
      makeBid: PropTypes.func.isRequired,
      bidErrorMessage: PropTypes.string,
      bidError: PropTypes.bool,
      relatedDealers: PropTypes.array.isRequired,
      onBidError: PropTypes.func,
      makeOfferLoading: PropTypes.bool.isRequired,
      authUser: PropTypes.object.isRequired,
      minimumBid: PropTypes.number,
      multipliers: PropTypes.arrayOf(PropTypes.number),
    };

    static defaultProps = {
      minimumBid: AppConstants.MinimumBid,
      multipliers: [1, 2, 4],
      selectedAuction: {},
    };

    constructor(props) {
      super(props);
      this.state = {
        amount: this.getBidAmount(),
        localError: false,
        errorText: '',
        lastOfferByUser: 0,
        bidsCount: props.selectedAuction.auction ? props.selectedAuction.auction.bids.length : 0,
        priceSet: false,
        selectedDealer: '',
        selectedTaxProfile: '',
        taxProfiles: [],
        dealerId: '',
      };
    }

    async fetchTaxIdList() {
      try {
        const response = await getTaxIdList();
        taxIdList = response.data.data;
        this.setState({ taxProfiles: taxIdList });
      } catch (error) {
        console.error('Failed to fetch tax ID list:', error);
      }
    }

    componentDidMount() {
      this.fetchTaxIdList();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedAuction._id !== this.props.selectedAuction._id) {
        this.fetchTaxIdList();
        const amount = this.getBidAmount(this.props.selectedAuction);
        this.setState({
          selectedDealer: '',
          selectedTaxProfile: '',
          taxProfiles: [],
          localError: false,
          errorText: '',
          amount: amount,
        });
      }

      const { auction: nextSelectedAuction } = this.props.selectedAuction;

      if (
        this.props.selectedAuction._id &&
        (!this.state.priceSet || nextSelectedAuction.bids.length !== this.state.bidsCount)
      ) {
        const { relatedDealers, authUser } = this.props;
        const winningBid = nextSelectedAuction.winningBid
          ? nextSelectedAuction.winningBid.amount
          : 0;
        const startingPrice = this.props.selectedAuction.summary.startingPrice;
        const amount = winningBid || startingPrice || 0;

        const lastBidByUser =
          bidsByUser(this.props.selectedAuction, authUser, relatedDealers, '').slice(-1)[0] || {};
        const lastOfferByUser = lastBidByUser.amount || 0;

        this.setState({
          amount,
          lastOfferByUser,
          bidsCount: nextSelectedAuction.bids.length,
          priceSet: true,
        });
      }
    }

    handleAmountChange = e => {
      const {
        target: { value },
      } = e;
      return this.updateAmount(toAmount(value));
    };

    handleBlur = () => {
      const { amount } = this.state;
      const currentAmount = this.getBidAmount();
      let rounded = Math.round(amount / 50) * 50;
      rounded = rounded ? rounded : currentAmount;
      rounded = rounded > 50 ? rounded : 50;

      if (amount >= currentAmount && amount % 50 !== 0) {
        return this.updateAmount(toAmount(rounded));
      }
      if (amount < currentAmount) {
        return this.updateAmount(toAmount(currentAmount));
      }
      return null;
    };

    updateAmount = newAmount => {
      this.setState({
        amount: newAmount,
        priceSet: true,
      });
    };

    isNinjaBidValid = () => {
      if (!this.state.selectedDealer) {
        this.setState({
          localError: true,
          errorText: 'Deve selecionar a loja para colocar um lance',
        });
        return false;
      }
      if (!this.state.selectedTaxProfile) {
        this.setState({
          localError: true,
          errorText: 'Deve selecionar o perfil para colocar um lance',
        });
        return false;
      }
      return true;
    };

    handleSubmit = e => {
      e && e.preventDefault();
      const defaultObject = findDefaultObject(taxIdList);

      const { amount } = this.state;
      const { authUser, minimumBid } = this.props;
      const currentAmount = this.getBidAmount();
      if (amount < currentAmount + minimumBid) {
        this.setState({
          localError: true,
          errorText: `Faça um lance de ${toPrice(currentAmount + minimumBid)} ou mais`,
        });
        return;
      } else if (authUser.isNinja() && !this.isNinjaBidValid()) {
        return;
      }
      this.setState({
        localError: false,
        errorText: '',
      });
      const dealershipName = authUser.isNinja()
        ? this.state.selectedDealer
        : authUser.dealershipName;
      const dealerId = authUser.isNinja() ? this.state.dealerId : null;

      this.props.makeBid(
        this.props.selectedAuction._id,
        this.state.amount,
        dealershipName,
        dealerId,
        null,
        false,
        this.state.selectedTaxProfile
          ? this.state.selectedTaxProfile
          : defaultObject?.taxIdentificationNumber,
        this.props.selectedAuction.auction.isSupperOffer
      );
    };

    allowOnlyNumbers = event => {
      const character = String.fromCharCode(event.charCode || event.keyCode);
      if (!/[0-9\b]/.test(character)) {
        event.preventDefault();
      }
    };

    handleQuickAmount = shortcutAmount => {
      const { auction } = this.props.selectedAuction;
      const { amount } = this.state;

      const lastBid = auction.winningBid.amount;
      const newAmount = amount <= lastBid ? lastBid + shortcutAmount : amount + shortcutAmount;
      return this.updateAmount(newAmount);
    };

    handleIncrementAmount = (type, e) => {
      e ? e.preventDefault() : null;
      const incrementor = type === 'dec' ? -1 : 1;
      const { minimumBid } = this.props;

      const minValidPrice = this.getBidAmount();
      const newAmount = this.state.amount + incrementor * minimumBid;

      if (incrementor === -1 && minValidPrice > newAmount) {
        return;
      }
      this.updateAmount(newAmount);
    };

    getBidAmount = selectedAuction => {
      const { auction, summary } = selectedAuction ? selectedAuction : this.props.selectedAuction;
      const amount = (auction && auction.winningBid.amount) || 0;
      const startingPrice = (summary && summary.startingPrice) || 0;
      return amount || startingPrice || 0;
    };

    handleBuyNow = () => {
      if (this.props.authUser.isNinja() && !this.isNinjaBidValid()) {
        return;
      }

      const dealershipName = this.props.authUser.isNinja()
        ? this.state.selectedDealer
        : this.props.authUser.dealershipName;

      const dealerId = this.props.authUser.isNinja() ? this.state.dealerId : null;
      const defaultObject = findDefaultObject(taxIdList);

      this.props.makeBid(
        this.props.selectedAuction._id,
        this.props.selectedAuction.summary.buynowPrice.amount,
        dealershipName,
        dealerId,
        null,
        false,
        this.state.selectedTaxProfile
          ? this.state.selectedTaxProfile
          : defaultObject?.taxIdentificationNumber,
        this.props.selectedAuction.auction.isSupperOffer
      );
    };

    onSubmitBuyFor = () => {
      if (this.props.authUser.isNinja() && !this.isNinjaBidValid()) return;

      const dealershipName = this.props.authUser.isNinja()
        ? this.state.selectedDealer
        : this.props.authUser.dealershipName;

      const dealerId = this.props.authUser.isNinja() ? this.state.dealerId : null;
      const defaultObject = findDefaultObject(taxIdList);

      this.props.makeBid(
        this.props.selectedAuction._id,
        this.props.selectedAuction.summary.buyForPrice.amount,
        dealershipName,
        dealerId,
        null,
        false,
        this.state.selectedTaxProfile
          ? this.state.selectedTaxProfile
          : defaultObject?.taxIdentificationNumber,
        this.props.selectedAuction.auction.isSupperOffer,
        true
      );
    };

    filterApprovedTaxProfiles = profiles => {
      return profiles.filter(profile => profile.status === 'APPROVED');
    };

    onDealerChange = async dealerId => {
      try {
        const { relatedDealers } = this.props;
        const dealer = relatedDealers?.find(el => el._id === dealerId);
        if (!dealer) throw Error(`Código da loja "${dealerId} não encontrado`);
        this.setState({
          selectedDealer: dealer.dealershipName,
          dealerId,
          localError: false,
          selectedTaxProfile: '',
        });
        const taxProfiles = await getTaxIdListByStore(dealer._id);
        if (!taxProfiles.data) {
          this.setState({ taxProfiles: [] });
        } else {
          const approvedProfiles = this.filterApprovedTaxProfiles(taxProfiles.data.data);
          this.setState({ taxProfiles: approvedProfiles });
        }
      } catch (e) {
        this.setState({
          localError: true,
          errorText: e.message,
        });
      }
    };

    onTaxProfileChange = selectedTaxProfile => {
      this.setState({ selectedTaxProfile, localError: false });
    };

    render() {
      if (!this.props.selectedAuction._id) {
        return null;
      }

      const {
        selectedAuction: auction,
        selectedAuction,
        multipliers,
        minimumBid,
        makeOfferLoading,
        bidError,
        bidErrorMessage,
        authUser,
        ...etc
      } = this.props;

      return (
        <Component
          auction={auction}
          selectedAuction={selectedAuction}
          handleAmountChange={this.handleAmountChange}
          handleAmountKeyPress={this.allowOnlyNumbers}
          handleAmountIncrement={this.handleIncrementAmount}
          handleQuickAmount={this.handleQuickAmount}
          handleBlur={this.handleBlur}
          amountValue={toPrice(this.state.amount, false)}
          bidHasError={bidError || this.state.localError}
          bidErrorMessage={bidErrorMessage || this.state.errorText}
          makeOfferLoading={makeOfferLoading}
          lastOfferByUser={toPrice(this.state.lastOfferByUser, false)}
          showBuyNow={selectedAuction.summary.buynowPrice.enabled}
          buyNowAmount={toPrice(selectedAuction.summary.buynowPrice.amount || 0, false)}
          submitBuyNow={this.handleBuyNow}
          onDealerChange={this.onDealerChange}
          onTaxProfileChange={this.onTaxProfileChange}
          selectedDealer={this.state.selectedDealer}
          dealerId={this.state.dealerId}
          selectedTaxProfile={this.state.selectedTaxProfile}
          taxProfiles={this.state.taxProfiles}
          showDealersDropdown={authUser.isNinja()}
          submitOffer={this.handleSubmit}
          multipliers={multipliers}
          minimumBid={minimumBid}
          updateAmount={this.updateAmount}
          onSubmitBuyFor={this.onSubmitBuyFor}
          {...etc}
        />
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    makeBid: bindActionCreators(makeBid, dispatch),
    onBidError: bindActionCreators(onBidError, dispatch),
  });

  const mapStateToProps = state => {
    const { ui, auctions, user, authentication } = state;
    const { bidSuccess, bidError, bidErrorMessage } = auctions;

    return {
      bidError,
      bidErrorMessage,
      selectedAuction: getSelectedAuction(state) || {},
      makeOfferLoading: ui.makeOfferLoading,
      bidSuccess,
      relatedDealers: user.relatedDealers,
      authUser: authentication.user,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(MakeOffer);
};

export default WithMakeOffer;
