import Types from './types';

export const enableTooltip = payload => ({
  type: Types.TOOLTIP_ENABLED,
  payload,
});

export const disableTooltip = () => ({
  type: Types.TOOLTIP_DISABLED,
});
