import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { exclude } from '../../../../../utils/misc';

import './styles.scss';

class Header extends React.Component {
  handleOnCloseClick = () => {
    const { modal, onCloseClick } = this.props;

    if (modal) modal.dismiss();
    if (onCloseClick) onCloseClick();
  };

  render() {
    const {
      showCloseButton,
      primary,
      secondary,
      large,
      noPadding,
      className,
      children,
      ...others
    } = this.props;
    const classNames = classnames('modal-header', className, {
      'modal-header--primary': primary,
      'modal-header--secondary': secondary,
      'modal-header--large': large,
      'modal-header--no-padding': noPadding,
    });
    const props = exclude(others, 'modal', 'onCloseClick');
    return (
      <header className={classNames} {...props}>
        {children}
        {showCloseButton && (
          <i onClick={this.handleOnCloseClick} className="modal-header__close-btn icon-cross" />
        )}
      </header>
    );
  }
}
Header.propTypes = {
  modal: PropTypes.any,
  onCloseClick: PropTypes.func,
  children: PropTypes.any,
  showCloseButton: PropTypes.bool,
  primary: PropTypes.bool,
  noPadding: PropTypes.bool,
  secondary: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
};
Header.defaultProps = {
  showCloseButton: true,
};

export { Header };
