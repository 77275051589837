import React, { useEffect, useMemo, useState } from 'react';
import { FaSearch } from 'react-icons/fa';

import { Container } from './styles';

import ActiveItemsImage from './components/active-items-image';
import ActiveItemsText from './components/active-items-text';

function SelectWithSearch({
  type = 'text',
  data,
  name,
  onChange,
  label,
  reset,
  fontFamily,
  orderCars = false,
  presetValue,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (reset) {
      setSelectedItems([]);
      onChange({ target: { name, value: [] } });
    }
  }, [reset]);

  const options = useMemo(
    function() {
      if (!searchValue) return data;
      return data.filter(item => {
        const value = item?.value || item;
        return value.toLowerCase().includes(searchValue.toLowerCase());
      });
    },
    [data, searchValue]
  );

  function handleChange(event) {
    setSearchValue(event.target.value);
  }

  function handleRemove(item) {
    return () => {
      const filtered = selectedItems.filter(i => i !== item);
      setSelectedItems([...filtered]);
      onChange({ target: { name, value: [...filtered] } });
    };
  }

  function handleSelect(item) {
    return () => {
      const value = item?.value || item;
      const finded = selectedItems.find(i => i === value);
      if (finded) return;
      setSelectedItems([...selectedItems, value]);
      onChange({ target: { name, value: [...selectedItems, value] } });
    };
  }

  useEffect(() => {
    if (!!presetValue?.length) {
      setSelectedItems(presetValue);
    } else {
      setSelectedItems([]);
    }
  }, [presetValue]);

  return (
    <Container>
      {orderCars && selectedItems.length > 0 && (
        <>
          {type === 'image' ? (
            <ActiveItemsImage
              activeItems={selectedItems}
              data={data}
              removeAction={handleRemove}
              orderCars={orderCars}
            />
          ) : (
            <ActiveItemsText data={selectedItems} removeAction={handleRemove} />
          )}
        </>
      )}

      <Container.InputSearch orderCars={orderCars}>
        <label>{label}</label>
        <div className="input-wrapper">
          <span className="search-icon">
            <FaSearch />
          </span>
          <input onChange={handleChange} type="text" placeholder="Ex: Volkswagen" />
        </div>
      </Container.InputSearch>

      {!orderCars && selectedItems.length > 0 && (
        <>
          {type === 'image' ? (
            <ActiveItemsImage activeItems={selectedItems} data={data} removeAction={handleRemove} />
          ) : (
            <ActiveItemsText data={selectedItems} removeAction={handleRemove} />
          )}
        </>
      )}

      <div>
        <Container.List>
          {options?.map((item, key) => (
            <Container.List.Item key={key} onClick={handleSelect(item)} orderCars={orderCars}>
              {typeof item === 'object' ? (
                <>
                  <div className="image">
                    {item.image ? (
                      <img src={item.image} alt="image" />
                    ) : (
                      <span className="text">{item.value}</span>
                    )}
                  </div>
                  <span className="label" fontFamily={fontFamily}>
                    {item.value}
                  </span>
                </>
              ) : (
                <span className="label" fontFamily={fontFamily}>
                  {item}
                </span>
              )}
            </Container.List.Item>
          ))}
        </Container.List>
      </div>
    </Container>
  );
}

export default SelectWithSearch;
