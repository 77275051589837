import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

import { Modal } from './styles';

function ModalTemplate({ children, handleCloseModal }) {
  return (
    <Modal>
      <Modal.Body>
        <Modal.Close onClick={handleCloseModal}>
          <FaTimes />
        </Modal.Close>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default ModalTemplate;
