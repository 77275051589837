import ActionTypes from '../../constants/actionTypes';
import { getUser, saveToken, clearToken } from '../../utils/auth';
import { trackLoginSucceeded } from './event-tracking.actions';
import { getBuyerId } from './user.actions';
import { clearMyPurchasesFilters } from './ui.actions';

import axios from 'axios';
import AppConstants from '../../constants/appConstants';

export function toggleLoginModal(toggle) {
  return {
    type: ActionTypes.Modal.Login.Toggle,
    showModal: toggle,
  };
}

export const authenticate = ({ username, password }) => async dispatch => {
  dispatch(authenticateStart());
  try {
    const { data } = await axios.post(AppConstants.API.newAuthentication, {
      grant_type: 'password',
      identifier: username,
      password: password,
    });
    const user = getUser(() => data.body);
    dispatch(authenticateSuccess(data.body, user));
    trackLoginSucceeded(user);

    if (user.dealershipName && user.isLoja()) {
      dispatch(getBuyerId(user.dealershipName));
    } else {
      dispatch({ type: ActionTypes.RelatedDealers.ClearBuyerId });
    }
  } catch (error) {
    dispatch(authenticateError(error.response.data.meta.message));
  }
};

export function deauthenticate(cb) {
  clearToken();
  return dispatch => {
    dispatch({
      type: ActionTypes.Authenticate.DeAuthenticate,
      user: getUser(() => null),
      token: null,
    });
    dispatch({
      type: ActionTypes.RelatedDealers.Clear,
    });
    dispatch({
      type: ActionTypes.Auction.ClearAuctions,
    });
    dispatch({
      type: ActionTypes.RelatedDealers.ClearBuyerId,
    });
    dispatch(clearMyPurchasesFilters());
    cb();
  };
}

function authenticateSuccess(token, user) {
  saveToken(token);
  return {
    type: ActionTypes.Authenticate.Success,
    user,
    token,
  };
}

function authenticateError(error) {
  return {
    type: ActionTypes.Authenticate.Error,
    error,
  };
}

function authenticateStart() {
  return {
    type: ActionTypes.Authenticate.Perform,
  };
}
