class FilterServiceProxy {
  constructor(service, notify) {
    this.service = service;
    this.notify = notify;
  }

  async getUserFilters(userId) {
    const resp = await this.service.getUserFilters(userId);
    return resp;
  }

  async addFilter(filter) {
    const resp = await this.service.addFilter(filter);
    this.notify.onFilterAdded(resp);
    return resp;
  }

  async removeFilter(filterId) {
    const resp = await this.service.removeFilter(filterId);
    this.notify.onFilterRemoved(resp);
    return resp;
  }
}

export function createFilterServiceProxy(service, notify) {
  return new FilterServiceProxy(service, notify);
}
