import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

Container.Title = styled.h5`
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #2f3741;
`;

Container.Loading = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const List = styled.div``;

List.Title = styled.h6`
  font-weight: normal;
  margin-bottom: 25px;
  font-style: normal;
  font-size: 16px;
  color: #2f3741;

  .icon {
    vertical-align: middle;
    margin-right: 8px;
    color: #757778;
  }
`;

List.Sublist = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: 38px;
  grid-gap: 10px;
  display: grid;
  width: 100%;
`;
