import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlag } from '@unleash/proxy-client-react';
import { useSelector } from 'react-redux';

import { Heading } from '../Heading';
import { Button } from '../Button';
import { Box, Text } from '../../abstract';
import { Container, Content, Image, Divider, Buttons } from './styles';
import { FilterCategory } from '../../filters/components/filter-category';
import { PreferencesCard } from '../../NewAuctionsList/Components/PreferencesCard';
import { FincredCard } from '../../NewAuctionsList/Components/FincredCard';
import { CarList } from '../../NewAuctionsList/NewOffersList/styles';
import { checkFinancingEnabled } from '../../../services/fincredit';

export const EmptyState = ({ title, subtitle, description, buttons, showFinCred, ...props }) => {
  const { id: preferenceId } = useSelector(state => state.orderCars.userPreferences);
  const { loading: preferenceLoading } = useSelector(state => state.orderCars);
  const { user } = useSelector(state => state.authentication);
  const [displaySimulationBanner, setDisplaySimulationBanner] = useState(false);

  const enabledCategory = useFlag('category-test');
  const enabledOrderCars = useFlag('order-cars');
  const enabledSimulationBanner = useFlag('banner-simulacao');

  const fetchData = async () => {
    try {
      const response = await checkFinancingEnabled(user?.email);
      setDisplaySimulationBanner(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (enabledSimulationBanner) {
      fetchData();
    }
  }, [enabledSimulationBanner]);

  return (
    <Container tab={props.tab}>
      {enabledCategory && props.tab === 0 && (
        <div style={{ width: '100%' }}>
          <FilterCategory />
        </div>
      )}

      {!preferenceId &&
      !preferenceLoading &&
      props.tab === 0 &&
      enabledOrderCars &&
      !user.isNinja() ? (
        <CarList>
          <PreferencesCard />
          {displaySimulationBanner && <FincredCard />}
        </CarList>
      ) : displaySimulationBanner && showFinCred ? (
        <CarList>
          <FincredCard />
        </CarList>
      ) : (
        <Content>
          <Image src={require('../../../images/maninho.svg')} alt="Empty List" />
          <Divider />

          <Box>
            <Heading color="neutrals.EclipseE700" fontSize={5}>
              {title}
            </Heading>

            {subtitle ? (
              <Text mt={1} fontSize={2} color="neutrals.EclipseE500">
                {subtitle}
              </Text>
            ) : null}
            <br />

            <Text fontSize={2} color="neutrals.EclipseE500">
              {description}
            </Text>

            <Buttons>
              {buttons
                ? buttons.map((el, i) => (
                    <React.Fragment key={el.label}>
                      <Button variant={el.variant} onClick={el.action} data-name="button">
                        {el.label}
                      </Button>

                      {buttons.length > 1 && i === 0 ? <Divider /> : null}
                    </React.Fragment>
                  ))
                : null}
            </Buttons>
          </Box>
        </Content>
      )}
    </Container>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.array,
  showFinCred: PropTypes.bool,
};

EmptyState.defaultProps = {
  title: 'Sem leilões acontecendo no momento',
  description:
    'Você pode conferir as próximas ofertas ou criar um alerta e ser notificado assim que novos carros entrarem na plataforma.',
};
