import React, { useCallback } from 'react';
import { Container, Label, TempName, PriceLabel, Slider } from './styles';
import { useTemperature } from '../../../hooks/useTemperature';
import ArrowTop from '../../../images/icons/arrow-top.svg';
import { formatPrice } from '../../../utils/formatPrice';

const sliders = ['#6b0874', '#864600', '#22aa52'];

export const WithSlider = ({ auction, currentValue }) => {
  const { highChances, lowChances, currentTemperature } = useTemperature(auction, currentValue);

  const useLayout = useCallback(() => {
    if (lowChances) {
      return (
        <Label>
          Em nosso histórico, lances como esse não venceram as disputas. Aposte em ofertas acima de{' '}
          <PriceLabel>R$ {formatPrice(auction?.summary?.vehiclePricing?.hotTarget)}.</PriceLabel>
        </Label>
      );
    }

    if (highChances) {
      return (
        <Label>
          <strong>Continue participando,</strong> sua oferta possui grande potencial de fechar
          negócio rapidamente!
        </Label>
      );
    }

    return (
      <Label>
        Melhore sua chance de fechar negócio, invista em lances acima de{' '}
        <PriceLabel>R$ {formatPrice(auction?.summary?.vehiclePricing?.hotTarget)}</PriceLabel> para
        comprar mais rápido!
      </Label>
    );
  }, [highChances, lowChances]);

  return (
    <Container.Default>
      {useLayout()}
      <Slider.Container>
        <Slider.Content>
          {sliders.map(color => (
            <Slider.Item color={color} />
          ))}
        </Slider.Content>
        <Slider.Price temperature={currentTemperature}>
          <img src={ArrowTop} alt="" />
          R$ {formatPrice(currentValue)}
        </Slider.Price>
      </Slider.Container>
    </Container.Default>
  );
};

export const ParticipateWithSlider = ({ auction, currentValue, style }) => {
  const { lowChances, highChances, currentTemperature } = useTemperature(auction, currentValue);
  const { icon } = currentTemperature || {};
  const isDetails = window.location.pathname.includes('detalhes');

  const useLayout = useCallback(() => {
    if (lowChances) {
      return (
        <span
          style={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: '14px',
            lineHeight: '20px',
            color: isDetails ? '#fff' : '#000',
          }}
        >
          Em nosso histórico, lances como esse não venceram as disputas. Aposte em ofertas acima de{' '}
          <strong>R$ {formatPrice(auction?.summary?.vehiclePricing?.hotTarget)}.</strong>
        </span>
      );
    }

    if (highChances) {
      return (
        <span
          style={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: '14px',
            lineHeight: '20px',
            color: isDetails ? '#fff' : '#000',
          }}
        >
          <strong>Continue participando,</strong> sua oferta possui grande potencial de fechar
          negócio rapidamente!
        </span>
      );
    }

    return (
      <span
        style={{
          fontFamily: 'Roboto, sans-serif',
          fontSize: '14px',
          lineHeight: '20px',
          color: isDetails ? '#fff' : '#000',
        }}
      >
        Melhore sua chance de fechar negócio, invista em lances acima de{' '}
        <strong>R$ {formatPrice(auction?.summary?.vehiclePricing?.hotTarget)}</strong> para comprar
        mais rápido!
      </span>
    );
  }, [lowChances, highChances]);

  return (
    <Container.Participate style={style}>
      <Slider.Container>
        <Slider.Content>
          {sliders.map(color => (
            <Slider.Item color={color} />
          ))}
        </Slider.Content>
        <Slider.Price temperature={currentTemperature}>
          <img src={ArrowTop} alt="" />
        </Slider.Price>
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0 8px' }}>
          <img
            src={isDetails ? icon?.slider?.details : icon?.slider?.participate}
            style={{ width: '20px', height: '20px' }}
          />
          {useLayout()}
        </div>
      </Slider.Container>
    </Container.Participate>
  );
};
