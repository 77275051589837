import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CarList } from './styles';
import AuctionNewOffersCard from '../../AuctionCards/AuctionNewOffersCard';
import { PreferencesCard } from '../Components/PreferencesCard';
import { FincredCard } from '../Components/FincredCard';

import { setSelectedAuctionId } from '../../../store/actions/auction.actions';
import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import { checkFinancingEnabled } from '../../../services/fincredit';

const WithPreferencesCard = ({ auction, handleOnLoad, showFincred, newOffers }) => (
  <>
    <AuctionNewOffersCard auction={auction} previousCard={handleOnLoad} />
    <PreferencesCard />
    {showFincred && newOffers && <FincredCard />}
  </>
);

const WithCreditCard = ({ auction, handleOnLoad, newOffers }) => (
  <>
    <AuctionNewOffersCard auction={auction} previousCard={handleOnLoad} />
    {newOffers && <FincredCard />}
  </>
);

function NewOffersList({ auctions, newOffers }) {
  const selectedAuctionId = useSelector(state => state.ui.selectedAuctionId);
  const { id: preferenceId } = useSelector(state => state.orderCars.userPreferences);
  const { loading: preferenceLoading } = useSelector(state => state.orderCars);
  const { user } = useSelector(state => state.authentication);

  const [displaySimulationBanner, setDisplaySimulationBanner] = useState(false);

  const updateContext = useUnleashContext();
  const enabledSimulationBanner = useFlag('banner-simulacao');

  const dispatch = useDispatch();
  const enabledOrderCars = useFlag('order-cars');

  const handleOnLoad = id => {
    if (id === selectedAuctionId) {
      dispatch(setSelectedAuctionId());
      document.getElementById(id).scrollIntoView();
    }
  };

  const fetchData = async () => {
    try {
      const response = await checkFinancingEnabled(user?.email);
      setDisplaySimulationBanner(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateContext({ userId: user?.email });
  }, [user?.email]);

  useEffect(() => {
    if (enabledSimulationBanner) {
      fetchData();
    }
  }, [enabledSimulationBanner]);

  return (
    <CarList>
      {auctions?.map((auction, index) => {
        const lastIndex = auctions.length - 1;
        if (
          index === 0 &&
          !preferenceId &&
          !preferenceLoading &&
          enabledOrderCars &&
          !user.isNinja()
        ) {
          return (
            <WithPreferencesCard
              key={auction._id}
              auction={auction}
              handleOnLoad={handleOnLoad}
              showFincred={index === lastIndex && index === 0 && displaySimulationBanner}
              newOffers={newOffers}
            />
          );
        } else if ((preferenceId ? index === 0 : index === 8) && displaySimulationBanner) {
          return (
            <WithCreditCard
              key={auction._id}
              auction={auction}
              handleOnLoad={handleOnLoad}
              newOffers={newOffers}
            />
          );
        } else if (
          index === lastIndex &&
          (preferenceId ? index === 0 : index < 8) &&
          displaySimulationBanner
        ) {
          return (
            <WithCreditCard
              key={auction._id}
              auction={auction}
              handleOnLoad={handleOnLoad}
              newOffers={newOffers}
            />
          );
        } else {
          return (
            <AuctionNewOffersCard key={auction._id} auction={auction} previousCard={handleOnLoad} />
          );
        }
      })}
    </CarList>
  );
}

export default NewOffersList;
