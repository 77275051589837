import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { MdClose } from 'react-icons/md';

import { LabelStyles, FormTextStyles } from '../styled/forms/common-styles';
import { Button } from '../../enhanced/Button';

const Input = styled.input`
  width: 33.33%;
  color: transparent;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::before {
    content: 'Escolher arquivos';
    display: flex;
    background: ${themeGet('colors.secondaries.Uno')};
    color: #ffffff;
    border-radius: ${themeGet('radii.fields')};
    outline: none;
    font-size: 1rem;
    padding: 0.5rem 0.35rem;
    cursor: pointer;
    user-select: none;
    --webkit-user-select: none;
    align-items: center;
    justify-content: center;
    border: 1px solid #c4c4c4;
  }

  &:hover::before {
    border-color: ${themeGet('colors.secondaries.Uno')};
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const FileItem = styled.div`
  border: 1px solid #c4c4c4;
  padding: 0 1rem;
  border-radius: ${themeGet('radii.rounded')};
  display: inline-flex;
  align-items: center;

  .button-icon {
    margin: 0;
  }
`;

export const FileInput = ({ labelText, formText, onChange = () => {}, ...props }) => {
  const [files, setFiles] = useState([]);
  const inputRef = useRef(null);

  const handleChange = event => {
    const newFiles = Array.from(event.currentTarget.files);
    setFiles(newFiles);
    onChange(newFiles);
    if (inputRef) {
      inputRef.current.value = null;
    }
  };

  const handleRemoveFile = fileToRemove => {
    const newFiles = files.filter(file => file.name !== fileToRemove.name);
    setFiles(newFiles);
    onChange(newFiles);
  };

  return (
    <>
      <LabelStyles htmlFor={props.id}>{labelText}</LabelStyles>
      {formText && <FormTextStyles id={`${props.id}-help-block`}>{formText}</FormTextStyles>}
      <Input
        {...props}
        onChange={handleChange}
        aria-describedby={`${props.id}-help-block`}
        type="file"
        ref={inputRef}
      />
      <ul>
        {files.map(file => (
          <li key={file.name} css={{ marginBottom: '0.75rem !important' }}>
            <FileItem>
              {file.name}
              <Button
                variant="link.secondary"
                css={css`
                  padding-left: 0.5rem;
                  padding-right: 0;
                  color: ${themeGet('colors.neutrals.EclipseE500')};
                `}
                onClick={() => handleRemoveFile(file)}
              >
                <Button.Icon>
                  <MdClose />
                </Button.Icon>
              </Button>
            </FileItem>
          </li>
        ))}
      </ul>
    </>
  );
};
