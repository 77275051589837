import styled from 'styled-components';

export const FiltersContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 35px;
  @media screen and (max-width: 1025px) {
    flex-direction: column;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const OrdenateContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  @media screen and (max-width: 1025px) {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FilterButtonContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;

  @media screen and (max-width: 1025px) {
    width: 100%;
    padding-top: 30px;
  }
`;

export const Line = styled.hr`
  width: 100%;
  border: 1px solid #b9bbbd;
`;

export const LineDiv = styled.div`
  @media screen and (max-width: 1025px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const ResultText = styled.div`
  font-size: 'Nunito';
  font-size: 16px;
  color: #171c24;
  font-weight: 400;
`;

export const ResultNumber = styled.div`
  font-size: 'Nunito';
  font-size: 16px;
  color: #171c24;
  font-weight: 700;
  padding-right: 3px;
`;

export const ResultContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
`;

export const FilterAndOrdenateContainer = styled.a`
  flex-direction: row;
  align-items: center;
  display: flex;
`;

export const FilterAndOrdenateText = styled.div`
  font-family: 'Nunito';
  font-size: 16px;
  color: #00a81f;
  font-weight: 700;
  padding-right: 8px;
`;

export const DropText = styled.a`
  font-family: 'Nunito';
  font-size: 16px;
  color: #00a81f;
  font-weight: 400;
  width: 100%;
  padding: 10px;

  &:hover {
    background-color: #dcdcdc;
    border-radius: 6px;
  }
`;

export const DropContainer = styled.div`
  box-shadow: 0px 4px 16px 0px rgba(24, 94, 136, 0.15);
  background-color: white;
  position: absolute;
  z-index: 1;
  width: 150px;
  border-radius: 6px;
  flex-direction: column;
  display: flex;
`;

export const DivFilter = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
`;
