import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 25px;
  left: 50%;

  display: ${({ enabled }) => (enabled ? 'flex' : 'none')};
  align-items: center;

  width: 90%;
  min-height: 48px;

  padding: 11px 22px;
  margin-left: -45%;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.27);
  z-index: 999999;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;

  width: 100%;

  @media screen and (max-width: 1025px) {
    ${({ showTemperature }) =>
      showTemperature &&
      css`
        flex-direction: column;
        gap: 12px 0;
      `}
  }
`;

export const FeedbackText = styled.span`
  margin-right: 1.875rem;

  font: normal 1rem/1em 'Roboto', sans-serif;
  font-weight: ${props => props.fontWeight};
  color: #2f3741;
`;

export const CloseBtn = styled.button`
  display: flex;
  align-items: center;

  font-size: 1.875rem;
  color: #2f3741;

  background-color: transparent;
  border: none;

  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
`;

export const BuyForMessage = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 12px;
`;

export const BuyForText = styled(FeedbackText)`
  width: 100%;
  margin-right: 0;
  font-size: 14px;
  line-height: 20px;
`;
