import React, { createContext, useContext, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { useLocalStorage } from '../hooks';

export const AppContext = createContext({
  isApp: false,
});
export const useAppContext = () => useContext(AppContext);
export const AppConsumer = AppContext.Consumer;

export const AppProvider = ({ children }) => {
  const location = useLocation();
  const [isApp, setApp] = useLocalStorage('app', false);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query && query.app) {
      setApp(query.app === 'mobile');
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        isApp,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
