import moment from 'moment';

function getDates(notification) {
  const toDay = moment().format('YYYY-MM-DD');
  let date = moment(notification.auction.expectedEndTime);
  if (date.format('YYYY-MM-DD') !== toDay && date.isAfter(toDay)) {
    return date.format('YYYY-MM-DD');
  } else {
    return date.format('YYYY-MM-DD');
  }
}

function sortDates(dateA, dateB) {
  if (moment(dateA).isBefore(dateB)) {
    return 1;
  }
  if (moment(dateA).isAfter(dateB)) {
    return -1;
  }
  return 0;
}

function getNotificationsByDate(date, notifications) {
  return notifications.filter(notification => {
    if (getDates(notification) === date) return true;
  });
}

export function encapsulateNotificationsByDate(notifications) {
  // Get all auctions dates
  const dates = notifications.map(getDates);

  // Remove repeated dates
  const uniqDates = [...new Set(dates)];

  // Orders decreasingly
  const sortedDates = uniqDates.sort(sortDates);

  // Initializes an array that will encapsulate the information
  let listNotifications = [];

  for (let date of sortedDates) {
    const items = {
      date,
      data: getNotificationsByDate(date, notifications),
    };
    listNotifications.push({
      ...items,
    });
  }
  return listNotifications;
}
