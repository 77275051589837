import { rem, size } from 'polished';
import { css } from 'styled-components';
import { themeGet } from 'styled-system';

const indicatorStyle = (thickness, color) => css`
  &::after {
    display: block;
    position: absolute;
    content: '';
    height: ${rem(thickness)};
    background-color: ${themeGet(`colors.${color}`)};
    width: 100%;
    bottom: 0;
    left: 0;
  }
`;

const activeStyle = color => ({ active }) => active && indicatorStyle(6, color);

const defaultStyles = css`
  font-family: ${themeGet('fonts.paragraphs')};
  font-size: ${themeGet('fontSizes.2')};
  line-height: ${themeGet('lineHeights.paragraphs')};
  cursor: pointer;
  ${({ active }) => css`
    font-weight: ${themeGet(`fontWeights.paragraphs.${active ? 2 : 0}`)};
  `}

  svg {
    ${size(rem(20))}
    margin-right: ${themeGet('space.1')};
  }
`;

export default {
  primary: css`
    ${defaultStyles}
    ${activeStyle('alerts.SoulS40')}

    color: ${themeGet('colors.primaries.Taxi')};
  `,
};
