import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { themeGet } from 'styled-system';

import { Check as BaseCheck } from '..';
import { Substep, substepPropTypes } from './Substep';
import { Box, Text } from '../../../abstract';

export const stepPropTypes = {
  title: PropTypes.string.isRequired,
  titleAfterDone: PropTypes.string,
  description: PropTypes.string,
  isDone: PropTypes.bool,
  isActive: PropTypes.bool,
  isPassed: PropTypes.bool,
  substeps: PropTypes.arrayOf(substepPropTypes),
};

const Wrapper = styled(Box)`
  border-left: 1px solid ${props => themeGet(`colors.${props.lineColor}`)(props)};
  line-height: ${rem(16)};

  &:last-child {
    border-left-color: transparent;
    padding-bottom: 0;
  }
`;

const Check = styled(BaseCheck).attrs({
  position: 'absolute',
  left: rem(-10),
  top: 0,
})``;

export const Step = ({
  title,
  titleAfterDone,
  isDone,
  isActive,
  isPassed,
  description,
  substeps = [],
  color = 'primaries.Soul',
}) => {
  return (
    <Wrapper
      position="relative"
      pl={rem(20)}
      lineColor={isPassed ? color : 'neutrals.EclipseE100'}
      pb={(description || substeps.length) && isActive ? 1 : 3}
    >
      <Check isChecked={isDone} bg={color} isDisabled={!isActive && !isDone} />
      <Text fontWeight="paragraphs.bold" lineHeight={rem(20)} fontSize={1}>
        {isDone && titleAfterDone ? titleAfterDone : title}
      </Text>

      {description && (
        <Text fontSize={0} color="neutrals.EclipseE500">
          {description}
        </Text>
      )}

      {isActive && substeps.map(step => <Substep key={step} step={step} color={color} mt={1} />)}
    </Wrapper>
  );
};

Step.propTypes = {
  ...stepPropTypes,
  color: PropTypes.string,
};
