import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  color: #ffffff;
  background-color: #007e62;
  height: 161px;

  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) minmax(110px, 420px);

  @media (max-width: 760px) {
    width: 100%;
    transform: none;

    display: grid;
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  margin-right: 10px;
`;

export const Img = styled.img`
  height: 130px;
  object-fit: fill;

  @media (max-width: 488px) {
    right: 0;
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  height: 70.5%;

  @media (max-width: 1023px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 760px) {
    padding-left: 20px;
  }

  @media (max-width: 488px) {
    padding: 10px 0 10px 10px;
  }
`;

export const Title = styled.h1`
  font-size: 52px;
  font-family: 'Balboa Condensed', sans-serif;
  white-space: nowrap;

  @media (max-width: 896px) {
    font-size: 28px;
  }

  @media (max-width: 760px) {
    font-size: 32px;
    width: calc(100% + 60px);
    font-size: 24px;
    white-space: nowrap;
  }

  @media (max-width: 488px) {
    width: calc(100% + 20px);
    font-size: 24px;
  }
`;

export const Description = styled.p`
  font-family: 'Rubik', sans-serif;
  font-size: 17px;
  word-wrap: break-word;
  line-height: 20px;
  margin: 5px 0 10px 0;
  width: calc(100% + 30px);
  min-width: 170px;
  padding-right: 20px;

  @media (max-width: 896px) {
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    padding-right: 0px;
  }

  @media (max-width: 488px) {
    width: 100%;
    line-height: 14px;
    padding-right: 0px;
    font-size: 10px;
  }
`;

export const BulletPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const BulletPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BulletPointText = styled.div`
  display: flex;
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  align-items: center;
  font-weight: 700;
  line-height: 18px;

  @media (max-width: 896px) {
    font-size: 10px;
  }

  @media (max-width: 760px) {
    font-weight: 500;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70.5%;
  margin-left: 24%;

  @media (max-width: 760px) {
    align-items: start;
    justify-content: start;
    height: auto;
    margin-left: 0;
  }
`;

export const Button = styled.a`
  display: inline-block;
  background-color: #89fff6;
  color: #171c24;
  border: none;
  border-radius: 5px;
  max-width: 191px;
  width: 100%;
  min-width: 170px;
  padding: 17px 0px 17px 0px;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  @media (max-width: 792px) {
    margin-left: 0;
    max-width: fit-content;
    padding: 10px;
  }

  @media (max-width: 760px) {
    margin: 0;
    font-size: 12px;
  }
`;

export const ImgDeskDiv = styled.div`
  height: 70.5%;
`;

export const ImgMobDiv = styled.div`
  height: 69.2%;
`;
