import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import trophy from 'images/trophy.svg';
import './styles.scss';

const SuccessMessage = ({ icon, title, subtitle, className, ...props }) => {
  const classNames = classnames('success-message', className);
  return (
    <div className={classNames} {...props}>
      <img src={icon} />
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
  );
};
SuccessMessage.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};
SuccessMessage.defaultProps = {
  icon: trophy,
};

export { SuccessMessage };
