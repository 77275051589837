import initialState from './initialState';
import ActionTypes from '../../constants/actionTypes';

function inspectionsReducer(state = initialState.inspections, action) {
  switch (action.type) {
    case ActionTypes.Inspections.Fetch:
      return {
        ...state,
        loading: true,
        haveError: false,
        error: '',
      };
    case ActionTypes.Inspections.FetchedSuccess:
      return {
        ...state,
        loading: false,
        items: action.items,
        pageCount: action.pageCount,
      };
    case ActionTypes.Inspections.FetchedError:
      return {
        ...state,
        loading: false,
        items: [],
        pageCount: 1,
        haveError: true,
        error: action.error,
      };
    default:
      return state;
  }
}

export default inspectionsReducer;
