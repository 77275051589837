import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppConstants from '../../../constants/appConstants';
import {
  negotiationStart,
  negotiationEnd,
  won,
  end,
  start,
  buyNowWon,
  updateBuyNowPrice,
  cancel,
  setDuration,
  outBid,
  preferenceStart,
  temperatureNotification,
  buyForEnabled,
} from '../../../store/actions/auction.actions';

class AuctionPusher extends React.Component {
  constructor() {
    super();
    this.auctionsChannel = null;
    this.negotiationsChannel = null;
    this.notificationsChannel = null;
    this.notificationsPusherChannel = null;
    this.negotiationStart = this.negotiationStart.bind(this);
    this.temperatureNotification = this.temperatureNotification.bind(this);
    this.negotiationEnd = this.negotiationEnd.bind(this);
  }

  componentDidMount() {
    this.initPusher();
    this.subscribeToChannels();
    this.registerAllEvents();
  }

  componentWillUnmount() {
    this.pusher.unsubscribe(AppConstants.AuctionsPusherChannel);
    this.pusher.unsubscribe(AppConstants.NegotiationsPusherChannel);
    this.pusher.unsubscribe(AppConstants.NotificationsPusherChannel);
    this.pusher.unsubscribe(AppConstants.NotificationsTemperaturePusherChannel);
  }

  initPusher() {
    this.pusher = this.props.pusher;
  }

  subscribeToChannels() {
    this.auctionsChannel = this.pusher.subscribe(AppConstants.AuctionsPusherChannel);
    this.negotiationsChannel = this.pusher.subscribe(AppConstants.NegotiationsPusherChannel);
    this.notificationsChannel = this.pusher.subscribe(AppConstants.NotificationsPusherChannel);
    this.notificationsPusherChannel = this.pusher.subscribe(
      AppConstants.NotificationsTemperaturePusherChannel
    );
  }

  registerAllEvents() {
    this.getAuctionEvents().forEach(event => {
      this.auctionsChannel.bind(event.eventName, event.eventHandler);
    });

    this.getNegotiationsEvents().forEach(event => {
      this.negotiationsChannel.bind(event.eventName, event.eventHandler);
    });

    this.getNotificationsEvents().forEach(event => {
      this.notificationsChannel.bind(event.eventName, event.eventHandler);
    });

    this.getNotificationsPusherEvents().forEach(event => {
      this.notificationsPusherChannel.bind(event.eventName, event.eventHandler);
    });
  }

  getAuctionEvents() {
    return [
      { eventName: 'start', eventHandler: this.startEvent() },
      { eventName: 'outbid', eventHandler: this.outBidEvent() },
      {
        eventName: 'extend-duration',
        eventHandler: this.extendDurationEvent(),
      },
      { eventName: 'set-duration', eventHandler: this.setDurationEvent() },
      { eventName: 'won', eventHandler: this.wonEvent() },
      { eventName: 'end', eventHandler: this.endEvent() },
      { eventName: 'cancel', eventHandler: this.cancelEvent() },
      {
        eventName: 'update-buy-now-price',
        eventHandler: this.updateBuyNowPrice(),
      },
      { eventName: 'buy-for-amount', eventHandler: this.buyForEnabled() },
      { eventName: 'buy-now-won', eventHandler: this.buyNowWon() },
    ];
  }

  getNegotiationsEvents() {
    return [
      { eventName: 'start', eventHandler: this.negotiationStart() },
      { eventName: 'end', eventHandler: this.negotiationEnd() },
    ];
  }

  getNotificationsEvents() {
    return [{ eventName: 'COMBINED_PREFERENCE', eventHandler: this.preferenceStart() }];
  }

  getNotificationsPusherEvents() {
    return [{ eventName: 'TEMPERATURE', eventHandler: this.temperatureNotification() }];
  }

  preferenceStart() {
    return data => {
      this.props.preferenceStart(data);
    };
  }

  negotiationStart() {
    return data => {
      //console.info('negotiationStart', data);
      this.props.negotiationStart(data, this.props.auctions);
    };
  }
  temperatureNotification() {
    return data => {
      this.props.temperatureNotification(data);
    };
  }

  negotiationEnd() {
    return data => {
      //console.info('negotiationEnd', data);
      this.props.negotiationEnd(data, this.props.auctions);
    };
  }

  startEvent() {
    const self = this;
    return data => {
      // console.info('start', data);
      self.props.start(data, self.props.notifier);
    };
  }

  outBidEvent() {
    const self = this;
    return data => {
      //console.info('outBid', data);
      self.props.outBid(data, self.props.notifier, self.props.auctions);
    };
  }

  extendDurationEvent() {
    const self = this;
    return data => {
      //console.info('extendDurationEvent', data);
      self.props.setDuration(data, true);
    };
  }

  setDurationEvent() {
    const self = this;
    return data => {
      //console.info('setDurationEvent', data);
      self.props.setDuration(data);
    };
  }

  wonEvent() {
    const self = this;
    return data => {
      //console.info('wonEvent', data);
      self.props.won(data, self.props.notifier, self.props.auctions);
    };
  }

  endEvent() {
    const self = this;
    return data => {
      //console.info('endEvent', data);
      self.props.end(data);
    };
  }

  cancelEvent() {
    const self = this;
    return data => {
      //console.info('cancelEvent', data);
      self.props.cancel(data);
    };
  }

  updateBuyNowPrice() {
    const self = this;
    return data => {
      //console.info('update-buy-now-price', data);
      self.props.updateBuyNowPrice(data);
    };
  }

  buyForEnabled() {
    const self = this;
    return data => self.props.buyForEnabled(data);
  }

  buyNowWon() {
    const self = this;
    return data => {
      //console.info('buy-now-won', data);
      self.props.buyNowWon(data, this.props.notifier, this.props.auctions);
    };
  }

  render() {
    return null;
  }
}

AuctionPusher.propTypes = {
  negotiationStart: PropTypes.func.isRequired,
  temperatureNotification: PropTypes.func.isRequired,
  negotiationEnd: PropTypes.func.isRequired,
  buyNowWon: PropTypes.func.isRequired,
  updateBuyNowPrice: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  end: PropTypes.func.isRequired,
  won: PropTypes.func.isRequired,
  setDuration: PropTypes.func.isRequired,
  outBid: PropTypes.func.isRequired,
  start: PropTypes.func.isRequired,
  notifier: PropTypes.object,
  auctions: PropTypes.array.isRequired,
  buyForEnabled: PropTypes.func.isRequired,
};

export default connect(null, {
  negotiationStart,
  temperatureNotification,
  negotiationEnd,
  buyNowWon,
  updateBuyNowPrice,
  cancel,
  end,
  won,
  setDuration,
  outBid,
  start,
  preferenceStart,
  buyForEnabled,
})(AuctionPusher);
