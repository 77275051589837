import React, { useState, useEffect, useCallback } from 'react';
import {
  Text,
  MainCardContainer,
  ArrowContainer,
  DropContainer,
  SubContainer,
  Container,
  CardsContainer,
  StatusImgContainer,
  LinhaVertical,
  CardSlugContainer,
  CardOpenInfo,
  InfoText,
  InfoContainer,
  InfoLinkText,
  UpdateDiv,
  LinkMessageDiv,
  UpdateText,
  DateDiv,
  IconsDiv,
  FinancingButton,
  CashLink,
  FinanceDiv,
} from './styles';
import ArrowDown from '../../../../images/icons/fi-bs-angle-down.svg';
import ArrowUp from '../../../../images/icons/fi-bs-angle-up.svg';
import CheckPurchase from '../../../../images/icons/check-purchase.svg';
import PendingPurchase from '../../../../images/icons/icon-pending.svg';
import ErrorPurchase from '../../../../images/icons/icon-error.svg';
import CalendarIcon from '../../../../images/icons/fi-bs-calendar.svg';
import { SubCardItem } from '../SubCardItem';
import { usePurchaseTime } from '../../../../hooks/usePurchaseTime';

export const CardItem = ({ all, lastUpdated, showFinCredi }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasActiveTrue = all.subMsg ? all.subMsg.some(subMsg => subMsg.active === true) : false;

  const CardActivated = useCallback(() => {
    if (
      all.state === 'complete' ||
      all.state === 'active' ||
      all.state === 'done' ||
      all.state === 'error'
    ) {
      return true;
    } else {
      return false;
    }
  }, [all]);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    (all.state === 'active' || all.state === 'error' || all.showDeliveredSchedule) &&
      setIsOpen(true);
  }, [all]);

  return (
    <Container>
      <StatusImgContainer>
        <img
          className="icon"
          src={
            all.state === 'complete' || all.state === 'done'
              ? CheckPurchase
              : all.state === 'pending' || all.state === 'active'
              ? PendingPurchase
              : ErrorPurchase
          }
          alt="ícone"
        />
        {all.showLine && all.state !== 'error' && (
          <LinhaVertical
            color={all.state === 'complete' || all.state === 'done' ? '#bcf738' : '#F7D1B7'}
          />
        )}
      </StatusImgContainer>
      <CardsContainer>
        <MainCardContainer
          backgroundColor={CardActivated() ? '#FFFFFF' : '#F3F3EF'}
          onClick={CardActivated() ? handleDropdownToggle : null}
        >
          <CardOpenInfo>
            <CardSlugContainer>
              <Text color={CardActivated() ? '#2f3741' : '#B9BBBD'}>{all.label}</Text>
              <ArrowContainer>
                {isOpen ? (
                  <img className="icon" src={ArrowUp} alt="ícone" />
                ) : (
                  <img className="icon" src={ArrowDown} alt="ícone" />
                )}
              </ArrowContainer>
            </CardSlugContainer>
            {isOpen && (
              <InfoContainer>
                <UpdateDiv>
                  {lastUpdated && (
                    <DateDiv>
                      <IconsDiv>
                        <img className="icon" src={CalendarIcon} alt="ícone" />
                      </IconsDiv>
                      <UpdateText>
                        {usePurchaseTime(all.updateTime ? all.updateTime : lastUpdated)}
                      </UpdateText>
                    </DateDiv>
                  )}
                  <LinkMessageDiv>
                    {all.msg.long && (
                      <InfoText color={!hasActiveTrue ? '#171C24' : '#B9BBBD'}>
                        {all.msg.long}
                      </InfoText>
                    )}
                    {/*                     {all.id === 1 && (
                      <InfoLinkText onClick={() => window.open(all.data.value, '_blank')}>
                        {all.data.dataLabel}
                      </InfoLinkText>
                    )} */}
                    {all.id === 4 && !hasActiveTrue && (
                      <FinanceDiv>
                        {showFinCredi && (
                          <FinancingButton onClick={() => window.open(all.data.value, '_blank')}>
                            {all.data.dataLabel}
                          </FinancingButton>
                        )}
                        <CashLink
                          onClick={() => window.open(all.data.secondValue, '_blank')}
                          onlyCash={showFinCredi ? 15 : 20}
                        >
                          Pagamento à vista
                        </CashLink>
                      </FinanceDiv>
                    )}
                  </LinkMessageDiv>
                </UpdateDiv>
              </InfoContainer>
            )}
          </CardOpenInfo>
        </MainCardContainer>
        {isOpen && all.subMsg && (
          <SubContainer>
            {all.subMsg.map((sub, index) => (
              <SubCardItem key={index} sub={sub} all={all} />
            ))}
          </SubContainer>
        )}
      </CardsContainer>
    </Container>
  );
};
