import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { toggleModal } from '../../../../store/actions/ui.actions';
import { AUCTION_OFFER_KEY, AUCTION_BUY_KEY } from '../../../../constants/modalConstants';
import { Price, Button, BuyNowButton } from '../..';
import { Flex, Box, Text } from '../../../abstract';
import { toPrice } from '../../../../utils/number';
import { media } from '../../../../design-system/utils';

const ButtonsWrapper = styled(Flex).attrs({ width: 1 })`
  max-width: ${props => (props.isSmall ? rem(260) : rem(420))};

  ${media.md`
    max-width: none;
  `};
`;

export const AuctionDetailsCTA = ({
  isSmall,
  amount,
  handleMakeOfferClick,
  handleBuyNowClick,
  buyNowAmount,
  showBuyNow,
  showMakeOfferButton,
  showSellerOfferMessage,
}) => {
  return (
    <Flex alignItems="center" flexWrap="wrap" justifyContent={{ sm: 'flex-end', lg: 'flex-start' }}>
      <Box flex="1" mb={{ sm: 1, md: 0 }}>
        <Text
          fontSize={isSmall ? 0 : { sm: 1, md: 3 }}
          fontWeight={isSmall ? 'paragraphs.regular' : 'paragraphs.medium'}
          lineHeight="1"
        >
          Última oferta
        </Text>

        <Price
          value={showMakeOfferButton ? toPrice(amount, false) : '---'}
          symbolFontSize={isSmall ? 0 : 2}
          fontSize={isSmall ? 5 : { sm: 5, md: rem(32) }}
        />
      </Box>

      <ButtonsWrapper isSmall={isSmall}>
        {showSellerOfferMessage && (
          <Text>
            Estamos conversando com o vendendor, assim que tivermos uma resposta te avisaremos
          </Text>
        )}

        {showMakeOfferButton && (
          <Box flex={isSmall ? '1' : '3'} mr={showBuyNow ? 1 : 0}>
            <Button
              onClick={handleMakeOfferClick}
              width={1}
              fontSize={isSmall ? 1 : { sm: 2, md: rem(20) }}
              height={isSmall ? rem(40) : { sm: rem(44), md: rem(54) }}
            >
              Fazer oferta
            </Button>
          </Box>
        )}

        {showBuyNow && showMakeOfferButton && (
          <Box flex={isSmall ? '1' : '2'}>
            <BuyNowButton
              onClick={handleBuyNowClick}
              price={buyNowAmount}
              priceProps={{
                fontSize: isSmall ? 3 : { sm: 2, md: 5 },
                symbolFontSize: isSmall ? rem(9) : 0,
              }}
            />
          </Box>
        )}
      </ButtonsWrapper>
    </Flex>
  );
};

AuctionDetailsCTA.propTypes = {
  isSmall: PropTypes.bool,
  amount: PropTypes.number.isRequired,
  showBuyNow: PropTypes.bool,
  onBuyNowClick: PropTypes.func,
  handleBuyNowClick: PropTypes.func.isRequired,
  onMakeOfferClick: PropTypes.func,
  handleMakeOfferClick: PropTypes.func.isRequired,
  buyNowAmount: PropTypes.number,
  showSellerOfferMessage: PropTypes.bool,
  showMakeOfferButton: PropTypes.bool,
};

AuctionDetailsCTA.defaultProps = {
  buyNowAmount: 0,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleBuyNowClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const { onBuyNowClick } = ownProps;

    if (typeof onBuyNowClick === 'function') {
      onBuyNowClick();
    }

    dispatch(toggleModal({ key: AUCTION_BUY_KEY, show: true }));
  },
});

export default connect(null, mapDispatchToProps)(AuctionDetailsCTA);
