import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  color: #ffffff;
  background-color: #fff;
  height: 89px;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 6px;

  display: flex;

  @media (max-width: 760px) {
    height: 146px;
    width: 100%;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media (max-width: 760px) {
    justify-content: start;
    align-items: start;
    display: inline-block;
    margin-top: -4px;
    margin-right: 5px;
  }
`;

export const Img = styled.img`
  height: 130px;
  object-fit: fill;
  flex-direction: row;
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-bottom: -3px;

  @media (max-width: 760px) {
    margin-bottom: 0px;
  }
`;

export const Row = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 760px) {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  width: 130%;
  flex: 7;

  @media (max-width: 1023px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 760px) {
    padding-left: 20px;
  }

  @media (max-width: 488px) {
    padding: 10px 0 10px 10px;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;

  @media (max-width: 760px) {
    flex-direction: row
    font-size: 14px;
    margin-left: 8px
    margin-bottom: 0px;
  }
`;

export const Description = styled.a`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #000000;
  text-decoration: underline;
  margin: 0px;
  margin-right: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  margin-left: 13%;
  flex: 3;

  @media (max-width: 760px) {
    align-items: center;
    margin-right: 0px;
    margin: 10px 0px 15px 0px;
  }
`;

export const Button = styled.a`
  display: inline-block;
  background-color: transparent;
  color: #22aa52;
  font-weight: 700;
  border: solid;
  border-radius: 5px;
  border-color: #22aa52;
  max-width: 200px;
  width: 100%;
  min-width: 180px;
  padding: 10px 20px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  @media (max-width: 760px) {
    margin-left: 0;
    max-width: fit-content;
    padding: 10px;
    min-width: 350px;
  }
`;
