import React from 'react';
import { Tag, TagText, Container } from './styles';
import { formatPrice } from '../../utils/formatPrice';

import FincreditCoin from '../../images/icons/fincredit-coin.svg';

export function TagFincreditLimit(limitValue) {
  return (
    <Container>
      <Tag>
        <img src={FincreditCoin} alt={'fincredit icon'} />
        <TagText>Saldo: R${formatPrice(limitValue.limitValue)}</TagText>
      </Tag>
    </Container>
  );
}
