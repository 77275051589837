import React from 'react';
import { Container, FiltersButton } from './styles';

export const FilterButton = ({
  label,
  className,
  noBorder,
  leftRadius,
  rightRadius,
  isSelected,
  onClick,
}) => {
  return (
    <Container
      noBorder={noBorder}
      leftRadius={leftRadius}
      rightRadius={rightRadius}
      isSelected={isSelected}
      onClick={onClick}
    >
      <FiltersButton isSelected={isSelected} className={className}>
        {label}
      </FiltersButton>
    </Container>
  );
};
