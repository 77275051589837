import styled, { css } from 'styled-components';

export const Backdrop = styled.div`
  ${({ isOpen }) => css`
    display: ${isOpen ? 'flex' : 'none'};
  `}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;

  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
`;

export const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '680px',
    maxWidth: '100%',
    transform: 'translate(-50%, -50%)',
    padding: '48px 120px',
    margin: '20px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    zIndex: '99999',
    position: 'relative',

    backgroundColor: '#fff',
    dropxShadow: '0px 4px 4px 10px rgba(0, 0, 0, 0.25)',
  },
};

export const Container = styled.div`
  position: absolute;
  max-width: '680px';
  width: '100%';
  height: fit-content;
  padding: '48px 120px';

  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
  gap: 16;
  z-index: 99999;
  background-color: #fff;

  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  width: '680px';
  max-width: '100%';
  transform: 'translate(-50%, -50%)';
`;

export const WarningIcon = styled.img`
  width: 65px;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin-top: 8px;
`;
export const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
export const Button = styled.button`
  font-family: Roboto;
  background-color: #2274a5;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  border: none;
  height: 48px;
  padding-inline: 40px;
  border-radius: 6px;
  margin-top: 8px;
`;
