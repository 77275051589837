import React, { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { REMOVE_AUTOMATIC_OFFER_KEY } from '../../../../constants/modalConstants';
import { AiFillCloseCircle } from 'react-icons/ai';
import { PrimaryButton } from '../../../Buttons/old';
import Modal from '../../../enhanced/Modal';
import {
  Content,
  Title,
  Message,
  KeepOfferButton,
  removeAutomaticStyle,
  specificStyle,
} from './styles';
import { connect, useSelector } from 'react-redux';
import { toggleModal } from '../../../../store/actions/ui.actions';
import { removeAutoBidVehicle } from '../../../../store/autobid/effects';
import { useSegment } from '../../../../hooks/useSegment';

const RemoveAutomaticOffer = ({ show, toggleModal, removeAutoBidVehicle }) => {
  const { user } = useSelector(state => state?.authentication);
  const { loading, error } = useSelector(state => state?.autoBid);

  const { extras } = useSelector(state => state.ui.modals.removeAutomaticOffer);

  const { selectedAuctionId, selectedAutoBidId, section, otherStyle = false, selectedAuctionAuId } =
    extras || {};

  const { trackRemoveAutoBid } = useSegment();

  const handleCancelAutomaticOfferRemoval = useCallback(() => {
    toggleModal({
      key: REMOVE_AUTOMATIC_OFFER_KEY,
      show: false,
    });
  }, []);

  const handleRemoveAutomaticOffer = useCallback(async () => {
    await removeAutoBidVehicle(selectedAutoBidId, user.id);
    trackRemoveAutoBid(selectedAuctionId, selectedAutoBidId, selectedAuctionAuId, section);

    if (!error) {
      return toggleModal({
        key: REMOVE_AUTOMATIC_OFFER_KEY,
        show: false,
      });
    }
  }, [removeAutoBidVehicle, selectedAuctionId, error, toggleModal]);

  return (
    <Modal
      show={show}
      toggle={() => toggleModal({ key: REMOVE_AUTOMATIC_OFFER_KEY, show: false })}
      style={otherStyle ? specificStyle : removeAutomaticStyle}
    >
      <Content>
        <Title>Deseja remover a oferta automática?</Title>

        {otherStyle ? (
          <Message>
            Para efetuar a <strong>oferta avulsa</strong> é necessário remover a oferta automática.
            Não se preocupe, os lances efetuados serão mantidos e caso possua edições disponíveis
            será possível voltar para a oferta automática.
          </Message>
        ) : (
          <Message>
            Ao remover, somente os lances já <br /> efetuados serão mantidos.
          </Message>
        )}

        <PrimaryButton
          color="#D90A0A"
          modal={!otherStyle ? true : false}
          onClick={handleRemoveAutomaticOffer}
          isLoading={loading}
        >
          <AiFillCloseCircle fill="#fff" size={14} style={{ marginRight: '0.625rem' }} />
          Remover oferta automática
        </PrimaryButton>

        <KeepOfferButton onClick={handleCancelAutomaticOfferRemoval}>
          Manter oferta automática
        </KeepOfferButton>
      </Content>
    </Modal>
  );
};

const mapStateToProps = state => ({
  show: state.ui.modals.removeAutomaticOffer.show,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  removeAutoBidVehicle: bindActionCreators(removeAutoBidVehicle, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAutomaticOffer);
