import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ModalContainer as Modal, withModal } from '../base';
import { AUCTION_REJECTED_KEY } from '../../../Modals';
import { VehicleDetails } from '../../../shared/vehicle-details';

import { CloseButton as Button } from '../shared';

import './styles.scss';

const Content = withModal(({ modal }) => (
  <div>
    <VehicleDetails auction={modal.extras().auction} />
  </div>
));

const RejectedModal = ({ username, className, ...props }) => {
  const classNames = classnames('rejected-modal', className);
  return (
    <Modal modalKey={AUCTION_REJECTED_KEY} className={classNames} {...props}>
      <Modal.Header secondary large>
        cliente não aceitou
      </Modal.Header>
      <Modal.Body>
        <Content username={username} />
      </Modal.Body>
      <Modal.Footer>
        <Button>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};
RejectedModal.propTypes = {
  username: PropTypes.string,
  className: PropTypes.string,
};

const mapStateToProps = ({ authentication }) => ({
  username: authentication.user.getLoginName(),
});
const RejectedModalContainer = connect(mapStateToProps)(RejectedModal);

export { RejectedModal, RejectedModalContainer };
