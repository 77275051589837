import React from 'react';
import { useTooltip, TooltipPopup } from '@reach/tooltip';
import Portal from '@reach/portal';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import '@reach/tooltip/styles.css';

import { secondaries } from '../design-system/colors';

const centered = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  };
};

const Popup = styled(TooltipPopup)`
  background: ${props => props.bg};
  color: ${props => props.color};
  border: none;
  border-radius: 3px;
  padding: 1em;
  font-family: ${themeGet('fonts.paragraphs')};
  max-width: 400px;
  white-space: normal;
  border-top: 2px solid ${secondaries.Uno};
  line-height: 1.5;
`;

export const Tooltip = ({
  children,
  label,
  background = '#EAEBEC',
  color = '#2E3743',
  'aria-label': ariaLabel,
}) => {
  const [trigger, tooltip] = useTooltip();
  const { isVisible, triggerRect } = tooltip;

  return (
    <>
      {React.cloneElement(children, trigger)}
      {isVisible && (
        <Portal>
          <div
            className="tooltip-triangle"
            style={{
              position: 'absolute',
              left: triggerRect && triggerRect.left - 10 + triggerRect.width / 2,
              top: triggerRect && triggerRect.bottom + window.scrollY,
              width: 0,
              height: 0,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: `10px solid ${secondaries.Uno}`,
            }}
          />
        </Portal>
      )}

      <Popup
        {...tooltip}
        bg={background}
        color={color}
        label={label}
        aria-label={ariaLabel}
        position={centered}
      />
    </>
  );
};
