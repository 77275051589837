import styled from 'styled-components';
import { rem } from 'polished';
import { Box, Flex } from '../../abstract';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: ${({ isNinja }) => (isNinja ? '20px' : 0)};
  padding: 2rem 1.5rem;
`;

export const VehicleInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VehicleNameAndInfos = styled.span`
  margin-bottom: 0.8rem;

  font: 700 normal 1rem/1.25em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const DealExclusivelyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DealExclusivelyLabel = styled.span`
  margin-bottom: 0.875rem;

  font: 400 normal 1rem/1.375em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const Divider = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: 0.875rem 0;

  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #c4c4c4;

  &:before {
    content: '';

    display: inline-block;
    width: 143px;
    height: 1px;

    margin-right: 8px;

    background-color: #c4c4c4;
  }

  &:after {
    content: '';

    display: inline-block;
    width: 143px;
    height: 1px;

    margin-left: 8px;

    background-color: #c4c4c4;
  }
`;

export const LastOfferContainer = styled.div`
  display: ${props => (props.showDealersDropdown ? 'flex' : 'none')};
  flex-direction: column;

  width: 100%;

  margin-bottom: 0.75rem;
`;

export const LastOfferLabel = styled.span`
  margin-bottom: 0.3rem;

  font: 400 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const LastOfferValue = styled.span`
  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const SingleOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  width: 100%;
`;

export const SingleOfferLabel = styled.span`
  display: ${props => (!props.showDealersDropdown ? 'inline' : 'none')};

  font: 700 normal 1rem/1.125em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const ColorBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;

  width: 100%;
  height: 2rem;

  background-color: ${({ winning }) => (winning ? '#22aa52' : '#f58f29')};
  box-sizing: border-box;

  z-index: -1;
  opacity: ${({ participating }) => (participating ? '1' : '0')};

  transition: all 300ms;
  pointer-events: ${({ participating }) => (participating ? 'initial' : 'none')};
`;

export const ColorBoxTitle = styled(Flex)`
  color: white;
  padding: ${rem(7)};
`;

export const ReturnNewOffers = styled.span`
  margin-top: 1rem;
  font: 400 normal 1rem/1em 'Roboto', sans-serif;
  color: #2274a5;
  text-decoration: underline;
  cursor: pointer;
`;

export const BuyForContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px 0;
`;

export const BuyForText = styled.span`
  font: 400 normal 12.5px/20px 'Roboto', sans-serif;
  color: #2f3741;
`;

export const Button = styled.button`
  width: 100%;
  padding: 12px 0;

  font: 400 normal 16px/1em 'Roboto', sans-serif;
  color: #000;
  text-align: center;

  background-color: #f2c94c;

  border: none;
  border-radius: 4px;

  outline: none;
  cursor: pointer;
`;
