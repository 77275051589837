import styled, { css } from 'styled-components';

const Default = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Participate = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 10px 0;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const Container = {
  Default,
  Participate,
};

export const Label = styled.span`
  margin-bottom: 1.25rem;
  font: 400 normal 0.875rem/1.4em 'Roboto', sans-serif;
  color: #575756;
`;

export const TempName = styled.strong`
  color: ${({ color }) => color ?? '#a34f00'};
`;

export const PriceLabel = styled.strong`
  color: #000;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SliderContent = styled.div`
  display: flex;
  gap: 0 0.25rem;
  align-items: center;
`;

const SliderItem = styled.div`
  width: 33%;
  height: 8px;
  background-color: ${({ color }) => color ?? '#864600'};
  border-radius: 0.375rem;
`;

const SliderPrice = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -0.375rem;
  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #6b0874;

  ${({ temperature }) =>
    temperature?.id === 2 &&
    css`
      align-items: center;
      color: #a34f00;
    `}

  ${({ temperature }) =>
    temperature?.id === 3 &&
    css`
      align-items: flex-end;
      color: #22aa52;
    `}
`;

export const Slider = {
  Container: SliderContainer,
  Content: SliderContent,
  Item: SliderItem,
  Price: SliderPrice,
};
