/* eslint react/no-multi-comp: 0 */
import React from 'react';
import styled from 'styled-components';

import { BarWrapper } from './bar-wrapper';
import { Box, Flex } from '../abstract';
import IPVA from '../shared/IPVA';

export const TabButton = styled(Flex)`
  border-bottom: 3px solid;
  cursor: pointer;
  border-color: transparent;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  ${({ active }) =>
    active &&
    `
    border-color: rgb(214, 229, 238);
    font-weight: bold!important;
  `}
`;

const NotifyIcon = styled.div`
  background: #eb5757;
  border-radius: 50%;
  margin-right: 6px;
  height: 10px;
  width: 10px;
`;

export default ({ tabs, ...etc }) => (
  <BarWrapper {...etc}>
    <Box bg="primaries.Soul" width={1} height={{ sm: '2.5rem', md: '3.7rem' }}>
      <Flex
        alignItems="center"
        justifyContent="center"
        height="100%"
        className="container"
        position="relative"
        mx="auto"
        px={1}
      >
        <Flex height="100%">
          {tabs &&
            tabs.map(({ label, counter, showNotifyIcon, ...rest }) => (
              <TabButton px={{ sm: 1, md: 2 }} key={label} {...rest}>
                {showNotifyIcon && <NotifyIcon />}
                <Box as="strong" mr={1}>
                  {counter}
                </Box>
                {label}
              </TabButton>
            ))}
        </Flex>
      </Flex>
    </Box>
    <IPVA />
  </BarWrapper>
);
