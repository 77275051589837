import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';

import { disableTooltip } from '../../../store/tooltip/actions';

import {
  Container,
  Content,
  FeedbackText,
  CloseBtn,
  Header,
  MessageWrapper,
  BuyForText,
  BuyForMessage,
} from './styles';

import { MdClose } from 'react-icons/md';
import { IoIosCloseCircle } from 'react-icons/io';
import { Typography } from '@mui/material';
import { CheckCircleRounded, Error } from '@mui/icons-material';
import { Temperature } from '../../Temperature';
import { FaCheckCircle } from 'react-icons/fa';

const messagesAutoBid = [
  {
    id: 1,
    icon: <FaCheckCircle size={20} fill="#22AA52" />,
    message: 'Oferta automática efetuada!',
    showTemperature: true,
  },
  {
    id: 2,
    icon: <FaCheckCircle size={20} fill="#22AA52" />,
    message: 'Oferta automática alterada',
    showTemperature: true,
  },
  {
    id: 3,
    message: 'Oferta automática removida.',
    icon: <IoIosCloseCircle size={24} fill="#D90A0A" />,
    showTemperature: false,
  },
  {
    id: 4,
    message: (
      <Typography>
        <Typography component="span" fontWeight={700} color="#22AA52">
          Preferências salvas com sucesso!{' '}
        </Typography>
        Para alterá-las, basta clicar em{' '}
        <Typography component="span" fontWeight={700} sx={{ textDecoration: 'underline' }}>
          “Alterar preferências”
        </Typography>{' '}
        abaixo de Recomendados para você.
      </Typography>
    ),
    icon: <CheckCircleRounded sx={{ color: '#22AA52', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
  {
    id: 5,
    message: (
      <Typography component="span" fontWeight={700} color="#22AA52">
        Suas alterações foram salvas com sucesso!
      </Typography>
    ),
    icon: <CheckCircleRounded sx={{ color: '#22AA52', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
  {
    id: 6,
    message: (
      <Typography>
        <Typography component="span" fontWeight={700} color="#22AA52">
          Suas alterações foram salvas com sucesso!
        </Typography>{' '}
        Ainda não há disputas com base no seu perfil, mas não se preocupe, assim que novas disputas
        entrarem na plataforma você será avisado.
      </Typography>
    ),
    icon: <CheckCircleRounded sx={{ color: '#22AA52', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
  {
    id: 7,
    message: (
      <Typography>
        <Typography component="span" fontWeight={700} color="#DF0808">
          Ops...Ocorreu um erro.
        </Typography>{' '}
        Suas preferências não foram salvas, pedimos desculpas pelo imprevisto. Fique tranquilo, você
        pode tentar novamente mais tarde.
      </Typography>
    ),
    icon: <Error sx={{ color: '#DF0808', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
  {
    id: 8,
    message: (
      <Typography>
        <Typography component="span" fontWeight={700} color="#22AA52">
          Preferências salvas com sucesso!
        </Typography>{' '}
        Ainda não há disputas com base no seu perfil, mas não se preocupe, assim que novas disputas
        entrarem na plataforma você será avisado.
      </Typography>
    ),
    icon: <CheckCircleRounded sx={{ color: '#22AA52', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
  {
    id: 9,
    message: (
      <Typography>
        <Typography component="span" fontWeight={700} color="#22AA52">
          Preferências removidas!
        </Typography>{' '}
        Caso queira ativá-las novamente, basta acessar o menu e clicar em{' '}
        <strong>"Definir preferências de ofertas".</strong>
      </Typography>
    ),
    icon: <CheckCircleRounded sx={{ color: '#22AA52', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
  { id: 10, message: 'Oferta efetuada com sucesso!', showTemperature: true },
  {
    id: 11,
    icon: <FaCheckCircle size={20} fill="#22AA52" />,
    showTemperature: false,
  },
  {
    id: 12,
    message: (
      <Typography component="span" fontWeight={700} color="#22AA52">
        Preferências salvas com sucesso!
      </Typography>
    ),
    icon: <CheckCircleRounded sx={{ color: '#22AA52', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
  {
    id: 13,
    message: (
      <Typography>
        <Typography component="span" fontWeight={700} color="#22AA52">
          Sua solicitação de Perícia Cautelar foi realizada com sucesso!
        </Typography>{' '}
        A Perícia Cautelar ficará disponível após a liberação do carro.
      </Typography>
    ),
    icon: <CheckCircleRounded sx={{ color: '#22AA52', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
  {
    id: 14,
    message: (
      <Typography>
        <Typography component="span" fontWeight={700} color="#DF0808">
          Erro ao solicitar documento
        </Typography>{' '}
        Tente novamente mais tarde.
      </Typography>
    ),
    icon: <Error sx={{ color: '#DF0808', marginRight: 2, marginLeft: 1 }} />,
    showTemperature: false,
  },
];

export default function TooltipFeedback() {
  const dispatch = useDispatch();
  const [currentMessage, setCurrentMessage] = useState();
  const [size, setSize] = useState(window.innerWidth);

  const { allAuctions } = useSelector(state => state.auctions);
  const {
    enabled,
    payload: { inspection, messageId, balance, selectedAuction },
  } = useSelector(state => state.tooltip);
  const { user } = useSelector(state => state.authentication);

  const currentAuction = useMemo(() => find(allAuctions, { _id: inspection }), [
    allAuctions,
    inspection,
  ]);

  const messageMeasurement = useCallback(
    messageId => {
      const selectedMessage = find(messagesAutoBid, { id: messageId });

      if (!selectedMessage) throw new Error('Invalid provided message');

      setCurrentMessage({ ...selectedMessage });
    },
    [setCurrentMessage, messagesAutoBid]
  );

  let timeout;

  useEffect(() => {
    if (enabled) {
      messageMeasurement(messageId);

      timeout = setTimeout(() => {
        window.history.replaceState(null, '');
        dispatch(disableTooltip());
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [enabled, messageMeasurement]);

  const useTemperatureLayout = useCallback(() => {
    if (!currentMessage?.showTemperature) return null;

    if (user.isNinja()) {
      return <Temperature.Ninja auction={currentAuction} currentValue={balance} />;
    }

    return <Temperature.Basic colored auction={currentAuction} currentValue={balance} tooltip />;
  }, [currentMessage, user, currentAuction, balance]);

  const useLayout = useCallback(() => {
    if (!selectedAuction && size <= 1024) {
      return (
        <Content showTemperature={currentMessage?.showTemperature}>
          <Header>
            <MessageWrapper>
              {currentMessage?.icon}
              <FeedbackText fontWeight={700}>{currentMessage?.message}</FeedbackText>
            </MessageWrapper>
            <CloseBtn>
              <MdClose onClick={() => dispatch(disableTooltip())} />
            </CloseBtn>
          </Header>
          {useTemperatureLayout()}
        </Content>
      );
    }

    if (!!selectedAuction && size <= 1024) {
      return (
        <BuyForMessage>
          <FaCheckCircle size={30} fill="#22AA52" />
          <BuyForText>
            A compra do veículo{' '}
            <strong>
              {selectedAuction?.summary?.make} {selectedAuction?.summary?.model}
            </strong>{' '}
            foi efetuada com sucesso. Confira em "Minhas compras".
          </BuyForText>
          <CloseBtn>
            <MdClose size={30} onClick={() => dispatch(disableTooltip())} />
          </CloseBtn>
        </BuyForMessage>
      );
    }

    if (!!selectedAuction) {
      return (
        <React.Fragment>
          <Content>
            {currentMessage?.icon}
            <FeedbackText>
              A compra do veículo{' '}
              <strong>
                {selectedAuction?.summary?.make} {selectedAuction?.summary?.model}
              </strong>{' '}
              foi efetuada com sucesso. Confira em "Minhas compras"
            </FeedbackText>
          </Content>
          <CloseBtn>
            <MdClose onClick={() => dispatch(disableTooltip())} />
          </CloseBtn>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Content>
          {currentMessage?.icon}
          <FeedbackText fontWeight={700}>{currentMessage?.message}</FeedbackText>
          {useTemperatureLayout()}
        </Content>
        <CloseBtn>
          <MdClose onClick={() => dispatch(disableTooltip())} />
        </CloseBtn>
      </React.Fragment>
    );
  }, [size, selectedAuction, currentMessage]);

  window.addEventListener('resize', () => setSize(window.innerWidth));

  return <Container enabled={enabled}>{useLayout()}</Container>;
}
