const date = new Date();
const isDecember = date.getMonth() === 11;
const currentYear = isDecember ? date.getFullYear() + 1 : date.getFullYear();

export const listOfYears = [
  {
    label: '1 ano - A partir de 2023',
    value: currentYear - 1,
  },
  {
    label: '2 anos - A partir de 2022',
    value: currentYear - 2,
  },
  {
    label: '3 anos - A partir de 2021',
    value: currentYear - 3,
  },
  {
    label: '4 anos - A partir de 2020',
    value: currentYear - 4,
  },
  {
    label: '5 anos - A partir de 2019',
    value: currentYear - 5,
  },
  {
    label: '6 anos - A partir de 2018',
    value: currentYear - 6,
  },
  {
    label: '7 anos - A partir de 2017',
    value: currentYear - 7,
  },
  {
    label: '8 anos - A partir de 2016',
    value: currentYear - 8,
  },
  {
    label: '9 anos - A partir de 2015',
    value: currentYear - 9,
  },
  {
    label: '10 anos - A partir de 2014',
    value: currentYear - 10,
  },
  {
    label: '11 anos - A partir de 2013',
    value: currentYear - 11,
  },
  {
    label: '12 anos - A partir de 2012',
    value: currentYear - 12,
  },
  {
    label: '13 anos - A partir de 2011',
    value: currentYear - 13,
  },
  {
    label: '14 anos - A partir de 2010',
    value: currentYear - 14,
  },
  {
    label: '15 anos - A partir de 2009',
    value: currentYear - 15,
  },
  {
    label: '16 anos - A partir de 2008',
    value: currentYear - 16,
  },
  {
    label: '17 anos - A partir de 2007',
    value: currentYear - 17,
  },
  {
    label: '18 anos - A partir de 2006',
    value: currentYear - 18,
  },
  {
    label: '19 anos - A partir de 2005',
    value: currentYear - 19,
  },
  {
    label: '20 anos - A partir de 2004',
    value: currentYear - 20,
  },
];
