import styled from 'styled-components';
import { variant } from 'styled-system';

import { Text } from './Text';

const linkStyle = variant({
  key: 'links',
});

export const Link = styled(Text)(linkStyle);

Link.displayName = 'Link';

Link.defaultProps = {
  as: 'a',
  variant: 'primary',
  fontFamily: 'paragraphs',
  fontWeight: 'paragraphs.bold',
};
