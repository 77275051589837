import styled from 'styled-components';

export const Modal = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  position: fixed;
  overflow: auto;
  height: 100vh;
  width: 100vw;
  z-index: 9;
  left: 0;
  top: 0;
  display: flex;

  &.show {
    display: flex;
  }
`;

Modal.Body = styled.div`
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.3));
  padding: 17px 5px 5px 5px;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  min-height: 54px;
  width: 325px;

  @media (max-width: 882px) {
    width: 100%;
  }
`;

Modal.Close = styled.span`
  position: absolute;
  font-size: 12px;
  cursor: pointer;
  height: 20px;
  right: 0;
  width: 20px;
  top: 5px;
`;
