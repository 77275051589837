import { toPrice } from '../../utils/number';

export const STATUS = {
  OFFER_DECLINED: {
    id: 0,
    key: 'OFFER_DECLINED',
  },
  ON_OFFER: { id: 1, key: 'ON_OFFER' },
  OFFER_ACCEPTED: { id: 2, key: 'OFFER_ACCEPTED' },
  TICKET_SENT: {
    id: 3,
    key: 'TICKET_SENT',
    dataLabel: 'Ver boleto',
    dataType: 'url',
    dataKey: 'bankBilletURL',
  },
  PAID: {
    id: 4,
    key: 'PAID',
    dataLabel: 'Ver nota fiscal',
    dataType: 'url',
    dataKey: 'invoiceURL',
  },
  WAITING_WITHDRAWAL: { id: 5, key: 'WAITING_WITHDRAWAL' },
  DELIVERED: { id: 6, key: 'DELIVERED' },
  DOCUMENTATION_IN_PROGRESS: { id: 2, key: 'DOCUMENTATION_IN_PROGRESS' },
  DOCUMENTATION_READY: {
    id: 7,
    key: 'DOCUMENTATION_READY',
    dataLabel: '',
    dataType: 'text',
    dataKey: 'sedexCode',
  },
  RETURNED: { id: 8, key: 'RETURNED' },
};

const defaultEvents = [
  {
    auctionId: '',
    user: '',
    status: STATUS.ON_OFFER.key,
    updatedAt: null,
    timestamp: 0,
  },
];

export const formatPurchased = (arr = []) =>
  arr.map(
    ({ vsrId, id, negotiationStartedAt, winningBid, negotiations, slug, photo, usedUserTaxId }) => {
      const events = FormatEvents({
        negotiations,
        winningBid,
        negotiationStartedAt,
        id: vsrId,
      });
      return {
        slug,
        src: photo,
        step: events.active ? Math.ceil(events.active.id / 2) : 1,
        events,
        id,
        usedUserTaxId,
        vsrId,
      };
    }
  );

export const FormatEvents = ({
  negotiations = defaultEvents,
  winningBid,
  negotiationStartedAt,
  id,
}) => {
  let events = negotiations.length > 0 ? negotiations : defaultEvents;

  const returned = events.find(el => el.status === STATUS.RETURNED.key);

  if (returned) {
    events = events.filter(el => el.timestamp <= returned.timestamp);
  }

  const lastEvent = events[0];

  let lastUpdated = lastEvent.updatedAt ?? negotiationStartedAt;

  let activeEvent = null;

  const isComplete = (arr = []) =>
    arr.some(status => STATUS[lastEvent.status]?.id > STATUS[status]?.id);

  const isActive = (arr = []) => arr.some(status => status === lastEvent.status);

  const getData = status => {
    const { dataLabel, dataType, dataKey } = STATUS[status];

    const found = events.find(
      e => e.status === status && e.data && e.data.length > 0 && e.data.some(d => d.key === dataKey)
    );

    if (found) {
      return { ...found.data[0], dataLabel, dataType, dataKey };
    } else {
      return null;
    }
  };

  const states = [
    {
      id: 1,
      label: 'Lance',
      pendingMsg: { long: 'Aguardando' },
      completeMsg: { long: `Vencedor ${toPrice(winningBid)}` },
      complete: true,
      data: { dataLabel: 'ver avaliação', dataType: 'url', value: `/detalhes/${id}` },
    },
    {
      id: 2,
      label: 'Fechamento',
      pendingMsg: {
        long: 'Aguardando a resposta do vendedor',
        short: 'Aguardando vendedor',
      },
      completeMsg: { long: 'Proposta aceita' },
      errorMsg: { long: 'Negociação cancelada' },
      error: isActive([STATUS.OFFER_DECLINED.key]),
      complete: isComplete([STATUS.ON_OFFER.key]),
      active: isActive([STATUS.ON_OFFER.key, STATUS.OFFER_DECLINED.key]),
    },
    {
      id: 3,
      label: 'Tramitação InstaCarro',
      pendingMsg: isComplete([STATUS.OFFER_DECLINED.key])
        ? { long: 'Processando carro e documento', short: 'Processando carro' }
        : { long: 'Aguardando' },
      completeMsg: { long: 'Disponivel para tranferência' },
      complete: isComplete([STATUS.OFFER_ACCEPTED.key, STATUS.DOCUMENTATION_IN_PROGRESS.key]),
      active: isActive([STATUS.OFFER_ACCEPTED.key, STATUS.DOCUMENTATION_IN_PROGRESS.key]),
    },
    {
      id: 4,
      label: 'Pagamento',
      pendingMsg: { long: 'Aguardando' },
      completeMsg: { long: 'Pagamento recebido' },
      doneMsg: { long: 'Disponível para pagamento' },
      complete: isComplete([STATUS.TICKET_SENT.key]),
      active: isActive([STATUS.TICKET_SENT.key]),
      done: isActive([STATUS.TICKET_SENT.key]),
      data: isComplete([STATUS.TICKET_SENT.key])
        ? getData(STATUS.PAID.key)
        : getData(STATUS.TICKET_SENT.key),
    },
    {
      id: 5,
      label: 'Liberação do carro',
      pendingMsg: isComplete([STATUS.TICKET_SENT.key])
        ? { long: 'Aguardando liberação' }
        : { long: 'Aguardando' },
      errorMsg: { long: 'Devolução' },
      completeMsg: { long: 'Carro Retirado' },
      doneMsg: { long: 'Pronto para retirada' },
      error: isActive([STATUS.RETURNED.key]),
      complete:
        isComplete([STATUS.TICKET_SENT.key]) &&
        events.find(ev => ev.status === STATUS.DELIVERED.key),
      active: isComplete([STATUS.TICKET_SENT.key]),
      done: isActive([STATUS.WAITING_WITHDRAWAL.key]),
    },
    {
      id: 6,
      label: 'Liberação do documento',
      pendingMsg: isComplete([STATUS.TICKET_SENT.key])
        ? { long: 'Processando documentação' }
        : { long: 'Aguardando' },
      errorMsg: { long: 'Devolução' },
      completeMsg: { long: 'Documento enviado' },
      error: isActive([STATUS.RETURNED.key]),
      active: isComplete([STATUS.TICKET_SENT.key]),
      complete:
        isComplete([STATUS.TICKET_SENT.key]) &&
        events.some(ev => ev.status === STATUS.DOCUMENTATION_READY.key),
      data: getData(STATUS.DOCUMENTATION_READY.key),
    },
  ];

  const newEvents = states.map(
    ({ completeMsg, pendingMsg, errorMsg, doneMsg, error, done, complete, active, id, ...etc }) => {
      const event = {
        state: done
          ? 'done'
          : error
          ? 'error'
          : complete
          ? 'complete'
          : active
          ? 'active'
          : 'pending',
        msg: error ? errorMsg : done ? doneMsg : complete ? completeMsg : pendingMsg,
        id,
        ...etc,
      };
      event.color = getColor(event.state);
      if ((active && !activeEvent) || (active && id === 5)) activeEvent = event;
      return event;
    }
  );

  return {
    active: activeEvent,
    lastUpdated: lastUpdated ? FormatDate({ date: lastUpdated }) : 'N/A',
    timestamp: lastUpdated ? lastUpdated : 'N/A',
    all: newEvents,
  };
};

export const getColor = str => {
  switch (str) {
    case 'active':
      return {
        light: '#ffbe43',
        dark: '#eaa400',
      };
    case 'complete':
      return {
        light: '#76c9e3',
        dark: '#2274A5',
      };
    case 'error':
      return {
        light: '#e57373',
        dark: '#e57373',
      };
    case 'done':
      return {
        light: '#89e095',
        dark: '#44b868',
      };
    default:
      return {
        light: '#BDC0C4',
        dark: '#BDC0C4',
      };
  }
};

export const strIncludes = (value, reference = '') => {
  if (!value || value === '') {
    return true;
  } else {
    const newValue = value.replace(/\s/g, '');
    const newReference = reference.replace(/\s/g, '');
    return newReference.toLowerCase().includes(newValue.toLowerCase());
  }
};

export const FormatDate = ({
  date = null,
  short = false,
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
}) => {
  if (short) options.month = 'numeric';
  return new Date(date).toLocaleDateString('pt-br', options);
};
