import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleModal, setAuctionTabSelected } from '../../store/actions/ui.actions';
import { AUCTION_WON_KEY } from './';
import Modal from '../enhanced/Modal';

const Component = ({ show, toggle, summary, setAuctionTab }) => {
  const details = {
    title: summary ? `${summary.make} ${summary.model} ${summary.version}` : '',
    year: summary ? [summary.productionYear, summary.year].filter(n => Boolean(n)).join(' / ') : '',
    mileage: summary && summary.km ? `${summary.km} km` : 'N/A km',
    plate: summary ? `Placa: ${summary.plateNumber}` : '',
    img: summary ? summary.mainPhoto : '',
  };
  const buttons = [
    {
      link: '/',
      label: 'Ver seus leilões',
      variant: 'primary',
      onClick: () => {
        setAuctionTab(1);
        toggle({ key: AUCTION_WON_KEY, show: false });
      },
      main: true,
    },
  ];
  return (
    <Modal
      title="Você ganhou este leilão!"
      description="Agora é só esperar. Vamos entrar em contato com o dono do carro e negociar o valor."
      won
      buttons={buttons}
      details={details}
      show={show}
      toggle={() => {
        toggle({ key: AUCTION_WON_KEY, show: false });
      }}
    />
  );
};

Component.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  summary: PropTypes.any,
  setAuctionTab: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  toggle: bindActionCreators(toggleModal, dispatch),
  setAuctionTab: bindActionCreators(setAuctionTabSelected, dispatch),
});

const mapStateToProps = ({
  ui: {
    modals: { auctionWon },
  },
}) => ({
  show: auctionWon.show,
  summary:
    auctionWon.extras && auctionWon.extras.auction ? auctionWon.extras.auction.summary : null,
});

const WonModal = connect(mapStateToProps, mapDispatchToProps)(Component);
export { WonModal, Component as default };
