export class AddFilterCommand {
  constructor(service, filter) {
    this.service = service;
    this.filter = filter;
  }

  async execute() {
    const filter = await this.service.addFilter(this.filter);
    this.filter = filter;
  }

  async undo() {
    await this.service.removeFilter(this.filter._id);
  }
}
