import React from 'react';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { themeGet } from 'styled-system';

import { Dialog } from './dialog';
import { Flex, Box } from './abstract';
import { TaxProfileIcon } from './tax-profile-icon';
import arrowRight from '../images/icons/arrow-right.svg';

const TaxIdNickName = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.4rem;
`;

const TaxId = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  color: ${themeGet('colors.primaries.Soul')};
  flex-wrap: wrap;

  ${TaxIdNickName} {
    flex: 1;
    text-align: left;
  }

  svg {
    width: 24px;
  }

  .mini-label {
    font-size: ${themeGet('fontSizes.0')};
    color: ${themeGet('colors.neutrals.EclipseE500')};
    flex-basis: 100%;
  }
`;

const ChangeTaxIdDialog = ({ oldTaxId, newTaxId, header, onCTAClick, onSecondaryBtnClick }) => {
  return (
    <Dialog
      variant="info"
      primaryBtnLabel="Confirmar"
      primaryBtnVariant="secondary"
      header={header}
      onCTAClick={onCTAClick}
      onSecondaryBtnClick={onSecondaryBtnClick}
    >
      <IconContext.Provider value={{ size: 24, color: '' }}>
        <Flex justifyContent="space-between" px={4} py={2}>
          <TaxId>
            <TaxProfileIcon profileType={oldTaxId.type} />
            <TaxIdNickName>{oldTaxId.nickName}</TaxIdNickName>
            <div className="mini-label">Antes</div>
          </TaxId>
          <Box>
            <img src={arrowRight} alt="seta apontando para a direita" />
          </Box>
          <TaxId>
            <TaxProfileIcon profileType={newTaxId.type} />
            <TaxIdNickName>{newTaxId.nickName}</TaxIdNickName>
            <div className="mini-label">Depois</div>
          </TaxId>
        </Flex>
      </IconContext.Provider>
    </Dialog>
  );
};

export { ChangeTaxIdDialog };
