import Types from './types';

export const autoBidRequest = () => ({
  type: Types.AUTOBID_REQUEST,
});

export const autoBidSuccess = payload => ({
  type: Types.AUTOBID_SUCCESS,
  payload,
});

export const autoBidFailure = error => ({
  type: Types.AUTOBID_FAILURE,
  error,
});

export const listAutoBidVehiclesRequest = () => ({
  type: Types.LIST_AUTOBID_VEHICLES_REQUEST,
});

export const listAutoBidVehiclesSuccess = payload => ({
  type: Types.LIST_AUTOBID_VEHICLES_SUCCESS,
  payload,
});

export const listAutoBidVehiclesFailure = error => ({
  type: Types.LIST_AUTOBID_VEHICLES_FAILURE,
  error,
});

export const listAllAutoBidVehiclesRequest = () => ({
  type: Types.LIST_ALL_AUTOBID_VEHICLES_REQUEST,
});

export const listAllAutoBidVehiclesSuccess = payload => ({
  type: Types.LIST_ALL_AUTOBID_VEHICLES_SUCCESS,
  payload,
});

export const listAllAutoBidVehiclesFailure = error => ({
  type: Types.LIST_ALL_AUTOBID_VEHICLES_FAILURE,
  error,
});
