import React, { useCallback } from 'react';

import Modal from '../enhanced/Modal';

import { toggleModal } from '../../store/actions/ui.actions';
import { Box } from '../abstract';
import { rem } from 'polished';
import styled from 'styled-components';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AUCTION_CONFIRM_MAKE_OFFER_KEY } from './';

import ConfirmMakeOfferIcon from '../../images/icons/confirm-make-offer.svg';
import { useSegment } from '../../hooks/useSegment';
import { toAmount } from '../../utils/number';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  max-width: 100%;
  width: 20%;
  margin-bottom: 24px;
`;

const ActionsButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;

  @media screen and (max-width: 415px) {
    flex-direction: column-reverse;
  }
`;

export const Title = styled.span`
  margin-bottom: 0.5rem;

  font: 700 normal 1.25rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const Description = styled.span`
  width: 72%;
  margin-bottom: 1rem;

  font: 400 normal 1.125rem/1.375em 'Roboto', sans-serif;
  color: #2f3741;
  text-align: center;

  @media screen and (max-width: 415px) {
    margin-bottom: 1.125rem;

    width: 100%;
  }
`;

export const ButtonCancel = styled.button`
  padding: 0.8rem 2rem;
  margin-bottom: 0.75rem;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #2274a5;
  text-align: center;

  background-color: transparent;

  border: 1px solid #2274a5;
  border-radius: 0.5rem;

  cursor: pointer;
  outline: none;

  @media screen and (max-width: 415px) {
    width: 100%;
    padding: 0.8rem 0;
  }
`;

export const ConfirmButton = styled.button`
  padding: 0.8rem 2.8rem;
  margin-bottom: 0.75rem;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #fff;
  text-align: center;

  background-color: #2274a5;

  border: none;
  border-radius: 0.5rem;

  cursor: pointer;
  outline: none;

  @media screen and (max-width: 415px) {
    width: 100%;
    padding: 0.8rem 0;
  }
`;

const confirmMakeOfferStyle = {
  content: {
    position: 'relative',
    maxHeight: `calc(100% - ${rem(10)})`,
    maxWidth: `calc(100% - ${rem(10)})`,
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const _ConfirmMakeOfferModal = ({ show, toggleModal }) => {
  const { extras } = useSelector(state => state.ui.modals.auctionConfirmMakeOffer);
  const {
    auction,
    currentOffer,
    section,
    submitOffer,
    isRecommended = false,
    quickButtons,
    setQuickButtons,
  } = extras ?? {};
  const { trackConfirmBid } = useSegment(isRecommended);

  const handleSubmitOffer = useCallback(() => {
    toggleModal({ key: AUCTION_CONFIRM_MAKE_OFFER_KEY, show: false });
    submitOffer();
    trackConfirmBid({
      inspection_id: auction._id,
      auction_id: auction.auction.idAuction,
      currentBid: auction.auction.winningBid.amount,
      exclusivityBid: toAmount(currentOffer),
      type: 'exclusivityBid',
      section,
      quickButtons,
    });
    setQuickButtons([]);
  }, [toggleModal, submitOffer]);

  return (
    <Modal
      toggle={() => {
        toggleModal({ key: AUCTION_CONFIRM_MAKE_OFFER_KEY, show: false });
      }}
      show={show}
      style={confirmMakeOfferStyle}
    >
      <Box
        style={{
          maxWidth: rem(600),
          padding: `${rem(30)} ${rem(25)} ${rem(25)} ${rem(25)}`,
        }}
        bg="white"
      >
        <Content>
          <Icon src={ConfirmMakeOfferIcon} alt="" />
          <Title>Importante</Title>
          <Description>
            Ao continuar, o leilão será encerrado e você será o ganhador com o lance R${' '}
            {currentOffer}. <br /> <strong>Deseja continuar?</strong>
          </Description>

          <ActionsButtons>
            <ButtonCancel
              onClick={() => toggleModal({ key: AUCTION_CONFIRM_MAKE_OFFER_KEY, show: false })}
            >
              Cancelar
            </ButtonCancel>
            <ConfirmButton onClick={handleSubmitOffer}>Sim, quero continuar</ConfirmButton>
          </ActionsButtons>
        </Content>
      </Box>
    </Modal>
  );
};

const mapStateToProps = state => ({
  show: state.ui.modals.auctionConfirmMakeOffer.show,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

const ConfirmMakeOfferModal = connect(mapStateToProps, mapDispatchToProps)(_ConfirmMakeOfferModal);

export { ConfirmMakeOfferModal, ConfirmMakeOfferModal as default };
