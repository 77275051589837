import styled from 'styled-components';
import { rem } from 'polished';

import { media } from '../../../../design-system/utils';
import { Box } from '../../../../components';

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  align-items: center;

  .tax-id-col {
    display: flex;
    align-items: center;
  }

  ${media.lg`
    grid-template-columns: repeat(auto-fill, 33.33%);
    .tax-id-number {
      display: none;
    }
    .tax-id-col {
      font-size: ${rem(14)};
    }
  `};
`;
