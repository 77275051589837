import styled from 'styled-components';

export const Tag = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #357503;
  box-shadow: 0 0.25rem 0.25rem #2274a519;
  padding: 8px;
  border-radius: 16px;
  width: fit-content;
`;

export const TagText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: center;
  color: #fff6a7;
  padding-left: 8px;
  padding-right: 8px;
`;

export const Container = styled.div`
  padding-top: 18px;
  @media screen and (max-width: 1025px) {
    padding-top: 0px;
  }
`;
