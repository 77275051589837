import initialState from './initialState';
import ActionTypes from '../../constants/actionTypes';

function settingsReducer(state = initialState.settings, action) {
  switch (action.type) {
    case ActionTypes.Settings.UpdateAuctionView:
      return {
        ...state,
        auctionView: action.view,
      };
    default:
      return state;
  }
}

export default settingsReducer;
