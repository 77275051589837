import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { themeGet } from 'styled-system';

import { Flex } from '../../../abstract';

const StyledTab = styled(Flex)`
  cursor: pointer;

  &::after {
    display: block;
    position: absolute;
    content: '';
    height: ${props => (props.isActive ? rem(4) : 0)};
    background-color: ${props => themeGet(`colors.${props.tabColor}`)(props)};
    width: 100%;
    bottom: 0;
    left: 0;
  }

  font-weight: ${props =>
      props.isActive
        ? themeGet('fontWeights.paragraphs.bold')(props)
        : themeGet('fontWeights.paragraphs.regular')(props)}
    ${props =>
      !props.isActive &&
      `
    &:hover::after {
      height: ${rem(2)};
    }
  `};
`;

const TabLabel = styled.span`
  text-align: center;
  display: block;
  width: 100%;
`;

export const Tab = ({ children, isActive, tabColor = 'primaries.Soul', ...props }) => (
  <StyledTab
    position="relative"
    py={1}
    px={3}
    alignItems="center"
    tabColor={tabColor}
    isActive={isActive}
    {...props}
  >
    <TabLabel>{children}</TabLabel>
  </StyledTab>
);

Tab.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  tabColor: PropTypes.string,
};
