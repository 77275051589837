import React from 'react';
import { FaTimes } from 'react-icons/fa';

import FilterIcon from '../../../images/icons/filter-icon-white.svg';
import NewOffersFilters from '../new-offers-filters';
import { Container } from './styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleFilters } from '../../../store/actions/auction.actions';

function MobileNewOffersFilters({ show, closeFilters }) {
  function handleClose() {
    closeFilters({ showNewOffersFilters: false });
  }

  return (
    <Container className={show ? 'show' : 'hide'}>
      <Container.Header>
        <Container.Header.Title>
          <img src={FilterIcon} alt="filtrar" />
          <span>Filtrar</span>
        </Container.Header.Title>
        <Container.Header.Close onClick={handleClose}>
          <FaTimes />
        </Container.Header.Close>
      </Container.Header>
      <Container.Body>
        <NewOffersFilters />
      </Container.Body>
    </Container>
  );
}

function mapStateToProps({ ui }) {
  return {
    show: ui.filters.showNewOffersFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeFilters: bindActionCreators(handleFilters, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileNewOffersFilters);
