import actionTypes from '../../constants/actionTypes';
import constants, { sortFilterParams } from '../../constants/appConstants';

export const filterMyPurchasesByKeyword = keyword => ({
  type: actionTypes.UI.SetMyPurchasesKeywordFilter,
  keyword: keyword,
});

export const setMyPurchasesFilters = (status, startDate, endDate) => ({
  type: actionTypes.UI.SetMyPurchasesFilters,
  filters: {
    status,
    startDate,
    endDate,
  },
});

export const clearMyPurchasesFilters = () => ({
  type: actionTypes.UI.ClearMyPurchasesFilters,
});

export const setAuctionTabSelected = selected => ({
  type: actionTypes.UI.SetAuctionTabSelected,
  selected,
});

export const setFilters = ({ price, kilometers, attentionPoints, search, locations }) => ({
  type: actionTypes.UI.SetFilters,
  price,
  kilometers,
  attentionPoints,
  search,
  locations,
});

export const resetFilters = (filters = []) => ({
  type: actionTypes.UI.ResetFilters,
  filters,
});

export const toggleLojaSelector = toggle => ({
  type: actionTypes.UI.SetShowLojaSelector,
  toggle,
});

export const toggleAutoBidConfirmation = toggle => ({
  type: actionTypes.Modal.AutoBidConfirmation.Toggle,
  toggle,
});

export const clearNegotiationModal = () => (dispatch, getState) => {
  const { auction } = getState().ui.negotiationsModal;

  dispatch({
    type: actionTypes.Negotiations.Clear,
  });

  setTimeout(() => {
    dispatch({
      type: actionTypes.Auction.ClearAuction,
      id: auction._id,
    });
  }, constants.TimeToDisappearAuctions);

  const { negotiations } = getState().ui.negotiationsModal;
  // If there is any pending notification, re-show modal with the next negotiation
  if (negotiations.length) {
    dispatch(showNegotiationModal(negotiations[0].accept, negotiations[0].auction));
  }
};

export const endNegotiation = (accept, auction) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.Negotiations.Ended,
    auction,
    accept,
  });

  // If there is an active modal notification at the moment a new one is received
  // do not override it. See clearNegotiationModal.
  if (!getState().ui.negotiationsModal.show) {
    dispatch(showNegotiationModal(accept, auction));
  }
};

export const startNegotiation = auction => ({
  type: actionTypes.Negotiations.Started,
  auction,
});

export const showNegotiationModal = (accept, auction) => ({
  type: actionTypes.Negotiations.Show,
  accept,
  auction,
});

export const toggleAuctionDetailsImageZoom = (toggle, image = '') => ({
  type: actionTypes.Modal.AuctionDetailsZoomImage.Toggle,
  toggle,
  image,
});

export const toggleBuyNowSuccessModal = toggle => ({
  type: actionTypes.Modal.BuyNowSuccess.Toggle,
  toggle,
});

export const toggleFullScreenCarousel = toggle => ({
  type: actionTypes.Modal.FullScreenCarousel.Toggle,
  toggle,
});

export const showCarouselPlaceHolder = () => ({
  type: actionTypes.UI.ToggleCarouselPlaceHolder,
  show: true,
});

export const hideCarouselPlaceHolder = () => ({
  type: actionTypes.UI.ToggleCarouselPlaceHolder,
  show: false,
});

export const showModal = (key, extras = null) => ({
  type: actionTypes.UI.ShowModal,
  key,
  show: true,
  extras,
});
export const toggleModal = ({ key, show = null, extras = null }) => ({
  type: actionTypes.UI.ToggleModal,
  key,
  show,
  extras,
});
export const sortAuctions = (sortFilterParam = sortFilterParams.SHORTEST_EXPECTED_TIME) => ({
  type: actionTypes.UI.Sorting,
  sortFilterParam,
});

export const closeModal = key => ({
  type: actionTypes.UI.CloseModal,
  key,
  show: false,
});

export const setCategoryFilter = name => ({
  type: actionTypes.UI.SetCategoryFilter,
  name: name,
});

export const clearCategoryFilter = () => ({
  type: actionTypes.UI.ClearCategoryFilter,
});
