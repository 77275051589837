import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 12px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  background-color: transparent;
`;

export const QuickKeyboardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent ?? 'space-between'};

  width: 100%;
`;

export const QuickKeyboardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 8px;

  width: 87px;
  height: 40px;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #fff;

  background-color: #2274a5;

  border: none;
  border-radius: 0.5rem;

  cursor: pointer;
  outline: none;

  transition: background-color 0.2s ease-out;

  &:nth-child(2) {
    margin: 0 1.2rem;
  }

  &:hover {
    background-color: #4b8cb8;
    transition: background-color 0.2s ease-in;
  }
`;

export const ErrorMessage = styled.span`
  width: 100%;
  font: 500 normal 0.9rem/1em 'Roboto', sans-serif;
  color: #ff4c00;
`;
