import React from 'react';
import { rem } from 'polished';

import { Card, Text } from '../../../abstract';

export const OfferRejectedCallout = () => {
  return (
    <Card variant="flat" bg="neutrals.EclipseE60" p={rem(12)}>
      <Text as="p" fontSize={1} mb={0} color="neutrals.EclipseE500">
        Infelizmente o vendedor não aceitou sua oferta, podemos te avisar assim que outro modelo
        deste carro entrar na plataforma.
      </Text>
    </Card>
  );
};
