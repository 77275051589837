import actionTypes from '../../constants/actionTypes';
import initialState from './initialState';

function uiReducer(state = initialState.ui, action) {
  switch (action.type) {
    case actionTypes.UI.SetMyPurchasesKeywordFilter:
      return {
        ...state,
        purchaseFilters: {
          ...state.purchaseFilters,
          keyword: action.keyword,
        },
      };

    case actionTypes.UI.SetMyPurchasesFilters:
      return {
        ...state,
        purchaseFilters: {
          ...state.purchaseFilters,
          ...action.filters,
        },
      };

    case actionTypes.UI.ClearMyPurchasesFilters:
      return {
        ...state,
        purchaseFilters: {
          ...state.purchaseFilters,
          status: [],
          startDate: null,
          endDate: null,
        },
      };

    case actionTypes.UI.Sorting:
      return {
        ...state,
        sortFilterParam: action.sortFilterParam,
      };
    case actionTypes.UI.SetShowLojaSelector:
      return {
        ...state,
        showLojaSelector: action.toggle,
      };
    case actionTypes.Modal.AutoBidConfirmation.Toggle:
      return {
        ...state,
        showConfirmationModal: action.toggle,
        confirmationModalMessage: action.message,
      };
    case actionTypes.UI.MakeOfferLoading:
      return {
        ...state,
        makeOfferLoading: action.loading,
      };
    case actionTypes.UI.IsParticipatingInNewAuctions:
      return {
        ...state,
        isParticipatingInNewAuctions: action.isParticipating,
      };
    case actionTypes.UI.NotificationsCount:
      return {
        ...state,
        notificationsCount: action.notificationsCount,
      };

    case actionTypes.UI.RecommendedCarsNotificationsCount:
      return {
        ...state,
        recommendedCarsNotificationsCount: action.count,
      };

    case actionTypes.UI.SelectedAuctionId:
      return {
        ...state,
        selectedAuctionId: action.auctionId,
      };
    case actionTypes.UI.MakeOfferStockLoading:
      return {
        ...state,
        makeOfferStockLoading: action.loading,
      };
    case actionTypes.Modal.AuctionDetailsZoomImage.Toggle:
      return {
        ...state,
        auctionDetailsImageZoom: {
          image: action.image,
          show: action.toggle,
        },
      };
    case actionTypes.Negotiations.Ended:
      return {
        ...state,
        negotiationsModal: {
          ...state.negotiationsModal,
          negotiations: state.negotiationsModal.negotiations.concat([action]),
        },
      };
    case actionTypes.Negotiations.Show:
      return {
        ...state,
        negotiationsModal: {
          ...state.negotiationsModal,
          show: true,
          accept: action.accept,
          auction: action.auction,
        },
      };

    case actionTypes.Negotiations.Clear:
      return {
        ...state,
        negotiationsModal: {
          show: false,
          negotiations: state.negotiationsModal.negotiations.slice(1),
          accept: null,
          auction: {},
        },
      };
    case actionTypes.Modal.FullScreenCarousel.Toggle:
      return {
        ...state,
        showFullScreenCarousel: action.toggle,
      };
    case actionTypes.UI.ToggleCarouselPlaceHolder:
      return {
        ...state,
        showCarouselPlaceHolder: action.show,
      };
    case actionTypes.UI.ShowModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.key]: {
            show: action.show,
            extras: action.extras,
          },
        },
      };
    case actionTypes.UI.CloseModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.key]: {
            show: action.show,
            extras: null,
          },
        },
      };
    case actionTypes.UI.ToggleModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.key]: {
            show: action.show,
            extras: action.extras,
          },
        },
      };
    case actionTypes.UI.SetFilters: {
      const { price, kilometers, attentionPoints, search, locations } = action;
      // Obtain the number of filters that are being applied.
      const count =
        ['price', 'kilometers', 'attentionPoints'].reduce((prev, curr) => {
          const { filters } = initialState.ui;
          return action[curr] !== filters[curr] ? prev + 1 : prev;
        }, 0) +
        (search.length ? 1 : 0) +
        (locations.length ? 1 : 0);
      return {
        ...state,
        filters: {
          price,
          kilometers,
          attentionPoints,
          search,
          locations,
          metadata: {
            count,
          },
        },
      };
    }
    case actionTypes.UI.ResetFilters: {
      const { filters } = action;
      if (filters.length) {
        const resetFilters = filters.reduce((acc, current) => {
          return {
            ...acc,
            [current]: initialState.ui.filters[current],
          };
        }, {});

        const { filters: stateFilters } = state;

        return {
          ...state,
          filters: {
            ...stateFilters,
            ...resetFilters,
            metadata: {
              count:
                stateFilters.metadata.count > 0
                  ? stateFilters.metadata.count - 1
                  : stateFilters.metadata.count,
            },
          },
        };
      }

      return {
        ...state,
        filters: initialState.ui.filters,
      };
    }
    case actionTypes.UI.SetAuctionTabSelected:
      return {
        ...state,
        auctionTab: { ...state.auctionTab, selected: action.selected },
      };
    case actionTypes.UI.ShowSuccessBidMessage:
      return {
        ...state,
        showSuccessBidMessage: action.show,
      };
    case actionTypes.UI.HandleFilters:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    case actionTypes.UI.SetCategoryFilter:
      return {
        ...state,
        categoryFilter: {
          ...state.categoryFilter,
          [action.name]: !state.categoryFilter[action.name],
        },
      };
    case actionTypes.UI.ClearCategoryFilter:
      const newObject = { ...state.categoryFilter };
      Object.keys(newObject).forEach(key => (newObject[key] = false));

      return {
        ...state,
        categoryFilter: newObject,
      };

    default:
      return state;
  }
}
export default uiReducer;
