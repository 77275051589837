import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Image } from '../../../abstract';

const Photo = styled(Image)`
  border-radius: 50%;
  object-fit: contain;
  width: ${rem(70)};
  height: ${rem(70)};
`;

const RoundPhoto = ({ src, alt, ...etc }) => <Photo src={src} alt={alt} {...etc} />;

RoundPhoto.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

RoundPhoto.defaultProps = {
  src: require('../../../../images/user--placeholder.png'),
  alt: '',
};

export { RoundPhoto };
