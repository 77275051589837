import styled from 'styled-components';

export const Container = styled.div`
  transition: top 0.2s;
  background: #2274a5;
  position: fixed;
  overflow: auto;
  height: 100vh;
  width: 100vw;
  top: 100vh;
  z-index: 9;
  left: 0;

  &.show {
    top: 0;
  }

  @media (min-width: 882px) {
    display: none;
  }
`;

Container.Header = styled.div`
  justify-content: space-between;
  align-items: center;
  background: #2274a5;
  display: flex;
  padding: 20px;
  height: 96px;
  width: 100%;
`;

Container.Header.Title = styled.div`
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  height: 24px;

  img {
    margin-right: 12px;
  }
`;

Container.Header.Close = styled.button`
  background: transparent;
  font-size: 21px;
  height: 25px;
  border: none;
  width: 25px;
  color: #fff;
`;

Container.Body = styled.div`
  border-radius: 8px 8px 0 0;
  min-height: calc(100vh - 96px);
  background: #fff;
  padding: 29px 32px;
`;
