import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${props => props.inputWidth ?? '100'}%;

  padding: 0.75rem 0;

  background-color: #fff;
  border: 0.063rem solid #d2d4d7;
  border-radius: 0.25rem;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      background-color: transparent;
      border: 0.063rem solid #fff;
    `}
`;

export const Label = styled.label`
  position: absolute;
  top: -6px;
  left: 14px;

  font: 400 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  background-color: #fff;

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      padding: 0 0.25rem;

      color: #fff;
      background-color: #1a2331;
    `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 60%;
  margin: 0 1.2rem;

  ${({ autobid }) =>
    autobid &&
    css`
      margin: 0 0.875rem;

      @media screen and (max-width: 376px) {
        margin: 0;
      }
    `}
`;

export const DollarSign = styled.span`
  margin-right: 0.25rem;

  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const Input = styled.input`
  width: 58%;
  padding: 0.3rem 0;

  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  border: none;
  outline: none;

  ${({ autobid }) =>
    autobid &&
    css`
      width: 100%;

      text-align: center;

      &::placeholder {
        font-weight: 400;
      }
    `}

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
      background-color: transparent;
    `}
`;
