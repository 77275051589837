import styled from 'styled-components';

export const Container = styled.div`
  border: 1.5px solid #22aa52;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;

  display: flex;
  flex-direction: column;

  position: relative;

  &.lowChance {
    border-color: #6b0874;
  }

  &.warmChance {
    border-color: #864600;
  }

  &.lose {
    border-color: #9c3a3b;
  }

  @media (max-width: 882px) {
    flex-direction: row;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

Container.Header = styled.div`
  border-radius: 6px 6px 0 0;
  border: 1px solid #d2d4d7;
  box-sizing: border-box;
  background: #c4c4c4;
  width: 100%;

  &.lose {
    opacity: 0.6;
  }

  .carousel-photos {
    display: block;
  }

  @media (max-width: 882px) {
    background-image: url(${({ img }) => img});
    background-position: center;
    border-radius: 6px 0 0 6px;
    width: 98px !important;
    background-size: cover;
    min-width: 98px;
    height: 100%;

    .carousel-photos {
      display: none;
    }
  }
`;

Container.Body = styled.div`
  padding: 12px 14px 34px 14px;
  flex-grow: 1;
  width: 100%;

  &.lose {
    opacity: 0.6;
  }

  @media (max-width: 882px) {
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 15px;
    flex-grow: unset;
    width: auto;
  }
`;

Container.Body.Content = styled.div`
  margin-bottom: 19px;

  .title {
    margin-top: 12.39px;
    font-style: normal;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    color: #2f3741;
  }

  .temperatureDescription {
    margin-top: 12.39px;
    font-style: normal;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    color: #2f3741;
  }

  .temperatureLow {
    margin-top: 12.39px;
    font-style: normal;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    color: #6b0874;
  }
  .temperatureWarm {
    margin-top: 12.39px;
    font-style: normal;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    color: #864600;
  }

  .description {
    font-style: normal;
    font-weight: normal;
    margin-bottom: 12px;
    line-height: 24px;
    font-size: 14px;
    color: #757778;

    .car-name {
      display: none;
    }
  }

  @media (max-width: 882px) {
    margin-bottom: 0;

    .extras,
    .title {
      display: none;
    }

    .description {
      margin-bottom: 8px;
      line-height: 15px;
      font-size: 10px;

      .car-name {
        display: inline;
      }
    }
  }
`;

Container.Body.tempLink = styled.div`
  align-items: center;
  .temperatureDetail {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #22aa52;
  }

  .icon {
    margin-left: 10px;
  }
`;

Container.Body.Btn = styled.button`
  background: #22aa52;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  min-height: 40px;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  outline: none;
  border: none;
  width: 100%;

  .icon {
    display: none;
  }

  @media (max-width: 882px) {
    background-color: transparent;
    min-height: 14px;
    text-align: left;
    font-size: 14px;
    padding-left: 0;
    color: #22aa52;

    .icon {
      vertical-align: middle;
      margin-left: 3px;
      display: inline;
    }
  }
`;

Container.Footer = styled.div`
  border-radius: 0 0 6px 6px;
  padding: 0 14px 16px 14px;
  width: 100%;

  &.lose {
    opacity: 0.6;
  }

  @media (max-width: 882px) {
    display: none;
  }
`;

Container.Footer.Container = styled.div`
  border-top: 1px solid #d2d4d7;
  font-weight: normal;
  font-style: normal;
  padding-top: 15px;
  line-height: 22px;
  font-size: 12px;
  color: #757778;

  .title {
    font-weight: bold;
  }
`;

Container.Status = styled.div`
  border-radius: 4px 0px 0px 4px;
  justify-content: space-evenly;
  align-items: center;
  font-style: normal;
  position: absolute;
  font-weight: bold;
  line-height: 15px;
  font-size: 16px;
  display: flex;
  height: 22px;
  width: 170px;
  z-index: 1;

  left: 13px;
  top: -12px;

  &.losing {
    background: #9c3a3b;
    color: #fff;
  }

  &.lowChance {
    background: #6b0874;
    color: #fff;
    &::after {
      border-left-color: #6b0874 !important;
      border-top-color: #6b0874 !important;
    }
  }

  &.warmChance {
    background: #864600;
    color: #fff;
    &::after {
      border-left-color: #864600 !important;
      border-top-color: #864600 !important;
    }
  }

  &.winning {
    background: #1a843f;
    color: #fff;

    &::after {
      border-left-color: #1a843f !important;
      border-top-color: #1a843f !important;
    }
  }

  &::after {
    content: '';
    height: 0px;
    width: 0;
    position: absolute;
    right: -6px;
    top: 0px;
    border-radius: 0px 4px 4px 0px;
    border-top: 4px solid #9c3a3b;
    border-left: 6px solid #9c3a3b;
    border-bottom: 16px solid transparent;
    border-right: 20px transparent;
  }

  @media (max-width: 882px) {
    font-size: 12px;
    width: 131px;
    left: 114px;
  }
`;
