import styled from 'styled-components';

export const Container = styled.div`
  justify-content: space-between;
  align-items: center;
  //max-width: 266px;
  display: flex;
  height: 24px;
  width: 100%;
`;

Container.Percentage = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: #22aa52;
  display: flex;

  span {
    margin-left: 4.8px;
    font-weight: normal;
    font-style: normal;
    line-height: 16px;
    color: #737171;
  }
`;

Container.StateTag = styled.div`
  align-items: center;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  font-size: 14px;
  cursor: pointer;
  color: #22aa52;
  display: flex;
  display: flex;
  height: 24px;
  width: 42px;

  position: relative;

  span {
    margin-left: 6px;
  }

  .tooltip {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px 0px;
    border-top: 2px solid rgb(61 184 112);
    position: absolute;
    border-radius: 6px;
    line-height: 1.3em;
    background: white;
    font-size: 0.8rem;
    min-width: 15rem;
    padding: 0.5rem;
    color: #212529;
    z-index: 99;
    top: 30px;
    left: 0;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 100%;
      left: 1.5em;
      border: 0.5rem solid transparent;
      border-top: none;
      border-bottom-color: rgb(61 184 112);
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }
  }

  /* VERTICAL STYLE */
  &.vertical-style {
    .tooltip {
      right: 0 !important;
      left: unset;

      &:before {
        left: 16.5em;
      }
    }
  }

  @media (max-width: 882px) {
    .tooltip {
      right: 0 !important;
      left: unset;

      &:before {
        left: 16.5em;
      }
    }
  }
`;
