import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import { themeGet } from 'styled-system';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

import { Flex, Box, Link } from '../../../abstract';

export const DEFAULT_ITEMS_PER_PAGE = 12;
export const ALIGMENT_LEFT = 'flex-start';
export const ALIGMENT_RIGHT = 'flex-end';
export const ALIGMENT_CENTER = 'center';
const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const NEXT_PAGE = 'NEXT';
const PREV_PAGE = 'PREV';

const addArrowNav = pages => {
  return [PREV_PAGE, ...pages, NEXT_PAGE];
};

const PageLink = styled(Link).attrs({
  py: rem(2),
  variant: 'undecorated',
  width: 1,
})`
  display: flex;
  border-radius: 2px;
  border: 1px solid ${themeGet('colors.neutrals.EclipseE70')};
  transition: all 0.2s;
  height: 28px;
  outline: none !important;
  align-items: center;
  justify-content: center;

  ${({ disabled }) =>
    disabled &&
    css`
      &,
      &:hover {
        cursor: not-allowed;
        background: ${themeGet('colors.neutrals.EclipseE70')};
        color: ${themeGet('colors.neutrals.EclipseE300')} !important;
      }
    `}

  &:focus {
    box-shadow: 0 0 2px 1px ${themeGet('colors.primaries.Soul')};
  }
`;

const inactiveStyles = css`
  color: ${themeGet('colors.neutrals.EclipseE500')};

  &:hover {
    color: ${themeGet('colors.primaries.Soul')};
  }
`;

const activeStyles = css`
  color: #ffffff;

  ${PageLink} {
    background: ${themeGet('colors.primaries.Soul')};
    border-color: ${themeGet('colors.primaries.Soul')};
  }
`;

const Pages = styled(Flex).attrs(({ alignment }) => ({
  as: 'ul',
  alignItems: 'center',
  justifyContent: alignment,
}))`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const PageItem = styled(Box).attrs({
  as: 'li',
  width: rem(36),
  mx: rem(4),
})`
  text-align: center;
  ${({ isActive }) => (isActive ? activeStyles : inactiveStyles)};
`;

export class Pagination extends React.Component {
  static propTypes = {
    totalItems: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number,
    pageNeighbours: PropTypes.number,
    alignment: PropTypes.oneOf([ALIGMENT_LEFT, ALIGMENT_RIGHT, ALIGMENT_CENTER]),
  };

  static defaultProps = {
    pageNeighbours: 1,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    alignment: ALIGMENT_CENTER,
  };

  componentDidMount() {
    this.goToPage(this.props.currentPage);
  }

  get pageNeighbours() {
    const { pageNeighbours } = this.props;
    return typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 3)) : 0;
  }

  get totalPages() {
    const { totalItems, itemsPerPage } = this.props;
    return Math.ceil(totalItems / itemsPerPage);
  }

  goToPage = page => {
    const currentPage = Math.max(1, Math.min(page, this.totalPages));
    const { itemsPerPage, totalItems } = this.props;

    document.querySelector('html').scrollTop = 0;

    this.props.onPageChange({
      currentPage,
      totalPages: this.totalPages,
      itemsPerPage,
      totalItems,
    });
  };

  fetchPageNumbers() {
    const { currentPage } = this.props;
    const totalPages = this.totalPages;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage + 1);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      if (hasLeftSpill && !hasRightSpill) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        const extraPages = range(startPage - spillOffset, startPage);
        pages = [LEFT_PAGE, ...extraPages, ...pages];
      } else if (!hasLeftSpill && hasRightSpill) {
        // handle: (1) {2 3} [4] {5 6} > (10)
        const extraPages = range(endPage + 1, endPage + spillOffset + 1);
        pages = [...pages, ...extraPages, RIGHT_PAGE];
      } else {
        // handle: (1) < {4 5} [6] {7 8} > (10)
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
      }

      return addArrowNav([1, ...pages, totalPages]);
    }

    return addArrowNav(range(1, totalPages + 1));
  }

  goNextPage = e => {
    e.preventDefault();
    const { currentPage } = this.props;

    if (currentPage < this.totalPages) {
      this.goToPage(currentPage + 1);
    }
  };

  goPrevPage = e => {
    e.preventDefault();
    const { currentPage } = this.props;

    if (currentPage > 1) {
      this.goToPage(currentPage - 1);
    }
  };

  handleClick = page => e => {
    e.preventDefault();
    this.goToPage(page);
  };

  showPagesLeft = e => {
    e.preventDefault();
    this.goToPage(this.props.currentPage - this.props.pageNeighbours * 2 - 1);
  };

  showPagesRight = e => {
    e.preventDefault();
    this.goToPage(this.props.currentPage + this.props.pageNeighbours * 2 + 1);
  };

  render() {
    const { currentPage, alignment } = this.props;
    const pages = this.fetchPageNumbers();
    const totalPages = this.totalPages;
    const shouldDisplayArrows = totalPages > 1;

    return (
      <Box as="nav" my={3} aria-label="Auctions Pagination">
        <Pages alignment={alignment} unselectable="unselectable">
          {pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <PageItem key={index}>
                  <PageLink as="button" onClick={this.showPagesLeft}>
                    ...
                  </PageLink>
                </PageItem>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <PageItem key={index}>
                  <PageLink as="button" onClick={this.showPagesRight}>
                    ...
                  </PageLink>
                </PageItem>
              );
            }

            if (page === PREV_PAGE) {
              return shouldDisplayArrows ? (
                <PageItem key={index}>
                  <PageLink
                    as="button"
                    onClick={this.goPrevPage}
                    disabled={currentPage === 1}
                    bg="neutrals.EclipseE60"
                  >
                    <MdKeyboardArrowLeft size={24} />
                  </PageLink>
                </PageItem>
              ) : null;
            }

            if (page === NEXT_PAGE) {
              return shouldDisplayArrows ? (
                <PageItem key={index}>
                  <PageLink
                    as="button"
                    onClick={this.goNextPage}
                    disabled={currentPage === totalPages}
                    bg="neutrals.EclipseE60"
                  >
                    <MdKeyboardArrowRight size={24} />
                  </PageLink>
                </PageItem>
              ) : null;
            }

            return (
              <PageItem key={index} isActive={currentPage === page}>
                <PageLink onClick={this.handleClick(page)} tabIndex={0}>
                  {page}
                </PageLink>
              </PageItem>
            );
          })}
        </Pages>
      </Box>
    );
  }
}
