import AppConstants from '../constants/appConstants';
import { UserFactory } from '../factories/user.factory';

export function saveToken(token) {
  localStorage.setItem(AppConstants.localStorage.token, JSON.stringify(token));
}

export function getToken() {
  let token = {};
  try {
    token = JSON.parse(localStorage.getItem(AppConstants.localStorage.token));
  } catch (err) {}
  return token;
}

export function clearToken() {
  localStorage.removeItem('instacarro::session');
  localStorage.removeItem('instacarro:buyerId');
  localStorage.removeItem('pusherTransportTLS');
}

export function getUser(from) {
  const token = from();
  let userData = {};

  if (token !== null) {
    try {
      const base64Url = token.access_token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      userData = JSON.parse(window.atob(base64));
    } catch (err) {}
  }
  return UserFactory.CreateUser(userData);
}

export function getLocalUser() {
  return getUser(() => getToken());
}

export function saveBuyerId(buyerId) {
  localStorage.setItem(AppConstants.localStorage.buyerId, buyerId);
}

export function getBuyerId() {
  return localStorage.getItem(AppConstants.localStorage.buyerId);
}
