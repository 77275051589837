import React from 'react';
import { MaximumQuality as SMaximumQuality, Row, Typography } from './styles';
import ImageSeal from '../../../../images/selo-qualidade-maxima.png';
import IconSeal from '../../../../images/icons/qualidade-maxima.svg';
import { useFlag } from '@unleash/proxy-client-react';
import { getSelectedAuction } from '../../../../store/selectors';
import { useSelector } from 'react-redux';

const maximumQualityItems = [
  'Veículo com menos de 10 anos',
  'Sem apontamentos na cautelar',
  'Em ótimas condições de mecânica',
  'Menos de 100.000 Km rodados',
];

function MaximumQuality() {
  let size = window.screen.width;
  const state = useSelector(state => state);
  const selectedAuction = getSelectedAuction(state);
  const enabled = useFlag('maximum-quality') && selectedAuction?.summary?.maximumQuality;

  const useHeaderLayout = () => {
    if (size > 1024) return null;

    return (
      <Row>
        <SMaximumQuality.Seal src={ImageSeal} />
        <Typography fontSize={24} lineHeight={32}>
          Qualidade Máxima InstaCarro
        </Typography>
      </Row>
    );
  };

  window.addEventListener('resize', () => (size = window.screen.width));

  if (!enabled) return null;

  return (
    <SMaximumQuality.Container>
      {useHeaderLayout()}
      {size > 1024 ? <SMaximumQuality.Seal src={ImageSeal} /> : null}
      <SMaximumQuality.Content>
        {size > 1024 ? <Typography fontSize={28}>Qualidade Máxima InstaCarro</Typography> : null}
        <Typography fontFamily="Nunito" fontWeight={size <= 1024 ? 400 : 700}>
          Os veículos com este selo não possuem apontamento na cautelar e estão em ótimas condições,
          confira mais detalhes:
        </Typography>
        <SMaximumQuality.List>
          {maximumQualityItems.map(paragraph => (
            <SMaximumQuality.Item key={paragraph}>
              <SMaximumQuality.ItemSeal src={IconSeal} />
              <Typography fontFamily="Nunito">{paragraph}</Typography>
            </SMaximumQuality.Item>
          ))}
        </SMaximumQuality.List>
      </SMaximumQuality.Content>
    </SMaximumQuality.Container>
  );
}

export default MaximumQuality;
