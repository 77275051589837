import React, { useCallback, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdZoomOutMap } from 'react-icons/md';

import { S } from './styles';
import { FlagIcon } from '../../../../../components/Icons';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1302 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 1302, min: 982 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 982, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 764, min: 0 },
    items: 1,
  },
};

function sortbyImages(items) {
  const photos = [];
  const texts = [];
  items.map(el => (el.img && el.img.src ? photos.push(el) : texts.push(el)));
  return { photos: photos, texts: texts };
}

function CustomArrowLeft(props) {
  return (
    <svg
      width={37}
      height={37}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: '30%', left: '1%', cursor: 'pointer' }}
      {...props}
    >
      <path
        opacity={0.7}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 0C8.283 0 0 8.283 0 18.5S8.283 37 18.5 37 37 28.717 37 18.5 28.717 0 18.5 0zm3.712 12.962a1.682 1.682 0 00-2.378-2.378l-6.728 6.727a1.681 1.681 0 000 2.378l6.728 6.727a1.682 1.682 0 002.378-2.378L16.674 18.5l5.538-5.538z"
        fill="#22AA52"
      />
    </svg>
  );
}

function CustomArrowRight(props) {
  return (
    <svg
      width={37}
      height={37}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: '30%', right: '1%', cursor: 'pointer' }}
      {...props}
    >
      <path
        opacity={0.7}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 0C28.717 0 37 8.283 37 18.5S28.717 37 18.5 37 0 28.717 0 18.5 8.283 0 18.5 0zm-3.712 12.962a1.682 1.682 0 012.378-2.378l6.728 6.727a1.681 1.681 0 010 2.378l-6.728 6.727a1.682 1.682 0 01-2.378-2.378l5.538-5.538-5.538-5.538z"
        fill="#22AA52"
      />
    </svg>
  );
}

export const Content = ({ title, items, onGallery, isOptional = false }) => {
  const sortedItems = sortbyImages(items);
  const [opened, setOpened] = useState(false);

  if (!items.length) return null;

  const useTitleLayout = () => {
    if (!title) return null;

    return (
      <S.Title onClick={() => setOpened(!opened)}>
        <span className="dropdown-list-title">
          {title.includes('Mecanica') ? 'Mecânica' : title}
        </span>
        {opened ? <MdKeyboardArrowDown size={27} /> : <MdKeyboardArrowUp size={27} />}
      </S.Title>
    );
  };

  return (
    <React.Fragment>
      {useTitleLayout()}
      {opened && !!sortedItems?.photos?.length ? (
        <S.CarouselContainer>
          <Carousel
            containerClass="carousel-container"
            draggable
            responsive={responsive}
            showDots={false}
            customRightArrow={<CustomArrowRight />}
            customLeftArrow={<CustomArrowLeft />}
            swipeable
            centerMode
          >
            {sortedItems?.photos?.map(({ img, description }, index) => (
              <S.Card key={description}>
                <img
                  className="card-image"
                  {...img}
                  alt={description}
                  onClick={() => onGallery(index, sortedItems?.photos)}
                />
                <p className="card-description">{description}</p>
                <div className="resize-icon">
                  <MdZoomOutMap />
                </div>
              </S.Card>
            ))}
          </Carousel>
        </S.CarouselContainer>
      ) : null}

      {opened && !!sortedItems?.texts?.length ? (
        <S.WithoutImage isOptional={isOptional}>
          {sortedItems?.texts?.map(({ description }) => (
            <span className="onlyText" key={description}>
              {description}
            </span>
          ))}
        </S.WithoutImage>
      ) : null}
    </React.Fragment>
  );
};

export const Container = ({ title, hideIcon = false, color, children }) => {
  return (
    <S.Container>
      <S.Title>
        {!hideIcon ? <FlagIcon color={color} /> : null}
        <span className="group-title">{title}</span>
      </S.Title>
      {children}
    </S.Container>
  );
};

export const NewSection = {
  Container,
  Content,
};
