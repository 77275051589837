import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const Container = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;

  display: none;

  width: 340px;
  min-height: 350px;

  background-color: #fff;
  /* border-radius: 10px; */
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.57);

  z-index: 10;

  &.showDialog {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

Container.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  width: 100%;
  padding: 10px;

  color: #ffffff;

  background-color: #FFD88C;

  /* background-color: ${props => {
    switch (props.variant) {
      case 'success':
        return themeGet('colors.secondaries.Uno')(props);
      case 'info':
        return themeGet('colors.primaries.Soul')(props);
      case 'error':
        return themeGet('colors.alerts.Ferrari')(props);
    }
  }}; */
  /* border-radius: 8px; */

  .closeBtn {
    width: 17px;
    height: 17px;
  }
`;

Container.Body = styled.div`
  padding: 57px 22px;
`;

Container.Body.Title = styled.h3`  
  margin-left: 12px;

  font: 700 normal 1.25rem/1em 'Roboto', sans-serif;
  color: #2F3741;
  text-align: center;
  text-transform: uppercase;

  /* color: ${props => {
    switch (props.variant) {
      case 'success':
        return themeGet('colors.secondaries.Uno')(props);
      case 'info':
        return themeGet('colors.primaries.Soul')(props);
      case 'error':
        return themeGet('colors.alerts.Ferrari')(props);
    }
  }}; */
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 45px;
`;
