import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  padding: 2.5rem 1.063rem;

  button {
    border-radius: 0.25rem;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0.5rem;

  font: 700 normal 1.2rem/1em 'Roboto', sans-serif;
  color: #2f3741;
  text-align: center;
`;

export const Message = styled.p`
  margin-bottom: 1.25rem;

  font: 400 normal 1rem/1.313em 'Roboto', sans-serif;
  color: #2f3741;
  text-align: center;
`;

export const KeepOfferButton = styled.button`
  margin-top: 1.188rem;

  font: 400 normal 1rem/1em 'Roboto', sans-serif;
  color: #757778;
  text-decoration: underline;
  text-align: center;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;
`;

export const removeAutomaticStyle = {
  content: {
    position: 'relative',
    maxWidth: `380px`,
    maxHeight: '260px',
    height: '100%',
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const specificStyle = {
  content: {
    position: 'relative',
    maxWidth: `445px`,
    maxHeight: '320px',
    height: '100%',
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
