import React from 'react';
import { UpdateContractInfo } from '../../components/update-contact-info';
import { Layout } from '../../components';
import { userProfileService } from '../../services/user-profile';
import { UserContextProvider } from '../../context/UserProfileContext';

export const UpdateContactInfoPage = () => {
  return (
    <Layout>
      <UserContextProvider service={userProfileService} notifier={console}>
        <UpdateContractInfo />
      </UserContextProvider>
    </Layout>
  );
};
