import PropTypes from 'prop-types';

export const photosPropTypes = {
  photos: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      })
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
