import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { rem } from 'polished';

import { Flex, Box } from '../../abstract';
import { BuyNowButton, ProgressTimer } from '..';

export const AuctionItemTimer = ({
  auction,
  onBuyNowClick,
  showProgressBar = true,
  showBuyNowButton = true,
  participate,
}) => {
  const buyNowEnabled = get(auction, 'summary.buynowPrice.enabled', false);
  const buyNowPrice = Number(get(auction, 'summary.buynowPrice.amount'));

  return (
    <Flex alignItems="flex-end" flexWrap="wrap" style={{ marginBottom: '20px' }}>
      <Box
        pr={buyNowEnabled && showBuyNowButton ? { sm: rem(4), md: rem(6) } : null}
        width={buyNowEnabled && showBuyNowButton ? { sm: 1 / 2, md: 1 / 2, xlg: 7 / 12 } : 1}
        mb={{ sm: 0, md: 0 }}
      >
        <ProgressTimer participate={participate} showProgressBar={showProgressBar} />
      </Box>

      {buyNowEnabled && showBuyNowButton && (
        <Box width={{ sm: 1 / 2, xlg: 5 / 12 }} pl={{ sm: rem(4), md: rem(6) }}>
          <BuyNowButton price={buyNowPrice} onClick={onBuyNowClick} />
        </Box>
      )}
    </Flex>
  );
};

AuctionItemTimer.propTypes = {
  auction: PropTypes.object,
  onBuyNowClick: PropTypes.func,
  showProgressBar: PropTypes.bool,
  showBuyNowButton: PropTypes.bool,
};

AuctionItemTimer.defaultProps = {
  onBuyNowClick: () => {},
  showProgressBar: true,
  showBuyNowButton: true,
};
