import styled from 'styled-components';

import { BarWrapper } from '../../components/topbar/bar-wrapper';

export const TopBar = styled(BarWrapper)`
  justify-content: center;
  color: #ffffff;
  display: flex;
  align-items: center;
`;
