import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  TimingAuctionContextProvider,
  useTimingAuctionContext,
} from '../../../../context/TimingAuctionContext';

import { getSelectedAuction } from '../../../../store/selectors';
import { getSelectedAuctionRemainingTime } from '../../../../store/selectors/auctionSelectors';
import { toggleModal } from '../../../../store/actions/ui.actions';

import { useSegment } from '../../../../hooks/useSegment';
import { useAutoBid } from '../../../../hooks/useAutoBid';

import {
  AUCTION_AUTOMATIC_OFFER_KEY,
  AUCTION_BUY_KEY,
  AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY,
  CONFIRM_BUY_FOR,
  REMOVE_AUTOMATIC_OFFER_KEY,
  TIEBREAKER_RULES_KEY,
} from '../../../../constants/modalConstants';

import Modal from '../../../enhanced/Modal';
import {
  Content,
  AutomaticOfferInfo,
  AutomaticOfferLabel,
  VehicleNameAndInfos,
  automaticOfferStyle,
  LastOfferLabel,
  LastOfferAndIsWinning,
  LastOfferValue,
  IsWinningLabel,
  AutomaticBidBudgetEditingLabel,
  BidBudgetContainer,
  RemoveLimitButton,
  Button,
  BidActionsContainer,
  MoreOptionsContainer,
  AutomaticBidBudgetEditingContainer,
  AlertIcon,
  AutoBidFeedBackContainer,
  AutoBidFeedbackLabel,
  RulesLabel,
  Divider,
  EditingLabelStyled,
  ErrorMessage,
} from './styles';
import { AuctionItemTimer } from '../../../enhanced';
import { formatPrice } from '../../../../utils/formatPrice';
import { Temperature } from '../../../Temperature';
import { AiFillCloseCircle } from 'react-icons/ai';
import { RiArrowRightSLine, RiArrowUpSLine } from 'react-icons/ri';
import {
  createAutoBid,
  editionAutoBidVehicle,
  removeAutoBidVehicle,
} from '../../../../store/autobid/effects';
import BidInput from '../../../Inputs/BidInput';
import { toAmount, toPrice } from '../../../../utils/number';
import AlertImage from '../../../../images/icons/automaticOfferAlertIcon.svg';
import withMakeOffer from '../../../hoc/WithMakeOffer';
import { GoAlert } from 'react-icons/go';
import SingleOfferAction from '../../../BidActions/SingleOfferAction';
import AlertIconImg from '../../../../images/icons/automaticOfferAlertIconParticipate.svg';

import LoadIcon from '../../../../images/icons/loading-tail.svg';
import { Image } from '../../../abstract';
import { setSelectedAuction } from '../../../../store/actions/auction.actions';
import { autoBidFailure } from '../../../../store/autobid/actions';
import { AUTO_BID_ERRORS } from '../../../../utils/autobid-enums';

function AutoBidOffer({
  amountValue,
  handleAmountIncrement,
  handleAmountChange,
  submitOffer,
  handleBlur,
  handleQuickAmount,
}) {
  const dispatch = useDispatch();

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [budget, setBudget] = useState();
  const [loading, setLoading] = useState(false);
  const [showBidInput, setShowBidInput] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  const state = useSelector(state => state);
  const { show, extras } = useSelector(state => state.ui.modals.auctionAutomaticOffer);
  const { user } = useSelector(state => state.authentication);
  const { loading: isRequestLoading, error } = useSelector(state => state.autoBid);

  const selectedAuction = getSelectedAuction(state);
  const selectedAuctionRemainingTime = getSelectedAuctionRemainingTime(state);
  const { section, isRecommended = false } = extras || {};

  const { milisecondsLeft } = useTimingAuctionContext();
  const {
    trackConfirmBid,
    trackTieAutoBid,
    trackChangeMaximumAutoBid,
    trackReachedLimitAutoBid,
  } = useSegment(isRecommended);
  const {
    currentAutoBidConfigured,
    maximumAutoBidBudget,
    currentUpdateLimit,
    isActiveAutoBid,
    tieWithEdits,
    tieWithoutEdits,
    losingWithEdits,
    losingWithoutEdits,
    noEdits,
    noEditsAndDesconfigured,
  } = useAutoBid(selectedAuction?._id);

  const disabled = !!budget ? budget <= selectedAuction?.auction?.winningBid?.amount : true;

  const icon = showMoreOptions ? (
    <RiArrowUpSLine size={18} fill="#2f3741" />
  ) : (
    <RiArrowRightSLine size={18} fill="#2f3741" />
  );

  const isWinning = useMemo(() => selectedAuction?.auction?.winning, [
    selectedAuction?.auction?.winning,
  ]);

  const auction = useMemo(() => {
    return {
      ...selectedAuction?.auction,
      remainingTime: selectedAuctionRemainingTime,
    };
  }, [selectedAuction, selectedAuctionRemainingTime]);

  const handleCloseModal = () =>
    dispatch(toggleModal({ key: AUCTION_AUTOMATIC_OFFER_KEY, show: false }));

  const toggleMoreOptions = () => setShowMoreOptions(!showMoreOptions);

  const handleRemoveAutoBid = () =>
    dispatch(removeAutoBidVehicle(currentAutoBidConfigured?.autoBidId, user.id));

  const onChange = ({ target: { value } }) => setBudget(toAmount(value));

  const handleTiebreakerRulesModal = () => {
    dispatch(toggleModal({ key: AUCTION_AUTOMATIC_OFFER_KEY, show: false }));
    dispatch(
      toggleModal({
        key: TIEBREAKER_RULES_KEY,
        show: true,
      })
    );
  };

  const handleMinusButton = useCallback(() => {
    const disabled = toAmount(budget) <= selectedAuction?.auction?.winningBid?.amount;
    setBudget(oldState => (disabled ? oldState : toAmount(oldState) - 250));
  }, [budget, selectedAuction?.auction?.winningBid?.amount]);

  const handlePlusButton = useCallback(() => {
    setBudget(oldState =>
      oldState ? toAmount(oldState) + 250 : selectedAuction?.auction?.winningBid?.amount + 250
    );
  }, [selectedAuction?.auction?.winningBid?.amount]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const existAutoBid = currentAutoBidConfigured;
    const isExclusivity = selectedAuction?.summary?.buynowPrice?.enabled;
    const biggerThanBuyNow = budget >= selectedAuction?.summary?.buynowPrice?.amount;
    const isBuyFor = selectedAuction?.summary?.buyForPrice?.enabled;
    const biggerThanBuyFor = budget >= selectedAuction?.summary?.buyForPrice?.amount;

    if (existAutoBid) {
      const { update_limit, tie } = await dispatch(
        editionAutoBidVehicle(currentAutoBidConfigured?.autoBidId, budget, user.id)
      );

      trackChangeMaximumAutoBid(
        selectedAuction._id,
        currentAutoBidConfigured.autoBidId,
        budget,
        currentAutoBidConfigured?.balance,
        section
      );

      if (update_limit === 2) {
        trackReachedLimitAutoBid(
          selectedAuction._id,
          currentAutoBidConfigured.autoBidId,
          selectedAuction.auction.idAuction,
          section
        );
      }

      if (tie) {
        trackTieAutoBid(selectedAuction._id, currentAutoBidConfigured.autoBidId, budget, section);
      }
      setLoading(false);
      setShowBidInput(false);
    } else {
      if (!isBuyFor && isExclusivity && biggerThanBuyNow) {
        dispatch(toggleModal({ key: AUCTION_AUTOMATIC_OFFER_KEY, show: false }));
        dispatch(
          toggleModal({
            key: AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY,
            show: true,
            extras: {
              amountValue: budget,
              auctionId: selectedAuction._id,
              auctionAuId: selectedAuction.auction.idAuction,
              section,
            },
          })
        );
        return;
      }

      if (isBuyFor && biggerThanBuyFor) {
        dispatch(toggleModal({ key: AUCTION_AUTOMATIC_OFFER_KEY, show: false }));
        dispatch(
          toggleModal({
            key: CONFIRM_BUY_FOR,
            show: true,
            extras: { bid: budget, type: 'automaticOffer', section },
          })
        );
        return;
      }

      const { id, tie } = await dispatch(
        createAutoBid(selectedAuction?._id, user?.id, budget, 250)
      );

      trackConfirmBid({
        inspection_id: selectedAuction._id,
        auction_id: selectedAuction.auction.idAuction,
        bidAmount: budget,
        type: 'autoBid',
        section,
      });

      if (tie) {
        trackTieAutoBid(selectedAuction?._id, id, budget, section);
      }
      setLoading(false);
      setShowBidInput(false);
    }
  }, [currentAutoBidConfigured, budget, user.id, selectedAuction.id, section]);

  const handleRemoveAutomaticOfferModal = useCallback(() => {
    dispatch(toggleModal({ key: AUCTION_AUTOMATIC_OFFER_KEY, show: false }));
    dispatch(
      toggleModal({
        key: REMOVE_AUTOMATIC_OFFER_KEY,
        show: true,
        extras: {
          selectedAuctionId: selectedAuction._id,
          selectedAutoBidId: currentAutoBidConfigured?.autoBidId,
          selectedAuctionAuId: selectedAuction.auction.idAuction,
          section,
          otherStyle: true,
        },
      })
    );
  }, [currentAutoBidConfigured, selectedAuction]);

  const handleNegotiateExclusivityModal = useCallback(() => {
    dispatch(setSelectedAuction(selectedAuction, { remainingTime: milisecondsLeft }));
    dispatch(toggleModal({ key: AUCTION_AUTOMATIC_OFFER_KEY, show: false }));
    dispatch(toggleModal({ key: AUCTION_BUY_KEY, show: true, extras: { section } }));
  }, [selectedAuction, milisecondsLeft]);

  const useLosingWithEdits = useCallback(() => {
    const hasAutoBid = currentAutoBidConfigured && isActiveAutoBid;

    if (!hasAutoBid || isWinning) return null;

    if (tieWithEdits) {
      return (
        <React.Fragment>
          <Divider />
          <AutoBidFeedBackContainer>
            <GoAlert
              size={40}
              fill="#C50000"
              style={{
                alignSelf: 'flex-start',
                marginRight: '.313rem',
                height: '18px',
              }}
            />
            <AutoBidFeedbackLabel>
              <strong>
                Outro lojista definiu primeiro o limite de {maximumAutoBidBudget}. Insira um limite
                maior para continuar na disputa.
              </strong>{' '}
              <RulesLabel onClick={handleTiebreakerRulesModal}>
                Consulte a regra de desempate.
              </RulesLabel>
            </AutoBidFeedbackLabel>
          </AutoBidFeedBackContainer>

          <EditingLabelStyled>{currentUpdateLimit}</EditingLabelStyled>
          <BidInput
            label="Seu lance máximo"
            placeholder="Inserir valor"
            value={toPrice(budget)}
            onChange={onChange}
            handleMinusButton={handleMinusButton}
            handlePlusButton={handlePlusButton}
            autobid
          />
          {budget > 0 ? (
            <Temperature.WithSlider.Default auction={selectedAuction} currentValue={budget} />
          ) : null}
          <BidActionsContainer style={{ marginTop: 0 }}>
            <Button onClick={onSubmit} disabled={disabled}>
              Alterar lance máximo{' '}
              {isRequestLoading && <Image ml={1} src={LoadIcon} width="20px" />}
            </Button>
            <RemoveLimitButton onClick={handleRemoveAutoBid}>
              <AiFillCloseCircle size={18} fill="#125d8a" />
              Remover limite
            </RemoveLimitButton>
            <MoreOptionsContainer visible={showMoreOptions} onClick={toggleMoreOptions}>
              Mais opções de oferta {icon}
            </MoreOptionsContainer>
          </BidActionsContainer>
        </React.Fragment>
      );
    }

    if (losingWithEdits) {
      return (
        <React.Fragment>
          <Divider />
          <AutoBidFeedBackContainer>
            <GoAlert
              size={40}
              fill="#C50000"
              style={{
                alignSelf: 'flex-start',
                marginRight: '.313rem',
                height: '18px',
              }}
            />
            <AutoBidFeedbackLabel>
              <strong>
                O lance de {maximumAutoBidBudget} foi superado por outro lojista. Insira um valor
                maior de limite para voltar a disputar o leilão.
              </strong>
            </AutoBidFeedbackLabel>
          </AutoBidFeedBackContainer>

          <EditingLabelStyled>{currentUpdateLimit}</EditingLabelStyled>
          <BidInput
            label="Seu lance máximo"
            placeholder="Inserir valor"
            value={toPrice(budget)}
            onChange={onChange}
            handleMinusButton={handleMinusButton}
            handlePlusButton={handlePlusButton}
            autobid
          />
          {budget > 0 ? (
            <Temperature.WithSlider.Default auction={selectedAuction} currentValue={budget} />
          ) : null}
          <BidActionsContainer style={{ marginTop: 0 }}>
            <Button onClick={onSubmit} disabled={disabled}>
              Alterar lance máximo{' '}
              {isRequestLoading && <Image ml={1} src={LoadIcon} width="20px" />}
            </Button>
            <RemoveLimitButton onClick={handleRemoveAutoBid}>
              <AiFillCloseCircle size={18} fill="#125d8a" />
              Remover limite
            </RemoveLimitButton>
            <MoreOptionsContainer visible={showMoreOptions} onClick={toggleMoreOptions}>
              Mais opções de oferta {icon}
            </MoreOptionsContainer>
          </BidActionsContainer>
        </React.Fragment>
      );
    }
  }, [
    isActiveAutoBid,
    isWinning,
    tieWithEdits,
    maximumAutoBidBudget,
    budget,
    selectedAuction,
    isRequestLoading,
    showMoreOptions,
    losingWithEdits,
    currentUpdateLimit,
    currentAutoBidConfigured,
  ]);

  const useLosingWithoutEdits = useCallback(() => {
    const hasAutoBid = currentAutoBidConfigured && isActiveAutoBid;

    if (!hasAutoBid || isWinning) return null;

    if (tieWithoutEdits) {
      return (
        <React.Fragment>
          <Divider />
          <AutoBidFeedBackContainer>
            <GoAlert
              size={40}
              fill="#C50000"
              style={{
                alignSelf: 'flex-start',
                marginRight: '.313rem',
                height: '18px',
              }}
            />
            <AutoBidFeedbackLabel>
              <strong>Outro lojista definiu primeiro o limite de {maximumAutoBidBudget}.</strong>{' '}
              Edições encerradas, continue na disputa através da oferta avulsa ou negocie com
              exclusividade.
            </AutoBidFeedbackLabel>
          </AutoBidFeedBackContainer>

          <SingleOfferAction
            auction={selectedAuction}
            inputValue={amountValue}
            handleAmountIncrementOrDecrement={handleAmountIncrement}
            onChange={handleAmountChange}
            onSubmit={submitOffer}
            onBlur={handleBlur}
            handleQuickKeyboard={handleQuickAmount}
            automaticOffer
            showSlider={showSlider}
          />
        </React.Fragment>
      );
    }

    if (losingWithoutEdits) {
      return (
        <React.Fragment>
          <Divider />
          <AutoBidFeedBackContainer>
            <GoAlert
              size={40}
              fill="#C50000"
              style={{
                alignSelf: 'flex-start',
                marginRight: '.313rem',
                height: '18px',
              }}
            />
            <AutoBidFeedbackLabel>
              <strong>O lance de {maximumAutoBidBudget} foi superado por outro lojista.</strong>{' '}
              Edições encerradas, continue na disputa através da oferta avulsa ou negocie com
              exclusividade.
            </AutoBidFeedbackLabel>
          </AutoBidFeedBackContainer>

          <SingleOfferAction
            auction={selectedAuction}
            inputValue={amountValue}
            handleAmountIncrementOrDecrement={handleAmountIncrement}
            onChange={handleAmountChange}
            onSubmit={submitOffer}
            onBlur={handleBlur}
            handleQuickKeyboard={handleQuickAmount}
            automaticOffer
            showSlider={showSlider}
          />
        </React.Fragment>
      );
    }
  }, [
    isActiveAutoBid,
    isWinning,
    tieWithEdits,
    tieWithoutEdits,
    losingWithEdits,
    losingWithoutEdits,
    maximumAutoBidBudget,
    selectedAuction,
    amountValue,
    currentAutoBidConfigured,
    showSlider,
  ]);

  const useWinningLabelLayout = useCallback(() => {
    if (!currentAutoBidConfigured) return null;

    return (
      <IsWinningLabel isWinning={isWinning}>{isWinning ? 'Ganhando' : 'Perdendo'}</IsWinningLabel>
    );
  }, [currentAutoBidConfigured, isWinning]);

  const useWinningLayout = useCallback(() => {
    const hasAutoBid = currentAutoBidConfigured && isActiveAutoBid;

    if (!hasAutoBid || showBidInput || !isWinning) return null;

    return (
      <React.Fragment>
        {noEdits && (
          <React.Fragment>
            <Divider />

            <div style={{ display: 'flex', gap: '0 8px', marginBottom: '18px' }}>
              <img
                src={AlertIconImg}
                alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                style={{ width: '16px', height: '16px' }}
              />
              <span style={{ font: '400 normal 14px/1.5em Roboto, sans-serif', color: '#2f3741' }}>
                Você não pode mais alterar o <strong>lance máximo</strong> porque atingiu o número
                máximo de edições.
              </span>
            </div>
          </React.Fragment>
        )}

        <LastOfferLabel>Seu lance máximo:</LastOfferLabel>
        <BidBudgetContainer>
          <LastOfferValue>{maximumAutoBidBudget}</LastOfferValue>
          <AutomaticBidBudgetEditingLabel>{currentUpdateLimit}</AutomaticBidBudgetEditingLabel>
        </BidBudgetContainer>

        <Temperature.Basic auction={selectedAuction} currentValue={budget} />

        <BidActionsContainer>
          {!noEdits && <Button onClick={() => setShowBidInput(true)}>Alterar lance máximo</Button>}
          <RemoveLimitButton onClick={handleRemoveAutoBid}>
            <AiFillCloseCircle size={18} fill="#125d8a" />
            Remover limite
          </RemoveLimitButton>

          <MoreOptionsContainer onClick={toggleMoreOptions}>
            Mais opções de oferta {icon}
          </MoreOptionsContainer>

          {showMoreOptions ? (
            <React.Fragment>
              <Button onClick={handleRemoveAutomaticOfferModal}>Oferta Avulsa</Button>
              {selectedAuction?.summary?.buynowPrice?.enabled && (
                <Button bg="#ff4c00" onClick={handleNegotiateExclusivityModal}>
                  Negocie a partir de{' '}
                  <strong>R$ {formatPrice(selectedAuction?.summary?.buynowPrice?.amount)}</strong>
                </Button>
              )}
            </React.Fragment>
          ) : null}
        </BidActionsContainer>
      </React.Fragment>
    );
  }, [
    isActiveAutoBid,
    isWinning,
    maximumAutoBidBudget,
    currentUpdateLimit,
    selectedAuction,
    showMoreOptions,
    budget,
    icon,
    showBidInput,
    currentAutoBidConfigured,
    noEdits,
    selectedAuction?.summary?.buynowPrice?.enabled,
  ]);

  const useNotHasAutoBidLayout = useCallback(() => {
    const isAutoBidInactive = currentAutoBidConfigured && !isActiveAutoBid;
    const loses = tieWithEdits || tieWithoutEdits || losingWithEdits || losingWithoutEdits;
    const losing = !isWinning && !loses;

    if (!currentAutoBidConfigured || isAutoBidInactive || showBidInput || losing) {
      return (
        <React.Fragment>
          <BidInput
            label="Seu lance máximo"
            placeholder="Inserir valor"
            value={toPrice(budget)}
            onChange={onChange}
            handleMinusButton={handleMinusButton}
            handlePlusButton={handlePlusButton}
            autobid
            style={{ marginBottom: '12px' }}
          />

          {!!error && <ErrorMessage>{AUTO_BID_ERRORS[error]}</ErrorMessage>}

          {budget > 0 ? (
            <Temperature.WithSlider.Default auction={selectedAuction} currentValue={budget} />
          ) : null}

          <Button onClick={onSubmit} disabled={disabled}>
            {!isActiveAutoBid ? 'Fazer oferta automática' : 'Alterar lance máximo'}{' '}
            {isRequestLoading && <Image ml={1} src={LoadIcon} width="20px" />}
          </Button>

          <AutomaticBidBudgetEditingContainer>
            <AlertIcon
              src={AlertImage}
              alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
            />
            <AutomaticBidBudgetEditingLabel>
              É possível editar 02 vezes o lance máximo
            </AutomaticBidBudgetEditingLabel>
          </AutomaticBidBudgetEditingContainer>
        </React.Fragment>
      );
    }
    return null;
  }, [
    isActiveAutoBid,
    currentAutoBidConfigured,
    budget,
    isRequestLoading,
    disabled,
    selectedAuction,
    showBidInput,
    isWinning,
    tieWithEdits,
    tieWithoutEdits,
    losingWithEdits,
    losingWithoutEdits,
    error,
  ]);

  useEffect(() => {
    if (!show) {
      setBudget();
      dispatch(autoBidFailure(false));
    }
  }, [show]);

  useEffect(() => {
    const currentWinner = selectedAuction?.auction?.winningBid?.amount < toAmount(amountValue);

    if (selectedAuction?.auction?.participating && currentWinner) setShowSlider(true);
  }, [selectedAuction, amountValue]);

  return (
    <TimingAuctionContextProvider auction={auction}>
      <Modal show={show} toggle={handleCloseModal} style={automaticOfferStyle}>
        <Content>
          <VehicleNameAndInfos>
            {selectedAuction?.summary?.make} {selectedAuction?.summary?.model}{' '}
            {selectedAuction?.summary?.yearLabel} - {selectedAuction?.summary?.version}{' '}
            {selectedAuction?.summary?.kmLabel}
          </VehicleNameAndInfos>

          <AuctionItemTimer showBuyNowButton={false} auction={selectedAuction} />

          {!noEditsAndDesconfigured ? (
            <React.Fragment>
              <AutomaticOfferLabel>Oferta automática</AutomaticOfferLabel>
              <AutomaticOfferInfo>
                Insira o seu valor máximo de orçamento e a plataforma dará lances automáticos até
                chegar no seu limite de valor estabelecido.
              </AutomaticOfferInfo>
            </React.Fragment>
          ) : null}

          <LastOfferLabel>Lance atual</LastOfferLabel>
          <LastOfferAndIsWinning>
            <LastOfferValue>
              R$ {formatPrice(selectedAuction?.auction?.winningBid?.amount)}
            </LastOfferValue>
            {useWinningLabelLayout()}
          </LastOfferAndIsWinning>
          {useWinningLayout()}
          {useLosingWithEdits()}
          {useLosingWithoutEdits()}

          {noEditsAndDesconfigured ? (
            <React.Fragment>
              <Divider />

              <Temperature.Basic auction={selectedAuction} currentValue={budget} />

              <div style={{ display: 'flex', gap: '0 8px', margin: '8px 0 16px' }}>
                <img
                  src={AlertIconImg}
                  alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                  style={{ width: '16px', height: '16px' }}
                />
                <span
                  style={{ font: '400 normal 14px/1.5em Roboto, sans-serif', color: '#2f3741' }}
                >
                  Você não pode mais fazer a <strong>oferta automática</strong> porque atingiu o
                  valor limite de lance máximo.
                </span>
              </div>

              <SingleOfferAction
                auction={selectedAuction}
                inputValue={amountValue}
                handleAmountIncrementOrDecrement={handleAmountIncrement}
                onChange={handleAmountChange}
                onSubmit={submitOffer}
                onBlur={handleBlur}
                handleQuickKeyboard={handleQuickAmount}
                automaticOffer
                showSlider={showSlider}
              />
            </React.Fragment>
          ) : (
            useNotHasAutoBidLayout()
          )}
        </Content>
      </Modal>
    </TimingAuctionContextProvider>
  );
}

export const AutoBidOfferModal = withMakeOffer(AutoBidOffer);
