import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { profileUpdationSchema } from '../../context/UserProfileContext/validation/schema-validator';
import { Alert, Form } from './styles/contact-form-styles';
import { PhoneNumber } from '../masks/phone-number-mask';
import { INPUT_STATUS, TextInput } from './TextInput';
import { Button } from '../enhanced/Button/Button.js';
import { useMemo } from 'react';
import { ContactFormHeader } from './FormHeader';

const DEFAULT_ERROR_MESSAGE = 'Não foi possivel atualizar os dados.';

export const ContactForm = ({ initialValues, onSubmit, status }) => {
  const {
    values: formValues,
    errors: formErros,
    handleChange: onFieldChange,
    resetForm,
    touched,
    setValues,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      companyPhone: initialValues.companyPhone,
      phoneNumber: initialValues.phoneNumber,
    },
    onSubmit,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: profileUpdationSchema,
  });

  useEffect(() => {
    resetForm();
    setValues({
      companyPhone: initialValues.companyPhone,
      phoneNumber: initialValues.phoneNumber,
    });
  }, [initialValues, resetForm]);

  const companyPhoneStatus = useMemo(() => {
    if (!touched.companyPhone) return INPUT_STATUS.DEFAULT_INPUT_STATUS;
    if (formErros.companyPhone) return INPUT_STATUS.ERROR_INPUT_STATUS;
    if (!formErros.companyPhone) return INPUT_STATUS.SUCCESS_INPUT_STATUS;

    return INPUT_STATUS.DEFAULT_INPUT_STATUS;
  }, [touched.companyPhone, formErros.companyPhone]);

  const phoneNumberStatus = useMemo(() => {
    if (!touched.phoneNumber) return INPUT_STATUS.DEFAULT_INPUT_STATUS;
    if (formErros.phoneNumber) return INPUT_STATUS.ERROR_INPUT_STATUS;
    if (!formErros.phoneNumber) return INPUT_STATUS.SUCCESS_INPUT_STATUS;

    return INPUT_STATUS.DEFAULT_INPUT_STATUS;
  }, [touched.phoneNumber, formErros.phoneNumber]);

  const error =
    (status.error && DEFAULT_ERROR_MESSAGE) || formErros.companyPhone || formErros.phoneNumber;

  return (
    <Form onSubmit={handleSubmit}>
      <ContactFormHeader />
      <label>Nome da Loja</label>
      <TextInput
        readonly
        disabled
        id="dealerShip"
        name=""
        value={initialValues.dealershipName}
        fullWidth
        margin="dense"
        size="small"
        onBlur={handleBlur}
      />
      <label>CNPJ</label>
      <TextInput
        readonly
        disabled
        id="cnpj"
        name="cnpj"
        value={initialValues.cnpj}
        fullWidth
        margin="dense"
        size="small"
        onBlur={handleBlur}
      />
      <label>E-mail</label>
      <TextInput
        readonly
        disabled
        id="email"
        name="email"
        value={initialValues.email}
        fullWidth
        margin="dense"
        size="small"
        onBlur={handleBlur}
      />
      <label>Telefone Comercial</label>
      <TextInput
        id="companyPhone"
        name="companyPhone"
        value={formValues.companyPhone}
        onChange={onFieldChange}
        type="tel"
        InputProps={{ inputComponent: PhoneNumber }}
        inputStatus={companyPhoneStatus}
        helperText={formErros.companyPhone}
        fullWidth
        margin="dense"
        size="small"
        onBlur={handleBlur}
      />
      <label>Celular</label>
      <TextInput
        id="phoneNumber"
        name="phoneNumber"
        value={formValues.phoneNumber}
        onChange={onFieldChange}
        type="tel"
        InputProps={{ inputComponent: PhoneNumber }}
        inputStatus={phoneNumberStatus}
        helperText={formErros.phoneNumber}
        fullWidth
        margin="dense"
        size="small"
        onBlur={handleBlur}
      />
      {status.success && <Alert severity="success">Dados atualizados!</Alert>}
      {!!error && <Alert severity="error">{error}</Alert>}
      <Button type="submit">Salvar alterações</Button>
    </Form>
  );
};
