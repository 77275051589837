import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { rem } from 'polished';
import get from 'lodash/get';
import { compose } from 'redux';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Card, Text, Box, Flex } from '../../abstract';
import { Button, Price, AuctionItemTimer } from '..';
import { TestLabel } from '../../../components';
import { Title as AuctionTitle, AuctionCard } from './shared';
import { AttentionPointsLink } from './AttentionPointsLink';
import { toPrice, fractionToPercentage } from '../../../utils/number';
import { EXP_BUYERS_PARTICIPATING } from '../../../constants/experiments';
import AuctionBidders from '../../auction-bidders/AuctionBidders';
import { gtmEvents } from '../../../utils/gtmTracker';
import MiniDocumentResearch from '../../DocumentResearch/MiniDocumentResarch';
import { NegotiationCallout, OfferAcceptedCallout, OfferRejectedCallout } from './callouts';
import { auctionStatus } from '../../../constants/appConstants';
import { trackAuctionEvent } from '../../../store/actions/event-tracking.actions';
import { toggleModal } from '../../../store/actions/ui.actions';
import { setSelectedAuction } from '../../../store/actions/auction.actions';
import { AUCTION_BUY_KEY, AUCTION_OFFER_KEY } from '../../../constants/modalConstants';
import { CarouselPhoto, State } from '../../index';

const IPVA = styled(Box)`
  font-weight: 700;
  background-color: #000000;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.6rem;
  position: absolute;
  top: 25px;
  left: -25px;
  z-index: 1;
  padding: 4px 20px;
  transform: rotate(-45deg);
  pointer-events: none;
`;

class CarItem extends PureComponent {
  static propTypes = {
    auction: PropTypes.object.isRequired,
    toggle: PropTypes.func,
    selectAuction: PropTypes.func, // TODO: Add auction schema types.
    trackAuctionEventAction: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  getContent() {
    const { auction } = this.props;
    const { summary } = auction;

    const status = get(auction, 'auction.status');

    const photos = Array.isArray(summary.photos)
      ? summary.photos.reduce((acc, current) => [...acc, { src: current }], [])
      : [];

    return {
      auctionId: auction._id,
      title: `${summary.make} ${summary.model} ${summary.version}`,
      year: [summary.productionYear, summary.year].filter(n => Boolean(n)).join(' / '),
      mileage: summary.km ? `${summary.km} km` : 'N/A km',
      winningBid: toPrice(get(auction, 'auction.winningBid.amount', 0), false),
      approvalPct: this.getApprovalPct(),
      photos,
      documentsResearchItems: get(summary, 'documentResearch.historics', []),
      state: summary.state || {},
      status: {
        onGoing: status === auctionStatus.auctionOngoing,
        inNegotiation: status === auctionStatus.onOffer || status === auctionStatus.offerOngoing,
        offerAccepted: status === auctionStatus.offerAccepted,
        offerDeclined: status === auctionStatus.offerDeclined,
      },
    };
  }

  getApprovalPct() {
    const { auction } = this.props;

    const numerator = Number(get(auction, 'summary.approvedAnswersCount'));
    const denominator = Number(get(auction, 'summary.answersCount'));

    return fractionToPercentage(numerator, denominator);
  }

  trackEvent(event) {
    const { auction, trackAuctionEventAction } = this.props;
    // TODO: Normalize auction object shape in order to send the
    // auction instead of the id. The auction in details page is different
    // from the one here.
    trackAuctionEventAction(event, auction._id);
  }

  trackViewDetailsClick = () => {
    this.trackEvent(gtmEvents.CTA_VIEW_AUCTION_DETAILS);
  };

  handleMakeOfferClick = () => {
    const { toggle, selectAuction, auction } = this.props;
    selectAuction(auction);
    toggle({ key: AUCTION_OFFER_KEY, show: true });
    this.trackEvent(gtmEvents.CTA_MAKE_OFFER_LISTING);
  };

  handleBuyNowClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const { toggle, selectAuction, auction } = this.props;
    selectAuction(auction);
    toggle({ key: AUCTION_BUY_KEY, show: true });
  };

  gotoAttentionPoints = () => {
    const { auction, history } = this.props;
    history.push(`/detalhes/${auction._id}#attention-points`);
  };

  render() {
    const {
      auctionId,
      title,
      year,
      mileage,
      winningBid,
      approvalPct,
      photos,
      documentsResearchItems,
      status,
      state,
    } = this.getContent();

    return (
      <AuctionCard>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          <TestLabel show={this.props.auction.summary.test} />
          <CarouselPhoto photos={photos} arrows dots counter href={`/detalhes/${auctionId}`}>
            <IPVA>IPVA {new Date().getFullYear()} PAGO</IPVA>
          </CarouselPhoto>

          <Flex
            flex="1"
            flexDirection="column"
            justifyContent="space-between"
            px={{ sm: rem(12), xlg: 3 }}
            py={rem(20)}
          >
            <AuctionTitle title={title} linkTo={`/detalhes/${auctionId}`} />

            <Text fontSize={1} color="neutrals.EclipseE500" mb={2}>
              {year}
              <Text as="span" mx={1}>
                |
              </Text>
              {mileage}
            </Text>
            <State state={state} />

            {status.inNegotiation && <NegotiationCallout price={winningBid} />}

            {status.offerAccepted && <OfferAcceptedCallout price={winningBid} />}

            {status.offerDeclined && <OfferRejectedCallout />}

            {status.onGoing && (
              <React.Fragment>
                <Box mb={rem(18)}>
                  <AuctionItemTimer
                    onBuyNowClick={this.handleBuyNowClick}
                    auction={this.props.auction}
                  />
                </Box>

                <Card bg="neutrals.EclipseE60" p={rem(10)} variant="flat" mb={rem(14)}>
                  <Flex alignItems="center">
                    <Box flex="1" pr={1}>
                      <Text as="span" fontSize={rem(10)} color="neutrals.EclipseE500">
                        Última oferta
                      </Text>

                      <Price
                        value={winningBid}
                        fontSize={winningBid.length >= 9 ? 4 : rem(26)}
                        display="block"
                      />
                    </Box>

                    <Box flex="1" pl={1}>
                      <Button
                        type="button"
                        width={1}
                        onClick={this.handleMakeOfferClick}
                        px={{ sm: 2, lg: 3 }}
                        fontSize={{ sm: 1, md: 2 }}
                      >
                        Fazer oferta
                      </Button>
                    </Box>
                  </Flex>
                </Card>

                <Box mb={rem(8)}>
                  {!!documentsResearchItems.length && (
                    <MiniDocumentResearch items={documentsResearchItems} />
                  )}
                </Box>

                <AttentionPointsLink percentage={approvalPct} onClick={this.gotoAttentionPoints} />
              </React.Fragment>
            )}
          </Flex>
        </Flex>
      </AuctionCard>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toggle: bindActionCreators(toggleModal, dispatch),
  selectAuction: bindActionCreators(setSelectedAuction, dispatch),
  trackAuctionEventAction: trackAuctionEvent,
});

export default compose(withRouter, connect(null, mapDispatchToProps))(CarItem);
