import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '../../../abstract';

export const UndecoratedLink = ({ children, to, ...props }) => (
  <Link as={RouterLink} to={to} variant="undecorated" {...props}>
    {children}
  </Link>
);

UndecoratedLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
