import React, { useContext, useMemo } from 'react';

import { SlideContext } from '../ScrollView';
import { Container } from './styles';

export default function Slide({ children }) {
  const { MIN_SLIDE_WIDTH, MAX_SLIDES, width } = useContext(SlideContext);

  const maxWidth = useMemo(() => width / MAX_SLIDES, [MAX_SLIDES, width]);

  return (
    <Container minWidth={MIN_SLIDE_WIDTH} maxWidth={maxWidth}>
      {children}
    </Container>
  );
}
