import styled from 'styled-components';

const MaximumQualityContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 0 72px;
  padding: 32px 60px;
  background-color: #003a76;
  @media screen and (max-width: 1025px) {
    flex-direction: column;
    padding-inline: 20px;
  }
`;

const MaximumQualitySeal = styled.img`
  max-width: 210px;
  width: 100%;
  max-height: 210px;
  height: 100%;
  @media screen and (max-width: 1025px) {
    max-width: 90px;
    max-height: 90px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
`;

const MaximumQualityContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  @media screen and (max-width: 1025px) {
    gap: 24px 0;
    margin-top: 20px;
  }
`;

export const Typography = styled.span(
  ({ fontFamily, fontWeight, fontSize, color, lineHeight }) => `
  font-family: ${fontFamily ?? 'Rubik'}, sans-serif;
  font-weight: ${fontWeight ?? 700};
  font-size: ${fontSize ?? 16}px;
  color: ${color ?? '#fff'};
  line-height: ${lineHeight ?? 24}px;
`
);

const MaximumQualityList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-self: center;
  gap: 14px 96px;
  margin-top: 20px !important;
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    gap: 14px 0;
    align-self: flex-start;
    margin-top: 0 !important;
  }
`;

const MaximumQualityItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0 14px;
`;

const MaximumQualityItemSeal = styled.img`
  max-width: 24px;
  width: 100%;
`;

export const MaximumQuality = {
  Container: MaximumQualityContainer,
  Seal: MaximumQualitySeal,
  Content: MaximumQualityContent,
  List: MaximumQualityList,
  Item: MaximumQualityItem,
  ItemSeal: MaximumQualityItemSeal,
};
