import React from 'react';

import { Container, Avatar, Content } from './styles';

export const NewAppraiser = ({ name, picture }) => (
  <Container>
    <Avatar src={picture} alt="Foto do Avaliador" />
    <Content>
      <span className="rated-by">Avaliado por</span>
      <span className="evaluator-name">{name}</span>
    </Content>
  </Container>
);
