import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/**
 * Creates a new portal to render components outside the app container.
 * {@link https://reactjs.org/docs/portals.html}
 * @param {string} id the id of the portal container where new children will be inserted to.
 */
export const createPortal = id => {
  const target = document.getElementById(id);
  class Portal extends React.Component {
    constructor(props) {
      super(props);
      this.el = document.createElement('div');
    }

    componentDidMount() {
      target.appendChild(this.el);
    }

    componentWillUnmount() {
      target.removeChild(this.el);
    }

    render() {
      return ReactDOM.createPortal(this.props.children, this.el);
    }
  }
  Portal.propTypes = { children: PropTypes.any };
  return Portal;
};
