import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setFavoritesAuctions } from '../../store/actions/auction.actions';
import { EmptyState } from '../../components/enhanced/EmptyState';
import Card from '../../components/NewAuctionsList/Components/Card';
import { useFavoriteContext } from '../../context/FavoriteContext';
import { sortFavoriteList } from '../../utils/favorite';
import { Layout, Loading } from '../../components';
import { Container } from './styles';
import { useSegment } from '../../hooks/useSegment';
import AuctionNewOffersCard from '../../components/AuctionCards/AuctionNewOffersCard';

function Favorites({ user, favorites, setFavorites }) {
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const { getFavoriteVehicles, isLoading } = useFavoriteContext();
  const { page } = useSegment();

  useEffect(
    function() {
      async function getFavorites() {
        setLoading(true);
        const fav = await getFavoriteVehicles(user);
        const favSorted = sortFavoriteList(fav);
        setFavorites([...favSorted]);
      }
      getFavorites();
    },
    [getFavoriteVehicles]
  );

  useEffect(() => {
    setLoading(false);
  }, [favorites]);

  useEffect(() => {
    page('Meus Favoritos');
  }, []);

  return (
    <Layout>
      <Container width="100%" mx="auto">
        <Container.Title>Favoritos</Container.Title>
        {loading || isLoading ? (
          <Container.Loading>
            <Loading />
          </Container.Loading>
        ) : (
          <>
            {favorites?.length > 0 ? (
              <Container.List>
                {favorites?.map((favorite, key) => (
                  <>
                    <AuctionNewOffersCard key={key} auction={favorite} isFromFavorite={true} />
                    {/* <Card key={key} auction={favorite} verticalStyle={true} /> */}
                  </>
                ))}
              </Container.List>
            ) : (
              <EmptyState
                title="Você não favoritou nenhum leilão ainda"
                description="Vá para a tela de leilões e favorite o seu primeiro carro."
                buttons={[
                  {
                    label: 'Ver leilões ativos',
                    action: () => {
                      history.push('/');
                    },
                    variant: 'ghost.default',
                  },
                ]}
              />
            )}
          </>
        )}
      </Container>
    </Layout>
  );
}

function mapStateToProps({ authentication, auctions }) {
  return {
    user: authentication.user,
    favorites: auctions.favorites,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFavorites: bindActionCreators(setFavoritesAuctions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
