import React from 'react';
import ClickOutside from 'react-click-outside';

import { Button, FilterContent, MobileFilter, DesktopFilter, Container } from './styles';
import ArrowUpDown from '../../../../images/icons/arrow-up-down.svg';
import { SORT_OPTIONS } from '../../../../context/AuctionFilters/filter-enums';
import SortFilterMobile from './components/sort-filter-mobile';
import SortFilterDesktop from './components/sort-filter-desktop';
import { connect } from 'react-redux';
import { getAuctionsSorted } from '../../../../store/selectors/uiSelectors';
import { bindActionCreators } from 'redux';
import { sortAuctions } from '../../../../store/actions/ui.actions';
import { handleFilters } from '../../../../store/actions/auction.actions';

function SortFilter({ sortFilterParam, sortAuctions, toggleFilters, show }) {
  function toggle() {
    toggleFilters({ showSortFilters: !show });
  }

  function closeFilters() {
    if (show) toggleFilters({ showSortFilters: false });
  }

  function handleOnItemClicked(value) {
    sortAuctions(value);
  }

  function getSelectedOption() {
    const finded = SORT_OPTIONS?.find(option => option.value === sortFilterParam);
    if (!finded) return 'Selecione uma opção';
    return finded.label;
  }

  return (
    <ClickOutside onClickOutside={closeFilters}>
      <Container>
        <Button onClick={toggle}>
          <span className="label">{getSelectedOption()}</span>
          <img src={ArrowUpDown} alt="Ordernar" />
        </Button>
        <FilterContent className={show ? 'show' : ''}>
          <MobileFilter>
            <SortFilterMobile
              options={SORT_OPTIONS}
              handleOptionClicked={handleOnItemClicked}
              value={sortFilterParam}
            />
          </MobileFilter>
          <DesktopFilter>
            <SortFilterDesktop
              options={SORT_OPTIONS}
              handleOptionClicked={handleOnItemClicked}
              value={sortFilterParam}
            />
          </DesktopFilter>
        </FilterContent>
      </Container>
    </ClickOutside>
  );
}

const mapStateToProps = state => ({
  sortFilterParam: getAuctionsSorted(state),
  show: state.ui.filters.showSortFilters,
});

const mapDispatchToProps = dispatch => ({
  sortAuctions: bindActionCreators(sortAuctions, dispatch),
  toggleFilters: bindActionCreators(handleFilters, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortFilter);
