import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import get from 'lodash/get';

import { AuctionCard, Title as AuctionTitle } from './shared';
import { Flex, Text } from '../../abstract';
import { UndecoratedLink } from '../ui';
import MiniCarousel from '../../shared/carousel/MiniCarousel';
import { PostNegotiationStatusTracker } from './PostNegotiationStatusTracker';

export class NegotiatedCarItem extends React.Component {
  static propTypes = {
    auction: PropTypes.object.isRequired,
  };

  renderCarItem() {
    const { auction } = this.props;
    const { summary } = auction;

    const status = get(auction, 'auction.status');

    const title = `${summary.make} ${summary.model} ${summary.version}`;
    const year = [summary.productionYear, summary.year].filter(n => Boolean(n)).join(' / ');
    const mileage = summary.km ? `${summary.km} km` : 'N/A km';
    const detailsPath = `/detalhes/${auction._id}`;

    return (
      <React.Fragment>
        <UndecoratedLink to={detailsPath}>
          <MiniCarousel images={summary.photos || []} />
        </UndecoratedLink>

        <Flex flex="1" flexDirection="column" px={{ sm: 3, xlg: 4 }} py={rem(30)}>
          <AuctionTitle title={title} linkTo={detailsPath} />

          <Text fontSize={1} color="neutrals.EclipseE500" mb={2}>
            {year}
            <Text as="span" mx={1}>
              |
            </Text>
            {mileage}
          </Text>

          <PostNegotiationStatusTracker status={status} />
        </Flex>
      </React.Fragment>
    );
  }

  render() {
    return (
      <AuctionCard>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          {this.renderCarItem()}
        </Flex>
      </AuctionCard>
    );
  }
}
