import axios from 'axios';
import constants from '../constants/appConstants';
import { get } from '../utils/fecth-wrappers';

const URL = `${constants.API.makers}`;

export function fetchMarks() {
  return get(URL)
    .then(data => data)
    .catch(() => []);
}
