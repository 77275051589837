import styled from 'styled-components';

export const Container = styled.div`
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.3));
  padding: 17px 5px 5px 5px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  min-height: 54px;
  display: none;
  width: 325px;
  z-index: 2;
  top: 110px;

  &.show {
    display: block;
  }

  @media (max-width: 882px) {
    display: none !important;
  }
`;
