import styled from 'styled-components';

export const LastOffer = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  min-height: 47px;
  display: flex;
  width: 100%;

  .label {
    color: rgb(115, 113, 113);
    font-weight: normal;
    font-style: normal;
    line-height: 15px;
    font-size: 13px;
  }

  .money-sign {
    color: rgb(33, 33, 33);
    font-weight: normal;
    font-style: normal;
    line-height: 19px;
    margin-right: 5px;
    font-size: 16px;
  }

  .amount {
    color: rgb(33, 33, 33);
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
    font-size: 24px;
  }
`;

export const Box = styled.div`
  box-shadow: 0px 2px 11px rgba(73, 80, 87, 0.1);
  justify-content: center;
  background: #ffffff;
  align-items: center;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #db4647;
  padding: 10px;
  display: flex;
  height: 80px;
  width: 100%;

  span {
    margin-left: 4px;
  }
`;
