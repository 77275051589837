import React from 'react';

export const DownwardIcon = ({ color = '#fff', ...props }) => {
  return (
    <svg
      width={11}
      height={6}
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.46 5.69A.5.5 0 0110 6H7.5a.5.5 0 110-1h1.295L6 2.205l-1.645 1.65a.5.5 0 01-.71 0l-3-3a.5.5 0 01.163-.82.5.5 0 01.547.11L4 2.795l1.645-1.65a.5.5 0 01.71 0L9.5 4.295V3a.5.5 0 011 0v2.5a.5.5 0 01-.04.19z"
        fill={color}
      />
    </svg>
  );
};
