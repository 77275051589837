import React from 'react';

import { Flex } from '../../../abstract';
import { TabConsumer } from './TabsContext';
import { Tab } from './Tab';

export const TabList = props => (
  <TabConsumer>
    {({ tabs, onClick, activeTabId }) => (
      <Flex {...props}>
        {tabs &&
          tabs.map(({ id, title, tabProps = {} }, index) => (
            <Tab
              key={index}
              isActive={activeTabId === id}
              tabColor="primaries.Soul"
              onClick={onClick(id)}
              {...tabProps}
            >
              {title}
            </Tab>
          ))}
      </Flex>
    )}
  </TabConsumer>
);
