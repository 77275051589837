export const AUTO_BID_STATUS = {
  ON: true,
  OFF: false,
};

export const AUTO_BID_ERRORS = {
  AUTOBID_CREATED_ERROR: 'Não foi possível salvar o lance.',
  AUTOBID_NOT_FOUND: 'Lance automático não encontrado.',
  USER_ID_IS_REQUIRED: 'Necessário o ID do usuário.',
  USER_NOT_FOUND: 'Usuário não encontrado.',
  VEHICLE_NOT_FOUND: 'Veículo não enconrtado.',
  REACHED_THE_UPDATE_LIMIT: 'O limite de edições foi alcançado.',
  INSUFFICIENTE_FUNDS: 'O lance máximo definido é menor do que o lance atual.',
  FAILED_TO_GET_USER_ACCESS_TOKEN: 'Token expirado.',
  ALLOWED_TO_PLACE_BID_WITHOUT_DEALERSHIPNAME:
    'Não é possível configurar o lance automático sem o nome da loja.',
  ERROR_WHEN_BIDDING: 'Não foi possível efetuar o lance.',
  AUCTION_ENDED: 'Leilão encerrado.',
};
