import styled from 'styled-components';

export const Container = styled.div`
  display: none;

  &.show {
    display: block;
  }
  @media (min-width: 882px) {
    display: none !important;
  }
`;
