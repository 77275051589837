import Types from './types';

const INITIAL_STATE = {
  userPreferences: {},
  loading: false,
  error: false,
  loaded: false,
};

export function orderCars(state = INITIAL_STATE, action) {
  const { id, channelOfContact, preferences, ninja } = action.payload || {};

  switch (action.type) {
    case Types.CREATE_USER_PREFERENCES_REQUEST:
      return { ...state };
    case Types.CREATE_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        userPreferences: { id, channelOfContact, preferences, ninja },
        error: false,
      };
    case Types.CREATE_USER_PREFERENCES_FAILURE:
      return { ...state, loading: false, userPreferences: {}, error: action.error };
    case Types.UPDATE_USER_PREFERENCES_REQUEST:
      return { ...state, loading: true };
    case Types.UPDATE_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        userPreferences: { id, channelOfContact, preferences, ninja },
        error: false,
      };
    case Types.UPDATE_USER_PREFERENCES_FAILURE:
      return { ...state, loading: false, error: action.error };
    case Types.DELETE_USER_PREFERENCES_REQUEST:
      return { ...state, loading: true };
    case Types.DELETE_USER_PREFERENCES_SUCCESS:
      return { ...state, loading: false, userPreferences: {}, error: false };
    case Types.DELETE_USER_PREFERENCES_FAILURE:
      return { ...state, loading: false, error: action.error };
    case Types.LIST_USER_PREFERENCES_REQUEST:
      return { ...state, loading: true };
    case Types.LIST_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        userPreferences: { id, channelOfContact, preferences, ninja },
        error: false,
        loaded: true,
      };
    case Types.LIST_USER_PREFERENCES_FAILURE:
      return { ...state, loading: false, userPreferences: {}, error: action.error, loaded: false };
    default:
      return state;
  }
}
