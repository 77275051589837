import React from 'react';

export default {
  title: 'PAGAMENTO FACILITADO',
  imgAlt: 'Carro Instacarro',
  bulletPoints: {
    desktop: ['Atraia clientes com mais opções de veículos', 'Estabeleça sua loja como referência'],
    mobile: ['Estabeleça sua loja como referência', 'Atraia clientes com mais opções de veículos'],
  },
  desktopButton: 'Simular Agora',
  mobileButton: 'Simular Agora',
};
