import styled from 'styled-components';

export const Badge = styled.span`
  justify-content: center;
  align-items: center;
  background: #2274a5;
  font-style: normal;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  height: 20px;
  width: 20px;
  margin-left: 8px;
`;
