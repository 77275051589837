import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import hammer from 'hammerjs';

import { Container, List } from './styles';

const MIN_SLIDE_WIDTH = 280;
const MAX_SLIDES = 4;
const MAX_SLIDE_SIZE = MAX_SLIDES * MIN_SLIDE_WIDTH;

export const SlideContext = createContext({
  MIN_SLIDE_WIDTH,
  MAX_SLIDES,
  width: 0,
});

let observer;

function subscribeToResize(element, callback) {
  if (!observer) {
    observer = new ResizeObserver(entries => {
      callback(entries[0]);
    });
  }

  observer.observe(element);

  return observer;
}

function unsubscribeToResize(element) {
  if (!observer) {
    return null;
  }

  observer.unobserve(element);

  return observer;
}

function getSlidesOnScreen(width) {
  if (MAX_SLIDE_SIZE <= width) {
    return 4;
  }

  return Math.floor(width / MIN_SLIDE_WIDTH);
}

export default function ScrollView({ slidesNumber, children }) {
  const scrollViewRef = useRef(null);
  const [width, setWidth] = useState(scrollViewRef?.current?.clientWidth || 0);
  const [slidesOnScreen, setSlidesOnScreen] = useState(4);
  const [activedIndex, setActivedIndex] = useState(0);

  const panelsCount = useMemo(() => {
    if (slidesOnScreen === 0) {
      return 0;
    }
    return Math.ceil(slidesNumber / slidesOnScreen);
  }, [slidesNumber, slidesOnScreen]);

  const panelsArr = useMemo(() => [...Array(panelsCount).keys()], [panelsCount]);

  useEffect(() => {
    const calculatedWidth = width / MAX_SLIDES;

    const minSlidesWidth =
      calculatedWidth > MIN_SLIDE_WIDTH ? Math.ceil(calculatedWidth) : MIN_SLIDE_WIDTH;

    scrollViewRef.current.scrollTo(activedIndex * slidesOnScreen * minSlidesWidth, 0);
  }, [activedIndex, width, slidesOnScreen]);

  useEffect(() => {
    setSlidesOnScreen(getSlidesOnScreen(width));
  }, [width]);

  useEffect(() => {
    const target = scrollViewRef.current;

    if (!target) return;

    const listener = new hammer(target);

    listener.on('swipeleft', function() {
      setActivedIndex(activedIndex => activedIndex + 1);
    });

    listener.on('swiperight', function() {
      setActivedIndex(activedIndex => activedIndex - 1);
    });

    setWidth(target.clientWidth);

    subscribeToResize(target, () => {
      setActivedIndex(0);
      setWidth(target.clientWidth);
    });

    return () => unsubscribeToResize(target);
  }, []);

  return (
    <SlideContext.Provider value={{ MIN_SLIDE_WIDTH, MAX_SLIDES, width }}>
      <Container ref={scrollViewRef}>{children}</Container>
      {panelsCount && (
        <List>
          {panelsArr.map(function(i, index) {
            return (
              <div
                className={`page ${index === activedIndex && '__actived'}`}
                onClick={() => setActivedIndex(index)}
                key={i}
              />
            );
          })}
        </List>
      )}
    </SlideContext.Provider>
  );
}
