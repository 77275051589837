import React, { useEffect, useContext, useCallback, useState, createContext, useMemo } from 'react';

const doNothing = val => () => Promise.resolve(val);
export const UserFavoriteInspectionsContext = createContext({
  isLoading: true,
  favorites: [],
  addFavorite: doNothing(null),
  removeFavorite: doNothing(null),
  getFavoriteVehicles: doNothing([]),
});

export function UserFavoriteInspectionsContextProvider({ service, notify, children }) {
  const [isLoading, setIsloading] = useState(true);
  const [favorites, setFavorites] = useState([]);
  /**
   *  That function provide a instantly change on favorite status.
   *  If that isn't used the ux only changes once the api respond causing a bad experience.
   */
  const setTemporaryFavorite = useCallback(({ inspectionId, isActive }) => {
    setFavorites(oldFavorites => [{ inspectionId, isActive }].concat(oldFavorites));
  }, []);

  const addFavorite = useCallback(
    async inspectionId => {
      setTemporaryFavorite({ inspectionId, isActive: true });
      const newFavorites = await service.addFavorite({ inspectionId });
      setFavorites(newFavorites);
    },
    [setTemporaryFavorite]
  );

  const removeFavorite = useCallback(
    async inspectionId => {
      setTemporaryFavorite({ inspectionId, isActive: false });
      const newFavorites = await service.removeFavorite({ inspectionId });
      setFavorites(newFavorites);
    },
    [setTemporaryFavorite]
  );

  const getFavoriteVehicles = useCallback(
    async user => {
      try {
        const vehicleIds = favorites.map(fav => fav.inspectionId);
        if (vehicleIds.length > 0) {
          const vehicles = await service.getFavoriteVehicles(vehicleIds, user);
          return vehicles;
        } else {
          return [];
        }
      } catch (error) {
        notify.error(error);
      }
    },
    [favorites]
  );

  useEffect(() => {
    const fillContextData = async () => {
      try {
        const userFavorites = await service.getFavorites();
        setFavorites(userFavorites);
      } catch (error) {
        notify.error(error);
      } finally {
        setIsloading(false);
      }
    };

    fillContextData();
  }, [service, notify]);

  const contextValue = useMemo(
    () => ({
      isLoading,
      favorites,
      addFavorite,
      removeFavorite,
      getFavoriteVehicles,
    }),
    [isLoading, favorites, addFavorite, removeFavorite]
  );

  return (
    <UserFavoriteInspectionsContext.Provider value={contextValue}>
      {children}
    </UserFavoriteInspectionsContext.Provider>
  );
}

export function useFavoriteContext() {
  const context = useContext(UserFavoriteInspectionsContext);
  return context;
}

export function useUserFavoriteVehicle(inspectionId) {
  const [isFavorite, setIsFavorite] = useState(false);
  const context = useFavoriteContext();

  const addFavorite = useCallback(async () => {
    return context.addFavorite(inspectionId);
  }, [inspectionId]);

  const removeFavorite = useCallback(async () => {
    return context.removeFavorite(inspectionId);
  }, [inspectionId]);

  useEffect(() => {
    const favorite = context.favorites.find(fav => fav.inspectionId === inspectionId);
    const isFavoriteVehicle = !!(favorite && favorite.isActive);
    setIsFavorite(isFavoriteVehicle);
  }, [context.favorites, inspectionId]);

  return { isFavorite, addFavorite, removeFavorite };
}
