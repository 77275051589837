import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: calc(100vw - 20px);

  width: 100%;
  margin: 10px 0;

  scroll-behavior: smooth;
  overflow: hidden;
`;

export const List = styled.div`
  list-style: none;
  margin: auto 0px;
  display: flex;
  padding: 0px;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;

  .page {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 10px;
    width: 10px;
    margin: 0 10px;

    &.__actived {
      background: #fcab10;
      border: none;
    }
  }
`;
