import styled from 'styled-components';

import { flexWrap, flexDirection, alignItems, justifyContent, justifySelf } from 'styled-system';

import { Container } from './Container';

export const Flex = styled(Container)(
  {
    display: 'flex',
  },
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  justifySelf
);

Flex.displayName = 'Flex';

Flex.propTypes = {
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...justifySelf.propTypes,
};
