import React, { useState, useEffect, useMemo } from 'react';
import { TiLocation } from 'react-icons/ti';
import styled from 'styled-components';

import { Box } from './abstract';

const StateDiv = styled(Box)`
  display: inline-block;
  position: relative;
  width: fit-content;
  .state_button {
    padding: 0.5em 0.5em 0.5em 0.7em;
    min-height: 2.3rem;
    background: ${({ bgStyle }) => bgStyle};
    color: ${({ colorStyle }) => colorStyle};
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: ${({ borderStyle }) => borderStyle};
  }
  .state_icon {
    margin-right: 0.2rem;
    margin-top: -1px;
  }
  .state_overlay {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px 0px;
    position: absolute;
    background: white;
    border-radius: 6px;
    color: #212529;
    padding: 0.5rem;
    font-size: 0.8rem;
    z-index: 99;
    min-width: 15rem;
    line-height: 1.3em;
    position: absolute;
    left: 0px;
    top: calc(100% + 0.5rem);
    border-top: 2px solid rgb(61 184 112);
  }
  .state_overlay:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: 0.5rem solid transparent;
    border-top: none;
    border-bottom-color: rgb(61 184 112);
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }
`;

export const State = ({
  state = '',
  bgStyle = '#3db870',
  borderStyle = 'none',
  colorStyle = 'white',
}) => {
  const [open, setOpen] = useState(false);

  const clickHandler = () => {
    if (open) setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('click', clickHandler, false);
    return () => {
      window.removeEventListener('click', clickHandler, false);
    };
  }, [open]);

  const getMessageUF = useMemo(() => {
    if (state === 'SP') {
      return 'O carro poderá ser retirado em nosso pátio em SP.';
    }

    return state === 'RJ'
      ? 'O carro poderá ser retirado em nossa base no RJ ou em nosso pátio em SP, mediante programação e frete por conta do lojista.'
      : 'O carro poderá ser retirado em nossa base local ou em nosso pátio em SP, mediante programação e frete por conta da InstaCarro.';
  }, [state]);

  return state && typeof state === 'string' ? (
    <StateDiv className="state" bgStyle={bgStyle} borderStyle={borderStyle} colorStyle={colorStyle}>
      <div
        className="state_button"
        onClick={() => {
          setOpen(true);
        }}
      >
        <TiLocation className="state_icon" />
        {state}
      </div>

      {open && <div className="state_overlay">{getMessageUF}</div>}
    </StateDiv>
  ) : null;
};
