import React from 'react';
import Cleave from 'cleave.js/react';

import { TextInput } from './text-input';

export const CPFInput = props => (
  <TextInput
    as={Cleave}
    options={{
      blocks: [3, 3, 3, 2],
      delimiters: ['.', '.', '-'],
      numericOnly: true,
    }}
    {...props}
  />
);
