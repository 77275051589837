import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Pagination, DEFAULT_ITEMS_PER_PAGE } from './Pagination';

class RouterPagination extends React.PureComponent {
  static propTypes = {
    totalItems: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    pageKey: PropTypes.string,
    itemsPerPage: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChange: PropTypes.func,
  };

  static defaultProps = {
    pageKey: 'page',
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  };

  get currentPage() {
    const {
      location: { search: query },
      pageKey,
    } = this.props;

    const currentPage = Number.parseInt(query[pageKey], 10);

    return Number.isNaN(currentPage) ? 1 : currentPage;
  }

  handlePageChange = paginationData => {
    const { location, history, pageKey, onPageChange } = this.props;

    history.push(`${location.pathname}?${pageKey}=${paginationData.currentPage}`);

    onPageChange && onPageChange(paginationData);
  };

  render() {
    const { totalItems, itemsPerPage, pageNeighbours } = this.props;
    const currentPage = this.currentPage;

    return (
      <Pagination
        currentPage={currentPage}
        totalItems={totalItems}
        onPageChange={this.handlePageChange}
        itemsPerPage={itemsPerPage}
        pageNeighbours={pageNeighbours}
      />
    );
  }
}

export default withRouter(RouterPagination);
