import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  ${({ modal }) =>
    modal &&
    css`
      align-self: center;
      margin-bottom: 0.75rem;
    `}
`;

export const Icon = styled.img`
  max-width: 100%;
  width: 1.25rem;

  margin-right: 0.313rem;
`;

export const Label = styled.span`
  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #a34f00;

  ${({ color }) =>
    color === 1 &&
    css`
      color: #6b0874;
    `}

  ${({ color }) =>
    color === 3 &&
    css`
      color: #22aa52;
    `}

  ${({ detailsVehicle }) =>
    detailsVehicle &&
    css`
      color: #fff;
    `}

  ${({ autobid }) =>
    autobid &&
    css`
      @media screen and (max-width: 1281px) {
        font-size: 0.75rem;
      }

      @media screen and (max-width: 415px) {
        font-size: 0.688rem;
      }
    `}
  
  ${({ autobidParticipate }) =>
    autobidParticipate &&
    css`
      font-size: 0.75rem;
    `}
`;
