import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '../base-modal/BaseModal.jsx';

import './ConfirmationModal.scss';

class ConfirmationModal extends React.Component {
  render() {
    const { confirmationToggle, message = '' } = this.props;

    const handleClick = ev => {
      ev.preventDefault();
      confirmationToggle(false);
    };
    return (
      <div className="confirmation-modal">
        <div className="header">
          <div>
            Parabéns!
            <i className="icon-noun_1079837_cc" />
          </div>
        </div>
        <div className="text">{message}</div>
        <button className="confirmation" onClick={handleClick}>
          Fechar
        </button>
      </div>
    );
  }
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  confirmationToggle: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default BaseModal(ConfirmationModal);
