import ProdConstants from './appConstants.prod';
import SandConstants from './appConstants.sand';
import moment from 'moment';

export let AppConstants = {};

switch (process.env.IC_ENV) {
  case 'production':
    AppConstants = ProdConstants;
    break;
  default:
    AppConstants = SandConstants;
    break;
}

AppConstants.API = {
  authentication: `${AppConstants.baseURLs.authentication}/oauth/token`,
  newAuthentication: `${AppConstants.baseURLs.newAuthentication}/oauth/token`,
  carListing: `${AppConstants.baseURLs.dealers}/listings-aggregation/?limit=200&sort=-updatedAt`,
  ninjaView: `${AppConstants.baseURLs.dealers}/listings-aggregation/ninja-view?limit=300`,
  auctions: `${AppConstants.baseURLs.dealers}/listings-aggregation`,
  historyAuctions: `${AppConstants.baseURLs.auQueries}/my-purchases`,
  negotiatedAuctions: `${AppConstants.baseURLs.logistics}/historics/negotiated`,
  soldAuctions: `${AppConstants.baseURLs.logistics}/historics/sold`,
  auctionDetails: `${AppConstants.baseURLs.buyers}/auctions`,
  relatedDealers: `${AppConstants.baseURLs.sales}/related-dealers/`,
  dealership: `${AppConstants.baseURLs.sales}/related-dealers/dealership`,
  wishlist: `${AppConstants.baseURLs.dealers}/wishlist/`,
  notifications: `${AppConstants.baseURLs.dealers}/listings-aggregation/auctions-participated`,
  stockCars: `${AppConstants.baseURLs.buyersV3}/buy-inventory-car-now/`,
  forgotPassword: `${AppConstants.baseURLs.accounts}/forgot-password`,
  resetPassword: `${AppConstants.baseURLs.accounts}/reset-password`,
  inspections: `${AppConstants.baseURLs.mechanics}/vehicleSellRequests/v2/text-search/inspections`,
  taxIds: `${AppConstants.baseURLs.icApiDealers}/current-user/taxids`,
  auctionsAuthenticated: `${AppConstants.baseURLs.buyers}/auctions/authenticated`,
  userFavorite: `${AppConstants.baseURLs.icApiDealers}/favorite`,
  autobid: `${AppConstants.baseURLs.autobid}/auto-bid`,
  makers: 'https://apigateway.instacarro.com/api/sellers_price/v1/makers',
  models: 'https://apigateway.instacarro.com/api/sellers_price/v1/makers/MAKER/models/',
  user_preferences: `${AppConstants.baseURLs.preferences}/user-preferences`,
  user_notifications: `${AppConstants.baseURLs.preferences}/user-notifications`,
  createViews: `${AppConstants.baseURLs.dealers}/listings-aggregation/auctions/create-views`,
  checkFinancingEnabled: `${AppConstants.baseURLs.fincredit}/check-financing-enabled`,
  loadCreditAvaible: `${AppConstants.baseURLs.fincredit}/load-credit-available`,
  saveCreditSimulation: `${AppConstants.baseURLs.fincredit}/save-credit-simulation-dealer`,
  generateDoc: `${AppConstants.baseURLs.generateDoc}`,
};

AppConstants.API.autoBid = id => `${AppConstants.baseURLs.buyers}/auctions/${id}/bids/auto/`;

AppConstants.localStorage = {
  token: 'instacarro::session',
  buyerId: 'instacarro:buyerId',
};

AppConstants.AuctionsPusherChannel = 'auctions';
AppConstants.NegotiationsPusherChannel = 'negotiations';
AppConstants.NotificationsPusherChannel = 'pedir_carros';
AppConstants.NotificationsTemperaturePusherChannel = 'temperature';
AppConstants.MinimumBid = 250;

AppConstants.DefaultICImage = 'https://cdn3.instacarro.com/sellers_home/logo--small.png';

AppConstants.SECOND = 1000;
AppConstants.MINUTE = 60 * AppConstants.SECOND;

AppConstants.TimeToDisappearAuctions = AppConstants.MINUTE;

AppConstants.MAX_INPUT_AMOUNT = 99999999;
AppConstants.Debug = true;

AppConstants.TaxIdStatus = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  VALIDATING: 'VALIDATING',
};

AppConstants.TaxIdType = {
  CNPJ: 'CNPJ',
  CPF: 'CPF',
};

export default AppConstants;

export const auctionStatus = {
  auctionOngoing: 'AUCT_ONGOING',
  onOffer: 'ON_OFFER',
  offerOngoing: 'OFFER_ONGOING',
  offerAccepted: 'OFFER_ACCEPTED',
  offerDeclined: 'OFFER_DECLINED',
  paymentPending: 'TICKET_SENT',
  paymentReady: 'PAID',
  waitingWithdrawal: 'WAITING_WITHDRAWAL',
  delivered: 'DELIVERED',
  documentationPending: 'DOCUMENTATION_IN_PROGRESS',
  documentationReady: 'DOCUMENTATION_READY',
  finished: 'FINISHED',
};

export const getAuctionStatusText = status => {
  return {
    [auctionStatus.auctionOngoing]: 'Em leilão',
    [auctionStatus.onOffer]: 'Aguardando vendedor',
    [auctionStatus.offerOngoing]: 'Em negociação',
    [auctionStatus.offerAccepted]: 'Oferta aceita',
    [auctionStatus.offerDeclined]: 'Oferta recusada',
  }[status];
};

export const gaugeRanges = [
  {
    start: 0,
    end: 1 / 8,
    color: '#C95C3D',
  },
  {
    start: 1 / 8,
    end: 2 / 8,
    color: '#CD7841',
  },
  {
    start: 2 / 8,
    end: 3 / 8,
    color: '#DF9957',
  },
  {
    start: 3 / 8,
    end: 4 / 8,
    color: '#E4C261',
  },
  {
    start: 4 / 8,
    end: 5 / 8,
    color: '#E3DB89',
  },
  {
    start: 5 / 8,
    end: 6 / 8,
    color: '#CDD76C',
  },
  {
    start: 6 / 8,
    end: 7 / 8,
    color: '#ACB648',
  },
  {
    start: 7 / 8,
    end: 1,
    color: '#60A33C',
  },
];

export const lojaStatus = {
  Bid: 'Bid',
  Visualizar: 'Visualizar',
  Pendente: 'Pendente',
};

export const myAuctionsFilters = {
  all: '',
  winning: 'winning',
  negotiation: 'negotiation',
  offerAccepted: 'offer_accepted',
  participating: 'participating',
};

export const myAuctionsFilterOptions = [
  {
    value: myAuctionsFilters.participating,
    text: 'Participando',
  },
  {
    value: myAuctionsFilters.negotiation,
    text: 'Em negociação',
  },
  {
    value: myAuctionsFilters.offerAccepted,
    text: 'Oferta aceita',
  },
  {
    value: myAuctionsFilters.all,
    text: 'Todos',
  },
];

AppConstants.USER_PROFILE_TTL_IN_DAYS = 120;

export const sortFilterParams = {
  SHORTEST_EXPECTED_TIME: 'SHORTEST_EXPECTED_TIME',
  SHORTER_TOTAL_TIME: 'SHORTER_TOTAL_TIME',
  LONGER_TIME: 'LONGER_TIME',
};

export const nextYear = moment()
  .add(1, 'years')
  .year();

export const mixpanelToken = AppConstants.MixpanelToken;
