import React from 'react';
import { Select } from './styles';

export const DealershipSelect = ({ list, value, onChange, placeholder }) => {
  if (!list) return null;
  return (
    <Select.Container>
      <Select value={value} onChange={onChange}>
        <option value={null}>{placeholder}</option>
        {list.map(({ dealershipName, _id }) => (
          <option key={_id} value={_id}>
            {dealershipName}
          </option>
        ))}
      </Select>
      <Select.Icon fill="#2274a5" />
    </Select.Container>
  );
};

export const NewSelect = ({ list, value, onChange, placeholder }) => {
  if (!list) return null;

  return (
    <Select.Container>
      <Select value={value} onChange={onChange}>
        <option value={0}>{placeholder}</option>
        {list.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
      <Select.Icon fill="#2274a5" />
    </Select.Container>
  );
};
