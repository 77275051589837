import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  display: flex;
`;

export const ImgWrapper = styled.div`
  padding-left: 5px;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    &:hover > div {
      display: block;
    }
  }
`;

export const TooltipContent = styled.div`
  position: absolute;
  left: 50%;
  display: none;
  padding: 12px 14px;
  font: 400 normal 0.625rem/1em 'Nunito', sans-serif;
  color: white;
  background-color: #171c24;
  border-radius: 4px;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: calc(6px * -1);
    border: 6px solid transparent;
    border-top-color: #171c24;
    pointer-events: none;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5em;
  & > strong {
    font-size: 0.75rem;
    line-height: 1em;
  }
  ${({ isHidden }) =>
    isHidden &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
      text-align: start;
    `}
`;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
