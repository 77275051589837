import React, { memo } from 'react';

import MinusButton from '../../Buttons/MinusButton';
import PlusButton from '../../Buttons/PlusButton';

import { Container, Label, Content, DollarSign, Input } from './styles';

const BidInput = ({
  inputWidth,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  autobid = false,
  handleMinusButton,
  handlePlusButton,
  detailsVehicle = false,
  style,
}) => {
  return (
    <Container inputWidth={inputWidth} detailsVehicle={detailsVehicle} style={style}>
      <Label detailsVehicle={detailsVehicle}>{label}</Label>
      <MinusButton onClick={handleMinusButton} />

      <Content autobid={autobid}>
        {!placeholder && <DollarSign>R$</DollarSign>}

        <Input
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          required
          autobid={autobid}
          detailsVehicle={detailsVehicle}
        />
      </Content>

      <PlusButton onClick={handlePlusButton} />
    </Container>
  );
};

export default memo(BidInput);
