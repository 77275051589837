import moment from 'moment';

export const saveFirstLoginOfTheDay = () => {
  const currentDateTime = moment();
  const savedFirstLoginOfTheDay = JSON.parse(localStorage.getItem('first_login_of_the_day'));

  if (savedFirstLoginOfTheDay) {
    if (!currentDateTime.isSame(savedFirstLoginOfTheDay.date, 'day')) {
      localStorage.setItem(
        'first_login_of_the_day',
        JSON.stringify({
          date: moment().format(),
          showed: false,
        })
      );
    }
  } else {
    localStorage.setItem(
      'first_login_of_the_day',
      JSON.stringify({
        date: moment().format(),
        showed: false,
      })
    );
  }
};

export const setShowed = () => {
  const savedFirstLoginOfTheDay = JSON.parse(localStorage.getItem('first_login_of_the_day'));
  if (!savedFirstLoginOfTheDay) return;
  savedFirstLoginOfTheDay.showed = true;
  localStorage.setItem('first_login_of_the_day', JSON.stringify(savedFirstLoginOfTheDay));
};

export const canShowFreeShippingDialog = () => {
  const savedFirstLoginOfTheDay = JSON.parse(localStorage.getItem('first_login_of_the_day'));
  if (savedFirstLoginOfTheDay && !savedFirstLoginOfTheDay?.showed) {
    return true;
  } else {
    return false;
  }
};
