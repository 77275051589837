import ActionTypes from '../../constants/actionTypes';
import { getCurrentElapsed } from '../../utils/dateUtils';

export function startTimer() {
  return dispatch => {
    dispatch(incrementTimer());
    dispatch({
      type: ActionTypes.Timer.Start,
    });
  };
}

export function stopTimer() {
  return {
    type: ActionTypes.Timer.Stop,
  };
}

function incrementTimer() {
  return (dispatch, getState) => {
    setInterval(() => {
      const state = getState();
      if (state.timer.stop) {
        return;
      }
      dispatch({
        type: ActionTypes.Timer.Increment,
        elapsed: getCurrentElapsed(),
      });
    }, 1000);
  };
}
