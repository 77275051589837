import React from 'react';

export function ArrowBackRounded({ color = '#fff', ...props }) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.125 15.3l6.6-6.6c.1-.1.171-.208.213-.325.042-.117.063-.242.062-.375 0-.133-.021-.258-.063-.375a.889.889 0 00-.212-.325L9.125.7a.976.976 0 00-.688-.287.931.931 0 00-.712.287.937.937 0 00-.313.688.925.925 0 00.288.712L12.6 7H1.425a.968.968 0 00-.713.288A.964.964 0 00.425 8a.97.97 0 00.287.713.963.963 0 00.713.287H12.6l-4.9 4.9a.994.994 0 00-.288.7.868.868 0 00.288.7c.183.2.417.3.7.3s.525-.1.725-.3z"
        fill={color}
      />
    </svg>
  );
}
