import { useMemo } from 'react';
import { objectToArray } from '../utils/array';
import { before100k, cautelar, closeToEnd, fipe70, fipe70k, fipe100k, score80 } from './useFilter';
import { useSelector } from 'react-redux';

const invertPreferencesLabel = {
  CAUTELAR_APROVADA: 'cautelar',
  UF: 'uf',
  DISPUTA_TURBO: 'closeToEnd',
  SCORE: 'score80',
  ABAIXO_DA_FIPE: 'fipe70',
  CEM_MIL_KM: 'before100k',
  FIPE_70K: 'fipe70k',
  FIPE_100K: 'fipe100k',
  BRANDS: 'brands',
  YEAR: 'year',
  KM: 'km',
};

export const usePreferences = () => {
  const { preferences } = useSelector(state => state.orderCars.userPreferences);
  const { newOffers } = useSelector(state => state.auctions || []);
  const { profile } = useSelector(state => state.user?.defaultUserProfile || []);

  const uf = list => {
    const currentProfile = !!profile ? profile[0] : {};
    return list.filter(
      i => i?.summary?.state?.toUpperCase() === currentProfile?.address?.state?.toUpperCase()
    );
  };

  const km = list => {
    const km = preferences?.find(v => v.name === 'KM').value;
    return list.filter(v => v?.summary?.km <= km);
  };

  const year = list => {
    const year = preferences?.find(v => v.name === 'YEAR').value;
    return list.filter(v => v?.summary?.year >= year);
  };

  const brands = list => {
    const filter = [];
    const brands = preferences?.find(v => v.name === 'BRANDS').value;

    for (let brand of brands) {
      const v = list.filter(i => i?.summary?.make === brand);
      v.forEach(i => filter.push(i));
    }

    return filter;
  };

  const activedPreferences = !!preferences?.length
    ? preferences
        ?.filter(v => v.isActive === true)
        .map(preference => invertPreferencesLabel[preference.name])
    : [];

  const recommendedCars = useMemo(() => {
    let list = newOffers;

    if (!activedPreferences.length || !newOffers.length) return (list = []);

    if (activedPreferences.includes('score80')) list = score80(list);
    if (activedPreferences.includes('cautelar')) list = cautelar(list);
    if (activedPreferences.includes('fipe70')) list = fipe70(list);
    if (activedPreferences.includes('before100k')) list = before100k(list);
    if (activedPreferences.includes('closeToEnd')) list = closeToEnd(list);
    if (activedPreferences.includes('uf')) list = uf(list);
    if (activedPreferences.includes('fipe70k')) list = fipe70k(list);
    if (activedPreferences.includes('fipe100k')) list = fipe100k(list);
    if (activedPreferences.includes('km')) list = km(list);
    if (activedPreferences.includes('year')) list = year(list);
    if (activedPreferences.includes('brands')) list = brands(list);

    return (list = list.map(({ _id }) => _id));
  }, [activedPreferences, newOffers]);

  return { recommendedCars };
};
