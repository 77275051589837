import React, { useState } from 'react';
import {
  Accordion as AccordionBase,
  AccordionItem as AccordionItemBase,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion';
import '@reach/accordion/styles.css';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { MdChevronRight, MdExpandMore } from 'react-icons/md';

import { Flex, Box } from './abstract';

const AccordionButtonStyles = styled(Box).attrs({
  as: 'button',
})`
  display: block;
  width: 100%;
  border: 1px solid #9f9f9f;
  padding: 1rem;
  margin: 0;
  text-decoration: none;
  background: #ffffff;
  color: ${themeGet('colors.primaries.Soul')};
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: left;
  transition: background 250ms ease-in-out, transform 150ms ease;
  appearance: none;
`;

const AccordionItem = styled(AccordionItemBase)`
  margin-bottom: 1rem;
`;

const chevronProps = {
  size: '1.7rem',
};

export const Accordion = ({
  items = [],
  initialOpenIndices = [],
  multiple = true,
  collapsible = true,
}) => {
  const [indices, setIndices] = useState(initialOpenIndices);

  const toggleItem = toggledIndex => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter(currentIndex => currentIndex !== toggledIndex));
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  };

  const itemIsOpen = index => indices.includes(index);

  return (
    <AccordionBase onChange={toggleItem} multiple={multiple} collapsible={collapsible}>
      {items.map(({ id, header, content }, index) => {
        const isOpen = itemIsOpen(index);
        return (
          <AccordionItem key={id}>
            <h3>
              <AccordionButton as={AccordionButtonStyles}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Box flex="1">{header({ isOpen })}</Box>
                  <Box>
                    {isOpen ? (
                      <MdExpandMore {...chevronProps} />
                    ) : (
                      <MdChevronRight {...chevronProps} />
                    )}
                  </Box>
                </Flex>
              </AccordionButton>
            </h3>
            <AccordionPanel>{content({ isOpen })}</AccordionPanel>
          </AccordionItem>
        );
      })}
    </AccordionBase>
  );
};
