import { addDays, isAfter } from 'date-fns';

export const viewedStorage = (user, auctionId, vehicleId, date) => {
  const storage = JSON.parse(localStorage.getItem(user));
  const vehicle = {
    id: auctionId,
    vehicleId,
    viewed: date,
  };

  if (!storage) {
    return localStorage.setItem(user, JSON.stringify([vehicle]));
  } else if (!storage?.find(el => el.id === vehicle.id)) {
    return localStorage.setItem(user, JSON.stringify([vehicle, ...storage]));
  }
};

export const removeViewStorage = user => {
  const storage = JSON.parse(localStorage.getItem(user));

  if (!!storage) {
    storage.forEach(el => {
      const date = addDays(new Date(el.viewed), 7);

      if (isAfter(new Date(), date)) {
        storage.splice(storage.indexOf(storage.find(item => item.id === el.id)), 1);

        localStorage.setItem(user, JSON.stringify(storage));
      }
    });
  }
};
