import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from '..';
import { Flex, Text } from '../../../abstract';

export const substepPropTypes = PropTypes.shape({
  title: PropTypes.string.isRequired,
  titleAfterDone: PropTypes.string,
  isDone: PropTypes.bool,
});

export const Substep = ({ step, color = 'primaries.Soul', ...props }) => {
  const { title, titleAfterDone, isDone } = step;

  return (
    <Flex alignItems="center" {...props}>
      <Badge bg={isDone ? color : 'transparent'} borderColor={color} size={10} mr={1} />

      <Text fontSize={0} fontWeight="paragraphs.medium" color="neutrals.EclipseE700">
        {isDone && titleAfterDone ? titleAfterDone : title}
      </Text>
    </Flex>
  );
};

Substep.propTypes = {
  step: substepPropTypes.isRequired,
  color: PropTypes.string,
};
