import React from 'react';
import PropTypes from 'prop-types';
import Notification from './Notifications';

class NotificationWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ignore: true,
      title: '',
    };

    this._trigger = this._trigger.bind(this);
  }

  _trigger(title, body, tag, icon) {
    if (this.state.ignore) {
      return;
    }

    const newIcon = icon ? icon : null;

    const options = {
      tag: `${tag}_${Math.random()}`,
      body: body,
      icon: newIcon,
      lang: 'pt',
      dir: 'ltr',
      sound: 'https://s3-sa-east-1.amazonaws.com/cdn-instacarro-com/sellers_home/sound.mp3', // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
    };

    this.setState({
      title: title,
      options: options,
    });
  }

  _handlePermissionGranted() {
    this.setState({
      ignore: false,
    });
  }

  _handlePermissionDenied() {
    this.setState({
      ignore: true,
    });
  }

  _handleNotSupported() {
    this.setState({
      ignore: true,
    });
  }

  _handleNotificationOnClick(e, tag) {
    window.focus();

    if (tag.indexOf('Outbid') !== -1) {
      this.props.outbidClick();
    }
  }

  _handleNotificationOnError(e, tag) {}

  _handleNotificationOnClose(e, tag) {}

  _handleNotificationOnShow(e, tag) {
    document
      .getElementById('sound')
      ?.play()
      .catch(err => null);
  }

  render() {
    const hidden = this.props.hidden || true;
    const loop = this.props.loop || false;
    return (
      <div>
        <Notification
          ignore={this.state.ignore && this.state.title !== ''}
          notSupported={this._handleNotSupported.bind(this)}
          onPermissionGranted={this._handlePermissionGranted.bind(this)}
          onPermissionDenied={this._handlePermissionDenied.bind(this)}
          onShow={this._handleNotificationOnShow.bind(this)}
          onClick={this._handleNotificationOnClick.bind(this)}
          onClose={this._handleNotificationOnClose.bind(this)}
          onError={this._handleNotificationOnError.bind(this)}
          timeout={8000}
          title={this.state.title}
          options={this.state.options}
        />
        <audio id="sound" preload="auto">
          <source
            src="https://s3-sa-east-1.amazonaws.com/cdn-instacarro-com/sellers_home/sound.mp3"
            type="audio/mpeg"
          />
          <source
            src="https://s3-sa-east-1.amazonaws.com/cdn-instacarro-com/sellers_home/sound.ogg"
            type="audio/ogg"
          />
          <embed
            hidden={hidden}
            loop={loop}
            src="https://s3-sa-east-1.amazonaws.com/cdn-instacarro-com/sellers_home/sound.mp3"
          />
        </audio>
      </div>
    );
  }
}

NotificationWrapper.propTypes = {
  outbidClick: PropTypes.func,
  hidden: PropTypes.bool,
  loop: PropTypes.bool,
};

export default NotificationWrapper;
