import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../enhanced/Modal';
import { AuctionItemTimer } from '../../enhanced';
import {
  Button,
  Content,
  Image,
  Text,
  style,
  ContentFin,
  TitleFinDiv,
  TitleFinText,
  FinText,
  FinTextDiv,
  FinancingInfoDiv,
  EntryValueText,
  EntryValue,
  TaxesTotalDiv,
  ButtoFincred,
} from './styles';

import Ilustration from './images/ilustracao-carro.svg';

import { getSelectedAuction } from '../../../store/selectors';
import { toggleModal } from '../../../store/actions/ui.actions';
import { getSelectedAuctionRemainingTime } from '../../../store/selectors/auctionSelectors';
import { AUCTION_BUY_FOR } from '../../../constants/modalConstants';
import { TimingAuctionContextProvider } from '../../../context/TimingAuctionContext';
import { formatPrice } from '../../../utils/formatPrice';
import withMakeOffer from '../../hoc/WithMakeOffer';
import DealersDropdown from '../../Selects/DealersDropdown';
import TaxProfilesDropdown from '../../Selects/TaxProfilesDropdown';
import { useSegment } from '../../../hooks/useSegment';
import { toAmount } from '../../../utils/number';
import FincreditCoin from '../../../images/icons/fincredit-coin.svg';
import { saveCreditSimulation } from '../../../services/fincredit.js';
import { useDefaultProfile } from '../../../hooks/useDefaultProfile';

const BuyFor = ({
  onSubmitBuyFor,
  showDealersDropdown,
  dealerId,
  onDealerChange,
  relatedDealers,
  selectedTaxProfile,
  onTaxProfileChange,
  taxProfiles,
}) => {
  const dispatch = useDispatch();

  const state = useSelector(state => state);
  const { show, extras } = useSelector(state => state.ui.modals.auctionBuyFor);
  const { showFin, fincreditInfo, unleashFinFlag } = extras || {};
  const { user } = useSelector(state => state.authentication);
  const defaultProfile = useDefaultProfile();

  const selectedAuction = getSelectedAuction(state);
  const selectedAuctionRemainingTime = getSelectedAuctionRemainingTime(state);
  const { trackConfirmBid } = useSegment(extras?.isRecommended);

  const toggle = () => dispatch(toggleModal({ key: AUCTION_BUY_FOR, show: false }));

  const auction = useMemo(
    () => ({
      ...selectedAuction?.auction,
      remainingTime: selectedAuctionRemainingTime,
    }),
    [selectedAuction, selectedAuctionRemainingTime]
  );

  const handleSubmit = useCallback(
    async finCredit => {
      await onSubmitBuyFor();
      finCredit &&
        (await saveCreditSimulation(user.id, defaultProfile, fincreditInfo, selectedAuction));
      trackConfirmBid({
        inspection_id: selectedAuction._id,
        auction_id: selectedAuction.auction.idAuction,
        currentBid: selectedAuction.auction.winningBid.amount,
        buyForBid: toAmount(selectedAuction?.summary?.buyForPrice?.amount),
        type: finCredit && fincreditInfo?.entryAmount ? 'buyForBidFinCredit' : 'buyForBid',
        entry_amount: fincreditInfo?.entryAmount,
        quantity_installments: fincreditInfo?.quantityInstallments,
        date_hour: new Date().getTime(),
        section: extras?.section,
      });
    },
    [selectedAuction, extras?.section]
  );

  return (
    <TimingAuctionContextProvider auction={auction}>
      <Modal show={show} toggle={toggle} style={style}>
        <Content>
          <Text fontWeight={700} fontSize="16px" lineHeight="20px">
            {selectedAuction?.summary?.make} {selectedAuction?.summary?.model}{' '}
            {selectedAuction?.summary?.yearLabel} - {selectedAuction?.summary?.version}{' '}
            {selectedAuction?.summary?.kmLabel}
          </Text>
          <AuctionItemTimer auction={selectedAuction} showBuyNowButton={false} />
          <Image src={Ilustration} />
          <Text fontWeight={800} fontSize="18px">
            Compre agora por R$ {formatPrice(selectedAuction?.summary?.buyForPrice?.amount)}
          </Text>
          <Text fontSize="16px" lineHeight="24px">
            Preço de venda já negociado com atual proprietário. Compra imediata e sem negociação de
            valor.
          </Text>

          {showDealersDropdown ? (
            <DealersDropdown
              selectedDealer={dealerId}
              onDealerChange={onDealerChange}
              relatedDealers={relatedDealers}
              mb={0}
            />
          ) : null}

          {dealerId && taxProfiles.length > 0 ? (
            <TaxProfilesDropdown
              selectedTaxProfile={selectedTaxProfile}
              onTaxProfileChange={onTaxProfileChange}
              taxProfiles={taxProfiles}
              mb={0}
            />
          ) : null}

          <Button onClick={() => handleSubmit(false)}>Confirmar compra</Button>
        </Content>
        {showFin && fincreditInfo?.entryAmount && (
          <ContentFin>
            <TitleFinDiv>
              <img src={FincreditCoin} alt={'fincredit icon'} />
              <TitleFinText>Pagamento facilitado disponível</TitleFinText>
            </TitleFinDiv>
            <FinTextDiv>
              <FinText>
                Aproveite o pagamento facilitado para finalizar essa compra e pague agora apenas:
              </FinText>
              <FinancingInfoDiv>
                <EntryValueText>Valor de entrada</EntryValueText>
                <EntryValue>R$ {formatPrice(fincreditInfo?.entryAmount)}</EntryValue>
                <TaxesTotalDiv>
                  + {fincreditInfo?.quantityInstallments} parcelas de R${' '}
                  {formatPrice(fincreditInfo?.monthlyInstallment)}
                </TaxesTotalDiv>
              </FinancingInfoDiv>
            </FinTextDiv>
            <ButtoFincred onClick={() => handleSubmit(true)}>
              Comprar e pagar com facilidade
            </ButtoFincred>
          </ContentFin>
        )}
      </Modal>
    </TimingAuctionContextProvider>
  );
};

export default withMakeOffer(BuyFor);
