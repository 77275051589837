import axios from 'axios';
import constants from '../constants/appConstants';
import { get } from '../utils/fecth-wrappers';

const URL = `${constants.API.models}`;

export function fetchModels(makers) {
  const promises = makers.map(maker => {
    const url = URL.replace('MAKER', maker);
    return get(url);
  });
  return Promise.all(promises);
}
