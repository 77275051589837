import React from 'react';
import { VideoContainer, StyledIframe } from './styles';

const VideoPlayer = ({ video }) => {
  return (
    <VideoContainer>
      <StyledIframe src={video} allow="autoplay; fullscreen" allowFullScreen />
    </VideoContainer>
  );
};

export default VideoPlayer;
