import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { hideVisually } from 'polished';

const RadioButtonStyles = styled.input`
  ${hideVisually()};

  + label {
    position: absolute;
    display: inline-block;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    left: 0;
    top: 0;

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid
        ${props =>
          props.disabled
            ? themeGet('colors.neutrals.EclipseE80')(props)
            : themeGet('colors.neutrals.EclipseE500')(props)};
      background-color: ${props =>
        props.disabled ? themeGet('colors.neutrals.EclipseE50')(props) : 'transparent'};
    }

    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      left: 1px;
      top: 1px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }

  &:checked {
    + label::after {
      background: ${themeGet('colors.primaries.Soul')};
    }
  }
`;

const RadioButtonWrapperStyles = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
`;

const RadioButton = ({ name, value, onChange, disabled, labelText = '', ...props }) => {
  const id = props.id || name;
  return (
    <RadioButtonWrapperStyles
      onClick={e => {
        e.preventDefault();
        if (!disabled) {
          onChange(value);
        }
      }}
    >
      <RadioButtonStyles
        name={name}
        value={value}
        disabled={disabled}
        {...props}
        type="radio"
        id={id}
      />
      <label for={id}>{labelText}</label>
    </RadioButtonWrapperStyles>
  );
};

export { RadioButton };
