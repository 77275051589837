import axios from 'axios';
import AppConstants from '../constants/appConstants';
import { getToken, saveToken } from './auth';

function request(url, config = {}) {
  if (!config.headers) config.headers = {};
  config.headers['content-type'] = 'application/json';
  return axios({
    method: config.method || 'GET',
    url,
    headers: config.headers,
    data: config.body,
  }).then(({ status, data, response, statusText, ...etc }) => {
    if (status == 200) {
      return data;
    } else {
      const error = new Error(statusText);
      error.data = data;
      error.response = { status, data, response, statusText, ...etc };
      throw error;
    }
  });
}

function authenticatedFetch(url, config = {}) {
  if (!config.headers) config.headers = {};
  config.headers.Authorization = 'Bearer ' + getToken().access_token;
  return axios({
    method: config.method,
    url,
    headers: config.headers,
    data: config.body,
  }).catch(({ response, data }) => {
    const _data = {
      ...data,
      responseStatus: response ? response.status : 0,
      message: response && response.data.message ? response.data.message : '',
    };
    if ((!response || response.status === 401) && !config.retrying) {
      return refreshToken()
        .then(() => {
          return authenticatedFetch(url, {
            ...config,
            retrying: true,
          });
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }

    return Promise.reject(_data);
  });
}

function post(url, body, headers = new Headers()) {
  return request(url, { headers, method: 'POST', body });
}

function authenticatedPost(url, body, config = {}) {
  return authenticatedFetch(url, {
    method: 'POST',
    body,
    ...config,
  });
}

export const checkToken = () =>
  new Promise((resolve, reject) => {
    const token = getToken();

    if (token.expires > new Date().getTime()) resolve();

    reject();
  });

function refreshToken(token = getToken()) {
  return axios
    .post(AppConstants.API.authentication, {
      refresh_token: token.refresh_token,
      grant_type: 'token',
    })
    .then(res => {
      saveToken(res.data.body);
      return res.data.body;
    })
    .catch(error => {
      if (error?.response?.status === 403) {
        localStorage.clear();
        history.go('login');
      } else {
        throw error;
      }
    });
}

export function authGet(url, opts) {
  return authenticatedFetch(url, { method: 'GET', ...opts })
    .then(res => (res && res.result ? res.result : res))
    .catch(e => e);
}

export function authPost(url, body) {
  return authenticatedPost(url, body).then(res => (res && res.result ? res.result : res));
}

export function authenticatedDelete(url, body) {
  return authenticatedFetch(url, {
    method: 'DELETE',
    body,
  });
}

export function authPut(url, body) {
  return authenticatedFetch(url, {
    method: 'PUT',
    body,
  });
}

export function authPatch(url, body) {
  return authenticatedFetch(url, {
    method: 'PATCH',
    body,
  });
}

export function fetchAuth(credentials) {
  return post(AppConstants.API.newAuthentication, {
    grant_type: 'password',
    identifier: credentials.username,
    password: credentials.password,
  });
}

export function fetchCars(user) {
  return authGet(
    `${AppConstants.API.carListing}&test=${user.permissions.includes('can_see_test_inspections')}`
  );
}

export function get(url) {
  return request(url);
}

export function fetchHistoryAuctions(params) {
  return authGet(`${AppConstants.API.historyAuctions}${params ? '?' + params : ''}`);
}

export function fetchNegotiatedAuctions(params) {
  return authGet(`${AppConstants.API.negotiatedAuctions}${params ? '?' + params : ''}`);
}

export function fetchSoldAuctions() {
  return authGet(AppConstants.API.soldAuctions);
}

export function makeBid(
  id,
  amount,
  dealership = '',
  dealerId,
  taxProfile = '',
  isSupperOffer = false,
  isBuyForOffer
) {
  return authenticatedPost(`${AppConstants.API.auctionDetails}/${id}/bids`, {
    amount,
    dealership,
    dealerId,
    taxIdentificationNumber: taxProfile,
    isSupperOffer,
    isBuyForOffer,
  });
}

export function fetchWishlist(email) {
  return authenticatedFetch(`${AppConstants.API.wishlist}/${email}`);
}

export function insertWish(dealerEmail, wish) {
  return authenticatedPost(
    `${AppConstants.API.wishlist}/insert/${encodeURIComponent(dealerEmail)}`,
    wish
  );
}

export function fetchAuction(id) {
  return authenticatedFetch(`${AppConstants.API.auctionDetails}/authenticated/${id}`);
}

export function fetchStockCars() {
  return request(`${AppConstants.API.stockCars}?__limit=${Number.MAX_SAFE_INTEGER}`);
}

export function buyStockCar(carId) {
  return authenticatedPost(AppConstants.API.stockCars, { carId });
}

export function setAutoBid(carId, amount, dealership = '') {
  return authenticatedPost(`${AppConstants.API.autoBid(carId)}`, {
    amount,
    dealership,
  });
}

export function deleteAutoBid(carId, dealership = '') {
  return authenticatedDelete(`${AppConstants.API.autoBid(carId)}`, {
    dealership,
  });
}

export function forgotPassword(email) {
  return post(AppConstants.API.forgotPassword, { email });
}

export function resetPassword(newPassword, changePasswordToken) {
  return post(AppConstants.API.resetPassword, {
    newPassword,
    changePasswordToken,
  });
}
