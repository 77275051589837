export class FilterCommandManager {
  commandHistory = [];

  async executeCommand(command) {
    await command.execute();
    this.commandHistory.push(command);
  }

  async undoLastCommand() {
    const lastCommand = this.commandHistory.pop();
    if (!lastCommand) return;

    await lastCommand.undo();
  }
}
