import { Typography } from '@mui/material';
import React from 'react';
import { Button, PreferencesContainer } from './styles';

import boilerPreferences from '../../../../images/boiler-preferences.svg';
import { useDispatch, useSelector } from 'react-redux';
import { PREFERENCES_CONFIG_KEY } from '../../../Modals';
import { showModal } from '../../../../store/actions/ui.actions';
import { sendEvent } from '../../../../helpers/googleAnalytics';

export function PreferencesCard() {
  const dispatch = useDispatch();
  const { id, email, dealershipName } = useSelector(state => state.authentication.user);

  return (
    <PreferencesContainer>
      <Typography fontSize={19} textAlign={'center'}>
        Não encontrou a oportunidade que estava buscando?
      </Typography>
      <img style={{ width: '95%' }} src={boilerPreferences} />
      <Typography fontSize={14} textAlign={'center'} marginBottom={4}>
        Insira as suas preferências, como:{' '}
        <Typography component={'span'} fontWeight={700}>
          Cautelar sem apontamento; Até 100.000 km; e outras.
        </Typography>{' '}
        Seja avisado assim que uma ou mais ofertas de seu interesse entrarem na plataforma.
      </Typography>
      <Button
        onClick={() => {
          sendEvent('preferencesTracker', {
            dealershipId: id,
            dealershipEmail: email,
            dealershipName: dealershipName,
            eventCategory: 'Auctions',
            eventAction: 'ClickCard',
            eventLabel: 'Pedir Carros',
          });
          dispatch(showModal(PREFERENCES_CONFIG_KEY));
        }}
      >
        Quero ser avisado!
      </Button>
    </PreferencesContainer>
  );
}
