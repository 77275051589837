import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: ${props => (props.tab === 0 ? '1rem auto 0' : '5rem auto 0')};

  @media only screen and (max-width: 770px) {
    flex-direction: column;
    margin: 1rem auto 0;

    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 700px;

  @media only screen and (max-width: 770px) {
    flex-direction: column;
    margin: 1rem auto 0;

    text-align: center;
  }
`;

export const Image = styled.img`
  width: cacl(50% - 1.5rem);
`;

export const Divider = styled.div`
  min-width: 1rem;
  min-height: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 1rem;

  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
`;
