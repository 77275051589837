import axios from 'axios';
import AppConstants from '../../constants/appConstants';
import { getToken } from '../../utils/auth';
import { createViewsFailure, createViewsRequest, createViewsSuccess } from './actions';

export function createViews(inspectionId, auctionId) {
  return async dispatch => {
    dispatch(createViewsRequest());

    try {
      const { data } = await axios.post(
        AppConstants.API.createViews,
        {
          inspectionId,
          auctionId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + getToken().access_token,
          },
        }
      );
      dispatch(createViewsSuccess(data.result));
    } catch (e) {
      dispatch(createViewsFailure(e));
    }
  };
}
