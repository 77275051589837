import { useFormik } from 'formik';

const _defaultValues = {
  price: null,
  km: null,
  marks: null,
  models: null,
  yearInterval: null,
  states: null,
};

export const useFormFilters = ({ initialValues = _defaultValues, validationSchema, onSubmit }) => {
  const _formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return _formik;
};
