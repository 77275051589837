import React, { useState } from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components';
import { Box } from './abstract';
import { Carousel } from './';

const CarouselWrapper = styled(Box)`
  position: relative;
  width: ${props => (props.participate ? '20%' : 'auto')};
  height: ${props => (props.participate ? '100%' : 'auto')};
  margin-right: ${props => (props.participate ? '1.4rem' : '0')};
  border-radius: ${props => (props.detailVehicle ? '0' : '0.8rem')};
  overflow: hidden;

  @media screen and (max-width: 1025px) {
    ${({ participate }) =>
      participate &&
      css`
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 1.25rem;
      `}
  }

  ${({ notifications }) =>
    notifications &&
    css`
      position: relative;
      overflow: hidden;
      border-radius: 0;
    `}
`;

const StyledLazyLoadImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledLazyLoadImage = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
  cursor: zoom-in;
  ${({ zoomPosition, isZoomed }) =>
    isZoomed &&
    zoomPosition &&
    css`
      transform: scale(2);
      transform-origin: ${zoomPosition.x}% ${zoomPosition.y}%;
      cursor: zoom-out;
    `}
`;

export const CarouselPhoto = trackWindowScroll(
  ({
    photos = [],
    height = 200,
    scrollPosition,
    children,
    detailVehicle,
    participate,
    notifications,
    openCarousel,
    showMaximumQualitySeal = false,
    categorySelected = 0,
    ...etc
  }) => {
    const [zoomPosition, setZoomPosition] = useState(null);
    const [isZoomed, setIsZoomed] = useState(false);

    const handleMouseMove = e => {
      if (isZoomed) {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - top) / height) * 100;
        setZoomPosition({ x, y });
      }
    };

    const handleClick = () => {
      setIsZoomed(prev => !prev);
    };

    const handleMouseLeave = () => {
      setZoomPosition(null);
    };

    return (
      <CarouselWrapper
        notifications={notifications}
        detailVehicle={detailVehicle}
        participate={participate}
      >
        <Carousel
          height={height}
          participate={participate}
          showMaximumQualitySeal={showMaximumQualitySeal}
          categorySelected={categorySelected}
          {...etc}
        >
          {photos.map((photo, i) =>
            openCarousel ? (
              <StyledLazyLoadImageWrapper key={i}>
                <StyledLazyLoadImage
                  src={photo.img ? photo.img.src : photo.src || photo}
                  alt={photo.img ? photo.img.alt : ''}
                  effect="opacity"
                  scrollPosition={scrollPosition}
                  zoomPosition={zoomPosition}
                  isZoomed={isZoomed}
                  onMouseMove={handleMouseMove}
                  onClick={handleClick}
                  onMouseLeave={handleMouseLeave}
                />
              </StyledLazyLoadImageWrapper>
            ) : (
              <LazyLoadImage
                key={photo.img ? photo.img.src : photo.src || photo}
                src={photo.img ? photo.img.src : photo.src || photo}
                alt={photo.img ? photo.img.alt : ''}
                effect="opacity"
                scrollPosition={scrollPosition}
                wrapperClassName="slide lazy"
                className="lazy"
              />
            )
          )}
        </Carousel>
        {children}
      </CarouselWrapper>
    );
  }
);
