export const chunk = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

export const flatten = arr => {
  return arr.reduce((a, b) => a.concat(b), []);
};

export const invertKeysToValues = obj =>
  Object.assign({}, ...Object.entries(obj).map(([k, v]) => ({ [v]: k })));

export const objectToArray = obj => {
  let array = [];

  Object?.keys(obj)?.forEach((k, i) => {
    array?.push({
      name: k,
      isActive: Object?.values(obj)[i],
    });
  });

  return array;
};
