import { createSelector } from 'reselect';
import groupBy from 'lodash.groupby';
import get from 'lodash/get';

import { getAuthenticatedUser, getUser } from './userSelectors';
import { getPurchaseFilters } from './uiSelectors';
import {
  isUserParticipating,
  isAuctionOfferAccepted,
  getAuctionById as auctionById,
} from '../../utils/auction';
import { auctionStatus } from '../../constants/appConstants';
import { Set } from 'es6-shim';

export const getAllAuctions = state => state.auctions.allAuctions;

export const getDetailedAuction = state => state.auctions.detailedAuction;

export const getSelectedAuctionId = state => state.auctions.selectedAuctionId;

export const getSelectedAuctionRemainingTime = state => {
  return state.auctions.selectedAuctionRemainingTime;
};

export const getAuctionById = (state, auctionId) => {
  return auctionById(state.auctions.allAuctions, auctionId);
};

// The following function return a new selector function to get an auction
// object. This is to retain memoization between multiple components instances
// using the selector.
// @see https://github.com/reduxjs/reselect#accessing-react-props-in-selectors
export const makeGetAuctionById = () => {
  return createSelector([getAuctionById], auction => auction);
};

export const getSelectedAuction = createSelector(
  [getSelectedAuctionId, getAllAuctions],
  (auctionId, auctions) => {
    return auctionById(auctions, auctionId);
  }
);

export const makeGetAuctionBiddersCount = () => {
  return createSelector([getAuctionById], auction => {
    if (!auction) return 0;
    const bidsByBidderEmail = groupBy(auction.auction.bids, bid => bid.bidder.email);
    return Object.keys(bidsByBidderEmail).length;
  });
};

export const getUserActivityAuctions = createSelector(
  [getUser, getAuthenticatedUser, getAllAuctions],
  (user, authentication, auctions) => {
    const { relatedDealers } = user;
    const activeAuctions = [];
    const noneActiveAuctions = [];

    auctions.forEach(auction => {
      const participating = isUserParticipating(auction, authentication.user, relatedDealers);

      if ((auction.auction.onGoing && participating) || auction.auction.won) {
        activeAuctions.push(auction);
      } else if (auction.auction.onGoing) {
        noneActiveAuctions.push(auction);
      }
    });

    return { activeAuctions, noneActiveAuctions };
  }
);

export const getUserActivityAuctionsCount = createSelector(
  [getUserActivityAuctions],
  ({ activeAuctions, noneActiveAuctions }) => ({
    activeCount: activeAuctions.filter(
      auction =>
        !isAuctionOfferAccepted(auction.auction.status) ||
        auction.auction.status === auctionStatus.offerAccepted
    ).length,
    noneActiveCount: noneActiveAuctions.length,
  })
);

export const getOngoingParticipatingAuctions = createSelector(
  [getUserActivityAuctions],
  ({ activeAuctions }) => {
    return activeAuctions.filter(
      auction => auction.auction.status === auctionStatus.auctionOngoing
    );
  }
);

export const getEndedParticipatingAuctions = createSelector(
  [getUserActivityAuctions],
  ({ activeAuctions }) => {
    return activeAuctions.filter(
      auction => auction.auction.status !== auctionStatus.auctionOngoing && auction.auction.winning
    );
  }
);

export const getLosingAuctions = createSelector([getOngoingParticipatingAuctions], auctions => {
  return auctions.filter(auction => !auction.auction.winning);
});

export const getWinningAuctions = createSelector([getOngoingParticipatingAuctions], auctions => {
  return auctions.filter(auction => auction.auction.winning);
});

export const getAuctionsInNegotiation = createSelector(
  [getEndedParticipatingAuctions],
  auctions => {
    return auctions.filter(
      auction =>
        auction.auction.status === auctionStatus.onOffer ||
        auction.auction.status === auctionStatus.offerOngoing
    );
  }
);

export const getAcceptedAuctions = createSelector([getEndedParticipatingAuctions], auctions => {
  return auctions.filter(auction => auction.auction.status === auctionStatus.offerAccepted);
});

export const getDeclinedAuctions = createSelector([getEndedParticipatingAuctions], auctions => {
  return auctions.filter(auction => auction.auction.status === auctionStatus.offerDeclined);
});

export const getMyPurchases = createSelector([getEndedParticipatingAuctions], auctions => {
  return auctions.filter(auction => isAuctionOfferAccepted(auction.auction.status));
});

const collectPurchaseKeywords = selector =>
  createSelector([selector], auctions =>
    Array.from(
      auctions.reduce((a, { summary: { make, model } }) => {
        a.add(`${make} ${model}`.toLowerCase());
        return a;
      }, new Set())
    )
  );

export const getDeclinedKeywordsToSearch = collectPurchaseKeywords(getDeclinedAuctions);
export const getPurchaseKeywordsToSearch = collectPurchaseKeywords(getMyPurchases);

const createFilteredPurchasesSelector = (selector, shouldApplyFilters = true) =>
  createSelector(
    [selector, getPurchaseFilters],
    (auctions, { keyword, status, startDate, endDate }) => {
      return auctions.filter(({ auction, summary }) => {
        const { make, model } = summary;

        if (shouldApplyFilters) {
          const { status: currentStatus, negotiationStartedAt } = auction;

          // First, filter auctions by status if there are status filters checked.
          if (Array.isArray(status) && status.length && !status.includes(currentStatus)) {
            return false;
          }

          // Second, filter auctions after startDate if the filter is set.
          if (startDate && negotiationStartedAt && negotiationStartedAt < startDate) {
            return false;
          }

          // Second, filter auctions before endDate if the filter is set.
          if (endDate && negotiationStartedAt && negotiationStartedAt > endDate) {
            return false;
          }
        }

        return `${make} ${model}`.toLowerCase().includes(keyword.toLowerCase());
      });
    }
  );

export const getFilteredDeclinedAuctions = createFilteredPurchasesSelector(
  getDeclinedAuctions,
  false
);

export const getFilteredPurchasesAuctions = createFilteredPurchasesSelector(getMyPurchases);

export const getDetailedAuctionPhotos = createSelector(getDetailedAuction, auction => {
  const photos = get(auction, 'summary.photos');
  if (Array.isArray(photos)) {
    return photos.reduce((acc, currentValue) => acc.concat({ src: currentValue }), []);
  }

  return [];
});

export const getAuctionsLocationStates = createSelector(getAllAuctions, auctions => {
  const states = auctions.reduce((acc, auction) => {
    const state = auction.summary.state || 'None';
    const count = acc[state] ? acc[state].count : 0;
    return {
      ...acc,
      [state]: {
        name: state,
        count: count + 1,
      },
    };
  }, {});

  return states;
});

export const isUserParticipatingInAuction = createSelector(
  [getUser, getAuthenticatedUser, getAuctionById],
  (user, authentication, auction) => {
    if (!auction) return false;
    return isUserParticipating(auction, authentication.user, user.relatedDealers || []);
  }
);
