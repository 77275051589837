import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const FiltersWrapper = styled.div`
  margin-bottom: 13px;
  width: 100%;

  grid-template-columns: 345px 1fr auto;
  align-items: flex-end;
  display: grid;

  .result-counter {
    font-style: normal;
    font-weight: bold;
    color: #757778;
    font-size: 16px;
  }

  @media (max-width: 882px) {
    grid-template-columns: 1fr 1fr;
    align-items: center;

    .result-counter {
      display: none;
    }

    & > div:last-child > * {
      margin-left: auto;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
`;

export const FilterSide = styled.div`
  border: 1px solid #d2d4d7;
  box-sizing: border-box;
  background: #ffffff;
  margin-right: 20px;
  border-radius: 8px;
  position: relative;
  padding: 30px 20px;
  min-width: 325px;
  width: 325px;
`;

FilterSide.Close = styled.div`
  background: #757778;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  font-weight: bold;
  padding-top: 2px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  height: 20px;
  width: 20px;
  right: 18px;
  top: -9px;
`;

export const CarList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19.375rem, 1fr));
  margin-bottom: 4rem;

  grid-gap: 0.625rem;

  width: 100%;

  @media (max-width: 882px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;
