import React from 'react';

export function ArrowRight({ color = '#2F3741', ...props }) {
  return (
    <svg
      width={14}
      height={22}
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.91 11L0 19.556 2.545 22 14 11 2.545 0 0 2.446l8.91 8.556V11z" fill={color} />
    </svg>
  );
}
