import React, { useEffect } from 'react';
import { useMultipleSelection, useSelect } from 'downshift';
import { MdKeyboardArrowDown, MdClose } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { rem, hideVisually } from 'polished';

import { Button } from './enhanced/Button';
import { Checkbox } from './enhanced/ui/Checkbox';
import { Card, Box } from './abstract';
import breakpoints from '../design-system/breakpoints';

const Item = styled(Box)`
  label {
    margin-top: 0;
    margin-bottom: ${rem(5)};
    @media screen and (min-width: ${breakpoints.md}) {
      margin-bottom: 0;
    }
    &:hover {
      cursor: pointer !important;
    }
  }
`;

const Label = styled.label`
  ${hideVisually()};
`;

const DropdownMenu = styled(Card)`
  position: absolute;
  width: 100%;
  ${props =>
    props.isOpen &&
    css`
      padding: ${rem(6)}!important;
    `};
`;

const checkboxLabelProps = {
  onClick: e => e.preventDefault(),
};

// TODO: Define propTypes.
export const MultiSelect = ({
  items,
  itemToString,
  onSelectItems,
  selected,
  buttonLabel = 'Selecione',
  selectAllLabel = 'Selecione todos',
  includeSelectAll = true,
}) => {
  const {
    selectedItems,
    addSelectedItem,
    removeSelectedItem,
    getDropdownProps,
    setSelectedItems,
  } = useMultipleSelection({
    selectedItems: selected,
    onSelectedItemsChange: changes => {
      if (typeof onSelectItems === 'function') {
        onSelectItems(changes.selectedItems);
      }
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
    getLabelProps,
    selectItem,
  } = useSelect({
    items,
    itemToString,
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            toggleSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  const selectAllItems = () => {
    const mappedItems = items.map(({ name }) => name);
    if (mappedItems.length > 0) {
      setSelectedItems(mappedItems);
    }
  };

  useEffect(() => {
    if (!selectedItems.length) {
      selectAllItems();
    }
  }, [selectedItems]);

  const toggleSelectedItem = item => {
    if (itemIsSelected(item)) {
      removeSelectedItem(item.name);
    } else {
      addSelectedItem(item.name);
    }

    selectItem(null);
  };

  const itemIsSelected = searchItem => {
    return selectedItems.includes(searchItem.name);
  };

  const handleSelectAllClick = e => {
    e.preventDefault();
    if (items.length !== selectedItems.length) {
      selectAllItems();
    }
  };

  return (
    <div
      css={`
        position: relative;
        margin-bottom: 2.5rem;
      `}
    >
      <Label {...getLabelProps()}>{buttonLabel}</Label>
      <Button
        {...getToggleButtonProps(getDropdownProps())}
        variant="ghost.inversed"
        justifyContent="space-between"
        width={1}
        isRounded
      >
        {buttonLabel}
        <Button.Icon isRight>{isOpen ? <MdClose /> : <MdKeyboardArrowDown />}</Button.Icon>
      </Button>
      <DropdownMenu as="ul" variant="default" bg="white" isOpen={isOpen} {...getMenuProps()}>
        {isOpen && (
          <>
            {includeSelectAll && (
              <Item as="li" color="primaries.Soul" onClick={handleSelectAllClick}>
                <Checkbox
                  labelProps={checkboxLabelProps}
                  label={selectAllLabel}
                  checked={items.length === selectedItems.length}
                />
              </Item>
            )}

            {items.map((item, index) => (
              <Item
                as="li"
                color="primaries.Soul"
                key={`${item.id}`}
                {...getItemProps({ item, index })}
              >
                <Checkbox
                  labelProps={checkboxLabelProps}
                  checked={itemIsSelected(item)}
                  label={itemToString(item)}
                />
              </Item>
            ))}
          </>
        )}
      </DropdownMenu>
    </div>
  );
};
