import { primaries, neutrals, alerts } from '../colors';

// Border radius definitions in pixels.
export const radii = {
  default: '6px',
  rounded: '24px',
  dropdown: '7.52px',
  dropdownField: '6px 6px 3.93px 3.93px',
  scrollbar: '6px',
  fields: '4px',
};

// Borders definitions.
export const borders = {
  ghostButton: {
    default: `2px solid ${neutrals.EclipseE500}`,
    primary: `2px solid ${primaries.Soul}`,
    warning: `2px solid ${alerts.Lamborghini}`,
    inversed: `1px solid ${primaries.Taxi}`,
    disabled: `2px solid ${neutrals.EclipseE80}`,
  },
  dropdownField: `1.31px solid ${neutrals.EclipseE70}`,
};
