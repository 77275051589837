const calendarLabel = 'Calendário';
const focusStartDate = `Interaja com o calendário e adicione a data de início
para filtrar suas compras.`;
const clearDates = 'Limpar datas';
const jumpToPrevMonth = 'Retroceda para mudar para o mês anterior.';
const jumpToNextMonth = 'Siga em frente para mudar para o próximo mês.';
const keyboardShortcuts = 'Atalhos do teclado';
const showKeyboardShortcutsPanel = 'Abra o painel de atalhos de teclado.';
const hideKeyboardShortcutsPanel = 'Feche o painel de atalhos.';
const openThisPanel = 'Abra este painel.';
const enterKey = 'Tecla Enter';
const leftArrowRightArrow = 'Teclas de seta para a direita e para a esquerda';
const upArrowDownArrow = 'teclas de seta para cima e para baixo';
const pageUpPageDown = 'teclas de página para cima e para baixo';
const homeEnd = 'Chaves de casa e fim';
const escape = 'Chave de escape';
const questionMark = 'Ponto de interrogação';
const selectFocusedDate = 'Selecione a data em foco.';
const moveFocusByOneDay = 'Retroceder (esquerda) e avançar (direita) um dia.';
const moveFocusByOneWeek = `Mova para trás (para cima) e avance (para baixo)
por uma semana.`;
const moveFocusByOneMonth = 'Troque meses.';
const moveFocustoStartAndEndOfWeek = `Vá para o primeiro ou último dia
de uma semana.`;
const returnFocusToInput = 'Volte para o campo de entrada de data.';
const keyboardNavigationInstructions = `Pressione a tecla de seta para baixo
para interagir com o calendário e selecione uma data.`;

const chooseAvailableStartDate = ({ date }) =>
  `Escolha ${date} como sua data de início. Está disponível.`;
const chooseAvailableEndDate = ({ date }) =>
  `Escolha ${date} como sua data final. Está disponível.`;
const chooseAvailableDate = ({ date }) => date;
const dateIsUnavailable = ({ date }) => `Não disponível. ${date}`;
const dateIsSelected = ({ date }) => `Selecionada. ${date}`;
const dateIsSelectedAsStartDate = ({ date }) => `Selecionada como data de início. ${date}`;
const dateIsSelectedAsEndDate = ({ date }) => `Selecionada como data final. ${date}`;

export const DayPickerPhrases = {
  calendarLabel,
  jumpToPrevMonth,
  jumpToNextMonth,
  keyboardShortcuts,
  showKeyboardShortcutsPanel,
  hideKeyboardShortcutsPanel,
  openThisPanel,
  enterKey,
  leftArrowRightArrow,
  upArrowDownArrow,
  pageUpPageDown,
  homeEnd,
  escape,
  questionMark,
  selectFocusedDate,
  moveFocusByOneDay,
  moveFocusByOneWeek,
  moveFocusByOneMonth,
  moveFocustoStartAndEndOfWeek,
  returnFocusToInput,
  chooseAvailableStartDate,
  chooseAvailableEndDate,
  chooseAvailableDate,
  dateIsUnavailable,
  dateIsSelected,
  dateIsSelectedAsStartDate,
  dateIsSelectedAsEndDate,
};

export const DateRangePickerInputPhrases = {
  focusStartDate,
  clearDates,
  keyboardNavigationInstructions,
};
