import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { primaries } from '../../../../design-system/colors';
import PropTypes from 'prop-types';
import NewIcon from '../../../../images/icons/new_icon.svg';

export function LabeledCheckBoxWithIcon({
  name,
  checked,
  label,
  onClick,
  imgSrc,
  disabled,
  number = 0,
  showNumber = true,
  fontFamily = 'Nunito',
  fontWeight = 800,
}) {
  return (
    <FormControlLabel
      label={
        <Box
          display="flex"
          alignItems="center"
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          color="#2F3741"
          sx={{ opacity: disabled ? 0.5 : 1 }}
        >
          {!!imgSrc && <img src={imgSrc} style={{ marginRight: '10px' }} />}
          {label}
          {showNumber && (
            <Typography sx={{ fontSize: 10, marginLeft: '5px' }}>
              ({!!number ? number : 0})
            </Typography>
          )}
          {name === 'buyFor' && <img src={NewIcon} style={{ marginLeft: '10px' }} />}
        </Box>
      }
      control={
        <Checkbox
          checked={checked}
          onClick={onClick}
          name={name}
          disableRipple
          disabled={disabled}
          sx={{
            color: '#737171',
            opacity: disabled ? 0.5 : 1,
            '&.Mui-checked': {
              color: primaries.Soul,
            },
          }}
        />
      }
    />
  );
}

LabeledCheckBoxWithIcon.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  imgSrc: PropTypes.string,
  disabled: PropTypes.bool,
  number: PropTypes.number,
};
