import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonWrapper,
  Container,
  ContentWrapper,
  Description,
  Icon,
  ImgWrapper,
  Title,
  Row,
} from './styles';

import ImgDesktopSrc from '../../images/logo-omie-desktop.png';
import ImgMobileSrc from '../../images/logo-omie-mobile.png';
import ImglogoHelpSrc from '../../images/logo-help.png';
import textContent from './constants';
import { sendEvent } from '../../helpers/googleAnalytics';
import { useSelector } from 'react-redux';

const BannerOmieDesktop = () => {
  const { email } = useSelector(state => state.authentication.user);
  return (
    <Container>
      <ImgWrapper>
        <img src={ImgDesktopSrc} alt={textContent.imgAlt} />
      </ImgWrapper>
      <ContentWrapper>
        <Title>{textContent.title}</Title>
        <Row>
          <Description
            onClick={() => {
              sendEvent('MC_Omie_Help', {
                dealershipEmail: email,
                eventCategory: 'MinhasCompras',
                eventAction: 'Click',
                eventLabel: 'Omie',
              });
            }}
            target="_blank"
            href={textContent.urlPortalHelp}
          >
            {textContent.description}
          </Description>
          <Icon src={ImglogoHelpSrc} alt="help" />
        </Row>
      </ContentWrapper>
      <ButtonWrapper>
        <Button
          onClick={() => {
            sendEvent('MC_Omie_Portal', {
              dealershipEmail: email,
              eventCategory: 'MinhasCompras',
              eventAction: 'Click',
              eventLabel: 'Omie',
            });
          }}
          target="_blank"
          href={textContent.urlPortal}
        >
          {textContent.buttonText}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

const BannerOmieMobile = () => {
  return (
    <Container>
      <Row>
        <ImgWrapper>
          <img src={ImgMobileSrc} alt={textContent.imgAlt} />
        </ImgWrapper>
        <Title>{textContent.title}</Title>
      </Row>   
      <ButtonWrapper>
        <Button target="_blank" href={textContent.urlPortal}>{textContent.buttonText}</Button>
      </ButtonWrapper>
      <Row>
          <Description target="_blank" href={textContent.urlPortalHelp}>{textContent.description}</Description>
          <Icon src={ImglogoHelpSrc} alt="help" />
      </Row>
    </Container>
  );
};

const BannerOmie = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth <= 760) {
        return;
      }

      if (!isMobile && window.innerWidth > 760) {
        return;
      }

      if (isMobile && window.innerWidth > 760) {
        setIsMobile(false);
      }

      if (!isMobile && window.innerWidth <= 760) {
        setIsMobile(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return <div>{isMobile ? <BannerOmieMobile /> : <BannerOmieDesktop />}</div>;
};

export default BannerOmie;
