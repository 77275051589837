import styled from 'styled-components';
import bannerCreditoMobile from '../../../../images/banner-credito-mobile.png';
import bannerCreditoDesk from '../../../../images/banner-credito-desk.png';

export const FincreditContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #003a76;
  background-image: url(${bannerCreditoDesk});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  padding: 24px 1.125rem;
  border: 0.063rem solid #d2d4d7;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.25rem #2274a519;

  @media screen and (max-width: 1025px) {
    background-image: url(${bannerCreditoMobile});
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: #ff6c1c;
  border-radius: 8px;
  color: #ffffff;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 400;
  cursor: pointer;
  border: 1px solid #ff6c1c;
  margin-bottom: 8px;
`;

export const CardTitle = styled.div`
  font-family: 'Balboa Condensed', sans-serif;
  font-size: 32px;
  color: #ffffff;
  text-align: center;
`;

export const CardSubTitle = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  padding-top: 5px;
  @media screen and (max-width: 1025px) {
    padding-top: 15px;
  }
`;

export const Text = styled.div`
  font-family: 'Rubik';
  font-size: 16px;
  color: #ffffff;
  width: 181px;
  line-height: 24.96px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const TextDiv = styled.div`
  width: 100%;
  padding-top: 20px;

  @media screen and (max-width: 1025px) {
    padding-top: 0px;
    padding-bottom: 20px;
    padding-top: 15px;
  }
`;

export const TopDiv = styled.div`
  padding-bottom: 171px;

  @media screen and (max-width: 1025px) {
    padding-bottom: 111px;
    padding-top: 10px;
  }
`;
