import Types from './types';

const INITIAL_STATE = {
  payload: [],
  loading: false,
  error: false,
};

export function autoBid(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.AUTOBID_REQUEST:
      return { ...state, loading: true };
    case Types.AUTOBID_SUCCESS:
      return { ...state, loading: false, error: false, payload: action.payload };
    case Types.AUTOBID_FAILURE:
      return { ...state, loading: false, error: action.error, payload: [] };
    default:
      return state;
  }
}

export function listAutoBidVehicles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_AUTOBID_VEHICLES_REQUEST:
      return { ...state, loading: true };
    case Types.LIST_AUTOBID_VEHICLES_SUCCESS:
      return { ...state, loading: false, error: false, payload: action.payload };
    case Types.LIST_AUTOBID_VEHICLES_FAILURE:
      return { ...state, loading: false, error: action.error, payload: [] };
    default:
      return state;
  }
}

export function listAllAutoBidVehicles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_ALL_AUTOBID_VEHICLES_REQUEST:
      return { ...state, loading: true };
    case Types.LIST_ALL_AUTOBID_VEHICLES_SUCCESS:
      return { ...state, loading: false, error: false, payload: action.payload };
    case Types.LIST_ALL_AUTOBID_VEHICLES_FAILURE:
      return { ...state, loading: false, error: action.error, payload: [] };
    default:
      return state;
  }
}
