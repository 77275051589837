import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { rem, rgba } from 'polished';
import { Box, Text } from '../../../abstract';

const foregroundColor = rgba('#ffffff', 0.8);
const rangeSelectedRadius = '10px';

export const CalendarDay = styled(Text).attrs(({ isBlocked }) => ({
  fontSize: 1,
  color: isBlocked ? 'neutrals.EclipseE500' : 'neutrals.EclipseE600',
  bg: isBlocked ? 'neutrals.EclipseE300' : foregroundColor,
  mx: rem(5),
  fontWeight: 'paragraphs.bold',
}))`
  border-radius: ${rem(2)};
  cursor: ${props => (props.isBlocked ? 'not-allowed' : 'pointer')};
`;

export const DatePickerRangeWrapper = styled(Box)`
  .DayPicker {
    background: ${themeGet('colors.primaries.SoulS600')};
    border-radius: ${themeGet('radii.default')};
    width: 100% !important;
    max-width: ${rem(272)};
    overflow-x: hidden;

    &_weekHeader {
      padding: 0 !important;
      top: ${rem(44)};

      &_li {
        small {
          font-size: ${rem(12)};
          color: ${themeGet('colors.neutrals.EclipseE50')};
          font-weight: ${themeGet('fontWeights.paragraphs.bold')};
          font-family: ${themeGet('fonts.paragraphs')};
        }
      }
    }
  }

  .CalendarMonthGrid,
  .CalendarMonth {
    background: none;
  }

  .CalendarMonth {
    padding: 0 !important;

    &_caption {
      padding-top: ${rem(8)};
      padding-bottom: ${rem(28)};
    }

    &_table {
      tr {
        border: solid;
        border-width: ${rem(8)} 0;
        border-color: ${themeGet('colors.primaries.SoulS600')};
      }
    }
  }

  .DayPickerNavigation {
    &__horizontal {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      top: ${rem(11)};
    }

    &_button {
      height: ${rem(22)};

      &:hover {
        border: none;
      }

      &:first-child {
        margin-left: ${rem(8)};
      }

      &:last-child {
        margin-right: ${rem(8)};
      }

      &__disabled {
        border: 0;
        color: ${themeGet('colors.neutrals.EclipseE500')};
        cursor: not-allowed;
      }
    }
  }

  .CalendarDay {
    &,
    &:hover {
      background: none;
      border: 0;
      padding: 0;
    }

    &:focus {
      ${CalendarDay} {
        box-shadow: 0 0 1px 2px ${themeGet('colors.secondaries.UnoU300')};
      }
    }

    &__selected {
      &,
      &_span {
        &,
        &:hover {
          background: ${themeGet('colors.secondaries.Uno')};
        }

        ${CalendarDay} {
          background: ${themeGet('colors.secondaries.Uno')};
          color: #ffffff;
        }
      }

      &_start,
      &_span:first-child {
        border-top-left-radius: ${rangeSelectedRadius};
        border-bottom-left-radius: ${rangeSelectedRadius};
      }

      &_end,
      &_span:last-child {
        border-top-right-radius: ${rangeSelectedRadius};
        border-bottom-right-radius: ${rangeSelectedRadius};
      }
    }
  }

  .DateRangePickerInput {
    background: none;
    border-radius: none;
    border: none;
    margin: ${rem(8)} 0;
    display: flex;
    align-items: center;

    &_arrow {
      height: 1px;
      background: ${foregroundColor};
      overflow: hidden;
      width: 1rem;
    }
  }

  .DateInput {
    background: none;
    width: auto;

    &_input {
      background: none;
      border: 1px solid ${foregroundColor};
      border-radius: ${rem(3)};
      font-size: ${rem(14)};
      height: ${rem(36)};
      text-align: center;
      color: ${themeGet('colors.neutrals.EclipseE50')};
      font-family: ${themeGet('fonts.paragraphs')};

      &__small {
        padding: ${rem(5)};
      }

      &,
      &::placeholder {
        color: #ffffff;
      }

      &__focused {
        background: none;
        border-bottom-width: 1px;
        border-color: ${themeGet('colors.secondaries.Uno')};
      }
    }
  }
`;
