import React from 'react';
import { Svg } from './styles';

export function YouTubeIcon({ color = '#E9EBEC' }) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4309 5H5.56912C3.04566 5 1 6.97106 1 9.40248V15.5975C1 18.0289 3.04566 20 5.56912 20H18.4309C20.9543 20 23 18.0289 23 15.5975V9.40248C23 6.97106 20.9543 5 18.4309 5ZM15.3408 12.8014L9.32495 15.566C9.16465 15.6396 8.97949 15.527 8.97949 15.3559V9.654C8.97949 9.48047 9.16952 9.368 9.33016 9.44644L15.346 12.3838C15.5249 12.4711 15.5218 12.7183 15.3408 12.8014Z"
        fill={color}
      />
    </Svg>
  );
}
