import React from 'react';
import PropTypes from 'prop-types';

import './BaseModal.scss';

export default function composedModal(Component) {
  class BaseModal extends React.Component {
    render() {
      const { props } = this;
      const activeClass = props.show ? 'active' : '';
      const modalClass = 'modal';
      const className = `${activeClass} ${modalClass} ${
        props.class ? props.class : ''
      }`;

      if (props.show && props.preventScrolling) {
        document.body.classList.add('open-modal');
      }

      const closeModal = event => {
        if (
          event.target.classList.contains(modalClass) ||
          event.target.classList.contains('close') ||
          event.target.classList.contains('icon-cross') ||
          event.target.classList.contains(props.classCloseOn)
        ) {
          event.stopPropagation();
          event.preventDefault();
          props.toggle(false);
          if (
            document &&
            document.body.classList.contains('open-modal') &&
            props.preventScrolling
          ) {
            document.body.classList.remove('open-modal');
          }
        }
      };
      return (
        <div className={className} onClick={closeModal}>
          <div id={props.id} className={`modal-content ${props.id}`}>
            {props.showClose && (
              <a
                title="Close"
                className="close"
                onClick={closeModal}
                data-name="modal-close"
              >
                <i className="icon-cross" />
              </a>
            )}
            <Component {...props} />
          </div>
        </div>
      );
    }
  }
  BaseModal.propTypes = {
    show: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    showClose: PropTypes.bool,
    class: PropTypes.string,
    preventScrolling: PropTypes.bool,
    classCloseOn: PropTypes.string,
  };

  BaseModal.defaultProps = {
    showClose: false,
    preventScrolling: false,
    classCloseOn: '',
  };
  return BaseModal;
}
