import React from 'react';

import {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
  TwitterIcon,
  YouTubeIcon,
} from '../components/Icons';

export const medias = [
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/InstaCarroBR/',
    icon: FacebookIcon,
  },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/instacarrobr/',
    icon: InstagramIcon,
  },
  {
    title: 'YouTube',
    link: 'https://www.youtube.com/channel/UCM5HEJQ3CeA7g1DgN5ID0Cw/videos',
    icon: YouTubeIcon,
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/instacarro',
    icon: TwitterIcon,
  },
  {
    title: 'TikTok',
    link: 'https://www.tiktok.com/@instacarrobr',
    icon: TikTokIcon,
  },
];
