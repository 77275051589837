import AppConstants from '../constants/appConstants';

export const hotjar = {
  initialize: () => {
    (function(h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function() {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: AppConstants.Hotjar, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  },
  identify: (userId, email, dealershipName, isNinja, categoriesOn) => {
    window.hj('identify', userId, {
      email,
      dealershipName,
      isNinja,
      categoriesOn,
      date: `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()} - ${new Date().getHours()}:${new Date().getMinutes()}`,
    });
  },
};
