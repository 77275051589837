import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserProfileContext } from './context';
import { UserProfileDTO } from './service-dto/me-to-profile';
import * as _userActions from '../../store/actions/user.actions';

export const _UserContextProvider = ({ service, notifier, children, userActions }) => {
  const [currentUserProfile, setCurrentUserProfile] = useState(UserProfileDTO.default);
  const currentUser = useSelector(state => state.authentication.user);
  const isLoggedIn = useMemo(() => currentUser?.isLoggedIn(), [currentUser]);

  const _populateContext = useCallback(async () => {
    try {
      const user = await service.getCurrentUserProfile();
      const profile = UserProfileDTO.createFromUser(user);
      setCurrentUserProfile(profile);
      userActions.userProfile(user);
    } catch (error) {
      notifier.error(error);
    }
  }, [service, isLoggedIn]);

  const updateProfile = useCallback(
    async contactInfo => {
      const apiUpdatedProfile = await service.updateProfile(contactInfo);
      setCurrentUserProfile(oldProfile => oldProfile.cloneAndUpdate(apiUpdatedProfile));
    },
    [service]
  );

  useEffect(() => {
    _populateContext();
  }, []);

  useEffect(() => {
    userActions.setUserProfileState(
      currentUserProfile.hasCompanyInfo && currentUserProfile.isOutdated
    );
  }, [currentUserProfile.isOutdated, currentUserProfile.hasCompanyInfo]);

  const providerValue = useMemo(() => ({ updateProfile, currentUserProfile }), [
    currentUserProfile,
    updateProfile,
  ]);

  return (
    <UserProfileContext.Provider value={providerValue}>{children}</UserProfileContext.Provider>
  );
};

export function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { userActions: bindActionCreators(_userActions, dispatch) };
}

export const UserContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(_UserContextProvider);
