import React, { useMemo, useCallback } from 'react';

import { Tag } from './styles';
import { AscendingIcon, DownwardIcon } from './icon';

export const PercentageTag = ({ price, currentOffer, web = false, isDetails = false }) => {
  const percentage = useMemo(() => {
    if (!price || price < 100) return 0;
    let op = Math.trunc((currentOffer / price) * 100);
    if (op === 100) return op;
    else return op - 100;
  }, [price, currentOffer]);

  const useLayout = useCallback(() => {
    const ascending = percentage > 0 && percentage < 100;
    const color = isDetails ? '#fff' : '#438a4c';
    const icon = ascending ? <AscendingIcon color={color} /> : <DownwardIcon color={color} />;
    const label = web ? 'web' : 'FIPE';

    if (ascending) {
      return (
        <React.Fragment>
          {icon} +{percentage}% da {label}
        </React.Fragment>
      );
    }

    if (percentage === 100) return <React.Fragment>100% da {label} </React.Fragment>;

    return (
      <React.Fragment>
        {icon} {percentage}% da {label}
      </React.Fragment>
    );
  }, [percentage, isDetails, web]);

  if (!percentage) return null;

  return <Tag isDetails={isDetails}>{useLayout()}</Tag>;
};
