import React from 'react';

import { TabProvider } from './TabsContext';
import { TabList } from './TabList';
import { TabPanel } from './TabPanel';
import { DetailsTabList } from './DetailsTabList';

export const Tabs = props => <TabProvider {...props} />;

Tabs.TabList = TabList;
Tabs.TabPanel = TabPanel;
Tabs.DetailsTabList = DetailsTabList;
