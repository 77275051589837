const TransmissionTypes = {
  MANUAL: 'MANUAL',
  AUTOMATICO: ['AUTOMATICO', 'AUTOMATIZADO', 'AUTO'],
  TIPTRONIC: 'TIPTRONIC',
  MULTITRONIC: 'MULTITRONIC',
  POWERSHIFT: 'POWERSHIFT',
  S_TRONIC: ['S-TRONIC', 'S TRONIC'],
  TETRAFUEL: 'TETRAFUEL',
};

const TransmissionTypeLabels = {
  MANUAL: 'Manual',
  AUTOMATICO: 'Automático',
  TIPTRONIC: 'Tiptronic',
  MULTITRONIC: 'Multitronic',
  POWERSHIFT: 'Powershift',
  S_TRONIC: 'S-Tronic',
  TETRAFUEL: 'Tetrafuel',
};

class Transmission {
  static isManual(version) {
    return version.indexOf(TransmissionTypes.MANUAL) !== -1;
  }

  static isAutomatic(version) {
    let isAutomatic = false;
    for (const tranny of TransmissionTypes.AUTOMATICO) {
      isAutomatic = isAutomatic || version.indexOf(tranny) !== -1;
    }
    return isAutomatic;
  }

  static isTiptronic(version) {
    return version.indexOf(TransmissionTypes.TIPTRONIC) !== -1;
  }

  static isPowershift(version) {
    return version.indexOf(TransmissionTypes.POWERSHIFT) !== -1;
  }

  static isMultitronic(version) {
    return version.indexOf(TransmissionTypes.MULTITRONIC) !== -1;
  }

  static isStronic(version) {
    let isStronic = false;
    for (const tranny of TransmissionTypes.S_TRONIC) {
      isStronic = isStronic || version.indexOf(tranny) !== -1;
    }
    return isStronic;
  }

  static isTetrafuel(version) {
    return version.indexOf(TransmissionTypes.TETRAFUEL) !== -1;
  }

  static getTranny(value) {
    if (!value) return TransmissionTypeLabels.MANUAL;

    const valueUpper = value.toUpperCase();

    if (Transmission.isManual(valueUpper)) {
      return TransmissionTypeLabels.MANUAL;
    }

    if (Transmission.isAutomatic(valueUpper)) {
      return TransmissionTypeLabels.AUTOMATICO;
    }

    if (Transmission.isTiptronic(valueUpper)) {
      return TransmissionTypeLabels.TIPTRONIC;
    }

    if (Transmission.isPowershift(valueUpper)) {
      return TransmissionTypeLabels.POWERSHIFT;
    }

    if (Transmission.isMultitronic(valueUpper)) {
      return TransmissionTypeLabels.MULTITRONIC;
    }

    if (Transmission.isStronic(valueUpper)) {
      return TransmissionTypeLabels.S_TRONIC;
    }

    if (Transmission.isTetrafuel(valueUpper)) {
      return TransmissionTypeLabels.TETRAFUEL;
    }

    return TransmissionTypeLabels.MANUAL;
  }
}

export default Transmission;
