export default {
  CREATE_USER_PREFERENCES_REQUEST: '@orderCars/CREATE_USER_PREFERENCES_REQUEST',
  CREATE_USER_PREFERENCES_SUCCESS: '@orderCars/CREATE_USER_PREFERENCES_SUCCESS',
  CREATE_USER_PREFERENCES_FAILURE: '@orderCars/CREATE_USER_PREFERENCES_FAILURE',

  UPDATE_USER_PREFERENCES_REQUEST: '@orderCars/UPDATE_USER_PREFERENCES_REQUEST',
  UPDATE_USER_PREFERENCES_SUCCESS: '@orderCars/UPDATE_USER_PREFERENCES_SUCCESS',
  UPDATE_USER_PREFERENCES_FAILURE: '@orderCars/UPDATE_USER_PREFERENCES_FAILURE',

  DELETE_USER_PREFERENCES_REQUEST: '@orderCars/DELETE_USER_PREFERENCES_REQUEST',
  DELETE_USER_PREFERENCES_SUCCESS: '@orderCars/DELETE_USER_PREFERENCES_SUCCESS',
  DELETE_USER_PREFERENCES_FAILURE: '@orderCars/DELETE_USER_PREFERENCES_FAILURE',

  LIST_USER_PREFERENCES_REQUEST: '@orderCars/LIST_USER_PREFERENCES_REQUEST',
  LIST_USER_PREFERENCES_SUCCESS: '@orderCars/LIST_USER_PREFERENCES_SUCCESS',
  LIST_USER_PREFERENCES_FAILURE: '@orderCars/LIST_USER_PREFERENCES_FAILURE',
};
