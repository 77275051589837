import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Flex } from '../../components/abstract';
import Loading from '../../components/shared/loading/Loading';
import { EmptyState } from '../../components/enhanced/EmptyState';
import { Layout } from '../../components';
import { NewFilterBar, ListItem } from './components/NewFilterBar';
import { NewListItem } from './components/NewListItem';
import { usePurchasesContext } from './NewPurchasesContext';
import { useSegment } from '../../hooks/useSegment';
import { useUnleashContext, useFlag } from '@unleash/proxy-client-react';
import BannerFincredit from '../../components/BannerFincredit';
import { checkFinancingEnabled } from '../../services/fincredit';
import { useSelector } from 'react-redux';
import BannerOmie from '../../components/BannerOmie';

const Container = styled(Flex)`
  position: relative;
`;

const FilterBarContainer = styled(Flex)``;

export const Purchases = () => {
  const [open, setOpen] = useState();
  const [displaySimulationBanner, setDisplaySimulationBanner] = useState(false);

  const { filtered, filterByDate } = usePurchasesContext();
  const { page } = useSegment();

  const { user } = useSelector(state => state.authentication);

  const updateContext = useUnleashContext();
  const enabledSimulationBanner = useFlag('banner-simulacao');

  const fetchData = async () => {
    try {
      const response = await checkFinancingEnabled(user?.email);
      setDisplaySimulationBanner(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateContext({ userId: user?.email });
  }, [user?.email]);

  useEffect(() => {
    if (enabledSimulationBanner) {
      fetchData();
    }
  }, [enabledSimulationBanner]);

  useEffect(() => {
    filterByDate(7);
    page('Minhas Compras');
  }, []);

  return (
    <Layout bg="#f4f8f9" css={{ flexDirection: 'column' }}>
      <Container className="container" mx="auto" flexDirection="column" width="100%">
        {displaySimulationBanner && <BannerFincredit />}
        <FilterBarContainer className="container" mx="auto" flexDirection="column" width="100%">
          <NewFilterBar filteredAuctions={filtered} />
        </FilterBarContainer>
        <BannerOmie />
        {/*  {filtered &&
          filtered.length > 0 &&
          filtered.map(({ id, ...etc }) => (
            <ListItem
              key={id}
              id={id}
              open={open === id}
              onClick={() => {
                setOpen(open === id ? '' : id);
              }}
              {...etc}
            />
          ))} */}
        {filtered &&
          filtered.length > 0 &&
          filtered.map(({ id, ...etc }) => {
            return <NewListItem key={id} id={id} {...etc} showFinCredi={displaySimulationBanner} />;
          })}
        {filtered && filtered.length < 1 && (
          <EmptyState
            title="Nenhum resultado encontrado"
            description="Faça uma compra para ver os detalhes aqui"
            css={{ position: 'relative' }}
          />
        )}
        {!filtered && <Loading css={{ margin: 'auto', position: 'relative' }} />}
      </Container>
    </Layout>
  );
};
