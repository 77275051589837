import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';
import styled from 'styled-components';
import { rem } from 'polished';
import omit from 'lodash/omit';

import { Box } from '../../../abstract';
import { CarouselNavButton } from './CarouselNavButton';
import { getIntegerValue } from '../../../../utils/number';
import { photosPropTypes } from './propTypes';

const Slide = styled(Box)``;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const Slider = styled(Box)`
  position: relative;

  .slick-dots {
    bottom: 10px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    li {
      display: inline-block;
      margin: 0;
      width: ${rem(12)};
      height: ${rem(12)};

      &.slick-active {
        button {
          &:before {
            opacity: 1;
            color: #ffffff;
            font-size: ${rem(10)};
          }
        }
      }

      button {
        margin: 0;
        width: 100%;
        height: 100%;

        &:before {
          color: #ffffff;
          font-size: ${rem(8)};
        }
      }
    }
  }
`;

export const Carousel = ({ photos, height, hasBigArrows, showDots, overrides, onSlideClick }) => {
  const settings = {
    dots: showDots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    nextArrow: <CarouselNavButton isBig={hasBigArrows} isRight />,
    prevArrow: <CarouselNavButton isBig={hasBigArrows} />,
    ...omit(overrides, ['dots', 'nextArrow', 'prevArrow', 'slide']),
  };

  const handleSlideClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onSlideClick(getIntegerValue(event.target.dataset.index, 0));
  };

  return (
    <Slider as={SlickSlider} height={height} {...settings}>
      {photos.map(({ src, alt }, index) => (
        <Slide key={src} height={height}>
          <SlideImage data-index={index} src={src} alt={alt} onClick={handleSlideClick} />
        </Slide>
      ))}
    </Slider>
  );
};

Carousel.propTypes = {
  ...photosPropTypes,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  hasBigArrows: PropTypes.bool,
  showDots: PropTypes.bool,
  overrides: PropTypes.object,
  onSlideClick: PropTypes.func,
};

Carousel.defaultProps = {
  photos: [],
  onSlideClick() {},
};
