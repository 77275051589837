import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { pusher } from '../App';
import { encode } from '../utils/queryString';
import AppConstants from '../constants/appConstants';

const prepareHeaders = (headers, { getState }) => {
  headers.set('Access-Control-Allow-Origin', '*');
  const token = getState().authentication.token.access_token;
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  return headers;
};

const commercialService = createApi({
  reducerPath: 'commercialService',
  baseQuery: fetchBaseQuery({ baseUrl: AppConstants.API.auctions, prepareHeaders }),
  tagTypes: ['Auction'],
  endpoints: builder => ({
    getAuctions: builder.query({
      query: ({ transformResponse, ...params }) => ({
        url: 'ninja-view',
        params: params ? encode(params) : null,
      }),
      onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
        try {
          await cacheDataLoaded;

          function onOutbid(event) {
            const { id: vehicleId, bid } = event;
            updateCachedData(draft => {
              const vehicleToUpdateIndex = draft.findIndex(vehicle => vehicle._id === vehicleId);
              if (vehicleToUpdateIndex > -1) {
                draft[vehicleToUpdateIndex].maxBidValue = bid.amount;
                draft[vehicleToUpdateIndex].currentWinnerDealership = bid.dealership;
              }
            });
          }

          function onExtendDuration(event) {
            const { id: vehicleId } = event;
            updateCachedData(draft => {
              const vehicleToUpdateIndex = draft.findIndex(vehicle => vehicle._id === vehicleId);
              if (vehicleToUpdateIndex > -1) {
                draft[vehicleToUpdateIndex].remainingTime = event.remainingTime;
                draft[vehicleToUpdateIndex].currentAuctionEndTime = event.endTime;
              }
            });
          }

          const auctionChannel = pusher.channel(AppConstants.AuctionsPusherChannel);

          const outbidUnsubscribe = auctionChannel.bind('outbid', onOutbid);
          const extendUnsubscribe = auctionChannel.bind('extend-duration', onExtendDuration);

          cacheEntryRemoved.then(() => {
            outbidUnsubscribe();
            extendUnsubscribe();
          });
        } catch (error) {
          console.error(error);
        }
      },
      transformResponse: (response, meta, arg) => {
        return arg.transformResponse(response, meta, arg);
      },
      providesTags: result => {
        return [{ type: 'Auction', id: 'LIST' }].concat(
          result?.map(({ _id: id }) => ({ type: 'Auction', id }))
        );
      },
    }),
    getAuction: builder.query({
      query: id => ({
        url: `ninja-view?id=${id}`,
      }),
      transformResponse: response => {
        return response.result[0];
      },
      providesTags: (result, error, id) => [{ type: 'Auction', id }],
    }),
    getNinjas: builder.query({
      query: params => ({
        url: 'ninja-users',
        params: params ? encode(params) : null,
      }),
    }),
  }),
});

export const { useGetAuctionsQuery, useGetAuctionQuery, useGetNinjasQuery } = commercialService;

export default commercialService;
