import React, { useCallback } from 'react';
import { MdSchedule } from 'react-icons/md';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AUCTION_BUY_KEY, AUCTION_OFFER_KEY } from '../../../Modals';
import { trackAuctionEvent } from '../../../../store/actions/event-tracking.actions';
import { setSelectedAuction } from '../../../../store/actions/auction.actions';
import { toggleModal } from '../../../../store/actions/ui.actions';
import { gtmEvents } from '../../../../utils/gtmTracker';
import { Button, BuyNowButton } from '../../../enhanced';
import { toPrice } from '../../../../utils/number';
import MakeOfferQuick from '../MakeOfferQuick';
import { LastOffer, Box } from './styles';
import { useTimingAuctionContext } from '../../../../context/TimingAuctionContext';
import { PrimaryButton } from '../../../Buttons/old';

function MakeOffer({
  trackAuctionEventAction,
  selectAuction,
  hasFinished,
  showButton,
  lastOffer,
  auction,
  toggle,
  user,
}) {
  const { milisecondsLeft } = useTimingAuctionContext();
  /**
   * Handle offer click @HERE
   *
   */
  const handleMakeOfferClick = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggle({
      key: AUCTION_OFFER_KEY,
      show: true,
    });
    trackAuctionEventAction(gtmEvents.CTA_MAKE_OFFER_LISTING, auction._id);
  }, [auction, trackAuctionEventAction, toggle, milisecondsLeft]);

  /**
   * Handle Buy now
   *
   */

  const handleBuyNowClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggle({ key: AUCTION_BUY_KEY, show: true });
  });

  if (hasFinished) {
    return (
      <Box>
        <MdSchedule />
        <span>leilão encerrado</span>
      </Box>
    );
  }

  return (
    <React.Fragment>
      {user.isNinja() || showButton ? (
        <>
          <LastOffer>
            <span className="label">Lance atual</span>
            <div>
              <span className="money-sign">R$</span>
              <span className="amount">{toPrice(lastOffer, false)}</span>
            </div>
          </LastOffer>
          <PrimaryButton hover="#4ab971" onClick={handleMakeOfferClick}>
            Fazer oferta
          </PrimaryButton>
        </>
      ) : (
        <MakeOfferQuick lastOffer={lastOffer} auction={auction} />
      )}
      {auction.summary.buynowPrice.enabled && (
        <PrimaryButton
          onClick={handleBuyNowClick}
          color="#ff4c00"
          hover="#ff7941"
          padding={'0.8rem 2rem'}
        >
          Negociar com Exclusividade
        </PrimaryButton>
      )}
    </React.Fragment>
  );
}

function mapStateToProps({ authentication }) {
  return {
    user: authentication.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectAuction: bindActionCreators(setSelectedAuction, dispatch),
    toggle: bindActionCreators(toggleModal, dispatch),
    trackAuctionEventAction: trackAuctionEvent,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MakeOffer);
