import React from 'react';
import { MdClose } from 'react-icons/md';

import { Container } from './styles';

function ActiveItemsText({ data, removeAction }) {
  return (
    <Container>
      {data?.map((item, key) => (
        <Container.Item key={key}>
          {item}
          <div className="close" onClick={removeAction(item)}>
            <MdClose />
          </div>
        </Container.Item>
      ))}
    </Container>
  );
}

export default ActiveItemsText;
