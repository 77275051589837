import { userFiltersMock } from '../../../context/AuctionFilters/utils/filter-mock';

export class LocalFilterStore {
  static key = 'CURRENT_USER_FILTERS';
  constructor() {
    const filtersString = window.localStorage.getItem(LocalFilterStore.key);
    const hasFilters = Boolean(filtersString);
    if (!hasFilters) this._setFilters(userFiltersMock);
  }

  async getUserFilters() {
    const filtersString = window.localStorage.getItem(LocalFilterStore.key);
    const hasFilters = Boolean(filtersString);
    if (!hasFilters) return [];

    const filtersArray = JSON.parse(filtersString);
    return filtersArray;
  }

  _setFilters(filters) {
    const filterString = JSON.stringify(filters);
    window.localStorage.setItem(LocalFilterStore.key, filterString);
  }

  _generateRandomId() {
    return `${Math.round(Math.random() * 1000)}${Date.now()}`;
  }

  async addFilter(filter) {
    const currentFilters = await this.getUserFilters();
    const _filter = { ...filter, _id: this._generateRandomId() };
    currentFilters.push(_filter);

    this._setFilters(currentFilters);
    return _filter;
  }

  async removeFilter(filterId) {
    const currentFilters = await this.getUserFilters();
    const indexOfFilterToRemove = currentFilters.findIndex(filter => filter._id === filterId);
    const shouldRemoveFilter = indexOfFilterToRemove !== -1;
    if (!shouldRemoveFilter) return currentFilters;

    const [removedItem] = currentFilters.splice(indexOfFilterToRemove, 1);
    this._setFilters(currentFilters);
    return removedItem;
  }
}
