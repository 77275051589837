import { getUser, getToken, getBuyerId } from '../../utils/auth';
import { nextYear, sortFilterParams } from '../../constants/appConstants';
import {
  AUCTION_ACCEPTED_KEY,
  AUCTION_REJECTED_KEY,
  AUCTION_WON_KEY,
  AUCTION_LOST_KEY,
  AUCTION_BUY_KEY,
  AUCTION_OFFER_KEY,
  AUCTION_SUCCESS_KEY,
  AUCTION_NEGOTIATION_KEY,
  AUCTION_CONFIRM_MAKE_OFFER_KEY,
  AUCTION_AUTOMATIC_OFFER_KEY,
  MORE_OPTIONS_KEY,
  REMOVE_AUTOMATIC_OFFER_KEY,
  TIEBREAKER_RULES_KEY,
  AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY,
  AUCTION_BUY_KEY_CONFIRMATION,
  PREFERENCES_CONFIG_KEY,
  TEMPERATURE_MODAL_KNOW_MORE,
  PRODUCT_NF,
  AUCTION_BUY_FOR,
  CONFIRM_BUY_FOR,
} from '../../components/Modals';

export default {
  authentication: {
    user: getUser(getToken),
    token: getToken(),
    showModal: false,
    authError: false,
    authOnGoing: false,
    authErrorMessage: '',
  },
  user: {
    relatedDealers: [],
    wishlist: [],
    dealerMetrics: [],
    relatedDealersLoading: false,
    wishlistLoading: false,
    wishlistHaveError: false,
    wishlistError: '',
    buyerId: getBuyerId(),
    isRegistrationOutdate: false,

    notificationsPreferences: {
      id: null,
      channelOfContact: null,
      userPreferences: null,
      loaded: false,
      error: false,
    },
  },
  auctions: {
    allAuctions: [],
    selectedAuctionId: null,
    selectedAuctionRemainingTime: 0,
    showSingleOfferModal: false,
    bidSuccess: false,
    bidError: false,
    bidErrorMessage: '',
    detailedAuction: {},
    autoBid: {
      isSet: false,
      amount: null,
    },
    allRequestOnGoing: false,
    loadingHistoricsAuctions: false,
    historicsLoaded: false,
    singleRequestOnGoing: false,
    allCarsFetched: false,
    showMakeBidConfirmationModal: false,
    confirmationCar: {},
    favorites: [],
    participatingAuctions: [],
    newOffers: [],
    recommendedCarsId: [],
  },
  timer: {
    elapsedTime: 0,
    stop: false,
  },
  ui: {
    sortFilterParam: sortFilterParams.SHORTEST_EXPECTED_TIME,
    filters: {
      price: 300000,
      kilometers: 400000,
      attentionPoints: 200,
      search: [],
      locations: [],
      metadata: {
        count: 0,
      },
      newOffers: {
        yearInterval: [2000, nextYear],

        search: '',
        models: [],
        states: [],
        marks: [],
        price: [0, 900000],
        km: [0, 400000],
      },
      participating: [],
      participatingErrors: '',
      showNewOffersFilters: false,
      showParticipatingFilters: false,
      showSortFilters: false,
      showNewOffersLoading: false,
      showParticipatingLoading: false,
      newOffersFiltersApplyed: false,
    },
    purchaseFilters: {
      keyword: '',
      startDate: null,
      endDate: null,
      status: [],
    },
    auctionTab: {
      selected: 0,
    },
    categoryFilter: {
      buyFor: false,
      cautelar: false,
      closeToEnd: false,
      mostViewed: false,
      fipe70: false,
      before100k: false,
      score80: false,
      fipe70k: false,
      fipe100k: false,
    },
    showConfirmationModal: false,
    confirmationModalMessage: '',
    makeOfferLoading: false,
    selectedAuctionId: null,
    notificationsCount: 0,
    recommendedCarsNotificationsCount: 0,
    showSuccessBidMessage: false,
    makeOfferStockLoading: false,
    auctionDetailsImageZoom: {
      show: false,
      image: '',
    },
    negotiationsModal: {
      show: false,
      negotiations: [],
      accept: null,
      auction: {},
    },
    showFullScreenCarousel: false,
    showCarouselPlaceHolder: false,
    modals: {
      [AUCTION_ACCEPTED_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_REJECTED_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_WON_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_LOST_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_BUY_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_OFFER_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_SUCCESS_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_NEGOTIATION_KEY]: {
        show: false,
        negotiations: [],
        accept: null,
        auction: {},
      },
      [AUCTION_CONFIRM_MAKE_OFFER_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_AUTOMATIC_OFFER_KEY]: {
        show: false,
        extras: null,
      },
      [MORE_OPTIONS_KEY]: {
        show: false,
        extras: null,
      },
      [REMOVE_AUTOMATIC_OFFER_KEY]: {
        show: false,
        extras: null,
      },
      [TIEBREAKER_RULES_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY]: {
        show: false,
        extras: null,
      },
      [AUCTION_BUY_KEY_CONFIRMATION]: {
        show: false,
        extras: null,
      },
      [PREFERENCES_CONFIG_KEY]: {
        show: false,
        extras: null,
      },
      [TEMPERATURE_MODAL_KNOW_MORE]: {
        show: false,
        extras: null,
      },
      [PRODUCT_NF]: {
        show: false,
        extras: null,
      },
      [AUCTION_BUY_FOR]: {
        show: false,
        extras: null,
      },
      [CONFIRM_BUY_FOR]: {
        show: false,
        extras: null,
      },
    },
  },
  stockCars: {
    cars: [],
    selected: {},
    showSingleOfferModal: false,
    showConfirmationModal: false,
    allRequestOnGoing: false,
  },
  inspections: {
    items: [],
    pageCount: 1,
    loading: false,
    haveError: false,
    errorText: '',
  },
  settings: {
    auctionView: 'grid',
  },
};
