import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px 0;
`;

export const Image = styled.img`
  max-width: 90px;
  width: 100%;
`;

export const About = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px 0;
`;

export const Text = styled.span`
  font: 400 normal 16px/22px 'Roboto', sans-serif;
  color: #2f3741;
  text-align: center;
`;

export const Title = styled(Text)`
  font-weight: 700;
  font-size: 20px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
  width: 100%;

  @media screen and (max-width: 1025px) {
    flex-direction: column-reverse;
    gap: 20px 0;
  }
`;

export const Button = styled.button`
  width: ${props => props.w ?? '100%'};
  padding: 12px 0;

  font: normal 16px/1em 'Roboto', sans-serif;
  font-weight: ${props => props.fontWeight ?? 700};
  color: ${props => props.color ?? '#000'};
  text-align: center;

  background-color: ${props => props.bg ?? 'transparent'};

  border: ${props => props.border ?? 'none'};
  border-radius: 6px;

  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${props => props.hover};
  }

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;
