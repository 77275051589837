const ActionTypes = {};

ActionTypes.Modal = {
  Login: {
    Toggle: 'Modal_Login_Toggle',
  },
  MakeOfferStock: {
    Toggle: 'Modal_MakeOffer_Toggle',
  },
  StockConfirmation: {
    Toggle: 'Modal_MakeOffer_Toggle',
  },
  AutoBidConfirmation: {
    Toggle: 'UI_ToggleAutoBidConfirmation',
  },
  AuctionDetailsZoomImage: {
    Toggle: 'UI_AuctionDetailsZoomImage',
  },
  MakeBidConfirmation: {
    Toggle: 'Modal_MakeBidConfirmation',
  },
  FullScreenCarousel: {
    Toggle: 'UI_Toggle_FullScreenCarousel',
  },
};

ActionTypes.Negotiations = {
  Started: 'Negotiation_Started',
  Ended: 'Negotiation_Ended',
  Clear: 'Negotiation_Clear',
  Show: 'Negotiation_Show',
};

ActionTypes.Authenticate = {
  Perform: 'Authenticate_Perform',
  Success: 'Authenticate_Success',
  Error: 'Authenticate_Error',
  DeAuthenticate: 'Authenticate_DeAuthenticate',
};

ActionTypes.Auction = {
  IsBidding: 'Auction_IsBidding',
  IsBuyNow: 'Auction_IsBuyNow',
  Start: 'Auction_Start',
  OutBid: 'Auction_OutBid',
  RemoveSuccessBidMessage: 'Auction_RemoveSuccessBidMessage',
  OutBidOvercome: 'Auction_Overcome',
  Won: 'Auction_Won',
  SetDuration: 'Auction_SetDuration',
  End: 'Auction_End',
  FetchedALl: 'Auction_FetchedALl',
  FetchedNewOffers: 'Auction_FetchedNewOffers',
  FetchedParticipatingAuctions: 'Auction_FetchedParticipatingAuctions',
  SetOutBid: 'Auction_SetOutBid',
  SetSelected: 'Auction_SetSelected',
  BidSucesss: 'Auction_BidSucesss',
  ClearBidSucess: 'Auction_ClearBidSucess',
  BidError: 'Auction_BidError',
  FetchedSingle: 'Auction_FetchedSingle',
  SetAutoBid: 'Auction_SetAutoBid',
  DeleteAutoBid: 'Auction_DeleteAutoBid',
  CalculateAutoBid: 'Auction_CalculateAutoBid',
  OutBid_Details: 'Auction_OutBid_Details',
  SingleRequestStarted: 'Auction_SingleRequestStarted',
  AllRequestStarted: 'Auction_AllRequestStarted',
  AllRequestEnded: 'Auction_AllRequestEnded',
  SetConfirmationCar: 'Auction_SetConfirmationCar',
  ClearAuction: 'Auction_ClearAuction',
  ClearAuctions: 'Auction_ClearAuctions',
  Cancel: 'Auction_Cancel',
  BuyNow: {
    Update: 'BuyNow_Update',
    Won: 'BuyNow_Won',
  },
  BuyFor: {
    Enabled: '@buyfor/ENABLED',
  },
  SetBlink: 'Auction_SetBlink',
  RemoveBlink: 'Auction_RemoveBlink',
  RemoveSingle: 'Auction_RemoveSingle',
  FetchAuctionsHistory: 'Auction_FetchAuctionsHistory',
  FetchAuctionsHistorySuccess: 'Auction_FetchAuctionsHistorySuccess',
  FetchAuctionsNegotiatedSuccess: 'Auction_FetchAuctionsNegotiatedSuccess',
  SetFavorites: 'Auction_SetFavorites',
  SetRecommendedCarsIds: 'Auction_SetRecommendedCarsIds',
  AddRecommendedCarsId: 'Auction_AddRecommendedCarsId',
};

ActionTypes.RelatedDealers = {
  Fetch: 'RelatedDealers_Fetch',
  FetchSuccess: 'RelatedDealers_FetchSuccess',
  FetchError: 'RelatedDealers_FetchError',
  Clear: 'Related_Dealers_Clear',
  GetBuyerId: 'RelatedDealers_GetBuyerId',
  GetBuyerIdSuccess: 'RelatedDealers_GetBuyerIdSuccess',
  GetBuyerIdError: 'RelatedDealers_GetBuyerIdError',
  ClearBuyerId: 'RelatedDealers_ClearBuyerId',
};

ActionTypes.Timer = {
  Start: 'Timer_Start',
  Stop: 'Timer_Stop',
  Increment: 'Timer_Increment',
};

ActionTypes.Filter = {
  Set: 'Filter_Set',
  Toggle: {
    Losing: 'Filter_Toggle_Losing',
    Ending: 'Filter_Toggle_Ending',
  },
};

ActionTypes.UI = {
  SetShowLojaSelector: 'UI_SetShowLojaSelector',
  MakeOfferLoading: 'UI_MakeOfferLoading',
  NotificationsCount: 'UI_NotificationsCount',
  RecommendedCarsNotificationsCount: 'UI_RecommendedCarsNotificationsCount',
  IsParticipatingInNewAuctions: 'UI_IsParticipatingInNewAuctions',
  SelectedAuctionId: 'UI_SelectedAuctionId',
  MakeOfferStockLoading: 'UI_MakeOfferStockLoading',
  ToggleCarouselPlaceHolder: 'UI_ToggleCarouselPlaceHolder',
  ShowModal: 'UI_ShowModal',
  ToggleModal: 'UI_ToggleModal',
  CloseModal: 'UI_CloseModal',
  SetFilters: 'UI_SetFilters',
  ResetFilters: 'UI_ResetFilters',
  SetAuctionTabSelected: 'UI_SetAuctionTabSelected',
  Sorting: 'Sorting_changed',
  SetMyPurchasesKeywordFilter: 'UI_SetMyPurchasesKeywordFilter',
  SetMyPurchasesFilters: 'UI_SetMyPurchasesFilters',
  ClearMyPurchasesFilters: 'UI_ClearMyPurchasesFilters',
  ShowSuccessBidMessage: 'UI_ShowSuccessBidMessage',
  SetUserProfileState: 'UI_SetUserProfileState',
  HandleFilters: 'UI_HandleFilters',
  SetCategoryFilter: 'UI_SetCategoryFilter',
  ClearCategoryFilter: 'UI_ClearCategoryFilter',
  UpdateNotificationPreferences: 'UI_UpdateNotificationPreferences',
  DefaultUserProfile: 'UI_DefaultUserProfile',
  UserProfile: 'UI_UserProfile',
};

ActionTypes.StockCars = {
  FetchedAll: 'StockCars_FetchedAll',
  SetSelected: 'StockCars_SetSelected',
  SuccesfullyBought: 'StockCars_SuccesfullyBought',
  AllRequestOnGoing: 'StockCars_AllRequestOnGoing',
};

ActionTypes.Inspections = {
  Fetch: 'Inspections_Fetch',
  FetchedSuccess: 'Inspections_FetchedSuccess',
  FetchedError: 'Inspections_FetchedError',
};

ActionTypes.Wishlist = {
  Fetch: 'Wishlist_Fetch',
  FetchSuccess: 'Wishlist_FetchSuccess',
  FetchError: 'Wishlist_FetchError',
  Add: 'Wishlist_Add',
  AddSuccess: 'Wishlist_AddSuccess',
  AddError: 'Wishlist_AddError',
};

ActionTypes.Settings = {
  UpdateAuctionView: 'Settings_UpdateAuctionView',
};

export default ActionTypes;
