import { IntervalJob } from '../utils/interval-job';

export class TimingService extends Map {
  buildTicker(auctionId, remainingTime) {
    const start = window.performance.now();
    return () => {
      const elapsedTimeSinceStart = window.performance.now() - start;
      const auctionRemainingTime = remainingTime - elapsedTimeSinceStart;

      const { job } = this.get(auctionId);

      this.set(auctionId, { remainingTime: auctionRemainingTime, job });
    };
  }

  setAuction(auctionId, remainingTime, ignoreReschedule = false) {
    if (ignoreReschedule) this.stopLastAuctionJob(auctionId);

    const onTick = this.buildTicker(auctionId, remainingTime);
    const job = new IntervalJob(onTick, 1000);

    job.start();

    this.set(auctionId, { remainingTime, job });
  }

  stopLastAuctionJob(auctionId) {
    const timer = this.get(auctionId);
    if (!timer) return null;
    timer.job.end();
  }

  getAuction(auctionId) {
    const timer = this.get(auctionId);
    if (timer) return timer.remainingTime;

    return 0;
  }
}

export const timingService = new TimingService();
