export const localeTable = {
  emptyMessage: 'Nenhum leilão encontrado.',
  loading: 'Carregando...',
};

export const localeTextField = {
  clearText: 'Limpar',
  closeText: 'Fechar',
  loadingText: 'Carregando...',
  noOptionsText: 'Nenhuma opção encontrada',
};
