import React from 'react';
import { Container, Content, Badge } from './styles';
import { formatPrice } from '../../../../utils/formatPrice';
import DollarSignIcon from '../../../../images/icons/cifrao.svg';

export const ReferencePrices = ({ marketPrice, webPrice }) => {
  const withoutPrices = !marketPrice && !webPrice;

  if (withoutPrices) return null;

  return (
    <Container>
      <Content>
        <Badge>
          <div className="badge-icon">
            <img src={DollarSignIcon} />
          </div>
          <span className="badge-title">Preços de referência</span>
          <div className="geometric"></div>
        </Badge>

        <span className="description">
          Analisamos as principais plataformas de venda de carros na web e chegamos a uma média de
          preço para esse modelo.
        </span>
      </Content>

      <Content.Price>
        {marketPrice >= 5_000 && (
          <Content.Price.Item>
            <span className="price-number">R$ {formatPrice(marketPrice)}</span>
            <span className="price-label">Preço na tabela FIPE</span>
          </Content.Price.Item>
        )}

        {webPrice >= 5_000 && (
          <Content.Price.Item>
            <span className="price-number">R$ {formatPrice(webPrice)}</span>
            <span className="price-label">Preço médio na web</span>
          </Content.Price.Item>
        )}
      </Content.Price>
    </Container>
  );
};
