import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

const Body = ({ className, children, ...props }) => {
  const classNames = classnames('modal-body', className);
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};
Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export { Body };
