import { authGet } from '../utils/fecth-wrappers';
import constants from '../constants/appConstants';

export const getAuctionsNotifications = async () => {
  try {
    const notifications = await authGet(`${constants.API.notifications}`);
    return notifications.data.result;
  } catch (e) {
    return [];
  }
};
