import React from 'react';
import { connect } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';

import FilterButton from '../filter-button';
import { FilterCategory } from '../filter-category';
import SortFilter from '../sort-filter';
import { Container } from './styles';

function FilterNavigation({
  showResultNumber = true,
  showNewOffersFilters,
  handleFilterButton,
  newOffersCount,
  tab = 0,
}) {
  const enabledCategory = useFlag('category-test');

  return (
    <>
      <Container>
        <div>
          <FilterButton handleAction={handleFilterButton} tab={tab} />
        </div>
        <div className="result-number">
          {showResultNumber && showNewOffersFilters && `${newOffersCount} resultados encontrados`}
        </div>
        <div>
          <SortFilter />
        </div>
      </Container>

      {enabledCategory && tab !== 1 ? <FilterCategory /> : null}
    </>
  );
}

function mapStateToProps({ ui, auctions }) {
  return {
    showNewOffersFilters: ui.filters.showNewOffersFilters,
    newOffersCount: auctions.newOffers.length,
  };
}

export default connect(mapStateToProps)(FilterNavigation);
