import React from 'react';
import { rem } from 'polished';

import { TaxProfileDetails } from '../../../../components/tax-profile-details';
import appConstants from '../../../../constants/appConstants';
import { Box } from '../../../../components';

const { TaxIdType } = appConstants;

function Content({ taxId }) {
  return (
    <>
      <Box
        px={3}
        py={2}
        bg="alerts.SoulS40"
        color="primaries.Soul"
        css={{
          fontWeight: 'bold',
          fontSize: rem(19),
        }}
      >
        {taxId.type === TaxIdType.CNPJ ? 'Pessoa jurídica' : 'Pessoa física'}
      </Box>
      <TaxProfileDetails profile={taxId} />
    </>
  );
}

export default Content;
