import initialState from './initialState';
import ActionTypes from '../../constants/actionTypes';
import objectAssign from 'object-assign';

function timerReducer(state = initialState.timer, action) {
  switch (action.type) {
    case ActionTypes.Timer.Start:
      return objectAssign({}, state, {
        elapsedTime: 0,
        stop: false,
      });
    case ActionTypes.Timer.Increment:
      return objectAssign({}, state, { elapsedTime: action.elapsed });
    case ActionTypes.Timer.Stop:
      return objectAssign({}, state, { stop: true });
    default:
      return state;
  }
}

export default timerReducer;
