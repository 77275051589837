import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { MdClose } from 'react-icons/md';
import { rem, math } from 'polished';

import breakpoints from '../../design-system/breakpoints';
import { Card, Flex, Box } from '../abstract';

const CloseIcon = styled(({ close, ...props }) => (
  <Box as="a" display={{ md: 'none' }} onClick={close} alignSelf="flex-end" mt={1} {...props}>
    <MdClose size={32} color={themeGet('colors.neutrals.EclipseE800')(props)} />
  </Box>
))`
  cursor: pointer;
`;

const Content = styled(Card)`
  position: absolute;
  left: 0;
  transform: translateX(-85%);
  z-index: 9;

  @media screen and (max-width: ${math(`${breakpoints.md} - 1`)}) {
    position: fixed;
    right: 0;
    top: 0;
    width: 70vw;
    min-width: 0;
    height: 100%;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    left: unset;
  }
`;

export const Menu = ({ children, close, opened, ...props }) => (
  <Content
    p={2}
    boxShadow="contextual"
    minWidth={rem(273)}
    minHeight={rem(100)}
    display={opened ? 'block' : 'none'}
    {...props}
  >
    <Flex flexDirection="column" height="100%">
      <CloseIcon close={close} />
      {children}
    </Flex>
  </Content>
);

Menu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  close: PropTypes.func,
  opened: PropTypes.bool,
};
