import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeGetAuctionBiddersCount } from '../../store/selectors/auctionSelectors';

import './AuctionBidders.scss';

export const AuctionBidders = ({ count }) => {
  return count > 0 ? (
    <div className="auction-bidders">
      <img
        className="auction-bidders__icon"
        src={require('../../images/icons/user-friends-solid.svg')}
        alt="bidders icon"
      />
      <strong className="auction-bidders__count">{count}</strong> participando
    </div>
  ) : null;
};

AuctionBidders.propTypes = {
  auctionId: PropTypes.string.isRequired,
  count: PropTypes.number,
};

AuctionBidders.defaultProps = {
  count: 0,
};

const makeMapStateToProps = () => {
  const getAuctionBiddersCount = makeGetAuctionBiddersCount();
  const mapStateToProps = (state, props) => {
    return {
      count: getAuctionBiddersCount(state, props.auctionId),
    };
  };

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(AuctionBidders);
