import { sortFilterParams } from '../../constants/appConstants';

export const CAR_MARKS = {
  VOLKSWAGEN: 'VOLKSWAGEN',
  FIAT: 'FIAT',
  HYUNDAI: 'HYUNDAI',
  FORD: 'FORD',
  HONDA: 'HONDA',
  NISSAN: 'NISSAN',
  PEUGEOT: 'PEUGEOT',
  CITROEN: 'CITROEN',
  TOYOTA: 'TOYOTA',
};

export const CAR_MODELS = {
  SEDAN: 'SEDAN',
  SUV: 'SUV',
  PICAPE: 'PICAPE',
  CROSSOVER: 'CROSSOVER',
};

export const PARTICIPATING_OPTIONS = [
  {
    value: 'WINNING',
    label: 'Ganhando',
  },
  {
    value: 'LOSING',
    label: 'Perdendo',
  },
  {
    value: 'HIGH_CHANCES_BUYING',
    label: 'Altas chances de compra',
  },
  {
    value: 'POSSIBLE_NEGOTIATION',
    label: 'Possível negociação',
  },
  {
    value: 'LOW_CHANCES_BUYING',
    label: 'Baixas chances de compra',
  },
];

export const SORT_OPTIONS = [
  {
    label: 'Menor tempo de leilão - Previsto',
    value: sortFilterParams.SHORTEST_EXPECTED_TIME,
  },
  {
    label: 'Menor tempo de leilão - Total',
    value: sortFilterParams.SHORTER_TOTAL_TIME,
  },
  {
    label: 'Maior tempo de leilão',
    value: sortFilterParams.LONGER_TIME,
  },
];
