import styled from 'styled-components';

export const ICCheckbox = styled.span`
  width: 22px;
  height: 22px;
  border: 1px solid #737171;
  box-sizing: border-box;
`;

export const ICCheckedIcon = styled(ICCheckbox)`
  box-shadow: 0px 1px 10px -1px rgba(34, 116, 165, 0.68);
  height: 22px;
  width: 22px;
  background: #2274a5;
  background-image: url(${({ bg }) => bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
`;

export const Option = styled.div`
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  min-height: 54px;
  display: flex;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  padding: 28px 24px 13px;
`;

export const SubmitBtn = styled.button`
  border: 1px solid #2274a5;
  background: transparent;
  box-sizing: border-box;
  font-style: normal;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #2274a5;
  height: 40px;
  width: 100%;
`;

export const OutlineBtn = styled.button`
  text-decoration: underline;
  background: transparent;
  font-weight: normal;
  font-style: normal;
  margin-top: 12px;
  font-size: 16px;
  cursor: pointer;
  color: #737171;
  height: 40px;
  border: none;
  width: 100%;
`;
