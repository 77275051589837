import styled from 'styled-components';
import { borderRadius } from 'styled-system';

import { Container } from './Container';

export const Image = styled(Container)(borderRadius);

Image.propTypes = {
  ...borderRadius.propTypes,
};

Image.defaultProps = {
  as: 'img',
  m: 0,
  maxWidth: '100%',
  height: 'auto',
};
