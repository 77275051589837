/* eslint react/no-multi-comp: 0 */

import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba, rem } from 'polished';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import { themeGet } from 'styled-system';

import { Button } from '../../abstract';

import './mini-carousel.scss';

const baseClass = 'mini-carousel';

const PageIndicator = ({ curr, total }) => (
  <div className={`${baseClass}__page-indicator`}>{`${curr}/${total}`}</div>
);
PageIndicator.propTypes = {
  total: PropTypes.number.isRequired,
  curr: PropTypes.number,
};
PageIndicator.defaultProps = {
  curr: 1,
};

const arrowStyles = css`
  color: ${themeGet('colors.primaries.Taxi')};
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.6));
`;

const NextArrow = styled(MdNavigateNext).attrs({ size: rem(24) })`
  ${arrowStyles};
`;

const PrevArrow = styled(MdNavigateBefore).attrs({ size: rem(24) })`
  ${arrowStyles};
`;

const CarouselButton = styled(Button)`
  box-shadow: 0 2px 10px 2px ${rgba('#000000', 0.18)};
  opacity: 0.93;
  background-color: ${rgba('#020202', 0.4)};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  ${props =>
    props.isRight
      ? css`
          right: 10px;
        `
      : css`
          left: 10px;
        `};

  &:hover,
  &:focus {
    background-color: ${rgba('#000000', 0.5)};
    outline: 0;
  }
`;

CarouselButton.displayName = 'CarouselButton';

const arrowButtonProps = {
  width: rem(32),
  height: rem(32),
  borderRadius: '50%',
  bg: rgba('#000000', 0.4),
  px: 0,
};

const ArrowButton = ({ onClick, isRight, children }) => (
  <CarouselButton {...arrowButtonProps} onClick={onClick} isRight={isRight}>
    {children}
  </CarouselButton>
);

ArrowButton.propTypes = {
  onClick: PropTypes.func,
  isRight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

class MiniCarousel extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    settings: PropTypes.object,
  };

  state = {
    currIndex: 1,
  };

  handleBeforeChange = (_, nextIndex) => {
    this.setState({ currIndex: nextIndex + 1 });
  };

  render() {
    const { images, settings, ...props } = this.props;
    const defaultSettings = {
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.handleBeforeChange,
      nextArrow: (
        <ArrowButton isRight>
          <NextArrow />
        </ArrowButton>
      ),
      prevArrow: (
        <ArrowButton>
          <PrevArrow />
        </ArrowButton>
      ),
    };
    const _settings = { ...defaultSettings, settings };
    const { currIndex } = this.state;

    return (
      <div className={baseClass} {...props}>
        <Slider {..._settings}>
          {images.map((image, i) => (
            <img key={image} src={image} alt={`Imagen ${i}`} />
          ))}
        </Slider>
        <PageIndicator curr={currIndex} total={images.length} />
      </div>
    );
  }
}

export default MiniCarousel;
