export class HttpCache {
  constructor(storage, { cacheName, ttl }) {
    this.cacheName = cacheName;
    this.storage = storage;
    this.ttl = ttl;
  }

  _getCacheKey(suffix) {
    return `${this.cacheName}-${suffix}`;
  }

  async setCache(url, response) {
    const key = this._getCacheKey(url);
    const now = Date.now();
    const expirationTime = now + this.ttl;

    await this.storage.setItem(key, { response, expirationTime });
  }

  async isValid(url) {
    try {
      const key = this._getCacheKey(url);
      const now = Date.now();
      const { expirationTime } = await this.storage.getItem(key);
      return expirationTime < now;
    } catch (error) {
      return { isValid: false, cachedResponse: {} };
    }
  }

  async getCache(url) {
    try {
      const key = this._getCacheKey(url);
      const isValid = this.isValid(url);
      const { response } = await this.storage.getItem(key);
      return { isValid, cachedRequest: response };
    } catch (error) {
      return { isValid: false, cachedResponse: {} };
    }
  }

  async clearCache() {
    const keys = await this.storage.getAllKeys();
    const ownKeysRegex = new RegExp(this.cacheName);
    const ownKeys = keys.filter(key => ownKeysRegex.test(key));
    await Promise.all(ownKeys.map(key => this.storage.removeItem(key)));
  }
}

const localStorageProxy = {
  getItem(key) {
    return Promise.resolve(localStorage.getItem(key));
  },
  setItem(key, value) {
    return Promise.resolve(localStorage.setItem(key, JSON.stringify(value)));
  },
  clear() {
    return Promise.resolve(localStorage.clear());
  },
  removeItem(key) {
    return Promise.resolve(localStorage.removeItem(key));
  },
  getAllKeys() {
    return Promise.resolve(Object.entries(localStorage));
  },
};

export const localStorageHttpCache = new HttpCache(localStorageProxy, {
  cacheName: 'common',
  ttl: 1000 * 60 * 60 * 60,
});
