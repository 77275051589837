import React from 'react';
import styled from 'styled-components';

import { Box } from '../../../abstract';

const Hr = styled(Box)`
  border: 0;
`;

Hr.displayName = 'Hr';

export const SeparatorLine = props => (
  <Hr height={1} bg="neutrals.EclipseE70" width={1} {...props} as="hr" />
);
