import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Box, Text, Flex } from '../../../abstract';
import { TabConsumer } from './TabsContext';
import { Badge } from '../Badge';
import { HorizontalLine } from '../HorizontalLine';

const StyledTab = styled(Box)`
  padding-right: ${rem(30)};
  cursor: pointer;
  flex-shrink: 0;
  margin-bottom: ${rem(14)};
  @media only screen and (max-width: 770px) {
    width: 50%;
    padding: 0;
  }
`;
const StyledBadge = styled(Badge)`
  @media only screen and (max-width: 770px) {
    display: none;
  }
`;
const Line = styled(HorizontalLine)`
  margin: 0;
  width: 70%;
  border-color: white;
  ${({ open, color }) => open && `border-color: ${color}`};
`;

export const DetailsTabList = props => (
  <TabConsumer>
    {({ tabs, onClick, activeTabId }) => (
      <Flex {...props}>
        {tabs &&
          tabs.map(({ id, title, tabProps = {} }, index) => (
            <StyledTab onClick={onClick(id)} key={index}>
              <Text
                color={activeTabId === id ? 'neutrals.EclipseE800' : 'neutrals.EclipseE400'}
                fontSize={3}
                fontWeight="paragraphs.bold"
                open={activeTabId === id}
                my={rem(11)}
                display="flex"
              >
                {title}
                <StyledBadge
                  color="white"
                  bg={activeTabId === id ? tabProps.color : 'neutrals.EclipseE400'}
                  size={30}
                  ml={1}
                >
                  {tabProps.counter}
                </StyledBadge>
              </Text>
              <Line open={activeTabId === id} color={tabProps.color} />
            </StyledTab>
          ))}
      </Flex>
    )}
  </TabConsumer>
);
