import React, { useState, useMemo } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { GoVerified } from 'react-icons/go';

import { fractionToPercentage } from '../../../../utils/number';
import { Container } from './styles';

function Extras({ verticalStyle = false, auction }) {
  const [openStateTooltip, setOpenStateTooltip] = useState(false);
  const history = useHistory();

  /**
   * Redirect user to auction details
   *
   */
  function goToDetailsPage() {
    history.push(`/detalhes/${auction._id}`);
  }

  /**
   * Get auction approval percentage
   *
   */
  function getApprovalPct() {
    const numerator = Number(auction.summary.approvedAnswersCount);
    const denominator = Number(auction.summary.answersCount);
    return fractionToPercentage(numerator, denominator);
  }

  const getMessageUF = useMemo(() => {
    if (auction?.summary?.state === 'SP') {
      return 'O carro poderá ser retirado em nosso pátio em SP.';
    }

    return auction?.summary?.state === 'RJ'
      ? 'O carro poderá ser retirado em nossa base no RJ ou em nosso pátio em SP, mediante programação e frete por conta do lojista.'
      : 'O carro poderá ser retirado em nossa base local ou em nosso pátio em SP, mediante programação e frete por conta da InstaCarro.';
  }, [auction?.summary?.state]);

  return (
    <Container className={`extras ${verticalStyle ? 'vertical-style' : ''}`}>
      <Container.Percentage onClick={goToDetailsPage}>
        <GoVerified />
        <span>Aprovado em {getApprovalPct()}% dos pontos.</span>
      </Container.Percentage>
      <Container.StateTag
        className={verticalStyle ? 'vertical-style' : ''}
        onClick={() => setOpenStateTooltip(!openStateTooltip)}
      >
        <FaMapMarkerAlt />
        <span>{auction.summary.state}</span>
        {openStateTooltip && <span className="tooltip">{getMessageUF}</span>}
      </Container.StateTag>
    </Container>
  );
}

export default Extras;
