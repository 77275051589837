import React from 'react';
import PropTypes from 'prop-types';

import { Button as AbstractButton, Text } from '../../abstract';

export const Button = ({ children, leftIcon, rightIcon, ...props }) => (
  <AbstractButton {...props}>
    {leftIcon && (
      <Text className="left-icon" lineHeight="0" as="span" mr={1}>
        {leftIcon}
      </Text>
    )}
    {children}
    {rightIcon && (
      <Text className="right-icon" lineHeight="0" as="span" ml={1}>
        {rightIcon}
      </Text>
    )}
  </AbstractButton>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
};
