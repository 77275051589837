import styled from 'styled-components';
import { borderRadius, boxShadow, variant } from 'styled-system';

import { Container } from './Container';

const cardStyles = variant({ key: 'cards' });

export const Card = styled(Container)(boxShadow, borderRadius, cardStyles);

Card.displayName = 'Card';

Card.propTypes = {
  ...borderRadius.propTypes,
  ...boxShadow.propTypes,
  ...cardStyles.propTypes,
};

Card.defaultProps = {
  variant: 'default',
  bg: 'primaries.Taxi',
};
