import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { neutrals } from '../../../design-system/colors';
import { rem } from 'polished';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';

const customStyles = {
  content: {
    position: 'relative',
    maxHeight: `calc(100% - ${rem(10)})`,
    maxWidth: `calc(100% - ${rem(10)})`,
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '99999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const buyNowLgStyles = {
  content: {
    position: 'relative',
    maxHeight: '406px',
    height: '100%',
    maxWidth: '21.25rem',
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '99999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const buyNowMdStyles = {
  content: {
    position: 'relative',
    maxHeight: `calc(100% - ${rem(10)})`,
    maxWidth: `50%`,
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '99999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const CloseButton = styled(MdClose)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: ${props => props.closeBtnSize ?? 32}px;
  padding: ${props => props.closeBtnPadding ?? 8}px;
  cursor: pointer;
  border-radius: 0 6px 0 0;
  color: ${neutrals.EclipseE600};
  ${({ inverted }) =>
    inverted &&
    `
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  `}
`;

Modal.setAppElement(document.getElementById('app'));

const Container = ({
  children,
  toggle,
  inverted,
  show,
  hideCloseBtn,
  buyNowSpecificStyle,
  makeOfferSpecificStyle,
  style,
  closeBtnPadding,
  closeBtnSize,
}) => {
  const getBuyNowSpecificStyle = useMemo(() => {
    if (buyNowSpecificStyle === 'buyNowLg') return buyNowLgStyles;

    return buyNowSpecificStyle === 'buyNowMd' ? buyNowMdStyles : customStyles;
  }, [buyNowSpecificStyle]);

  const getMakeOfferSpecificStyle = useMemo(() => {
    if (makeOfferSpecificStyle === 'makeOfferLg') return (customStyles.content.maxWidth = '22rem');

    return customStyles;
  }, [makeOfferSpecificStyle]);

  return (
    <Modal
      isOpen={show}
      shouldFocusAfterRender
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      onRequestClose={toggle}
      style={style ?? getBuyNowSpecificStyle ?? getMakeOfferSpecificStyle}
    >
      {toggle && !hideCloseBtn && (
        <CloseButton
          onClick={toggle}
          data-name="close-button"
          inverted={inverted ? inverted : undefined}
          closeBtnPadding={closeBtnPadding}
          closeBtnSize={closeBtnSize}
        />
      )}
      {children}
    </Modal>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  toggle: PropTypes.any,
  loading: PropTypes.bool,
  onOk: PropTypes.any,
  CTA: PropTypes.any,
  inverted: PropTypes.bool,
  show: PropTypes.bool,
};

Container.defaultProps = {
  children: [],
  toggle: null,
  inverted: false,
  show: false,
};

export default Container;
