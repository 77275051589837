import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1.75rem 1.375rem;
`;

export const VehicleNameAndInfos = styled.span`
  margin-bottom: 0.5rem;

  font: 700 normal 1rem/1.281em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const AutomaticOfferLabel = styled.span`
  margin-bottom: 1rem;

  font: 700 normal 1.25rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const AutomaticOfferInfo = styled.span`
  margin-bottom: 0.75rem;

  font: 400 normal 1rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const LastOfferLabel = styled.span`
  margin-bottom: 0.188rem;

  font: 400 normal 0.875rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const LastOfferValue = styled.span`
  margin-right: 0.625rem;

  font: 700 normal 1.125rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const AutoBidFeedBackContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 1.2rem;
`;

export const AutoBidFeedbackLabel = styled.span`
  font: 400 normal 0.875rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const RulesLabel = styled.button`
  padding: 0;

  font: 400 normal 0.875rem/1em 'Roboto', sasns-serif;
  color: #2f3741;
  text-decoration: underline;

  background-color: transparent;
  border: none;

  cursor: pointer;
  outline: none;
`;

export const TemperatureAndUpdateLimit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.625rem;
`;

export const AutomaticBidContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;

  padding: 0.75rem 0.75rem;
  margin-bottom: 0.688rem;

  border: 1px solid #d2d4d7;
  border-radius: 0.25rem;
`;

export const InputLabel = styled.label`
  position: absolute;
  top: -8px;
  left: 8px;

  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;
  letter-spacing: 0.02rem;

  background-color: #fff;
`;

export const Input = styled.input`
  width: 80%;
  padding: 0 1rem;

  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;
  text-align: center;

  border: none;
  outline: none;
`;

export const ErrorMessage = styled.span`
  width: 100%;
  margin: 0.75rem 0;

  font: 500 normal 0.9rem/1.3em 'Roboto', sans-serif;
  color: #ff4c00;
`;

export const AutomaticBidBudgetEditingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 0.7rem;
`;

export const AutomaticBidBudgetEditingLabel = styled.span`
  font: 400 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const EditingLabelStyled = styled(AutomaticBidBudgetEditingLabel)`
  width: 100%;
  margin-bottom: 8px;
  text-align: end;
`;

export const LastOfferAndIsWinning = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 20px;
`;

export const IsWinningLabel = styled.span`
  display: flex;
  align-items: center;
  justify-items: center;

  padding: 0.25rem 0.5rem;

  font: 700 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #fff;

  background-color: ${({ isWinning }) => (isWinning ? '#05762d' : '#c50000')};
  border-radius: 1.25rem;
`;

export const Divider = styled.div`
  display: inline-block;

  width: 100%;
  height: 1px;

  margin: 0 0 0.8rem;

  background-color: #d6d6d6;
`;

export const automaticOfferStyle = {
  content: {
    position: 'relative',
    maxWidth: '355px',
    overflow: 'unset',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
    padding: '0',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '99999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const MaxinumBidBudgetLabel = styled.span`
  margin-bottom: 0.188rem;

  font: 400 normal 0.875rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const MaximumBidBudgetContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${props => (props.specificStyle ? '0' : '1.2rem')};
`;

export const MaximumBidBudgetValue = styled.span`
  font: 700 normal 1.125rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const AlertIcon = styled.img`
  align-self: flex-start;

  /* margin-top: 0.375rem; */
  margin-right: 0.375rem;
`;

export const BidBudgetContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 12px 0;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #fff;
  text-align: center;

  background-color: ${({ bg }) => bg ?? '#438a4c'};
  border: none;
  border-radius: 4px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: none;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #f4f5f5;
    `}
`;

export const RemoveLimitButton = styled(Button)`
  gap: 0 6px;
  font-weight: 400;
  color: #125d8a;
  background-color: transparent;
  border: 1px solid;
  border-color: #125d8a;
`;

export const BidActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px 0;

  width: 100%;
  margin-top: 14px;
`;

export const MoreOptionsContainer = styled(Button)`
  padding: 0;

  font-size: 0.875rem;
  color: #2f3741;
  text-transform: uppercase;
  background-color: transparent;

  @media screen and (max-width: 1025px) {
    margin-bottom: 16px;
  }
`;
