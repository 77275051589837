import styled from 'styled-components';

export const NewOffersContainer = styled.div`
  width: 100%;
`;

export const NewOffersBody = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ filtersOpen }) => (filtersOpen ? 'auto 100%' : '1fr')};
  grid-gap: 20px;

  @media (max-width: 882px) {
    grid-template-columns: 1fr;
  }
`;

NewOffersBody.Content = styled.div`
  width: 100%;
  min-width: 100%;
  position: relative;
`;

export const FilterSide = styled.div`
  width: 325px;

  @media (max-width: 882px) {
    display: none;
  }
`;

FilterSide.Content = styled.div`
  border: 1px solid #d2d4d7;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  padding: 30px 20px;
  width: 100%;
`;

FilterSide.Content.Close = styled.div`
  background: #757778;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  font-weight: bold;
  padding-top: 2px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  height: 20px;
  width: 20px;
  right: 18px;
  top: -9px;
`;

export const LoadingAuctions = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  position: absolute;
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  display: flex;

  img {
    margin-top: 50px;
    height: 100px;
    width: 100px;
  }
`;

export const CarList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19.375rem, 1fr));
  margin-bottom: 4rem;

  grid-gap: 0.625rem;

  width: 100%;

  @media (max-width: 882px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;
