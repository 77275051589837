import styled from 'styled-components';

export const Container = styled.div`
  justify-content: space-evenly;
  border: 1px solid #2274a5;
  box-sizing: border-box;
  align-items: center;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 18px;
  cursor: pointer;
  color: #2274a5;
  display: flex;
  padding: 4px;
  width: 113px;
  height: 45px;

  max-width: 129px;

  @media (max-width: 882px) {
    height: 50px;
    padding: 2px;
    width: ${({ width }) => width}px;

    .icon {
      display: none;
    }

    .label {
      font-size: 16px;
    }
  }
`;

export const Badge = styled.span`
  justify-content: center;
  align-items: center;
  background: #2274a5;
  font-style: normal;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  height: 20px;
  width: 20px;
`;
