import styled from 'styled-components';

export const Container = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(56px, 1fr));
  margin-bottom: ${props => (props.orderCars ? 0 : '30px')};
  grid-gap: 12px;
  display: grid;
  width: 100%;
`;

Container.Item = styled.div`
  box-shadow: 0px 1px 14px -2px rgba(0, 0, 0, 0.25);
  justify-content: center;
  background: #ffffff;
  align-items: center;
  border-radius: 4px;
  position: relative;
  display: flex;
  height: 56px;
  width: 56px;

  img {
    max-width: 56px;
  }

  .text {
    font-style: normal;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
  }

  .close {
    position: absolute;
    background: #5d5d5e;
    border-radius: 50%;
    text-align: center;
    padding-top: 0.5px;
    font-size: 10px;
    cursor: pointer;
    height: 12px;
    width: 12px;
    color: #fff;
    right: -2px;
    top: -2px;
  }
`;
