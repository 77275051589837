import get from 'lodash/get';
import { hasInvalityPaper, hasValityPaper } from '../../../utils/auction';
import { fractionToPercentage } from '../../../utils/number';
import { secondsToHHMMSS } from '../../../utils/time';

export const moneyFormatter = number => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number || 0);
};

export const dateFormatter = date => {
  return new Date(date).toLocaleString();
};

export const marketPriceFormatter = (marketPrice, auction) => {
  const lastOffer = auction.maxBidValue;
  if (!(marketPrice && lastOffer)) return '0%';
  return Math.trunc((100 * lastOffer) / marketPrice) + '%';
};

export const scoreFormatter = (approvedAnswersCount, auction) => {
  return (
    fractionToPercentage(
      Number(approvedAnswersCount),
      Number(get(auction, 'summary.answersCount'))
    ) + '%'
  );
};

export const auctionTemperatureFormatter = (vehiclePricing, auction) => {
  const hotTarget = (vehiclePricing && vehiclePricing.hotTarget) || 0;
  if (!hotTarget) return 'Frio';

  const warmTarget = (vehiclePricing && vehiclePricing.warmTarget) || 0;
  const currentMaxBid = Number(get(auction, 'maxBidValue'));
  if (currentMaxBid >= hotTarget) return 'Quente';
  else if (currentMaxBid >= warmTarget) return 'Morno';

  return 'Frio';
};

export const elapsedTimeFormatter = currentAuctionEndTime => {
  const currentTimeStamp = new Date().getTime();
  const secondsLeft = (currentAuctionEndTime - currentTimeStamp) / 1000;
  return secondsToHHMMSS(secondsLeft);
};

export const formatterHasValityPaper = (highlights, auction) => {
  if (highlights) {
    return hasValityPaper(auction) || hasInvalityPaper(auction);
  } else {
    return '-';
  }
};

export const ninjaNameFormatter = ninjaName => {
  return ninjaName === 'Não encontrado' ? 'Aguardando associação' : ninjaName;
};
