import React, { useEffect, useState } from 'react';

import {
  BulletPointText,
  Button,
  ButtonWrapper,
  Container,
  ContentWrapper,
  Description,
  Icon,
  Img,
  ImgWrapper,
  BulletPointsWrapper,
  Title,
  BulletPoint,
  ImgDeskDiv,
  ImgMobDiv,
} from './styles';

import ImgDesktopSrc from '../../images/banner-credito-carro.png';
import ImgMobileSrc from '../../images/banner-credito-mob.png';
import YellowCheckmark from '../../images/icons/yellow-checkmark.svg';
import textContent from './constants';

const BannerFincreditDesktop = () => {
  return (
    <Container>
      <ImgDeskDiv>
        <img style={{ height: '100%' }} src={ImgDesktopSrc} alt={textContent.imgAlt} />
      </ImgDeskDiv>
      <ContentWrapper>
        <Title>{textContent.title}</Title>
        <Description>
          Acelere o crescimento da sua loja com nossa oferta exclusiva de
          <strong> pagamento facilitado em até 6x!</strong>
        </Description>
      </ContentWrapper>
      <ButtonWrapper>
        <Button href="/pagamento-facilitado/simule">{textContent.desktopButton}</Button>
      </ButtonWrapper>
    </Container>
  );
};

const BannerFincreditMobile = () => {
  return (
    <Container>
      <ImgMobDiv>
        <img style={{ height: '100%' }} src={ImgMobileSrc} alt={textContent.imgAlt} />
      </ImgMobDiv>
      <ContentWrapper>
        <Title>{textContent.title}</Title>
        <Description>
          Acelere o crescimento da sua loja com nossa oferta exclusiva de
          <strong> pagamento facilitado em até 6x!</strong>
        </Description>
        <ButtonWrapper>
          <Button href="/pagamento-facilitado/simule">{textContent.mobileButton}</Button>
        </ButtonWrapper>
      </ContentWrapper>
    </Container>
  );
};

const BannerFincredit = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth <= 760) {
        return;
      }

      if (!isMobile && window.innerWidth > 760) {
        return;
      }

      if (isMobile && window.innerWidth > 760) {
        setIsMobile(false);
      }

      if (!isMobile && window.innerWidth <= 760) {
        setIsMobile(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return isMobile ? <BannerFincreditMobile /> : <BannerFincreditDesktop />;
};

export default BannerFincredit;
