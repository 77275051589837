import * as aucUtil from './auction';
import { definePointBounds, enBounds, mapBoundsToLabel } from '../hooks/useAuctionTemperature';
import AppConstants from '../constants/appConstants';
import { isArray } from 'lodash';

export function losingFilter(user, relatedDealers = []) {
  return auction => {
    return (
      !aucUtil.isUserWinning(auction, user, relatedDealers) &&
      aucUtil.isUserParticipating(auction, user, relatedDealers)
    );
  };
}

export function endingFilter(elapsedTime) {
  return auction => {
    const { remainingTime } = auction.auction;
    const { elapsedSinceLastRequest } = auction;
    const actualRemainingTime = remainingTime - (elapsedTime - elapsedSinceLastRequest);
    return actualRemainingTime > 0 && actualRemainingTime <= 5 * AppConstants.MINUTE;
  };
}

export function participatingFilter(user, relatedDealers = []) {
  return auction => {
    return aucUtil.isUserParticipating(auction, user, relatedDealers);
  };
}

export function searchQuery(query) {
  const queryArray = query
    .toLowerCase()
    .trim()
    .split(' ');
  return auction => {
    return queryArray.some(word => {
      return (
        auction.summary.make.toLowerCase().indexOf(word) > -1 ||
        auction.summary.model.toLowerCase().indexOf(word) > -1
      );
    });
  };
}

export function applyFilters(auctions, activeFilter, ending, losing, time, user, dealers) {
  const activeAuctions = auctions.filter(activeFilter);
  if (!ending && !losing) {
    return activeAuctions;
  }
  return activeAuctions.filter(ending ? endingFilter(time) : losingFilter(user, dealers));
}

export function getLosingCount(auctions, activeFilter, user, dealers) {
  return auctions.filter(activeFilter).filter(losingFilter(user, dealers)).length;
}

export function getEndingCount(auctions, activeFilter, time) {
  return auctions.filter(activeFilter).filter(endingFilter(time)).length;
}

export function cleanFilterValues(filterValues) {
  const asArray = Object.entries(filterValues);
  const filtered = asArray.filter(([key, value]) => {
    if (isArray(value)) {
      return value.length > 0;
    } else {
      return value ?? false;
    }
  });
  return Object.fromEntries(filtered);
}

export function transformFilters(filterValues) {
  return {
    limit: 300,
    ...filterValues,
  };
}

function getAuctionTemperature(auction, lastOffer) {
  const { summary } = auction || { summary: null };

  const lowerTarget = (summary?.vehiclePricing && summary?.vehiclePricing?.warmTarget) || 0;
  const upperTarget = (summary?.vehiclePricing && summary?.vehiclePricing?.hotTarget) || 0;
  const boundsLine = [lowerTarget, upperTarget];

  const hasValidTemperature = boundsLine.every(point => point > 0);

  let statusLabel;
  if (!summary || !hasValidTemperature) {
    statusLabel = enBounds.HIGH;
  } else {
    const bounds = definePointBounds(boundsLine, lastOffer);
    statusLabel = mapBoundsToLabel(bounds);
  }

  return { hasValidTemperature, statusLabel };
}

export function applyParticipatingFilters(filters, auctions) {
  if (filters.length < 1) return [...auctions];
  let statusFiltered = [];
  let temperatureAuctions = [];
  let hasTemperatureFilter = false;

  if (filters.includes('WINNING')) {
    const winning = [...auctions].filter(auction => (auction.auction.winning ? true : false));
    statusFiltered = [...statusFiltered, ...winning];
  }

  if (filters.includes('LOSING')) {
    const losing = [...auctions].filter(auction => !auction.auction.winning);
    statusFiltered = [...statusFiltered, ...losing];
  }

  const auctionsFiltered = statusFiltered.length > 0 ? statusFiltered : [...auctions];

  if (filters.includes('HIGH_CHANCES_BUYING')) {
    const highChancesBuying = auctionsFiltered.filter(auction => {
      const lastOffer = auction.auction.winningBid.amount || 0;
      const { hasValidTemperature, statusLabel } = getAuctionTemperature(auction, lastOffer);
      return hasValidTemperature && statusLabel === 2;
    });
    temperatureAuctions = [...temperatureAuctions, ...highChancesBuying];
    hasTemperatureFilter = true;
  }

  if (filters.includes('POSSIBLE_NEGOTIATION')) {
    const possibleNegotiation = auctionsFiltered.filter(auction => {
      const lastOffer = auction.auction.winningBid.amount || 0;
      const { hasValidTemperature, statusLabel } = getAuctionTemperature(auction, lastOffer);
      return hasValidTemperature && statusLabel === 1;
    });
    temperatureAuctions = [...temperatureAuctions, ...possibleNegotiation];
    hasTemperatureFilter = true;
  }

  if (filters.includes('LOW_CHANCES_BUYING')) {
    const lowChancesBuying = auctionsFiltered.filter(auction => {
      const lastOffer = auction.auction.winningBid.amount || 0;
      const { hasValidTemperature, statusLabel } = getAuctionTemperature(auction, lastOffer);
      return hasValidTemperature && statusLabel === 0;
    });
    temperatureAuctions = [...temperatureAuctions, ...lowChancesBuying];
    hasTemperatureFilter = true;
  }

  return hasTemperatureFilter ? temperatureAuctions : statusFiltered;
}
