export class RemoveFilterCommand {
  constructor(service, filterId) {
    this.service = service;
    this.filterId = filterId;
  }

  async execute() {
    const filter = await this.service.removeFilter(this.filterId);
    this.filter = filter;
  }

  async undo() {
    await this.service.addFilter(this.filter);
  }
}
