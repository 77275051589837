import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { themeGet } from 'styled-system';

import { Button } from '../..';

const arrowStyles = css`
  color: ${themeGet('colors.primaries.Taxi')};
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.6));
`;

const NextArrow = styled(MdChevronRight)`
  ${arrowStyles};
`;

const PrevArrow = styled(MdChevronLeft)`
  ${arrowStyles};
`;

const CarouselButton = styled(Button)`
  box-shadow: 0 2px 10px 2px ${rgba('#000000', 0.18)};
  opacity: 0.93;
  background-color: ${rgba('#020202', 0.4)};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  ${props =>
    props.isRight
      ? css`
          right: 10px;
        `
      : css`
          left: 10px;
        `};

  &:hover,
  &:focus {
    background-color: ${rgba('#000000', 0.5)};
    outline: 0;
  }
`;

CarouselButton.displayName = 'CarouselButton';

const arrowButtonProps = {
  borderRadius: '50%',
  bg: rgba('#000000', 0.4),
  px: 0,
};

export const CarouselNavButton = ({ onClick, isRight, isBig, className }) => {
  const buttonSize = isBig ? '54px' : '32px';
  const iconSize = isBig ? 42 : 24;

  const dimensions = {
    width: buttonSize,
    height: buttonSize,
  };

  // Remove classes passed by react-slick.
  const classes = className.replace(/slick-(prev|arrow|next)/g, '');

  return (
    <CarouselButton
      {...arrowButtonProps}
      {...dimensions}
      onClick={onClick}
      isRight={isRight}
      className={classes}
    >
      {isRight ? <NextArrow size={iconSize} /> : <PrevArrow size={iconSize} />}
    </CarouselButton>
  );
};

CarouselNavButton.propTypes = {
  onClick: PropTypes.func,
  isRight: PropTypes.bool,
  isBig: PropTypes.bool,
  className: PropTypes.string,
};
