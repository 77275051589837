import React from 'react';
import { MdStore, MdAccountCircle } from 'react-icons/md';

import appConstants from '../constants/appConstants';

const { TaxIdType } = appConstants;

const TaxProfileIcon = ({ profileType }) => {
  return profileType === TaxIdType.CNPJ ? <MdStore /> : <MdAccountCircle />;
};

export { TaxProfileIcon };
